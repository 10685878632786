<div class="search-select">
  <div class="search-select-input-results">
    <div class="search-select-input-button-row">
      <label for="search-select-text-search" class="visually-hidden">Search</label>
      <input #textInput id="search-select-text-search" autocomplete="off" type="text" name="search-select-text-search"
        [ngClass]="{
          'search-select__text-input': true,
          'search-select__text-input--pending': pending,
          'search-select__text-input--disabled': disabled || (multiSelect === false && selections && selections.length > 0)
        }" (input)="onTextChange($event)" (focus)="onTextFocus()" (focusout)="justEmitEvent($event, 'textFocusOut')"
        [disabled]="disabled || (multiSelect === false && selections && selections.length > 0)"
        [(ngModel)]="enteredText" [placeholder]="placeholder" title="Search">
      <tuya-spinner [size]="'small'" class="search-select__spinner" *ngIf="pending"></tuya-spinner>
      <button class="search-select__button" [ngClass]="{
          'search-select__button': true,
          'search-select__button--disabled': isButtonDisabled()
        }" [disabled]="isButtonDisabled()" (click)="onClickButton()">
        +
      </button>
    </div>

    <ul *ngIf="shelfOpen && !pending" class="search-select__search-results">
      <li class="search-select-result-item" *ngFor="let result of _searchResults; let i = index"
        attr.data-id="{{ result.id }}" (click)="onClickSearchResult(i)" [ngClass]="{
          'search-select__result': true,
          'search-select__result--selected': selectedSearchResults.includes(i)
        }">
        {{ result.name }}
      </li>
      <li class="search-select-result-item search-select__no-results" *ngIf="displayNoMatches">
        {{ 'builderDriverSelection.noMatches' | translate }}
      </li>
    </ul>

    <ul class="search-select__selected-items">
      <li class="search-select__selected-item" *ngFor="let item of selections; let i = index">
        <span class="search-select__selected-item-text">
          {{ item.name }}
        </span>
        <button *ngIf="!disabled" class="search-select__remove-item" (click)="onRemoveItem(i)">
          &times;
        </button>
      </li>
    </ul>
  </div>
</div>
<div class="select-search-mask"></div>