import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../models/redux.action';
import { TermsOfServiceDto } from '../models/dto';

export type TermsOfServiceAction = Action<any, any>;

export const LOAD_TERMS = '[TERMS] Load terms request';
export const LOAD_TERMS_SUCCESS = '[TERMS] Terms loaded';
export const LOAD_TERMS_FAIL = '[TERMS] Terms loading failed';
export const LOAD_PRIVACY = '[TERMS] Load privacy ruquest';
export const LOAD_PRIVACY_SUCCESS = '[TERMS] Privacy loaded';
export const LOAD_PRIVACY_FAIL = '[TERMS] Privacy loading failed';

@Injectable()
export class TermsOfServiceActions {
    @dispatch()
    loadTerms = (userType: string): TermsOfServiceAction => ({
        type: LOAD_TERMS,
        payload: userType
    })
    loadTermsSucceeded = (terms: TermsOfServiceDto): TermsOfServiceAction => ({
        type: LOAD_TERMS_SUCCESS,
        payload: terms
    })
    loadTermsFailed = (error: any): TermsOfServiceAction => ({
        type: LOAD_TERMS_FAIL,
        payload: null,
        error,
    })
    @dispatch()
    loadPrivacy = (userType: string): TermsOfServiceAction => ({
        type: LOAD_PRIVACY,
        payload: userType
    })
    loadPrivacySucceeded = (terms: TermsOfServiceDto): TermsOfServiceAction => ({
        type: LOAD_PRIVACY_SUCCESS,
        payload: terms
    })
    loadPrivacyFailed = (error: any): TermsOfServiceAction => ({
        type: LOAD_PRIVACY_FAIL,
        payload: null,
        error,
    })
}
