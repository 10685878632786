<div class="container">
    <div class="col-md-12 login-wrapper">
        <div class="col-md-12 row">
            <div class="col-md-6 big-logo">
                <h1 class="logo"><img src="/assets/img/navigation-bar/TUYA.svg" alt="TUYA" /></h1>
                <!--<h3 class="sub-logo">Now</h3>-->
            </div>
            <div class="col-md-6 login-part">
                <div class="row">
                    <div *ngIf="!(forgotPasswordRequestedEmail$ | async)" class="col-md-offset-1 back-to-login">
                        <a routerLink="/login">
                            <i class="fa fa-long-arrow-left"></i>
                            {{'tuya-forgot-password.backToLogin' | translate}}
                        </a>
                    </div>
                    <tuya-spinner *ngIf="isLoading$ | async"></tuya-spinner>
                    <tuya-reset-password *ngIf="!(passwordResetTokenExists$ | async)" [hidden]="(isLoading$ | async)"
                        [isUserNotValid]="this.isUserNotValid.getValue()"
                        [forgotPasswordRequestedEmail]="forgotPasswordRequestedEmail$ | async"
                        (onSubmit)="requestForgotPassword($event)"></tuya-reset-password>
                    <tuya-choose-new-password *ngIf="(passwordResetTokenExists$ | async)"
                        [hidden]="(isLoading$ | async)" [validationResult]="resetPasswordValidationResult$ | async"
                        (onPasswordSubmit)="resetPassword($event)"></tuya-choose-new-password>
                </div>
            </div>
            <div class="col-md-12 assistant"><span [translate]="'tuya-login.assistant'"></span><a
                    [href]="'tel:' + ('tuya-app.assistance-phone' | translate)">{{ 'tuya-app.assistance-phone-text' |
                    translate }}</a></div>
        </div>
    </div>
</div>