import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../../core/models/redux.action';
import { IndustrySegment } from '../../core/models/dto';


export type IndustrySegmentAction = Action<IndustrySegment[], string>;

export const LOAD_INDUSTRY_SEGMENTS = '[INDUSTRY_SEGMENT] Load';
export const LOAD_INDUSTRY_SEGMENTS_SUCCESS = '[INDUSTRY_SEGMENT] Load success';
export const LOAD_INDUSTRY_SEGMENTS_ERROR = '[INDUSTRY_SEGMENT] Load error';

@Injectable()
export class IndustrySegmentActions {
    @dispatch()
    load = (): IndustrySegmentAction => ({
        type: LOAD_INDUSTRY_SEGMENTS,
        payload: null
    })

    loadSucceeded = (segments: IndustrySegment[]): IndustrySegmentAction => ({
        type: LOAD_INDUSTRY_SEGMENTS_SUCCESS,
        payload: segments
    })

    loadFailed = (error: any): IndustrySegmentAction => ({
        type: LOAD_INDUSTRY_SEGMENTS_ERROR,
        payload: null,
        error,
    })
}
