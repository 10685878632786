import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../../core/models/redux.action';
import { ShipperRegisterationResultDto, ShipperRegistrationDto } from '../../core/models/dto';
import { AccountValidationResultVO, CompanyValidationResultVO } from '../model/model';


export type StepbackRegisterAction = Action<any, string>;
export type ValidateAccountRegisterAction = Action<any, string>;
export type ValidateCompanyRegisterAction = Action<any, string>;
export type SubmitRegisterAction = Action<any, string>;

export const STEPBACK_REGISTER_FORM = '[REGISTER_FORM] Stepback';

export const VALIDATE_ACCOUNT_FORM = '[ACCOUNT_FORM] Validate';
export const VALIDATE_ACCOUNT_FORM_SUCCESS = '[ACCOUNT_FORM] Validate success';
export const VALIDATE_ACCOUNT_FORM_ERROR = '[ACCOUNT_FORM] Validate error';

export const VALIDATE_COMPANY_FORM = '[PROFILE_FORM] Validate';
export const VALIDATE_COMPANY_FORM_SUCCESS = '[COMPANY_FORM] Validate success';
export const VALIDATE_COMPANY_FORM_ERROR = '[COMPANY_FORM] Validate error';

export const SUBMIT_REGISTER_FORM = '[REGISTER_FORM] Submit';
export const REGISTER_FCM = '[REGISTER_FORM] Register FCM';
export const REGISTER_GET_PERMISSION_FCM = '[REGISTER_FORM] Register get permission FCM';
export const SUBMIT_REGISTER_FORM_SUCCESS = '[REGISTER_FORM] Submit success';
export const SUBMIT_REGISTER_FORM_ERROR = '[REGISTER_FORM] Submit error';
export const CLEAR_FORM = '[REGISTER_FORM] Clear form values and errors';


@Injectable()
export class RegisterFormActions {
    @dispatch()
    stepback = (): StepbackRegisterAction => ({
        type: STEPBACK_REGISTER_FORM,
        payload: null
    })

    @dispatch()
    clearForm = (): StepbackRegisterAction => ({
        type: CLEAR_FORM,
        payload: null
    })

    @dispatch()
    validateAccount = (form: ShipperRegistrationDto): ValidateAccountRegisterAction => ({
        type: VALIDATE_ACCOUNT_FORM,
        payload: form
    })

    validateAccountSucceeded = (result: AccountValidationResultVO): ValidateAccountRegisterAction => ({
        type: VALIDATE_ACCOUNT_FORM_SUCCESS,
        payload: result
    })

    validateAccountFailed = (error: any): ValidateAccountRegisterAction => ({
        type: VALIDATE_ACCOUNT_FORM_ERROR,
        payload: null,
        error,
    })

    @dispatch()
    validateCompany = (form: ShipperRegistrationDto): ValidateCompanyRegisterAction => ({
        type: VALIDATE_COMPANY_FORM,
        payload: form
    })

    validateCompanySucceeded = (result: CompanyValidationResultVO): ValidateCompanyRegisterAction => ({
        type: VALIDATE_COMPANY_FORM_SUCCESS,
        payload: result
    })

    validateCompanyFailed = (error: any): ValidateCompanyRegisterAction => ({
        type: VALIDATE_COMPANY_FORM_ERROR,
        payload: null,
        error,
    })

    submit = (form: ShipperRegistrationDto): SubmitRegisterAction => ({
        type: SUBMIT_REGISTER_FORM,
        payload: form
    })
    @dispatch()
    registerFCM = (form: ShipperRegisterationResultDto): SubmitRegisterAction => ({
        type: REGISTER_FCM,
        payload: form
    })
    @dispatch()
    registerGetPermissionFCM = (form: ShipperRegisterationResultDto): SubmitRegisterAction => ({
        type: REGISTER_GET_PERMISSION_FCM,
        payload: form
    })

    @dispatch()
    submitSucceeded = (data: ShipperRegisterationResultDto): SubmitRegisterAction => ({
        type: SUBMIT_REGISTER_FORM_SUCCESS,
        payload: data
    })

    submitFailed = (error: any): SubmitRegisterAction => ({
        type: SUBMIT_REGISTER_FORM_ERROR,
        payload: null,
        error,
    })


}
