import { OrderEditMode } from '../../core/models/order.dto';
import { OrderStatusId } from '../../core/models/dto';

export function getOrderEditMode(statusId: number): OrderEditMode {
    if (statusId === OrderStatusId.Draft || statusId === OrderStatusId.Offered) {
        return OrderEditMode.NotAccepted;        
    } else if (statusId === OrderStatusId.Assigned) {
        return OrderEditMode.Accepted;
    } else if (statusId === OrderStatusId.InProgress) {
        // @TODO THIS IS TEMP Should be also stops in count!!!!
        return OrderEditMode.PartiallyCompleted;
    } else {
        return OrderEditMode.Other;
    }
}

export function isEditOrderDisabled(statusId: number) {
    // TODO: This will disable the edit button for the details section of the site
    return (statusId > OrderStatusId.Declined) && (statusId < OrderStatusId.Released);
}
