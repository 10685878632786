import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { WizardStep } from '../../models/ui.model';
import { AssignedDriverDto } from '../../../core/models/order.dto';

@Component({
    selector: 'tuya-edit-limitation-bar',
    templateUrl: './edit-limitation-bar.component.html',
    styleUrls: ['./edit-limitation-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditLimitationBarComponent implements OnInit {

    @Input() public wizardStep: WizardStep;
    @Input() public set drivers(drivers: Array<AssignedDriverDto>) {
        this._dirvers = drivers;
        this.driverNames = this.getDriversNames(drivers);
    }
    public get drivers () {
        return this._dirvers;
    }
    public wizardStepModel = WizardStep;
    public driverNames: string;
    private _dirvers: Array<AssignedDriverDto>;

    constructor() { }

    ngOnInit() {
    }

    private getDriversNames (drivers: Array<AssignedDriverDto>): string {
        if (!drivers) { return ''; }
        return this.driverNames = drivers.reduce((p, c) => {
            return p.concat(c.fullName);
        }, []).join(', ');
    }
}
