import { StateDto } from '../../core/models/dto';
import { IOrderAppState } from '../models/order.model';
import { AutocompleteItem } from '../../core/models/autocomplete-item.model';
import { getInitialStopState, StopType, StopDto } from '../models/stop.model';
import { getFullAddressLine } from '../utils/address.utils';
import { AssignmentsDto, ConfirmDriversAssignmentsDto } from '../models/assignments.model';
import { AddressDto } from '../models/address.model';

export function transformStop2AutocompleteItem(item: StopDto, index, states: StateDto[]): AutocompleteItem {
    return {
        name: getFullAddressLine(item, states),
        value: index,
        type: item.stopDtoType
    };
}

/**
 * Transform OrderDto to IOrderAppState. Iterate over items, build stops array, move stops to top level.
 * @param response Result of API result.
 * @param string Request method of response.
 */
export function transformOrderDtoToOrderAppState(response): IOrderAppState {
    const stops = [];
    response.orderItems = response.orderItems.map( (item) => {
        item.stops.forEach( (stop) => {
            const isInStopsArray = stops.some( stopArrayEntry => {
                if (typeof stopArrayEntry.id !== 'undefined') {
                    return stop.id === stopArrayEntry.id;
                } else {
                    return false;
                }
            });

            if (!isInStopsArray) {
                stops.push(stop);
            }
        });
        delete item.stops;
        return item;
    });
    response.stops = stops;
    if (response.stops.length === 1) {
        response.stops.push(getInitialStopState({ type: StopType.Delivery, sequenceNumber: 1 }));
    }
    return response;
}

export function transformToConfirmDriversAssignmentsObj(assignments: AssignmentsDto): ConfirmDriversAssignmentsDto {
    const driverIds = assignments.drivers.map(driver => driver.userAccountId);
    const poolIds = assignments.pools.map(pool => pool.id);

    return {
      'orderId': assignments.orderId,
      'driverUserAccountIds': driverIds,
      'driverPoolIds': poolIds
    };
}

/*
 * Adds empty stops if there aren't at least 2 stops.
*/
export function transformStopsToMinimalRequirement(stops): StopDto[] {
    if (stops.length === 0) {
        return [getInitialStopState({ type: StopType.Pickup, sequenceNumber: 0 }),
                getInitialStopState({ type: StopType.Delivery, sequenceNumber: 1 })];
    } else if (stops.length === 1) {
        return [...stops, getInitialStopState({ type: StopType.Delivery, sequenceNumber: 1 })];
    } else {
        return stops;
    }
}

export function transformAddressIntoStopDto(address: AddressDto[]): StopDto [] {
    const addresslength = address.length;
    const stops = [];
    for (let i = 0; i < addresslength; i++) {
        const stopDto: StopDto = {
            id: 0,
            type: null,
            typeId: 0,
            sequenceNumber: 0,
            nameOrDescription: address[i].name,
            pickupDateTypeId: null,
            pickupNoEarlierThan: null,
            pickupNoLaterThan: null,
            contactName: null,
            contactPhoneNumber: null,
            contactEmail: null,
            isEmailTrackingLink: false,
            isTextTrackingLink: false,
            isSignatureRequired: false,
            itemsDescription: null,
            notes: null,
            status: null,
            address: address[i]
        };

        stops.push(stopDto);
    }

    return stops;
}

export function transformOrderToOrderEdit(order) {
    const orderEditObject: any = {};
    orderEditObject.id = order.id || 0;
    orderEditObject.shipperContactId = order.shipperContactId || 0;
    orderEditObject.referenceId = order.referenceId || 'abc';
    orderEditObject.deliveryType = order.deliveryType;
    orderEditObject.offerType = order.offerType;
    orderEditObject.isDirect = order.isDirect;
    orderEditObject.isExtendable = order.isExtendable;
    orderEditObject.total = order.total;
    orderEditObject.orderItems = order.orderItems.map(item => {
        const orderItem: any = {};
        orderItem.id = item.id;
        orderItem.piecesCount = item.piecesCount;
        orderItem.sizeTypeId = item.sizeTypeId;
        orderItem.weight = item.weight;
        orderItem.description = item.description || '';
        orderItem.stops = item.stops.map(stop => {
            const newStop: any = {};
            newStop.id = stop.id;
            newStop.sequenceNumber = stop.sequenceNumber;
            newStop.type = stop.typeId;
            newStop.typeId = stop.typeId;
            newStop.nameOrDescription = stop.nameOrDescription;
            newStop.pickupDateTypeId = stop.pickupDateTypeId;
            newStop.pickupNoEarlierThan = stop.pickupNoEarlierThan;
            newStop.pickupNoLaterThan = stop.pickupNoLaterThan;
            newStop.isEmailTrackingLink = stop.isEmailTrackingLink;
            newStop.isTextTrackingLink = stop.isTextTrackingLink;
            newStop.isSignatureRequired = stop.isSignatureRequired;
            newStop.itemsDescription = stop.itemsDescription;
            newStop.notes = stop.notes;
            newStop.contactPhoneNumber = stop.contactPhoneNumber;
            newStop.contactEmail = stop.contactEmail;
            newStop.status = stop.status;
            newStop.contactName = stop.contactName;
            newStop.address = stop.address;

            return newStop;
        });
        return orderItem;
    });
    return orderEditObject;
}
