<div class="details-section" [ngClass]="{collapsed: isCollapsed}">
    <p>{{'tuya-shipment.tuya-stop-details.contact-text' | translate }}</p>
    <form name="form"
          #form="ngForm"
          autocomplete="off"
          novalidate>
        <div class="form-group"
             [ngClass]="{ 'has-error': contactName.dirty &&
        (!contactName.valid || contactName.errors?.pattern || isOnlySpaces)}"
             tooltip="{{'tuya-shipment.unable-change' | translate}}"
             placement="top"
             containerClass="tuya-tooltip"
             container="body"
             [isDisabled]="getDisabledStatement(true)">
            <input type="text"
                   class="form-control"
                   autocomplete="off"
                   [ngClass]="{ 'filled': stop.contactName }"
                   name="contactName"
                   [disabled]="getDisabledStatement()"
                   [(ngModel)]="stop.contactName"
                   (ngModelChange)="onValidateContactName()"
                   #contactName="ngModel"
                   required
                   maxlength="50"
                   trim="blur"
                   [pattern]="contactNamePattern">
            <span class="reminder">{{'tuya-shipment.tuya-stop-details.name' | translate }}</span>
            <div *ngIf="contactName.dirty && !contactName.valid && !contactName.errors?.pattern"
                 class="help-block"
                 [translate]="'tuya-shipment.tuya-stop-details.error.contactName'"></div>
            <div *ngIf="contactName.dirty && (contactName.errors?.pattern || isOnlySpaces)"
                 class="help-block"
                 [translate]="'tuya-shipment.tuya-stop-details.error.contact-name-pattern-error'"></div>
        </div>
        <a (click)="isCollapsed = !isCollapsed" class="btn-link add-info-expanded" [ngClass]="{expanded: !isCollapsed}">
            <span [translate]="'tuya-shipment.tuya-stop-details.add-contact-details'"></span>
        </a>
        <div [collapse]="isCollapsed">
            <div class="form-group"
                 [ngClass]="{ 'has-error': contactPhoneNumber.touched && !isContactPhoneValid }"
                 tooltip="{{'tuya-shipment.unable-change' | translate}}"
                 placement="top"
                 containerClass="tuya-tooltip"
                 container="body"
                 [isDisabled]="getDisabledStatement(true)">
                <input title="Phone" type="text"
                       autocomplete="off"
                       class="form-control"
                       [disabled]="getDisabledStatement()"
                       [ngClass]="{ 'filled': contactPhoneNumberValue.length }"
                       mask="(000) 000-0000"
                       name="contactPhoneNumber"
                       #contactPhoneNumber="ngModel"
                       [(ngModel)]="stop.contactPhoneNumber"
                       (ngModelChange)="onValidatePhone()"/>
                <span class="reminder">{{'tuya-shipment.tuya-stop-details.phone' | translate }}</span>
                <div class="help-block" *ngIf="contactPhoneNumber.touched && !isContactPhoneValid"
                     [translate]="'tuya-shipment.tuya-stop-details.error.phone'"></div>
            </div>
            <div class="form-group" [ngClass]="{ 'has-error': contactEmail.touched && !contactEmail.valid }"
                 tooltip="{{'tuya-shipment.unable-change' | translate}}"
                 placement="top"
                 containerClass="tuya-tooltip"
                 container="body"
                 [isDisabled]="getDisabledStatement(true)">
                <input type="text"
                       autocomplete="off"
                       class="form-control"
                       name="contactEmail"
                       [disabled]="getDisabledStatement()"
                       [ngClass]="{ 'filled': stop.contactEmail }"
                       [pattern]="contactEmailPattern"
                       #contactEmail="ngModel"
                       [(ngModel)]="stop.contactEmail"
                       (ngModelChange)="onValidateContactEmail()"/>
                <span class="reminder">{{'tuya-shipment.tuya-stop-details.email' | translate }}</span>
                <div class="help-block" *ngIf="contactEmail.touched && !contactEmail.valid"
                     [translate]="'tuya-shipment.tuya-stop-details.error.email'"></div>
            </div>
            <div class="form-group">
                <label class="checkbox-inline"
                       tooltip="{{'tuya-shipment.unable-change' | translate}}"
                       placement="top"
                       containerClass="tuya-tooltip"
                       container="body"
                       [isDisabled]="getDisabledStatement(true)">
                    <input name="isEmailTrackingLink"
                           type="checkbox"
                           [(ngModel)]="stop.isEmailTrackingLink"
                           [disabled]="getDisabledStatement()"
                           #isEmailTrackingLink="ngModel"> {{'tuya-shipment.tuya-stop-details.email-tracking' |
                    translate}}
                </label>
                <!--Does not support the future yet-->
                <label class="checkbox-inline"
                       *ngIf="false"
                       tooltip="{{'tuya-shipment.unable-change' | translate}}"
                       placement="top"
                       container="body"
                       containerClass="tuya-tooltip"
                       [isDisabled]="getDisabledStatement(true)">
                    <input name="isTextTrackingLink"
                           type="checkbox"
                           [(ngModel)]="stop.isTextTrackingLink"
                           [disabled]="getDisabledStatement()"
                           #isTextTrackingLink="ngModel">{{'tuya-shipment.tuya-stop-details.text-tracking' | translate}}
                </label>
                <label class="checkbox-inline"
                       tooltip="{{'tuya-shipment.unable-change' | translate}}"
                       placement="top"
                       containerClass="tuya-tooltip"
                       container="body"
                       [isDisabled]="getDisabledStatement(true)">
                    <input name="isSignatureRequired"
                           type="checkbox"
                           [(ngModel)]="stop.isSignatureRequired"
                           [disabled]="getDisabledStatement()"
                           #isSignatureRequired="ngModel">{{'tuya-shipment.tuya-stop-details.signatureRequired' |
                    translate}}
                </label>
            </div>
        </div>
    </form>
</div>
