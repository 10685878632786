import { Stop, StopType } from '../models/order.model';
import { DateHelper } from './dateHelper';

export const MIN_TIME_WINDOW = 10 * 60 * 1000; // 10 minutes, story 2070.4.2.2
export const HOUR = 60 * 60 * 1000; // one hour in ms
export const DEFAULT_TIME_WINDOW = 4 * 3600 * 1000; // 4 hours
export const MAX_NO_EARLIER_TIME_FOR_TIME_WINDOW = (24 * 3600 * 1000) - DEFAULT_TIME_WINDOW;

export class StopPickupTimeUtils {
    public static getTomorrow = DateHelper.getTomorrow;
    public static addMsec2Date = DateHelper.addMsec2Date;
    public static getMaxNoLaterThan = DateHelper.getMaxNoLaterThan;

    public static getTime(currentStop: Stop, relatedStop?: Stop) {
        const noEarlierThanTime =  this.getDefaultPickupNoEarlierThanTime(relatedStop),
              noLaterThanTime = this.getDefaultPickupNoLaterThanTime(noEarlierThanTime, relatedStop);

        return {
            noEarlierThanTime: noEarlierThanTime.toISOString(),
            noLaterThanTime: noLaterThanTime.toISOString(),
        };
    }

    public static getMaxDateTimeToday(dt: Date, window = false) {
        dt = new Date(DateHelper.getTomorrow(dt).getTime() - 1000); // 1 sec before tomorrow.
        return (window) ? new Date(dt.getTime() - MIN_TIME_WINDOW) : dt; // substract minimal time window if needed.
    }

    public static getDefaultPickupNoEarlierThanTime(relatedStop?: Stop) {
        const currentDate = new Date();
        if (relatedStop) {
            return new Date(relatedStop.pickupNoEarlierThan);
        } else {
           return new Date(currentDate);
        }
    }

    public static getDefaultPickupNoLaterThanTime(noEarlierThanTime, relatedStop?: Stop) {
        const maxDateTimeToday = this.getMaxDateTimeToday(noEarlierThanTime);
        let noLaterTime;
        if (relatedStop) {
            noLaterTime = this.addMsec2Date(DEFAULT_TIME_WINDOW + MIN_TIME_WINDOW, new Date(relatedStop.pickupNoEarlierThan));
        } else {
            noLaterTime = this.addMsec2Date(DEFAULT_TIME_WINDOW, noEarlierThanTime);
        }
        return noLaterTime > maxDateTimeToday ? maxDateTimeToday : noLaterTime;
    }

    public static getDefaultDateForTomorrow(): Date {
        const newDefaultDate = DateHelper.getTomorrow(new Date());
        newDefaultDate.setHours(8); // 5964  8am
        return newDefaultDate;
    }

    public static validatePickupArriveNoLater(currentTime: Date, stop: Stop): boolean {
        // Invalid: Pickup ArriveNoLater  is less than [Current time] +1 hour; story 7357
        return (new Date(stop.pickupNoLaterThan)) >= StopPickupTimeUtils.addMsec2Date(HOUR, currentTime);
    }

    public static validateDeliveryArriveNoLater(currentTime: Date, deliveryStop: Stop, pickupStop: Stop): boolean {
        // Invalid: Delivery pickupNoLaterThan is less than [current time ] + 1 hour &&  Is less than Pickup Stop ArriveNoLater; story 7357
        const deliveryPickupNoLaterThan = new Date(deliveryStop.pickupNoLaterThan);
        return deliveryPickupNoLaterThan >= StopPickupTimeUtils.addMsec2Date(HOUR, currentTime) &&
            deliveryPickupNoLaterThan >= (new Date(pickupStop.pickupNoLaterThan));
    }

    public static validateTimeframesForShipmentBuilder(currentTime: Date, stopList: Array<Stop>, orderType: number): Array<any> {
        const pickupStops = stopList.filter(stop => stop.typeId === StopType.Pickup),
            // take singular Pickup stop OR Lastest pickup stop
            pickupStop = pickupStops.length === 1 ? pickupStops[0] : pickupStops.filter(stop => stop.typeId === StopType.Pickup)
                .sort( (a, b) => {
                    const c =  new Date(a.pickupNoLaterThan),
                        d = new Date(b.pickupNoLaterThan);
                    return d.valueOf() - c.valueOf();
                })[0],
            invalidStopIds = [];

        stopList.forEach((stop: Stop) => {
           const isValid = stop.typeId === StopType.Pickup ? StopPickupTimeUtils.validatePickupArriveNoLater(currentTime, stop) :
               StopPickupTimeUtils.validateDeliveryArriveNoLater(currentTime, stop, pickupStop);
                if (!isValid) {
                    invalidStopIds.push(stop.id);
                }
        });

        return invalidStopIds;
    }


}
