import { ManageCompanyTab } from './../../model/model';
import { Component, EventEmitter, Input, OnInit, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { BusinessEntityDto } from './../../../core/models/dto';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'tuya-billing-contacts',
    templateUrl: './billing-contacts.component.html',
    styleUrls: ['./billing-contacts.component.scss']
})

export class BillingContactsComponent implements OnInit, OnChanges {

    @Input() currentManageCompanyTab: ManageCompanyTab;
    @Input() companyProfilesInfo: BusinessEntityDto = new BusinessEntityDto();
    @Input() states: string[];
    @Input() arePhysicalAndBillingAddressTheSame: Function;
    @Input() suspended: boolean;
    public ManageCompanyTab: typeof ManageCompanyTab = ManageCompanyTab;
    @Output() changeManageCompanyTab = new EventEmitter<ManageCompanyTab>();
    @Output() saveBillingInfo = new EventEmitter<BusinessEntityDto>();
    @ViewChild('cancel', {static: true}) modalChangePassword;
    @ViewChild('billingInfoForm', {static: true}) billingInfoForm;
    model: BusinessEntityDto;
    isChecked = false;
    phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    phonePattern = /[0-9]{10}/;
    namePattern = /^([a-zA-Z-.üöäÿïëßÄËÏÖÜŸ']+\s*)+$/;
    emailPattern = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    isPhoneNumberValid = true;
    nextTab: ManageCompanyTab;
    component: { name: string; code: string; industrySegmentId: number; physicalAddress: { id: string; name: string; addressLine: string; city: string; stateId: number; country: string; postalCode: string; suiteNumber: string; latitude: string; longitude: string; }; billingAddress: { id: string; name: string; addressLine: string; city: string; stateId: number; country: string; postalCode: string; suiteNumber: string; latitude: string; longitude: string; }; generalPhone: string; billingPhone: string; billingEmail: string; billingFirstName: string; billingLastName: string; };

    constructor(private modalService: BsModalService) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.setDefaultBillingInfo();
    }

    saveBillingInfoForm() {
        this.saveBillingInfo.emit(this.model);
    }

    onHideModal(evt) {
        if (evt) {
            this.discardChanges();
        }
        this.modalService.hide(1);
    }

    setDefaultBillingInfo() {
        if (this.companyProfilesInfo) { // to prevent reading property of null
            this.model = {
                ...this.companyProfilesInfo,
                physicalAddress: {
                    ...this.companyProfilesInfo.physicalAddress
                },
                billingAddress: {
                    ...this.companyProfilesInfo.billingAddress
                }
            };

            this.isChecked = this.arePhysicalAndBillingAddressTheSame(this.model);
        }
    }

    isPhoneValid() {
        if (this.model.billingPhone) {
            this.model.billingPhone = this.model.billingPhone.replace(/\D+/g, '');
            this.isPhoneNumberValid = this.phonePattern.test(this.model.billingPhone);
        }
    }

    isPhoneEmpty() {
        return (typeof this.model.billingPhone === 'undefined') ? true :
            !this.model.billingPhone.length;
    }

    isModelChanged() {
        if (this.model) {
            return !!(JSON.stringify(this.companyProfilesInfo) !== JSON.stringify(this.model));
        }
        return false;
    }

    cancelBillingInfoForm(template: TemplateRef<any>) {
        this.modalService.show(template, { ignoreBackdropClick: true });
    }

    discardChanges() {
        this.setDefaultBillingInfo();
        this.isPhoneNumberValid = true;
        this.modalService.hide(1);
        if (this.nextTab) {
            this.changeManageCompanyTab.emit(this.nextTab); // go to the next tab with discarded changes
        }
        return true;
    }

    sameAsPhysical() {
        if (this.isChecked) {
            this.model.billingAddress = {
                ...this.companyProfilesInfo.physicalAddress,
                id: this.model.billingAddress.id
            };
        } else {
            this.model.billingAddress = {
                ...this.companyProfilesInfo.billingAddress,
                id: this.model.billingAddress.id
            };
        }
    }
}
