import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, switchMap, filter, take, finalize } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { ConfigService } from '../../app.config.service';
import { ACCESS_TOKEN_KEY, TOKEN_TYPE, REFRESH_TOKEN_KEY, BEARER_AUTH } from '../constants/storage.constants';
import { API_AUTHENTICATION } from '../constants/api.constants';
import { AuthActions } from '../../auth/actions/auth.actions';
import { AlertActions } from '../../core/actions/alert.actions';
import { BuilderActions } from '../../builder/actions/builder.actions';
import { environment } from 'environments/environment';
export type setHeadersType = { [name: string]: string | string[]; };

interface TokenResponse {
    access_token: string;
    refresh_token: string;
    token_type: string;
}

@Injectable()
export class ApiWithAuthInterceptor implements HttpInterceptor {

    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    constructor(
        private injector: Injector,
        private authActions: AuthActions,
        private alertActions: AlertActions,
        private builderActions: BuilderActions
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authService = this.injector.get(AuthenticationService);
        const configService = this.injector.get(ConfigService);

        request = this.addDefaultHeaders(request);

        return next.handle(request).pipe(
            catchError(error => {
                if (error.status !== 401 || request.url.includes(API_AUTHENTICATION)) {
                    return throwError(error);
                }

                return this.handle401Error(request, next, authService);
            })
        );
    }

    private addDefaultHeaders(request: HttpRequest<any>): HttpRequest<any> {
        const configService = this.injector.get(ConfigService);
        const cfg = configService.getConfiguration();
        const cachedTokenType = localStorage.getItem(TOKEN_TYPE);
        const cachedToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        let selfApiUrl: string;
        let openIdApiUrl: string;

        if (cfg) {
            selfApiUrl = cfg['default'].apiBaseUrl;
            openIdApiUrl = cfg['default'].openIdBaseUrl;

            // Return immediately if we're not working with the correct APIs.
            const usingSelfApi = request.url.includes(selfApiUrl);
            const usingOpenIdApiUrl = request.url.includes(openIdApiUrl);
            if (!usingSelfApi && !usingOpenIdApiUrl) return request;

            if (usingSelfApi) {
                request = request.clone({
                    setHeaders: {
                        'api_key': cfg['default'].apiKey,
                        'Content-Type': 'application/json',
                        ...this.getAdditionalHeaders()
                    }
                });
            } else {
                request = request.clone({
                    setHeaders: {
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        ...this.getAdditionalHeaders()
                    }
                });
            }
        }

        if (cachedTokenType && cachedToken) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `${cachedTokenType} ${cachedToken}`
                }
            });
        }

        return request;
    }

    private getAdditionalHeaders() {
        const is_ie = navigator.userAgent.includes('MSIE') || navigator.userAgent.includes('Trident/');
        if (is_ie) {
            return <setHeadersType>{
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
            };
        }
        return <setHeadersType>{};
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler, authService: AuthenticationService): Observable<HttpEvent<any>> {
        if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
    
            return authService.openIdCreateAccessToken().pipe(
                switchMap((result: TokenResponse) => {
                    result.token_type = BEARER_AUTH;
                    this.storeNewTokens(result);
                    this.refreshTokenSubject.next(result.access_token);

                    if (!environment.production) console.debug(`${BEARER_AUTH} token set, retrying request`);
                    return next.handle(this.addAuthorizationHeader(request, BEARER_AUTH, result.access_token));
                }),
                catchError(err => {
                    this.handleLoginFailure();
                    if (!environment.production) console.debug('Error refreshing token:', err);                    
                    return EMPTY; // Return an empty observable, redirect to login screen
                }),
                finalize(() => {
                    this.refreshTokenInProgress = false;
                })
            );
        } else {
            // Wait for token refresh to complete before retrying the request
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    if (!environment.production) console.debug(`${BEARER_AUTH} token available, proceeding with request`);
                    return next.handle(this.addAuthorizationHeader(request, BEARER_AUTH, token));
                })
            );
        }
    }

    private addAuthorizationHeader(request: HttpRequest<any>, tokenType: string, token: string): HttpRequest<any> {
        if (!environment.production) console.debug('Adding Authorization Header:', tokenType, token);
        return request.clone({
            setHeaders: {
                'Authorization': `${tokenType} ${token}`
            }
        });
    }
    

    private storeNewTokens(result: any): void {
        localStorage.setItem(ACCESS_TOKEN_KEY, result.access_token);
        localStorage.setItem(REFRESH_TOKEN_KEY, result.refresh_token);
        localStorage.setItem(TOKEN_TYPE, result.token_type);
    }

    private handleLoginFailure(): void {
        this.alertActions.allowIncomingAlerts();
        this.builderActions.setNavPromptNotRequired();
        this.refreshTokenInProgress = false;
        this.authActions.initRefreshReset();
        this.authActions.logout();
    }
}