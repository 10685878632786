import { TermsOfServiceDto } from '../models/dto';

export enum TERMS {
    Terms = 1,
    Privacy = 2
}

export class TermsOfService {
    terms: TermsOfServiceDto | null;
    privacy: TermsOfServiceDto | null;
}
