import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BuilderActions} from '../../actions/builder.actions';
import { select } from '@angular-redux/store';
import { ShipmentSelectors } from '../../../shipment/selectors/shipment.selectors';
import { Observable } from 'rxjs';
import { BoxSizeDto } from '../../../core/models/dto';
import { BoxSizeTypes } from '../../models/item.model';
import { BuilderSelectors } from '../../selectors/builder.selectors';
import { OrderItemDto } from '../../../core/models/order.dto';
import { StopDto } from '../../../core/models/dto';
import { UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';


@Component({
  selector: 'tuya-item-selector',
  templateUrl: './item-selector.component.html',
  styleUrls: ['./item-selector.component.scss']
})
export class ItemSelectorComponent implements OnInit {
  @select (ShipmentSelectors.itemsSizesSelector) itemsSizes$: Observable<BoxSizeDto[]>;
  @select (BuilderSelectors.getItems) items$: Observable<OrderItemDto[]>;
  @Input() stop: StopDto;
  @Input() stopType: number;
  @Input() stopIndex: number;
  @Input() items;
  @Input() itemSelectorForm: UntypedFormGroup;

  public itemsSizes: BoxSizeDto[];
  public numItems: number;

  @Input() materialLocked = false;
  @Input() nonMaterialLocked = false;

  constructor(private builderActions: BuilderActions,
              private formBuilder: UntypedFormBuilder) {
    this.itemsSizes$.subscribe(itemsSizes => {
      this.itemsSizes = itemsSizes.filter(itemSize => { return itemSize.isVisible; });
    });
    this.items$.subscribe(items => { this.numItems = items.length; });
  }

  ngOnInit() {
    this.initItemsList();
    if (this.materialLocked) {
      this.itemList.controls.forEach(formGroup => {
        formGroup.disable();
      });
    }
  }

  // initialize with small items
  get defaultItemSizeType(): number {
    return BoxSizeTypes.Small;
  }

  get defaultItemSizeIndex(): number {
    return this.defaultItemSizeType - 1;
  }

  get defaultItem() {
    return {
      sizeTypeId: this.defaultItemSizeType,
      piecesCount: 1,
      weight: this.itemsSizes[this.defaultItemSizeIndex].weight,
      referenceId: null
    };
  }

  get itemList() {
    return this.itemSelectorForm.get('itemList') as UntypedFormArray;
  }

  initItemsList() {
    // if itemsSizes is populated (should be), then initialize the itemsList
    if (this.itemsSizes.length && this.itemsSizes[this.defaultItemSizeIndex]) {
      // Add an initial item if none exist.
      if (this.items.length === 0) {
        this.builderActions.onAddItemToOrder(this.defaultItem);
        this.addItemForm(null);
      } else {
        // create an itemForm for each item
        this.items.forEach((item, index) => {
          this.addItemForm(index);
        });
      }
    }
  }

  onAddItem() {
    if (this.itemsSizes.length && this.itemsSizes[this.defaultItemSizeIndex]) {
      this.builderActions.onAddItemToOrder(this.defaultItem);
      this.addItemForm(null);
    }
  }

  addItemForm(index: number) {
    // if index is passed as arg, init forms with appropriate values from state
    // if no index passed, init forms with default values
    const initialValues = this.items[index] ? {
      sizeTypeId: this.items[index].sizeTypeId,
      piecesCount: this.items[index].piecesCount,
      weight: this.items[index].weight,
      referenceId: this.items[index].referenceId
    } : this.defaultItem;

    const itemForm = this.formBuilder.group({
      sizeTypeId: [
        initialValues.sizeTypeId,
        Validators.required
      ],
      piecesCount: [
        initialValues.piecesCount,
        [
          Validators.required,
          Validators.min(1),
          Validators.max(999)
        ]
      ],
      weight: [
        initialValues.weight,
        [
          Validators.required,
          Validators.min(1),
          Validators.max(999)
        ]
      ],
      referenceId: [
        initialValues.referenceId,
        Validators.maxLength(75)
      ]
    });

    this.itemList.push(itemForm);
  }

  onRemoveItem(index: number) {
    this.builderActions.onRemoveItemFromOrder(index);
    this.removeItemForm(index);
  }

  removeItemForm(index: number) {
    this.itemList.removeAt(index);
  }

  onEditItem = (index: number, event) => {
    const key = event.target.dataset.key;
    let value = event.target.value;
    if (key === 'piecesCount' || key === 'weight') {
      if (value === '') { value = 1; }
      value = Number.parseInt(value, 0);
      if (value < 1 || value > 999) {
        value = this.minMaxTransform(value);
      }
    }

    // update item in state
    const newItem = {
      ...this.items[index],
      [key]: value
    };
    this.builderActions.onEditItem(newItem, index);
    // update form control
    this.itemList['controls'][index].patchValue({ [key]: value });
  }

  onEditItemNotes(event) {
    const newStop = {
      ...this.stop,
      itemsDescription: event.target.value
    };

    this.builderActions.onEditStop(newStop, this.stopIndex);
  }

  minMaxTransform(value: number) {
    if (value < 1) { value = 1; }
    if (value > 999) { value = 999; }
    return value;
  }

  onSelectItemSize(index: number, event) {
    // event.target.value indexes start at 1
    // subtract 1 to match 0-index array of this.itemsSizes
    const itemSize = this.itemsSizes[event.target.value - 1];
    const newItemObj = {
      ...this.items[index],
      sizeTypeId: itemSize.sizeTypeId,
      weight: itemSize.weight
    };

    // update item in state
    this.builderActions.onEditItem(newItemObj, index);
    // update item weight form control
    this.itemList['controls'][index].patchValue({ weight: itemSize.weight });
  }

  piecesCount(index: number) {
    return this.itemList['controls'][index]['controls']['piecesCount'];
  }

  weight(index: number) {
    return this.itemList['controls'][index]['controls']['weight'];
  }
}
