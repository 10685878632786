import {
    LOAD_SHIPPER_PROFILE_SUCCESS,
    LOAD_SHIPPER_PROFILE_FAIL,
    ShipperAction,
    LOAD_SHIPPER_PROFILE,
    UPDATE_CAN_CREATE_SHIPEMENT,
    SET_INITIAL_STATE,
    UPDATE_HAS_VALID_CC,
} from '../actions/shipper.status.actions';
import {
    LOGOUT_SUCCESS,
    UPDATE_HAS_ORDERS
} from '../../auth/actions/auth.actions';
import { Action } from 'redux';
import { ShipperStatuses } from '../model/model';

export const initialState: ShipperStatuses = {
    isEmailConfirmed: false,
    isUnderTerms: false,
    canCreateShipment: false,
    hasOrders: false,
    suspended: false,
    hasValidCreditCard: false
};

export function shipperStatusReducer(state = initialState, action: Action): any {
    const shipperAction = action as ShipperAction;
    switch (shipperAction.type) {
        case LOAD_SHIPPER_PROFILE: {
            return {
                ...state,
                shipper: shipperAction.payload
            };
        }

        case LOAD_SHIPPER_PROFILE_SUCCESS: {
            const payload = shipperAction.payload;
            return {
                ...state,
                isEmailConfirmed: payload.isEmailConfirmed,
                isUnderTerms: payload.isUnderTerms,
                canCreateShipment: payload.canCreateShipment,
                hasOrders: payload.hasOrders,
                suspended: payload.suspended,
                hasValidCreditCard: payload.hasValidCreditCard
            };
        }

        case LOAD_SHIPPER_PROFILE_FAIL: {
            return {
                ...state,
                isEmailConfirmed: true,
                isUnderTerms: false,
                canCreateShipment: false,
                hasOrders: false,
                suspended: false,
                hasValidCreditCard: false
            };
        }

        case UPDATE_CAN_CREATE_SHIPEMENT: {
            return {
                ...state,
                canCreateShipment: shipperAction.payload
            };
        }

        case UPDATE_HAS_ORDERS: {
            return {
                ...state,
                hasOrders: shipperAction.payload
            };
        }

        case UPDATE_HAS_VALID_CC: {
            return {
                ...state,
                hasValidCreditCard: shipperAction.payload || false
            };
        }

        case LOGOUT_SUCCESS:
        case SET_INITIAL_STATE: {
            return {
                ...initialState
            };
        }

        default: {
            return state;
        }
    }
}

