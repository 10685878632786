import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ElementRef } from '@angular/core';
import { HighlightType, NotificationBucketItemDto } from '../../models/notification.model';
import { UIActions } from '../../../shipment/actions/ui.actions';

export const DEFAULT_PAGE_NUMBER = 1;

@Component({
    selector: 'tuya-notification-panel',
    templateUrl: './notification-panel.component.html',
    styleUrls: ['./notification-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotificationPanelComponent {

    @Input() set notificationList(notifications: Array<NotificationBucketItemDto>) {
        this._notificationList = notifications;
    }

    get notificationList() {
        return this._notificationList;
    }

    @Input() notificationTotalCount = 0;
    @Input() isLoading: boolean;
    @Input() errorMessageKey: string;
    @Output() loadNotificationBucket = new EventEmitter();
    @Output() clearNotificationBucket = new EventEmitter();

    HighlightType: typeof HighlightType = HighlightType;

    public isOpen = false;
    private pageNumber = DEFAULT_PAGE_NUMBER;
    private _notificationList = [];

    constructor(private uIActions: UIActions, private elementRef: ElementRef) {
    }

    onShowPanel() {
        this.loadNotificationBucket.emit(this.pageNumber);
        this.isOpen = true;
    }

    onHidePanel() {
        this.onClearList();
        this.isOpen = false;
    }

    onLoadNextSetOfNotifications() {
        if (this.isOpen && (this.notificationList.length < this.notificationTotalCount)) {
            this.pageNumber++;
            this.loadNotificationBucket.emit(this.pageNumber);
        }
    }

    onOffClick() {
        if (this.isOpen) {
            this.onHidePanel();
        }
    }

    onRefreshNotifications() {
        this.onClearList();
        this.loadNotificationBucket.emit(this.pageNumber);

    }

    onClearList() {
        this.pageNumber = DEFAULT_PAGE_NUMBER;
        this.clearNotificationBucket.emit();
    }

    goToOrderDetails(item: any) {
        this.onOffClick();
        if (item.orderId) {
            this.uIActions.goToOrderDetails({orderId: item.orderId, recurringId: item.recurringOrderId});
        }
    }

    onScroll() {
        const panel = this.elementRef.nativeElement.querySelector('.ps-content-container');
        const top = panel.scrollTop;
        const offset = panel.clientHeight;
        const max = panel.scrollHeight;

        if (top + offset >= max && !this.isLoading) {
            this.onLoadNextSetOfNotifications();
        }
    }
}
