import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { SearchOrderActions } from '../../../home/actions/search-order.actions';
import { select } from '@angular-redux/store';
import { Observable, takeUntil } from 'rxjs';
import { Subject } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HomeActions } from '../../../home/actions/home.actions';

@Component({
  selector: 'tuya-search-filter-view',
  templateUrl: './search-filter-view.component.html',
  styleUrls: ['./search-filter-view.component.scss']
})
export class SearchFilterViewComponent implements OnInit, OnDestroy {

  @select(['home', 'searchOrders', 'searchFilter'])
  readonly searchFilter$: Observable<any>;
  @select(['home', 'searchOrders', 'searchQuery'])
  readonly searchQuery$: Observable<string>;
  @select(['home', 'searchOrders', 'filterViewActive'])
  readonly filterViewActive$: Observable<boolean>;

  draft = false;
  active = false;
  completed = false;

  startDate = '';
  endDate = '';

  // Mask
  // dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  dateForm: any;
  startDateTooltip = '';
  endDateTooltip = '';
  searchFilter = {};

  private searchQuery = '';
  private unsubscriber = new Subject<void>();

  constructor(private searchOrderActions: SearchOrderActions,
              private homeActions: HomeActions,
              private datepipe: DatePipe,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.searchFilter$.pipe(takeUntil(this.unsubscriber)).subscribe(res => {
      this.searchFilter = res;
      this.draft = res['1'] || false;
      this.active = res['2'] || false;
      this.completed = res['3'] || false;
      this.startDate = this.datepipe.transform(res.startDate, 'MM/dd/yyyy');
      this.endDate = this.datepipe.transform(res.endDate, 'MM/dd/yyyy');
    });
    this.searchQuery$.pipe(takeUntil(this.unsubscriber)).subscribe(res => {
      this.searchQuery = res;
    });

    this.dateFormBuilder();
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
    clickout(event: any) {
      if (event.target.getElementsByClassName('searchFilter')[0]) {
        this.searchOrderActions.closeFilter();
      }
  }

  dateFormBuilder() {
    this.dateForm = this.fb.group({
      startDate: [this.startDate],
      endDate: [this.endDate]
    });

    this.onChanges();
  }

  onChanges(): void {
    this.dateForm.valueChanges.takeUntil(this.unsubscriber).subscribe(() => {
      this.onFocusOut(false);
    });
  }

  isValid(userDate: string, conparationDate: Date): boolean {
    const pDate = Date.parse(userDate);
    const newUserDate = new Date(pDate);
    conparationDate.setHours(new Date(pDate).getHours());
    return (!isNaN(pDate) && newUserDate <= conparationDate);
  }

  isValidMask(dt: string[]): boolean {
    if (dt.length === 3 && dt[2].length === 4) {
      return true;
    }
    return false;
  }

  onFocusOut(isFocusOut: boolean) {
    const stDate = this.dateForm.controls.startDate.value;
    const edDate = this.dateForm.controls.endDate.value;

    if (stDate && stDate !== '') {
      const sD = stDate.match(/\d+/g).map(String);
      if (this.isValidMask(sD) || isFocusOut) {
        if (!this.isValid(stDate, new Date()) || sD[2][0] === '0') {
          this.dateForm.controls['startDate'].setErrors({'incorrect': true});
          this.startDateTooltip = 'Start Date must be today’s date or a date in the past.';
        }
      }
    }

    if (edDate && edDate !== '') {
      const eD = edDate.match(/\d+/g).map(String);
      if (this.isValidMask(eD) || isFocusOut) {
        if (!this.isValid(edDate, new Date()) || eD[2][0] === '0') {
          this.dateForm.controls['endDate'].setErrors({'incorrect': true});
          this.endDateTooltip = 'End Date must be today’s date or a date in the past.';
        }
      }
    }

    if (stDate && stDate !== '' && edDate && edDate !== '') {
      const sD = stDate.match(/\d+/g).map(String);
      const eD = edDate.match(/\d+/g).map(String);

      if (this.isValidMask(sD) && this.isValidMask(eD)) {
        if (!this.isValid(stDate, new Date())) {
          this.startDateTooltip = 'Start Date must be today’s date or a date in the past.';
          this.dateForm.controls['startDate'].setErrors({'incorrect': true});
        }
        if (!this.isValid(edDate, new Date())) {
          this.endDateTooltip = 'End Date must be today’s date or a date in the past.';
          this.dateForm.controls['endDate'].setErrors({'incorrect': true});
        }
        if (this.isValid(stDate, new Date()) && this.isValid(edDate, new Date())) {
          if (!this.isValid(stDate, new Date(edDate))) {
            this.endDateTooltip = 'End Date cannot be an earlier date than the Start Date.';
            this.dateForm.controls['endDate'].setErrors({'incorrect': true});
          }
        }
      }
    }
  }

  closeDropdownFilter() {
    this.searchOrderActions.closeFilter();
  }

  setDateHours(dateType: string, val: string): string {
    if (!isNaN(Date.parse(val))) {
      const a = new Date(val);
      dateType === 'startDate' ? a.setHours(0, 0, 0) : a.setHours(18, 59, 59);
      return a.toISOString();
    }
    return '';
  }

  applySearch() {
    const controls = this.dateForm.controls;
    this.homeActions.setPageNumber(1);
    this.searchOrderActions.updateSearchFilter({
      1: this.draft,
      2: this.active,
      3: this.completed,
      startDate: this.setDateHours('startDate', controls['startDate'].value),
      endDate: this.setDateHours('endDate', controls['endDate'].value)
    });
    this.searchOrderActions.searchForAnOrder(this.searchQuery);
    this.searchOrderActions.closeFilter();
  }

  onResetFilter() {
    if (!this.searchFilter['1'] &&
        !this.searchFilter['2'] &&
        !this.searchFilter['3'] &&
        !this.searchFilter['4'] &&
        this.searchFilter['endDate'] === '' && this.searchFilter['startDate'] === '') {
      this.resetFilter();
    } else {
      this.searchOrderActions.resetSearchFilter(false);
      this.searchOrderActions.searchForAnOrder(this.searchQuery);
      this.searchOrderActions.closeFilter();
    }
  }

  resetFilter() {
    this.draft = false;
    this.active = false;
    this.completed = false;
    this.dateForm.controls.startDate.setValue('');
    this.dateForm.controls.endDate.setValue('');
  }

  onCloseFilter() {}

  onFilterChange() {}
}
