import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'tuya-terms-of-service',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

    @Output() tosTermsClick = new EventEmitter();
    @Output() tosPrivacyClick = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    clickTerms() {
        this.tosTermsClick.emit();
    }

    clickPrivacy() {
        this.tosPrivacyClick.emit();
    }
}
