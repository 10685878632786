import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';

import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AdminDriverPortalActions, GET_DRIVERS, ACTIVATE_DIACT_DRIVER, DRIVER_RESET_PASSWORD, ADD_NEW_DRIVER, UPDATE_VEHICLE, ADD_VEHICLE } from '../actions/admin.driver.portal.actions';
import { DriverPortalService } from '../services/driver.potal.service';
import { DriverTablePagination } from '../models/driverportal.model';
import { AlertActions } from 'app/core/actions/alert.actions';
import { AlertType } from 'app/core/models/alert.model';
import { SearchOrderActions } from 'app/home/actions/search-order.actions';

@Injectable()
export class DriverportalEpics {
  constructor(
    private driverPortalAction: AdminDriverPortalActions,
    private driverPortalService: DriverPortalService,
    private searchOrderActions: SearchOrderActions,
    private alertActions: AlertActions
  ) {}

  public createEpic() {
    return combineEpics(
        createDriverLoadEpic(this.driverPortalAction, this.searchOrderActions, this.driverPortalService, this.alertActions),
        createActivateDeactDriverEpic(this.driverPortalAction, this.driverPortalService, this.alertActions),
        createResetPasswordEpic(this.driverPortalAction, this.driverPortalService, this.alertActions),
        createAddNewDriverEpic(this.driverPortalAction, this.driverPortalService, this.alertActions),
        createAddVehicleEpic(this.driverPortalAction, this.driverPortalService, this.alertActions),
        createUpdateVehicleEpic(this.driverPortalAction, this.driverPortalService, this.alertActions)
    );
  }

}

export function createDriverLoadEpic(actions: AdminDriverPortalActions,
                                    searchOrderActions: SearchOrderActions,
                                    service: DriverPortalService, alertActions: AlertActions) {

    return (action$, store) => action$.pipe(
        ofType(GET_DRIVERS),
          switchMap((action: any) => {

              if (action.payload === 'search') {
                searchOrderActions.updateProgressBar(20);
                setTimeout(() => { searchOrderActions.updateProgressBar(45); }, 150);
                setTimeout(() => { searchOrderActions.updateProgressBar(75); }, 250);
              }

              const pagination = store.value.admin.driverPortal.pagination;
              const params: DriverTablePagination = {
                  pageSize: pagination.pageSize,
                  currentPageNumber: pagination.currentPageNumber,
                  searchQuery: pagination.searchQuery
              };
              if (params.searchQuery === '') { delete(params.searchQuery); }

              return service.getDrivers(params).pipe(
                  map(data => actions.getDriversSuccess(data))
                  ,catchError(error => {
                    console.log('error');
                    return of(actions.getDriversFailure(),
                              alertActions.show({error: error, type: AlertType.BadRequest}));
                  }));
          })
    );
}

export function createActivateDeactDriverEpic(actions: AdminDriverPortalActions,
                                          service: DriverPortalService, alertActions: AlertActions) {
  return (action$, store) => action$.pipe(
    ofType(ACTIVATE_DIACT_DRIVER),
      switchMap((action: any) => {
        return service.activateDeactDriverBy(action.payload).pipe(
          mergeMap(data => of(actions.activateDiactSuccess(action.payload),
                              actions.showSnackBar({show: true,
                                                    message: action.payload.isActivate ?
                                                            'Driver Successfully Activated!' :
                                                            'Driver successfully deactivated!'})))
            ,catchError(error => {
              console.log('error');
              return of(actions.activateDiactFailure(),
                        alertActions.show({error: error, type: AlertType.BadRequest}));
        }));
      })
  );
}

export function createResetPasswordEpic(actions: AdminDriverPortalActions,
                                        service: DriverPortalService, alertActions: AlertActions) {
  return (action$, store) => action$.pipe(
    ofType(DRIVER_RESET_PASSWORD),
      switchMap((action: any) => {
        const driverName = service.getDriverNameByDriverId(store, action.payload.driverId);
        return service.resetDriverPasswordBy(action.payload).pipe(
          mergeMap(data => of(actions.resetPasswordSuccess(),
                              actions.showSnackBar({ show: true,
                                                     message: 'Password Successfully Updated!',
                                                     subTitle: `Password was sucessfully reset for <b>${driverName}!</b>`
                                                  })))
            ,catchError(error => {
              console.log('error');
              return of(actions.resetPasswordFailure(),
                        alertActions.show({error: error, type: AlertType.BadRequest}));
        }));
      })
  );
}

export function createAddNewDriverEpic(actions: AdminDriverPortalActions,
                                   service: DriverPortalService, alertActions: AlertActions) {
  return (action$, store) => action$.pipe(
    ofType(ADD_NEW_DRIVER),
      switchMap((action: any) => {

        return service.addNewDriver(action.payload).pipe(
          mergeMap(data => of(actions.addNewDriverSuccess({newDriver: action.payload, driverId: data['DriverId'],
                                                            vehicleId: data['VehicleId']}),
                              actions.showSnackBar({ show: true,
                                                    message: 'Driver Successfully Created!',
                                                  subTitle: `<b>${action.payload.firstname } ${action.payload.lastname }</b> was successfully added.`}),
                              actions.driverModalReact(false)))
            ,catchError(error => {
              console.log('error');
              return of(actions.addNewDriverFailure(),
                        alertActions.show({error: error, type: AlertType.BadRequest}));
        }));
      })
  );
}

export function createAddVehicleEpic(actions: AdminDriverPortalActions,
                                   service: DriverPortalService, alertActions: AlertActions) {
  return (action$, store) => action$.pipe(
    ofType(ADD_VEHICLE),
      switchMap((action: any) => {
        return service.addDriverVehicle(action.payload).pipe(
          mergeMap((data: any) => {
            const paloadWithResponse = {...action.payload};
            paloadWithResponse.id = data.vehicleId;

            const driverName = service.getDriverNameByDriverId(store, action.payload.driverId);

            return of(actions.addDriverVehicleSuccess(paloadWithResponse),
                              actions.showSnackBar({ show: true,
                                                    message: 'Vehicle Successfully Added!',
                                                    subTitle: `The vehicle for <b>${driverName}</b> was successfully added!`}),
                              actions.driverModalReact(false));
            })
            ,catchError(error => {
              console.log('error');
              return of(actions.addDriverVehicleFailure(),
                        alertActions.show({error: error, type: AlertType.BadRequest}));
        }));
      })
  );
}

export function createUpdateVehicleEpic(actions: AdminDriverPortalActions,
                                   service: DriverPortalService, alertActions: AlertActions) {
  return (action$, store) => action$.pipe(
    ofType(UPDATE_VEHICLE),
      switchMap((action: any) => {
        const st = {...store};
        const driverName = service.getDriverName(st.value.admin.driverPortal.drivers, action.payload.id);

        return service.updateVehicle(action.payload).pipe(
          mergeMap(data => of(actions.updateDriverVehicleSuccess(action.payload),
                              actions.showSnackBar({ show: true,
                                                     message: 'Vehicle Successfully Updated!',
                                                     subTitle: `The vehicle for <b>${driverName}</b> was successfully updated!` }),
                              actions.driverModalReact(false)))
            ,catchError(error => {
              console.log('error');
              return of(actions.updateDriverVehicleFailure(),
                        alertActions.show({error: error, type: AlertType.BadRequest}));
        }));
      })
  );
}

