import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, TimeoutError, throwError } from 'rxjs';
import { timeout, tap, catchError } from 'rxjs/operators';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';

@Injectable()
export class NetworkTimeoutInterceptor implements HttpInterceptor {

  constructor(private alertActions: AlertActions) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // timeout time is currently set to 60 seconds to prevent errors in dev and qa environments
    // this will be updated when backend performance is improved and the ideal timeout time is
    // determined based on performance
    const timeoutTime = 60 * 1000;

    return next.handle(request).pipe(
      timeout(timeoutTime),
      tap(() => {}, err => {
        if (err.statusText === 'Unknown Error') {
          this.alertActions.show({ type: AlertType.Unknown });
          this.alertActions.turnOffAllSpinners();
        }
        if (err instanceof TimeoutError) {
          console.error(`Timeout Error: ${request.url}`);
          this.alertActions.show({ type: AlertType.Timeout });
          this.alertActions.turnOffAllSpinners();
        }
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
