import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { select } from '@angular-redux/store';
import { ProfilesSelectors } from 'app/profiles/selectors/profiles.selectors';
import { StateDto } from 'app/core/models/dto';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { VehicleDTO, DriverModalModeEnum } from 'app/admin/models/driverportal.model';

@Component({
  selector: 'tuya-driver-modal',
  templateUrl: './driver-modal.component.html',
  styleUrls: ['./driver-modal.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '480px', width: '452px' })),
      state('out', style({ height: '700px', width: '452px' })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})
export class DriverModalComponent implements OnInit, OnDestroy {

  @select(['admin', 'driverPortal', 'driverModal', 'isDriverSubmitLoading'])
  readonly isLoading$: Observable<boolean>;
  @select(['admin', 'driverPortal', 'driverModal', 'mode'])
  readonly modalMode$: Observable<DriverModalModeEnum>;

  // satates from redux store
  @select(ProfilesSelectors.statesSelector)
  readonly states$: Observable<StateDto[]>;

  public driverModalModeEnum = DriverModalModeEnum;

  // declering forms
  driverForm: UntypedFormGroup;
  vehicleForm: UntypedFormGroup;

  // ngMat forn error mathcer
  // matcher = new MyErrorStateMatcher();
  // declaring initial title and showBackButton
  title$ = new BehaviorSubject('Add New Driver');
  showBackButton$ = new BehaviorSubject(false);
  modalModeForSize = 'out';
  selectionIndx = 0;

  serviceAreas = [
    { id: 1, name: 'Houston' },
    { id: 2, name: 'Dallas' },
    { id: 4, name: 'San Antonio' }
  ];

  vehicles = [
    { id: 1, name: 'Sedan' },
    { id: 2, name: 'SUV' },
    { id: 3, name: 'Pickup' },
    { id: 4, name: 'VAN' }
  ];

  // stepper ngMat component
  @ViewChild('stepper', { static: false }) private driverStepper: MatStepper;

  constructor(private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) private data) {
  }

  ngOnInit() {
    if (this.data.driver) {
      this.initForm(this.data.driver.vehicle);
      this.title$.next(this.data.title);
    } else {
      this.initForm(null);
    }
    this.modalMode$.subscribe(res => {
      if (res === DriverModalModeEnum.AddVehicle || res === DriverModalModeEnum.UpdateVehicle) {
        this.modalResize('in');
      }
    });
  }

  // on destroy componenvt unsubscribe
  ngOnDestroy() { }

  // init Driver and Vehicle form
  initForm(vehicle: VehicleDTO) {
    this.driverForm = this.formBuilder.group({
      driverData: this.formBuilder.group({
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        physicalAddress: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zipCode: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        emailAddress: ['', Validators.required],
        serviceArea: ['', Validators.required],
        cxtNumber: ['', Validators.required],
        active: [false],
        id: [this.data.driver ? this.data.driver.id : null]
      }),
      vehicleData: this.formBuilder.group({
        make: [vehicle ? vehicle.make : '', Validators.required],
        model: [vehicle ? vehicle.model : '', Validators.required],
        vehicleType: [vehicle ? vehicle.vehicleType : null, Validators.required],
        id: [vehicle ? vehicle.id : null],
        driverId: ['']
      })
    });
  }

  // return to previous form - Add New Driver form
  onGoBack(): void {
    if (this.selectionIndx !== this.driverStepper.selectedIndex) {
      this.selectionIndx = this.driverStepper.selectedIndex;
      this.modalResize('out');
      this.title$.next('Add New Driver');
      this.showBackButton$.next(false);
      this.driverStepper.previous();
    }
  }

  // function sets modalResize type / sets animations state name
  modalResize(mode: string): void {
    this.modalModeForSize = mode;
  }

  onDriverSubmit(): void {
    if (this.driverForm.controls.driverData.valid) {
      this.selectionIndx = this.driverStepper.selectedIndex;
      this.modalResize('in');
      this.title$.next('Add Vehicle');
      this.showBackButton$.next(true);
    }
  }

  selectionChan(ev: StepperSelectionEvent): void {
    ev.selectedIndex === 1 ? this.onDriverSubmit() : this.onGoBack();
  }
}