<tuya-simple-autocomplete
            [isRequired]="true"
            [disable]="isControlDisabled"
            [className]="className"
            [placeholder]="placeholder"
            [initialValue]="stop.address.addressLine"
            [searchResults]="addressAutocompletFoundItems$ | async"
            [isLoading]="addressAutoCompleteIsLoading"
            (loadSearchResults)="onRequestSearchResults($event)"
            [focusAndClear]="focusAndClear"
            (emitFocusIn)="focusIn()"
            (emitFocusOut)="focusOut($event)"
            [isInvalid]="displayAddressSearchError"
            (selectValue)="onSelectStopItem($event)"
            [isInline]="true"
            (keyDown)="onKeyDown($event)">
</tuya-simple-autocomplete>
