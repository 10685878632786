import { IAppState } from '../../store/model';
import { UserProfile } from '../../auth/model/model';
import { map, Observable } from 'rxjs';
import { transformStop2AutocompleteItem } from '../transforms/builder.transforms';
import { AutocompleteItem } from '../../core/models/autocomplete-item.model';
import { StateDto, AddressDto } from '../../core/models/dto';
import { StopDto } from '../models/stop.model';
import { OrderItemBase } from '../models/item.model';
import { DriverAssignmentVO, PoolAssignmentDto, AssignmentsDto } from '../models/assignments.model';
import { OrderDto } from '../../builder/models/order.model';

export function getAddressAutocompleteItems(stops: StopDto[], states: StateDto[]): AutocompleteItem[] {
    return stops.map((stop, index) => {
        return transformStop2AutocompleteItem(stop, index, states);
    });
}

export function getAddress(cu$: Observable<UserProfile>): Observable<AddressDto> {
    return cu$.pipe(map(cu => {
        if (!cu) { return <AddressDto>{}; }
        return cu.shipperProfile.businessEntity.physicalAddress;
    }));
}

export class BuilderSelectorTransforms {
    /**
     * Transform objects when selecting driver search results and selections so it matches shape expected by
     * the search-select component.
     * @param driver DriverAssignmentDto
     */
    public static driverSearchResultTransform(driver): DriverAssignmentVO {
        // create new object so we don't mutate state in the store.
        const returnDriver = Object.assign({}, driver);
        returnDriver.name = driver.fullName;
        returnDriver.id = driver.userAccountId;
        return returnDriver;
    }

    public static orderDtoTransform(order): OrderDto {
        const returnOrder = Object.assign({}, order);
        returnOrder.orderItems.map(item => item.stops = order.stops);
        delete returnOrder.stops;
        return returnOrder;
    }
}

export class BuilderSelectors {
    public static getItems = (state: IAppState): OrderItemBase[] => state.builder.order.orderItems;
    public static getOrderId = (state: IAppState): number => state.builder.order.id;
    public static getStops = (state: IAppState): StopDto[] => state.builder.order.stops;
    public static getSummary = (state: IAppState): Object => state.builder.order.orderSummary;
    public static getPrice = (state: IAppState): number => state.builder.order.total;
    public static getUserId = (state: IAppState): any => state.builder.order.shipperContactId;
    public static getOfferType = (state: IAppState): number => state.builder.order.offerType;
    public static getDriverSearchTerm = (state: IAppState): string => state.builder.ui.driverSelection.driver.searchTerm;
    public static getPoolSearchTerm = (state: IAppState): string => state.builder.ui.driverSelection.pool.searchTerm;
    public static getOrderCode = (state: IAppState): string => state.builder.ui.orderCode;
    public static getOrderEditMode = (state: IAppState): number => state.builder.ui.orderEditMode;
    public static getDriverSearchResults = (state: IAppState): Array<DriverAssignmentVO> => {
        return state.builder.ui.driverSelection.driver.searchResults.map(searchResult =>
            BuilderSelectorTransforms.driverSearchResultTransform(searchResult));
    }
    public static getPoolSearchResults = (state: IAppState): Array<any> => state.builder.ui.driverSelection.pool.searchResults;
    public static getDriverSelections = (state: IAppState): Array<DriverAssignmentVO> | null => {
        if (state.builder.order.assignments) {
            return state.builder.order.assignments.drivers.map(driver => BuilderSelectorTransforms.driverSearchResultTransform(driver));
        } else {
            return null;
        }
    }
    public static getPoolSelections = (state: IAppState): Array<PoolAssignmentDto> | null => {
        if (state.builder.order.assignments) {
            return state.builder.order.assignments.pools;
        } else {
            return null;
        }
    }
    public static getTimeSelections = (state: IAppState): Array<any> => state.builder.ui.timeSelections;
    public static getCurrentStep = (state: IAppState): number => state.builder.ui.accordion.currentStep;
    public static getReferenceID = (state: IAppState): string => state.builder.order.referenceId;
    public static getWaitTimeValue = (state: IAppState): boolean => state.builder.ui.waitTimePassed;
    public static getIsInitPrice = (state: IAppState): boolean => state.builder.ui.editOrder.isInitPrice;
    public static getStatusId = (state: IAppState): number => state.builder.order.statusId;
    public static isScheduledOrder = (state: IAppState): boolean => state.builder.order.isScheduledOrder;
    public static getSubmitOrder = (state: IAppState): boolean => state.builder.order.submitOrder;
    public static scheduledOrderOfferTime = (state: IAppState): string => state.builder.order.scheduledOrderOfferTime;
    public static getIsExtendable = (state: IAppState): boolean => state.builder.order.isExtendable;
    public static getOrderDto = (state: IAppState): OrderDto => BuilderSelectorTransforms.orderDtoTransform(state.builder.order);
    public static getIsLoading = (state: IAppState): boolean => state.builder.ui.isLoading;
    public static getIsDuplicateMode = (state: IAppState): boolean => state.builder.ui.isDuplicateMode;
    public static getSubmitErrors = (state: IAppState): object => state.builder.ui.submitErrors;
    public static getAssignmentsDto = (state: IAppState): AssignmentsDto => {
        let assignments = null;
        if (state.builder.order.assignments) {
            assignments = state.builder.order.assignments;
        }
        if (assignments) {
            assignments.orderId = state.builder.order.id;
        }
        return assignments;
    }
    public static getVehicleTypeId = (state: IAppState): string => state.builder.order.vehicleType && state.builder.order.vehicleType.id;
    public static getIsEditMode = (state: IAppState): boolean => state.builder.ui.isEditMode;
    public static getOriginalPrice = (state: IAppState): number => state.builder.ui.editOrder.price;
    public static getIsEditOrderLoaded = (state: IAppState): boolean => state.builder.ui.isEditOrderLoaded;
    public static getIsNavPromptRequired = (state: IAppState): boolean => state.builder.ui.navPromptRequired;
    public static getRoutificError = (state: IAppState): boolean => state.builder.ui.routificError;
 }
