<div
  class="builder-accordion">

  <button
    *ngIf="stepIndex < currentStep"
    class="builder-accordion__edit"
    (click)="onEditButtonClick()">
      {{ 'builderSummary.edit' | translate }}
  </button>

  <div
    [ngClass]="{
    'builder-accordion__container': true,
    'builder-accordion__container--expanded': expanded}">
    <ng-content class="builder-accordion--content"></ng-content>

    <tuya-builder-submit-error
      *ngIf="submitErrors && submitErrors[stepIndex]"
      [message]="'validation.' + submitErrors[stepIndex] | translate">
    </tuya-builder-submit-error>

    <button
      *ngIf="currentStep < totalSteps"
      class="builder-accordion__button"
      [ngClass]="{ 'builder-accordion__button--disabled': disabled }"      
      (click)="onNextButtonClick(currentStep)"
      [disabled]="disabled"
      >
      {{ 'builderInput.next' | translate }}
    </button>

  </div>
</div>
