import { Component, OnInit } from '@angular/core';
import { select, select$ } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { AuthActions } from '../../actions/auth.actions';
import { BehaviorSubject } from 'rxjs';
import { PasswordValidationVO } from '../../model/model';
import { getPasswordResetTokenExists } from '../../selectors/auth.selectors';

@Component({
  selector: 'tuya-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  isUserNotValid = new BehaviorSubject<boolean>(false);

  @select(['auth', 'error'])
  readonly currentUserError$: Observable<boolean>;
  @select(['auth', 'isLoading'])
  readonly isLoading$: Observable<boolean>;

  @select(['auth', 'forgotPasswordRequestedEmail'])
  readonly forgotPasswordRequestedEmail$: Observable<string | null>;

  @select(['auth', 'resetPasswordValidationResult'])
  readonly resetPasswordValidationResult$: Observable<PasswordValidationVO | null>;

  @select$(['auth'], getPasswordResetTokenExists)
  readonly passwordResetTokenExists$: Observable<boolean>;

  constructor(private authActions: AuthActions) {
  }

  ngOnInit() {
      this.currentUserError$.subscribe(this.isUserNotValid);
  }

  resetPassword(password: string) {
      // this.authActions.validateNewPassword(password);
      this.authActions.requestResetPassword(password);
  }

  requestForgotPassword(email: string) {
      this.authActions.requestForgotPassword(email);
  }
}
