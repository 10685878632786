import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';

import { ShipmentActions } from '../actions/shipment.actions';

import { Stop } from '../models/order.model';
import { StopPickupTimeUtils } from '../utils/stop-pickup-time.utils';
import { getRelatedStop } from '../selectors/shipment.selectors';
import { PickupDateType } from '../../core/models/shipment.model';
import {
    OrderStopsActions,
    SHIPMENT_IS_DIRECT_ORDER_UPDATE,
    SHIPMENT_SET_DEFAULT_DATE_TIME_FOR_STOP_LIST
} from '../actions/order-stops.actions';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class OrderStopsEpics {
    constructor(private orderStopsActions: OrderStopsActions,
                private shipmentActions: ShipmentActions) {
    }

    public createEpic() {
        return combineEpics(
            createUpdateIsDirectOrder(this.orderStopsActions, this.shipmentActions),
            createSetDefaultDateTimeForStops(this.orderStopsActions, this.shipmentActions)
        );
    }
}

export function createUpdateIsDirectOrder(actions: OrderStopsActions, shipmentActions: ShipmentActions) {
    return (action$, store) => action$.pipe(
        ofType(SHIPMENT_IS_DIRECT_ORDER_UPDATE),
        switchMap((action: any) => {
            if (!action.payload.isDirect) {
                // if order switched from isDirect assign default time values for stops
                return of(actions.setDefaultDateTimeForStopList());
            } else {
                if (action.payload.isUpdateOrder) {
                    return of(shipmentActions.shipmentOrderUpdate(), actions.saveInvalidTimeframesStops([]));
                }
                // if order sets to direct - clear invalid timeframes
                return of(actions.saveInvalidTimeframesStops([]));
            }
        })
    );
}

// Default easy hour delivery
export function createSetDefaultDateTimeForStops(actions: OrderStopsActions, shipmentActions: ShipmentActions) {
    return (action$, store) => action$.pipe(
        ofType(SHIPMENT_SET_DEFAULT_DATE_TIME_FOR_STOP_LIST),
        switchMap((action) => {
            const stopList = store.value.shipment.order.stopList,
                relatedStop = getRelatedStop(store.value),
                updatedStopList = stopList.map((stop: Stop) => {
                    const isMainStop = relatedStop && relatedStop.id === stop.id,
                        dateObj = StopPickupTimeUtils.getTime(stop, (isMainStop ? undefined : <Stop>relatedStop));
                    stop.pickupNoLaterThan = dateObj.noLaterThanTime;
                    stop.pickupNoEarlierThan = dateObj.noEarlierThanTime;
                    stop.pickupDateTypeId = PickupDateType.Today;
                    return stop;
                });
            return of(actions.saveDefaultDateTimeForStopList(updatedStopList), shipmentActions.shipmentOrderUpdate());
        })
    );
}




