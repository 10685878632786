import { Component, Input, OnInit } from '@angular/core';

import { EventItem } from '../../model/order-events.model';
import { OrderExpandedView } from '../../model/my-orders.model';

@Component({
    selector: 'tuya-events-log',
    templateUrl: './events-log.component.html',
    styleUrls: ['./events-log.component.scss']
})
export class EventsLogComponent implements OnInit {

    @Input() orderEventsList: Array<EventItem>;
    @Input() errorMessageKey: string;
    @Input() isLoading: boolean;
    @Input() isShipperSuspended: boolean;
    @Input() statusId: number;

    constructor() { }

    ngOnInit() {
    }
}
