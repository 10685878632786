<div class="stop-list-wrapper">
    <div class="stop-list">
        <ng-container *ngFor="let stop of filterStopList; trackBy: trackByFn">
            <tuya-stop-line
                    *ngIf="!isShowStopLineOnEnd(stop)"
                    [stop]="stop"
                    [hasInvalidAddress]="hasInvalidAddress"
                    [disabled]="ifDisabledInProgress()"
                    [isOptionalStopLine]="false"
                    [canDelete]="canDelete(stop)"
                    [selectedStop]="selectedStop"
                    [isDirectOrder]="isDirectOrder"
                    [isInvalidTimeframe]="isInvalidTimeframe(stop)"
                    [stopItems]="orderItems | filterItemsByStopId: stop.id"
                    [isStopsRequiredFieldsProvided]="isStopsRequiredFieldsProvided"
                    [isLastOfMultipleStops]="OrderHelper.isLastStopByType(stop, isStopsRequiredFieldsProvided,
                                        shipmentType, filterStopList)"
                    [stopListLength] = "filterStopList.length"
                    (selectStop)="onStopSelect($event)"
                    (removeStop)="onRemoveStop($event)"
                    (editStop)="onEditStop($event)"
                    (updateSuiteNumber)="onUpdateSuiteNumber($event)"
            ></tuya-stop-line>
        </ng-container>

        <!-- Stop Line Input for second required stop -->
        <ng-template [ngIf]="!OrderHelper.isDeliveryStopExist(stopList)" >
            <tuya-stop-line
                [shipmentType]="shipmentType"
                [isOptionalStopLine]="false"
            > </tuya-stop-line>
        </ng-template>
        <!-- Stop Line Input for additional optional stops - available when all details for previous entered stops is provided -->
        <ng-template [ngIf]="filterStopList.length > 1 && isStopsRequiredFieldsProvided && editMode !== OrderEditMode.PartiallyCompleted" >
            <tuya-stop-line [shipmentType]="shipmentType" [isOptionalStopLine]="true"> </tuya-stop-line>
        </ng-template>

        <!-- Last stop is Delivery for Collection Type, or Return for Distribution Type -->
        <ng-template [ngIf]="getLastStop().id" >
            <tuya-stop-line
                    [stop]="getLastStop()"
                    [hasInvalidAddress]="hasInvalidAddress"
                    [isOptionalStopLine]="false"
                    [selectedStop]="selectedStop"
                    [stopItems]="orderItems | filterItemsByStopId: getLastStop().id"
                    [isStopsRequiredFieldsProvided]="isStopsRequiredFieldsProvided"
                    [canDelete]="canDelete(getLastStop())"
                    [disabled]="ifDisabledInProgress()"
                    [isInvalidTimeframe]="isInvalidTimeframe(getLastStop())"
                    [isDirectOrder]="isDirectOrder"
                    (selectStop)="onStopSelect($event)"
                    (removeStop)="onRemoveStop($event)"
                    (editStop)="onEditStop($event)"
                    (updateSuiteNumber)="onUpdateSuiteNumber($event)"
            ></tuya-stop-line>
        </ng-template>
    </div>
</div>
