import { Injectable } from '@angular/core';
import { CoreEpics } from '../core/epics/core.epics';
import { PoolsEpics } from '../core/epics/pools.epics';
import { AuthEpics } from '../auth/epics/auth.epics';
import { RegisterEpics } from '../register/epics/register.epics';
import { ShipmentEpics } from '../shipment/epics/shipment.epics';
import { ShipperEpics } from '../shipper/epics/shipper.profile.epic';
import { DeliveryStopEpics } from '../shipment/epics/delivery-stop.epics';
import { MyOrdersEpics } from '../home/epics/my-orders.epics';
import { ProfilesEpics } from './../profiles/epics/profiles.epics';
import { GeneralOfferEpics } from '../shipment/epics/general-offer.epics';
import { AddressAutocompleteEpics } from '../shipment/epics/address-autocomplete.epics';
import { TrackingEpics } from '../tracking/epics/tracking.epics';
import { ForgotPasswordEpics } from '../auth/epics/forgot-password.epics';
import { OrderStopsEpics } from '../shipment/epics/order-stops.epic';
import { ShipmentEditEpics } from '../shipment-edit/epics/shipment-edit.epics';
import { OrderEventsEpics } from '../home/epics/order-events.epics';
import { NotificationEpics } from '../core/epics/notification.epics';
import { BillingEpics } from '../profiles/epics/billing.epics';
import { PaymentsEpics } from '../shipment/epics/payments.epics';
import { WizardStepperEpics } from '../shipment/epics/wizard-stepper.epics';
import { ShipmentOrderEpics } from '../shipment/epics/shipment-order.epics';
import { AdminEpics } from '../admin/epics/admin.epics';
import { DriverportalEpics } from '../admin/epics/driverportal.epics';
import { BuilderEpics } from '../builder/epics/builder.epics';
import { combineEpics } from 'redux-observable';


@Injectable()
export class RootEpics {
    constructor(private coreEpic: CoreEpics,
                private poolsEpic: PoolsEpics,
                private notificationEpic: NotificationEpics,
                private authEpic: AuthEpics,
                private adminEpic: AdminEpics,
                private driverPortalEpics: DriverportalEpics,
                private trackingEpic: TrackingEpics,
                private forgotPasswordEpic: ForgotPasswordEpics,
                private registerEpic: RegisterEpics,
                private shipmentEpic: ShipmentEpics,
                private shipmentOrderEpic: ShipmentOrderEpics,
                private paymentsEpics: PaymentsEpics,
                private wizardStepperEpics: WizardStepperEpics,
                private shipmentEditEpic: ShipmentEditEpics,
                private orderStopsEpic: OrderStopsEpics,
                private shipperEpic: ShipperEpics,
                private generalOfferEpic: GeneralOfferEpics,
                private deliveryStopEpic: DeliveryStopEpics,
                private addressAutocompleteEpic: AddressAutocompleteEpics,
                private myOrdersEpic: MyOrdersEpics,
                private profilesEpics: ProfilesEpics,
                private billingEpics: BillingEpics,
                private orderEventsEpics: OrderEventsEpics,
                private builderEpics: BuilderEpics) {
    }

    public createEpics() {
        return combineEpics(
            this.coreEpic.createEpic(),
            this.poolsEpic.createEpic(),
            this.notificationEpic.createEpic(),
            this.trackingEpic.createEpics(),
            this.authEpic.createEpic(),
            this.forgotPasswordEpic.createEpic(),
            this.registerEpic.createEpic(),
            this.shipmentEpic.createEpic(),
            this.shipmentOrderEpic.createEpic(),
            this.wizardStepperEpics.createEpic(),
            this.paymentsEpics.createEpic(),
            this.shipmentEditEpic.createEpic(),
            this.orderStopsEpic.createEpic(),
            this.shipperEpic.createEpic(),
            this.deliveryStopEpic.createEpic(),
            this.generalOfferEpic.createEpic(),
            this.addressAutocompleteEpic.createEpic(),
            this.myOrdersEpic.createEpic(),
            this.profilesEpics.createEpic(),
            this.billingEpics.createEpic(),
            this.orderEventsEpics.createEpic(),
            this.adminEpic.createEpic(),
            this.driverPortalEpics.createEpic(),
            this.builderEpics.createEpic()
        );
    }
}
