<div class="tracking-info-container">
    <header>        
        <tuya-tracking-stepper [trackingInfo]="trackingInfo"></tuya-tracking-stepper>
    </header>
    <main>
        <tuya-tracking-info-time [trackingInfo]="trackingInfo"></tuya-tracking-info-time>
        <tuya-tracking-order-info [trackingInfo]="trackingInfo"></tuya-tracking-order-info>        
        <tuya-tracking-driver-info [trackingInfo]="trackingInfo"></tuya-tracking-driver-info>
        <tuya-stop-events-log [trackingInfo]="trackingInfo"></tuya-stop-events-log>
    </main>
    <footer>        
        <tuya-tracking-stop-info [trackingInfo]="trackingInfo"></tuya-tracking-stop-info>             
    </footer>
</div>
