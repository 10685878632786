<div class="details-section">
    <p [translate]="'tuya-delivery-stop.tuya-item-selection.text'">
    </p>
    <div class="rows-container">
        <div *ngFor="let item of _filterItems; let itemIdx = index" class="row items-row">
            <div class="col col-sm-2" [ngClass]="{ 'has-error': piecesCount.invalid }"
                 tooltip="{{'tuya-shipment.unable-change' | translate}}"
                 containerClass="tuya-tooltip"
                 placement="top"
                 container="body" [isDisabled]="!disabled">
                <input type="number"
                       class="piecesCount"
                       maxlength="2"
                       [min]="0"
                       [max]="99"
                       [attr.min]="0"
                       [attr.max]="99"
                       [disabled]="disabled"
                       pattern="^$|^([1-9]|[1-9][0-9])$"
                       required
                       [(ngModel)]="item.piecesCount"
                       name="piecesCount"
                       #piecesCount
                       value="1"
                       (focusout)="onItemsChanged()"
                       /><span class="label">x</span>
            </div>
            <div class="col col-sm-4 select"
                 tooltip="{{'tuya-shipment.unable-change' | translate}}"
                 placement="top"
                 container="body"
                 containerClass="tuya-tooltip"
                 [isDisabled]="!disabled">
                <div class="btn-group" dropdown [isDisabled]="disabled">
                    <button dropdownToggle type="button" class="btn dropdown-toggle">
                        <div class="icon {{getSizeById(item.sizeId).name.toLowerCase()}}"></div>
                        <div>
                            {{getSizeById(item.sizeId).name}} <br/>
                            <small>{{getSizeById(item.sizeId).description}}</small>
                            <span class="caret"></span>
                        </div>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" *ngFor="let sizeItem of itemsSizes | filterBySizeId:item.sizeId">
                            <a (click)="onItemSizeUpdate(itemIdx, sizeItem);">
                                <div class="icon {{sizeItem.name.toLowerCase()}}"></div>
                                <div>
                                    {{sizeItem.name}}<br/>
                                    <small>{{sizeItem.description}}</small>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col col-sm-3" [ngClass]="{ 'has-error': weight.invalid }"
                 tooltip="{{'tuya-shipment.unable-change' | translate}}"
                 placement="top"
                 containerClass="tuya-tooltip"
                 container="body" [isDisabled]="!disabled">
                <input type="number"
                       class="weight"
                       maxlength="4"
                       [disabled]="disabled"
                       [attr.min]="1"
                       [min]="1"
                       pattern="^[1-9]\d{0,3}$"
                       required
                       [(ngModel)]="item.weight"
                       name="weight"
                       #weight
                       value="1"
                       (focusout)="onItemsChanged()"
                       /><span class="label">{{'tuya-delivery-stop.tuya-item-selection.weight' | translate }}</span>
            </div>
            <div class="col col-sm-3">
                <input type="text"
                       [(ngModel)]="(item.reference || {}).name"
                       name="referenceId"
                       placeholder="{{'tuya-delivery-stop.tuya-item-selection.reference-id' | translate }}"/>
                <span class="label">id</span>
            </div>
            <div class="delete-btn-wrapper"
                 tooltip="{{'tuya-shipment.unable-change' | translate}}"
                 placement="top"
                 containerClass="tuya-tooltip"
                 container="body" [isDisabled]="!disabled">
                <button *ngIf="_filterItems.length > 1" (click)="deleteItem(itemIdx)" [disabled]="disabled">x</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 no-padding item-add-button-tooltip">
            <div tooltip="{{'tuya-shipment.unable-change' | translate}}"
                  placement="top"
                  containerClass="tuya-tooltip"
                  container="body" [isDisabled]="!disabled">
                <input type="button" class="btn-link add" [disabled]="disabled" (click)="onAddItem()"/>
            </div>
        </div>
    </div>
    <a (click)="isCollapsed = !isCollapsed" class="btn-link add-info-expanded" [ngClass]="{expanded: !isCollapsed}">
        <span [translate]="'tuya-shipment.tuya-stop-details.tell-us-about-items'"></span>
    </a>
    <div [collapse]="isCollapsed" class="row">
        <div class="col-sm-12 form-group no-padding"
             tooltip="{{'tuya-shipment.unable-change' | translate}}"
             containerClass="tuya-tooltip"
             [isDisabled]="!disabledDescription"
             placement="top"
             container="body">
            <textarea class="form-control" rows="3" maxlength="500" name="description"
                      [formControl]="itemDescriptionCtrl"
                      [disabled]="disabledDescription"
                      [ngClass]="{ 'filled': stop.itemsDescription }"
                      [(ngModel)]="stop.itemsDescription"></textarea>
            <span class="reminder">{{'tuya-delivery-stop.tuya-item-selection.description' | translate }}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-8 checkbox">
            <label class="control control-checkbox">
                <span [translate]="'tuya-delivery-stop.tuya-item-selection.hazardCheckbox'"></span>
                <input type="checkbox" [(ngModel)]="isChecked"  (ngModelChange)="onUpdateHazardCheckbox($event)" />
                <div class="control_indicator_checkbox"></div>
            </label>
          </div>
    </div>

    <ng-template #hazardous>
        <div class="modal-content">
            <div class="modal-body">
                <h3 [translate]="'tuya-delivery-stop.tuya-item-selection.hazardous-modal.not-permitted'"></h3>
                <button type="button" class="btn btn-primary" (click)="hazardousMaterialRef.hide()"
                  [translate]="'tuya-delivery-stop.tuya-item-selection.hazardous-modal.ok-btn'"></button>
            </div>
        </div>
    </ng-template>
</div>
