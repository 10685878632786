<div *ngIf="currentManageCompanyTab === ManageCompanyTab.BillingContact">

    <form name="billingInfoForm" autocomplete="off" (ngSubmit)="!billingInfoForm.invalid && saveBillingInfoForm()"
        #billingInfoForm="ngForm" novalidate>
        <div class="row">
            <p [translate]="'tuya-company.billing-contact.info'"></p>
            <div class="row">
                <div class="form-group col-sm-6" [ngClass]="{ 'has-error': firstName.dirty && !firstName.valid }">
                    <label for="firstName" [translate]="'tuya-company.billing.first-name'"></label>
                    <input placeholder="{{'tuya-company.billing.first-name' | translate }}" type="text"
                        class="form-control" name="firstName" [(ngModel)]="model.billingFirstName" #firstName="ngModel"
                        maxlength="50" [pattern]="namePattern" trim="blur" [disabled]="suspended" required />
                    <div *ngIf="firstName.dirty && !!firstName.errors?.required" class="help-block"
                        [translate]="'tuya-profiles.error.firstName'"></div>
                    <div *ngIf="firstName.dirty && !!firstName.errors?.pattern" class="help-block"
                        [translate]="'tuya-profiles.error.first-name-pattern'"></div>
                </div>
                <div class="form-group col-sm-6" [ngClass]="{ 'has-error': lastName.dirty && !lastName.valid }">
                    <label for="lastName" [translate]="'tuya-company.billing.last-name'"></label>
                    <input placeholder="{{'tuya-company.billing.last-name' | translate }}" type="text" name="lastName"
                        class="form-control" [(ngModel)]="model.billingLastName" #lastName="ngModel" maxlength="50"
                        [pattern]="namePattern" trim="blur" [disabled]="suspended" required />
                    <div *ngIf="lastName.dirty && !!lastName.errors?.required" class="help-block"
                        [translate]="'tuya-profiles.error.lastName'"></div>
                    <div *ngIf="lastName.dirty && !!lastName.errors?.pattern" class="help-block"
                        [translate]="'tuya-profiles.error.last-name-pattern'"></div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-6"
                    [ngClass]="{ 'has-error': phoneNumber.dirty && (!isPhoneNumberValid || isPhoneEmpty()) }">
                    <label [translate]="'tuya-company.billing.phone'"></label>
                    <input placeholder="{{'tuya-company.billing.phone' | translate }}" type="text" class="form-control"
                        name="phoneNumber" [(ngModel)]="model.billingPhone" (ngModelChange)="isPhoneValid()"
                        #phoneNumber="ngModel" [textMask]="{mask: phoneMask}" [disabled]="suspended" required />
                    <div *ngIf="phoneNumber.dirty && !isPhoneNumberValid && !isPhoneEmpty()" class="help-block"
                        [translate]="'tuya-profiles.error.phone-pattern'"></div>
                    <div *ngIf="phoneNumber.dirty && isPhoneEmpty()" class="help-block"
                        [translate]="'tuya-profiles.error.phone'"></div>
                </div>
                <div class="form-group col-sm-6" [ngClass]="{ 'has-error': email.dirty && !email.valid }">
                    <label for="suiteNumber" [translate]="'tuya-company.billing.email'"></label>
                    <input placeholder="{{'tuya-company.billing.email' | translate }}" type="email" class="form-control"
                        name="email" [(ngModel)]="model.billingEmail" #email="ngModel" [pattern]="emailPattern"
                        [disabled]="suspended" required />
                    <div *ngIf="email.dirty && !!email.errors?.required" class="help-block"
                        [translate]="'tuya-profiles.error.email'"></div>
                    <div *ngIf="email.dirty && !!email.errors?.pattern" class="help-block"
                        [translate]="'tuya-profiles.error.email-pattern'"></div>
                </div>
            </div>
            <div class="form-group col-sm-9 form-checkbox">
                <label class="control control-checkbox">
                    <span [translate]="'tuya-company.general.use-company'"></span>
                    <input type="checkbox" class="use-company" title="Use Company Address" name="useCompany"
                        [disabled]="suspended" [(ngModel)]="isChecked" (ngModelChange)="sameAsPhysical()" />
                    <div class="control_indicator"></div>
                </label>
            </div>
            <div class="form-group billing-contacts" *ngIf="!isChecked">
                <div class="row">
                    <div class="form-group col-sm-6"
                        [ngClass]="{ 'has-error': addressLine.dirty && !addressLine.valid }">
                        <label for="addressLine" [translate]="'tuya-company.billing.address-line'"></label>
                        <input placeholder="{{'tuya-company.billing.address-line' | translate }}" type="text"
                            name="addressLine" class="form-control" [(ngModel)]="model.billingAddress.addressLine"
                            #addressLine="ngModel" maxlength="75" [disabled]="suspended" required />
                        <div *ngIf="addressLine.dirty && !!addressLine.errors?.required" class="help-block"
                            [translate]="'tuya-profiles.error.addressLine'"></div>
                        <div *ngIf="addressLine.dirty && !!addressLine.errors?.pattern" class="help-block"
                            [translate]="'tuya-profiles.error.addressLine-pattern'"></div>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="suiteNumber" [translate]="'tuya-company.billing.suite-number'"></label>
                        <input placeholder="{{'tuya-company.billing.suite-number' | translate }}" type="text"
                            name="suiteNumber" maxlength="10" class="form-control"
                            [(ngModel)]="model.billingAddress.suiteNumber" [disabled]="suspended"
                            #suiteNumber="ngModel" />
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-6" [ngClass]="{ 'has-error': city.dirty && !city.valid }">
                        <label for="city" [translate]="'tuya-company.billing.city'"></label>
                        <input placeholder="{{'tuya-company.billing.city' | translate }}" type="text" name="city"
                            class="form-control" [(ngModel)]="model.billingAddress.city" [disabled]="suspended"
                            #city="ngModel" required />
                        <div *ngIf="city.dirty && !!city.errors?.required" class="help-block"
                            [translate]="'tuya-profiles.error.city'"></div>
                        <div *ngIf="city.dirty && !!city.errors?.pattern" class="help-block"
                            [translate]="'tuya-profiles.error.city-pattern'"></div>
                    </div>
                    <div class="form-group col-sm-3">
                        <label for="state" [translate]="'tuya-company.billing.state'"></label>
                        <div class="select-wrapper">
                            <select name="stateId" title="State" [(ngModel)]="model.billingAddress.stateId"
                                #stateId="ngModel" class="form-control-select" [disabled]="suspended" required>
                                <option *ngFor="let state of states" [ngValue]="state.id">
                                    {{state.shortName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group col-sm-3" [ngClass]="{ 'has-error': postalCode.dirty && !postalCode.valid }">
                        <label for="postalCode" [translate]="'tuya-company.billing.zip'"></label>
                        <input placeholder="{{'tuya-company.billing.zip' | translate }}" type="text" name="postalCode"
                            class="form-control" [(ngModel)]="model.billingAddress.postalCode" #postalCode="ngModel"
                            pattern="[0-9]{5}" [disabled]="suspended" required />
                        <div *ngIf="postalCode.dirty && !!postalCode.errors?.required" class="help-block"
                            [translate]="'tuya-profiles.error.zip'"></div>
                        <div *ngIf="postalCode.dirty && !!postalCode.errors?.pattern" class="help-block"
                            [translate]="'tuya-profiles.error.zip-pattern'"></div>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-primary col-sm-3 btn-right save-button" [translate]="'tuya-company.general.save'"
            type="submit" title="Save Changes"
            [disabled]="!billingInfoForm.dirty || billingInfoForm.invalid || (!isPhoneNumberValid && !isPhoneEmpty()) || !isModelChanged() || suspended">
        </button>
        <button class="btn btn-default col-sm-3 btn-right cancel-button" type="button" title="Cancel Changes"
            (click)="billingInfoForm.dirty && isModelChanged() ? cancelBillingInfoForm(cancel) : null"
            [disabled]="!isModelChanged() || suspended" [translate]="'tuya-company.general.cancel'">
        </button>
    </form>
</div>
<ng-template #cancel>
    <tuya-cancel-modal (hideModal)="onHideModal($event)"></tuya-cancel-modal>
</ng-template>