<div class="date-wrapper">
    <div class="input-container" (click)="picker.open(); onOpen();">
        <input matInput
            [min]="minDate"
            [matDatepicker]="picker"
            [(ngModel)]="selectedDate"
            (dateInput)="addEvent('input', $event)"
            (dateChange)="addEvent('change', $event)"
            [ngClass]="[isActive ? 'activeColor' : 'defaultColor', datePickerActive ? 'openedDatePicker' : '']"
            disabled>
        <mat-datepicker #picker disabled="false" (closed)="onClose()"></mat-datepicker>
        <div class="date-picker-toggle"
             [ngClass]="{'openedDatePickerBg': datePickerActive}">
            <img src="../../../../../assets/img/my-orders/{{imageName}}.svg" />
        </div>
    </div>
</div>
