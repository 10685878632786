import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { select } from '@angular-redux/store';
import { BuilderSelectors } from '../../selectors/builder.selectors';
import { Observable, takeUntil } from 'rxjs';
import { BuilderActions } from '../../actions/builder.actions';
import { Subject, BehaviorSubject } from 'rxjs';
import { OrderStatusId } from '../../../core/models/dto';

@Component({
  selector: 'tuya-builder-summary',
  templateUrl: './builder-summary.component.html',
  styleUrls: ['./builder-summary.component.scss']
})
export class BuilderSummaryComponent implements OnInit, OnDestroy, OnChanges {

  @select (BuilderSelectors.getSummary)
  readonly summary$: Observable<String>;
  @select (BuilderSelectors.getPrice)
  readonly price$: Observable<String>;
  @select (BuilderSelectors.getCurrentStep)
  readonly cuurentStep$: Observable<number>;
  @select (BuilderSelectors.getIsInitPrice)
  readonly isInitPrice$: Observable<boolean>;
  @select (BuilderSelectors.getStatusId)
  readonly statusId$: Observable<number>;
  @select (BuilderSelectors.scheduledOrderOfferTime)
  readonly scheduledOrderOfferTime$: Observable<number>;
  @select (BuilderSelectors.isScheduledOrder)
  readonly isScheduledOrder$: Observable<number>;

  @Input() stops;
  @Input() stopCount;
  @Input() currentStep;
  @Input() isEditMode: boolean;
  @Input() restartTimer: boolean;

  ids = {
    deliveryKey: 'delivery',
    deliveryLabel: 'Delivery',
    pickupKey: 'pickup',
    pickupLabel: 'Pickup'
  };

  circle;
  counter$ = new BehaviorSubject({});
  timeLoading$ = new BehaviorSubject(true);
  initialTime = {ms: '05', sc: '00'};
  timeOut = {ms: '00', sc: '00'};
  myinterval: any;
  strokeDashoffset = 173;
  wTime = 300;

  orderStatusId = OrderStatusId;

  public summary;
  public price: String;

  private builderActions: BuilderActions;
  private unsubscriber$ = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.initLoader();
    this.initComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.restartTimer) {
      this.counter$.next(this.initialTime);
      this.startTimer();
      this.initLoader();
    }
  }

  ngOnDestroy() {
    this.resetTimer();
    clearInterval(this.myinterval);
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  initLoader() {
    this.circle = document.getElementsByClassName('circle_animation');
    const a = this.circle[0];
    if (a) {
      a.style.strokeDashoffset = this.strokeDashoffset - ((1) * (this.strokeDashoffset / this.wTime));
    }
  }

  initComponent() {
    this.summary$.pipe(takeUntil(this.unsubscriber$)).subscribe(result => this.summary = result);
    this.summary$.pipe(takeUntil(this.unsubscriber$)).subscribe(result => this.summary = result);
    this.price$.pipe(takeUntil(this.unsubscriber$)).subscribe(result => this.price = result);
    this.builderActions = new BuilderActions;

    this.counter$.subscribe();
    this.timeLoading$.subscribe();

    this.cuurentStep$.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
      if (this.currentStep && res === 3 && this.initialTime.ms === '05' && this.initialTime.sc === '00') {
        this.initLoader();
        this.builderActions.builderWaitTimePassed(false);
        this.builderActions.updateInitPrice(true);
        this.counter$.next(this.initialTime);
        this.startTimer();
      } else {
        this.resetTimer();
      }
    });
  }

  startTimer() {
    this.timeLoading$.next(true);

    let i = 0;
    const myTime = this.wTime - 1;
    const initialOffset = this.strokeDashoffset;

    const _this = this;

    let mins = 5;
    let secs = 60;
    let ms = '05';
    let sc = '00';

    _this.myinterval = setInterval(function() {
      const a = this.circle;
      if (a) {
        a.style.strokeDashoffset = initialOffset - ((i + 1) * (initialOffset / (myTime)));
      }
      if (mins === 5) {
        mins = 4;
      }
      secs = secs - 1;
      if (i === myTime || secs < 0) {
        a.style.strokeDashoffset = 0.2;
        _this.builderActions.builderWaitTimePassed(true);
        clearInterval(_this.myinterval);
        _this.resetTimer();
        return;
      }
      if (secs === 0 && mins !== 0) {
          mins -= 1;
          secs = 60;
      }
      ms = `0${mins}`;
      sc = secs < 10 ? `0${secs}` : `${secs}`;

      _this.counter$.next({ms, sc});
      i++;
    }, 1000);
  }

  resetTimer() {
    this.timeLoading$.next(false);
    clearInterval(this.myinterval);
    this.counter$.next(this.timeOut);
  }

}
