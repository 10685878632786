<div class="admin-header-container" [ngClass]="{'show-shipper': showShipper()}">
  <div class="left-side">
    <!-- left side -->
    <button *ngIf="showShipper() || showGoBakcButton()" class="back-btn" (click)="goBack()">
      <span class="btn-text">{{ 'tuya-admin-header.back-to-search' | translate }}</span>
    </button>
    
    <div *ngIf="showShipper() && (selectedShipper$ | async)" class="shipper-container">
      <div class="shipper-icon"></div>
      <div class="shipper-name-container">
        <span>Viewing: </span>
        <span class="shipper-name">{{ (selectedShipper$ | async).businessEntity.name }}</span>
      </div>
    </div>
  </div>
  <div class="right-side">
    <!-- right side -->
    <button mat-button *ngIf="showDriverPortalButton()" class="driver-portal-btn" (click)="goToDriverPortal()">Driver Portal</button>
    <div class="greeting-container">
      <div class="admin-image"></div>
      <div class="admin-greeting">
        <span>{{ 'tuya-admin-header.hello' | translate: { first: adminProfile?.firstName || '', last: adminProfile?.lastName || ''} }}</span>
      </div>
    </div>
    <button 
      class="sign-out-btn"
      (click)="logout()">
      <span class="btn-text">{{ 'tuya-admin-header.sign-out' | translate }}</span>
    </button>
  </div>
</div>