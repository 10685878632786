import { Action } from 'redux';
import { OrdersDetailsState } from '../../model/my-orders.model';
import { HOME_COLLAPSE_ROW_INFO, HomeAction, HOME_LOAD_ROW_INFO_SUCCESS, HOME_CLEAR_ROW_INFO, HOME_LOAD_ROW_INFO, HOME_LOAD_ROW_INFO_FAILURE } from '../../actions/home.actions';
import {
    CANCEL_ORDER_CANCELED, CANCEL_ORDER_CANCELED_CLEAR, CANCEL_ORDER_SUCCESS, HOME_RATE_DRIVER_SUCCESS,
    UPDATE_ORDER_STATUS,
    CANCEL_ORDER,
    CANCEL_ORDER_ERROR,
    SHOW_RECURRING_MODAL
} from '../../actions/my-orders.actions';
import { ShipperAssessment } from '../../model/driver-info.model';
import { HIDE_DRIVER_POOLS_NOTIFICATION, ADD_DRIVER_TO_POOLS_SUCCESS } from '../../../core/actions/pools.actions';
import { TURN_OFF_ALL_SPINNERS } from '../../../core/actions/alert.actions';

export const initialOrdersDetailsState: OrdersDetailsState = {
    ordersExpandedInfo: {},
    canceledOrderInfo: null,
    activeOrderDetails: null,
    isLoading: false,
    cancelOrderLoading: false,
    recurringId: null
};

export function ordersDetailsReducer(state = initialOrdersDetailsState, a: Action): OrdersDetailsState {
    const {type, payload} = a as HomeAction;
    switch (type) {
        case HOME_LOAD_ROW_INFO: {
           return {
                ...state,
                isLoading: true
           };
        }
        case HOME_LOAD_ROW_INFO_SUCCESS: {
            return {
                ...state,
                ordersExpandedInfo: {...state.ordersExpandedInfo, [payload.orderId]: payload},
                activeOrderDetails: payload,
                isLoading: false
            };
        }
        case HOME_LOAD_ROW_INFO_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        case HOME_COLLAPSE_ROW_INFO: {
            const tempObj = {...state.ordersExpandedInfo};
            delete tempObj[payload];
            return {
                ...state,
                ordersExpandedInfo: tempObj,
                activeOrderDetails: null
            };
        }

        case HOME_CLEAR_ROW_INFO: {
            return {
                ...state,
                ordersExpandedInfo: {},
                activeOrderDetails: null
            };
        }


        case HOME_RATE_DRIVER_SUCCESS: {
            /* FIXME: it's quite dangerously to retrieve driverId from such a nested object */
            /* lack of some property could cause exception */
            const driverId = state.ordersExpandedInfo[payload.orderId].assignments.drivers[0].userAccountId;

            const ordersExpandedInfo = updateDriverScore(state.ordersExpandedInfo, payload.driverRate, driverId);
            const order = ordersExpandedInfo[payload.orderId];
            if (order.assignments.assessment === null) {
                order.assignments.assessment = new ShipperAssessment();
            }
            order.assignments.assessment.rate = payload.rate; // shipper assessment
            order.assignments.assessment.reasonId = payload.reasonId;
            order.assignments.assessment.isRateUpdated = true;
            return {
                ...state,
                ordersExpandedInfo: {...ordersExpandedInfo, [payload.orderId]: order},
                activeOrderDetails: order
            };
        }

        case CANCEL_ORDER_CANCELED: {
            return {
                ...state,
                canceledOrderInfo: payload
            };
        }

        case CANCEL_ORDER_CANCELED_CLEAR: {
            return {
                ...state,
                canceledOrderInfo: payload
            };
        }

        case UPDATE_ORDER_STATUS: {
            const activeOrderDetails = {...state.activeOrderDetails};
            if (activeOrderDetails.orderId === payload.orderId) {
                activeOrderDetails.statusId = payload.statusId;
            }
            const ordersExpandedInfo = {...state.ordersExpandedInfo};
            if (ordersExpandedInfo[payload.orderId]) {
                ordersExpandedInfo[payload.orderId].statusId = payload.statusId;
            }
            return {
                ...state,
                activeOrderDetails: activeOrderDetails,
                ordersExpandedInfo: ordersExpandedInfo
            };

        }

        case CANCEL_ORDER: {
            return {
                ...state,
                isLoading: true,
                cancelOrderLoading: true
            };
        }

        case CANCEL_ORDER_SUCCESS: {
            const activeOrderDetails = {...state.activeOrderDetails};
            if (activeOrderDetails.orderId === payload.orderId) {
                activeOrderDetails.statusId = payload.orderStatusId;
            }
            const ordersExpandedInfo = {...state.ordersExpandedInfo};
            if (ordersExpandedInfo[payload.orderId]) {
                ordersExpandedInfo[payload.orderId].statusId = payload.orderStatusId;
            }
            return {
                ...state,
                isLoading: false,
                cancelOrderLoading: false,
                activeOrderDetails: activeOrderDetails,
                ordersExpandedInfo: ordersExpandedInfo
            };
        }

        case CANCEL_ORDER_ERROR: {
            return {
                ...state,
                isLoading: false,
                cancelOrderLoading: false
            };
        }

        case TURN_OFF_ALL_SPINNERS: {
            return {
                ...state,
                isLoading: false
            };
        }

        case TURN_OFF_ALL_SPINNERS: {
            return {
                ...state,
                isLoading: false
            };
        }

        case SHOW_RECURRING_MODAL: {
            return {
                ...state,
                recurringId: payload
            };
        }

        default:
            return state;
    }
}

function updateDriverScore(ordersExpandedInfo, driverRate, driverId) {
    return Object.keys(ordersExpandedInfo).reduce((result, orderId) => {
        const order = ordersExpandedInfo[orderId];
        if (order.assignments.drivers[0].userAccountId === driverId) {
            order.assignments.drivers[0].rate = driverRate;
        }
        result[orderId] = order;
        return result;
    }, {});
}
