import { Action } from 'redux';
import { INotificationState } from '../models/model';
import {
    ADD_NOTIFICATION,
    CLEAR_NOTIFICATION_BUCKET,
    LOAD_NOTIFICATION_BUCKET,
    NotificationAction,
    REMOVE_NOTIFICATION,
    SAVE_NOTIFICATION_BUCKET,
    SHOW_ERROR_MESSAGE
} from '../actions/notification.actions';
import { TURN_OFF_ALL_SPINNERS } from '../../core/actions/alert.actions';

export const initialState: INotificationState = {
    notification: [],
    notificationBucket: [],
    totalItemCount: 0,
    isLoading: false,
    errorMessageKey: ''
};

export function notificationReducer(state = initialState, a: Action): INotificationState {
    const action = a as NotificationAction;
    switch (a.type) {
        case ADD_NOTIFICATION: {
            return {
                ...state,
                notification: [...state.notification, action.payload]
            };
        }
        case REMOVE_NOTIFICATION: {
            const start = state.notification.indexOf(action.payload);
            if (start !== -1) {
                const notifications = state.notification.map(item => item);
                notifications.splice(start, 1);
                return {
                    ...state,
                    notification: notifications
                };
            } else {
                return state;
            }

        }
        case LOAD_NOTIFICATION_BUCKET: {
            return {
                ...state,
                isLoading: true,
                errorMessageKey: ''
            };
        }
        case SAVE_NOTIFICATION_BUCKET: {
            return {
                ...state,
                notificationBucket: state.notificationBucket.slice().concat(action.payload.items),
                totalItemCount: action.payload.totalItemCount,
                isLoading: false
            };
        }
        case CLEAR_NOTIFICATION_BUCKET: {
            return {
                ...state,
                notificationBucket: [],
                totalItemCount: 0
            };
        }
        case SHOW_ERROR_MESSAGE: {
            return {
                ...state,
                isLoading: false,
                errorMessageKey: action.payload
            };
        }
        case TURN_OFF_ALL_SPINNERS: {
            return {
                ...state,
                isLoading: false
            };
        }
        default: {
            return state;
        }
    }
}
