<div class="modal-content">
    <div class="modal-body">
        <h3 [translate]="'tuya-profiles.modals.modal-cancel'"></h3>
        <button type="button" class="btn btn-default btn-lg" (click)="action(false)" 
              [translate]="'tuya-profiles.btn.stay'"></button>
        <button type="button" class="btn btn-primary btn-lg" (click)="action(true)"
              [translate]="'tuya-profiles.btn.leave'"></button>
    </div>
</div>

