import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { WizardStep } from './../models/ui.model';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';
import { GO_TO_ORDER_DETAILS, LOADING, UIActions } from '../actions/ui.actions';
import {
    INIT_FIRST_STOP,
    NEW_CONFIRM_ORDER_CREATE,
    NEW_CONFIRM_ORDER_CREATE_SUCCESS,
    OPEN_SHIPMENT,
    SHIPMENT_DRAFT_CREATE,
    SHIPMENT_DRAFT_CREATE_SUCCESS,
    SHIPMENT_ORDER_CURRENT_STOP_SELECT,
    SHIPMENT_ORDER_SAVE_TO_STATE,
    SHIPMENT_ORDER_UPDATE,
    SHIPMENT_ORDER_UPDATE_SUCCESS,
    SHIPMENT_REROUTE,
    ShipmentAction,
    ShipmentActions,
    NEW_SHIPMENT_READY_TO_CONFIRM
} from '../actions/shipment.actions';
import { ShipmentService } from '../services/shipment.service';
import { Router } from '@angular/router';
import {
    transformOrderDtoIntoOrderVo,
    transformOrderVoIntoOrderDto,
    transformToDefaultItemStopIds
} from '../../shipment/transforms/shipment.transforms';
import { Stop, StopType } from '../models/order.model';
import { UPDATE_LOCATION } from '@angular-redux/router';
import { AddressAutocompleteActions } from '../actions/address-autocomplete.actions';
import { ShipmentType } from '../../core/models/shipment.model';
import { map, exhaustMap, switchMap, concatMap, catchError } from 'rxjs/operators';
import { MyOrdersActions } from 'app/home/actions/my-orders.actions';

@Injectable()
export class ShipmentEpics {
    constructor(private shipmentActions: ShipmentActions,
        private uiActions: UIActions,
        private shipmentService: ShipmentService,
        private alertActions: AlertActions,
        private addressAutocompleteActions: AddressAutocompleteActions,
        private myOrdersActions: MyOrdersActions,
        private router: Router) {
    }

    public createEpic() {
        return combineEpics(
            createCreateShipmentEpic(this.shipmentActions, this.uiActions),
            createShipmentDraftCreateEpic(this.shipmentActions, this.shipmentService, this.alertActions),
            createShipmentDraftCreateSuccessEpic(this.shipmentActions, this.uiActions),
            createShipmentOrderUpdateEpic(this.shipmentActions, this.shipmentService, this.alertActions),
            createShipmentOrderUpdateSuccessEpic(this.shipmentActions),
            createNewConfirmOrderEpic(this.shipmentActions, this.shipmentService, this.alertActions, this.uiActions),
            createNewConfirmOrderSuccessEpic(this.shipmentActions, this.uiActions),
            createInitFirstStopEpic(this.shipmentActions, this.addressAutocompleteActions, this.shipmentService, this.alertActions),
            createShipmentRerouteEpic(this.router),
            createGoToOrderDetailsEpic(this.router, this.myOrdersActions),
            newShipmentReadyToConfirmEpic(this.shipmentActions, this.shipmentService, this.alertActions, this.uiActions)
        );
    }
}

export function createCreateShipmentEpic(actions: ShipmentActions, uiActions: UIActions) {
    return action$ => action$.pipe(
        ofType(OPEN_SHIPMENT),
        map((action) => {
            return uiActions.setWizardStep(WizardStep.Initial);
        })
    );
}

export function createShipmentDraftCreateEpic(actions: ShipmentActions, service: ShipmentService,
    alertActions: AlertActions) {
    return (action$, store) => action$.pipe(
        ofType(SHIPMENT_DRAFT_CREATE),
        exhaustMap((action) => {
            const orderVo = store.value.shipment.order;
            const userAccountId = store.value.auth.currentUser.shipperProfile.userAccountId;
            const orderDto = transformOrderVoIntoOrderDto(orderVo, userAccountId);
            return service.createDraftOrder(orderDto).pipe(
                map(data => actions.newShipmentDraftCreateSucceeded(transformOrderDtoIntoOrderVo(data)))
                , catchError(error => {
                    return of(actions.newShipmentDraftCreateFailed({
                        error,
                    }), alertActions.show({ message: error, type: AlertType.Error }));
                }));
        })
    );
}

export function createShipmentDraftCreateSuccessEpic(actions: ShipmentActions, uiActions: UIActions) {
    return action$ => action$.pipe(
        ofType(SHIPMENT_DRAFT_CREATE_SUCCESS),
        switchMap((action: any) => {
            return of(
                actions.shipmentOrderSaveToState(action.payload),
                actions.selectCurrentStop(action.payload.stopList[0].id),
                uiActions.initStopEdit({ ...(new Stop()), ...action.payload.stopList[0] }));
        })
    );
}

export function createShipmentOrderUpdateEpic(actions: ShipmentActions, service: ShipmentService,
    alertActions: AlertActions) {
    return (action$, store) => action$.pipe(
        ofType(SHIPMENT_ORDER_UPDATE),
        concatMap((action: any) => {
            const OrderVo = store.value.shipment.order;
            const userAccountId = store.value.auth.currentUser.shipperProfile.userAccountId;
            const orderDto = transformOrderVoIntoOrderDto(OrderVo, userAccountId);
            return service.updateOrder(orderDto).pipe(
                map(data => {
                    return actions.shipmentOrderUpdateSucceeded(transformOrderDtoIntoOrderVo(data),
                        action.payload.isUpdateSelectedStop, action.payload.isSavedStopChosen);
                }),
                catchError(error => {
                    console.log('error');
                    return of(actions.shipmentOrderUpdateFailed({
                        error,
                    }), alertActions.show({ message: error, type: AlertType.Error })).pipe(
                        concatMap(() => of({type: LOADING, payload: false}))
                    );
                })
            );
        })
    );
}

export function createShipmentOrderUpdateSuccessEpic(actions: ShipmentActions) {
    return (action$, store) => action$.pipe(
        ofType(SHIPMENT_ORDER_UPDATE_SUCCESS),
        switchMap((action: any) => {
            const orderVo = action.payload.orderVo;
            // if stop was created form SAVED stop - need to add default item to delivery and pickup stop and update the order
            if (action.payload.isSavedStopChosen) {
                const defaultItemSizeId = store.value.shipment.ui.defaultItemSize,
                    defaultItemSize = store.value.core.itemsSizes.find(item => item.id === defaultItemSizeId),
                    stopIds: Array<any> = transformToDefaultItemStopIds(orderVo);

                if (!stopIds.length) {
                    return of(actions.shipmentOrderSaveToState(orderVo)).pipe(
                        concatMap(() => of({type: LOADING, payload: false}))
                    );
                }

                return of(actions.shipmentOrderSaveToState(orderVo),
                    actions.shipmentAddMissedDefaultItemToStops(defaultItemSize, stopIds),
                    actions.shipmentOrderUpdate(action.payload.isUpdateSelectedStop));
            } else {
                if (action.payload.isUpdateSelectedStop) {
                    const isReturnStop = orderVo.stopList.filter(item => item.typeId === 3),
                        // for Collection additional Pickup - it past on position before last(delivery) stop
                        isAdditionalPickupStop = orderVo.deliveryType === ShipmentType.Collection && orderVo.stopList.length > 2,
                        newStop = orderVo.stopList.find(
                            (stop) => (isReturnStop.length > 0 || isAdditionalPickupStop) ?
                                stop.sequenceNumber === orderVo.stopList.length - 1 :
                                stop.sequenceNumber === orderVo.stopList.length);
                    return of({
                        type: SHIPMENT_ORDER_SAVE_TO_STATE,
                        payload: orderVo
                    }).pipe(
                        concatMap(() => of({ type: SHIPMENT_ORDER_CURRENT_STOP_SELECT, payload: newStop.id })
                        )).pipe(
                            concatMap(() => of({ type: LOADING, payload: false }))
                        );
                } else {
                    return of(actions.shipmentOrderSaveToState(orderVo)).pipe(
                        concatMap(() => of({ type: LOADING, payload: false }))
                    );
                }
            }
        })
    );
}

export function newShipmentReadyToConfirmEpic(actions: ShipmentActions, service: ShipmentService,
    alertActions: AlertActions, uiActions: UIActions) {
    return (action$, store) => action$.pipe(
        ofType(NEW_SHIPMENT_READY_TO_CONFIRM),
        exhaustMap(() => {
            const OrderVo = store.value.shipment.order;
            const userAccountId = store.value.auth.currentUser.shipperProfile.userAccountId;
            const orderDto = transformOrderVoIntoOrderDto(OrderVo, userAccountId);
            uiActions.loading(true);
            return service.updateOrder(orderDto).pipe(
                map(() => {
                    const state = store.value;
                    const confirmationDriversInfo = state.shipment.order.confirmationDriversInfo;
                    const orderId = state.shipment.order.id;
                    uiActions.loading(false);
                    return actions.newOrderConfirm({ orderId, ...confirmationDriversInfo });
                }),
                catchError(error => {
                    uiActions.loading(false);
                    console.error(error);
                    return of(
                        actions.shipmentOrderUpdateFailed({ error }),
                        alertActions.show({ message: error, type: AlertType.Error }),
                        concatMap(() => of({ type: LOADING, payload: false }))
                    );
                })
            );
        })
    );
}

export function createNewConfirmOrderEpic(
    actions: ShipmentActions,
    service: ShipmentService,
    alertActions: AlertActions,
    uiActions: UIActions
) {
    return (action$) => action$.pipe(
        ofType(NEW_CONFIRM_ORDER_CREATE),
        exhaustMap((action: any) => {
            return service.getConfirmOrderId(action.payload).pipe(
                map((data: string) => actions.newOrderConfirmSucceeded(data)),
                catchError(error => {
                    uiActions.loading(false);
                    console.error(error);
                    return of(
                        actions.newOrderConfirmFailed({ error }),
                        alertActions.show({ message: error.message || 'An error occurred', type: AlertType.Error })
                    );
                })
            );
        })
    );
}

export function createNewConfirmOrderSuccessEpic(actions: ShipmentActions, uiActions: UIActions) {
    return (action$) => action$.pipe(
        ofType(NEW_CONFIRM_ORDER_CREATE_SUCCESS),
        map(() => {
            uiActions.loading(false);
            return uiActions.setWizardStep(WizardStep.Confirmation);
        })
    );
}

export function createInitFirstStopEpic(actions: ShipmentActions, addressAutocompleteActions: AddressAutocompleteActions,
    service: ShipmentService, alertActions: AlertActions) {
    return (action$) => action$.pipe(
        ofType(INIT_FIRST_STOP),
        switchMap((action: any) => {
            return service.getNameAddressList(action.payload, true).pipe(
                map(stops => {
                    // for initial stop use just simple address - NOT a saved stop
                    const stopItem = stops.find(stop => !stop.nameOrDescription);
                    if (stopItem) {
                        return addressAutocompleteActions.checkIsAddressInMsa(stopItem, {
                            stopId: undefined,
                            firstStop: true,
                            stopType: StopType.Pickup
                        });
                    }
                    return alertActions.show({ message: '', type: AlertType.NotInTexas });
                }),
                catchError(error => of(alertActions.show({ message: error, type: AlertType.Error })))
            );
        })
    );
}

export function createShipmentRerouteEpic(router: Router) {

    return (action$) => action$.pipe(
        ofType(SHIPMENT_REROUTE),
        exhaustMap((action: any) => {
            const shipmentPagePrefix = '/shipment/';
            const desiredShipmentPageName = (function getReroutePageName(payload) {
                switch (payload) {
                    case WizardStep.Initial:
                        return 'initial';
                    case WizardStep.InitialStopEdit:
                    case WizardStep.Stops:
                    case WizardStep.EditStopDetails:
                        return 'builder';
                    case WizardStep.SummaryAndDriverAssignment:
                    case WizardStep.SummaryAndPaymentOrder:
                    case WizardStep.Confirmation:
                        return 'summary';
                    default:
                        return '';
                }
            })(action.payload);

            router.navigate([shipmentPagePrefix + desiredShipmentPageName]);
            return of({
                type: UPDATE_LOCATION,
                payload: shipmentPagePrefix + desiredShipmentPageName,
            });
        })
    );
}

export function createGoToOrderDetailsEpic(router: Router, myOrdersActions: MyOrdersActions) {
    return (action$) => action$.pipe(
        ofType(GO_TO_ORDER_DETAILS),
        map((action: any) => {
            router.navigate(['/order-details'],
                { queryParams: { orderId: action.payload.orderId } });

            if (action.payload.recurringId) {
                return myOrdersActions.setRecurringId(action.payload);
            }
            return { type: UPDATE_LOCATION, payload: '/order-details?orderId=' + action.payload.orderId };
        })
    );
}
