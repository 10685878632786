import { TrackingInfoVo } from './../../models/tracking.model';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'tuya-tracking-info-container',
    templateUrl: './tracking-info-container.component.html',
    styleUrls: ['./tracking-info-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackingInfoContainerComponent {
    @Input() public trackingInfo: TrackingInfoVo = <TrackingInfoVo>{};
}
