<div class="vehicle-from-wrapper">
    <div *ngIf="isLoading">
        <tuya-spinner size="fullcontained" [clear]="true"></tuya-spinner>
    </div>

    <form class="driver-form" [formGroup]="vehicleFormProp" autocomplete="off" (ngSubmit)="onSubmitVehicle()">

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Vehicle Make</mat-label>
            <input matInput formControlName="make" [errorStateMatcher]="matcher" />
            <mat-error>
                <span *ngIf="!vehicleFormProp.get('make').valid">
                    Vehicle Make is required
                </span>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Vehicle Model</mat-label>
            <input matInput formControlName="model" [errorStateMatcher]="matcher" />
            <mat-error>
                <span *ngIf="!vehicleFormProp.get('model').valid">
                    Vehicle Model is required
                </span>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Vehicle Type</mat-label>
            <mat-select formControlName="vehicleType" [errorStateMatcher]="matcher">
                <mat-option *ngFor="let vehicle of vehiclesProp" [value]="vehicle.id">
                    {{ vehicle.name }}
                </mat-option>
            </mat-select>

            <mat-error>
                <span *ngIf="!vehicleFormProp.get('vehicleType').valid">
                    Vehicle Type is required
                </span>
            </mat-error>
        </mat-form-field>

        <div class="button-row" style="flex: 1">
            <button type="button" mat-raised-button (click)="onCloseModal()" class="cancel">Cancel</button> &nbsp;
            &nbsp;
            <button type="submit" mat-raised-button class="submit">{{ submitBtnText }}</button>
        </div>
    </form>
</div>