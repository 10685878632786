export const locale = {
    'lang': 'en',
    'data': {
        'tuya-app': {
            'name': 'TUYA',
            'assistance-phone': '1-833-488-9266',
            'assistance-phone-text': '(833) 4TUYA-NOW'
        },
        'tuya-common': {
            'btn': {
                'yes': 'Yes',
                'no': 'No'
            }
        },
        'tuya-alert': {
            'edit-disabled': 'You can\'t edit this order, because it already completed',
            'ok': 'Ok',
            'cancel': 'Cancel',
            'submit': 'Submit',
            'timerTxt': 'You have 1 minute to submit',
            'suspend': 'Yes, suspend',
            'unsuspend': 'Yes, unsuspend',
            'unknownError': 'Unknown Error',
            'notInMsa': 'We are rapidly expanding our service areas but have not reached this location yet. Please check back soon.',
            // tslint:disable-next-line:max-line-length
            'timeout-error-message': 'The server encountered a temporary error and could not complete your request. Please try again later.',
            'unknownErrorMessage': 'Unknown error. Please check that you are connected to the internet and try again.',
            'suspend-title': 'Your Account Has Been Suspended',
            'suspend-content': `Your account has been suspended due to, <b>Terms of Service</b> violation(s).
                                 To re-activate your account, please contact the <b>TUYA</b>
                                 helpline at <b>(833) 488-9266.</b>`
        },
        'tuya-header': {
            'btn': {
                'register': 'Register',
                'login': 'Login',
                'create': 'Create order',
                'my-orders': 'my orders',
                'payment': 'payment',
                'drivers': 'drivers',
                'shipment': 'Create A Shipment',
                'add-credit-card': 'Add credit card',
                'suspend-tooltip': '"Create Order" is disabled when account is Suspended.'
            },
            'dropdown': {
                'admin-panel': 'Admin Panel',
                'profile': 'My Profile',
                'company': 'My Company',
                'logout': 'Logout'
            },
            'suspended-message': {
                'account-status': 'Account Status',
                'status-name': 'Suspended',
                'contact-info': 'Please contact the <b>TUYA</b> helpline at <b>(833) 488-9266</b>'
            },
            'notifications': 'Notifications',
            'mark-read': 'MARK READ',
            'no-notifications': 'There are no notifications for you yet.',
            'error1': 'Sorry, we\'re having trouble receiving notifications.'
        },
        'tuya-register': {
            'h': {
                'sign': 'Sign up to ship',
                'join': 'Join the same-day delivery revolution.',
                'clicking': 'By clicking "Start Shipping," you agree to Tuya`s Terms and Conditions and Privacy Policy.',
                'physicalAddress': 'Company\'s physical address',
                'billingAddress': 'Company\'s billing address'
            },
            'password-requirements': 'Password must be between 10 and 32 characters long and contain at least one lowercase letter,' +
                ' one uppercase letter, one number, and one of these special characters ,.?@&!#\'~*_-+;',
            'error': {
                'firstName': 'First Name is required.',
                'lastName': 'Last Name is required.',
                'nickname': 'Please enter a valid Nickname',
                'phone': 'Phone Number is required.',
                'email': 'E-mail address is required.',
                'email-pattern': 'Please enter a valid E-mail',
                'phone-pattern': 'Please enter a valid Phone Number',
                'confirm-password': 'Passwords must match. Try again.',
                'not-confirm': 'Please, confirm the password.',
                'industry': 'Industry is required.',
                'referralCode': 'Referral code is not valid. Please enter a valid referral code.',
                'first-name-pattern': 'Please enter a valid First name',
                'last-name-pattern': 'Please enter a valid Last name',

                'companyName': {
                    'required': 'Company Name is required.',
                    'pattern': 'Please enter a valid Company Name'
                },

                'addressString': {
                    'required': 'Physical Address is required.',
                    'pattern': 'Please enter a valid Physical address',
                    'maxlength': 'Max lenght of Mailing Address is 75 characters'
                },
                'suiteNumber': {
                    'pattern': 'Please enter a valid Suite number',
                    'maxlength': 'Max lenght of suite number is 10 characters'
                },
                'city': {
                    'required': 'City is required',
                    'pattern': 'Please enter a valid City',
                    'maxlength': 'Max lenght of city field 75 characters'
                },
                'state': {
                    'required': 'State is required',
                },
                'zipcode': {
                    'required': 'Zip Code is required',
                    'pattern': 'Please enter a valid Zipcode',
                    'maxlength': 'Max lenght of zipcode is 10 characters'
                }
            },
            'placeholder': {
                'firstName': 'First Name*',
                'lastName': 'Last Name*',
                'nickname': 'Nickname',
                'phone': 'Phone Number*',
                'email': 'E-mail*',
                'password': 'Password*',
                'confirmPassword': 'Confirm Password*',
                'company-name': 'Company Name*',
                'industry': 'Select Industry*',
                'physicalAddress': 'Physical Address*',
                'billingAddress': 'Billing Address',
                'referralCode': 'Referral Code',

                'address-string': 'Address*',
                'suite-number': 'Suite number',
                'city': 'City*',
                'state': 'State*',
                'zipcode': 'Zipcode*'
            },
            'btn': {
                'next': 'next',
                'shipping': 'start shipping'
            },
            'checkbox': {
                'sales': 'I would like a sales representative to contact me.',
                'sameAsPhysical': 'Same as Physical Address'
            },
            'progressbar': {
                'myAccount': 'My Account',
                'companyInfo': 'Company Info'
            }
        },
        'tuya-home': {
            'welcome': 'Welcome',
            'welcoming-text': 'Welcome to TUYA',
            'shipper-no-orders': 'It looks like we haven\'t delivered anything for you yet.',
            'shipper-no-credit-card': 'Please add a valid credit card to begin creating orders.',
            'lets-get-shipping': 'Let\'s get shipping',
            'shipper-email-not-verified': 'It looks like you need to verify your account before you can create a shipment. ' +
            'Check your e-mail for the link!',
            'shipper-email-not-verified-resend-pre': ' If you have not received the email,',
            'shipper-email-not-verified-resend-link': 'click here',
            'shipper-email-not-verified-resend-post': 'to resend it.',
            'shipper-email-not-verified-sent-stub': ' The link has been sent.'
        },
        'tuya-shipment': {
            'start-shipment': 'Where should we start your shipment?',
            'placeholder': {
                'start-address': 'Enter Stop Name or Address'
            },
            'footer': {
                'labels': {
                    'items': 'items',
                    'stops': 'stops',
                    'lb': 'lb',
                    'lbs': 'lbs',
                    'miles': 'miles',
                    'returns': 'returns'
                }
            },
            'pickup': {
                'stop': 'pickup stop'
            }
        },
        'tuya-driver': {
            'home': {
                'header': 'Thank you for signing up for TUYA Driver!',
                'text': 'Login to the mobile app to get started',
                'proceed': 'Proceed to Mobile App',
                'proceed-reset-password': 'Proceed to Reset Password'
            }
        },
        'tuya-tos-policy': {
            'tos': 'Terms of Service',
            'policy': 'Privacy Policy',
            'effective-date': 'Effective Date'
        },
        'tuya-admin-header': {
            'back-to-search': 'Back to Search',
            'hello': 'HELLO {{ first }} {{ last }}',
            'sign-out': 'Sign Out'
        }
    }
};

