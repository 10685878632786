import { Action } from 'redux';
import { PoolsState } from '../models/pools.model';
import {
    ADD_DRIVER_TO_POOLS_SUCCESS,
    CREATE_POOL_SUCCESS,
    DRIVER_POOLS_LOAD_SUCCESS,
    LOAD_DRIVERS,
    LOAD_DRIVERS_FAILURE,
    LOAD_DRIVERS_SUCCESS,
    LOAD_POOLS_SUCCESS,
    PoolsAction,
    RESET_DRIVERS,
    REMOVE_DRIVER_FROM_POOLS_SUCCESS,
    CREATE_POOL_FAILURE,
    CREATE_POOL,
    ADD_DRIVER_TO_POOLS,
    ADD_DRIVER_TO_POOLS_FAILURE,
    REMOVE_DRIVER_FROM_POOLS,
    REMOVE_DRIVER_FROM_POOLS_FAILURE
} from '../actions/pools.actions';
import { TURN_OFF_ALL_SPINNERS } from '../../core/actions/alert.actions';
import { LOGOUT_SUCCESS } from 'app/auth/actions/auth.actions';

export const initialState: PoolsState = {
    pools: [],
    drivers: [],
    isDriversLoading: false,
    driversPools: [],
    isPoolsLoading: false
};

export function poolsReducer(state = initialState, a: Action): PoolsState {
    const action = a as PoolsAction;

    switch (a.type) {
        case LOAD_POOLS_SUCCESS: {
            return {
                ...state,
                pools: action.payload
            };
        }
        case LOAD_DRIVERS: {
            return {
                ...state,
                isDriversLoading: true
            };
        }
        case LOAD_DRIVERS_FAILURE: {
            return {
                ...state,
                isDriversLoading: false
            };
        }
        case LOAD_DRIVERS_SUCCESS: {
            return {
                ...state,
                drivers: action.payload,
                isDriversLoading: false
            };
        }

        case DRIVER_POOLS_LOAD_SUCCESS: {
            return {
                ...state,
                driversPools: [{pools: action.payload.data.driverPools, driverId: action.payload.driverId}]
            };
        }

        case RESET_DRIVERS: {
            return {
                ...state,
                drivers: []
            };
        }

        case CREATE_POOL: {
            return {
                ...state,
                isPoolsLoading: true
            };
        }

        case CREATE_POOL_SUCCESS: {
            const tempDriversPools = [...state.driversPools];

            for (let i = 0, n = tempDriversPools.length; i < n; i++) {
                if (tempDriversPools[i].driverId === action.payload.driverId) {
                    tempDriversPools[i].pools.unshift({id: action.payload.data.id, name: action.payload.data.name});
                    break;
                }
            }
            const pools = [...state.pools];
            pools.unshift(action.payload.data);
            return {
                ...state,
                pools: pools,
                driversPools: tempDriversPools,
                isPoolsLoading: false
            };
        }

        case CREATE_POOL_FAILURE: {
            return {
                ...state,
                isPoolsLoading: false
            };
        }

        case ADD_DRIVER_TO_POOLS: {
            return {
                ...state,
                isPoolsLoading: true
            };
        }

        case ADD_DRIVER_TO_POOLS_SUCCESS: {
            const tempDriversPools = state.driversPools.map(({driverId, pools}) => ({
                driverId,
                pools
            }));
            for (let i = 0, n = tempDriversPools.length; i < n; i++) {
                if (tempDriversPools[i].driverId === action.payload.driverId) {
                    action.payload.driverPools.map(item => {
                        tempDriversPools[i].pools.push({id: item, name: ''});
                    });
                    break;
                }
            }
            return {
                ...state,
                driversPools: tempDriversPools,
                isPoolsLoading: false
            };
        }

        case ADD_DRIVER_TO_POOLS_FAILURE: {
            return {
                ...state,
                isPoolsLoading: false
            };
        }

        case REMOVE_DRIVER_FROM_POOLS: {
            return {
                ...state,
                isPoolsLoading: true
            };
        }

        case REMOVE_DRIVER_FROM_POOLS_SUCCESS: {
            const driversPools = [...state.driversPools];
            for (let i = 0; i < driversPools.length; i++) {
                if (driversPools[i].driverId === action.payload.driverId) {
                    const removedPools = [...action.payload.removedPoolIds];
                    for (let r = 0; r < removedPools.length; r++) {
                        for (let p = 0; p < driversPools[i].pools.length; p++) {
                            if (removedPools[r] === driversPools[i].pools[p].id) {
                                driversPools[i].pools.splice(p, 1);
                                break;
                            }
                        }
                    }
                    break;
                }
            }
            return {
                ...state,
                driversPools: driversPools,
                isPoolsLoading: false
            };
        }

        case REMOVE_DRIVER_FROM_POOLS_FAILURE: {
            return {
                ...state,
                isPoolsLoading: false
            };
        }

        case TURN_OFF_ALL_SPINNERS: {
            return {
                ...state,
                isDriversLoading: false
            };
        }

        case LOGOUT_SUCCESS: {
            return {
                ...initialState
            };
        }

        default: {
            return state;
        }
    }
}

