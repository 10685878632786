import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderItemDto } from 'app/builder/models/item.model';
import { BuilderActions } from 'app/builder/actions/builder.actions';

export interface Vehicle {
  id: number;
  maxDimension: number;
  name: string;
  usableVolume: number;
  disabled: boolean;
  isChecked: boolean;
}

const CUBIC_INCHES_IN_CUBIC_FOOT = 1728;

@Component({
  selector: 'tuya-vehicle-selection',
  templateUrl: './vehicle-selection.component.html',
  styleUrls: ['./vehicle-selection.component.scss']
})

export class VehicleSelectionComponent implements OnInit, OnChanges {

  /**
  * Declaring available vehicles
  */
  vehicles: Vehicle[] = [
    {id: 1, maxDimension: 10, name: 'Sedan', usableVolume: 40, disabled: false, isChecked: false},
    {id: 2, maxDimension: 10, name: 'SUV', usableVolume: 85, disabled: false, isChecked: false},
    {id: 3, maxDimension: 10, name: 'Pickup', usableVolume: 200, disabled: false, isChecked: false},
    {id: 4, maxDimension: 10, name: 'Van', usableVolume: 350, disabled: false, isChecked: false}
  ];

  /**
  * Declaring order items
  */
  itemTypes = {
    1: {id: 1, length: 15, width: 10, height: 1, volume: null},
    2: {id: 2, length: 12, width: 20, height: 5, volume: null},
    3: {id: 3, length: 16, width: 53, height: 10, volume: null},
    4: {id: 4, length: 48, width: 24, height: 24, volume: null},
    5: {id: 5, length: 48, width: 40, height: 60, volume: null}
  };

  /**
  * Component @Input() and @Outputs()
  */
  @Input() items: OrderItemDto[] = [];
  @Input() selectedVehicle = null;

  // Vehicles to use on DOM
  vehicles$ = new BehaviorSubject([]);

  constructor(private builderActions: BuilderActions) {
    // Setting item volume, based on: length, width, height
    for (const key in this.itemTypes) {
      if (this.itemTypes[key] != null) {
        const prop = this.itemTypes[key];
        this.itemTypes[key].volume = prop.length * prop.width * prop.height;
      }
    }
  }

  ngOnInit() {
  }

  /**
   * Handle order item changes
   * Then call updateSelectedVehicle() function to determine a new vehicle type
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
      if (changes && changes.items ) {
        this.items = changes.items.currentValue;
        this.updateSelectedVehicle();
      }
  }

  trackByFn(index) {
    return index;
  }

  /**
   * Unselects current selected button,
   * highlights new selected button, disables all buttons before
   * then update DOM
   */
  updateSelectedVehicle() {
    const availableVehicle = this.totalVolumeFor(this.items);
    const orderVehicles = [...this.vehicles];

    for (let i = 0; i < orderVehicles.length; i++ ) {
      if (!this.selectedVehicle) {
        orderVehicles[i].isChecked = true;
        this.selectedVehicle = orderVehicles[i];
        this.storeVehicleType(orderVehicles[i]);
        break;
      } else if (this.selectedVehicle.id < availableVehicle.id) {
        this.selectedVehicle = availableVehicle;
      }

      orderVehicles[i].disabled = false;
      orderVehicles[i].isChecked = false;

      if (orderVehicles[i].id < availableVehicle.id) {
        orderVehicles[i].disabled = true;
        orderVehicles[i].isChecked = false;
      }

      if (orderVehicles[i].id === this.selectedVehicle.id) {
        orderVehicles[i].isChecked = true;
        orderVehicles[i].disabled = false;
        this.selectedVehicle = orderVehicles[i];
        this.storeVehicleType(orderVehicles[i]);
      }
    }

    this.vehicles = orderVehicles;
    this.vehicles$.next(this.vehicles);
  }

  /**
   *
   * @param items
   * Calculates total volume for order items
   */
  totalVolumeFor(items: OrderItemDto[]): any {
    let volume = 0;
    const orderItems = [...items];

    orderItems.forEach(item => {
      volume = volume + item.sizeTypeId + (this.itemTypes[item.sizeTypeId].volume * item.piecesCount);
    });
    return this.orderVehicle(volume);
  }

  /**
   *
   * @param totalItemsVolume
   * Based on totalItemsVolume calculates vehicle type
   * and returns Vehicle object
   */
  orderVehicle(totalItemsVolume: number): Vehicle {
    for (let i = 0; i < this.vehicles.length; i++) {
      const vehicleUsableVolume = this.vehicles[i].usableVolume * CUBIC_INCHES_IN_CUBIC_FOOT;

      if (totalItemsVolume <= vehicleUsableVolume) {
        return this.vehicles[i];
      }
    }
    return this.vehicles[this.vehicles.length - 1];
  }

  /**
   * Handles mat toggle button click action
   * Updates store value for vehicleType property
   */
  onChange(ev: any) {
    const v = this.vehicles[ev.value - 1];
    for (let i = 0; i < this.vehicles.length; i++ ) {
      if (this.vehicles[i].id === v.id) {
        this.vehicles[i].isChecked = true;
        this.vehicles[i].disabled = false;
      } else {
        this.vehicles[i].isChecked = false;
      }
    }
    this.vehicles$.next(this.vehicles);
    this.selectedVehicle = v;
    this.storeVehicleType(v);
  }

  /**
   * Preparing vehicle obj to updayte
   * Calling redux action to uptate builder vehicle
   * @param vehicle
   */
  storeVehicleType (vehicle: Vehicle) {
    const v = {...vehicle};
    delete v.disabled;
    delete v.isChecked;
    this.builderActions.onSetVehicleType(v);
  }

}
