import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { ShipmentPageComponent } from '../containers/shipment-page/shipment.page';
import { LeaveUnfinishedModalComponent } from '../components/leave-unfinished-modal/leave-unfinished-modal.component';
import { WizardStep } from '../models/ui.model';
import { ShipmentActions } from '../actions/shipment.actions';
import { BsModalService } from 'ngx-bootstrap/modal';


@Injectable()
export class DeactivateShipmentGuard implements CanDeactivate<ShipmentPageComponent> {

    constructor(private modalService: BsModalService,
        private shipmentActions: ShipmentActions) {
    }

    canDeactivate(component: ShipmentPageComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
        nextState: RouterStateSnapshot): Observable<boolean> {
        const wizardStep = component.wizardStep.getValue();
        if (wizardStep === WizardStep.Initial
            || wizardStep === WizardStep.Confirmation) {
            // don't show modal window, because logout logic handles earlier, than redirect
            this.shipmentActions.reset(); // Reset previous order'
            return of(true);
        }

        // Show modal and wait for user interaction
        const modal = this.modalService.show(LeaveUnfinishedModalComponent, { ignoreBackdropClick: true });
        return modal.content.subject.pipe(
            map((isOk: boolean) => {
                if (isOk) this.shipmentActions.reset();

                return isOk;
            }),
            first()
        );
    }
}
