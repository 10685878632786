﻿import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { select } from '@angular-redux/store';
import { RegisterFormActions } from '../actions/register.actions';
import {
    AddressDto,
    BusinessEntityDto,
    IndustrySegment,
    ShipperRegistrationDto,
    StateDto
} from '../../core/models/dto';
import { IndustrySegmentActions } from '../actions/industry.segments.actions';
import { AccountValidationResultVO, CompanyValidationResultVO, RegisterFormStep } from '../model/model';
import { StatesActions } from '../../core/actions/states.actions';
import { BehaviorSubject } from 'rxjs';
import { RegisterSelectors } from '../selectors/register.selectors';

const PASSWORD_MAX_LENGTH = 32;

@Component({
    templateUrl: './register.page.component.html',
    styleUrls: ['./register.page.component.scss']
})

export class RegisterPageComponent implements OnInit {

    billingSameAsPhysical = true;
    sales = false; // temporary (awaiting for back-end)
    @select(RegisterSelectors.statesSelector)
    readonly states$: Observable<StateDto[]>;
    @select(RegisterSelectors.industrySegmentsSelector)
    readonly industrySegments$: Observable<IndustrySegment[]>;
    @select(RegisterSelectors.formStepSelector)
    readonly formStep$: Observable<number>;
    @select(RegisterSelectors.isLoadingSelector)
    readonly isLoading$: Observable<boolean>;
    @select(RegisterSelectors.validateAccountResultSelector)
    readonly validateAccountResult$: Observable<AccountValidationResultVO>;
    @select(RegisterSelectors.validateCompanyErrorSelector)
    readonly validateCompanyError$: Observable<CompanyValidationResultVO>;
    @select(RegisterSelectors.companyInfoFormSelector)
    readonly companyInfoForm$;

    public showErrorsInCompanyInfo = false;
    public hasPhoneMask = false;
    RegisterFormStep = RegisterFormStep;
    companyInfoForm = new BehaviorSubject(undefined);
    submitted = false;
    model: ShipperRegistrationDto;
    companyInfoModel: BusinessEntityDto;
    loading = false;
    phoneMask =  ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    phonePattern = /[0-9]{10}/;
    namePattern = /^([a-zA-Z-.üöäÿïëßÄËÏÖÜŸ']+\s*)+$/;
    emailPattern = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    isPhoneNumberValid = true;
    industrySegmentUndefinedOptionValue;
    companyNamePattern = /^([a-zA-Z\d-,.?@&!#'~*_;+]+\s*)+$/;
    @ViewChild('myAccountForm', {static: true}) myAccountForm;
    @ViewChild('companyInfoForm', {static: true}) myCompanyInfoForm;

    passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,.?@&!#'~*_\-\+;])[A-Za-z\d,.?@&!#'~*_\-\+;]{6,32}/g;

    public constructor(private statesActions: StatesActions,
                       industrySegmentActions: IndustrySegmentActions,
                       private registerFormActions: RegisterFormActions) {
        this.model = new ShipperRegistrationDto();
        this.companyInfoModel = new BusinessEntityDto();
        this.model.businessEntityRegistrationDto = this.companyInfoModel;
        industrySegmentActions.load();
    }
    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (this.myAccountForm.form.dirty || this.myCompanyInfoForm.form.dirty) {
            return $event.returnValue = 'Do you want to reload this site? Changes you made may not be saved';
        }
    }
    ngOnInit(): void {
        this.registerFormActions.clearForm();
        this.statesActions.load();
        this.companyInfoForm$.subscribe(this.companyInfoForm);
    }

    get isPasswordValid() {
        return this.model.password.match(this.passwordPattern) ? true : false;
    }

    public onStepBack() {
        this.registerFormActions.stepback();
    }

    public onAccountValidate() {
        if (this.myAccountForm.valid) {
            this.registerFormActions.validateAccount(this.model);
        }
    }

    public onCompanyInfoSubmit() {
        this.showErrorsInCompanyInfo = !this.billingSameAsPhysical;
        if (this.myCompanyInfoForm.valid && this.myCompanyInfoForm.value.physicalAddress.valid
            && (this.billingSameAsPhysical || this.myCompanyInfoForm.value.billingAddress.valid)) {
            this.registerFormActions.validateCompany({
                ...this.model,
                businessEntityRegistrationDto: this.getConvertedCompanyInfo()
            });
        }
    }

    public isPhoneValid() {
        if (this.model.phoneNumber) {
          this.hasPhoneMask = this.model.phoneNumber.includes('(');
          this.model.phoneNumber = this.model.phoneNumber.replace(/\D+/g, '');
          this.isPhoneNumberValid = this.phonePattern.test(this.model.phoneNumber);
        } else {
            this.hasPhoneMask = false;
        }
    }

    public isPhoneEmpty() {
        return (typeof this.model.phoneNumber === 'undefined') ? true :
                !this.model.phoneNumber.length;
    }

    public isLongPassword(value) {
        if (value) {
            return value.length > PASSWORD_MAX_LENGTH;
        }
    }

    private getConvertedCompanyInfo(): BusinessEntityDto {

        function companyAddress2AddressDto(form): AddressDto {
            return {
                ...new AddressDto(),
                addressLine: form.addressString as string,
                suiteNumber: form.suiteNumber as string,
                city: form.city as string,
                stateId: parseInt(form.state, 10),
                postalCode: form.zipcode as string,
            };
        }

        const obj = {
            ...this.companyInfoForm.getValue()
        };

        return {
            ...obj,
            physicalAddress: companyAddress2AddressDto(obj.physicalAddress),
            billingAddress: (this.billingSameAsPhysical)
                ? companyAddress2AddressDto(obj.physicalAddress)
                : companyAddress2AddressDto(obj.billingAddress)
        };
    }
}
