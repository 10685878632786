import { AddressAutocompleteState } from '../models/address-autocomplete.model';
import { Action } from 'redux';
import { AddressAutocompleteAction, SELECT_AUTOCOMPLETE_ITEM_SUCCESS } from '../actions/address-autocomplete.actions';


export const initialAddressAutocompleteState: AddressAutocompleteState = {
    searchTerm: ''
};

export function addressAutocompleteReducer(state = initialAddressAutocompleteState, a: Action): AddressAutocompleteState {
    const action = a as AddressAutocompleteAction;
    switch (a.type) {

        case SELECT_AUTOCOMPLETE_ITEM_SUCCESS: {
            return {
                ...state,
                selectedItem: action.payload.item
            };
        }

        default: {
            return state;
        }
    }
}

