import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IndustrySegment, StateDto } from '../models/dto';
import { map, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ConfigService } from '../../app.config.service';

import { GET_INDUSTRY_SEGMENTS, GET_STATES } from '../constants/api.constants';

@Injectable()
export class BusinessEntityService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    getIndustrySegments(): Observable<IndustrySegment[]> {
        return this.api().GET(GET_INDUSTRY_SEGMENTS).pipe(map((data: IndustrySegment[]) => data));
    }

    getStates(): Observable<StateDto[]> {
        return this.api().GET(GET_STATES).pipe(map((data: StateDto[]) => data));
    }
}