import { NgModule } from '@angular/core';
import { OffClickMouseUpDirective } from '../shipment/directives/offclick-mouseup.directive';
import { OffClickDirective } from '../shipment/directives/offclick.directive';

@NgModule({
    imports: [],
    declarations: [OffClickMouseUpDirective, OffClickDirective],
    exports: [OffClickMouseUpDirective, OffClickDirective]
})
export class DirectivesModule { }
