import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../../core/models/redux.action';
import { CreditCardDto } from '../../core/models/payment.dto';

export const CREDIT_CARD_ADD = '[Payment] Add credit card';
export const CREDIT_CARD_ADD_SUCCESS = '[Payment] Add credit card success';
export const CREDIT_CARD_ADD_FAILURE = '[Payment] Add credit card failed';
export const HAS_VALID_CREDIT_CARD = '[Payment] Has Valid credit card';
export const HAS_VALID_CREDIT_CARD_SUCCESS = '[Payment] Has Valid credit card success';
export const HAS_VALID_CREDIT_CARD_FAILED = '[Payment] Has Valid credit card failed';
export type PaymentAction = Action<any, any>;

@Injectable()
export class PaymentActions {
    @dispatch()
    addCreditCard = (creditCard: CreditCardDto): PaymentAction => ({
        type: CREDIT_CARD_ADD,
        payload: creditCard
    })

    addCreditCardSucceeded = (creditCard: CreditCardDto): PaymentAction => ({
        type: CREDIT_CARD_ADD_SUCCESS,
        payload: creditCard
    })

    addCreditCardFailed = (error: any): PaymentAction => ({
        type: CREDIT_CARD_ADD_FAILURE,
        payload: null,
        error
    })

    hasValidCreditCard = (): PaymentAction => ({
        type: HAS_VALID_CREDIT_CARD,
        payload: null
    })

    hasValidCreditCardSucceeded = (payload: boolean): PaymentAction => ({
        type: HAS_VALID_CREDIT_CARD_SUCCESS,
        payload
    })

    hasValidCreditCardFailed = (error: any): PaymentAction => ({
        type: HAS_VALID_CREDIT_CARD_FAILED,
        payload: null,
        error
    })
}

