import { OrderHelper } from './../../../shipment/utils/order-helper.utils';
import { TrackingInfoVo } from './../../models/tracking.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'tuya-tracking-stop-info',
    templateUrl: './tracking-stop-info.component.html',
    styleUrls: ['./tracking-stop-info.component.scss']
})
export class TrackingStopInfoComponent implements OnInit {

    @Input() public trackingInfo: TrackingInfoVo = <TrackingInfoVo>{};
    isOpen = false;
    OrderHelper: typeof OrderHelper = OrderHelper;

    constructor() { }

    ngOnInit() { }
}