import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { OrderStatuses } from '../../../core/models/order.dto';
import { OrderStatusId, OrderCollapsedViewDto } from '../../../core/models/dto';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MyOrdersActions } from '../../../home/actions/my-orders.actions';
import { SortDirection } from 'app/home/model/my-orders.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';

@Component({
    selector: 'tuya-orders-table',
    templateUrl: './orders-table.component.html',
    styleUrls: ['./orders-table.component.scss']
})

export class OrdersTableComponent implements OnDestroy, OnChanges {
    @Input() items: Array<OrderCollapsedViewDto>;
    @Input() headers: Array<string>;
    @Input() expandedRows: Array<any>;
    @Input() totalItemCount: number;
    @Input() currentPageNumber: number;
    @Input() sortDirection: number;
    @Input() isShipperSuspended = false;
    @Input() pageSize: number;

    @Output() loadPages = new EventEmitter();
    @Output() toggleRow = new EventEmitter();
    @Output() onOrderSelectEmit = new EventEmitter();
    @Output() onEditRecurringOrder = new EventEmitter();
    sortField = 'CreationDateUtc';

    orderStatuses = OrderStatuses;
    orderStatusId = OrderStatusId;
    expandedElement: any;

    expandedSymbol = null;

    bogusDataSource = new MatTableDataSource<Element>(null);

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    selection = new SelectionModel<OrderCollapsedViewDto>(true, []);

    private subscription: Subscription;

    constructor(private ordersAction: MyOrdersActions) {
        this.subscription = this.selection.changed.subscribe(data => {
            this.onOrderSelectEmit.emit(data.source.selected);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            this.selection.clear();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        if (this.items) {
            const numSelected = this.selection.selected.length;
            const numRows = this.items.length;
            return numSelected === numRows || numSelected > numRows;
        }
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.items) {
            this.isAllSelected() ?
                this.selection.clear() :
                this.items.forEach(row => this.selection.select(row));
        }
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: OrderCollapsedViewDto): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.orderId}`;
    }

    toggleExpandableSymbol(item: any): void {
        this.expandedSymbol = this.expandedSymbol === item.orderId
          ? '' : item.orderId;

        const isRowNeedToCollapse = this.expandedRows[item.orderId];
        this.toggleRow.emit({data: item, open: !isRowNeedToCollapse});
    }

    sortTable(ev) {
        ev.active = ev.active === 'date' ? 'CreationDateUtc' : ev.active;
        ev.active = ev.active === 'dateCompleted' ? 'CompletionDateUtc' : ev.active;
        ev.active = ev.active === 'code' ? 'orderCode' : ev.active;
        // ev.active = ev.active === 'code' ? 'OrderCode' : ev.active;

        this.sortField = ev.active;
        this.sortDirection = ev.direction === 'asc' ? SortDirection.Asc : SortDirection.Desk;
        this.updateStorePagination();
        this.loadPages.emit();
    }

    onPaginateChange(ev) {
        this.pageSize = ev.pageSize;
        this.currentPageNumber = ev.pageIndex + 1;
        this.updateStorePagination();
        this.loadPages.emit();
    }

    updateStorePagination() {
        this.ordersAction.updatePagination({
            pageSize: this.pageSize,
            currentPageNumber: this.currentPageNumber,
            sortDirection: this.sortDirection,
            statuses: [],
            sortField: this.sortField,
            hasExceptions: false
        });
    }

    onRecurringOrderEdit(data: {recurringId: number, orderId: number}) {
        this.onEditRecurringOrder.emit(data);
    }

}
