import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopupTypeEnum, Popup } from '../../../core/models/alert.model';

@Component({
  selector: 'tuya-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  popupType = PopupTypeEnum;

  constructor(private dialogRef: MatDialogRef<PopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Popup) { }

  ngOnInit() {
  }

  onCloseButtonClick = (data) => {
    this.dialogRef.close(data);
  }

}
