import { ShipperProfileDto } from './../../core/models/dto';
import { API_COMPANY_PROFILE, API_SHIPPER_PROFILE } from './../../core/constants/api.constants';
import { BaseService } from './../../core/services/base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../app.config.service';
import { map, Observable } from 'rxjs';

@Injectable()
export class ProfilesService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    updateShipperProfile(profile: ShipperProfileDto): Observable<ShipperProfileDto> {
        return this.api().PUT(API_SHIPPER_PROFILE.replace('{0}', String(profile.userAccountId)),
             {...profile}).pipe(map((data: ShipperProfileDto) => data));
    }

    updateCompanyProfile(profile: ShipperProfileDto): Observable<ShipperProfileDto> {
        return this.api().PUT(API_COMPANY_PROFILE, {...profile}).pipe(
               map((data: ShipperProfileDto) => data));
    }

}
