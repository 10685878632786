import { Action } from 'redux';

import {
    CLEAR_PRICE_OPTIMIZATION_TIMEFRAMES_ERRORS,
    GeneralOfferAction,
    START_PRICE_OPTIMIZATION_CREATE,
    START_PRICE_OPTIMIZATION_SUCCESS,
    START_PRICE_OPTIMIZATION_TIMEFRAMES_FAILURE
} from '../actions/general-offer.actions';
import { GeneralOfferState } from '../models/general-offer.model';
import { Stop } from '../models/order.model';


export const initialGeneralOfferState: GeneralOfferState = {
    priceOptimizationData: {
        startOptimization: false,
        price: 0,
        finishOptimization: false,
        isOptimized: false,
        hasAlreadyOptimized: false,
        optimizedDistance: 0,
        hasErrors: false,
        unservedStopNames: []
    }
};


export function generalOfferReducer(state = initialGeneralOfferState, a: Action): GeneralOfferState {
    const action = a as GeneralOfferAction;
    switch (action.type) {
        case START_PRICE_OPTIMIZATION_CREATE: {
            const priceOptimizationState = state.priceOptimizationData;
            return {
                ...state,
                priceOptimizationData: {
                    ...priceOptimizationState,
                    startOptimization: true,
                    finishOptimization: false,
                }
            };
        }

        case START_PRICE_OPTIMIZATION_SUCCESS: {
            return {
                ...state,
                priceOptimizationData: {
                    startOptimization: true,
                    price: action.payload.optimizedPrice,
                    finishOptimization: true,
                    isOptimized: action.payload.isOptimized,
                    hasAlreadyOptimized: action.payload.hasAlreadyOptimized,
                    hasErrors: false,
                    optimizedDistance: action.payload.optimizedDistance,
                    unservedStopNames: []
                }
            };
        }

        case START_PRICE_OPTIMIZATION_TIMEFRAMES_FAILURE: {
            const stopList = action.payload.stopList,
                unservedStopIds = action.payload.unservedStopIds,
                unservedStopNames = [];
                stopList.forEach((stop: Stop) => {
                    if (unservedStopIds.indexOf(stop.id) > -1) {
                        unservedStopNames.push(stop.nameOrDescription);
                    }
                });

            return {
                ...state,
                priceOptimizationData: {
                    startOptimization: false,
                    isOptimized: false,
                    price: 0,
                    finishOptimization: true,
                    hasAlreadyOptimized: false,
                    hasErrors: true,
                    optimizedDistance: 0,
                    unservedStopNames: unservedStopNames
                }
            };
        }
        case CLEAR_PRICE_OPTIMIZATION_TIMEFRAMES_ERRORS: {
            return {
                ...state,
                ...initialGeneralOfferState
            };
        }

        default: {
            return state;
        }
    }
}
