import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { SelectComponent } from '../../../core/components/pool-select';
import { PriceOptimizationModalComponent } from '../price-optimization-modal/price-optimization-modal.component';
import { DriverAssignmentTypeId } from '../../models/order.model';
import { DriverDto, PoolDto, PoolSelectDto, PriceOptimizationDto } from '../../../core/models/dto';
import { Stop } from '../../models/order.model';
import { OrderEditMode, SelectedCandidatesDto } from '../../../core/models/order.dto';

@Component({
    selector: 'tuya-general-offer',
    templateUrl: './general-offer.component.html',
    styleUrls: ['./general-offer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralOfferComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @ViewChild('exclusiveDriversSelect', {static: true}) exclusiveDriversSelect: SelectComponent;
    @ViewChild('driverPoolsSelect', {static: true}) driverPoolsSelect: SelectComponent;
    @ViewChild('priceOptimizationModal', {static: true}) priceOptimizationModal: PriceOptimizationModalComponent;
    @Output() driverTypeOffer = new EventEmitter<any>();
    @Output() setExtendable = new EventEmitter<boolean>();
    @Output() driverSearch = new EventEmitter<string>();
    @Output() hidePriceOptimizationModal = new EventEmitter();
    @Output() editOrder = new EventEmitter();
    @Input() orderStopList: Stop[] = [];
    @Input() vehicleName;
    @Input() isExtendable = false;
    @Input() orderEditMode;
    @Input() set selectedCandidates(selectedCandidates) {
        this._selectedCandidates = selectedCandidates;
        this.addDataToSelects();

    }
    @Input() driverAssignmentType: DriverAssignmentTypeId = null;
    @Input() set total(total) {
        this._total = total;
        this._setPriceOptParams();
    }

    @Input() set drivers(drivers: DriverDto[]) {
        this._drivers = drivers;
    }

    @Input() isDriversLoading;
    @Input() set priceOptimizationData(priceOptimizationData: PriceOptimizationDto) {
        this._setPriceOptParams(priceOptimizationData);
    }

    @Input() set pools(pools: PoolDto[]) {
        this._pools = pools.filter(item => item.drivers.length);
    }

    get priceOptimizationData() {
        return this._priceOptimizationData;
    }

    get pools() {
        return this._pools;
    }

    get drivers() {
        return this._drivers;
    }


    public disabledLimited: boolean;
    public driver = null;
    public disabledExclusive: boolean;
    public DriverAssignmentTypeId: typeof DriverAssignmentTypeId = DriverAssignmentTypeId;
    public $inputSubject: Subject<string> = new Subject();
    public MIN_LENGTH_STRING = 2;
    public delta = 0;
    public alreadyOptimizedPrice = false;
    public oldPrice = 0;
    public currentPrice = 0;
    public OrderEditMode: typeof OrderEditMode = OrderEditMode;
    public disabledIsExtendable = false;


    private _inputSubscription: Subscription;
    private _selectedCandidates: SelectedCandidatesDto = <SelectedCandidatesDto>{};
    private _pools: PoolDto[] = [];
    private _drivers: DriverDto[] = [];
    private driverIdsFromDriverList: Array<any> = [];
    private poolsIdsFromPoolsList: Array<any> = [];
    private _priceOptimizationData: PriceOptimizationDto = <PriceOptimizationDto>{
        price: 0,
        startOptimization: false,
        finishOptimization: false,
        isOptimized: false
    };
    private _total = 0;

    constructor() {
        this.$inputSubject = new Subject<string>();
    
        this._inputSubscription = this.$inputSubject.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(term => this.driverSearch.next(term));
    }

    ngOnInit() {
        this.exclusiveDriversSelect.items = [];
    }

    ngAfterViewInit() {
        this.addDataToSelects();
        if (this.driverAssignmentType === DriverAssignmentTypeId.Any) {
            this._setSelectsState(true);
        } else {
            this._setSelectsState(false);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.drivers) {
            this._onSendInputData(changes.drivers.currentValue);
        }
    }

    ngOnDestroy() {
        this._inputSubscription.unsubscribe();
    }

    public onTypedEventHandler(input: string) {
        if (input.length >= this.MIN_LENGTH_STRING) {
            this.$inputSubject.next(input);
        }
    }

    /*
    * @{desc} We need to handle price optimized case and update order to get correct total price.
    * @{info} In edit stops case only.
    * */
    public onHidePriceOptimizationModal() {
        if (this.driverAssignmentType) {
            this.hidePriceOptimizationModal.emit();
        }
    }

    public submitSelectedDrivers(e) {
        this.driverIdsFromDriverList = this.getUniqDriversidsArrayFromDrivers(e);
        if (this.driverAssignmentType !== this.DriverAssignmentTypeId.Any ) {
            this._updateOrder(this._getAssignedData());
        }

    }

    public submitSelectedPools(e) {
        this.poolsIdsFromPoolsList = this.getPoolsIdsArrayFromPools(e);
        if (this.driverAssignmentType !== this.DriverAssignmentTypeId.Any ) {
            this._updateOrder(this._getAssignedData());
        }
    }

    public driverTypeClick(event) {
        const predicateExp = (event === this.DriverAssignmentTypeId.Any);
        predicateExp ?
            this.driverTypeOffer.emit({type: this.DriverAssignmentTypeId.Any, data: {}})
            :
            this.driverTypeOffer.emit({type: this.DriverAssignmentTypeId.Exclusive, data: {}});

        this._setSelectsState(predicateExp);
        this.setExtendableBehaviour(predicateExp);
        this.driverAssignmentType = event;
        // clear selected items when switch to any
        if (predicateExp) {
            this.clearSelectedDriversTags();
        }
    }


    /*
    * @{desc} Now for edit order only.
    * @{info} Do not use the function with order creation!!! For testing edit order only
    * @{params} component - Select component. options: pools or drivers
    * void 0
    * */
    public setTagsIntoSelect(component: SelectComponent, options: PoolSelectDto[] | DriverDto[]) {
        this._setSelectsState(false);
        component.setSelectOptions(options);
        component.addTagsToSelect();
    }

    public changeExtendable() {
        this.setExtendable.emit(this.isExtendable);
    }

    public isDisaledDriverSection() {
        return this.orderEditMode === OrderEditMode.PartiallyCompleted ||
            this.orderEditMode === OrderEditMode.Accepted;
    }

    public getDisabledAnyStatement(): boolean {
        return !(this.isDisaledDriverSection() && this.driverAssignmentType !== DriverAssignmentTypeId.Any);
    }

    public getDisabledExclusiveStatement(): boolean {
        return !(this.isDisaledDriverSection() && this.driverAssignmentType === DriverAssignmentTypeId.Any);
    }

    public clearSelectedDriversTags () {
        this.exclusiveDriversSelect.clearActiveTags();
        this.driverPoolsSelect.clearActiveTags();
    }

    private _onSendInputData(drivers) {
        if (drivers.length) {
            this.exclusiveDriversSelect.items = drivers;
            this.exclusiveDriversSelect.openSelect();
        } else {
            this.exclusiveDriversSelect.items = [];
        }
    }

    private _setSelectsState(state) {
        this.disabledExclusive = state;
        this.disabledLimited = state;
    }

    private _getAssignedData() {
        return {
            type: this.DriverAssignmentTypeId.Exclusive,
            data: {
                driverUserAccountIds: this.driverIdsFromDriverList,
                driverPoolIds: this.poolsIdsFromPoolsList
            }
        };

    }

    private getPoolsIdsArrayFromPools(selectedItems) {
        return selectedItems.map(item => item.id);
    }

    private getUniqDriversidsArrayFromDrivers(selectedItems) {
        return selectedItems.reduce((p, c) => {
            if (p.indexOf(c.id) === -1) {
                p.push(c.id);
            }
            return p;
        }, []);
    }

    private _updateOrder(assignData: Object | null) {
        this.driverTypeOffer.emit(assignData);
    }

    private _setPriceOptParams(priceOptimizationData = this._priceOptimizationData) {
        this.delta = this._total - priceOptimizationData.price;
        this.currentPrice = priceOptimizationData.price;
        this.oldPrice = this._priceOptimizationData.price;
        this.alreadyOptimizedPrice = this._priceOptimizationData.hasAlreadyOptimized && (this.currentPrice > this.oldPrice) &&
            !this.priceOptimizationData.isOptimized;
        this._priceOptimizationData = priceOptimizationData;
    }

    private addDataToSelects() {
        if (this._selectedCandidates.drivers && this._selectedCandidates.drivers.length) {
            this.setTagsIntoSelect(this.exclusiveDriversSelect, this._selectedCandidates.drivers);
        }
        if (this._selectedCandidates.pools && this._selectedCandidates.pools.length) {
            this.setTagsIntoSelect(this.driverPoolsSelect, this._selectedCandidates.pools);
        }
    }

    private setExtendableBehaviour(assignmentType) {
        this.disabledIsExtendable = assignmentType;
        this.isExtendable = !assignmentType;
        this.changeExtendable();
    }
}
