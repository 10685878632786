import { OrderStatusId } from './../../../core/models/dto';
import { TrackingInfoVo } from './../../models/tracking.model';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'tuya-tracking-info-time',
    templateUrl: './tracking-info-time.component.html',
    styleUrls: ['./tracking-info-time.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackingInfoTimeComponent {
    @Input() public trackingInfo: TrackingInfoVo = <TrackingInfoVo>{};
    public orderStatusId: typeof OrderStatusId = OrderStatusId;
}
