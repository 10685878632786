<div class="header-wrapper">
    <div class="header-info">
        <h2 [translate]="'tuya-company.h2.manageCompany'"></h2>
    </div>
    <div class="header-info">
        <p class="code">
            <span class="code-label" [translate]="'tuya-company.code'"></span>:
            <span class="shipment-code">{{(companyProfilesInfo)?.accountingCode}}</span>
        </p>
    </div>
</div>
