import { AddressDto, LatLngDto } from '../../core/models/dto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, map, Observable, throwError } from 'rxjs';
import { BaseService } from '../../core/services/base.service';
import { ConfigService } from '../../app.config.service';
import { Stop } from '../models/order.model';
import { OrderDto } from '../../core/models/order.dto';
import { API_PREFIX } from '../../core/constants/api.constants';

const API_ORDER = '/api/v1/Order';
const API_ORDER_DELETE = API_PREFIX + '/Order/{0}';
const API_ORDER_CONFIRM = `/api/v1/Order/{0}/confirm`;
const API_CHECK_ADDRESS_MSA = `/api/v1/Order/CheckAddressIsInMSARange`;
const API_ADDRESS = '/api/v1/Address/search';
const API_ORDER_DISTANCE = '/api/v1/Order/Distance';
const API_PRICE_OPTIMIZATION = '/api/v1/Order/{orderId}/OptimizedPrice';

@Injectable()
export class ShipmentService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    createDraftOrder(order: OrderDto): Observable<OrderDto> {
        return this.api().POST(API_ORDER,
            { ...order }).pipe(
                map((data: OrderDto) => data));
    }

    deleteOrder(orderId: any) {
        return this.api().DELETE(API_ORDER_DELETE.replace('{0}', String(orderId)));
    }

    checkAddressIsInMSARange(address: AddressDto) {
        return this.api().POST(API_CHECK_ADDRESS_MSA,
            { ...address }).pipe(
                map((result: any) => result));
    }

    updateOrder(order: OrderDto): Observable<OrderDto> {
        return this.api().PUT(API_ORDER,
            { ...order }).pipe(
                map((data: OrderDto) => data));
    }

    getConfirmOrderId(assignmentData) {
        return this.api().PUT(API_ORDER_CONFIRM.replace('{0}', String(assignmentData.orderId)),
            assignmentData).pipe(map((data: string) => data));
    }

/*  private isConfirmingOrder: boolean = false;
    getConfirmOrderId(assignmentData) {
        if (this.isConfirmingOrder) return EMPTY;
                    
        this.isConfirmingOrder = true;
        return this.api().PUT(API_ORDER_CONFIRM.replace('{0}', String(assignmentData.orderId)), assignmentData)
            .pipe(
                map((data: string) => {
                    this.isConfirmingOrder = false;
                    return data;
                }),
                catchError((error) => {                    
                    this.isConfirmingOrder = false;
                    throw error;
                })
            );
    }*/

    getNameAddressList(addressSearchParam: string, checkState = true): Observable<Stop[]> {
        return this.api().GET(API_ADDRESS, { addressSearchParam, checkState })
            .pipe(map((data: Stop[]) => data));
    }

    getOrderDistance(coords: LatLngDto[]) {
        return this.api().POST(API_ORDER_DISTANCE, { coordinates: coords }).pipe(
            map((resp: { miles: number; duration: number; }) => resp.miles));
    }

    startPriceOptimization(orderId: any) {
        return this.api().GET(API_PRICE_OPTIMIZATION.replace('{orderId}', String(orderId)));
    }
}
