import { Injectable, EventEmitter } from '@angular/core';
export const CAN_NOT_CANCEL_ALERT = 'canNotCancelAlert';
@Injectable()
export class NotificationsService {

    showCanNotCancelAlert: EventEmitter<any> = new EventEmitter();


    constructor() {
    }

    setCanNotCancelAlert(data) {
        this.showCanNotCancelAlert.next ({type: CAN_NOT_CANCEL_ALERT, data: data});
    }
}
