import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigService } from '../../app.config.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RouteSubstituteService extends BaseService {

    constructor(http: HttpClient,
                configService: ConfigService,
                private location: Location,
                private router: Router) {
        super(http, configService);
    }

    subscribeToRoute() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                switch (this.router.url) {
                    case '/shipment/summary': {
                        this.location.replaceState('orders');
                        break;
                    }
                }
            }
        });
    }
}
