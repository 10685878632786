
import { Injectable } from '@angular/core';
import { Configuration } from './core/models/base.config';
import * as config from '../assets/config/config.json';

@Injectable()
export class ConfigService {
    private config: Configuration;

    constructor() {}

    load(url: string): Configuration {
        return this.config = (<any>config);
    }

    getConfiguration(): Configuration {
        return this.config;
    }
}
