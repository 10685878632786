import { StateDto } from './../../core/models/dto';
import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../models/redux.action';

export type StatesAction = Action<StateDto[], string>;

export const LOAD_STATES = '[STATES] Load';
export const LOAD_STATES_SUCCESS = '[STATES] Load success';
export const LOAD_STATES_ERROR = '[STATES] Load error';

@Injectable()
export class StatesActions {
    @dispatch()
    load = (): StatesAction => ({
        type: LOAD_STATES,
        payload: null
    })

    loadSucceeded = (states: StateDto[]): StatesAction => ({
        type: LOAD_STATES_SUCCESS,
        payload: states
    })

    loadFailed = (error: any): StatesAction => ({
        type: LOAD_STATES_ERROR,
        payload: null,
        error,
    })
}
