import { Subject } from 'rxjs';
import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'tuya-cancel-modal',
    templateUrl: 'cancel-modal.component.html',
    styleUrls: ['cancel-modal.component.scss']
})

export class CancelModalComponent {
    subject: Subject<boolean> = new Subject();
    @Output() hideModal = new EventEmitter();

    constructor(public bsModalRef: BsModalRef) { }

    action(value: boolean) {
        this.hideModal.emit(value);
        this.bsModalRef.hide();
        this.subject.next(value);
        this.subject.complete();
    }
}
