<div class="time-picker-wrapper">
    <div class="hint">{{ hint }}</div>
    <div class="input-group">
        <div class="input-group-btn">
            <button type="button" class="btn btn-outline-secondary" [disabled]="disabled" (click)="onTogglePicker()">
                <i class="fa fa-clock-o" aria-hidden="true"></i>
            </button>
        </div>
        <div class="input-group-status">{{ datetime | date: 'jm'  }}</div>
    </div>
    <tuya-popup-timepicker [value]="datetime"
                    (valueChange)="onValueChange($event)"
                    (invalid)="onTimeInvalid($event)"
                    [(showPopup)]="showPicker"
                    [isEditMode]="isEditMode"
                    [minDate]="minDatetime"
                    [maxDate]="maxDatetime"
                    [minDateError]="minDateError"
    ></tuya-popup-timepicker>
</div>

