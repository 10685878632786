import * as moment from 'moment';

export class BuilderTimeUtils {

    /**
     * Timestamp.
     */
    private time: number;
    private increment: number;

    /**
     * Constructor.
     * @param date Date object
     * @param increment number, increments of time in which to round returned times.
     */

    constructor(date: Date, increment: number) {
        this.time = date.getTime();
        this.increment = increment;
    }

    getTime(): number {
        return this.time;
    }

    setTime(date: Date): BuilderTimeUtils {
        this.time = date.getTime();
        return this;
    }

    getIncrement(): number {
        return this.increment;
    }

    setIncrement(increment: number): BuilderTimeUtils {
        this.increment = increment;
        return this;
    }

    /**
     * Get time rounded up to nearest increment in format specified by formatString.
     * @param formatString Time format string.
     * @see https://stackoverflow.com/questions/10789384/round-a-date-to-the-nearest-5-minutes-in-javascript/10789415#10789415
     */
    getNearestTimeIncrement(formatString: string): string {
        const coeff = 1000 * 60 * this.increment;
        const nearestIncrement = new Date(Math.ceil(this.time / coeff) * coeff);

        return moment(nearestIncrement).format(formatString);
    }
}

/**
* Change time from h:mm xm to HH:mm:ss.
* @param time String from time input field.
* @return parsed string.
*/
export function parseInputTime(time: string) {
    time = time.toLowerCase();

    const timeArray = splitTimeParts(time);   // split time into parts    
    if (timeArray === null) return null;        // return default time if no match is found    
    if (timeArray.length < 3) return null;

    // hours
    let hours = Number.parseInt(timeArray[0], 10);
    if (hours > 12) return null;

    let minutes = '00';                               // default minutes value    
    if (timeArray.length > 1) minutes = timeArray[1]; // minutes

    // Ensure hours are in valid range (0-23)
    const ampm = timeArray[2];
    if ((ampm === 'pm') && hours < 12) hours += 12;
    else if ((ampm === 'am') && hours === 12) hours = 0;
    hours %= 24;
    const hourString = hours.toString().padStart(2, '0');    // Format hours with leading zero if necessary

    if (hours === 12 && ampm === 'am') return '00:00:00'; // Handle special case: convert 12:00 am to 00:00 am
    return hourString + ':' + minutes + ':00';
}

function splitTimeParts(time: string): string[] {
    // Regex to match time in formats "hmm" or "hhmm" or "h:mm" or "hh:mm" followed by "am", "pm", "a", or "p"
    const regex = /(\d{1,2})(?=(?::|\d{2}))(?::?(\d{2}))?(?:\s*(am?|pm?|a\/p)?)?/i;

    // Use match method to directly extract matched parts from the original string
    const matches = time.match(regex);

    if (matches) {
        let [, hour, minutes, meridiem] = matches;

        // Add leading zero to hour if it's a single digit
        if (hour.length === 1) hour = '0' + hour;
        let adjustedHour = parseInt(hour, 10);
        if (adjustedHour > 12) return null;

        const adjustedMinutes = minutes ? minutes : '00';

        const adjustedMeridiem = meridiem && (meridiem.trim().toLowerCase() === 'a' || meridiem.trim().toLowerCase() === 'am') ? 'am' : (meridiem && (meridiem.trim().toLowerCase() === 'p' || meridiem.trim().toLowerCase() === 'pm') ? 'pm' : '');
        if (adjustedMeridiem === '') return null;

        return [hour, adjustedMinutes, adjustedMeridiem];
    }

    return null;
}

/**
 * Get a datetime string formatted in ISO format
 * @param day String in format YYY-MM-DD
 * @param time String in format HH:mm:ss
 */
export function formatToIsoTime(day: string, time: string): string {
    const dateObj = new Date(day);
    const dateStr = dateObj.toISOString().split('T').shift();
    const timeAndDate = moment(dateStr + ' ' + time).toDate();
    if (isNaN(new Date(timeAndDate).getTime())) return null;

    return timeAndDate.toISOString();
}

export function formatDisplayedTime(time: string, format: string) {
    return moment(time).format(format);
}

export function checkIsDateInThePast(isoTimeString: string): boolean {
    return moment(isoTimeString).isBefore();
}

export function convertDateToToday(isoTimeString: string): string {
    // find the difference in days between today and arg time
    const previousDate = moment(isoTimeString).startOf('day');
    const nowDate = moment().startOf('day');
    const dayDiff = moment.duration(nowDate.diff(previousDate)).days();

    // return ISO time with current date
    return moment(isoTimeString).add(dayDiff, 'days').toISOString();
}
