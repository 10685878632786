export const locale = {
    'lang': 'en',
    'data': {
        'tuya-tracking': {
            'tuya-tracking-footer': {
                'learn-more-link': 'Learn more',
                'title': 'The Future of Same-Day Express Delivery'
            },
            'tuya-tracking-info': {
                'tuya-tracking-stepper': {
                    'shipment-created': 'Shipment Created',
                    'driver-assigned': 'Driver Assigned',
                    'picked-up': 'Picked Up',
                    'await-arrival': 'Await Arrival',
                    'in-transit': 'In Transit',
                    'delivered': 'Delivered'
                },
                'tuya-tracking-time-info': {
                    'time-info-message': 'Driver last known location',
                    'refresh-updates': 'Refresh for Updates'
                },
                'tuya-stop-event-item': {
                    'delivery': 'delivery',
                    'pickup': 'pickup',
                    'return': 'return',
                    'of': 'of',
                    'items': 'items',
                    'stop-names': {
                        'arrived': 'Arrived',
                        'attempted-arrival': 'Attempted Arrival',
                        'delivery-completed': 'Delivery Completed',
                        'return-completed': 'Return Completed',
                        'pickup-completed': 'Pickup Completed',
                        'attempted-delivery': 'Attempted Delivery',
                        'attempted-pickup': 'Attempted Pickup',
                        'attempted-return': 'Attempted Return',
                        'item-exception': 'Item Exception'
                    }
                },
                'tuya-tracking-order-info': {
                    'shipped-by': 'Shipped by',
                },
                'tuya-tracking-driver-info': {
                    'awaiting-driver': 'Awaiting Driver Information',
                    'scheduled-arrival': 'Scheduled arrival ',
                    'delivery-professional': ' is your friendly delivery professional'
                },
                'tuya-tracking-stop-info': {
                    'items-for': 'items for ',
                    'items': 'Items',
                    'of': 'of',
                    'lbs': 'lb',
                    'delivery': 'delivery',
                    'pickup': 'pickup',
                    'return': 'return',
                    'delivery-with-return': 'delivery-with-return',
                    'errors': {
                        'ok': 'ok',
                        'no-receiver': 'No Receiver',
                        'damaged-items': 'Damaged Items',
                        'missing-items': 'Missing Items',
                        'refused': 'Refused By Receiver',
                        'extra-items': 'Extra Items',
                        'unknown': 'Unknown Issue'
                    }
                },

            }
        }
    }
};
