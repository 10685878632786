export const locale = {
    'lang': 'en',
    'data': {
        'tuya-login': {
            'login': 'Welcome Back!',
            'welcome': 'login to schedule deliveries',
            'h': {
                'agreeTuyas': 'By clicking Log In, you agree to TUYA’s ',
                'use': 'Terms of Use',
                'and': ' and ',
                'policy': 'Privacy Policy.'
            },
            'forgotPassword': 'Forgot your password?',
            'assistant': 'Need assistance? Call ',
            'noAccount': 'No Account?',
            'newToTuya': 'New to TUYA?',
            'createAnAcount': 'Create a new account',
            'passwordError': `The email or password you entered is incorrect. Please try again or you can`,
            'resetPassword': 'reset your password',
            'showPassword': 'Show password',
            'hidePassword': 'Hide password',
            'rememberMe': 'Remember me',
            'btn': {
                'shipping': 'Log In'
            },
            'placeholder': {
                'email': 'Email Address',
                'password': 'Password'
            },
            'error': {
                'email': 'Valid email is required.',
                'password': 'Password is required',
                'email-pattern': 'Enter a valid email.',
                'userNotValid': 'The email or password you entered is incorrect.',
                'account-locked': 'Temporarily Locked',
                'firebaseError': 'Notifications Disabled',
                'incognito': `If you are using incognito/private mode, please note that notifications are disabled by default.
                              To see notifications, please exit incognito/private mode or enable notifications in your browser settings.`,
                'server-error': 'To see notifications, enable notifications in your browser settings and refresh the page.',
                'code': 'Code'
            },
        },
        'tuya-forgot-password': {
            'forgottenYourPassword': 'Forgotten your password?',
            'enterToResetPassword': 'Please enter your email address to reset your password.',
            'backToLogin': 'Back to Login',
            'checkYourEmail' : 'Check your Email!',
            'submit' : 'submit',
            'checkYourEmailDescPre' : 'If there is TUYA account associated with',
            'checkYourEmailDescPost': ', we have sent reset instructions to your email.',
            'chooseANew': 'Choose a New Password!',
            'selectANew': 'Select a new permanent password to use when logging into your account',
            'newPassword': 'New Password*',
            'confirmPassword': 'Confirm password*',
            'error': {
                'password': 'Please enter a password.',
                'confirm-password': 'Passwords must match. Try again.',
            }
        },
        'tuya-register': {
            'validation': {
                'maxLength': 'Password is too long. Try one with maximum 32 characters',
                'minLength': 'Short passwords are easy to guess. Try one with at least 6 characters',
                'blackList': 'This password is common and easy to guess. Try again',
                'combination': 'Password is too weak. Your password must include at least one upper case letter' +
                    ' one lower case letter, one special character, and one number',
                'uniqueEmail': 'E-mail already exists in our system',
                'forbidden-symbols': 'Password contain forbidden symbol(s).',
                'first-name-format': 'First Name should start and end with letters only.',
                'last-name-format': 'Last Name should start and end with letters only.',
                'companyName': 'An account with that company name already exists. Please contact your administrator ' +
                                'to register with this company.'
            }
        }
    }
};

