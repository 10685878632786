import { StopDto } from './stop.model';

export enum BoxSizeTypes {
    Envelope = 1,
    Small = 2,
    Medium = 3,
    Large = 4,
    Pallet = 5,
    UnknownReturn = 6
}

/**
 * Base definition used in Shipment Builder application state.
 */
export interface OrderItemBase {
    piecesCount: number;
    sizeTypeId: number;
    weight: number;
    description?: string;
    referenceId?: string;
}

/**
 * DTO for PUT/POST
 */
export interface OrderItemDto extends OrderItemBase {
    stops?: StopDto[];
}
