import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MyErrorStateMatcher } from '../../../admin/containers/driver-portal/driver-portal.component';
import { Observable } from 'rxjs';
import { StateDto } from 'app/core/models/dto';
import { DriverDTO, NewDriverDTO } from 'app/admin/models/driverportal.model';
import { AdminDriverPortalActions } from 'app/admin/actions/admin.driver.portal.actions';

@Component({
  selector: 'tuya-driver-form',
  templateUrl: './driver-form.component.html',
  styleUrls: ['./driver-form.component.scss']
})
export class DriverFormComponent implements OnInit, OnChanges {
  @Input() states: Observable<StateDto[]>;
  @Input() formData: UntypedFormGroup;
  @Input() serviceAreasProp: any;
  @Output() driverDataEmit = new EventEmitter<NewDriverDTO>();
  // driverForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  driverFormProp: any;

  // phone mask and zipcode mask
  phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  zipCodeeMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/];

  constructor(private adminDriverPortalActions: AdminDriverPortalActions) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.driverFormProp = this.formData.controls.driverData;
    }
  }

  ngOnInit() { }

  onCloseModal() {
    this.adminDriverPortalActions.driverModalReact(false);
  }

  onSubmitDriver(data: NewDriverDTO) {
    if (this.driverFormProp.valid) {
      data.phoneNumber = data.phoneNumber.replace(/-/g, '');
      this.driverDataEmit.emit(data);
    }
  }

}
