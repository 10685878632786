import { HttpClient, HttpParams, HttpParameterCodec, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConfigService } from '../../app.config.service';
import { catchError, map, Observable } from 'rxjs';

export const USER_AGENT_EXTENTION = ' Tuya-Shipper-App 0.0.1 WEB';

// custom encoder because Angular uses its own, rather than the MDN
// causes issues with + in form components
// https://github.com/angular/angular/issues/18261

class CustomEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }
    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }
    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }
    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}

export class BaseService {
    private url: string;

    constructor(private _http: HttpClient, private configService: ConfigService) { }

    api(isOpenIdRequest?): any {
        const cfg = this.configService.getConfiguration();
        this.url = isOpenIdRequest !== undefined ? cfg['default'].openIdBaseUrl : cfg['default'].apiBaseUrl;
        
        return this;
    }

    // wrapping the params in this.wrapParams() assumes they are all query params
    // however there are other angular params you can set
    // https://angular.io/api/common/http/HttpRequest#constructor()
    GET(method: string, params: object = {}) {
        const options = this.wrapParams(params);
        if (params['responseType']) options['responseType'] = params['responseType'];

        return this._http.get(this.url + method, options).pipe(
            map(res => res as Object)
        );
    }

    /*GET<T>(method: string, queryParams: object = {}, options: { responseType?: 'json' | 'text' | 'arraybuffer' | 'blob' | 'json' | undefined, headers?: HttpHeaders } = { responseType: 'json' }): Observable<T> {
        let params = new HttpParams({ encoder: new CustomEncoder() });
    
        for (const key in queryParams) {
            if (queryParams.hasOwnProperty(key)) {
                const value = queryParams[key];
                if (Array.isArray(value)) {
                    value.forEach(v => params = params.append(key, v));
                } else {
                    params = params.set(key, value);
                }
            }
        }
    
        return this._http.get<T>(this.url + method, { params, ...options }).pipe(
            map((res: any) => res as T),
            catchError((error: any) => {
                console.error('HTTP Error:', error);
                throw error;
            })
        );
    }*/

    POST(method: string, body: Object = {}) {
        return this._http.post(this.url + method, body).pipe(
            map(res => res as Object)
        );
    }

    POST_OPEN_ID(method: string, body: any) {
        const bodyParsed = this.wrapParams(body).params;
        return this._http.post(this.url + method, bodyParsed.toString()).pipe(
            map(res => res as Object)
        );
    }


    PUT(method: string, body: Object = {}, options: Object = {}) {
        return this._http.put(this.url + method, body, options).pipe(
            map(res => res as Object)
        );
    }

    DELETE(method: string) {
        return this._http.delete(this.url + method).pipe(
            map(res => res as Object)
        );
    }

    private wrapParams(sourceParams: any): { params: HttpParams } {
        if (typeof sourceParams === 'undefined') return { params: undefined };

        let httpParams = new HttpParams({ encoder: new CustomEncoder() });    // use custom encoder here
        for (const key of Object.keys(sourceParams)) {
            if (sourceParams[key] instanceof Array) {
                sourceParams[key].forEach(st => {
                    httpParams = httpParams.append(key, st);
                });
            } else {
                httpParams = httpParams.set(key, sourceParams[key]);
            }
        }

        return { params: httpParams };
    }
}