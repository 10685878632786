<div tabindex="0" *ngIf="multiple === true" [tuyaOffClick]="clickedOutside"
    class="tuya-select-container tuya-select-multiple dropdown open">
    <div [ngClass]="{'tuya-disabled': disabled}"></div>
    <span class="tuya-select-match">
        <span class="tuya-select-match-item btn btn-secondary btn-xs" tabindex="-1" type="button"
            *ngFor="let a of active">
            <a class="close" style="margin-left: 5px; padding: 0;" (click)="removeClick(a, $event)">&times;</a>
            <span class="item-text" [title]="a.name" [innerHtml]="sanitize(a.name)"></span>
        </span>
    </span>

    <div class="input-container">
        <div class="input-wrapper">
            <input type="text" (keydown)="inputEvent($event)" (keyup)="inputEvent($event, true)"
                (keypress)="validateEvent()" (click)="matchClick($event)" [disabled]="disabled" [(ngModel)]="poolName"
                [placeholder]="placeholder" autocomplete="false" autocorrect="off" autocapitalize="off"
                spellcheck="false" class="form-control tuya-select-search" role="combobox">
            <tuya-ng-select-spinner *ngIf="pending" [size]="'small'"></tuya-ng-select-spinner>
            <button class="btn tuya-select-button" type="button"
                *ngIf="!hasSubmitButton || (optionsOpened === true || active.length <= 0)" (click)="addTags()"
                [disabled]="!options.length || optionsOpened === false">
                +
            </button>

            <button title="Send" class="btn tuya-select-button tuya-send-btn" type="button"
                *ngIf="hasSubmitButton && active.length > 0 && optionsOpened === false" (click)="onSendPools()">
            </button>
        </div>

        <!-- options template -->
        <ul *ngIf="optionsOpened && ( hasAddButton || staticItem || options.length > 0)  && !firstItemHasChildren"
            class="tuya-select-choices dropdown-menu" role="menu">
            <li role="menuitem" *ngIf="hasAddButton">
                <a class="tuya-select-choices-row" (click)="addOptionToSelect()">
                    <label>Create new pool</label>
                </a>
            </li>
            <li role="menuitem" *ngIf="staticItem && !options.length && (poolName.length > 5)">
                <a class="tuya-select-choices-row">
                    <label>{{staticItemLabel}}</label>
                </a>
            </li>
            <li *ngFor="let o of options" role="menuitem">
                <div class="tuya-select-choices-row" [class.active]="isActive(o)" (mouseenter)="selectActive(o)">
                    <a href="javascript:void(0)" class="dropdown-item" [ngClass]="{'liInPool': o.inPool}">
                        <label>
                            <span class="item-text">{{o.name}}</span>
                            <input *ngIf="!o.inPool" type="checkbox" [(ngModel)]="o.activated">
                            <span class="checkmark" *ngIf="!o.inPool">+</span>
                        </label>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</div>