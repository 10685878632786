import { ShipperProfileDto } from './../../core/models/dto';
import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../../core/models/redux.action';
import { AuthUserFull, ComplexToken, Credentials, PasswordValidationVO, UserProfile } from '../model/model';

export type AuthAction = Action<any, any>;

export const LOGIN = '[Auth] Login';
export const LOGIN_SUCCESS = '[Auth] Login Success';
export const LOGIN_FAILURE = '[Auth] Login Failure';
export const LOGIN_REDIRECT = '[Auth] Login Redirect';
export const HOME_PAGE_REDIRECT = '[Auth] Home Page Redirect';
export const LOGIN_FCM = '[Auth] Login FCM';
export const GET_PERMISSION_FCM = '[Auth] Get permission FCM';

export const UPDATE_CURRENT_USER = '[Auth] Update current user';

export const GO_TO_HOME = '[Auth] Go to home';
export const GO_TO_ORDERS = '[Auth] Go to orders';

export const INIT_REFRESH_DONE = '[Auth] Init refresh done';
export const INIT_REFRESH_RESET = '[Auth] Init refresh reset';

export const LOGOUT = '[Auth] Logout';
export const LOGOUT_SUCCESS = '[Auth] Logout Success';
export const LOGOUT_FAILURE = '[Auth] Logout Failure';

export const UPDATE_REMEMBER_ME = '[Login] Remember user email address';
export const UPDATE_USERNAME = '[Login] Update user name';

export const CREATE_ACCESS_TOKEN = '[Auth] Create Access Token';
export const CREATE_ACCESS_TOKEN_SUCCESS = '[Auth] Create Access Token Success';
export const CREATE_ACCESS_TOKEN_FAILURE = '[Auth] Create Access Token Failure';
export const REQUEST_FORGOT_PASSWORD = '[Auth] Request forgot password';
export const REQUEST_FORGOT_PASSWORD_SUCCESS = '[Auth] Request forgot password success';
export const REQUEST_FORGOT_PASSWORD_FAILURE = '[Auth] Request forgot password failure';

export const REQUEST_RESET_PASSWORD = '[Auth] Request reset password';
export const REQUEST_RESET_PASSWORD_SUCCESS = '[Auth] Request reset password success';
export const REQUEST_RESET_PASSWORD_FAILURE = '[Auth] Request reset password failure';

export const VALIDATE_NEW_PASSWORD = '[Auth] Validate new password';
export const VALIDATE_NEW_PASSWORD_SUCCESS = '[Auth] Validate new password success';
export const VALIDATE_NEW_PASSWORD_FAILURE = '[Auth] Validate new password failure';

export const PASSWORD_RESET_REDIRECT = '[Auth] Password reset redirect';
export const REQUEST_VERIFICATION_EMAIL = '[Auth] Request verification email';
export const REQUEST_VERIFICATION_EMAIL_SUCCESS = '[Auth] Request verification email success';
export const REQUEST_VERIFICATION_EMAIL_FAILURE = '[Auth] Request verification email failure';

export const UPDATE_SHIPPER_PROFILE_TO_STATE = '[SHIPPER] Update Shipper Profile';
export const UPDATE_ADMIN_PROFILE_TO_STATE = '[SHIPPER] Update Admin Profile';
export const UPDATE_HAS_ORDERS = '[Auth] Update Auth Shipper Has Orders';
export const UPDATE_AUTH_SHIPPER_CAN_CREATE_SHIPMENT = '[Auth] Update Auth Shipper Can Create Shipment';
export const UPDATE_AUTH_SHIPPER_STATUSES = '[Auth] Update Auth Shipper Statuses';

export const REQUEST_CHANGE_PASSWORD = '[Auth] Request change password';
export const REQUEST_CHANGE_PASSWORD_SUCCESS = '[Auth] Request change password success';
export const REQUEST_CHANGE_PASSWORD_FAILURE = '[Auth] Request change password failure';

export const CLEAR_CHANGE_PASSWORD_VALIDATION_RESULT = '[Auth] Clear change password validation result';
export const CLEAR_CHANGE_PASSWORD_OLD_PASSWORD_MSG = '[Auth] Clear change password error msgs for old password';
export const CLEAR_CHANGE_PASSWORD_NEW_PASSWORD_MSG = '[Auth] Clear change password error msgs for new password';

export const SHOW_MODAL = '[Auth] Modal show';
export const HIDE_MODAL = '[Auth] Modal hide';

export const UPDATE_SHOW_SUSPEND_ALERT = '[Auth] Update show suspent alert status';

@Injectable()
export class AuthActions {
    @dispatch()
    initRefreshDone = (): AuthAction => ({
        type: INIT_REFRESH_DONE,
        payload: null,
    })
    @dispatch()
    initRefreshReset = (): AuthAction => ({
        type: INIT_REFRESH_RESET,
        payload: null,
    })

    @dispatch()
    login = (auth: Credentials): AuthAction => ({
        type: LOGIN,
        payload: auth,
    })

    @dispatch()
    loginFCM = (auth: Credentials): AuthAction => ({
        type: LOGIN_FCM,
        payload: auth,
    })

    @dispatch()
    getPermissionFCM = (auth: Credentials): AuthAction => ({
        type: GET_PERMISSION_FCM,
        payload: auth,
    })

    @dispatch()
    logout = (): AuthAction => ({
        type: LOGOUT,
        payload: null
    })

    logoutSucceeded = (): AuthAction => ({
        type: LOGOUT_SUCCESS,
        payload: null
    })

    logoutFailed = (error: any): AuthAction => ({
        type: LOGOUT_FAILURE,
        payload: null,
        error
    })

    @dispatch()
    loginRedirect = (): AuthAction => ({
        type: LOGIN_REDIRECT,
        payload: null,
    })

    loginSucceeded = (authUser: AuthUserFull): AuthAction => ({
        type: LOGIN_SUCCESS,
        payload: authUser,
    })

    loginFailed = (error: any): AuthAction => ({
        type: LOGIN_FAILURE,
        payload: error
    })

    @dispatch()
    updateCurrentUser = (authUser: AuthUserFull): AuthAction => ({
        type: UPDATE_CURRENT_USER,
        payload: authUser,
    })

    @dispatch()
    homePageRedirect = (user: UserProfile): AuthAction => ({
        type: HOME_PAGE_REDIRECT,
        payload: user,
    })

    @dispatch()
    createAccessToken = (): AuthAction => ({
        type: CREATE_ACCESS_TOKEN,
        payload: null,
    })

    createAccessTokenSucceeded = (authUser: ComplexToken): AuthAction => ({
        type: CREATE_ACCESS_TOKEN_SUCCESS,
        payload: authUser,
    })

    createAccessTokenFailed = (error: any): AuthAction => ({
        type: CREATE_ACCESS_TOKEN_FAILURE,
        payload: null,
        error,
    })

    @dispatch()
    requestForgotPassword = (email: string): AuthAction => ({
        type: REQUEST_FORGOT_PASSWORD,
        payload: email
    })

    requestForgotPasswordSucceeded = (email: string): AuthAction => ({
        type: REQUEST_FORGOT_PASSWORD_SUCCESS,
        payload: email
    })
    requestForgotPasswordFailed = (error: any): AuthAction => ({
        type: REQUEST_FORGOT_PASSWORD_FAILURE,
        payload: error
    })

    @dispatch()
    requestResetPassword = (email: string): AuthAction => ({
        type: REQUEST_RESET_PASSWORD,
        payload: email
    })

    requestResetPasswordSucceeded = (authUser: AuthUserFull): AuthAction => ({
        type: REQUEST_RESET_PASSWORD_SUCCESS,
        payload: authUser
    })

    requestResetPasswordFailed = (error: any): AuthAction => ({
        type: REQUEST_RESET_PASSWORD_FAILURE,
        payload: error
    })

    @dispatch()
    validateNewPassword = (password: string): AuthAction => ({
        type: VALIDATE_NEW_PASSWORD,
        payload: password
    })

    validateNewPasswordSucceeded = (validationResult: PasswordValidationVO): AuthAction => ({
        type: VALIDATE_NEW_PASSWORD_SUCCESS,
        payload: validationResult
    })

    validateNewPasswordFailed = (error: any): AuthAction => ({
        type: VALIDATE_NEW_PASSWORD_FAILURE,
        payload: null,
        error,
    })

    @dispatch()
    passwordResetRedirect = (token: string): AuthAction => ({
        type: PASSWORD_RESET_REDIRECT,
        payload: token
    })

    @dispatch()
    requestVerificationEmail = (): AuthAction => ({
        type: REQUEST_VERIFICATION_EMAIL,
        payload: null
    })

    requestVerificationEmailSucceded = (): AuthAction => ({
        type: REQUEST_VERIFICATION_EMAIL_SUCCESS,
        payload: null
    })

    requestVerificationEmailFailed = (error: any): AuthAction => ({
        type: REQUEST_VERIFICATION_EMAIL_FAILURE,
        payload: error
    })

    @dispatch()
    updateShipperProfileToState = (shipperProfile: ShipperProfileDto): AuthAction => ({
        type: UPDATE_SHIPPER_PROFILE_TO_STATE,
        payload: shipperProfile
    })

    @dispatch()
    updateAdminProfileToState = (adminProfile: ShipperProfileDto): AuthAction => ({
        type: UPDATE_ADMIN_PROFILE_TO_STATE,
        payload: adminProfile
    })

    @dispatch()
    updateHasOrders = (hasOrders: boolean): AuthAction => ({
        type: UPDATE_HAS_ORDERS,
        payload: hasOrders
    })

    @dispatch()
    updateShipperStatuses = (shipperProfile: ShipperProfileDto): AuthAction => ({
        type: UPDATE_AUTH_SHIPPER_STATUSES,
        payload: shipperProfile
    })

    @dispatch()
    requestChangePassword = (passwords: any): AuthAction => ({
        type: REQUEST_CHANGE_PASSWORD,
        payload: passwords
    })

    requestChangePasswordSucceeded = (changePasswordResult: PasswordValidationVO): AuthAction => ({
        type: REQUEST_CHANGE_PASSWORD_SUCCESS,
        payload: changePasswordResult
    })

    requestChangePasswordFailed = (error: any): AuthAction => ({
        type: REQUEST_CHANGE_PASSWORD_FAILURE,
        payload: error,
    })

    @dispatch()
    clearChangePasswordOldPasswordError = (): AuthAction => ({
        type: CLEAR_CHANGE_PASSWORD_OLD_PASSWORD_MSG,
        payload: null
    })

    @dispatch()
    clearChangePasswordNewPasswordError = (): AuthAction => ({
        type: CLEAR_CHANGE_PASSWORD_NEW_PASSWORD_MSG,
        payload: null
    })

    @dispatch()
    clearChangePasswordValidationResult = (): AuthAction => ({
        type: CLEAR_CHANGE_PASSWORD_VALIDATION_RESULT,
        payload: null
    })

    @dispatch()
    showModal = (): AuthAction => ({
        type: SHOW_MODAL,
        payload: null
    })
    @dispatch()
    hideModal = (): AuthAction => ({
        type: HIDE_MODAL,
        payload: null
    })

    @dispatch()
    goToHome = (): AuthAction => ({
        type: GO_TO_HOME,
        payload: null
    })

    @dispatch()
    goToPageOrders = (): AuthAction => ({
        type: GO_TO_ORDERS,
        payload: null
    })

    @dispatch()
    updateShowSuspendStatus = (showAlert: boolean): AuthAction => ({
        type: UPDATE_SHOW_SUSPEND_ALERT,
        payload: showAlert
    })
}