import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select } from '@angular-redux/store';
import { MarkerUrls } from '../../../core/constants/general.constants';
import { ShipmentSelectors } from '../../../shipment/selectors/shipment.selectors';
import { LatLng, Marker } from '../../../core/models/map.model';
import { StopDto } from '../../models/stop.model';
import { BuilderSelectors } from '../../selectors/builder.selectors';

@Component({
  selector: 'tuya-builder-map',
  templateUrl: './builder-map.component.html',
  styleUrls: ['./builder-map.component.scss']
})
export class BuilderMapComponent implements OnInit {

  @select(ShipmentSelectors.mapCenterSelector)
  mapCenter$: Observable<LatLng>;

  @select(BuilderSelectors.getStops)
  stops$: Observable<StopDto[]>;

  constructor() {
  }

  ngOnInit() {
  }

  public createMarkers(stops: StopDto[]) {

    const markers = [];

    stops.forEach(stop => {
      const latLng: LatLng = {
        lat: Number(stop.address.latitude),
        lng: Number(stop.address.longitude)
      };
      const marker: Marker = {
        latLng: latLng,
        stopId: String(stop.id),
        infoWindow: {
          infoWindowHeader: stop.nameOrDescription,
          infoWindowBody:  stop.address.addressLine
        },
      };
      if (stop.typeId) {
        marker.activeIconUrl = MarkerUrls[stop.typeId].activeIconUrl;
        marker.iconUrl = MarkerUrls[stop.typeId].iconUrl;
      }
      if (latLng.lat !== 0 && latLng.lng !== 0) {
        markers.push(marker);
      }
    });
    return markers;
  }
}