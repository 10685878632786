<div [formGroup]="form">
  <div class="row">
    <div class="form-group col-md-12"
      [ngClass]="{'has-error': (showErrors || form.get('addressString').dirty) && form.get('addressString').invalid }">
      <input title="Address" type="text" class="form-control" [ngClass]="{ 'filled':  form.get('addressString').value }"
        formControlName="addressString" maxlength="75" />
      <span class="reminder">{{ 'tuya-register.placeholder.address-string' | translate }}</span>
      <tuya-form-ctrl-errr-printer
        *ngIf="(showErrors || form.get('addressString').dirty) && form.get('addressString').invalid"
        [ctrlName]="'addressString'" [ctrl]="form.get('addressString')"></tuya-form-ctrl-errr-printer>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6"
      [ngClass]="{'has-error': (showErrors || form.get('suiteNumber').dirty) && form.get('suiteNumber').invalid}">
      <input title="Suite" type="text" class="form-control" [ngClass]="{ 'filled':  form.get('suiteNumber').value }"
        formControlName="suiteNumber" maxlength="10" />
      <span class="reminder">{{ 'tuya-register.placeholder.suite-number' | translate }}</span>
      <div [ngClass]="{ 'has-error': showErrors || isDirtyAndInvalid('suiteNumber') }">
        <tuya-form-ctrl-errr-printer *ngIf="showErrors || isDirtyAndInvalid('suiteNumber')" [ctrlName]="'suiteNumber'"
          [ctrl]="form.get('suiteNumber')"></tuya-form-ctrl-errr-printer>
      </div>
    </div>
    <div class="form-group col-md-6"
      [ngClass]="{'has-error': (showErrors || form.get('city').dirty) && form.get('city').invalid}">
      <input title="City" type="text" class="form-control" [ngClass]="{ 'filled':  form.get('city').value }" formControlName="city"
        maxlength="75" required />
      <span class="reminder">{{ 'tuya-register.placeholder.city' | translate }}</span>
      <div [ngClass]="{ 'has-error': showErrors || isDirtyAndInvalid('city') }">
        <tuya-form-ctrl-errr-printer *ngIf="showErrors || isDirtyAndInvalid('city')" [ctrlName]="'city'"
          [ctrl]="form.get('city')"></tuya-form-ctrl-errr-printer>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-6"
      [ngClass]="{ 'form-state': form.get('state').value, 'has-error': (showErrors || form.get('state').dirty) && form.get('state').invalid}">
      <label *ngIf="form.get('state').value" class="label-errors"
        [translate]="'tuya-register.placeholder.state'"></label>
      <div class="select-wrapper">
        <select title="State" class="form-control-select" formControlName="state">
          <option [value]="null" hidden [translate]="'tuya-register.placeholder.state'"></option>
          <option *ngFor="let item of states" [value]="item.id">{{ item.shortName }}</option>
        </select>
      </div>
      <div [ngClass]="{'has-error': showErrors || isDirtyAndInvalid('state') }">
        <tuya-form-ctrl-errr-printer *ngIf="showErrors || isDirtyAndInvalid('state')" [ctrlName]="'state'"
          [ctrl]="form.get('state')"></tuya-form-ctrl-errr-printer>
      </div>
    </div>
    <!-- [textMask]="{mask: postalMask, placeholderChar: '\u2000'}" -->
    <div class="form-group col-md-6"
      [ngClass]="{'has-error': (showErrors || form.get('zipcode').dirty) && form.get('zipcode').invalid}">
      <input title="Zip Code" type="text" class="form-control" [ngClass]="{ 'filled':  form.get('zipcode').value }"
        formControlName="zipcode" mask="00000">
      <span class="reminder">{{ 'tuya-register.placeholder.zipcode' | translate }}</span>
      <div [ngClass]="{'has-error': showErrors || isDirtyAndInvalid('zipcode') }">
        <tuya-form-ctrl-errr-printer *ngIf="showErrors || isDirtyAndInvalid('zipcode')" [ctrlName]="'zipcode'"
          [ctrl]="form.get('zipcode')"></tuya-form-ctrl-errr-printer>
      </div> <!-- row -->
    </div>
  </div> <!-- row -->
  <!-- <div [ngClass]="{'has-error': showErrors || isDirtyAndInvalid('state') }">
    <tuya-form-ctrl-errr-printer
        *ngIf="showErrors || isDirtyAndInvalid('state')"
        [ctrlName]="'state'"
        [ctrl]="form.get('state')"
    ></tuya-form-ctrl-errr-printer>
  </div>
  <div [ngClass]="{'has-error': showErrors || isDirtyAndInvalid('zipcode') }">
    <tuya-form-ctrl-errr-printer
      *ngIf="showErrors || isDirtyAndInvalid('zipcode')"
      [ctrlName]="'zipcode'"
      [ctrl]="form.get('zipcode')"
    ></tuya-form-ctrl-errr-printer>
  </div> row -->
</div>