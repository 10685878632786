import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { Router } from '@angular/router';
import {
    DeliveryStopActions,
    OPEN_NEW_DELIVERY_STOP,
    SAVE_DELIVERY_STOP_ITEMS
} from '../actions/delivery-stop.actions';
import { exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class DeliveryStopEpics {
    constructor(private deliveryStopActions: DeliveryStopActions,
                private router: Router) {
    }

    public createEpic() {
        return combineEpics(
            createEnterDeliveryStopFieldsEpic(this.deliveryStopActions, this.router),
            createSaveDeliveryStopItemsEpic(this.deliveryStopActions, this.router)
        );
    }
}

export function createEnterDeliveryStopFieldsEpic(actions: DeliveryStopActions, router: Router) {
    return action$ => action$.pipe(
        ofType(OPEN_NEW_DELIVERY_STOP),
        exhaustMap((action) => {
            return of(actions.openNewSuccess());
        })
    );
}

export function createSaveDeliveryStopItemsEpic(actions: DeliveryStopActions, router: Router) {
    return action$ => action$.pipe(
        ofType(SAVE_DELIVERY_STOP_ITEMS),
        exhaustMap((action: any) => {
            // ToDo: return success and show next step
            // ToDo: call service to save items list
            console.debug(action.payload, 'SAVE_DELIVERY_STOP_ITEMS');
            return of();
        })
    );
}
