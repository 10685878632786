<div class="wrapper">
    <div class="driver-info">
        <div class="driver-avatar">
            <img class="img-circle"
                [src]="driverImageUrl"
                alt="driver avatar">
            <div class="avatar-icon" *ngIf="trackingInfo.driverUserAccountId"></div>
        </div>
        <div class="driver-block">
            <div *ngIf="trackingInfo.driverUserAccountId">
                <div class="driver-name">
                    <span class="name">{{trackingInfo.driverName}}</span>
                    <span class="driver-text" [translate]="'tuya-tracking.tuya-tracking-info.tuya-tracking-driver-info.delivery-professional'"></span>
                </div>
            </div>
                <div class="no-driver" *ngIf="!trackingInfo.driverUserAccountId"
                  [translate]="'tuya-tracking.tuya-tracking-info.tuya-tracking-driver-info.awaiting-driver'"></div>
            <div class="scheduled-arrival">
                <span class="uppercase" [translate]="'tuya-tracking.tuya-tracking-info.tuya-tracking-driver-info.scheduled-arrival'"></span>
                <span class="arrival-time">{{ trackingInfo.stopInfo.pickupNoEarlierThan | date:'M/dd/yyyy, h:mm a' }} - {{ trackingInfo.stopInfo.pickupNoLaterThan | date:'M/dd/yyyy, h:mm a' }}</span>
            </div>
        </div>
    </div>
</div>
