import { Component, OnInit } from '@angular/core';
import { NgRedux, select, select$ } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { WizardStep } from '../../models/ui.model';
import { UIActions } from '../../actions/ui.actions';
import { OrderItemVo, Stop, StopType } from '../../models/order.model';
import { getCurrentStop, getRelatedStop, ShipmentSelectors } from '../../selectors/shipment.selectors';
import { ShipmentActions } from '../../actions/shipment.actions';
import { BoxSizeDto, StopDto } from '../../../core/models/dto';
import { ShipmentType } from '../../../core/models/shipment.model';
import { OrderSelectors } from '../../selectors/order.selectors';
import { OrderStopsActions } from '../../actions/order-stops.actions';
import { ShipmentEditSelectors } from '../../../shipment-edit/selectors/shipment-edit.selectors';
import { OrderEditMode } from '../../../core/models/order.dto';
import { StopPickupTimeUtils } from '../../utils/stop-pickup-time.utils';
import { IAppState } from '../../../store/model';

@Component({
    selector: 'tuya-shipment-stop-details-page',
    templateUrl: './shipment-stop-details-page.component.html'
})
export class ShipmentStopDetailsPageComponent implements OnInit {
    @select(OrderSelectors.orderStopListSelector)
    orderStopList$: Observable<Stop[]>;
    @select(ShipmentSelectors.wizardStepSelector)
    wizardStep$: Observable<number>;
    @select$ (['shipment', 'order'], getCurrentStop)
    currentStop$: Observable<Stop>;
    @select(OrderSelectors.orderDeliveryTypeSelector)
    orderDeliveryType$: Observable<number>;
    @select (OrderSelectors.stopDetailsEditSelector)
    stopDeitalsEdit$: Observable<StopDto | null>;
    @select(OrderSelectors.orderItemListSelector)
    orderItemList$: Observable<OrderItemVo[]>;
    @select (ShipmentSelectors.itemsSizesSelector)
    itemsSizes$: Observable<BoxSizeDto[]>;
    @select(ShipmentSelectors.isStopDetailsEditModeSelector)
    isStopDetailsEditMode$: Observable<boolean>;
    @select(ShipmentSelectors.defaultItemSizeSelector)
    defaultItemSize$: Observable<number>;
    @select(getRelatedStop)
    relatedStop$: Observable<Stop|undefined>;
    @select(OrderSelectors.isDirectOrderSelector)
    isDirect$: Observable<boolean>;
    @select(OrderSelectors.isInitialPickupStop)
    isInitialPickupStop$: Observable<boolean>;
    @select(ShipmentEditSelectors.selectEditMode)
    orderEditMode$: Observable<OrderEditMode>;


    private currentStop = new BehaviorSubject<Stop>(new Stop());
    private orderDeliveryType = new BehaviorSubject<number>(0);
    private orderStopList = new BehaviorSubject<Stop[]>([]);
    private wizardStep = new BehaviorSubject<number>(0);
    private isDirect = new BehaviorSubject<boolean>(false);
    private orderEditMode = new BehaviorSubject<OrderEditMode>(0);

    private orderItemList = new BehaviorSubject<OrderItemVo[]>([]);

    constructor(private uiActions: UIActions,
                private ngRedux: NgRedux<IAppState>,
                private shipmentActions: ShipmentActions,
                private orderStopsActions: OrderStopsActions) {

        this.currentStop$.subscribe(this.currentStop);
        this.isDirect$.subscribe(this.isDirect);
        this.wizardStep$.subscribe(this.wizardStep);
        this.orderStopList$.subscribe(this.orderStopList);
        this.orderDeliveryType$.subscribe(this.orderDeliveryType);
        this.orderItemList$.subscribe(this.orderItemList);
        this.orderEditMode$.subscribe(this.orderEditMode);
    }

    ngOnInit() {
    }

    onUpdateStop(stopUpdates, sendOrderUpdate = true) {
        // Local stop update ( sync )
        this.shipmentActions.updateOrderStop({
            ...stopUpdates,
            stopId: this.currentStop.getValue().id
        });

        // async send
        if (sendOrderUpdate) {
            this.shipmentActions.shipmentOrderUpdate();
        }
    }

    onItemsUpdate(updatedItems) {
        const currentStop = this.currentStop.getValue();
        if (!currentStop || updatedItems === null || updatedItems === undefined || updatedItems.length < 1) {
            return;
        }

        const targetStop = this.orderDeliveryType.getValue() === ShipmentType.Distribution ?
                this.orderStopList.getValue().find(stop => stop.typeId === StopType.Pickup) :
                this.orderStopList.getValue().find(stop => stop.typeId === StopType.Delivery),
            targetStopId = targetStop ? targetStop.id : undefined;

        this.shipmentActions.updateOrderItems(updatedItems, targetStopId, currentStop.id);
    }

    saveStopDetails(detailsUpdate) {

        this.uiActions.loading(true);
        this.onItemsUpdate(detailsUpdate.items);
        this.onUpdateStop(detailsUpdate.details, false);
        // if isDirect order parameter was changed - update stops list and than send request to update order,
        // otherwise just send update order request
        if (this.isDirect.getValue() !== detailsUpdate.isDirect) {
            this.orderStopsActions.setIsDirectOrder(detailsUpdate.isDirect, true);
        } else {
            this.shipmentActions.shipmentOrderUpdate();
        }

        if (this.isDirect.getValue()) {
            this.uiActions.setWizardStep(WizardStep.Stops);
            return;
        }

        // go to stop list screen
        const editMode = this.orderEditMode.getValue(),
            isValidateTime = (editMode === OrderEditMode.NoEdit
                || editMode === OrderEditMode.NotAccepted) ? true :
                ShipmentEditSelectors.selectIsValidateOrder(this.ngRedux.getState());
        // validate timeframes
        const invalidStopIds = StopPickupTimeUtils.validateTimeframesForShipmentBuilder(new Date(),
            this.orderStopList.getValue(),
            this.orderDeliveryType.getValue());
        if (isValidateTime) {
            this.orderStopsActions.saveInvalidTimeframesStops(invalidStopIds);
        }

        this.uiActions.setWizardStep(WizardStep.Stops);
    }

    onGoBack() {
        this.uiActions.setWizardStep(WizardStep.Stops);
    }

    onDetailsTouched() {
        this.uiActions.setWizardStepContinueAllowed(this.wizardStep.getValue(), false);
    }

    onDefaultSizeUpdate (newSizeId: number) {
        localStorage.setItem('defaultItemSize', JSON.stringify(newSizeId));
        this.uiActions.setDefaultItemSize(newSizeId);
    }
}
