<div class="driver-from-wrapper">
    <form class="driver-form" [formGroup]="driverFormProp" autocomplete="off" novalidate
        (ngSubmit)="onSubmitDriver(driverFormProp.value)">

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>First Name</mat-label>
            <input title="First Name" matInput formControlName="firstname" [errorStateMatcher]="matcher" />
            <mat-error>
                <span *ngIf="!driverFormProp.get('firstname').valid && 
                            driverFormProp.get('firstname').touched">
                    First Name is required
                </span>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Last Name</mat-label>
            <input title="Last Name" matInput formControlName="lastname" [errorStateMatcher]="matcher" />
            <mat-error>
                <span *ngIf="!driverFormProp.get('lastname').valid && 
                            driverFormProp.get('lastname').touched">
                    Last Name is required
                </span>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Physical Address</mat-label>
            <input title="Address" matInput formControlName="physicalAddress" [errorStateMatcher]="matcher" />
            <mat-error>
                <span *ngIf="!driverFormProp.get('physicalAddress').valid && 
                            driverFormProp.get('physicalAddress').touched">
                    Physical Address is required
                </span>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>City</mat-label>
            <input title="City" matInput formControlName="city" [errorStateMatcher]="matcher" />
            <mat-error>
                <span *ngIf="!driverFormProp.get('city').valid &&
                            driverFormProp.get('city').touched">
                    City is required
                </span>
            </mat-error>
        </mat-form-field>

        <div class="state-row">
            <mat-form-field appearance="outline" class="half-width">
                <mat-label>State</mat-label>
                <mat-select formControlName="state" [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let state of states" [value]="state.shortName">
                        {{ state.shortName }}
                    </mat-option>
                </mat-select>

                <mat-error>
                    <span *ngIf="!driverFormProp.get('state').valid &&
                                driverFormProp.get('state').touched">
                        State is required
                    </span>
                </mat-error>
            </mat-form-field>


            <!-- [textMask]="{mask: zipCodeeMask, guide: false}" -->
            <mat-form-field appearance="outline" class="half-width">
                <mat-label>Zip Code</mat-label>
                <input title="Zip Code" matInput mask="00000" shownMaskExpression="'#####'" formControlName="zipCode"
                    [errorStateMatcher]="matcher" />
                <mat-error>
                    <span *ngIf="!driverFormProp.get('zipCode').valid &&
                                driverFormProp.get('zipCode').touched">
                        Zip Code is required
                    </span>
                </mat-error>
            </mat-form-field>
        </div>

        <!-- [textMask]="{mask: phoneMask, guide: false}" -->
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Phone Number</mat-label>
            <input title="Phone Number" matInput mask="(000) 000-0000" formControlName="phoneNumber"
                [errorStateMatcher]="matcher" />
            <mat-error>
                <span *ngIf="!driverFormProp.get('phoneNumber').valid &&
                            driverFormProp.get('phoneNumber').touched">
                    Phone Number is required
                </span>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Email Address</mat-label>
            <input title="Email Address" placeholder="example@*********" matInput formControlName="emailAddress" [errorStateMatcher]="matcher" />
            <mat-error>
                <span *ngIf="!driverFormProp.get('emailAddress').valid &&
                            driverFormProp.get('emailAddress').touched">
                    Email address is required
                </span>
            </mat-error>
        </mat-form-field>

        <div class="state-row">
            <mat-form-field appearance="outline" class="half-width">
                <mat-label>Service Area</mat-label>
                <mat-select formControlName="serviceArea" [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let area of serviceAreasProp" [value]="area.id">
                        {{ area.name }}
                    </mat-option>
                </mat-select>

                <mat-error>
                    <span *ngIf="!driverFormProp.get('serviceArea').valid &&
                                driverFormProp.get('serviceArea').touched">
                        Service Area is required
                    </span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="half-width">
                <mat-label>CXT Number</mat-label>
                <input title="CXT Number" matInput formControlName="cxtNumber" [errorStateMatcher]="matcher" />
                <mat-error>
                    <span *ngIf="!driverFormProp.get('cxtNumber').valid &&
                                driverFormProp.get('cxtNumber').touched">
                        CXT Number is required
                    </span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="button-row" style="flex: 1">
            <button title="Submit" mat-raised-button class="cancel" (click)="onCloseModal()">Cancel</button> &nbsp; &nbsp;
            <button type="submit" mat-raised-button class="submit" matStepperNext>Next</button>
        </div>
    </form>
</div>