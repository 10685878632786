import { AddressDto, DriverDto, PoolSelectDto, StopDto } from './dto';

export class SummaryDto {
    items: number;
    stops: number;
    lbs: number;
    miles: number;
    returns: number;
}

export class SelectedCandidatesDto {
    drivers: DriverDto[];
    pools: PoolSelectDto[];
}

export class OrderDto {
    id: any;
    userAccountId: any;
    assignments: AssignmentsDto;
    readonly selectedCandidates: SelectedCandidatesDto;
    deliveryTypeId: number; // 0 means undefined
    offerType: number;
    driverAssignmentTypeId?: number; // no longer used, delete when removing old shipment builder code
    isDirect: boolean;
    isExtendable: boolean;
    total: number;
    statusId: number;
    // ... other required order-specific fields
    orderCode: any;
    addresses: AddressDto[];  // Map of all used addresses tied by unique id
    stops: StopDto[];
    orderItems: OrderItemDto[];
    orderSummary?: SummaryDto;
    vehicleType: VehicleTypeDto;
    referenceId?: any;
}

export class AssignmentsDto {
    drivers: Array<AssignedDriverDto>;
    pools: PoolSelectDto[];
    hasAssignedDrivers: boolean;
}

export class AssignedDriverDto {
    fullName: string;
    userAccountId: any;
}

export class Reference {
    id: any;
    name: string;
}

export class OrderItemDto {
    id: any;
    orderId: any;
    readonly directId: any;
    piecesCount: number;
    sizeType: number;
    weight: number;
    reference: Reference;
    description: string;
    stopIds: any[]; // Guids
}

export interface VehicleTypeDto {
    id: any;
    maxDimension: number;
    name: string;
    usableVolume: number;
}

export const VehicleTypeIds = {
    0: {name: 'builderDriverSelection.vehicleTypes.unknown'},
    1: {name: 'builderDriverSelection.vehicleTypes.sedan'},
    2: {name: 'builderDriverSelection.vehicleTypes.suv'},
    3: {name: 'builderDriverSelection.vehicleTypes.pickup'},
    4: {name: 'builderDriverSelection.vehicleTypes.van'}
};

export enum OrderStatusName {
    Draft = 'Draft',
    Offered = 'Offered',
    Assigned = 'Assigned',
    InProgress = 'In Progress',
    Declined = 'Declined',
    Completed = 'Completed',
    CompletedWithExceptions = 'Completed',
    Canceled = 'Cancelled',
    Released = 'Released',
    PendingCancellation = 'Pending Cancellation',
    Scheduled = 'Scheduled',
    FutureAssigned = 'Future Assigned',
}

export const OrderStatuses = {
    10: {name: OrderStatusName.Draft, color: 'yellow'},
    20: {name: OrderStatusName.Offered, color: 'yellow'},
    30: {name: OrderStatusName.Assigned, color: 'blue'},
    35: {name: OrderStatusName.InProgress, color: 'blue'},
    40: {name: OrderStatusName.Declined, color: 'red'},
    50: {name: OrderStatusName.Completed, color: 'green'},
    55: {name: OrderStatusName.CompletedWithExceptions, color: 'green'},
    60: {name: OrderStatusName.Canceled, color: 'red'},
    61: {name: OrderStatusName.PendingCancellation, color: 'red'},
    70: {name: OrderStatusName.Released, color: 'red'},
    120: {name: OrderStatusName.Scheduled, color: 'blue'},
    130: {name: OrderStatusName.FutureAssigned, color: 'blue'}
};

export enum OrderEditMode {
    NoEdit, // no edit at all
    Other, // any not supported status
    NotAccepted, // Draft-Offered
    Accepted, // Assigned but not completed stops
    PartiallyCompleted  // any step already completed
}
