<div class="error-page-wrapper">
  <div class="main-logo" (click)="goBack()">
    <img src="../../../../assets/img/login-page/main_logo.png">
  </div>
  <div class="text-wrapper">
    <div class="txt">
        <div class="status-code">404</div>
        <div class="status-text">Something's wrong.</div>
        <div class="text">
          We can't find the page you're looking for.
          <p (click)="goBack()">Go back</p>
        </div>
    </div>
    
    <div class="img">
      <img src="../../../../assets/img/login-page/no-way.jpg">
    </div>
</div>
</div>