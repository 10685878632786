import { DeactivateCompanyGuard } from './auth/guards/deactivate-company.guard';
import { DeactivateProfileGuard } from './auth/guards/deactivate-profile.guard';
import { PaymentPageComponent } from './home/containers/payment.page/payment.page.component';
import { CompanyPageComponent } from './profiles/containers/company-page/company-page.component';
import { ProfilePageComponent } from './profiles/containers/profile-page/profile-page.component';
import { AdminPanelComponent } from './admin/containers/admin-panel/admin-panel.component';
import { Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { LoginGuard } from './auth/guards/login.guard';
import { LoginComponent } from './auth/containers/login/login.component';
import { ForgotPasswordComponent } from './auth/containers/forgot-password/forgot-password.component';
import { HomePageComponent } from './home/containers/home-page/home.page';
import { RegisterPageComponent } from './register/containers/register.page.component';
import { ShipmentPageComponent } from './shipment/containers/shipment-page/shipment.page';
import { MyOrdersPageComponent } from './home/containers/my-orders.page/my-orders.page.component';
import { DriversPageComponent } from './home/containers/drivers.page/drivers.page.component';
import { ShipmentInitialPageComponent } from './shipment/containers/shipment-initial-page/shipment-initial-page.component';
import { ShipmentBuilderPageComponent } from './shipment/containers/shipment-builder-page/shipment-builder-page.component';
import { ShipmentSummaryPageComponent } from './shipment/containers/shipment-summary-page/shipment-summary-page.component';
import { DeactivateShipmentGuard } from './shipment/guards/deactivate-shipment.guard';
import { OrderDetailsPageComponent } from './home/containers/order-details.page/order-details.page.component';
import { LogoutComponent } from './auth/containers/logout/logout.component';
import { OrdersListGuard } from './auth/guards/orders-list.guard';
import { TrackingPageComponent } from './tracking/containers/tracking-page/tracking-page.component';
import { TrackingGuard } from './tracking/guards/tracking.guard';
import { ErrorPageComponent } from './shared/containers/error-page.component';
import { ForgotPasswordGuard } from './auth/guards/forgot-password.guard';
import { DriverHomeComponent } from './driver/driver-home.component';
import { DriverHomeGuard } from './auth/guards/driver-home.guard';
import { DriverResetPasswordComponent } from './driver/change-password/change-password.component';
import { ShipperMobileHomeComponent } from './mobile/mobile-shipper-home.component';
import { AdminHomeComponent } from './admin/containers/admin-home/admin-home.component';
import { BuilderInputComponent } from './builder/containers/builder-input/builder-input.component';
import { CanDeactivateBuilderGuard } from './builder/guards/can-deactivate.guard';
import { CanActivateBuilderGuard } from './builder/guards/can-activate-builder.guard';
import { HomeGuard } from './auth/guards/home.guard';
import { Page404Component } from './core/containers/page-404/page-404.component';
import { DriverPortalComponent } from './admin/containers/driver-portal/driver-portal.component';

export const appRoutes: Routes = [
    { path: '', redirectTo: 'orders', pathMatch: 'full' },
    { path: 'tracking', component: TrackingPageComponent, canActivate: [TrackingGuard] },
    { path: 'driver', canActivate: [DriverHomeGuard],
        children: [
            { path: 'home', component: DriverHomeComponent },
            { path: 'resetPassword', component: DriverResetPasswordComponent }
        ]
    },
    { path: 'mobile', canActivate: [DriverHomeGuard],
        children: [
            { path: 'home', component: ShipperMobileHomeComponent }
        ]
    },
    { path: 'home', component: HomePageComponent, canActivate: [AuthGuard, HomeGuard] },
    { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
    { path: 'logout', component: LogoutComponent },
    { path: 'resetPassword', canActivate: [ForgotPasswordGuard], component: ErrorPageComponent },
    { path: 'forgotPassword', component: ForgotPasswordComponent },
    { path: 'register', component: RegisterPageComponent },
    { path: 'profile', component: ProfilePageComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateProfileGuard] },
    { path: 'company', component: CompanyPageComponent, canActivate: [AuthGuard], canDeactivate: [DeactivateCompanyGuard] },
    {
        path: 'shipment',
        pathMatch: 'prefix',
        component: ShipmentPageComponent,
        canActivate: [AuthGuard],
        canDeactivate: [DeactivateShipmentGuard],
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'initial' },
            { path: 'initial', component: ShipmentInitialPageComponent },
            { path: 'builder', component: ShipmentBuilderPageComponent },
            { path: 'summary', component: ShipmentSummaryPageComponent }
        ]
     },
    { path: 'shipment', component: ShipmentPageComponent, canActivate: [AuthGuard] },
    { path: 'orders', component: MyOrdersPageComponent, canActivate: [OrdersListGuard] },
    { path: 'order-details', component: OrderDetailsPageComponent, canActivate: [AuthGuard] },
    { path: 'payment', component: PaymentPageComponent, canActivate: [AuthGuard] },
    { path: 'drivers', component: DriversPageComponent, canActivate: [AuthGuard] },
    {
        path: 'builder',
        component: BuilderInputComponent,
        canActivate: [AuthGuard, CanActivateBuilderGuard],
        canDeactivate: [CanDeactivateBuilderGuard]
    },
    // admin restricted routes
    { path: 'admin-panel', component: AdminPanelComponent, canActivate: [AuthGuard], data: { mustBeAdmin: true } },
    { path: 'admin-search', component: AdminHomeComponent, canActivate: [AuthGuard], data: { mustBeAdmin: true } },
    { path: 'driver-portal', component: DriverPortalComponent, canActivate: [AuthGuard], data: { mustBeAdmin: true }},
    // catch
    { path: 'builder/:id', component: BuilderInputComponent, canActivate: [AuthGuard] },
    // Page not found 404
    { path: 'error', component: Page404Component },
    { path: '**', redirectTo: 'error' }
];
