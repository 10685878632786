import { Action } from 'redux';
import { IAlertState } from '../models/model';
import { AlertAction, REMOVE_ALERT, SHOW_ALERT,
         REJECT_INCOMING_ALERTS, ALLOW_INCOMING_ALERTS } from '../actions/alert.actions';

export const initialState: IAlertState = {
    alert: null,
    rejectIncomingAlerts: false
};

export function alertReducer(state = initialState, a: Action): IAlertState {
    switch (a.type) {
        case SHOW_ALERT: {
            const action = a as AlertAction;
            if (state.rejectIncomingAlerts) {
                return {
                    ...state
                };
            }
            return {
                ...state,
                alert: action.payload
            };
        }

        // @TODO clear alert message on route change!!
        case REMOVE_ALERT: {
            return {
                ...state,
                alert: null,
                rejectIncomingAlerts: false
            };
        }

        case REJECT_INCOMING_ALERTS: {
            return {
                ...state,
                rejectIncomingAlerts: true
            };
        }

        case ALLOW_INCOMING_ALERTS: {
            return {
                ...state,
                rejectIncomingAlerts: false
            };
        }

        default: {
            return state;
        }
    }
}
