<div class="fview-wrapper">
  <div class="title">
    Active Filters
  </div>
  <div class="item-wrapper">
      <div *ngFor="let filter of filterItems; let indx=index; trackBy: trackByFn;" class="item">
          <div class="txt">
              <p>{{filter.name}}</p>
              <label *ngIf="filter.name === 'Status'">{{filter.val}}</label>
              <label *ngIf="filter.name !== 'Status'">{{filter.val | date: 'M/dd/yyyy'}}</label>
          </div>
          <div class="remove-btn">
            <img (click)="removeFilter(indx)" src="../../../../assets/img/my-orders/filter-item-close.svg">
          </div>
      </div>
  </div>
  
</div>