import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationLoaderService } from '../core/services/translation-loader.service';
import { locale as english } from './i18n/builder.en';
import { CoreModule } from '../core/module';
import { BuilderEpics } from './epics/builder.epics';
import { BuilderActions } from './actions/builder.actions';
import { SharedModule } from '../shared/module';
import { BuilderService } from './services/builder.service';
import { BuilderInputComponent } from './containers/builder-input/builder-input.component';
import { AddressContactComponent } from './components/address-contact/address-contact.component';
import { TimeComponent } from './components/time/time.component';
import { InstructionsComponent } from './components/instructions/instructions.component';
import { BuilderSummaryComponent } from './components/builder-summary/builder-summary.component';
import { BuilderDriverSelectionComponent } from './components/builder-driver-selection/builder-driver-selection.component';
import { BuilderMapComponent } from './components/builder-map/builder-map.component';
import { ItemSelectorComponent } from './components/item-selector/item-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BuilderSummaryItemComponent } from './components/builder-summary-item/builder-summary-item.component';
import { BuilderStopComponent } from './components/builder-stop/builder-stop.component';
import { SelectModule } from '../core/components/pool-select/select/select.module';
import { BuilderAccordionComponent } from './components/builder-accordion/builder-accordion.component';
import { AddressAutocompleteContainerComponent } from './components/address-autocomplete/autocomplete-address.container';
import { BuilderSubmitErrorComponent } from './components/builder-submit-error/builder-submit-error.component';
import { BuilderPoolFilterComponent } from './components/builder-pool-filter/builder-pool-filter.component';
import { CanDeactivateBuilderGuard } from './guards/can-deactivate.guard';
import { CanActivateBuilderGuard } from './guards/can-activate-builder.guard';
import { NgmatBuilderModule } from './ngmat-builder.module';
import { ScheduleSubmitSelectionComponent } from './components/schedule-submit-selection/schedule-submit-selection.component';
import { BuilderReferenceIdComponent } from './components/builder-reference-id/builder-reference-id.component';
import { VehicleSelectionComponent } from './components/vehicle-selection/vehicle-selection.component';
import { PriceChangeModalComponent } from './components/price-change-modal/price-change-modal.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        CoreModule,
        TranslateModule,
        FormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        SelectModule,
        ReactiveFormsModule,
        NgmatBuilderModule        
    ],
    declarations: [
        BuilderInputComponent,
        AddressContactComponent,
        TimeComponent, InstructionsComponent,
        BuilderSummaryComponent,
        BuilderDriverSelectionComponent,
        BuilderMapComponent,
        ItemSelectorComponent,
        BuilderSummaryItemComponent,
        BuilderStopComponent,
        BuilderAccordionComponent,
        AddressAutocompleteContainerComponent,
        BuilderSubmitErrorComponent,
        BuilderPoolFilterComponent,
        ScheduleSubmitSelectionComponent,
        BuilderReferenceIdComponent,
        VehicleSelectionComponent,
        PriceChangeModalComponent
    ],
    providers: [
        BuilderEpics,
        BuilderActions,
        BuilderService,
        TranslationLoaderService,
        CanDeactivateBuilderGuard,
        CanActivateBuilderGuard,
        [provideNgxMask()]
    ]
})
export class BuilderModule {

  constructor(private translationLoader: TranslationLoaderService, private builderActions: BuilderActions) {
    this.translationLoader.loadTranslations(english);
  }

}
