export enum AlertType {
    Error = 'ERR',
    Cancellable = 'CANCELLABLE',
    NotInMsa = 'NOT IN MSA',
    BadRequest = 'BAD REQUEST',
    Firebase = 'FIREBASE',
    NotInTexas = 'NOT IN TEXAS', // This only appears in old shipment builder. Remove when we remove that module.
    Timeout = 'TimeoutError', // constant is in pascal case to match TimeoutError.name
    Unknown = 'UNKNOWN',
    AdminPanel = 'ADMIN PANEL',
    Suspended = 'SUSPENDED',
    NewPrice = 'NEWPRICE'
}

/**
 * Error structure to be used with error-modal component. This is meant to mimic the structure of HTTP
 * errors returned by the API.
 */
export type Error = {
    status?: string
    statusText?: string,
    url?: string,
    error?: ErrorErrors,
    name?: string
};

export type ErrorErrors = {
    errors: ErrorsArray
};

export type ErrorsArray = ErrorObject[];

export type ErrorObject = {
    [key: string]: string
};

/**
 * Callback function for alert modal. Pass true if "ok" clicked by user, false if "cancel".
 */
export type AlertCallback = (accepted: boolean) => Promise<any>;

export class Alert {
    type: AlertType;
    message?: string;
    error?: Error;
    statusCode?: string;
    callback?: AlertCallback;
    checked?: boolean;
    buttonText?: {
        ok?: string;
        cancel?: string;
    };
}

export enum PopupTypeEnum {
    CreateUpdateOrder = 'CREATE_ORDER', // Create | Update | Delete- recurring, regular and scheduled order
}

export interface Popup {
    type: PopupTypeEnum;
    title: string;
    subTitle: string;
    titleProps?: PopupMessageProperty;
    subTitleProps?: PopupMessageProperty;
    buttonText?: {
        ok?: string;
    };
}

export interface PopupMessageProperty {
    code?: string;
    message?: string;
}
