import { OrderHelper } from './../../utils/order-helper.utils';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderItemVo, Stop, StopType } from '../../models/order.model';
import { ShipmentType } from '../../../core/models/shipment.model';
import { OrderEditMode } from '../../../core/models/order.dto';

@Component({
    selector: 'tuya-shipment-builder',
    templateUrl: 'shipment-builder.component.html',
    styleUrls: ['shipment-builder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShipmentBuilderComponent implements OnInit {

    @Input() startAddress: string;
    @Input() selectedStop: Stop = new Stop();
    @Input() orderItems: OrderItemVo[];
    @Input() shipmentType: ShipmentType;
    @Input() isDirectOrder: boolean;
    @Input() hasInvalidAddress: boolean;
    @Input() editMode: OrderEditMode;
    @Input() invalidTimeframesStopsList: Array<any> = [];
    @Input() isStopsRequiredFieldsProvided: boolean;
    @Output() stopSelect = new EventEmitter<any>();
    @Output() removeStop = new EventEmitter<any>();
    @Output() editStop = new EventEmitter<any>();
    @Output() updateSuiteNumber = new EventEmitter<string>();

    ShipmentType: typeof ShipmentType = ShipmentType;
    StopType: typeof StopType = StopType;
    OrderEditMode: typeof OrderEditMode = OrderEditMode;
    OrderHelper: typeof OrderHelper = OrderHelper;

    public filterStopList: Stop [];

    private _stopList: Stop[];

    @Input() set stopList(value: Stop[]) {
        this._stopList = value;
        this.filterStopList = this._stopList.filter(stop => stop.typeId !== StopType.Return);
    }
    get stopList () {
        return this._stopList;
    }

    constructor() {
    }

    ngOnInit() {}

    // for Collection order - show Delivery on the end of list stop line on the end
    public isShowStopLineOnEnd(stop: Stop) {
        return this.shipmentType === ShipmentType.Collection && stop.typeId === StopType.Delivery;
    }

    public canDelete(currentStop: Stop) {
        switch (this.shipmentType) {
            case ShipmentType.Collection: {
                const pickupItems = this.stopList.filter(stop => stop.typeId === StopType.Pickup);
                return  currentStop.typeId === StopType.Pickup && pickupItems.length > 1;
            }

            case ShipmentType.Distribution: {
                return currentStop.typeId !== StopType.Pickup;
            }

            default: {
                return true;
            }
        }
    }

    public getLastStop() {
        switch (this.shipmentType) {
            case ShipmentType.Collection: {
                const deliveryItem = this.stopList.find(stop => stop.typeId === StopType.Delivery);
                return deliveryItem || new Stop();
            }

            case ShipmentType.Distribution: {
                const returnStop = this.stopList.find(stop => stop.typeId === StopType.Return);
                return returnStop || new Stop();
            }

            default: {
                return new Stop();
            }
        }
    }

    public isInvalidTimeframe(stop: Stop) {
        if (this.ifDisabledInProgress()) { return false; }
        return this.invalidTimeframesStopsList.indexOf(stop.id) !== -1;
    }

    public ifDisabledInProgress() {
        return this.editMode === OrderEditMode.PartiallyCompleted;
    }

    public onStopSelect(stopData: {id, sequenceNumber}) {
        const isFirstStop = stopData.sequenceNumber === 1;
        // if required fields provided for current stop and no stop was selected, or selection is changed - fire event
        if ((isFirstStop || this.isStopsRequiredFieldsProvided) &&
            (!this.selectedStop || this.selectedStop.id !== stopData.id)) {
            this.stopSelect.emit(stopData.id);
        }
    }

    public onRemoveStop(stopId: any) {
        this.removeStop.emit(stopId);
    }

    public onEditStop(stopId: any) {
        this.editStop.emit(stopId);
    }

    public onUpdateSuiteNumber(evt) {
        if (!evt.addressId) { return; }
        this.updateSuiteNumber.emit(evt);
    }

    public trackByFn(index, item) {
        return index;
    }

}
