<div class="event-name-col">
    <p class="name">{{stopName | translate}}</p>
    <p class="note">{{ stopItem.note }}</p>
    <div *ngIf="stopItem.typeId === stopEventType.Complete">
        <p class="items">
            <span class="orderType">{{orderType | translate}}:</span>
            {{eventItems.actualCount}}
            {{'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.of' | translate}}
            {{eventItems.totalCount}}
            {{'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.items' | translate}}
            <span *ngIf="typeId === stopType.DeliveryWithReturn">
                <span class="orderType"> | {{'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.pickup' |
                    translate}}:</span>
                {{eventItems.returnActualCount}}
                {{'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.of' | translate}}
                {{eventItems.returnTotalCount }}
                {{'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.items' | translate}}
            </span>

            <!-- Pickup Completed: Exception at Stop -->
            <span class="exclamation" *ngIf="stopItem.hasException || (stopItem.exceptions !== null && stopItem.exceptions !== 'Ok')">
                <img src="../../../../assets/img/my-orders/ic_triangle_exclamation.svg" alt="Item Exception">
            </span>
        </p>

        <!-- Completion Reason (Error) -->
        <!-- <p class="err" *ngIf="stopItem.exceptions !== null && stopItem.exceptions !== 'Ok'">{{stopItem.exceptions}}</p> -->
    </div>
</div>
<div class="event-status-col">
    <p class="time">{{stopItem.eventTime | date:'M/dd/yyyy, h:mm a' }}</p>
    <p class="status" [ngClass]="{'exception':  stopItem.typeId === stopEventType.Departure}">{{statusText}}</p>
</div>