import { combineReducers } from 'redux';
import { composeReducers, defaultFormReducer } from '@angular-redux/form';
import { routerReducer } from '@angular-redux/router';
import { authReducer } from '../auth/reducers/auth.reducer';
import { shipperReducer } from '../shipper/reducers/shipper.reducer';
import { coreReducer } from '../core/reducers/core.reducers';
import { registerReducer } from '../register/reducers/register.reducers';
import { shipmentReducer } from '../shipment/reducers/shipment.reducer';
import { deliveryReducer } from '../shipment/reducers/delivery-stop.reducer';
import { homeReducer } from '../home/reducers/home.reducer';
import { profilesReducer} from '../profiles/reducers/profiles.reducer';
import { trackingInfoReducer } from '../tracking/reducers/tracking.reducer';
import { shipmentEditReducer } from '../shipment-edit/reducers/shipment-edit.reducer';
import { billingReducer } from '../profiles/reducers/billing.reducer';
import { adminReducer } from '../admin/reducers/admin.reducer';
import { builderReducer } from '../builder/reducers/builder.reducer';

// Define the global store shape by combining our application's
// reducers together into a given structure.
export const rootReducer = composeReducers(
    defaultFormReducer(),
    combineReducers({
        core: coreReducer,
        auth: authReducer,
        router: routerReducer,
        shipment: shipmentReducer,
        shipmentEdit: shipmentEditReducer,
        shipper: shipperReducer,
        register: registerReducer,
        deliveryStop: deliveryReducer,
        home: homeReducer,
        profiles: profilesReducer,
        tracking: trackingInfoReducer,
        billing: billingReducer,
        admin: adminReducer,
        builder: builderReducer
    }));
