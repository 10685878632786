export class SelectItem {
    public id: any;
    public name: string;
    public inPool: boolean;
    public children: Array<SelectItem>;
    public additionalData: any;
    public parent: SelectItem;
    public activated?: boolean;

    public constructor(source: any) {
        if (typeof source === 'string') {
            this.id = this.name = source;
        }
        if (typeof source === 'number') { // need to update conditionals after fully switching to vnext
            this.id = source;
            this.name = source.toString();
        }
        if (typeof source === 'object') {
            this.id = source.id || source.text;
            this.name = source.name;
            this.inPool = source.inPool;
            this.activated = source.activated;
            this.additionalData = source.additionalData;
            if (source.children && source.name) {
                this.children = source.children.map((c: any) => {
                    const r: SelectItem = new SelectItem(c);
                    r.parent = this;
                    return r;
                });
                this.name = source.name;
            }
        }
    }

    public fillChildrenHash(optionsMap: Map<string, number>, startIndex: number): number {
        let i = startIndex;
        this.children.map((child: SelectItem) => {
            optionsMap.set(String(child.id), i++);
        });
        return i;
    }

    public hasChildren(): boolean {
        return this.children && this.children.length > 0;
    }

    public getSimilar(): SelectItem {
        const r: SelectItem = new SelectItem(false);
        r.id = this.id;
        r.name = this.name;
        r.parent = this.parent;
        r.inPool = this.inPool;
        return r;
    }
}
