import { Injectable } from '@angular/core';
import { ConfigService } from './app.config.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsConfigService {
  private apiKey: string;
  private libraries: string[] = ['places'];
  private language: string = 'en';

  constructor(private configService: ConfigService) { }

  loadScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const cfg = this.configService.getConfiguration();
      this.apiKey = cfg['default'].googleMapsKey;

      if (document.getElementById('google-maps-script')) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.id = 'google-maps-script';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=${this.libraries.join(',')}&language=${this.language}`;
      script.async = true;      
      script.defer = true;      
      script.onload = () => resolve();
      script.onerror = (error) => reject(error);

      document.head.appendChild(script); // Make sure to append the script to the document
    });
  }
}
