<div bsModal #staticModal="bs-modal" class="modal fade" role="dialog" [config]="{ignoreBackdropClick: true}">
    <div class="modal-content">
        <div *ngIf="priceOptProccesingCompleted; then priceOptProccesingCompletedTpl else priceOptNotCompletedTpl">
        </div>
    </div>
</div>

<!--Price optimized and process of price optimization has been once -->
<ng-template #priceOptimizedTpl>
    <div class="modal-logo">
    </div>
    <div class="modal-header minus-margin">
        <span [translate]="'tuya-shipment.price-optimization-dialog.title'"></span>
        <span class="price">${{price | number}}</span>
    </div>
    <div class="modal-body modal-info-message">
        <div [translate]="'tuya-shipment.price-optimization-dialog.message-text'"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" (click)="hideModalAsCallback()"
                [translate]="'tuya-shipment.price-optimization-dialog.thanks-button'"></button>
    </div>
</ng-template>

<!--Price optimization completed-->
<ng-template #priceOptProccesingCompletedTpl>
    <div *ngIf="alreadyOptimizedPrice; then alreadyOptimizedTpl else notAlreadyOptimizedTpl" ></div>
</ng-template>

<ng-template #notAlreadyOptimizedTpl>
    <div *ngIf="priceOptimized; then priceOptimizedTpl else priceNotOptTpl"></div>
</ng-template>

<ng-template #alreadyOptimizedTpl>
    <div class="modal-logo">
    </div>
    <div class="modal-header minus-margin">
        <span>{{'tuya-shipment.price-optimization-dialog.price-changed' | translate}}</span>
    </div>
    <div class="price-container">
         <span>
            <span class="price old-price"> {{oldPrice | currency:'USD':'symbol'}}</span>
            <span class="ico"></span>
            <span class="price new-price bold"> {{currentPrice | currency:'USD':'symbol'}}</span>
        </span>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" (click)="hideModalAsCallback()"
                [translate]="'tuya-shipment.price-optimization-dialog.ok-button'"></button>
    </div>
</ng-template>

<!--Processing price optimization-->
<ng-template #priceOptNotCompletedTpl>
    <div class="modal-header">
        <span [translate]="'tuya-shipment.price-optimization-dialog.optimizing-route'"></span>
    </div>
    <div class="modal-body">
        <tuya-spinner></tuya-spinner>
    </div>
</ng-template>

<!--Price not optimized-->
<ng-template #priceNotOptTpl>
    <div class="modal-logo">
    </div>
    <div class="modal-header minus-margin">
        <div [translate]="'tuya-shipment.price-optimization-dialog.message-text-well-done'"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" (click)="staticModal.hide()"
                [translate]="'tuya-shipment.price-optimization-dialog.ok-button'"></button>
    </div>
</ng-template>
