import { Action } from 'redux';

import { MapAction, SET_CENTER } from '../actions/map.actions';
import { MapState } from '../models/map.model';
import { SessionStorageUtil } from 'app/store/sessionStorage';

// Default to Houston, Texas instead of 0, 0 which is in the middle of the ocean
export function getShipperBusinessEntityLocation(): {lat: number, lng: number} {
  const instance = SessionStorageUtil.getInstance();
  const lat = instance.get('auth.currentUser.shipperProfile.physicalAddress.latitude', 29.76328, false);
  const lng = instance.get('auth.currentUser.shipperProfile.physicalAddress.latitude', -95.36327, false);
  return {lat, lng};
}

export const initialMapState: MapState = {
    mapCenter: getShipperBusinessEntityLocation(),
    markers: [],
};

export function mapReducer(state = initialMapState, a: Action): MapState {
    const action = a as MapAction;
    switch (action.type) {
        case SET_CENTER: {
            return {
                ...state,
                mapCenter: action.payload
            };
        }
        default: {
            return state;
        }
    }
}
