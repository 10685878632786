import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { HomeActionsButton } from 'app/home/model/model';
import { SvgConvertorService } from '../../../shared/services/svg.convertor.service';

@Component({
  selector: 'tuya-order-action-btns',
  templateUrl: './order-action-btns.component.html',
  styleUrls: ['./order-action-btns.component.scss']
})
export class OrderActionBtnsComponent implements OnChanges {

  @Input() actions: HomeActionsButton[];
  @Output() onActionClick = new EventEmitter<HomeActionsButton>();

  constructor(private svgConvertorService: SvgConvertorService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.actions = this.actions.filter(action => {
        this.svgConvertorService.svgConverToMatIcon('my-orders/' + `${action.icon}` + '.svg', action.icon);
        return !action.hidden;
      });
    }
  }

  onClick(action: HomeActionsButton) {
    this.onActionClick.emit(action);
  }

}
