import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StopDto } from '../../../core/models/dto';
import { BuilderActions } from '../../actions/builder.actions';
import { StopType } from '../../models/stop.model';

@Component({
  selector: 'tuya-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit {
  @Input() idKey: string;
  @Input() stopType: number;
  @Input() stopIndex: number;
  @Input() stop: StopDto;
  @Input() instructionsForm: UntypedFormGroup;
  @Input() addressContactForm: UntypedFormGroup;

  public StopType;

  constructor(private builderActions: BuilderActions) {
    this.StopType = StopType;
  }

  ngOnInit() {}

  onCheckboxToggle = (event) => {
    const newStop = {
      ...this.stop,
      [event.key]: event.value
    };

    this.builderActions.onEditStop(newStop, this.stopIndex);
  }

  onEditStopNotes(event) {
    const newStop = {
      ...this.stop,
      notes: event.target.value
    };
    this.builderActions.onEditStop(newStop, this.stopIndex);
  }

  get contactEmail() {
    return this.addressContactForm.get('contactEmail');
  }

}
