import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { ConfigService } from '../../app.config.service';
import { API_USER_PROFILE } from '../constants/api.constants';

@Injectable()
export class ShipperService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    get(userAccountId?: string) {
        const url = API_USER_PROFILE.replace(userAccountId ? '{0}' : '/{0}', userAccountId || '');
        return this.api().GET(url);
    }
}
