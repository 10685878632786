<div class="wrapper" [ngClass]="{'loading': isLoading}">
    <div class="driver-info-block row" *ngIf="!(isCancelled && !driverInfo.hasAssignedDrivers)">
        <div class="driver-avatar loader">
                <div class="avatar-icon" *ngIf="driverInfo.hasAssignedDrivers && !isLoading"></div>
                <img class="img-circle"
                    *ngIf="!isLoading"
                    [src]="driverImageUrl"
                    alt="driver avatar" />
        </div>
        <div class="driver-block-wrapper loader">
            <div class="driver-block">
                <div *ngIf="driverInfo.hasAssignedDrivers">
                    <div class="driver-name">
                        <span class="name">{{driverInfo.drivers[0].name}}</span>
                        <!-- <span class="rating-star" *ngIf="driverInfo.drivers[0].rate">{{ driverInfo.drivers[0].rate | number:'1.1-1' }}</span> -->
                    </div>
                </div>
                <div class="no-driver">
                    <span *ngIf="!driverInfo.hasAssignedDrivers && !isLoading">
                        {{ 'tuya-my-orders.driver-info.no-driver' | translate }}
                    </span>
                </div>
            </div>

            <div *ngIf="driverInfo.hasAssignedDrivers && isAllowedToRateDriver">
                <!-- <tuya-rate-driver [rate]="shipperRate"
                                  [reasonId]="shipperReasonId"
                                  [isTimeToRateExceed]="isTimeToRateExceed"
                                  [rateReasons]="order.rateReasons"
                                  [isRateUpdated]="isRateUpdated"
                                  (updateRate)="onRateDriver($event)">
                </tuya-rate-driver> -->
            </div>
            <div *ngIf="statisticInfo.offeredDriversCount !== 0 && !isLoading">
                <div class="declined"
                     *ngIf="showDeclinedBlock">
                    <span class="no-color declined-driver-name" *ngIf="statisticInfo.declinedDriversCount > 0">
                        {{statisticInfo.declinedDriverName}}
                        {{'tuya-my-orders.driver-info.has-declined-offer' | translate}} </span>
                    <span class="declined-count">
                        {{statisticInfo.declinedDriversCount}}/{{statisticInfo.offeredDriversCount}}</span>
                    <span class="no-color"
                          [translate]="'tuya-my-orders.driver-info.drivers-declined'"></span>
                </div>
                <div class="all-declined"
                     *ngIf="showAllDeclinedBlock">
                    <span *ngIf="statisticInfo.offeredDriversCount === 1">
                        {{'tuya-my-orders.driver-info.all-drivers-declined-exclusive1' | translate}}
                         {{statisticInfo.declinedDriverName}}
                    </span>
                    <span *ngIf="statisticInfo.offeredDriversCount !== 1">
                        {{'tuya-my-orders.driver-info.all-drivers-declined-limited' | translate}}
                    </span>
                </div>
                <a routerLink="" *ngIf="showAllDeclinedBlock && !isShipperSuspended"
                   [translate]="'tuya-my-orders.driver-info.edit-order-link'"
                   [hidden]="isEditOrderDisabled()"
                   (click)="onEditOrder()"></a>
            </div>
        </div>
    </div>
    <div class="driver-info-block-2" *ngIf="!isLoading">
        <div class="driver-contacts" *ngIf="driverInfo.hasAssignedDrivers && driverInfo.drivers && driverInfo.drivers.length">
            <div class="driver-phone">{{ driverInfo.drivers[0].phone | phoneNumber}}</div>
            <div class="driver-email">{{ driverInfo.drivers[0].email }}</div>
        </div>
        <div class="driver-pool" *ngIf="driverInfo.hasAssignedDrivers">
            <tuya-driver-pool [pools]="pools"
                              [driverName]="order.assignments.drivers[0].name"
                              [searchInputPlaceholder]="'tuya-my-orders.driver-info.add-driver-placeholder'"
                              (createPool)="onCreatePool($event)"
                              (addRemovePools)="addRemovePools($event)">
                            </tuya-driver-pool>
        </div>
    </div>
</div>
