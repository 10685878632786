import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'tuya-form-ctrl-errr-printer',
  templateUrl: './form-ctrl-errr-printer.component.html',
  styleUrls: ['./form-ctrl-errr-printer.component.scss']
})
export class FormCtrlErrrPrinterComponent implements OnInit {

  @Input() prefix = 'tuya-register.error';
  @Input() ctrlName = '';
  @Input() ctrl: UntypedFormControl;

  constructor() { }

  ngOnInit() {
  }

  public calcErrorPath(postfix: string) {
    return `${this.prefix}.${this.ctrlName}.${postfix}`;
  }

}
