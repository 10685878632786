import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/module';
import { ShipperEpics } from './epics/shipper.profile.epic';
import { ShipperActions } from './actions/shipper.status.actions';



export const COMPONENTS = [];

@NgModule({
    imports: [
        CommonModule,
        CoreModule
    ],
    declarations: COMPONENTS,
    exports: [...COMPONENTS],
    providers: [ShipperEpics, ShipperActions]
})
export class ShipperModule {

}
