import {Component} from '@angular/core';


@Component({
    selector: 'tuya-home',
    templateUrl: './home.component.html'
})

export class HomeComponent {

    constructor() {
    }
}
