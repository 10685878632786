import { UISpecificState, WizardStep } from '../models/ui.model';
import {
    CALL_PAYMENT_VALIDATION,
    DISABLED_REFRESH,
    INIT_STOP_EDIT,
    LOADING,
    SET_DEFAULT_ITEM_SIZE,
    SET_STOP_DETAILS_EDIT_MODE,
    SET_WIZARD_STEP,
    SET_WIZARD_STEP_CONTINUE_ALLOWED,
    TOGGLE_EDIT_NAME_MODE,
    UIAction,
} from '../actions/ui.actions';
import { Action } from 'redux';
import { SHIPMENT_SAVE_INVALID_TIMEFRAMES_STOPS } from '../actions/order-stops.actions';

export const initialUiState: UISpecificState = {
    firstLocation: null,
    wizardStep: WizardStep.Initial,
    continueAllowedMap: {},
    isStopDetailsEditMode: false,
    stopDetailsEdit: null,
    stopNameEditMode: false,
    isLoading: false,
    invalidTimeframesStops: [],
    defaultItemSize: JSON.parse(localStorage.getItem('defaultItemSize')) || 2,
    disabled: false,
    isValidatePayment: false
};

export function uiReducer(state = initialUiState, a: Action): UISpecificState {
    const action = a as UIAction;
    switch (action.type) {
        case CALL_PAYMENT_VALIDATION: {
            return {
                ...state,
                isValidatePayment: action.payload
            };
        }
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload
            };
        }
        case DISABLED_REFRESH: {
            return {
                ...state,
                disabled: action.payload
            };
        }
        case SET_WIZARD_STEP_CONTINUE_ALLOWED: {
            return {
              ...state,
              continueAllowedMap: {
                    ...state.continueAllowedMap,
                    [action.payload.step]: action.payload.allowed
                  }
            };
        }
        case SET_WIZARD_STEP : {
            return {
                ...state,
                wizardStep: action.payload
            };
        }

        case SET_STOP_DETAILS_EDIT_MODE : {
            return {
                ...state,
                isStopDetailsEditMode: action.payload
            };
        }

        case TOGGLE_EDIT_NAME_MODE : {
            return {
                ...state,
                stopNameEditMode: action.payload
            };
        }

        case INIT_STOP_EDIT: {
            return {
                ...state,
                stopDetailsEdit: action.payload
            };
        }

        case SET_DEFAULT_ITEM_SIZE: {
            return {
                ...state,
                defaultItemSize: action.payload
            };
        }

        case SHIPMENT_SAVE_INVALID_TIMEFRAMES_STOPS: {
            return {
                ...state,
                invalidTimeframesStops: action.payload
            };
        }

        default: {
            return state;
        }
    }
}
