<!-- TODO: handle SET_WIZARD_STEP w/ router -->
<router-outlet></router-outlet>
<tuya-action-bottom-bar
  *ngIf="wizardStep.getValue() !== WizardStep.Confirmation"
  [message]="(ACTION_BOTTOM_BAR_RULES[wizardStep.getValue()]).message"
  [step]="(ACTION_BOTTOM_BAR_RULES[wizardStep.getValue()]).actionBarStep"
  [stepList]="ACTION_BAR_STEP_LIST"
  [buttonLabel]="(ACTION_BOTTOM_BAR_RULES[wizardStep.getValue()]).buttonLabel"
  [isContinueAllowed]="(continueAllowedMap$ | async)[wizardStep.getValue()] || false"
  [total]="total$ | async"
  [price]="priceOpt$ | async"
  [disabled]="disabled$ | async"
  (onContinue)="onStepContinue()"
  (onReset)="onAskOrderCancel(orderResetDialog)"
  (onDiscard)="onAskOrderCancel(orderDiscardDialog)"
></tuya-action-bottom-bar>

<tuya-map
    mode="fixed-size"
    [mapCenter]="mapCenter$ | async"
    [markers]="markerList$ | async"
    [activeMarkerId]="selectedStopId$ | async"
    [autoBoundary]="true"
    *ngIf="wizardStep.getValue() !== WizardStep.SummaryAndDriverAssignment && wizardStep.getValue() !== WizardStep.SummaryAndPaymentOrder"
></tuya-map>

<tuya-edit-limitation-bar *ngIf="showEditLimitationBar()" [wizardStep]="wizardStep$ | async" [drivers]="drivers$ | async"></tuya-edit-limitation-bar>
<ng-template #orderResetDialog>
  <div class="order-reset-confirm-dialog">
    <div class="message"><h3>{{ 'tuya-shipment.reset-confirm-dialog.message' | translate }}</h3></div>
    <div class="btn-group">
      <button class="btn btn-cancel" (click)="dialogRef.hide()" >{{ 'tuya-shipment.reset-confirm-dialog.cancel' | translate }}</button>
      <button class="btn btn-ok" (click)="onOrderReset(); dialogRef.hide();">{{ 'tuya-shipment.reset-confirm-dialog.ok' | translate }}</button>
    </div>
  </div>
</ng-template>
<ng-template #orderDiscardDialog>
    <div class="order-reset-confirm-dialog">
        <div class="message"><h3>{{ 'tuya-shipment.discard-changes-dialog.message' | translate }}</h3></div>
        <div class="btn-group">
            <button class="btn btn-cancel" (click)="dialogRef.hide()" >{{ 'tuya-shipment.discard-changes-dialog.cancel' | translate }}</button>
            <button class="btn btn-ok" (click)="onOrderDiscard(); dialogRef.hide();">{{ 'tuya-shipment.discard-changes-dialog.ok' | translate }}</button>
        </div>
    </div>
</ng-template>