import { BoxSizeDto, TrackingInfoDto, TrackingOrderItemDto } from './../../core/models/dto';
import { TrackingInfoVo, TrackingOrderItem } from './../models/tracking.model';
import { IAppState } from '../../store/model';

type BoxSizeDtoMap = { [key: number]: BoxSizeDto };

export function trackDtoToVo(trackingInfoDto: TrackingInfoDto, sizes: BoxSizeDto[]): TrackingInfoVo {

    let trackingInfoVo = new TrackingInfoVo();
    const itemsSizesMap = sizes.reduce((l: BoxSizeDtoMap, r: BoxSizeDto) => ({
        ...l,
        [r.id]: r,
        [r.sizeTypeId]: r
    }), {});

    const trackingOrderItem = trackingInfoDto.orderItems
    .map((itemDto: TrackingOrderItemDto): TrackingOrderItem => {
        const itemSize = itemsSizesMap[itemDto.sizeTypeId];
        return {
            ...itemDto,
            itemName: itemSize?.name,
            itemDescription: itemSize?.description,
            itemWeight: itemSize?.weight
        };
    });

    trackingInfoVo = {
        ...trackingInfoDto,
        orderItems: trackingOrderItem
    };

    return trackingInfoVo;
}

export class TrackingSelector {
    public static trackingInfo = (state: IAppState) => state.tracking.trackingInfo;
    public static trackingInfoVo = (state: IAppState): TrackingInfoVo => trackDtoToVo(state.tracking.trackingInfo, state.core.itemsSizes);
}
