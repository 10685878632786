import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmPopup } from 'app/shared/models/confirm-alert.model';

@Component({
  selector: 'tuya-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ConfirmPopupComponent>,
             @Inject(MAT_DIALOG_DATA) public data: ConfirmPopup) { }

  ngOnInit() {
  }

  onCancel() {
    this.dialogRef.close();
  }

  onAccept() {
    this.dialogRef.close(true);
  }

}
