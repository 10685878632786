import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminHomeComponent } from './containers/admin-home/admin-home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminEpics } from './epics/admin.epics';
import { AdminService } from './services/admin.service';
import { SharedModule } from '../shared/module';
import { locale as english } from './i18n/admin.en';
import { TranslateService } from '@ngx-translate/core';
import { TranslationLoaderService } from '../core/services/translation-loader.service';
import { TranslateModule } from '@ngx-translate/core';
import { AdminPanelComponent } from './containers/admin-panel/admin-panel.component';
import { DriverPortalComponent } from './containers/driver-portal/driver-portal.component';
import { NgmatAdminModule } from './ngmat-admin.module';
import { DriverFormComponent } from './components/driver-form/driver-form.component';
import { DriverModalComponent } from './components/driver-modal/driver-modal.component';
import { VehicleFormComponent } from './components/vehicle-form/vehicle-form.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { DriverPortalService } from './services/driver.potal.service';
import { ShipperAdminActions } from './actions/shipper.admin.actions';
import { DriverportalEpics } from './epics/driverportal.epics';
import { AdminDriverPortalActions } from './actions/admin.driver.portal.actions';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        TranslateModule,
        NgmatAdminModule,
        ReactiveFormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        CdkStepperModule,
    ],
    declarations: [
        AdminHomeComponent,
        AdminPanelComponent,
        DriverPortalComponent,
        DriverModalComponent,
        DriverFormComponent,
        VehicleFormComponent,
        SnackBarComponent,
    ],
    providers: [
        ShipperAdminActions,
        AdminEpics,
        DriverportalEpics,
        AdminDriverPortalActions,
        AdminService,
        DriverPortalService,
        [provideNgxMask()]
    ]
})
export class AdminModule {
  constructor(
    private translate: TranslateService,
    private translationLoader: TranslationLoaderService) {
      /* Config translation in root module */
      this.translate.addLangs(['en']);
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      /* Load translations in each module/component as needed */
      this.translationLoader.loadTranslations(english);
    }
}
