import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CombinedPoolsDTO } from 'app/core/models/dto';
import { CreatePoolPopupComponent } from './create-pool-popup/create-pool-popup.component';
import { DriverPoolConfirmationComponent, PoolConfirmPopup, PoolConfirmType } from './driver-pool-confirmation/driver-pool-confirmation.component';
import { BehaviorSubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'tuya-driver-pool',
  templateUrl: './driver-pool.component.html',
  styleUrls: ['./driver-pool.component.scss'],
})

export class DriverPoolComponent implements OnInit {

  @Input() driverName = '';
  @Input()
  set pools(value) {
    this.poolsChangeSubscriber$.next(value);
  }
  get pools() {
    return this.poolsChangeSubscriber$.getValue();
  }
  @Input() searchInputPlaceholder;
  @Output() createPool = new EventEmitter();
  @Output() addRemovePools = new EventEmitter();

  poolFormControl = new UntypedFormControl();
  searchFieldFormControl = new UntypedFormControl();
  filteredOptions: Observable<CombinedPoolsDTO[]>;

  options: CombinedPoolsDTO[] = [];
  newPoolName = '';

  poolsNeedToAdd: CombinedPoolsDTO[] = [];
  poolsNeedToRemove: CombinedPoolsDTO[] = [];

  componentMode: PoolConfirmType;

  setFocus = new BehaviorSubject(true);
  poolsChangeSubscriber$ = new BehaviorSubject([]);
  prevCount = null;

  @ViewChild('poolSearchInput', {static: false}) poolSearchInput: MatSelect;

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    if (this.poolSearchInput) {
      this.poolSearchInput.focus();
    }
    this.initComponent();

    this.poolsChangeSubscriber$.subscribe(res => {
      if (res && res.length > 0) {
        this.options = res;
        this.onPoolsChangeDetected();
      }
    });
  }

  initComponent() {
    // search field form subscribe
    this.filteredOptions = this.searchFieldFormControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.poolName),
        map(name => name ? this._filter(name) : this.options.slice())
      );
  }

  onPoolsChangeDetected() {
    const mode = this.defineComponentMode();

    switch (mode) {
      case PoolConfirmType.create:
          this.onSuccessCreatePool(PoolConfirmType.create, [this.newPoolName]);
        break;
      case PoolConfirmType.add:
          if (this.poolsNeedToAdd.length) {
            this.onSuccessCreatePool(PoolConfirmType.add, this.poolsNeedToAdd);
          }
        break;
      case PoolConfirmType.remove:
          if (this.poolsNeedToRemove.length) {
            this.onSuccessCreatePool(PoolConfirmType.remove, this.poolsNeedToRemove);
          }
          break;
      case PoolConfirmType.addAndRemove:
          if (this.poolsNeedToRemove.length && this.poolsNeedToAdd.length) {
            this.onSuccessCreatePool(PoolConfirmType.addAndRemove, this.poolsNeedToAdd, this.poolsNeedToRemove);
          }
        break;
      default:
        break;
    }

    const activeDriverPools = this.options.filter(option => option.driverInPool);
    this.poolFormControl = new UntypedFormControl(activeDriverPools);

    this.initComponent();
  }

  defineComponentMode(): PoolConfirmType {
    const activePools = [...this.options].filter(p => p.driverInPool);
    let compMode: PoolConfirmType = null;
    const removedPools = [...this.poolsNeedToAdd];
    const addedPools = [...this.poolsNeedToRemove];

    if (this.componentMode === PoolConfirmType.create) {
      compMode = PoolConfirmType.create;
    } else if (removedPools.length > 0 && addedPools.length > 0) {
      compMode = PoolConfirmType.addAndRemove;
    } else if (this.prevCount !== null && this.prevCount > activePools.length) {
      compMode = PoolConfirmType.remove;
    } else if (this.prevCount !== null && this.prevCount < activePools.length) {
      compMode = PoolConfirmType.add;
    }

    setTimeout(() => {
      this.prevCount = activePools.length;
    }, 100);

    return compMode;
  }

  onToggle(ev) {
    this.setFocus.next(!ev);
    if (!ev) {
      const data = {
        add: this.poolsNeedToAdd.map(p => p.poolId),
        remove: this.poolsNeedToRemove.map(p => p.poolId)
      };
      this.addRemovePools.emit(data);
    }
  }

  onCheck(pool: CombinedPoolsDTO) {
    const options = [...this.options];

    for (let i = 0; i < options.length; i++) {
      if (pool.poolId === this.pools[i].poolId) {

        if (!pool.driverInPool && !pool.newStatus) {
          pool.newStatus = !pool.newStatus;
          this.options[i].newStatus = pool.newStatus;
        } else if (!pool.driverInPool && pool.newStatus) {
          pool.newStatus = !pool.newStatus;
          this.options[i].newStatus = pool.newStatus;
        } else if (pool.driverInPool && pool.newStatus) {
          pool.newStatus = !pool.newStatus;
          this.options[i].newStatus = pool.newStatus;
        } else if (pool.driverInPool && !pool.newStatus) {
          pool.newStatus = !pool.newStatus;
          this.options[i].newStatus = pool.newStatus;
        }

        if (pool.driverInPool && !pool.newStatus) {
          this.poolsNeedToRemove.push(pool);
        } else if (!pool.driverInPool && pool.newStatus) {
          this.componentMode = PoolConfirmType.add;
          this.poolsNeedToAdd.push(pool);
        }
        break;
      }
    }

    if (pool.newStatus) {
    // Pool was Added
      this.componentMode = PoolConfirmType.add;
      for (let i = 0; i < this.poolsNeedToRemove.length; i++) {
        if (pool.poolId === this.poolsNeedToRemove[i].poolId) {
          this.poolsNeedToRemove.splice(i, 1);
          break;
        }
      }
    } else {
    // Pool was Removed
      this.componentMode = PoolConfirmType.remove;
      for (let i = 0; i < this.poolsNeedToAdd.length; i++) {
        if (pool.poolId === this.poolsNeedToAdd[i].poolId) {
          this.poolsNeedToAdd.splice(i, 1);
          break;
        }
      }
    }
  }

  onCreatePool() {
    this.componentMode = PoolConfirmType.create;
    const dialogRef = this.dialog.open(CreatePoolPopupComponent, {data: {}, autoFocus: false });

    dialogRef.afterClosed().subscribe(poolName => {
        if (poolName) {
          this.newPoolName = poolName;
          this.createPool.emit(poolName);
        }
    });
  }

  onSuccessCreatePool(poolType: PoolConfirmType, pools: any[], removedPools?: any[]) {
    const data: PoolConfirmPopup = {
      type: poolType,
      pools: pools,
      driver: this.driverName
    };

    if (poolType === PoolConfirmType.addAndRemove) {
      data.removedPools = removedPools;
    }

    if (this.dialog.openDialogs.length === 0) {
      const dialogRef = this.dialog.open(DriverPoolConfirmationComponent, {data: data, autoFocus: false });

      dialogRef.afterClosed().subscribe(poolName => {
        this.poolsNeedToAdd = [];
        this.poolsNeedToRemove = [];
      });
    }
  }

  private _filter(name: string): CombinedPoolsDTO[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.poolName.toLowerCase().indexOf(filterValue) === 0);
  }

}
