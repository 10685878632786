import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TrackingStopInfoComponent } from './components/tracking-stop-info/tracking-stop-info.component';
import { TrackingDriverInfoComponent } from './components/tracking-driver-info/tracking-driver-info.component';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar-portable';
import { NgModule } from '@angular/core';
import { CoreModule } from '../core/module';
import { SharedModule } from '../shared/module';
import { TrackingPageComponent } from './containers/tracking-page/tracking-page.component';
import { TrackingFooterComponent } from './components/tracking-footer/tracking-footer.component';
import { TranslationLoaderService } from '../core/services/translation-loader.service';
import { locale as english } from './i18n/tracking.en';
import { TrackingStepperComponent } from './components/tracking-stepper/tracking-stepper.component';
import { TrackingInfoContainerComponent } from './containers/tracking-info-container/tracking-info-container.component';
import { CommonModule } from '@angular/common';
import { TrackingService } from './services/tracking.service';
import { TrackingEpics } from './epics/tracking.epics';
import { TrackingActions } from './actions/tracking.actions';
import { TrackingGuard } from './guards/tracking.guard';
import { TrackingInfoTimeComponent } from './components/tracking-info-time.component/tracking-info-time.component';
import { StopEventsLogComponent } from './components/stop-events-log/stop-events-log.component';
import { StopEventItemComponent } from './components/stop-event-item/stop-event-item.component';
import { TrackingOrderInfoComponent } from './components/tracking-order-info/tracking-order-info.component';
import { StopItemInfoComponent } from './components/tracking-stop-info/stop-item-info.component/stop-item-info.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

export const COMPONENTS =
    [
        TrackingPageComponent,
        TrackingInfoContainerComponent,
        TrackingFooterComponent,
        TrackingStepperComponent,
        TrackingInfoTimeComponent,
        StopEventsLogComponent,
        StopEventItemComponent,
        TrackingOrderInfoComponent,
        TrackingDriverInfoComponent,
        TrackingStopInfoComponent,
        StopItemInfoComponent
    ];

@NgModule({
    imports: [
        CoreModule, SharedModule, CommonModule,
        AccordionModule.forRoot(),
        PerfectScrollbarModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS,
    providers: [
        TrackingService,
        TrackingEpics,
        TrackingActions,
        TrackingGuard,
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG}
    ]
})
export class TrackingModule {
    constructor(private translationLoader: TranslationLoaderService) {
        this.translationLoader.loadTranslations(english);
    }
}

