<div class="wrapper">
  <div class="tracking-order-info">
    <div class="order-code">
      <span>[{{trackingInfo.orderCode}}]</span>
    </div>
    <div class="shipped-by">
      <div class="shipped-text">
        <span class="uppercase" [translate]="'tuya-tracking.tuya-tracking-info.tuya-tracking-order-info.shipped-by'"></span>
      </div>
      <div class="shipper-info">
        <span class="name">{{ trackingInfo.shipperName }} </span>
        |
        <span class="time">{{ trackingInfo.createDate | date:'M/dd/yyyy, h:mm a' }}</span>
      </div>
    </div>
  </div>
</div>