<div class="row wrapper">
    <div class="col-md-12">
        <div class="row order-header" [ngClass]="{'loading': (isLoading$ | async)}">
            <div class="order-id loader">
                <div class="number-label"><span *ngIf="!(isLoading$ | async)">{{
                        'tuya-my-orders.order-info.order-number' | translate }}:</span></div>
                <div class="number-content"><span *ngIf="!(isLoading$ | async)">{{currentOrderInfo.code}}</span></div>
            </div>
            <div class="order-ref-id loader">
                <div class="ref-id-label"><span *ngIf="!(isLoading$ | async)">{{ 'tuya-my-orders.order-info.ref-id' |
                        translate }}:</span></div>
                <div class="ref-id-content" *ngIf="currentOrderInfo.referenceId"><span
                        *ngIf="!(isLoading$ | async)">{{currentOrderInfo.referenceId}}</span></div>
                <div class="ref-id-content no-content" *ngIf="!currentOrderInfo.referenceId && !(isLoading$ | async)"
                    [translate]="'tuya-my-orders.order-info.no-content'"></div>
            </div>
        </div>
    </div>
    <ul class="col-md-12 stop-list" [ngClass]="{'loadingStop': (isLoading$ | async)}">
        <li *ngFor="let stop of currentOrderInfo.stopList">
            <div class="stop-icon loader" [ngClass]="getClassName(stop.typeId)"></div>
            <div class="time loader" *ngIf="!isStopCompleted(stop.statusId) && currentOrderInfo.directOrder"
                [translate]="'tuya-my-orders.order-info.asap'"></div>
            <div class="time loader" *ngIf="!isStopCompleted(stop.statusId) && !currentOrderInfo.directOrder">
                <span *ngIf="!(isLoading$ | async)">{{stop.pickupNoEarlerThan | date:"M/dd/yyyy, h:mm a"}} -
                    {{stop.pickupNoLaterThan | date:"M/dd/yyyy, h:mm a"}}</span>
            </div>
            <div class="time loader" *ngIf="isStopCompleted(stop.statusId)">
                {{stop.completedTimeStamp | date:"M/dd/yyyy, h:mm a"}}
            </div>
            <div class="stop-name loader">
                <span *ngIf="!(isLoading$ | async)">{{stop.name}}</span>
                <span *ngIf="stop.levelRefId && !(isLoading$ | async)" class="stop-ref-id">[{{stop.levelRefId}}]</span>
            </div>
            <div class="stop-address loader">
                <span *ngIf="!(isLoading$ | async)">{{stop.address.addressLine}}</span>
                <!--                 <span *ngIf="stop.address.suiteNumber && !(isLoading$ | async)" class="suite-number">
                    &nbsp;#{{stop.address.suiteNumber}}
                </span> -->
            </div>
            <div class="stop-items-list loader" *ngIf="isStopCompleted(stop.statusId)">
                <div class="picked-up">
                    <span
                        *ngIf="(stop.typeId === StopTypeId.PickedUp || stop.typeId === StopTypeId.DeliveredWithPickUp) && !(isLoading$ | async)"
                        [translate]="'tuya-my-orders.order-info.pickedUp'"></span>
                    <span class="stop-item" *ngIf="stop.typeId === StopTypeId.PickedUp && !(isLoading$ | async)">
                        {{stop.deliveredItems.actualCount}} of {{stop.deliveredItems.totalCount}}
                        <span class="exception" *ngIf="stop.deliveredItems.hasException"></span>
                    </span>
                    <span class="stop-item"
                        *ngIf="stop.typeId === StopTypeId.DeliveredWithPickUp && !(isLoading$ | async)">
                        {{stop.returnItems.actualCount}} of {{stop.returnItems.totalCount}}
                        <span class="exception" *ngIf="stop.returnItems.hasException"></span>
                    </span>
                </div>
                <div class="delivered">
                    <span *ngIf="stop.typeId !== StopTypeId.PickedUp && !(isLoading$ | async)"
                        [translate]="'tuya-my-orders.order-info.delivered'"></span>
                    <span class="stop-item" *ngIf="stop.typeId !== StopTypeId.PickedUp && !(isLoading$ | async)">
                        {{stop.deliveredItems.actualCount}} of {{stop.deliveredItems.totalCount}}
                        <span class="exception" *ngIf="stop.deliveredItems.hasException"></span>
                    </span>
                </div>
            </div>
        </li>
    </ul>
    <ul class="col-md-12 description" [ngClass]="{'loading': (isLoading$ | async)}">
        <li>
            <div class="description-value loader"><span
                    *ngIf="!(isLoading$ | async)">{{currentOrderInfo.orderSummary.items}}</span></div>
            <div class="description-name loader" *ngIf="!(isLoading$ | async)"
                [translate]="'tuya-my-orders.order-info.items'"></div>
        </li>
        <li>
            <div class="description-value loader"><span
                    *ngIf="!(isLoading$ | async)">{{currentOrderInfo.orderSummary.stops}}</span></div>
            <div class="description-name loader" *ngIf="!(isLoading$ | async)"
                [translate]="'tuya-my-orders.order-info.stops'"></div>
        </li>
        <li>
            <div class="description-value loader"><span
                    *ngIf="!(isLoading$ | async)">{{currentOrderInfo.orderSummary.lbs | number : '.0-2'}}</span></div>
            <div class="description-name loader" *ngIf="!(isLoading$ | async)"
                [translate]="'tuya-my-orders.order-info.lb'"></div>
        </li>
        <li>
            <div class="description-value loader"><span
                    *ngIf="!(isLoading$ | async)">{{currentOrderInfo.orderSummary.miles}}</span></div>
            <div class="description-name loader" *ngIf="!(isLoading$ | async)"
                [translate]="'tuya-my-orders.order-info.miles'"></div>
        </li>
        <li>
            <div class="description-value loader"><span
                    *ngIf="!(isLoading$ | async)">{{currentOrderInfo.orderSummary.returns}}</span></div>
            <div class="description-name loader" *ngIf="!(isLoading$ | async)"
                [translate]="'tuya-my-orders.order-info.returns'"></div>
        </li>
    </ul>
</div>