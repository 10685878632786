<div class="shipment-type">
    <tuya-shipment-kind-picker
        [shipmentType]="shipmentType"
        (shipmentTypeSelected)="onShipmentTypeSelected($event)"
    ></tuya-shipment-kind-picker>
    <div class="start-point">
            <div class="title">
                <span class="pickup-icon"></span>
                <span class="title-text" [translate]="'tuya-shipment.pickup.stop'"></span>
            </div>
            <div class="input-container">
                <tuya-address-autocomplete
                    [selectedAddress]="startAddressRawString"
                    [isFirstStop]="true"
                    [className]="'pickup'"
                    [stopType]="StopType.Pickup"
                ></tuya-address-autocomplete>
            </div>
            <div class="optional">
                <input type="text"
                        [(ngModel)]="stop.address.suiteNumber"
                        (blur)="onUpdateSuiteNumber()"
                        name="suiteNumber"
                        class="form-control suiteNumber"
                        placeholder="{{'tuya-shipment.tuya-stop-details.suiteNumber' | translate}}"
                        maxlength="10"
                        pattern="^[\w, .?@&!#'~*\-;+]*$"/>
            </div>
        </div>
</div>