import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatePasswordDirective } from './directives/validate.password.directive';
import { ErrorPageComponent } from './containers/error-page.component';
import { PhoneNumberPipe } from './pipes/formatPhoneNumber.pipe';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { AssignDriverComponent } from './components/assign-driver/assign-driver.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { SelectModule } from '../core/components/pool-select/select/select.module';
import { SwitchComponent } from './components/switch/switch.component';
import { TimeDropdownComponent } from './components/time-dropdown/time-dropdown.component';
import { DateDropdownComponent } from './components/date-dropdown/date-dropdown.component';
import { SearchSelectComponent } from './components/search-select/search-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleAutocompleteComponent } from './components/simple-autocomplete/simple-autocomplete.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { OrderSearchInputComponent } from './components/order-search-input/order-search-input.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SvgConvertorService } from './services/svg.convertor.service';
import { NgmatSharedModule } from './ngmat-shared.module';
import { SharedSnackBarComponent } from './components/shared-snack-bar/shared-snack-bar.component';
import { DriverPoolComponent } from './components/driver-pool/driver-pool.component';
import { CreatePoolPopupComponent } from './components/driver-pool/create-pool-popup/create-pool-popup.component';
import { DriverPoolConfirmationComponent } from './components/driver-pool/driver-pool-confirmation/driver-pool-confirmation.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { DriverPoolSearchComponent } from './components/driver-pool/driver-pool-search/driver-pool-search.component';
import { PopupComponent } from './components/popup/popup.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { MapComponent } from './components/map/map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ConfigService } from '../app.config.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

const COMPONENTS = [
    ValidatePasswordDirective,
    AutofocusDirective,
    ErrorPageComponent,
    PhoneNumberPipe,
    SpinnerComponent,
    SwitchComponent,
    TimeDropdownComponent,
    DateDropdownComponent,
    AssignDriverComponent,
    SearchSelectComponent,
    DatePickerComponent,
    SimpleAutocompleteComponent,
    OrderSearchInputComponent,
    ProgressBarComponent,
    SharedSnackBarComponent,
    DriverPoolComponent,
    CreatePoolPopupComponent,
    DriverPoolConfirmationComponent,
    DriverPoolSearchComponent,
    PopupComponent,
    ConfirmPopupComponent,
    MapComponent
];

const providers = [BsModalService, SvgConvertorService, [provideNgxMask()]];

@NgModule({
    imports: [
        CommonModule, ModalModule.forRoot(), ProgressbarModule.forRoot(), NgxMaskDirective, NgxMaskPipe, TranslateModule,
        FormsModule, TooltipModule, SelectModule, ReactiveFormsModule, NgmatSharedModule, GoogleMapsModule
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: providers
})
export class SharedModule { }
