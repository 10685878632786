import { ManageCompanyTab } from '../../model/model';
import { BusinessEntityDto } from '../../../core/models/dto';
import { Component, EventEmitter, Input, OnInit, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgModel } from '@angular/forms';

@Component({
    selector: 'tuya-general-information',
    templateUrl: 'general-information.component.html',
    styleUrls: ['general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit, OnChanges {

    @Input() currentManageCompanyTab: ManageCompanyTab; // default info on set
    @Input() companyProfilesInfo: BusinessEntityDto;
    @Input() states: string[];
    @Input() industrySegments: string[];
    @Input() validateCompanyMsg;
    @Input() suspended: boolean;
    @Input()
    public set validateCompanyErrors(value) {
        if (value) {
            this.validateErrors = value.isValid;
            this.validateErrorMsg = value.nameErrorMsg;
        }
    }

    @Output() changeManageCompanyTab = new EventEmitter<ManageCompanyTab>();
    @Output() saveGeneralInfo = new EventEmitter<BusinessEntityDto>();
    public ManageCompanyTab: typeof ManageCompanyTab = ManageCompanyTab;
    @ViewChild('cancel', {static: true}) modalChangePassword;
    @ViewChild('generalInfoForm', {static: true}) generalInfoForm;
    model: BusinessEntityDto;
    phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    zipCodeMask =  [/[0-9]/, /[0-9]/, /\d/, /\d/, /\d/];
    phonePattern = /[0-9]{10}/;
    companyNamePattern = /^([a-zA-Z\d-,.?@&!#'~*_;+]+\s*)+$/;
    addressAndCityPattern = /^([a-zA-Z-,.\d]+\s*)+$/;
    cityNamePattern = /^([\w,.-\/]+\s*)+$/;
    suitePattern = /^([a-zA-Z\d-,.?@&!#'~*_;+]+\s*)+$/;
    isPhoneNumberValid = true;
    validateErrors: boolean;
    validateErrorMsg: string[];
    doesCityContainsOnlySpaces = false;
    isNextTab = false;
    nextTab: ManageCompanyTab;

    constructor(private modalService: BsModalService) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.setDefaultCompanyInfo();
    }

    saveGeneralInfoForm() {
        this.saveGeneralInfo.emit(this.model);
    }

    onHideModal(evt) {
        if (evt) {
            this.discardChanges();
        }
        this.modalService.hide(1);
    }

    setDefaultCompanyInfo() {
        if (this.companyProfilesInfo) { // to prevent reading property of null
            this.model = {
                ...this.companyProfilesInfo,
                physicalAddress: {
                    ...this.companyProfilesInfo.physicalAddress
                },
                billingAddress: {
                    ...this.companyProfilesInfo.billingAddress
                }
            };
        }
        this.validateCompanyErrors = null; // clear validation results on modal hide
    }

    onCityNameChange() {
        this.doesCityContainsOnlySpaces = this.model.physicalAddress.city.length &&
            !this.model.physicalAddress.city.trim().length;
    }

    isDirtyAndInvalid(model: NgModel) {
        return (model.dirty && model.invalid);
    }
    onPhoneChange() {
        if (this.model.generalPhone) {
            this.model.generalPhone = this.model.generalPhone.replace(/\D+/g, '');
            this.isPhoneNumberValid = this.phonePattern.test(this.model.generalPhone);
        }
    }

    isPhoneEmpty() {
        return (typeof this.model.generalPhone === 'undefined') ? true :
            !this.model.generalPhone.length;
    }

    isModelChanged() {
        if (this.model) {
            return JSON.stringify(this.companyProfilesInfo) !==
                JSON.stringify(this.model);
        }
        return false;
    }

    cancelGeneralInfoForm(template: TemplateRef<any>) {
        this.modalService.show(template, {ignoreBackdropClick: true});
    }

    discardChanges() {
        this.setDefaultCompanyInfo();
        this.isPhoneNumberValid = true;
        this.modalService.hide(1);
        if (this.nextTab) {
            this.changeManageCompanyTab.emit(this.nextTab);
        }
        return true;
    }
}
