<fieldset class="builder-address-contact builder-fieldset">

  <div [formGroup]="addressContactForm">

      <div class="builder-field-wrapper">
        <div class="builder-field-group">
          <label 
            class="sr-only sr-only-focusable"
            for="{{ idKey }}-address">
              {{ 'builderAddress.addressLabel' | translate }}
          </label>
          <!-- (hideAddressSearchError)="onHideAddressSearchError()" -->
          <tuya-autocomplete-address
            [stopId]="stop.id"
            [selectedStopId]="stopType"
            [stopType]="stopType"
            [className]="'builder-address-input builder-address-1-input'"
            [isControlDisabled]="materialLocked"
            [isOptionalStop]="false"
            [stopTypeId]="stop.typeId"
            [stopIndex]="stopIndex"
            [stop]="stop"
            [displayAddressSearchError]="displayAddressSearchError"
            (showAddressSearchError)="onShowAddressSearchError()"
            
            (onAddressSelect)="onAddressSelectEvent($event)"
            [addressContactForm]="addressContactForm">
          </tuya-autocomplete-address>
          <div *ngIf="displayAddressSearchError"
            class="input-field-error-message builder-address-address-search-error">
              <div *ngIf="stopType === StopType.Pickup">
                {{ 'validation.pickupAddressSearch' | translate }}
              </div>
              <div *ngIf="stopType === StopType.Delivery">
                {{ 'validation.deliveryAddressSearch' | translate }}
              </div>
          </div>
        </div>
        <div class="builder-field-group">
          <label 
            class="sr-only sr-only-focusable"
            for="{{ idKey }}-address-2">
              {{ 'builderAddress.addressLine2label' | translate }}
          </label>
          <input
            class="builder-address-input builder-address-2-input"
            id="{{ idKey }}-address-2"
            #suiteNumber
            type="text"
            name="{{ idKey }}-address-2"
            formControlName="suiteNumber"
            maxlength="10"
            attr.data-key="{{ 'suiteNumber' }}"
            placeholder="{{ 'builderAddress.address2Placeholder' | translate }}"
            attr.data-key="{{ 'suiteNumber' }}"
            (input)="onEditAddress($event)"
          />
        </div>
      </div>

      <div class="builder-field-wrapper">
        <div class="builder-field-group">
          <label 
            class="sr-only sr-only-focusable"
            for="{{ idKey }}-name">
              {{ 'builderAddress.nameLabel' | translate }}
          </label>
          <input 
            class="builder-address-input builder-address-name-input" 
            id="{{ idKey }}-name" 
            type="text" 
            name="{{ idKey }}-name" 
            placeholder="{{ addressNamePlaceholder | translate }} *"
            formControlName="nameOrDescription"
            required
            minlength="1"
            maxlength="75"
            attr.data-key="{{ 'nameOrDescription' }}"
            (input)="onEditNameOrDescription($event)">
          <div *ngIf="nameOrDescription.invalid && nameOrDescription.touched"
            class="input-field-error-message builder-address-name-input-error">
            <div *ngIf="!!nameOrDescription?.errors.required && stopType === StopType.Pickup">
              {{ 'validation.pickupStop' | translate }}
            </div>
            <div *ngIf="!!nameOrDescription?.errors.required && stopType === StopType.Delivery">
              {{ 'validation.deliveryStop' | translate }}
            </div>
          </div>
        </div>
        <div class="builder-field-group">
          <label 
            class="sr-only sr-only-focusable"
            for="contact-name">
              {{ 'builderContact.contactName' | translate }}
          </label>
          <input 
            class="builder-address-input builder-contact-name-input"
            id="{{ idKey }}-contact-name" 
            type="text" 
            name="{{ idKey }}-contact-name" 
            placeholder="{{ 'builderContact.contactNamePlaceholder' | translate }} *"
            formControlName="contactName"
            required
            minlength="1"
            maxlength="50"
            attr.data-key="{{ 'contactName' }}"
            (input)="onEditName($event)">
          <div *ngIf="contactName.invalid && contactName.touched"
            class="input-field-error-message builder-contact-name-input-error">
            <div *ngIf="!!contactName.errors.required">
              {{ 'validation.contactName' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="builder-field-wrapper">
        <div class="builder-field-group">
          <label
          class="sr-only sr-only-focusable"
          for="{{ idKey }}-contact-phone">
            {{ 'builderContact.contactPhone' | translate }}
          </label>
          <input 
            class="builder-address-input builder-contact-phone-input" 
            type="tel" 
            id="{{ idKey }}-contact-phone" 
            name="{{ idKey }}-contact-phone"
            mask="(000) 000-0000"
            placeholder="{{ 'builderContact.contactPhone' | translate }}"
            attr.data-key="{{ 'contactPhoneNumber' }}"
            formControlName="contactPhoneNumber"
            (input)="onEditPhoneNumber($event)">
          <div *ngIf="contactPhoneNumber.invalid && contactPhoneNumber.touched"
            class="input-field-error-message builder-contact-phone-input-error">
            <div *ngIf="!!contactPhoneNumber.errors.phoneNumberLength">
              {{ 'validation.phoneNumberLength' | translate }}
            </div>
          </div>
        </div>
        
        <div class="builder-field-group">
          <label
          class="sr-only sr-only-focusable"
          for="{{ idKey }}-contact-email">
            {{ 'builderContact.contactEmail' | translate }}
          </label>
          <input 
            class="builder-address-input builder-contact-email-input"
            id="{{ idKey }}-contact-email"
            type="email"
            name="{{ idKey }}-contact-email"
            placeholder="{{ 'builderContact.contactEmailPlaceholder' | translate }}"
            attr.data-key="{{ 'contactEmail' }}"
            formControlName="contactEmail"
            maxlength="250"
            (input)="onEditEmailAddress($event)">
          <div *ngIf="contactEmail.invalid && contactEmail.touched"
            class="input-field-error-message builder-contact-email-input-error">
            <div *ngIf="contactEmail.errors">
              {{ 'validation.validEmail' | translate }}
            </div>
          </div>
        </div>
      </div>
  </div>

</fieldset>