import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    NG_VALUE_ACCESSOR,
    Validators
} from '@angular/forms';
import { StateDto } from '../../../core/models/dto';

export function createValidateFunction() {}

@Component({
  selector: 'tuya-company-address-input',
  templateUrl: './company-address-input.component.html',
  styleUrls: ['./company-address-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CompanyAddressInputComponent),
    multi: true
  }]
})

export class CompanyAddressInputComponent implements OnInit, ControlValueAccessor {

  @Input() states: StateDto[];
  @Input() showErrors = false;

  form: UntypedFormGroup;
  postalMask =  [/[0-9]/, /[0-9]/, /\d/, /\d/, /\d/];
  model: any = {};

  constructor(private fb: UntypedFormBuilder) { }

  propagnateChange: (_: any) => {} = () => { console.log('PROPAGNATE BEFORE REGISTER'); return {}; };

  ngOnInit() {
    this.form = this.fb.group({
      addressString: new UntypedFormControl('', [Validators.required, Validators.maxLength(75),
                                Validators.pattern(/^([A-Za-z\d\.,№#\/\-]+\s*)+$/)]),
      suiteNumber: new UntypedFormControl('', [Validators.maxLength(10), Validators.pattern(/^([a-zA-Z\d-,.?@&!#'~*_;+]+\s*)+$/)]),
      city: new UntypedFormControl('', [Validators.required, Validators.maxLength(75), Validators.pattern(/^([a-zA-Z\d\.,\-\/]+\s*)+$/)]),
      state: new UntypedFormControl(null, [Validators.required]),
      zipcode: new UntypedFormControl('', [Validators.required, Validators.maxLength(5), Validators.pattern(/^\d*$/)]),
    });
  }

  writeValue(value) {
    if (value) {
      this.model = value;
    }
  }
  isDirtyAndInvalid(input) {
    if (this.form.controls[input].value) {
      return (this.form.controls[input].dirty && this.form.controls[input].invalid);
    }
  }
  registerOnChange(fn) {
    this.propagnateChange = fn;
    this.form.valueChanges.subscribe(val => {
      fn({
        ...val,
        valid: this.form.valid
      });
    });
  }
  registerOnTouched() { }

}
