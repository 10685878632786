<div class="pool-wrapper">
  <mat-form-field>
    <mat-label>Add this driver to a Driver Group</mat-label>
    <mat-select [formControl]="poolFormControl"
                multiple
                (openedChange)="onToggle($event)"
                #poolSearchInput focus="false">
      
      <div class="pool-header-wrapper">
        <div class="search-box">
          <tuya-driver-pool-search [searchFormControl]="searchFieldFormControl"
                                   [inputPlaceholder]="searchInputPlaceholder"
                                   [setFocus]="setFocus | async"></tuya-driver-pool-search>
        </div>

        <button mat-button class="create-pool-btn" (click)="onCreatePool()">+ Create a new Driver Group</button>
        <div class="pool-header">Driver Groups You've Created</div>
      </div>
      <mat-option class="hiddedCheckbox" [disabled]="true" *ngIf="!(filteredOptions | async).length"></mat-option>

      <mat-option *ngFor="let pool of (filteredOptions | async)"
                  [value]="pool"
                  (click)="onCheck(pool)"
                  class="myMatOption">
          {{ pool.poolName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>