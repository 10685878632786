<div class="home-wrapper">
    <div class="home-container">
        <div class="home-content">
            <div class="content-image"></div>
            <p><a class="proceed" [href]="getAppLink()">{{'tuya-driver.home.proceed' | translate }}</a></p>
            <p class="buttons">
                <a class="btn btn-link btn-android" [href]="appConfig.driverAndroidApp"></a>
                <a class="btn btn-link btn-ios" [href]="appConfig.driverIosApp"></a>
            </p>
        </div>
        <div class="circle-background"></div>
    </div>
</div>