import { IAppState } from './../../store/model';
import { NotificationBucketItemDto } from '../models/notification.model';

export class NotificationSelectors {
    public static IsLoadingSelector = (state: IAppState): boolean => state.core.notification.isLoading;
    public static TotalItemCountSelector = (state: IAppState): number => state.core.notification.totalItemCount;
    public static NotificationBucketSelector =
        (state: IAppState): NotificationBucketItemDto[] => state.core.notification.notificationBucket
    public static ErrorMessageKeySelector = (state: IAppState): string => state.core.notification.errorMessageKey;
}

