export const locale = {
    'lang': 'en',
    'data': {
        'refresh-page': 'Do you want to reload this site? Changes you made may not be saved',
        'tuya-profiles': {
            'h1': {
                'my-profile': 'My profile',
                'change-password': 'Change Password'
            },
            'placeholder': {
                'firstName': 'First Name*',
                'lastName': 'Last Name*',
                'nickname': 'Nickname',
                'phone': 'Phone Number',
                'email': 'E-mail*',
                'password': 'Password*',
                'confirmPassword': 'Confirm Password*',
                'companyName': 'Company Name*',
                'industry': 'Select Industry*',
                'physicalAddress': 'Physical Address*',
                'billingAddress': 'Billing Address',
                'referralCode': 'Referral Code',
                'currentPassword': 'Current Password*',
                'newPassword': 'New Password*',
            },
            'error': {
                'firstName': 'First Name is required.',
                'first-name-pattern': 'Please enter a valid First name',
                'last-name-pattern': 'Please enter a valid Last name',
                'company-name-pattern': 'Please enter a valid company name',
                'addressLine-pattern': 'Please enter a valid address',
                'city-pattern': 'Please enter a valid city',
                'zip-pattern': 'Please enter a valid zip',
                'suite-number-pattern': 'Please enter a valid suite number',
                'lastName': 'Last Name is required.',
                'nickname': 'Please enter a valid nickname',
                'phone': 'Phone Number is required.',
                'email': 'E-mail address is required.',
                'email-pattern': 'Please enter a valid email',
                'phone-pattern': 'Please enter a valid phone number',
                'password': 'Password is required',
                'confirm-password': 'Passwords must match. Try again.',
                'companyName': 'Company Name is required.',
                'industry': 'Please select an industry.',
                'physicalAddress': 'Please enter a physical address.',
                'physicalAddressFormat': '"Street address, City State, Zip code" format of address is required',
                'billingAddress': '"Street address, City State, Zip code" format of address is required',
                'valid-password': 'Valid password is required',
                'short-password': 'Short passwords are easy to guess. Try one with at least 6 characters',
                'easy-password': 'Password is too weak. Your password must include at least one upper case letter,' +
                    ' one lower case letter, one special character, and one number',
                'confirm-password-required': 'Please confirm password',
                'addressLine': 'Address is required',
                'city': 'City is required',
                'state': 'State is required',
                'zip': 'Zip Code is required'
            },
            'btn': {
                'cancel': 'Cancel',
                'save': 'Save',
                'ok': 'Ok',
                'leave': 'Leave',
                'stay': 'Stay',
                'close': 'Close'
            },
            'checkbox': {
                'send-updates': 'Please send text/SMS updates on my orders to this number'
            },
            'link': {
                'change-password': 'Change Password'
            },
            'label': {
                'firstName': 'First Name*',
                'lastName': 'Last Name*',
                'nickname': 'Nickname',
                'phone': 'Phone Number*',
                'email': 'Email*',
                'verifyCurrentPassword': 'Verify Current Password*',
            },
            'modals': {
                'modal-save': 'Changes are saved.',
                'modal-cancel': 'Changes you\'ve made will not be saved.',
            }
        },
        'tuya-company': {
            'h2': {
                'manageCompany': 'Manage Company'
            },
            'code': 'Account Number',
            'navigation': {
                'generalInformation': 'General information',
                'billingList': 'Billing contact',
                'paymentMethods': 'Payment methods'
            },
            'payment': {
                'card': 'card',
                'name': 'cardholder\'s name',
                'expiration': 'expiration',
                'add': '+  add new card',
                'replace': 'replace card',
                'add-card': 'add card',
                'ending-in': 'ending in',
                'loose-changes': 'Are you sure you want to discard credit card creation?'
            },
            'billing-contact': {
                'info': 'Please enter some information regarding where you would like your invoices and any billing inquiries sent.'
            },
            'general': {
                'companyName': 'Company Name*',
                'physicalAddressLine1': 'Physical Address Line 1*',
                'suiteNumber': 'Suite Number',
                'city': 'City*',
                'state': 'State*',
                'zip': 'Zip*',
                'phone-number': 'Phone Number',
                'industryType': 'Industry Type',
                'save': 'SAVE',
                'cancel': 'CANCEL',
                'use-company': 'Please use Company Address'
            },
            'billing': {
                'first-name': 'First Name*',
                'last-name': 'Last Name*',
                'phone': 'Phone Number*',
                'email': 'Email*',
                'address-line': 'Address Line 1*',
                'suite-number': 'Suite Number',
                'city': 'City*',
                'state': 'State*',
                'zip': 'Zip*',
            }
        }
    }
};
