export const locale = {
    'lang': 'en',
    'data': {
        'builderInput': {
            'pickupHeader': 'Pickup Stop',
            'addItemsLabel': 'Add items to be picked up at this stop*',
            'hazardousMaterialsWarning': 'Hazardous materials not allowed at this time',
            'deliveryHeader': 'Delivery Stop',
            'selectItemsLabel': 'Select items to be delivered at this stop*',
            'next': 'Next',
            'submit': 'Submit Order',
            'update' : 'Update Order'
        },
        'builderAddress': {
            'nameLabel': 'Stop Name Required',
            'addressLabel': 'Stop Address',
            'addressLine2label': 'Address Line 2',
            'namePlaceholderPickup': 'Pickup stop name',
            'namePlaceholderDelivery': 'Delivery stop name',
            'addressPlaceholderPickup': 'Pickup address',
            'addressPlaceholderDelivery': 'Delivery address',
            'address2Placeholder': 'Ste. or Apt. N°',
            'noResults': 'We couldn’t find that location. Please search again.'
        },
        'builderDriverSelection': {
            'driverSelectionLegend': 'Select Driver',
            'driverVehicleType': 'Your order will require a driver with the vehicle type:',
            'whoDelivers': 'Please choose who you would like to deliver your order:',
            'driverPools': 'Enter driver pool(s)',
            'generalOffer': 'General Offer',
            'anyDriverLabel': 'Assign to any driver who is qualified to deliver my order.',
            'limitedOfferLabel': 'Limited Offer',
            'assignToPool': 'Assign to driver pool(s) of your choice.',
            'enterDriverCode': 'Driver code or name',
            'extendOffer': 'If selected drivers decline the offer, extend to all qualified drivers?',
            'assignToDriver': 'Assign directly to a driver of your choice.',
            'exclusiveOfferLabel': 'Exclusive Offer',
            'noMatches': 'No Matches',
            'vehicleTypes': {
                'unknown': 'Unknown',
                'sedan': 'Sedan',
                'suv': 'SUV',
                'pickup': 'Pickup',
                'van': 'Van'
            }
        },
        'builderSummary': {
            'summaryHeader': 'Order Summary',
            'stopsHeader': 'Stops',
            'itemsHeader': 'Items',
            'weightHeader': 'Weight',
            'distanceHeader': 'Distance',
            'totalHeader': 'Total Charged',
            'updatedTotalHeader': 'Updated Price',
            'enterRefIdLabel': 'Order Reference Id',
            'enterRefPlaceholder': 'Optional',
            'driverSelection': 'Driver Selection',
            'generalOffer': 'General Offer',
            'timeMessage': 'If the order is not submitted within the next 5 mins, the price will be recalculated and may change.',
            'scheduledTimeMessage': 'This Scheduled Order will become an Offered Order on ',
            'edit': 'Edit',
            'pound': 'lb',
            'miles': 'mi'
        },
        'builderCollapsedSummary': {
            'generalOffer': 'General offer',
            'limitedOffer': 'Limited offer',
            'exclusiveOffer': 'Exclusive offer'
        },
        'builderSummaryItems': {
            'stop': 'Stop',
            'itemsPlural': 'items',
            'itemSingular': 'item',
            'weightUOMPlural': 'lb',
            'weightUOMSingular': 'lb',
            'envelopesPlural': 'Envelopes',
            'envelopeSingular': 'Envelope',
            'smallPackage': 'Small',
            'mediumPackage': 'Medium',
            'largePackage': 'Large',
            'palletsPlural': 'Pallets',
            'palletSingular': 'Pallet'
        },
        'builderContact': {
            'contactLegend': 'Pickup contact information',
            'contactName': 'Contact Name Required',
            'contactNamePlaceholder': 'Contact name',
            'contactPhone': 'Phone number',
            'contactPhoneExtension': 'Phone extension',
            'contactEmail': 'Contact email address',
            'contactEmailPlaceholder': 'Email'
        },
        'builderInstructions': {
            'pickupInstructions': 'Pickup instructions',
            'signatureRequired': 'Signature?',
            'emailTracking': 'Email Tracking?',
            'emailSignatureToggleYes': 'Yes',
            'emailSignatureToggleNo': 'No',
            'pickupNotes': 'Notes about pickup',
            'pickupNotesPlaceholder': 'Enter your notes for this pickup',
            'deliveryInstructions': 'Delivery instructions',
            'deliveryNotes': 'Notes about delivery',
            'deliveryNotesPlaceholder': 'Enter your notes for this delivery'
        },
        'builderTime': {
            'pickupDate': 'Date',
            'pickupTime': 'Pickup time',
            'readyNow': 'Ready Now',
            'enterCustomWindow': 'Enter a custom time window',
            'startTime': 'Enter your time window start time',
            'to': 'to',
            'endTime': 'Enter your time window end time',
            'customWindow': 'Custom {{ stopType }} time',
            'deliveryTime': 'Delivery time',
            'deliveryDate': 'Delivery date',
            'timeWithin': 'Within 3 hrs',
            'defaultWindowLabel': 'Easy 4 hour delivery',
            'defaultWindowDescription': 'Ready for pickup now and delivery today any time from {{ start }} to {{ end }}.',
            'today': 'Today',
            'tomorrow': 'Tomorrow',
        },
        'builderModalStatus': {
            'success-title': 'Success!',
            'success-submit-text': 'Order <b>{{ code }}</b> has been successfully submitted.',
            'success-update-text':  'Order <b>{{ code }}</b> has been successfully updated.',
            'scheduled-title':  'Scheduled Offer {{ code }} has posted!',
            'scheduled-description-text':  'Your <b>Scheduled</b> Offer <b>{{ code }}</b> has been succesfully submitted to the platform.',
            'scheduled-title-edit':  'Scheduled Offer {{ code }} has updated!',
            'scheduled-description-text-edit':  'Your  <b>Scheduled</b> Offer <b>{{ code }}</b> has been succesfully updated.',
            'price-change': 'The price has changed from {{ originalPrice }} to {{ newPrice }}, do you want to continue?',
            'edit-expired': 'Your edit session has expired.'
        },
        'itemSelector': {
            'addItemsLabel': 'Add items to be picked up at this stop',
            'hazardousMaterialsWarning': 'Hazardous materials not allowed at this time',
            'quantityHeader': 'Quantity',
            'sizeHeader': 'Size',
            'weightHeader': 'Weight',
            'referenceHeader': 'Reference ID',
            'enterQuantity': 'Enter Quantity',
            'enterWeight': 'Enter Weight',
            'referenceId': 'Reference ID',
            'optional': 'optional',
            'addItemLabel': 'Add Item',
            'quantityAbbr': 'Qty',
            'weightUOM': 'lb/ea',
            'enterNotes': 'Enter the description for the item',
            'removeItem': 'Remove Item'
        },
        'priceChangeModal': {
            'title': 'Order Price Change',
            'message': 'The price of this order has changed from',
            'to': 'to',
            'shipperInfo': 'Please contact Shipper before changing the price of this order',
            'priceSelectionTitle': 'Select one of the following',
            'originalPrice': 'Original Price and No Changes',
            'originalPriceDesc': 'Disregard the changes and keep the original price of',
            'keepOriginalAndNew': 'Original Price and New Changes',
            'keepOriginalAndNewDesc': 'Keep the changes and original price of',
            'newAndChanges': ' New Price and New Changes',
            'newAndChangesDesc': 'Keep the changes and the new price of'
        },
        'itemSizeOptions': {
            'chooseItemSizeLabel': 'Choose Item Size',
            'lessThan': 'Within'
        },
        'stopType': {
            'pickup': 'pickup',
            'pickupCapitalized': 'Pickup',
            'delivery': 'delivery',
            'deliveryCapitalized': 'Delivery',
        },
        'validation': {
            'pickupAddressSearch': 'Select Pickup from verified address listing',
            'deliveryAddressSearch': 'Select Delivery from verified address listing',
            'addressSearch': 'Please search for and select address',
            'pickupStop': 'Pickup stop name required',
            'deliveryStop': 'Delivery stop name required',
            'contactName': 'Contact name is required',
            'phoneNumberLength': 'Phone number must contain 10 digits',
            'validEmail': 'Please enter a valid email address',
            'piecesCount': 'Quantity is required',
            'weight': 'Weight is required',
            'fromTime': 'Please enter a valid {{ stopType }} window start time',
            'toTime': 'Please enter a valid {{ stopType }} window end time',
            'startTime': '{{ stopType }} time window cannot start earlier than the current time',
            'minTimeWindow': 'Minimum time window is 30 minutes',
            'endTimeEarlier': 'The start time cannot be earlier than the current time.',
            'endBeforeStart': 'The end time cannot be earlier than the start time or span multiple days.',
            'deliveryStartBeforePickupStart': 'The delivery window start time cannot be earlier than the pickup window start time.',
            'deliveryEndBeforePickupEnd': 'The delivery window end time cannot be earlier than the pickup window end time.',
            'timeNoLongerValidOnNext': 'The timeframe you have selected is no longer valid. Please adjust your timeframe and click Next to continue.',
            'timeNoLongerValidOnSubmit': 'The timeframe you have selected is no longer valid. Please adjust your timeframe and submit your order to continue.',
            'notSaved': 'Are you sure you want to leave? Your changes might not be saved.',
        },
        'canNavigate': {
            'message': 'Add a Credit Card to create shipments.'
        }
    }
};
