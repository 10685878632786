import { Injectable } from '@angular/core';
import { LOAD_TRACKING_INFO, TrackingActions } from '../actions/tracking.actions';
import { TrackingService } from '../services/tracking.service';
import { AlertActions } from '../../core/actions/alert.actions';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { AlertType } from '../../core/models/alert.model';
import { catchError, exhaustMap, map } from 'rxjs/operators';

@Injectable()
export class TrackingEpics {
    constructor( private _trackingActions: TrackingActions,
                private _trackingService: TrackingService,
                private _alertActions: AlertActions) {}

    public createEpics() {
        return combineEpics (
            loadTrackingInfoEpic(this._trackingActions, this._trackingService, this._alertActions)
        );
    }
}

export function loadTrackingInfoEpic(actions: TrackingActions, service: TrackingService,
                                    alertActions: AlertActions) {
    return action$ => action$.pipe(
        ofType(LOAD_TRACKING_INFO),
        exhaustMap((action: any) => {
            return service.get(action.payload).pipe(
                map(data => actions.loadTrackingInfoSucceeded(data))
                ,catchError(error => {
                    return of(actions.loadTrackingInfoFailed({
                        error
                    }), alertActions.show({ message: error, type: AlertType.Error }));
                }));
        })
    );
}
