import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MyErrorStateMatcher } from '../../../admin/containers/driver-portal/driver-portal.component';
import { AdminDriverPortalActions } from 'app/admin/actions/admin.driver.portal.actions';
import { NewDriverDTO, DriverModalModeEnum, VehicleDTO } from 'app/admin/models/driverportal.model';

@Component({
  selector: 'tuya-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss']
})
export class VehicleFormComponent implements OnInit, OnChanges {

  @Input() formData: UntypedFormGroup;
  @Input() vehiclesProp: UntypedFormGroup;
  @Input() isLoading: boolean;
  @Input() modalMode: number;

  matcher = new MyErrorStateMatcher();
  vehicleFormProp: any;
  submitBtnText = 'Save';

  constructor(private adminDriverPortalActions: AdminDriverPortalActions) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.vehicleFormProp = this.formData.controls.vehicleData;
      this.submitBtnText = (this.modalMode === DriverModalModeEnum.AddDriver ||
        this.modalMode === DriverModalModeEnum.UpdateVehicle) ? 'Submit' : 'Save';
    }
  }

  onSubmitVehicle() {
    if (this.modalMode === DriverModalModeEnum.AddDriver) {
      const val: NewDriverDTO = this.formData.value.driverData;
      val.vehicle = this.formData.value.vehicleData;
      if (!this.formData.valid) {
        return;
      }
      delete val.vehicle.driverId;
      delete val['id'];
      this.adminDriverPortalActions.addNewDriver(val);
    } else if (this.modalMode === DriverModalModeEnum.UpdateVehicle) {
      const updatedVehicle = {
        id: this.formData.value.vehicleData.id,
        make: this.formData.value.vehicleData.make,
        model: this.formData.value.vehicleData.model,
        vehicleType: this.formData.value.vehicleData.vehicleType
      };

      if (this.vehicleFormCheckValidation(updatedVehicle.make, updatedVehicle.model, updatedVehicle.vehicleType)) {
        return;
      }
      this.adminDriverPortalActions.updateDriverVehicle(updatedVehicle);
    } else {
      const vehicle: VehicleDTO = {
        driverId: this.formData.value.driverData.id,
        make: this.formData.value.vehicleData.make,
        model: this.formData.value.vehicleData.model,
        vehicleType: this.formData.value.vehicleData.vehicleType
      };
      if (this.vehicleFormCheckValidation(vehicle.make, vehicle.model, vehicle.vehicleType)) {
        return;
      }
      this.adminDriverPortalActions.addDriverVehicle(vehicle);
    }
  }

  vehicleFormCheckValidation(make: string, model: string, vehicleType: number) {
    return (make === null || model === null || vehicleType === null ||
      make.trim() === '' || model.trim() === '' || vehicleType === null);
  }

  onCloseModal() {
    this.adminDriverPortalActions.driverModalReact(false);
  }

}
