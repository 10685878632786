<form name="pickUpDateForm"
      #pickUpDateForm="ngForm"
      [ngClass]="{'component-disabled':disabled}"
      novalidate>
    <h3>{{(isPickupStop ? 'tuya-shipment.tuya-stop-details.whenPickup' :
        'tuya-shipment.tuya-stop-details.whenDelivered') | translate}}</h3>
    <div class="form-group">
        <label for="date-form-control-select" class="label">{{(isPickupStop ? 'tuya-shipment.tuya-stop-details.pickupDateReminder' :
            'tuya-shipment.tuya-stop-details.arrivalDateReminder') | translate}}</label>
        <span  tooltip="{{'tuya-shipment.unable-change' | translate}}"
               class="date-tooltip"
               placement="top"
               containerClass="tuya-tooltip"
               container="body"
               [isDisabled]="!disabled">
             <select id="date-form-control-select" [disabled]="!isDateTypeChangeAvailable || disabled" class="form-control-select date-form-control-select" name="pickupDateType"
                     [(ngModel)]="dateType" required #pickupDateType="ngModel">
                <option *ngFor="let t of dateList;" [ngValue]="t.dateTypeId">{{ t.label }}</option>
            </select>
        </span>
    </div>
    <a (click)="isCollapsed = !isCollapsed" class="btn-link add-info-expanded"
       [ngClass]="{expanded: !isCollapsed, disabled:disabled}">
        <span [translate]="(isPickupStop ? 'tuya-shipment.tuya-stop-details.add-timeframe-pickup'
        : 'tuya-shipment.tuya-stop-details.add-timeframe-delivery')"></span>
    </a>
    <div [collapse]="isCollapsed">
        <div class="form-group">
            <tuya-arrive-timepicker
                    [hint]="'tuya-shipment.tuya-stop-details.arriveNoEarlier' | translate"
                    [datetime]="noEarlierDT"
                    [minDatetime]="minNoEarlierDT"
                    [maxDatetime]="maxNoEarlierDT"
                    (onDatetimeUpdate)="noEarlierThanUpdate($event)"
                    (invalid)="onTimeIsInvalid()"
                    [disabled]="disabled"
                    [isEditMode]="isEditMode"
                    [minDateError]="'tuya-shipment.popup-timepicker.error.min-arrive-no-earlier'"
            ></tuya-arrive-timepicker>
            <tuya-arrive-timepicker
                    [hint]="'tuya-shipment.tuya-stop-details.arriveNoLater' | translate"
                    [datetime]="noLaterDT"
                    [minDatetime]="minNoLaterDT"
                    [isEditMode]="isEditMode"
                    [maxDatetime]="maxNoLaterDT"
                    [disabled]="disabled"
                    (onDatetimeUpdate)="noLaterThanUpdate($event)"
                    (invalid)="onTimeIsInvalid()"
                    [minDateError]="'tuya-shipment.popup-timepicker.error.min-arrive-no-later'"
            ></tuya-arrive-timepicker>
        </div>
    </div>
</form>
