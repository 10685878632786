import { map, Observable } from 'rxjs';
import { IAuthState } from '../../auth/model/model';
import { IAppState } from '../../store/model';

export function getPasswordResetTokenExists(auth$: Observable<IAuthState>): Observable<boolean> {
    return auth$.pipe(map(auth => !!(auth.passwordResetToken)));
}

export class AuthSelectors {
    public static loggedInSelector = (state: IAppState): boolean => {
        if (state.auth.initRefreshFlag === 1) return true;

        return false;
    }
}