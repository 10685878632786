import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { StopDto, StopStatusId } from '../../../../core/models/dto';
import { NgForm } from '@angular/forms';
import { StopDetailsFormComponent } from '../stop-details-form/stop-details-form.component';

@Component({
    selector: 'tuya-shipment-stop-contact-fields',
    templateUrl: 'stop-contact-fields.component.html',
    styleUrls: ['stop-contact-fields.component.scss']
})
export class StopContactFieldsComponent extends StopDetailsFormComponent {
    @ViewChild('form', {static: true}) public form: NgForm;
    @Output()
    validate = new EventEmitter<any>();

    @Input() set stop (stop) {
        this._stop = stop;
        if (this.stop.contactPhoneNumber) {
            this.contactPhoneNumberValue = this.stop.contactPhoneNumber;

        }
    }

    get stop() {
        return this._stop;
    }

    @Input() disabled: boolean;

    public StopStatusId: typeof StopStatusId = StopStatusId;

    public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public phonePattern = /[0-9]{10}/;
    public contactNamePattern = /^([a-zA-Z-.üöäÿïëßÄËÏÖÜŸ']+\s*)+$/;
    public contactEmailPattern = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    public isContactPhoneValid = true;
    public isContactEmailValid = true;
    public isCollapsed = true;
    public isOnlySpaces: boolean;
    public contactPhoneNumberValue = '';
    private _stop: StopDto = new StopDto();
    constructor() {
        super();
    }

    onValidatePhone() {
        this.contactPhoneNumberValue = this.stop.contactPhoneNumber;
        // remove additional mask chars from phone value and validate it
        if (this.stop.contactPhoneNumber) {
            this.stop.contactPhoneNumber = this.stop.contactPhoneNumber
                ? this.stop.contactPhoneNumber.replace(/\D+/g, '') : this.stop.contactPhoneNumber;
            this.isContactPhoneValid = !this.stop.contactPhoneNumber.length || this.phonePattern.test(this.stop.contactPhoneNumber);
        } else {
            this.isContactPhoneValid = true;
        }
        this.validate.emit({contactPhoneNumber: this.isContactPhoneValid});
    }

    onValidateContactName() {
        this.isOnlySpaces = this.stop.contactName.length && !this.stop.contactName.trim().length;
        const isContactNameValid = this.contactNamePattern.test(this.stop.contactName) &&
            !!this.stop.contactName.length && !this.isOnlySpaces;
        this.validate.emit({contactName: isContactNameValid});
    }

    onValidateContactEmail() {
        if (this.stop.contactEmail) {
            this.isContactEmailValid = !this.stop.contactEmail.length || this.contactEmailPattern.test(this.stop.contactEmail);
        } else {
            this.isContactEmailValid = true;
        }
        this.validate.emit({contactEmail: this.isContactEmailValid});
    }

    getDisabledStatement(isTooltipStatement?: boolean): boolean {
        return isTooltipStatement ?
            !this.disabled || (this.disabled && StopStatusId.Completed > this.stop.statusId) :
            this.disabled && this.stop.statusId >= StopStatusId.Completed;
    }
}
