<div class="wrapper">
    <perfect-scrollbar class="sidebar-content">
        <tuya-stop-event-item
            *ngFor="let stopItem of trackingInfo.stopEventLog"
            [stopItem]="stopItem"
            [typeId]="trackingInfo.stopInfo.typeId" >
        </tuya-stop-event-item>
    </perfect-scrollbar>
</div>


