<div class="modal-wrapper">
    <div class="close-icon">
        <button mat-icon-button (click)="onCloseButtonClick(true)">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    
    <div class="modal-body" [ngSwitch]="data.type">
        <!-- POPUP MODAL: - ICON -->
        <div class="builder-modal-status-icon"></div>

        <!-- POPUP MODAL: - TITLE -->
        <div class="builder-modal-title"
             *ngSwitchCase="popupType.CreateUpdateOrder">
            {{ data.title | translate: { code: data.titleProps?.code } }}
        </div>

        <!-- POPUP MODAL: - SUBTITLE -->
        <div class="builder-modal-subtitle" 
             *ngSwitchCase="popupType.CreateUpdateOrder">
            <span [innerHtml]="data.subTitle | translate: { code: data.subTitleProps?.code, message: data.subTitleProps?.message }"></span>
        </div>

    </div>
 
 </div>
 