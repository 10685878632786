import { Action } from 'redux';
import { IDriverPortal, DriverDTO, NewDriverDTO, DriverModalModeEnum, ServiceArea, VehicleEnum, VehicleDTO } from '../models/driverportal.model';
import { DriverPortalAction, GET_DRIVERS, GET_DRIVERS_SUCCESS, GET_DRIVERS_FAILURE, ACTIVATE_DIACT_DRIVER, ACTIVATE_DIACT_SUCCESS, ACTIVATE_DIACT_FAILURE,
        DRIVER_RESET_PASSWORD_SUCCESS, DRIVER_RESET_PASSWORD_FAILURE, DRIVER_RESET_PASSWORD, UPDATE_PAGINATION_DRIVER_TABLE, ADD_NEW_DRIVER,
        ADD_NEW_DRIVER_SUCCESS, ADD_NEW_DRIVER_FAILURE, DRIVER_MODAL_REACT,
        DRIVER_SET_SEARCH_QUERY, UPDATE_DRIVER_MODAL_MODE, UPDATE_VEHICLE, UPDATE_VEHICLE_SUCCESS,
        SHOW_SNACK_BAR, UPDATE_VEHICLE_FAILURE, SET_INIT_STATE, ADD_VEHICLE, ADD_VEHICLE_SUCCESS, ADD_VEHICLE_FAILURE } from '../actions/admin.driver.portal.actions';
import { LOGOUT_SUCCESS } from 'app/auth/actions/auth.actions';

export const initialState: IDriverPortal = {
    drivers: [],
    pagination: {
        totalItemCount: 0,
        currentPageNumber: 1,
        pageSize: 10,
        searchQuery: '',
        isSearching: false
    },
    driverModal: {
      mode: DriverModalModeEnum.AddDriver,
      isActive: false,
      isDriverSubmitLoading: false
    },
    showSnackBar: {show: false, message: '', subTitle: ''},
    isLoading: false
};

export const addNewDriver = (drivers: DriverDTO[], newDriver: NewDriverDTO, driverId: number): DriverDTO[] => {
  const driver: DriverDTO = {
    id: driverId,
    name: `${newDriver.firstname} ${newDriver.lastname}`,
    driverCode: newDriver.cxtNumber,
    phoneNumber: newDriver.phoneNumber,
    email: newDriver.emailAddress,
    serviceArea: newDriver.serviceArea,
    vehicle: newDriver.vehicle,
    active: false
  };
  drivers.unshift(driver);
  drivers.pop();
  return drivers;
};

export const setDriverVehicle = (drivers: DriverDTO[], payload: VehicleDTO): DriverDTO[] => {
  for (let i = 0; i < drivers.length; i++) {
    if (payload.driverId) {
      if (drivers[i].id === payload.driverId) {
        drivers[i].vehicle = payload;
        break;
      }
    } else {
      if (drivers[i].vehicle && drivers[i].vehicle.id === payload.id) {
        drivers[i].vehicle = payload;
        break;
      }
    }
  }
  return drivers;
};

export function driverPortalReducer(state = initialState, a: Action): IDriverPortal {
  const {type, payload} = a as DriverPortalAction;
  switch (type) {
    case GET_DRIVERS: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          isSearching: payload === 'search' ? true : state.pagination.isSearching
        },
        isLoading: payload === 'all' ? true : state.isLoading
      };
    }
    case GET_DRIVERS_SUCCESS: {
      return {
        ...state,
        drivers: payload.items,
        pagination: {
          ...state.pagination,
          totalItemCount: payload.totalItemCount,
          currentPageNumber: payload.currentPageNumber,
          pageSize: payload.pageSize,
          isSearching: false
        },
        isLoading: false
      };
    }
    case GET_DRIVERS_FAILURE: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          isSearching: false
        },
        isLoading: false
      };
    }

    case ADD_NEW_DRIVER: {
      return {
        ...state,
        driverModal: {
          ...state.driverModal,
          isDriverSubmitLoading: true
        }
      };
    }
    case ADD_NEW_DRIVER_SUCCESS: {
      payload.newDriver.vehicle.id = payload.vehicleId;
      return {
        ...state,
        drivers: addNewDriver([...state.drivers], payload.newDriver, payload.driverId),
        pagination: {
          ...state.pagination,
          totalItemCount: state.pagination.totalItemCount + 1
        },
        driverModal: { ...state.driverModal, isDriverSubmitLoading: false }
      };
    }
    case ADD_NEW_DRIVER_FAILURE: {
      return {
        ...state,
        driverModal: {...state.driverModal, isDriverSubmitLoading: false }
      };
    }

    case ACTIVATE_DIACT_DRIVER: {
      return { ...state, isLoading: true };
    }
    case ACTIVATE_DIACT_SUCCESS: {
      return {
        ...state,
        drivers: state.drivers.map(item => {
          item.active = item.id === payload.id ? payload.isActivate : item.active;
          return item;
        }),
        isLoading: false
      };
    }
    case ACTIVATE_DIACT_FAILURE: {
      return {...state, isLoading: false};
    }

    case DRIVER_RESET_PASSWORD: {
      return {...state, isLoading: true};
    }
    case DRIVER_RESET_PASSWORD_SUCCESS: {
      return {...state, isLoading: false};
    }
    case DRIVER_RESET_PASSWORD_FAILURE: {
      return {...state, isLoading: false};
    }

    case UPDATE_PAGINATION_DRIVER_TABLE: {
      return {
          ...state,
          pagination: {
            ...state.pagination,
            currentPageNumber: payload.currentPageNumber,
            pageSize: payload.pageSize,
          }
      };
    }

    case DRIVER_MODAL_REACT: {
      return {
        ...state,
        driverModal: {
          ...state.driverModal,
          isActive: payload
        }
      };
    }

    case DRIVER_SET_SEARCH_QUERY: {
      if (payload === '') {
        return {
          ...state,
          pagination: {
            ...initialState.pagination
          }
        };
      }
      return {
        ...state,
        pagination: {
          ...state.pagination,
          searchQuery: payload
        }
      };
    }

    case UPDATE_DRIVER_MODAL_MODE: {
      return {
        ...state,
        driverModal: {
          ...state.driverModal,
          mode: payload
        }
      };
    }

    case ADD_VEHICLE:
    case UPDATE_VEHICLE: {
      return {
        ...state,
        driverModal: { ...state.driverModal, isDriverSubmitLoading: true }
      };
    }
    case ADD_VEHICLE_SUCCESS:
    case UPDATE_VEHICLE_SUCCESS: {
      return {
        ...state,
        drivers: setDriverVehicle([...state.drivers], payload),
        driverModal: {
          ...state.driverModal,
          isDriverSubmitLoading: false
        }
      };
    }
   case ADD_VEHICLE_FAILURE:
   case UPDATE_VEHICLE_FAILURE: {
      return {
        ...state,
       driverModal: {
          ...state.driverModal,
          isDriverSubmitLoading: false
        }
      };
    }

    case SHOW_SNACK_BAR: {
      return {...state, showSnackBar: payload };
    }

    case LOGOUT_SUCCESS:
    case SET_INIT_STATE: {
      return { ...initialState };
    }
  }
  return state;
}
