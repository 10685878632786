import { Component, Input, OnInit } from '@angular/core';
import { OrderStop, OrderExpandedView } from '../../model/my-orders.model';

@Component({
  selector: 'tuya-order-summary-log',
  templateUrl: './order-summary-log.component.html',
  styleUrls: ['./order-summary-log.component.scss']
})
export class OrderSummaryLogComponent implements OnInit {

  @Input() stopList: Array<OrderStop>;
  @Input() order: OrderExpandedView;
  length: number;

  constructor() { }

  ngOnInit() {
    this.length = this.stopList.length;
  }

  isLast(i): boolean {
    return i === this.stopList.length - 1;
  }

}
