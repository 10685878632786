import { IShipperAdminState } from '../models/admin.model';
import { Action } from 'redux';
import { ShipperAdminAction,
  SHIPPER_SEARCH,
  SHIPPER_SEARCH_SUCCEEDED,
  SHIPPER_SEARCH_FAILED,
  SELECT_SHIPPER,
  COMPLETE_STOP,
  COMPLETE_STOP_SUCCESS,
  COMPLETE_STOP_FAILURE,
  ADMIN_REOFFER_EDIT_ACTION,
  ADMIN_REOFFER_EDIT_ACTION_SUCCEEDED,
  ADMIN_REOFFER_EDIT_ACTION_FAILED,
  ADMIN_REOFFER_ORDER,
  ADMIN_REOFFER_ORDER_SUCCESS,
  ADMIN_REOFFER_ORDER_FAILURE,
  ADMIN_SET_REOFFER_EXTENDABLE,
  ADMIN_SET_SELECTED_CANDIDATES,
  ADMIN_SET_OFFER_TYPE,
  ADMIN_CLEAR_REOFFER,
  SET_IS_LOADING_TRUE,
  SET_IS_LOADING_FALSE,
  ADMIN_RESET_STORE_VALUES,
  UNDO_STOP_COMPLETE,
  UNDO_STOP_COMPLETE_SUCCESS,
  UNDO_STOP_COMPLETE_FAILURE,
  SHIPPER_TABLE_PAGINATE,
  LOAD_DRIVER_LOCATIONS,
  LOAD_DRIVER_LOCATIONS_FAILURE,
  LOAD_DRIVER_LOCATIONS_SUCCESS
} from '../actions/shipper.admin.actions';
import { LOGOUT } from '../../auth/actions/auth.actions';
import { SessionStorageUtil } from '../../store/sessionStorage';
import { TURN_OFF_ALL_SPINNERS } from '../../core/actions/alert.actions';
import { DRIVER_POOLS_LOAD_SUCCESS } from 'app/core/actions/pools.actions';

export const initialState = {
  shipperSearchTerm: '',
  shipperSearchResultsPerPage: SessionStorageUtil.getInstance().get('admin.shipperAdmin.shipperSearchResultsPerPage', 10),
  shipperSearchCurrentPage: SessionStorageUtil.getInstance().get('admin.shipperAdmin.shipperSearchCurrentPage', 1),
  shipperResults: [],
  shipperResultsCount: SessionStorageUtil.getInstance().get('admin.shipperAdmin.shipperResultsCount', 0),
  isLoading: false,
  selectedShipper: SessionStorageUtil.getInstance().get('admin.shipperAdmin.selectedShipper', null),
  currentReoffer: SessionStorageUtil.getInstance().get('admin.shipperAdmin.currentReoffer', {}),
  isReofferLoading: false,
  drivers: []
};

export function shipperAdminReducer(state = initialState, a: Action): IShipperAdminState {
  const action = a as ShipperAdminAction;
  switch (action.type) {
    case SHIPPER_SEARCH: {
      return {
        ...state,
        shipperSearchTerm: action.payload.value,
        isLoading: true,
        shipperSearchCurrentPage: action.payload.currentPageNumber
      };
    }
    case SHIPPER_SEARCH_SUCCEEDED: {
      return {
        ...state,
        shipperResults: action.payload.results,
        shipperResultsCount: action.payload.totalCount,
        isLoading: false
      };
    }
    case SHIPPER_SEARCH_FAILED: {
      return {
        ...state,
        shipperResults: [],
        shipperResultsCount: 0,
        isLoading: false
      };
    }
    case LOAD_DRIVER_LOCATIONS: {
      return {
        ...state
      };
    }
    case LOAD_DRIVER_LOCATIONS_SUCCESS: {
      return {
        ...state,
        drivers: action.payload
      };
    }
    case LOAD_DRIVER_LOCATIONS_FAILURE: {
      return {
        ...state,
        drivers: []
      };
    }
    case SHIPPER_TABLE_PAGINATE: {
      return {
        ...state,
        shipperSearchCurrentPage: action.payload
      };
    }
    case SELECT_SHIPPER: {
      return {
        ...state,
        selectedShipper: action.payload.shipper
      };
    }
    case COMPLETE_STOP: {
      return {
        ...state,
        isLoading: true
      };
    }
    case COMPLETE_STOP_SUCCESS:
    case DRIVER_POOLS_LOAD_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case COMPLETE_STOP_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case LOGOUT: {
      return {
        ...state,
        ...initialState
      };
    }
    case ADMIN_REOFFER_EDIT_ACTION: {
      return {
        ...state,
        isReofferLoading: true
      };
    }
    case ADMIN_REOFFER_EDIT_ACTION_SUCCEEDED: {
      return {
        ...state,
        currentReoffer: action.payload,
        isReofferLoading: false
      };
    }
    case ADMIN_REOFFER_EDIT_ACTION_FAILED: {
      return {
        ...state,
        isReofferLoading: false
      };
    }
    case ADMIN_REOFFER_ORDER: {
      return {
        ...state,
        isReofferLoading: true
      };
    }
    case ADMIN_REOFFER_ORDER_SUCCESS: {
      return {
        ...state,
        isReofferLoading: false
      };
    }
    case ADMIN_REOFFER_ORDER_FAILURE: {
      return {
        ...state,
        isReofferLoading: false
      };
    }
    case ADMIN_SET_REOFFER_EXTENDABLE: {
      return {
        ...state,
        currentReoffer: {
          ...state.currentReoffer,
          isExtendable: action.payload
        }
      };
    }
    case ADMIN_SET_SELECTED_CANDIDATES: {
      return {
        ...state,
        currentReoffer: {
          ...state.currentReoffer,
          selectedCandidates: action.payload,
        }
      };
    }
    case ADMIN_SET_OFFER_TYPE: {
      return {
        ...state,
        currentReoffer: {
          ...state.currentReoffer,
          offerType: action.payload
        }
      };
    }
    case ADMIN_CLEAR_REOFFER: {
      return {
        ...state,
        currentReoffer: {}
      };
    }
    case TURN_OFF_ALL_SPINNERS: {
      return {
          ...state,
          isLoading: false,
          isReofferLoading: false
      };
    }
    case SET_IS_LOADING_TRUE: {
      return {
        ...state,
        isLoading: true
      };
    }
    case SET_IS_LOADING_FALSE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case ADMIN_RESET_STORE_VALUES: {
      return {
        ...state,
        selectedShipper: null
      };
    }
    case UNDO_STOP_COMPLETE: {
      return {
        ...state,
        isLoading: true
      };
    }
    case UNDO_STOP_COMPLETE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UNDO_STOP_COMPLETE_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
}

