import { AddressDto, StopDto } from '../../core/models/dto';

export enum WizardStep {
    Initial,
    InitialStopEdit, // First Pickup Stop
    Stops,
    EditStopDetails, // DeliveryStop,
    SummaryAndDriverAssignment,
    SummaryAndPaymentOrder,
    Confirmation,
}

export enum StopDetailsSteps {
    Dates,
    Contacts,
    Items,
    Notes
}

export enum MainStopDetailsSteps {
    Dates,
    Contacts,
    Notes
}

export class DetailsUpdate {
  details: StopDto; // should be StopDetails
  isValid: boolean;
}

export interface Time {
    hour?: string | number;
    minute?: string | number;
    seconds?: string | number;
    isPM?: boolean;
}

export class ItemsDetails {
    weight: number;
    count: number;
    returnCount: number;
}

export class UISpecificState {
    wizardStep: WizardStep;
    stopDetailsEdit: StopDto | null;
    continueAllowedMap: {[key: number]: boolean};
    isStopDetailsEditMode: boolean;
    isLoading: boolean;
    firstLocation: AddressDto | null;
    stopNameEditMode: boolean;
    defaultItemSize: number;
    invalidTimeframesStops: Array<string>;
    disabled: boolean;
    isValidatePayment: boolean;
}
