import { AuthActions } from './../../auth/actions/auth.actions';
import { ShipperActions } from './../../shipper/actions/shipper.status.actions';
import { transformValidateCompanyResult } from './../../register/transformations/validate-result.transformation';
import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { ProfilesService } from './../services/profiles.service';
import {
    ProfilesActions,
    SAVE_COMPANY_PROFILE,
    SAVE_COMPANY_PROFILE_SUCCESS,
    SAVE_SHIPPER_PROFILE,
    SAVE_SHIPPER_PROFILE_SUCCESS,
    GO_TO_COMPANY_PROFILE
} from './../actions/profiles.actions';
import { ShipperAdminActions } from '../../admin/actions/shipper.admin.actions';

import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';
import { NavigationEnd, Router } from '@angular/router';
import { ShipperProfileDto } from '../../core/models/dto';
import { BehaviorSubject } from 'rxjs';
import { select } from '@angular-redux/store';
import { transformToProfileToEdit } from '../../shipper/utils/shipper-profile.utils';
import { UPDATE_LOCATION } from '@angular-redux/router';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { MapActions } from 'app/core/actions/map.actions';

@Injectable()
export class ProfilesEpics {
    @select(['auth', 'currentUser', 'shipperProfile'])
    currentUserProfile$: Observable<ShipperProfileDto>;
    @select(['auth', 'currentUser', 'adminProfile'])
    adminUserProfile$: Observable<ShipperProfileDto>;
    @select(['admin', 'shipperAdmin', 'selectedShipper', 'userAccountId'])
    selectedShipperUserAccountId$: Observable<any>;
    @select(['profiles', 'profileToEdit', 'userAccountId'])
    profileToEditUserAccountId$: Observable<any>;

    private currentUserProfile = new BehaviorSubject<ShipperProfileDto>(new ShipperProfileDto());
    private adminUserProfile = new BehaviorSubject<ShipperProfileDto>(new ShipperProfileDto());
    private selectedShipperUserAccountId = new BehaviorSubject<any>(null);
    private profileToEditUserAccountId = new BehaviorSubject<any>(null);

    constructor(private profilesActions: ProfilesActions,
        private authActions: AuthActions,
        private shipperAdminActions: ShipperAdminActions,
        private profilesService: ProfilesService,
        private alertActions: AlertActions,
        private shipperActions: ShipperActions,
        private router: Router,
        private mapActions: MapActions) {
        this.currentUserProfile$.subscribe(this.currentUserProfile);
        this.adminUserProfile$.subscribe(this.adminUserProfile);
        this.selectedShipperUserAccountId$.subscribe(this.selectedShipperUserAccountId);
        this.profileToEditUserAccountId$.subscribe(this.profileToEditUserAccountId);
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                switch (event.url) {
                    case '/profile':
                    case '/company':
                        if ( this.adminUserProfile.value && this.adminUserProfile.value.isAdmin) {
                            // fetch profiles only if selectedShipperUserAccountId different than profileToEditUserAccountId
                            if (this.profileToEditUserAccountId.value !== this.selectedShipperUserAccountId.value) {
                                this.shipperAdminActions.loadShipperAndCompanyProfilesAsAdmin(this.selectedShipperUserAccountId.value);
                            }
                        } else {
                            this.profilesActions.editShipperOrCompanyProfile(this.currentUserProfile.value);
                        }
                        break;
                    default:
                        break;
                }
            }
        });
    }

    public createEpic() {
        return combineEpics(
                createShipperProfileUpdateEpic(this.profilesActions, this.shipperAdminActions,
                                                this.profilesService, this.alertActions,
                                                this.shipperActions, this.authActions),
                createShipperProfileUpdateStateEpic(this.authActions),
                createCompanyProfileUpdateEpic(this.profilesActions, this.shipperAdminActions,
                                                this.profilesService, this.mapActions),
                createGoToCompanyProfileEpic(this.router)
            );
    }
}

export function createShipperProfileUpdateEpic(profilesActions: ProfilesActions, shipperAdminActions: ShipperAdminActions,
                                                profilesService: ProfilesService, alertActions: AlertActions,
                                                shipperActions: ShipperActions, authActions: AuthActions) {
    return (action$, store) => action$.pipe(
        ofType(SAVE_SHIPPER_PROFILE),
        exhaustMap((action: any) => {
            return profilesService.updateShipperProfile(action.payload).pipe(
                mergeMap(data => {
                    const adminProfile = store.value.auth.currentUser.adminProfile;
                    const isAdmin = adminProfile ? adminProfile.isAdmin : false;
                    if (isAdmin) {
                        // this action saves returned shipper profile to state.profiles.profileToEdit
                        // does not require the entire ShipperProfileDto, only profileToEdit
                        // profileToEdit is the ShipperProfileDto with businessEntity removed
                        const profileToEdit = transformToProfileToEdit(data);
                        return of(shipperAdminActions.saveShipperProfileAsAdminSuccess(profileToEdit),
                                authActions.updateShowSuspendStatus(data.suspended),
                                shipperActions.loadProfileSucceeded(data),
                                authActions.updateShipperStatuses(data));
                    }
                    // this action also saves returned shipper profile to state.profiles.profileToEdit
                    // however it triggers createShipperProfileUpdateStateEpic to save the ShipperProfileDto
                        // to state.auth.currentUser.shipperProfile and requires the full ShipperProfileDto
                    return of(profilesActions.saveShipperProfileSucceeded(data));
                })
                ,catchError(error => {
                    console.log('error');
                    return of(profilesActions.saveShipperProfileFailed({error}),
                                alertActions.show({type: AlertType.BadRequest, error: error}));
                }));
        })
    );
}

export function createShipperProfileUpdateStateEpic(authActions: AuthActions) {
    return action$ => action$.pipe(
        ofType(SAVE_SHIPPER_PROFILE_SUCCESS, SAVE_COMPANY_PROFILE_SUCCESS),
        exhaustMap((action: any) => {
            return of(authActions.updateShipperProfileToState(action.payload)); // save new data to store(auth->currentUser->shipperProfile)
        })
    );
}

export function createCompanyProfileUpdateEpic(profilesActions: ProfilesActions, shipperAdminActions: ShipperAdminActions,
                                                profilesService: ProfilesService, mapActions: MapActions) {
    return (action$, store) => action$.pipe(
        ofType(SAVE_COMPANY_PROFILE),
        exhaustMap((action: any) => {
            return profilesService.updateCompanyProfile(action.payload).pipe(
                mergeMap(data => {
                    const adminProfile = store.value.auth.currentUser.adminProfile;
                    const isAdmin = adminProfile ? adminProfile.isAdmin : false;
                    const companyProfile = data.businessEntity;
                    const mapCenter = {
                        lat: companyProfile.physicalAddress.latitude,
                        lng: companyProfile.physicalAddress.longitude,
                    };
                    if (isAdmin) {
                        return of(shipperAdminActions.saveCompanyProfileAsAdminSuccess(companyProfile),
                                mapActions.setCenter(mapCenter));
                    }

                    return of(profilesActions.saveCompanyProfileSucceeded(data),
                              mapActions.setCenter(mapCenter));
                })
                ,catchError(resp => {
                    const result = transformValidateCompanyResult(resp.error);
                    console.log('error');
                    return of(profilesActions.saveCompanyProfileFailed({
                        isValid: result.isValid,
                        nameErrorMsg: result.nameErrorMsg
                    }));
                }));
        })
    );
}

export function createGoToCompanyProfileEpic(router: Router) {
    return (action$) => action$.pipe(
        ofType(GO_TO_COMPANY_PROFILE),
        map(() => {
            router.navigate(['/company']);
            return {type: UPDATE_LOCATION, payload: '/company'};
        })
    );
}
