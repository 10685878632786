<div class="stop-list-wrapper">
  <div class="stop-list">
    <div *ngFor="let stop of stopList; let i = index;" class="stop-line row {{ OrderHelper.getStopType(stop.typeId) | translate}}">
      <div class="icons col-sm-1">
        <div class="line"></div>
        <div class="bubble"></div>
      </div>
      <div class="data col-sm-10">
        <div class="line-desc">
          <div class="title">
            {{ OrderHelper.getStopTypeName(stop.typeId) | translate}}   <!-- optimize it? -->
            <span class="items"> {{getStopItemsDetails(stop).count}}
              <span [translate]="'tuya-shipment.tuya-stop-details.items'"></span>
              <span *ngIf="stop.typeId !== StopType.Return">| {{getStopItemsDetails(stop).weight}}
                <span [translate]="'tuya-shipment.tuya-stop-details.lbs'"></span>
              </span>
            </span>
          </div>
          <div class="return-items" *ngIf="stop.typeId === StopType.DeliveryWithReturn">
            <span class="uppercase" [translate]="'tuya-shipment.tuya-stop-details.return-items'"></span>
            <span class="items"> {{getStopItemsDetails(stop).returnCount}}
              <span [translate]="'tuya-shipment.tuya-stop-details.items'"></span>
            </span>
          </div>
        </div>
        <div class="address">{{stop.nameOrDescription}}
          <span class="small">{{stop.address.addressLine}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
