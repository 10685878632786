import { Action } from 'redux';
import { OrdersTableState } from '../../model/my-orders.model';
import {HOME_LOAD_PAGE, HOME_LOAD_PAGE_SUCCESS, HomeAction, HOME_LOAD_PAGE_FAILURE} from '../../actions/home.actions';
import { CANCEL_ORDER_SUCCESS, UPDATE_ORDER_STATUS, CREATE_RECURRING_ORDER,
    CREATE_RECURRING_ORDER_SUCCESS, CREATE_RECURRING_ORDER_FAILURE,
    GET_RECURRING_DETAIL, GET_RECURRING_DETAIL_SUCCESS, GET_RECURRING_DETAIL_FAILURE,
    DELETE_RECURRING_DETAIL, DELETE_RECURRING_DETAIL_SUCCESS, DELETE_RECURRING_DETAIL_FAILURE,
    CLEAR_RECURRING_DETAIL } from '../../actions/my-orders.actions';
import { TURN_OFF_ALL_SPINNERS } from '../../../core/actions/alert.actions';
import { OrderRespDtoV2 } from 'app/core/models/dto';

export const initialOrdersTableState: OrdersTableState = {
    currentOrdersId: [],
    ordersCommonInfo: {},
    recurrenceOrderDetail: null,
    isLoading: true
};

function updateOrder(orders, payload) {
    orders[payload.orderId].recurrenceOrderDetail = payload.data;
    return orders;
}

function prepareOrdersState(payload): any {
    const currentOrders = payload.pagedList.items;
    const currentOrdersId = currentOrders.map((order: OrderRespDtoV2) => order.id);

    const ordersCommonInfo = currentOrders.reduce((state, order) => {
        const {expandedView: expandedInfo, ...commonOrderInfo} = order;
        state[order.id] = commonOrderInfo;
        return state;
    }, {});
    // TODO: add caching if needed
    return {
        ordersCommonInfo,
        currentOrdersId
    };
}

export function ordersTableReducer(state = initialOrdersTableState, a: Action): OrdersTableState {
    const {type, payload} = a as HomeAction;
    switch (type) {
        case HOME_LOAD_PAGE_SUCCESS: {
            return {
                ...state,
                ...prepareOrdersState(payload),
                recurrenceOrderDetail: null,
                isLoading: false
            };
        }
        case HOME_LOAD_PAGE: {
            return {
                ...state,
                isLoading: true
            };
        }
        case HOME_LOAD_PAGE_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        case CANCEL_ORDER_SUCCESS: {
            const order = state.ordersCommonInfo[payload.orderId];
            if (order) {
                order.statusId = payload.orderStatusId;
                return {
                    ...state,
                    ordersCommonInfo: {...state.ordersCommonInfo, [payload.orderId]: order}
                };
            } else {
                return {
                    ...state,
                    ordersCommonInfo: {...state.ordersCommonInfo}
                };
            }

        }

        case UPDATE_ORDER_STATUS: {
            const ordersCommonInfo = {...state.ordersCommonInfo};
            if (ordersCommonInfo[payload.orderId]) {
                ordersCommonInfo[payload.orderId].statusId = payload.statusId;
            }
            return {
                ...state,
                ordersCommonInfo: ordersCommonInfo
            };

        }

        case TURN_OFF_ALL_SPINNERS: {
            return {
                ...state,
                isLoading: false
            };
        }

        case CREATE_RECURRING_ORDER: {
            return {
                ...state,
                isLoading: true
            };
        }
        case CREATE_RECURRING_ORDER_SUCCESS: {
            return {
                ...state,
                ordersCommonInfo: {...updateOrder({...state.ordersCommonInfo}, payload)},
                isLoading: false
            };
        }
        case CREATE_RECURRING_ORDER_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        case GET_RECURRING_DETAIL: {
            return {
                ...state,
                isLoading: true
            };
        }
        case GET_RECURRING_DETAIL_SUCCESS: {
            return {
                ...state,
                recurrenceOrderDetail: payload,
                isLoading: false
            };
        }
        case GET_RECURRING_DETAIL_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        case DELETE_RECURRING_DETAIL: {
            return {
                ...state,
                isLoading: true
            };
        }
        case DELETE_RECURRING_DETAIL_SUCCESS: {
            return {
                ...state,
                ordersCommonInfo: {...updateOrder({...state.ordersCommonInfo}, payload)},
                isLoading: false
            };
        }
        case DELETE_RECURRING_DETAIL_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        case CLEAR_RECURRING_DETAIL: {
            return {
                ...state,
                recurrenceOrderDetail: null
            };
        }
        default:
            return state;
    }
}
