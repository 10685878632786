import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { SvgConvertorService } from 'app/shared/services/svg.convertor.service';
import { UntypedFormControl } from '@angular/forms';
import { NativeDateAdapter, MatDateFormats, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

// Please add a Injectable or Pipe or Directive or Component or NgModule decorator to the class
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {  
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    } else {
      const m = date.getMonth();
      return `${months[m]} ${date.getFullYear()}`;
    }
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { day: 'numeric' },
  },
  display: {
      dateInput: 'input',
      monthYearLabel: { month: 'short' },
      dateA11yLabel: { month: 'short', day: 'short' },
      monthYearA11yLabel: { month: 'short' },
  }
};

@Component({
  selector: 'tuya-recurring-date-pick',
  templateUrl: './recurring-date-pick.component.html',
  styleUrls: ['./recurring-date-pick.component.scss'],
  providers: [{provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}]
})

export class RecurringDatePickComponent implements OnInit {

  @Input() selectedDate = new Date();
  @Input() minDate = new Date();
  @Input() isActive = true;

  @Output() onDateChange = new EventEmitter<string>();

  date = new UntypedFormControl(new Date());
  datePickerActive = false;
  imageName = 'calendar-icon-black';

  constructor(private svgConvertorService: SvgConvertorService) { }

  ngOnInit() {
    this.svgConvertorService.svgConverToMatIcon('my-orders/calendar-black.svg', 'calendar-black');
    this.svgConvertorService.svgConverToMatIcon('my-orders/calendar-white.svg', 'calendar-white');
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (type === 'input') {
      this.onDateChange.emit(event.value.toISOString());
    }
  }

  onOpen() {
    this.datePickerActive = true;
    this.imageName = 'calendar-icon-white';
  }

  onClose() {
    this.datePickerActive = false;
    this.imageName = 'calendar-icon-black';
  }

}
