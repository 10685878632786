<div class="create-pool-wrapper">
    <div class="pool-container">
        <div class="title">Create New Driver Group</div>

        <mat-form-field class="input-container" appearance="outline">
            <mat-label>Name of Driver Group</mat-label>
            <input #poolInput matInput [(ngModel)]="poolName" autocomplete="off">
        </mat-form-field>

        <div class="button-container">
            <button mat-button class="cancel" (click)="onClosePopup()">Cancel</button>
            <button mat-button class="accept" (click)="onSubmit()" [disabled]="poolName.trim() === ''">Submit</button>
        </div>
    </div>
</div>