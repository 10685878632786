<div class="stop-line row {{getTypeName() | translate}}"
     [ngClass]="{'edit-mode': isSelected(), 'optional-line': isOptionalStopLine }"
     (click)="selectStop.emit({id: stop.id, sequenceNumber: stop.sequenceNumber})">
    <div class="icons shipment-builder-stop-icons col-sm-2">
        <div class="line {{getLineClass() | translate}}"></div>
        <div class="bubble"></div>
    </div>
    <div *ngIf="!isNewStop(); then label else input"></div>

    <ng-template #label>
        <div class="col-sm-10 label-stop stop-line-container">
            <div class="line-header">
                <tuya-stop-line-header
                    [stopTypeName]="OrderHelper.getStopTypeName(stop.typeId)"
                    [stop]="stop"
                    [isOptionalStopLine]="isOptionalStopLine"
                    [isNewStop]="isNewStop()"
                    [stopItems]="stopItems">
                </tuya-stop-line-header>
                <div class="line-actions" [ngClass]="{'edit-actions': isSelected()}">
                    <button
                        *ngIf="!isSelected() && !hasInvalidAddress"
                        class="uppercase btn-link btn-edit-details"
                        [ngClass]="{'highlighted': !isStopsRequiredFieldsProvided}"
                        (click)="onEditStop(stop.id)">Edit Details</button>
                    <span tooltip="{{'tuya-shipment.unable-change' | translate}}"
                          placement="top"
                          container="body"
                          containerClass="tuya-tooltip"
                          [isDisabled]="!disabled">
                        <button
                            *ngIf="canDelete && !hasInvalidAddress"
                            [disabled]="disabled"
                            class="btn-link btn-delete"
                            (click)="stop.typeId === StopType.Return ? onRemoveReturn(removeReturnStop) : onRemoveStop(stop.id) "></button>
                    </span>

                </div>
            </div>
            <div class="line-header timeframe" [ngClass]="{'pickup-color' : stop.typeId === StopType.Pickup}">
                <span *ngIf="isInvalidTimeframe" class="validation">{{'tuya-shipment.tuya-stop-details.invalid-timeframe' | translate}}</span>
                <span>{{ stop.pickupNoEarlierThan | date:'M/dd/yyyy, h:mm a' }} - {{ stop.pickupNoLaterThan | date:'M/dd/yyyy, h:mm a' }}</span>
            </div>
            <div class="line-body">
                <div *ngIf="isSelected(); then editMode else showMode"></div>
            </div>
        </div>
    </ng-template>

    <ng-template #input>
        <div class="col-sm-10 input-stop stop-line-container">
            <div class="line-header">
                <tuya-stop-line-header
                    [stopTypeName]="getTypeName(stop.typeId)"
                    [isOptionalStopLine]="isOptionalStopLine"
                    [isNewStop]="isNewStop()">
                </tuya-stop-line-header>
            </div>
            <div class="line-body">
                <div class="line-tpl edit-mode-tpl">
                    <div class="line-address"
                         tooltip="{{'tuya-shipment.unable-change' | translate}}"
                         placement="top"
                         [isDisabled]="!disabled"
                         containerClass="tuya-tooltip"
                         container="body">
                        <tuya-address-autocomplete
                            [stopId]="stop.id"
                            [selectedStopId]="selectedStop.id"
                            [stopType]="desiredStopType"
                            [className]="getTypeName() | translate"
                            [isControlDisabled]="disabled"
                            [isOptionalStop]="isOptionalStopLine">
                        </tuya-address-autocomplete>
                    </div>
                    <div>
                        <input type="text"
                               name="suiteNumber"
                               class="form-control suiteNumber"
                               [(ngModel)]="stop.address.suiteNumber"
                               [disabled]="disabled && (stop.statusId >= StopStatusId.Completed)"
                               (blur)="onUpdateSuiteNumber()"
                               placeholder="{{'tuya-shipment.tuya-stop-details.suiteNumberLabel' | translate}}"
                               maxlength="10"
                               pattern="^[\w, .?@&!#'~*\-;+]*$"/>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #showMode>
        <div class="line-tpl show-mode-tpl">
            <div class="line-address">
                <span class="name" *ngIf="stop.nameOrDescription">{{stop.nameOrDescription + ' - '}}</span>
                <span class="small">{{stop.address?.addressLine}}</span>
            </div>
        </div>
    </ng-template>

    <ng-template #editMode>
        <div class="line-tpl edit-mode-tpl">
            <div class="line-address"
                 tooltip="{{'tuya-shipment.unable-change' | translate}}"
                 placement="top"
                 [isDisabled]="!disabled"
                 containerClass="tuya-tooltip"
                 container="body">
                <tuya-address-autocomplete
                    [selectedAddress]="addressString"
                    [stopId]="stop.id"
                    [selectedStopId]="selectedStop.id"
                    [stopType]="desiredStopType"
                    [stopSequenceNumber]="stop.sequenceNumber"
                    [className]="getTypeName() | translate"
                    [isOptionalStop]="isOptionalStopLine"
                    [isControlDisabled]="(stop.typeId === StopType.Return) || disabled"
                    [stopTypeId]="stop.typeId">
                </tuya-address-autocomplete>
            </div>
            <div>
                <span tooltip="{{'tuya-shipment.unable-change' | translate}}"
                    placement="top"
                    container="body"
                    containerClass="tuya-tooltip"
                    [isDisabled]="isDisabledTooltip()">
                <input type="text"
                       name="suiteNumber"
                       class="form-control suiteNumber"
                       [(ngModel)]="stop.address.suiteNumber"
                       (blur)="onUpdateSuiteNumber()"
                       placeholder="{{'tuya-shipment.tuya-stop-details.suiteNumberLabel' | translate}}"
                       maxlength="10"
                       [disabled]="disabled && (stop.statusId >= StopStatusId.Completed)"
                       pattern="^[\w, .?@&!#'~*\-;+]*$"/>
                </span>
                <button class="btn-link edit" *ngIf="!hasInvalidAddress" [ngClass]="{'highlighted': !isStopsRequiredFieldsProvided}" (click)="onEditStop(stop.id)">Edit Details</button>
            </div>
        </div>
    </ng-template>

    <ng-template #removeReturnStop>
        <div class="modal-content">
            <div class="modal-body text-center">
                <h3 [translate]="'tuya-shipment.tuya-stop-details.modals.remove-return-stop.delete-stop'"></h3>
                <button type="button" class="btn btn-default btn-lg" (click)="modalService.hide(1)"
                        [translate]="'tuya-shipment.tuya-stop-details.modals.remove-return-stop.cancel'"></button>
                <button type="button" class="btn btn-primary btn-lg" (click)="onRemoveStop(stop.id)"
                        [translate]="'tuya-shipment.tuya-stop-details.modals.remove-return-stop.ok'"></button>
            </div>
        </div>
    </ng-template>
</div>
