import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { first, map, Observable } from 'rxjs';
import { IAuthState } from '../model/model';
import { AuthActions } from '../actions/auth.actions';
import { select } from '@angular-redux/store';

@Injectable()
export class LoginGuard implements CanActivate {
    @select(['auth'])
    readonly auth$: Observable<IAuthState>;

    constructor(private authActions: AuthActions) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.auth$.pipe(
            first(),
            map(auth => {
                if (!auth.loggedIn || !auth.currentUser) {
                    return true; // User is not logged in
                } else {
                    this.authActions.homePageRedirect({
                        'shipperProfile': auth.currentUser.shipperProfile,
                        'adminProfile': auth.currentUser.adminProfile,
                        'token': null,
                    });
                    return false; // User is logged in and redirected
                }
            })
        );
    }
}
