import { Component, Input, OnInit } from '@angular/core';
import { OrderExpandedView } from '../../model/my-orders.model';

@Component({
  selector: 'tuya-order-info-header',
  templateUrl: './order-info-header.component.html',
  styleUrls: ['./order-info-header.component.scss']
})
export class OrderInfoHeaderComponent implements OnInit {

  @Input() orderInfo: OrderExpandedView = new OrderExpandedView();
  @Input() isLoading: false;

  constructor() { }

  ngOnInit() {
  }

}
