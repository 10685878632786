<div class="filter-wrapper">

  <div class="header">
    <div class="close-btn" (click)="closeDropdownFilter()"></div>
    <div class="title">Filter Results</div>
  </div>

  <div class="date">
    <div class="title">Date Submitted</div>
    <form [formGroup]="dateForm" class="date-inputs" autocomplete="off">
      <div class="start">
        <p>Start Date</p>
        <input type="text"
                mask="00/00/0000" placeholder="MM/DD/YYYY"
                formControlName="startDate"
                id="startDate"
                placeholder="MM/DD/YYYY"
                (focusout)="onFocusOut(true)"/>
        <div *ngIf="dateForm.controls['startDate'].invalid"
              tooltip="{{ startDateTooltip }}"
              placement="bottom"
              container="body"
              containerClass="tuya-search-tooltip-lf"
              class="error-msg">
          Invalid date
        </div>
      </div>
      <div class="start">
        <p>End Date</p>
        <input type="text"
                mask="00/00/0000" placeholder="MM/DD/YYYY"
                formControlName="endDate"
                id="endDate"
                placeholder="MM/DD/YYYY"
                (focusout)="onFocusOut(true)"/>
        <div *ngIf="dateForm.controls['endDate'].invalid"
              tooltip="{{ endDateTooltip }}"
              placement="bottom"
              container="body"
              containerClass="tuya-search-tooltip"
              class="error-msg">
          Invalid date
        </div>
      </div>
    </form>
  </div>

  <div class="status">
    <div class="title">Status</div>

    <label class="container">Draft
      <input type="checkbox" [checked]="false"  [(ngModel)]="draft">
      <span class="checkmark"></span>
    </label>

    <label class="container">Active (Offered, Assigned, In Progress)
      <input type="checkbox" [checked]="false" [(ngModel)]="active">
      <span class="checkmark"></span>
    </label>

    <label class="container">Completed
      <input type="checkbox" [checked]="true" [(ngModel)]="completed">
      <span class="checkmark"></span>
    </label>
  </div>

  <div class="reset">
    <a type="button" (click)="onResetFilter()"> Reset Filter</a>
  </div>

  <div class="buttons">
      <button type="button" class="cancel" (click)="closeDropdownFilter()">Cancel</button>
      <button type="button" class="apply" (click)="applySearch()"
              [disabled] = "(dateForm.controls['startDate'].invalid && (dateForm.controls['startDate'].dirty || dateForm.controls['startDate'].touched)) ||
              (dateForm.controls['endDate'].invalid && (dateForm.controls['endDate'].dirty || dateForm.controls['endDate'].touched))">
              Apply
      </button>
  </div>
  
</div>
