import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { switchMap, catchError, filter, tap } from 'rxjs/operators';
import { ShipperProfileResultDto } from '../../core/models/dto';
import { IAppState } from '../../store/model';
import { ShipperActions, LOAD_SHIPPER_PROFILE } from '../actions/shipper.status.actions';
import { ShipperService } from '../../core/services/shipper.service';
import { AuthActions } from '../../auth/actions/auth.actions';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../../core/constants/storage.constants';
import { transformShipperProfile } from '../utils/shipper-profile.utils';
import { ShipperAdminActions } from 'app/admin/actions/shipper.admin.actions';
import { ofType, combineEpics } from 'redux-observable';
import { Action } from 'redux';
import { MapActions } from 'app/core/actions/map.actions';

@Injectable()
export class ShipperEpics {
    private isLoading = false;

    constructor(
        private shipperService: ShipperService,
        private actions: ShipperActions,
        private authActions: AuthActions,
        private shipperAdminActions: ShipperAdminActions,
        private alertActions: AlertActions,
        private mapActions: MapActions
    ) {}

    public createEpic() {
        return combineEpics(this.loadShipperProfileEpic());
    }

    private loadShipperProfileEpic() {
        return (action$) =>
            action$.pipe(
                ofType(LOAD_SHIPPER_PROFILE),
                filter(() => !this.isLoading), // Prevent multiple calls
                tap(() => { this.isLoading = true; }), // Set loading flag
                switchMap((action: any) =>
                    this.shipperService.get(action.payload).pipe(
                        switchMap((data: ShipperProfileResultDto) => this.handleShipperProfile(data)),
                        catchError((error) => this.handleError(error)),
                        tap(() => { this.isLoading = false; }) // Reset loading flag
                    )
                )
            );
    }

    private handleShipperProfile(data: ShipperProfileResultDto): Observable<Action> {
        const shipperProfile = transformShipperProfile(data);
        const st: IAppState = JSON.parse(sessionStorage.getItem('state') || '{}');
        const token = {
            access_token: localStorage.getItem(ACCESS_TOKEN_KEY),
            refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY),
        };

        if (shipperProfile?.isAdmin) return this.handleAdminUser(shipperProfile, st, token);

        return this.handleRegularUser(shipperProfile, st, token);
    }

    private handleAdminUser(shipperProfile: any, state: IAppState, token: any): Observable<Action> {
        if (state.auth?.loggedIn) {
            if (['/admin-search', '/driver-portal'].includes(state.router)) {
                return of(this.authActions.updateAdminProfileToState(shipperProfile));
            } else {
                return of(
                    this.shipperAdminActions.loadShipperAndCompanyProfilesAsAdmin(
                        state.admin.shipperAdmin.selectedShipper.userAccountId
                    )
                );
            }
        } else {
            return of(this.authActions.loginSucceeded({
                complexToken: token,
                shipperProfile: null,
                adminProfile: shipperProfile,
            }));
        }
    }

    private handleRegularUser(shipperProfile: any, state: IAppState, token: any): Observable<Action> {
        if (state.auth?.loggedIn) {
            return of(
                this.authActions.updateCurrentUser({ complexToken: token, shipperProfile, adminProfile: null }),
                this.authActions.updateShipperProfileToState(shipperProfile),
                this.actions.loadProfileSucceeded(shipperProfile),
                this.mapActions.setCenter({
                    lat: shipperProfile.businessEntity.physicalAddress.latitude,
                    lng: shipperProfile.businessEntity.physicalAddress.longitude,
                })
            );
        }

        return of(
            this.authActions.loginSucceeded({ complexToken: token, shipperProfile, adminProfile: null }),
            this.actions.loadProfileSucceeded(shipperProfile),
            this.mapActions.setCenter({
                lat: shipperProfile.businessEntity.physicalAddress.latitude,
                lng: shipperProfile.businessEntity.physicalAddress.longitude,
            })
        );
    }

    private handleError(error: any): Observable<Action> {
        console.error('Error loading shipper profile:', error);
        this.isLoading = false;
        return of(
            this.actions.loadProfileFailed({ status: '' + error.status }),
            this.alertActions.show({ error, type: AlertType.BadRequest }),
            this.authActions.logout()
        );
    }
}
