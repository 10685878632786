<div class="recurring-wrapper">

    <tuya-spinner size="fullwindow" *ngIf="(isLoading$ | async)"></tuya-spinner>

    <div class="header-button">
        <button mat-icon-button (click)="onCloseModal(false)">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="container">

        <form [formGroup]="recurringForm" autocomplete="off" (ngSubmit)="submit()">

            <div class="headerr">
                <div class="title">
                    <span *ngIf="!this.modalData?.id">Set this Order as a Recurring Order</span>
                    <span *ngIf="this.modalData.id">Edit this Recurring Order</span>
                </div>
                <div class="subtitle">
                    How often do you want this order to recur?
                </div>
            </div>

            <div class="toggle-buttons">
                <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="recurringPeriod">
                    <mat-button-toggle value="0">
                        <mat-icon *ngIf="recurringForm.get('recurringPeriod').value === '0'">done</mat-icon>&nbsp;Weekly
                    </mat-button-toggle>
                    <mat-button-toggle value="1">
                        <mat-icon
                            *ngIf="recurringForm.get('recurringPeriod').value === '1'">done</mat-icon>&nbsp;Monthly
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <!-- WEEKLY -->
            <div>
                <div class="week-selection" *ngIf="recurringForm.get('recurringPeriod').value === '0'"
                    [formGroup]="recurringForm.get('weekOccurence')">
                    <div class="week">
                        Order should repeat every
                        <mat-form-field class="repeat-day">
                            <mat-label>Week(s) 1 - 5</mat-label>
                            <input matInput type="number" placeholder="Weeks" formControlName="periodRepeatanceWeek"
                                min="1" max="5" aria-label="Repeat every number of weeks">
                        </mat-form-field>
                        week(s) on the following days
                    </div>
                    <div class="days">
                        <mat-checkbox class="day" *ngFor="let day of days.value; let i=index" [formControl]="day"
                            (change)="onWeekDayChange()">
                            {{ weekDays[i].name }}
                        </mat-checkbox>
                    </div>
                    <div class="seperator"></div>
                    <div class="date">
                        <div class="title">
                            Select the dates you would like this order to occur?
                        </div>

                        <div class="calendar-container">
                            <div class="left">
                                <label>Starts From</label>
                                <tuya-recurring-date-pick
                                    [selectedDate]="recurringForm.get('weekOccurence')['controls'].startWeek.value"
                                    [minDate]="minDateWS$ | async"
                                    (onDateChange)="onDateChange($event, 'week', 'start')"
                                    class="from-date"></tuya-recurring-date-pick>
                            </div>
                            <div class="right">
                                <mat-radio-group formControlName="endDateTypeWeek" class="end-selections">
                                    <div class="endsBy"
                                        [ngClass]="endActiveDateWeekly === 0 ? 'activeColor' : 'defaultColor'">
                                        <mat-radio-button class="end-date" value="0">
                                            Ends by &nbsp;</mat-radio-button>
                                        <tuya-recurring-date-pick [selectedDate]="minDateWED$ | async"
                                            [minDate]="minDateWE$ | async"
                                            [isActive]="endActiveDateWeekly === 0 ? true : false"
                                            (onDateChange)="onDateChange($event, 'week', 'end')"
                                            class="from-date"></tuya-recurring-date-pick>
                                    </div>

                                    <div class="endsAfter"
                                        [ngClass]="endActiveDateWeekly === 1 ? 'activeColor' : 'defaultColor'">
                                        <mat-radio-button class="end-day" value="1">
                                            Ends After &nbsp;</mat-radio-button>
                                        <mat-form-field class="repeat-day">
                                            <input matInput formControlName="occurencesWeek" type="number" min="1"
                                                max="999" placeholder="#">
                                        </mat-form-field>
                                        &nbsp;Occurence(s)
                                    </div>
                                    <div class="cont-last"
                                        [ngClass]="endActiveDateWeekly === 2 ? 'activeColor' : 'defaultColor'">
                                        <mat-radio-button class="infinite" value="2">
                                            No End Date</mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- MONTHLY -->
            <div>
                <div class="month-selection" *ngIf="recurringForm.get('recurringPeriod').value === '1'"
                    [formGroup]="recurringForm.get('monthOccurence')">

                    <mat-radio-group formControlName="monthlyRepeatTypeMonth" class="end-selections">
                        <div class="day" [ngClass]="repeatTypeMonth === 0 ? 'activeColor' : 'defaultColor'">
                            <span class="txt default-color">Order should repeat every</span>
                            <mat-radio-button class="end-day" type="number" value="0">Day&nbsp;</mat-radio-button>
                            <mat-form-field class="repeat-day">
                                <input matInput formControlName="dateFrequencyMonth" type="number" min="1" max="31"
                                    placeholder="MM">
                            </mat-form-field>
                            <span class="default-color">of every</span>
                            <mat-form-field class="repeat-day">
                                <input matInput formControlName="periodRepeatanceMonthA" type="number" min="1" max="99"
                                    placeholder="MM">
                            </mat-form-field>
                            <span class="default-color">Month(s)</span>
                        </div>

                        <div class="the" [ngClass]="repeatTypeMonth === 1 ? 'activeColor' : 'defaultColor'">
                            <mat-radio-button class="end-day" value="1">The&nbsp;</mat-radio-button>

                            <mat-form-field appearance="outline" class="week-selection">
                                <mat-select formControlName="dayOfWeekRepeatanceMonth"
                                    [ngClass]="repeatTypeMonth === 1 ? 'activeColor' : 'defaultColor'">
                                    <mat-option value="1">First</mat-option>
                                    <mat-option value="2">Second</mat-option>
                                    <mat-option value="3">Third</mat-option>
                                    <mat-option value="4">Fourth</mat-option>
                                    <mat-option value="5">Last</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="month-selection">
                                <mat-select formControlName="dayFrequencyMonth">
                                    <mat-option *ngFor="let d of weekDays" [value]="d.id.toString()">
                                        {{d.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>&nbsp;
                            <span class="default-color">of every</span>
                            <mat-form-field class="repeat-day">
                                <input matInput formControlName="periodRepeatanceMonthB" type="number" min="1" max="99"
                                    placeholder="MM">
                            </mat-form-field>
                            <span class="default-color">month(s)</span>
                        </div>
                    </mat-radio-group>

                    <div class="seperator"></div>

                    <!-- Start And End date selections  -->
                    <div class="date">
                        <div class="title">
                            Select the range of dates you would like this order to occur?
                        </div>
                        <div class="calendar-container">
                            <div class="left">
                                <label>Starts From</label>
                                <tuya-recurring-date-pick
                                    [selectedDate]="recurringForm.get('monthOccurence')['controls'].startMonth.value"
                                    [minDate]="minDateMS$ | async"
                                    (onDateChange)="onDateChange($event, 'month', 'start')"
                                    class="from-date"></tuya-recurring-date-pick>
                            </div>
                            <div class="right">
                                <mat-radio-group formControlName="endDateTypeMonth" class="end-selections">
                                    <div class="endsBy"
                                        [ngClass]="endActiveDateMonthly === 0 ? 'activeColor' : 'defaultColor'">
                                        <mat-radio-button class="end-date" value="0">
                                            Ends by &nbsp;</mat-radio-button>
                                        <tuya-recurring-date-pick [selectedDate]="minDateMED$ | async"
                                            [minDate]="minDateME$ | async"
                                            [isActive]="endActiveDateMonthly === 0 ? true : false"
                                            (onDateChange)="onDateChange($event, 'month', 'end')"
                                            class="from-date"></tuya-recurring-date-pick>
                                    </div>
                                    <div class="endsAfter"
                                        [ngClass]="endActiveDateMonthly === 1 ? 'activeColor' : 'defaultColor'">
                                        <mat-radio-button class="end-day" value="1">
                                            Ends After &nbsp;</mat-radio-button>
                                        <mat-form-field class="repeat-day">
                                            <input matInput formControlName="occurencesMonth" type="number" min="1"
                                                max="999" placeholder="#">
                                        </mat-form-field>
                                        &nbsp;Occurence(s)
                                    </div>
                                    <div class="cont-last"
                                        [ngClass]="endActiveDateMonthly === 2 ? 'activeColor' : 'defaultColor'">
                                        <mat-radio-button class="infinite" value="2">
                                            No End Date</mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="bottom-buttons">
                <button mat-button type="button" class="action__delete" *ngIf="modalData.id"
                    (click)="deleteRecurrence()">Delete Recurrence</button>
                <button mat-button type="button" class="action__cancel" (click)="onCloseModal(false)">Cancel</button>
                <button mat-button type="submit" class="action__save" [disabled]="!recurringForm.valid">Save</button>
            </div>
        </form>
    </div>
</div>