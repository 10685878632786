<div class="btn-group" (tuyaOffClick)="onOffClick()" #dropdown="bs-dropdown"
     (onShown)="onShowPanel()"
     (onHidden)="onHidePanel()"
     [isOpen]="isOpen" [autoClose]="false" dropdown>
    <button dropdownToggle type="button" class="notifications"></button>
    <div *dropdownMenu class="dropdown-menu notifications-menu">
        <div class="notification-panel">
            <div class="header">
                {{'tuya-header.notifications' | translate}}
                <button class="btn-reset btn-link" (click)="onRefreshNotifications()"><i class="fa fa-repeat" aria-hidden="true"></i>
                </button>
                <div class="arrow"></div>
            </div>
            <div class="ps-content-container" (scroll)="onScroll()" [ngClass]="{ 'flex': !notificationList.length }">
                <ng-container *ngIf="errorMessageKey; then renderError; else renderNotificationList"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #renderNotificationList>
    <ng-container *ngIf="!notificationList.length && !isLoading; then renderEmpty; else renderList"></ng-container>
</ng-template>

<ng-template #renderEmpty>
    <div *ngIf="!isLoading && !notificationList.length" class="list-empty">
        <div [translate]="'tuya-header.no-notifications'"></div>
    </div>
</ng-template>

<ng-template #renderList>
    <ul>
        <li class="item" *ngFor="let item of notificationList"
            [ngClass]="{
                'item-success': item.highlightingTypeId === HighlightType.Success,
                'item-warning': item.highlightingTypeId === HighlightType.Warning,
                'item-danger': item.highlightingTypeId === HighlightType.Danger,
                'item-primary': item.highlightingTypeId === HighlightType.Primary,
                'item-recurring': item.highlightingTypeId === HighlightType.Recurring,
                'unread': true}">
            <a (click)="goToOrderDetails(item.payload)">
                <div class="title-body"><span class="title"><span class="icon"></span> {{item.title}}</span><span class="date">{{item.creationDate | date: 'M/dd/yyyy, h:mm a'}}</span></div>
                <div class="message-body" [innerHtml]="item.template | formatNotificationTemplate : item.payload"></div>
            </a>
        </li>
        <li *ngIf="isLoading" class="spinner-container">
            <tuya-spinner size="fullcontained"></tuya-spinner>
        </li>
    </ul>
</ng-template>

<ng-template #renderError>
    <div class="list-error">
        <div [translate]="'tuya-header.' + errorMessageKey"></div>
    </div>
</ng-template>