import { BaseService } from '../../core/services/base.service';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../app.config.service';
import { HttpClient } from '@angular/common/http';
import { GET_PAYMENT_METHODS_URL, API_TPRO3_AUTH, API_PAYMENT_ADD_CREDIT_CARD } from '../../core/constants/api.constants';
import { Observable } from 'rxjs';
import { T3PROAuth, CreditCardDto } from '../../core/models/payment.dto';

@Injectable()
export class BillingService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    getPaymentMethods() {
        return this.api().GET(GET_PAYMENT_METHODS_URL);
    }

    getAuth(): Observable<T3PROAuth> {
        return this.api().POST(API_TPRO3_AUTH);
    }

    addCreditCard(creditCard: CreditCardDto): Observable<any> {
        return this.api().POST(API_PAYMENT_ADD_CREDIT_CARD, creditCard);
    }
}
