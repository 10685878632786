import { CompanyValidationResultVO, ProfileValidationResultVO } from './../../register/model/model';
import { ManageCompanyTab } from './../model/model';
import { PasswordValidationVO } from './../../auth/model/model';
import { IndustrySegment, ShipperProfileDto, BusinessEntityDto } from './../../core/models/dto';
import { ProfileToEditVO } from '../../register/model/model';
import { IAppState } from '../../store/model';
import { StateDto } from '../../core/models/dto';

export class ProfilesSelectors {
    public static profileToEditSelector = (state: IAppState): ProfileToEditVO => state.profiles.profileToEdit;
    public static changePasswordValidationSelector = (state: IAppState): PasswordValidationVO => state.auth.changePasswordValidationResult;
    public static statesSelector = (state: IAppState): StateDto[] => state.core.states;
    public static industrySegmentsSelector = (state: IAppState): IndustrySegment[] => state.register.industrySegments;
    public static companyInfoToEditSelector = (state: IAppState): BusinessEntityDto => state.profiles.companyInfoToEdit;
    public static currentManageCompanyTabSelector = (state: IAppState): ManageCompanyTab => state.profiles.currentManageCompanyTab;
    public static companyValidateSelector = (state: IAppState): CompanyValidationResultVO => state.profiles.companyInfoFormValidationResult;
    public static profileValidateSelector = (state: IAppState): ProfileValidationResultVO => state.profiles.profileInfoFormValidationResult;
    public static isLoading = (state: IAppState): boolean => state.profiles.isLoading;
}
