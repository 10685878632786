import { CancelModalComponent } from './../../profiles/components/cancel-modal/cancel-modal.component';
import { ProfilePageComponent } from './../../profiles/containers/profile-page/profile-page.component';
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CanDeactivate } from '@angular/router';
import { first, map, Observable, Subject } from 'rxjs';

@Injectable()
export class DeactivateProfileGuard implements CanDeactivate<ProfilePageComponent> {

  constructor(private modalService: BsModalService) { }

  canDeactivate(component: ProfilePageComponent): Observable<boolean> | boolean {
    if (component.shipperForm.form.dirty && component.isModelChanged()) {

      const subject = new Subject<boolean>();

      const modal = this.modalService.show(CancelModalComponent);
      modal.content.subject = subject;

      return subject.pipe(
        map(res => {
          if (res) component.discardProfilesChanges();

          return res;
        }),
        first()
      );
    }
    return true;
  }
}
