<div class="details-section">
    <form name="form"
          #form="ngForm"
          novalidate>
        <div class="form-group"
             [ngClass]="{ 'has-error':nameOrDescription.dirty &&
        (!nameOrDescription.valid  || nameOrDescription.errors?.pattern || isOnlySpaces)}"
             tooltip="{{'tuya-shipment.unable-change' | translate}}"
             placement="top"
             containerClass="tuya-tooltip"
             container="body"
             [isDisabled]="!disabled">
            <input name="nameOrDescription"
                   autocomplete="off"
                   #nameOrDescription="ngModel"
                   class="form-control"
                   [ngClass]="{ 'filled': stop.nameOrDescription }"
                   [disabled]="disabled"
                   type="text"
                   [(ngModel)]="stop.nameOrDescription"
                   (ngModelChange)="onValidate()"
                   maxlength="75"
                   [pattern]="namePattern"
                   trim="blur"
                   required/>
            <span class="reminder">{{'tuya-shipment.tuya-stop-details.stopReminder' | translate}}</span>
            <div *ngIf="nameOrDescription.dirty && !nameOrDescription.valid && !nameOrDescription.errors?.pattern"
                 class="help-block"
                 [translate]="'tuya-shipment.tuya-stop-details.error.nameOrDescription'"></div>
            <div *ngIf="nameOrDescription.dirty && (!!nameOrDescription.errors?.pattern || isOnlySpaces)"
                 class="help-block"
                 [translate]="'tuya-shipment.tuya-stop-details.error.name-pattern-error'"></div>
        </div>
    </form>
</div>
