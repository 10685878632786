export class ShipperAssessment {
    orderId: any;
    rate: number;
    reasonId?: number;
    isRateUpdated?: boolean;
}

/* COMMON DRIVER SCORE */
export class DriverScore {
    value: number;
}

/* RATE THAT SHIPPER COULD PUT */
export class DriverRate {
    id: number;
    value: string;
}

export class CanceledOrderInfo {
    orderId: any;
    orderStatusId: number;
    driverName: string;
    firstCopmletedStopName: string;
}
