  <a (click)="isCollapsed = !isCollapsed" class="btn-link add-info-expanded" [ngClass]="{expanded: !isCollapsed}">
    <span [translate]="'tuya-delivery-stop.tuya-item-selection.add-return'"></span>
  </a>
  <div [collapse]="isCollapsed">
    <p>{{'tuya-delivery-stop.tuya-item-selection.is-there-return' | translate}}</p>
    <form name="form" #form="ngForm" novalidate>
      <div class="return-items-picker">
        <div class="hint">{{'tuya-delivery-stop.tuya-item-selection.number-of-pieces' | translate }}</div>
        <div class="input-group">
          <div class="input-group-btn"
               tooltip="{{'tuya-shipment.unable-change' | translate}}"
               placement="top"
               containerClass="tuya-tooltip"
               [isDisabled]="!disabled"
               container="body">
            <button type="button" class="btn btn-outline-secondary plus" [disabled]="disabled" (click)="onIncrementReturnItems()">
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
            <button type="button" class="btn btn-outline-secondary" [disabled]="disabled" (click)="onDecrementReturnItems()">
              <i class="fa fa-minus" aria-hidden="true"></i>
            </button>
          </div>
          <div class="input-group-status">{{ returnItem.piecesCount }}</div>
        </div>
      </div>
    </form>
  </div>
