import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { PaymentService } from '../../core/services/payment.service';
import { CREDIT_CARD_ADD, HAS_VALID_CREDIT_CARD, PaymentActions } from '../actions/payment.actions';
import { CreditCardDto } from '../../core/models/payment.dto';
import { BillingActions } from '../../profiles/actions/billing.actions';
import { concatMap, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class PaymentsEpics {
    constructor(private paymentService: PaymentService,
                private paymentActions: PaymentActions, private billingActions: BillingActions) {
    }

    public createEpic() {
        return combineEpics(
            createNewCreditCardEpic(this.paymentService, this.paymentActions, this.billingActions),
            createHasValidCCEpic(this.paymentService, this.paymentActions)
        );
    }
}

export function createNewCreditCardEpic(paymentService: PaymentService,
                                        paymentActions: PaymentActions, billingActions) {
    return action$ => action$.pipe(
        ofType(CREDIT_CARD_ADD),
        concatMap((action: any) => {
            const creditCard: CreditCardDto = action.payload;
            return paymentService.addCreditCard(creditCard).pipe(
                mergeMap(data => of(paymentActions.addCreditCardSucceeded(data), billingActions.getPaymentMethods())));
        })
    );
        // @TODO ADD ERROR HANDLER
}

export function createHasValidCCEpic(paymentService: PaymentService, paymentActions: PaymentActions) {
    return action$ => action$.pipe(
        ofType(HAS_VALID_CREDIT_CARD),
        concatMap(action => {
            return paymentService.hasValidCreditCard().pipe(
                map(data => paymentActions.hasValidCreditCardSucceeded(data)));
        })
    );
        // @TODO ADD ERROR HANDLER
}


