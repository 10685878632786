import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';
import { GET_ALL_ORDER_EVENTS, OrderEventsActions } from '../actions/order-events.action';
import { OrderEventsService } from '../services/order-events.service';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class OrderEventsEpics {
    constructor(private orderEventsActions: OrderEventsActions,
                private orderEventsService: OrderEventsService,
                private alertActions: AlertActions) {
    }

    public createEpic() {
        return combineEpics(
            createOrderAllEventsLoadEpic(this.orderEventsActions, this.orderEventsService, this.alertActions)
        );
    }
}

export function createOrderAllEventsLoadEpic(actions: OrderEventsActions,
                                   service: OrderEventsService, alertActions: AlertActions) {
    return action$ => action$.pipe(
        ofType(GET_ALL_ORDER_EVENTS),
        switchMap((action: any) => {
            return service.getAllEvents(action.payload).pipe(
                map(data => actions.getAllOrderEventsSucceeded(data))
                ,catchError(error => {
                    console.log('error');
                    return of(actions.getAllOrderEventsFailed({
                        errorMessageKey: 'error1',
                    }), alertActions.show({error: error, type: AlertType.BadRequest}));
                }));
        })
    );
}





