import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';

const ngMatHome = [
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatIconModule,
    MatButtonToggleModule
];

@NgModule({
  imports: [
    ngMatHome
  ],
  exports: [
    ngMatHome
  ],
  providers: [
    MatDatepickerModule
  ]
})
export class NgmatBuilderModule {}
