import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { first, Observable } from 'rxjs';
import { select } from '@angular-redux/store';
import { IAuthState, UserProfile, InitFlagType } from '../model/model';
import { AuthActions } from '../actions/auth.actions';
import { ShipperActions } from '../../shipper/actions/shipper.status.actions';
import { get } from 'lodash-es';

@Injectable()
export class AuthGuard implements CanActivate {
    @select(['auth'])
    readonly auth$: Observable<IAuthState>;
    @select(['auth', 'currentUser'])
    readonly currentUser$: Observable<UserProfile>;
    @select(['auth', 'initRefreshFlag'])
    readonly initRefreshFlag$: Observable<number>;

    constructor(private authActions: AuthActions,
        private shipperActions: ShipperActions) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const mustBeAdmin = !!get(route, 'data.mustBeAdmin');
        return new Observable<boolean>(observer => {
            this.auth$.pipe(first())
                .subscribe(auth => {
                    if (!auth.loggedIn) {
                        // @TODO save preauth url
                        this.authActions.loginRedirect();
                        observer.next(false);
                    } else {
                        if (auth.initRefreshFlag !== InitFlagType.Done) {
                            this.authActions.createAccessToken();
                            this.initRefreshFlag$.subscribe(data => {
                                if (data === InitFlagType.Done) {
                                    if (!auth.currentUser) {
                                        this.shipperActions.loadProfile();
                                        this.currentUser$.subscribe(success => {
                                            if (success) {
                                                if (mustBeAdmin) {
                                                    const isAdmin = !!get(success, 'adminProfile.isAdmin');
                                                    observer.next(isAdmin);
                                                } else {
                                                    observer.next(true);
                                                }
                                            }
                                        });
                                    } else {
                                        if (mustBeAdmin) {
                                            this.currentUser$.subscribe(user => {
                                                const isAdmin = !!get(user, 'adminProfile.isAdmin');
                                                observer.next(isAdmin);
                                            });
                                        } else {
                                            observer.next(true);
                                        }
                                    }
                                } else if (data === InitFlagType.Reset) {
                                    observer.next(false);
                                }
                            });
                        } else {
                            if (!auth.currentUser) {
                                this.shipperActions.loadProfile();
                                this.currentUser$.subscribe(success => {
                                    if (mustBeAdmin) {
                                        const isAdmin = !!get(success, 'adminProfile.isAdmin');
                                        observer.next(isAdmin);
                                    } else {
                                        observer.next(true);
                                    }
                                });
                            } else {
                                if (mustBeAdmin) {
                                    this.currentUser$.subscribe(user => {
                                        const isAdmin = !!get(user, 'adminProfile.isAdmin');
                                        observer.next(isAdmin);
                                    });
                                } else {
                                    observer.next(true);
                                }
                            }
                        }
                    }
                });
        });
    }
}