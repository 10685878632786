import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ConfigService } from '../../app.config.service';
import { API_ORDER, OrdersService } from '../../core/services/orders.service';
import { EventItem } from '../model/order-events.model';

const API_EVENT_LOG = '/EventLog2';


@Injectable()
export class OrderEventsService extends OrdersService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    getAllEvents(orderId: any): Observable<Array<EventItem>> {
        return this.api().GET(API_ORDER + orderId + API_EVENT_LOG).pipe(map((data: any) => data));
    }

}
