<div class="summary-wrapper">
  <button class="summary-item-container" [ngClass]="{'expanded': expanded}" (click)="toggleExpanded()">
    <div class="summary-arrow-container">
      <div class="summary-arrow"></div>
    </div>
    <ng-container *ngTemplateOutlet="stopEvents"></ng-container>
  </button>
  <ng-container *ngIf="expanded; then stopEventDetails"></ng-container>
</div>

<!-- ------------------------- expanded details ---------------------------- -->
<ng-template #stopEventDetails>
  <div class="stop-details-container">
    <div class="stop-details">
      <div class="details-label">{{ 'tuya-my-orders.stop-completed-arrival-text.item-description-label' | translate }}
      </div>

      <!-- if there is a description, display the description, else, translate 'no content submitted' -->
      <div class="details-content"><span *ngIf="!!stopItem.itemsDescription; else noContent">{{
          stopItem.itemsDescription }}</span></div>
      <div class="details-label">{{ 'tuya-my-orders.stop-completed-arrival-text.items-label-pickup' | translate }}</div>
      <div class="details-content">
        <span class="content-label">{{ 'tuya-my-orders.stop-completed-arrival-text.total-quantity-label' | translate
          }}</span>
        <span class="content">{{ getTotalQuantity() }}</span>
        <span class="content-label"><span class="content">&nbsp;|</span>{{
          'tuya-my-orders.stop-completed-arrival-text.total-weight-label' | translate }}</span>
        <span class="content">{{ getWeightCopy(itemSize) }}</span>
      </div>
      <div class="details-content">
        <tuya-item-list [itemSizes]="stopItem.items"></tuya-item-list>
      </div>
    </div>

    <!-- stop contact info -->
    <div class="details-label">{{ 'tuya-my-orders.order-summary-text.contact-info-label' | translate }}</div>
    <div class="details-content">
      <span class="content-label">{{ 'tuya-my-orders.order-summary-text.contact-name-label' | translate }}</span>
      <span class="content">{{ stopItem.contactName }}</span>
      <span class="content-label" *ngIf="!!stopItem.contactEmail">&nbsp;<span class="content">|</span>{{
        'tuya-my-orders.order-summary-text.contact-email-label' | translate }}</span>
      <span class="content" *ngIf="!!stopItem.contactEmail">{{ stopItem.contactEmail }}</span>
      <span class="content-label" *ngIf="!!stopItem.contactPhoneNumber">&nbsp;<span class="content">|</span>{{
        'tuya-my-orders.order-summary-text.contact-phone-label' | translate }}</span>
      <span class="content" *ngIf="!!stopItem.contactPhoneNumber">{{ stopItem.contactPhoneNumber }}</span>
    </div>

    <!-- signature required and email tracking boxes -->
    <div class="sig-required-box">
      <div [ngClass]="{ check: stopItem.isSignitureRequired, ex: !stopItem.isSignitureRequired}"></div>
      <div class="sig-email-box-text">{{ 'tuya-my-orders.order-summary-text.signature-label' | translate }}</div>
    </div>
    <div class="email-link-box">
      <div [ngClass]="{ check: stopItem.hasTrackingLink, ex: !stopItem.hasTrackingLink}"></div>
      <div class="sig-email-box-text">{{ 'tuya-my-orders.order-summary-text.tracking-link-label' | translate }}</div>
    </div>
  </div>
</ng-template>

<!-- ------------------------- header summary ---------------------------- -->
<ng-template #stopEvents>
  <div class="stops-summary summary-icon" [ngClass]="getClasses()">
    <div class="summary-title">
      <div class="title-left">
        <!-- left side -->
        <div class="title-name">{{ stopItem.name }}</div>
      </div>
      <div class="title-right">
        <!-- right side -->
        <div class="status-label">{{ 'tuya-my-orders.common-summary-text.status' | translate }}: </div>
        <!-- stop status -->
        <span class="status-label" *ngIf="stopItem.statusId === stopStatus.New">{{
          'tuya-my-orders.stop-event-status.new' | translate }}</span>
        <!-- <span class="status-label" *ngIf="stopItem.statusId === stopStatus.Completed">{{ 'tuya-my-orders.stop-event-status.completed' | translate }}</span> -->
        <span class="status-label completed" *ngIf="stopItem.statusId === stopStatus.Completed">
          <img src="../../../../assets/img/stops-list/checkmark_circle.svg" alt="Completed" />
          {{ 'tuya-my-orders.stop-event-status.completed' | translate }}
        </span>
        <span class="status-label" *ngIf="stopItem.statusId === stopStatus.CompletedWithException">{{
          'tuya-my-orders.stop-event-status.completed' | translate }}</span>
        <span class="status-label" *ngIf="stopItem.statusId === stopStatus.DriverArrived">{{
          'tuya-my-orders.stop-event-status.driver-arrived' | translate }}</span>
        <span class="status-label" *ngIf="stopItem.statusId === stopStatus.DeliveryAttempt">{{
          'tuya-my-orders.stop-event-status.attempted' | translate }}</span>
        <span class="status-label" *ngIf="stopItem.statusId === stopStatus.ArrivalAttempt">{{
          'tuya-my-orders.stop-event-status.attempted' | translate }}</span>
      </div>
    </div>
    <div class="summary-details-row">
      <div class="summary-info">
        <div class="summary-ref-id">
          <span>{{ 'tuya-my-orders.common-summary-text.stop-ref-id' | translate }}:&nbsp;</span>
          <span *ngIf="stopItem.referenceId">{{ stopItem.referenceId }}</span>
          <span *ngIf="!stopItem.referenceId" class="no-content">{{ 'tuya-my-orders.common-event-text.no-content' |
            translate }}</span>
        </div>
        <div class="summary-address">
          <div class="addr-item">
            <span>{{ stopItem.address.addressLine }}</span>
            <!-- <span *ngIf="stopItem.address.suiteNumber">,</span>
            <span *ngIf="stopItem.address.suiteNumber">
              {{ '#' + stopItem.address.suiteNumber }}
            </span> -->
          </div>
          <!-- JAY Modified -->
          <!--<div class="addr-item">{{ stopItem.address.city + ", " + stopItem.address.stateAbbr + ", " }}</div>-->
          <!--<div class="addr-item">{{ stopItem.address.postalCode }}</div>-->
        </div>
        <div class="summary-time">
          <div class="time">
            <span class="time-item-label">{{ 'tuya-my-orders.common-summary-text.scheduled-arrival-label' | translate
              }}:&nbsp;</span>
            <span class="time-item">{{ getDateRange() }}</span>
          </div>
          <div class="time time-actual" *ngIf="stopItem.statusId === stopStatus.Completed">
            <span class="time-item-label">{{ 'tuya-my-orders.common-summary-text.actual-arrival-label' | translate
              }}:&nbsp;</span>
            <span class="time-item">{{stopItem.completedTimeStamp | date: 'MM/dd/yyyy, h:mm a'}}</span>
          </div>
        </div>
      </div>
      <div class="summary-admin-btn" *ngIf="adminUser?.isAdmin && stopItem.statusId !== stopStatus.Completed">
        <button class="admin-button" [disabled]="adminCompleteStopDisabled()"
          (click)="onAdminCompleteStop($event, adminModal)">
          {{ 'tuya-my-orders.common-summary-text.admin-complete-btn' | translate }}
        </button>
      </div>
      <div class="summary-admin-btn summary-admin-undo-btn"
        *ngIf="adminUser?.isAdmin && stopItem.statusId === stopStatus.Completed">
        <button class="admin-button"
          tooltip="{{ (order.stopList[order.stopList.length - 1].stopId === stopItem.stopId ? firstStopUndoText : lastStopUndoText) | translate}}"
          containerClass="tuya-white-tooltip undo" placement="bottom" container="body"
          [disabled]="stopDisabledList[stopItem.stopId]" (click)="onUndoStopCompletion($event, undoAdminModal)">
          {{ 'tuya-my-orders.common-summary-text.admin-undo-complete-btn' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="isLoading$ | async">
    <tuya-spinner size="fullwindow" clear="true"></tuya-spinner>
  </div>

</ng-template>

<ng-template #undoAdminModal>
  <div class="undo-modal">
    <div class="modal-text">
      Are you sure you want to <span class="undo">"Undo Complete"</span> this stop?
    </div>
    <div class="moda-btns">
      <button class="cancel" (click)="failureUndoCallback()">Cancel</button>
      <button class="ok" (click)="successUndoCallback(stopItem.stopId)">OK</button>
    </div>
  </div>
</ng-template>

<ng-template #adminModal>
  <div *ngIf="isLoading$ | async">
    <tuya-spinner size="fullwindow" clear="true"></tuya-spinner>
  </div>
  <div class="modal-container" [ngClass]="{loading: (isLoading$ | async)}">
    <h3>{{ 'tuya-my-orders.admin-modal.title' | translate }}</h3>
    <div class="modal-copy"
      [innerHtml]="'tuya-my-orders.admin-modal.instructions' | translate: {location: stopItem.name, driver: order.assignments?.drivers[0]?.name }">
    </div>
    <form [formGroup]="adminForm" (ngSubmit)="onAdminSubmit()">
      <div class="form-group bottom-border">
        <div class="input-label">{{ 'tuya-my-orders.admin-modal.completion-time' | translate }}*</div>
        <div class="date-container">
          <input placeholder="Date" maxlength="10" type="text" class="date-input" bsDatepicker formControlName="date"
            [bsConfig]="{ dateInputFormat: 'M/DD/YYYY', showWeekNumbers: false, maxDate: maxDate, minDate: minDate, containerClass: 'theme-orange' }"
            [ngClass]="{ 'error-field': 
              (adminFormDate.invalid && (adminFormDate.dirty || adminFormDate.touched)) || 
              (adminFormTime.valid && adminFormDate.valid && adminForm.errors?.dateLogic)
            }" />
          <div class="calendar-icon"></div>
          <!-- Date Errors -->
          <div *ngIf="adminFormDate.invalid && (adminFormDate.dirty || adminFormDate.touched)" class="error-msg">
            <div *ngIf="!!adminFormDate.errors.required">
              {{ 'tuya-my-orders.admin-modal.errors.date-required' | translate }}
            </div>
            <div *ngIf="!!adminFormDate.errors.bsDate">
              {{ 'tuya-my-orders.admin-modal.errors.date-pattern' | translate }}
            </div>
          </div>
        </div>
        <div class="time-container">
          <input placeholder="Time" maxlength="7" class="time-input" (focus)="onAdminTimeFocus()" formControlName="time"
            [ngClass]="{ 'error-field': 
              (adminFormTime.invalid && (adminFormTime.dirty || adminFormTime.touched)) || 
              (adminFormTime.valid && adminFormDate.valid && adminForm.errors?.dateLogic)
            }" (tuyaOffClick)="onAdminTimeBlur()">
          <div class="clock-icon"></div>
          <div class="time-options" *ngIf="adminShowTimeList">
            <div class="time-option" *ngFor="let timeOption of timeOptions" (mousedown)="onAdminSelectTime(timeOption)">
              {{ timeOption }}</div>
          </div>
          <!-- Time Errors -->
          <div *ngIf="adminFormTime.invalid && (adminFormTime.dirty || adminFormTime.touched)" class="error-msg">
            <div *ngIf="!!adminFormTime.errors.required">
              {{ 'tuya-my-orders.admin-modal.errors.time-required' | translate }}
            </div>
            <div *ngIf="!!adminFormTime.errors.pattern">
              {{ 'tuya-my-orders.admin-modal.errors.time-pattern' | translate }}
            </div>
          </div>
        </div>
        <!-- Date/Time Cross-field Errors -->
        <div
          *ngIf="(adminFormTime.valid && adminFormDate.valid) && !!adminForm.errors?.dateLogic && (adminForm.touched || adminForm.dirty)"
          class="error-msg cross-field">
          <div *ngIf="!!adminForm.errors.dateLogic.early"> {{ 'tuya-my-orders.admin-modal.errors.date-range-early' |
            translate }}</div>
          <div *ngIf="!!adminForm.errors.dateLogic.late"> {{ 'tuya-my-orders.admin-modal.errors.date-range-late' |
            translate }}</div>
          <div *ngIf="!!adminForm.errors.dateLogic.beforePickup && !adminForm.errors.dateLogic.early"> {{
            'tuya-my-orders.admin-modal.errors.date-range-before-pickup' | translate }}</div>
        </div>
      </div>
      <div class="form-group">
        <div class="input-label">{{ 'tuya-my-orders.admin-modal.comments' | translate }}</div>
        <div class="comment-input">
          <textarea placeholder="Enter comments (optional)" maxlength="250" formControlName="comments"></textarea>
        </div>
      </div>
      <div class="btn-group">
        <button type="submit" class="btn btn-ok" [disabled]="!adminForm.valid">{{ 'tuya-my-orders.admin-modal.confirm' |
          translate }}</button>
        <button type="button" class="btn btn-cancel" (click)="dialogRef.hide();">{{ 'tuya-my-orders.admin-modal.cancel'
          | translate }}</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #noContent>
  <span class="no-content">{{ 'tuya-my-orders.common-event-text.no-content' | translate }}</span>
</ng-template>