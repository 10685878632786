import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AddressDto } from '../../../../core/models/dto';
import { StopType } from '../../../models/order.model';
import { OrderHelper } from './../../../utils/order-helper.utils';

@Component({
  selector: 'tuya-stop-details-header',
  templateUrl: 'stop-details-header.component.html',
  styleUrls: ['stop-details-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopDetailsHeaderComponent {
  @Output() goBack = new EventEmitter();
  @Input() isBackButtonAvaliable: boolean;
  @Input() address: AddressDto;
  @Input() stopType: number;
  @Input() isEditMode: boolean;
  @Input() isDisableSuitNo: boolean;

  StopType: typeof StopType = StopType;
  OrderHelper: typeof OrderHelper = OrderHelper;

  constructor() {
  }

  onGoBack(evt) {
    this.goBack.emit();
  }

}
