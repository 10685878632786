import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { GET_ITEMS_SIZES } from '../constants/api.constants';
import { ConfigService } from '../../app.config.service';

@Injectable()
export class ItemsSizesService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    getItemsSizes() {
        return this.api().GET(GET_ITEMS_SIZES);
    }
}
