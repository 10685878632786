import { Reference, VehicleTypeDto } from './../../core/models/order.dto';
import { AssignmentsDto, SelectedCandidatesDto, SummaryDto } from '../../core/models/order.dto';
import { AddressDto, OrderStatusId, StopDtoType, StopStatusId } from '../../core/models/dto';
import { CreditCardDto } from '../../core/models/payment.dto';

export enum StopType {
    Pickup = 1,
    Delivery = 2,
    Return = 3,
    DeliveryWithReturn = 4
}
export enum DriverAssignmentTypeId {
    Any = 1,
    Exclusive = 2,
    DriversPool = 3
}
export enum paymentCardType { // temporary enum, will be deleted soon
    CreditCard = 1,
    NewCreditCard = 2
}

export enum StopRequiredFields {
    address,
    addressId,
    nameOrDescription,
    contactName
}
export enum BoxSizeTypes {
    Envelope = 1,
    Small = 2,
    Medium = 3,
    Large = 4,
    Pallet = 5,
    UnknownReturn = 6
}

export class Stop {
    id: any;
    orderId: any;
    address: AddressDto | null; // current AddressDto, w/o id or so
    addressId: any;
    typeId: StopType;
    statusId: StopStatusId;
    directId: any;
    sequenceNumber: number;
    nameOrDescription: string;
    pickupDateTypeId: number;
    pickupNoEarlierThan: string;
    pickupNoLaterThan: string;
    contactName: string;
    contactPhoneNumber: string;
    contactEmail: string;
    isEmailTrackingLink: boolean;
    isTextTrackingLink: boolean;
    isSignatureRequired: boolean;
    itemsDescription: string;
    notes: string;
    stopDtoType?: StopDtoType;
    suiteNumber?: string;
    country?: string;
    stateId?: number;
    city?: string;
}

export class PaymentState {
    nameOrCard: string;
    cardNumber: string;
    expirationDate: string;
    cvv: string;
    saveForFuture = false;
    nickname: string;
}

export class OrderVo {
    id?: any; // guid
    deliveryType: number; // 1 or 2
    stopList: Array<Stop>;
    itemList: Array<OrderItemVo>;
    isExtendable: boolean;
    orderCode?: string;
    selectedStopId: any;
    creditCard?: CreditCardDto;
    driverAssignmentType?: DriverAssignmentTypeId;
    confirmationDriversInfo: ConfirmationDriversInfoDto;
    isDirect = false;
    total?: number;
    orderSummary: SummaryDto;
    vehicleType: VehicleTypeDto;
    statusId?: OrderStatusId;
    selectedCandidates: SelectedCandidatesDto;
    assignments: AssignmentsDto;
    hasValidCreditCard: boolean;
    referenceId?: any;
    // TODO: contact list?
}

export class ConfirmationDriversInfoDto {
    driverUserAccountIds: any[];
    driverPoolIds: any[];
}

export class OrderItemVo {
    id?: any;
    piecesCount: number;
    directId?: any;
    sizeId: number;
    size?: any;
    weight: number; // should be an
    description: string;
    reference: Reference;
    stopIds: Array<any>; // guids
}
