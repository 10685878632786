import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrackingActions } from '../../actions/tracking.actions';
import { select, select$ } from '@angular-redux/store';
import { TrackingSelector } from '../../selectors/tracking.selectors';
import { TrackingTransforms } from '../../transforms/tracking.transforms';

@Component({
    selector: 'tuya-tracking-page',
    templateUrl: './tracking-page.component.html',
    styleUrls: ['./tracking-page.component.scss']
})
export class TrackingPageComponent implements OnInit {

    @select(TrackingSelector.trackingInfoVo) public trackingInfoVo$;
    @select$(TrackingSelector.trackingInfo, TrackingTransforms.transformTrackingInfoToMarkerArr) public markers$;

    constructor(private _activeRoute: ActivatedRoute,
        private _trackingActions: TrackingActions) { }

    ngOnInit() {
        this._trackingActions.loadTrackingInfo(this._activeRoute.snapshot.queryParams);
    }
}
