import { Component, Input } from '@angular/core';
import { BusinessEntityDto } from '../../../core/models/dto';

@Component({
  selector: 'tuya-company-header',
  templateUrl: './company-header.component.html',
  styleUrls: ['./company-header.component.scss']
})
export class CompanyHeaderComponent {
  @Input() public companyProfilesInfo: BusinessEntityDto;
  constructor() { }
}
