import { Injectable } from '@angular/core';
import { BillingActions, GET_PAYMENT_METHODS, ADD_CREDIT_CARD } from '../actions/billing.actions';
import { combineEpics, ofType } from 'redux-observable';
import { BillingService } from '../services/billing.service';
import { from, of } from 'rxjs';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';
import { CreditCardDto } from '../../core/models/payment.dto';
import { ShipperActions } from 'app/shipper/actions/shipper.status.actions';
import { AuthActions } from 'app/auth/actions/auth.actions';
import { exhaustMap, concatMap, mergeMap, catchError } from 'rxjs/operators';

@Injectable()
export class BillingEpics {

    constructor(private billingActions: BillingActions,
        private billingService: BillingService,
        private alertActions: AlertActions,
        private authActions: AuthActions,
        private shipperActions: ShipperActions) {
    }

    public createEpic() {
        return combineEpics(
            createGetPaymentMethodsEpic(this.billingActions, this.billingService, this.alertActions),
            createNewCreditCardEpic(this.billingService, this.billingActions, this.shipperActions, this.alertActions),
        );
    }
}

export function createGetPaymentMethodsEpic(billingActions: BillingActions, billingService: BillingService,
    alertActions: AlertActions) {
    return action$ => action$.pipe(
        ofType(GET_PAYMENT_METHODS),
        exhaustMap(() => {
            return billingService.getPaymentMethods().pipe(
                mergeMap((data: CreditCardDto[]) => {
                    return of(billingActions.getPaymentMethodsSucceeded(data));
                })
                ,catchError((error) => {
                    console.error(error);
                    alertActions.show({ error: error, type: AlertType.BadRequest });
                    return of(billingActions.getPaymentMethodsFailed(error));
                }));
        })
    );
}

export function createNewCreditCardEpic(billingService: BillingService, billingActions: BillingActions,
    shipperActions: ShipperActions, alertActions: AlertActions) {
    return action$ => action$.pipe(
        ofType(ADD_CREDIT_CARD),
        concatMap((action: any) => {
            const creditCard: CreditCardDto = action.payload;
            return from(billingService.addCreditCard(creditCard)).pipe(
                mergeMap(data => {
                    return of(
                        billingActions.addCreditCardSucceeded(data),
                        shipperActions.updateCanCreateShipment(true), // shipperStatuses & currentUser
                        shipperActions.updateHasValidCC(true), // shipperStatuses & currentUser
                        billingActions.closePaymentModal(),
                        billingActions.getPaymentMethods()
                    );
                }),
                catchError((error) => {
                    console.error(error);
                    return of(
                        alertActions.show({ error: error, type: AlertType.BadRequest }),
                        billingActions.addCreditCardFailed(error)
                    );
                })
            );
        })
    );
}


