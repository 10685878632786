import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { CanceledOrderInfo, ShipperAssessment } from '../model/driver-info.model';
import { Action } from '../../core/models/redux.action';
import { PaginationState } from '../model/my-orders.model';
import { RecurringDetail } from '../containers/recurring-modal/recurring-modal.component';

export const HOME_RATE_DRIVER = '[HOME] Rate driver';
export const HOME_RATE_DRIVER_SUCCESS = '[HOME] Rate driver successfully';
export const HOME_RATE_DRIVER_FAILURE = '[HOME] Rate driver failed';

export const HOME_LOAD_RATE_REASONS = '[HOME] Load low rate reasons';
export const HOME_LOAD_RATE_REASONS_SUCCESS = '[HOME] Load low rate reasons successfully';
export const HOME_LOAD_RATE_REASONS_FAILURE = '[HOME] Load low rate reasons failed';

export const UPDATE_ORDERS = '[HOME] Update orders';
export const UPDATE_PAGINATION = '[HOME] Update pagination';
export const RESET_PAGINATION = '[HOME] Reset pagination';

export const CANCEL_ORDER = '[HOME] Cancel order';
export const CANCEL_ORDER_SUCCESS = '[HOME] Cancel order successfully';
export const CANCEL_ORDER_ERROR = '[HOME] Cancel order error';
export const CANCEL_ORDER_CANCELED = '[HOME] Cancel order canceled';
export const CANCEL_ORDER_CANCELED_CLEAR = '[HOME] Cancel order canceled clear';
export const UPDATE_ORDER_STATUS = '[HOME] Update order status';

export const CREATE_RECURRING_ORDER = '[RECURRING] Create recurring order';
export const CREATE_RECURRING_ORDER_SUCCESS = '[RECURRING] Create recurring order succeeded';
export const CREATE_RECURRING_ORDER_FAILURE = '[RECURRING] Create recurring order failed';

export const GET_RECURRING_DETAIL = '[RECURRING] Get recurring detail';
export const GET_RECURRING_DETAIL_SUCCESS = '[RECURRING] Get recurring detail succeeded';
export const GET_RECURRING_DETAIL_FAILURE = '[RECURRING] Get recurring detail failed';

export const DELETE_RECURRING_DETAIL = '[RECURRING] Delete recurring detail';
export const DELETE_RECURRING_DETAIL_SUCCESS = '[RECURRING] Delete recurring detail succeeded';
export const DELETE_RECURRING_DETAIL_FAILURE = '[RECURRING] Delete recurring detail failed';

export const CLEAR_RECURRING_DETAIL = '[RECURRING] Clear recurring detail from store';
export const SHOW_RECURRING_MODAL = '[RECURRING] Set recurring id';

export type MyOrdersAction = Action<any, any>;

@Injectable()
export class MyOrdersActions {
    @dispatch()
    rateDriver = (data: ShipperAssessment): MyOrdersAction => ({
        type: HOME_RATE_DRIVER,
        payload: data
    })

    rateDriverSucceeded = (data: any): MyOrdersAction => ({
        type: HOME_RATE_DRIVER_SUCCESS,
        payload: data
    })

    rateDriverFailed = (error: any): MyOrdersAction => ({
        type: HOME_RATE_DRIVER_FAILURE,
        payload: null,
        error
    })

    @dispatch()
    loadLowRateReasons = (): MyOrdersAction => ({
        type: HOME_LOAD_RATE_REASONS,
        payload: null
    })

    loadLowRateReasonsSucceeded = (data: any): MyOrdersAction => ({
        type: HOME_LOAD_RATE_REASONS_SUCCESS,
        payload: data
    })

    loadLowRateReasonsFailed = (data: any): MyOrdersAction => ({
        type: HOME_LOAD_RATE_REASONS_FAILURE,
        payload: data
    })

    @dispatch()
    cancelOrder = (data: any): MyOrdersAction => ({
        type: CANCEL_ORDER,
        payload: data
    })

    cancelOrderSucceeded = (data: CanceledOrderInfo): MyOrdersAction => ({
        type: CANCEL_ORDER_SUCCESS,
        payload: data
    })

    cancelOrderCanceled = (data: CanceledOrderInfo): MyOrdersAction => ({
        type: CANCEL_ORDER_CANCELED,
        payload: data
    })
    @dispatch()
    cancelOrderCanceledClear = (): MyOrdersAction => ({
        type: CANCEL_ORDER_CANCELED_CLEAR,
        payload: null
    })

    cancelOrderFailed = (error: any): MyOrdersAction => ({
        type: CANCEL_ORDER_ERROR,
        payload: null,
        error
    })

    @dispatch()
    updateOrders = (): MyOrdersAction => ({
        type: UPDATE_ORDERS,
        payload: null
    })

    @dispatch()
    updatePagination = (data: PaginationState): MyOrdersAction => ({
        type: UPDATE_PAGINATION,
        payload: data
    })

    @dispatch()
    resetPagination = (): MyOrdersAction => ({
        type: RESET_PAGINATION,
        payload: null
    })

    @dispatch()
    updateOrderStatus = (orderVo: any): MyOrdersAction => ({
        type: UPDATE_ORDER_STATUS,
        payload: orderVo
    })

    @dispatch()
    createRecurringOrder = (item: {data: RecurringDetail, isEditMode: boolean}): MyOrdersAction => ({
        type: CREATE_RECURRING_ORDER,
        payload: item
    })
    @dispatch()
    createRecurringDetailSucceeded = (data: { recurrenceDetail: string, recurrenceOrderId: 26}, orderId: number): MyOrdersAction => ({
        type: CREATE_RECURRING_ORDER_SUCCESS,
        payload: {data, orderId}
    })
    createRecurringOrderFailed = (error: any): MyOrdersAction => ({
        type: CREATE_RECURRING_ORDER_FAILURE,
        payload: error
    })

    @dispatch()
    getRecurringDetail = (recurrId: number): MyOrdersAction => ({
        type: GET_RECURRING_DETAIL,
        payload: recurrId
    })
    getRecurringDetailSucceeded = (recurringDetail: RecurringDetail): MyOrdersAction => ({
        type: GET_RECURRING_DETAIL_SUCCESS,
        payload: recurringDetail
    })
    getRecurringDetailFailed = (error: any): MyOrdersAction => ({
        type: GET_RECURRING_DETAIL_FAILURE,
        payload: error
    })

    @dispatch()
    deleteRecurringDetail = (data: RecurringDetail): MyOrdersAction => ({
        type: DELETE_RECURRING_DETAIL,
        payload: data
    })
    deleteRecurringDetailSucceeded = (payload: any): MyOrdersAction => ({
        type: DELETE_RECURRING_DETAIL_SUCCESS,
        payload: payload
    })
    deleteRecurringDetailFailed = (error: any): MyOrdersAction => ({
        type: DELETE_RECURRING_DETAIL_FAILURE,
        payload: error
    })

    @dispatch()
    clearRecurringDetail = (): MyOrdersAction => ({
        type: CLEAR_RECURRING_DETAIL,
        payload: null
    })

    @dispatch()
    setRecurringId = (data: any) => ({
        type: SHOW_RECURRING_MODAL,
        payload: data
    })
}
