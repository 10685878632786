<div class="modal-wrapper">
    <div class="title">
        When do you want to submit this order?
    </div>

    <form [formGroup]="schSelectionForm" class="form-wrapper"
            (ngSubmit)="onSubmit()">
        <mat-radio-group formControlName="submitNow">
            <mat-radio-button [value]=true disabled="true">
                <span class="selection" [ngStyle]="{'color': !schSelectionForm.get('submitNow')?.value ? '#101b31' : '#5e6a75' }">
                    Allow <b>TUYA</b> to automatically submit this offer to the platform. 
                    (Typically 24 hrs- 36hrs before Pickup Start Time)
                </span>
            </mat-radio-button>

            <!-- Scheduled - submit to platform now -->
            <mat-radio-button [value]=false>
                <span class="selection" [ngStyle]="{'color': schSelectionForm.get('submitNow')?.value ? '#101b31' : '#5e6a75' }">Submit to the platform now.</span>
            </mat-radio-button>
        </mat-radio-group>

        <div class="buttons">
            <button mat-raised-button type="button" class="cancel" (click)="onCancel()">Cancel</button>
            <button mat-raised-button type="submit" class="submit">Submit</button>
        </div>
    </form>
</div>