<div class="modal-wrapper" [@slideInOut]="modalModeForSize">
    <div class="back-button" *ngIf="showBackButton$ | async">
        <button mat-icon-button aria-label="Go back" (click)="onGoBack()">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
    </div>
    <div class="title">{{ title$ | async }}</div>

    <mat-horizontal-stepper [linear]="true" #stepper *ngIf="(modalMode$ | async) === driverModalModeEnum.AddDriver"
        (selectionChange)="selectionChan($event, true)">

        <mat-step [stepControl]="driverForm['controls'].driverData">
            <ng-template matStepLabel>Driver Info</ng-template>
            <tuya-driver-form [formData]="driverForm" [states]="(states$ | async)"
                [serviceAreasProp]="serviceAreas"></tuya-driver-form>
        </mat-step>

        <mat-step [stepControl]="driverForm['controls'].vehicleData">
            <ng-template matStepLabel>Vehicle Info</ng-template>
            <tuya-vehicle-form [formData]="driverForm" [vehiclesProp]="vehicles" [isLoading]="(isLoading$ | async)"
                [modalMode]="(modalMode$ | async)"></tuya-vehicle-form>
        </mat-step>
    </mat-horizontal-stepper>

    <div *ngIf="(modalMode$ | async) === driverModalModeEnum.AddVehicle ||
                (modalMode$ | async) === driverModalModeEnum.UpdateVehicle" class="editVehicle">
        <tuya-vehicle-form [formData]="driverForm" [vehiclesProp]="vehicles" [isLoading]="(isLoading$ | async)"
            [modalMode]="(modalMode$ | async)"></tuya-vehicle-form>
    </div>
</div>