import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { IAppState } from '../../store/model';
import { BuilderActions } from '../actions/builder.actions';
import { MapActions } from '../../core/actions/map.actions';
import { BuilderService } from '../services/builder.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../app.config.service';
import { getInitialAddressState } from '../models/address.model';
import { initialMapState } from '../../core/reducers/map.reducer';
import { isValidAddress } from '../validators/address.validators';
import { Router } from '@angular/router';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';

import { get as _get, merge } from 'lodash-es';

// Actions
import {
    BUILDER_INIT,
    BUILDER_EDIT_TIME,
    BUILDER_ADDRESS_SELECT,
    BUILDER_CHECK_ADDRESS_IN_MSA,
    BUILDER_EDIT_STOP,
    BUILDER_EDIT_LOAD_ORDER,
    BUILDER_EDIT_ORDER,
    BUILDER_ADD_ITEM_TO_ORDER,
    BUILDER_REMOVE_ITEM_FROM_ORDER,
    BUILDER_EDIT_ITEM,
    BUILDER_FORM_UPDATE,
    BUILDER_EDIT_NAME_OR_DESCRIPTION,
    BUILDER_EDIT_PHONE,
    BUILDER_SELECT_DRIVERS,
    BUILDER_SELECT_POOLS,
    BUILDER_UPDATE_ORDER,
    BUILDER_SET_DRIVER_SELECT_SEARCH_TERM,
    BUILDER_SET_DRIVER_SELECT_SEARCH_RESULTS,
    BUILDER_TIME_SELECTION_CHANGE,
    BUILDER_CONFIRM_SUCCESS
} from '../actions/builder.actions';
import { UPDATE_LOCATION } from '@angular-redux/router';
import { catchError, exhaustMap, first, map, switchMap } from 'rxjs/operators';
import { TUYA_US_STATES } from 'app/app.component';

/**
 * Builder epic.
 */
@Injectable()
export class BuilderEpics {

    private builderService: BuilderService;

    constructor(private http: HttpClient,
                private router: Router,
                private config: ConfigService,
                private alertActions: AlertActions) {

        this.builderService = new BuilderService(this.http, this.config);

    }

    public createEpic() {
        return combineEpics(
            onBuilderInit(),
            OnEditAddress(),
            OnEditTime(),
            OnCheckAddressInMSA(this.builderService),
            onBuilderEditLoad(this.builderService, this.alertActions),
            onBuilderEditOrder(this.router),
            onBuilderFormEdit(),
            onBuilderFormRequestSuccess()
        );
    }
}

/**
 * Here as an example epic. Can remove when we get some actual epics in this file.
 */
export function onBuilderInit() {
    const builderActions = new BuilderActions;
    return (action$, store) => action$.pipe (
        ofType(BUILDER_INIT),
        exhaustMap((action: any) => {
            const actions = [];
            const state = store.value;
            const userId = getInitialUserId(state);

            if (userId) {
                actions.push(builderActions.setUserId(userId));
            }

            if (action.payload.orderId) {
                actions.push(builderActions.onBuilderLoadEditOrder(action.payload));
            }
            return actions;
        })
    );
}

/**
 * Update map center on select address.
*/
export function OnEditAddress() {
    return action$ => action$.pipe(
        ofType(BUILDER_ADDRESS_SELECT),
        exhaustMap((action: any) => {
            const actions = [];
            if (isValidAddress(action.payload.stop.address)) {
                // actions.push(builderActions.checkAddressInMsa(action.payload.stop.address, action.payload.index));
            }
            return actions;
        })
    );
}

/**
 * Clear errors on edit time.
 */
export function OnEditTime() {
    const builderActions = new BuilderActions;
    return action$ => action$.pipe (
        ofType(BUILDER_EDIT_TIME),
        exhaustMap((action: any) => {
            const actions = [];
            actions.push(builderActions.clearSubmitErrors(action.payload.index));
            return actions;
        })
    );
}

/**
 * Handle checking of address in MSA range.
 */
export function OnCheckAddressInMSA(service: BuilderService) {
    const mapActions = new MapActions;
    const alertActions = new AlertActions;
    const builderActions = new BuilderActions;
    return (action$, store) => action$.pipe (
        ofType(BUILDER_CHECK_ADDRESS_IN_MSA),
        exhaustMap((action: any) => {
            const index = action.payload.stopIndex;
            if (action.payload.address.latitude && action.payload.address.longitude) {
                const state = store.value;
                state.core.states.map(item => {
                    if (item.shortName === action.payload.address.stateAbbr) {
                        action.payload.address.stateId = item.id;
                        action.payload.address.stateName = item.name;
                        return;
                    }
                });

                action.payload.address.city = action.payload.address.city.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, '\ ');
                action.payload.address.addressLine = action.payload.address.addressLine.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, '\ ');

                service.checkAddressIsInMSARange(action.payload.address).pipe(first()).subscribe(
                    result => {
                        const order = {...state.builder.order};
                        const address = action.payload.address;
                        let isAddressValid = true;

                        if (!result.value && order.stops[index].typeId === 2) {
                            isAddressValid = service.orderHasMsaStop(order.stops);
                        }

                        if (isAddressValid && TUYA_US_STATES.has(address.stateId)) {
                            // inMsa and in Texas
                            // Call this directly instead of returning in array because the
                            // @dispatch() decorator will cause it to run twice.
                            order.stops[index].inMsa = result.value;
                            mapActions.setCenter({
                                lat: address.latitude,
                                lng: address.longitude
                            });
                            const newStop = merge(order.stops[index].address, action.payload);
                            return builderActions.onAddressSelect(newStop, index);
                        } else {
                            // set input error
                            const newStopObj = { ...order.stops[index] };
                            alertActions.show({type: AlertType.NotInMsa});
                            newStopObj.address = getInitialAddressState();
                            newStopObj.nameOrDescription = null;

                            return [mapActions.setCenter(initialMapState.mapCenter),
                                    builderActions.onEditStop({...newStopObj}, index)];
                        }
                    },
                    error => console.error(error)
                );
            }
            const actions = [];
            return actions;
        })
    );
}

export function onBuilderEditLoad(builderService: BuilderService, alertActions: AlertActions) {
    const builderActions = new BuilderActions;
    return action$ => action$.pipe(
        ofType(BUILDER_EDIT_LOAD_ORDER),
        exhaustMap((action: any) => {
            return builderService.getEditOrder(action.payload.orderId, action.payload.isDuplicateMode).pipe(
                                map(res => builderActions.onBuilderLoadEditOrderSuccess(res))
                                ,catchError(error => {
                return [builderActions.onBuilderLoadEditOrderFail(),
                        alertActions.show({error: error, type: AlertType.BadRequest})];
            }));
        })
    );
}

export function onBuilderEditOrder(router: Router) {
    return action$ => action$.pipe(
        ofType(BUILDER_EDIT_ORDER),
        switchMap((action: any) => {
            const payload = `/builder?orderId=${action.payload.orderId}&isDuplicateMode=${action.payload.isDuplicateMode}`;
            return [{type: UPDATE_LOCATION, payload: payload}];
        })
    );
}

/**
 * On any change to the builder form, set form to dirty.
 */
export function onBuilderFormEdit() {
    const builderActions = new BuilderActions;
    return (action$, store) => action$.pipe(
        ofType(
            BUILDER_ADD_ITEM_TO_ORDER,
            BUILDER_REMOVE_ITEM_FROM_ORDER,
            BUILDER_EDIT_ITEM,
            BUILDER_FORM_UPDATE,
            BUILDER_EDIT_STOP,
            BUILDER_EDIT_NAME_OR_DESCRIPTION,
            BUILDER_EDIT_PHONE,
            BUILDER_ADDRESS_SELECT,
            BUILDER_SELECT_DRIVERS,
            BUILDER_SELECT_POOLS,
            BUILDER_UPDATE_ORDER,
            BUILDER_SET_DRIVER_SELECT_SEARCH_TERM,
            BUILDER_SET_DRIVER_SELECT_SEARCH_RESULTS,
            BUILDER_TIME_SELECTION_CHANGE,
            BUILDER_EDIT_TIME
        ),
        exhaustMap((action) => {
            const actions = [];
            const currentState = store.value;
            if (!currentState.builder.ui.navPromptRequired) {
                // Execute rather than return do to the @dispatch decorator.
                builderActions.setNavPromptRequired();
            }
            return actions;
        })
    );
}

/**
 * On any successful confirm request set builder form to clean.
 */
export function onBuilderFormRequestSuccess() {
    const builderActions = new BuilderActions;
    return (action$, store) => action$.pipe(
        ofType(BUILDER_CONFIRM_SUCCESS),
        exhaustMap((action) => {
            const actions = [];
            const currentState = store.value;
            
            // Execute rather than return do to the @dispatch decorator.
            if (currentState.builder.ui.navPromptRequired) builderActions.setNavPromptNotRequired();
            
            return actions;
        })
    );
}

/**
 * Get inital user state depending on if user is admin.
 * @param state IAppState
 */
function getInitialUserId(state: IAppState) {
    let id = null;
    if (!state.auth.currentUser) {
        return id;
    }
    if (state.auth.currentUser &&
        state.auth.currentUser.adminProfile &&
        state.auth.currentUser.adminProfile.isAdmin &&
        state.admin.shipperAdmin.selectedShipper) {
        id = state.admin.shipperAdmin.selectedShipper.userAccountId;
    } else {
        id = state.auth.currentUser.shipperProfile.userAccountId;
    }
    return id;
}
