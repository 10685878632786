import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ItemSizeType } from '../../../home/model/order-events.model';

@Component({
  selector: 'tuya-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit {
  @Input() itemSizes: Array<ItemSizeType>;

  translator;

  constructor(translate: TranslateService) {
    this.translator = translate;
  }

  ngOnInit() {
  }

  getQuantityCopy(itemSize: ItemSizeType): string {
    let copy: string;
    this.translator.get(
        ['tuya-my-orders.stop-completed-arrival-text.item-size-copy.item-singular',
        'tuya-my-orders.stop-completed-arrival-text.item-size-copy.item-plural'],
        { num: itemSize.quantity, size: itemSize.packageType || '' }
       )
    .subscribe((res: object) => {
        if (itemSize.quantity === 1) {
            copy = res['tuya-my-orders.stop-completed-arrival-text.item-size-copy.item-singular'];
        } else {
            copy = res['tuya-my-orders.stop-completed-arrival-text.item-size-copy.item-plural'];
        }
    });
    return copy;
  }

  getWeightCopy(itemSize: ItemSizeType): string {
    let copy: string;
    const totalWeight: number = itemSize.weight * itemSize.quantity;
    this.translator.get(
        ['tuya-my-orders.stop-completed-arrival-text.weight-singular', 'tuya-my-orders.stop-completed-arrival-text.weight-plural'],
        {num: totalWeight})
        .subscribe((res: object) => {
            if (totalWeight === 1) {
                copy = res['tuya-my-orders.stop-completed-arrival-text.weight-singular'];
            } else {
                copy = res['tuya-my-orders.stop-completed-arrival-text.weight-plural'];
            }
        });
    return copy;
  }

  getIcon(itemSize: ItemSizeType): string {
      if (itemSize.packageType) {
        const iconType = itemSize.packageType.toLowerCase();
        return 'icon-' + iconType;
      }
  }

}
