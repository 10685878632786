import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { select } from '@angular-redux/store';
import { BuilderSelectors } from '../../selectors/builder.selectors';
import * as moment from 'moment';
import { StopDto } from '../../../core/models/dto';
import { BuilderActions } from '../../actions/builder.actions';
import { StopType, TimeRangeOptions, pickupStopIndex, deliveryStopIndex } from '../../models/stop.model';
import { Observable } from 'rxjs';
import { parseInputTime, formatToIsoTime, formatDisplayedTime } from '../../utils/time';

@Component({
  selector: 'tuya-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeComponent implements OnInit, OnChanges {
  @Input() idKey: string;
  @Input() groupName: string;
  @Input() stopType: number;
  @Input() stopTypeText: string;
  @Input() stopTypeTextCapitalized: string;
  @Input() stopIndex: number;
  @Input() stop: StopDto;
  @Input() currentStep: number;
  @Input() timeSelections: Array<number>;
  @Input() easyStartTime: string;
  @Input() easyEndTime: string;
  @Input() easyStartTimeDisplay: string;
  @Input() easyEndTimeDisplay: string;
  @Input() timeForm: UntypedFormGroup;
  @Input() dateDropdownOptions: any[];
  @Input() setTimesInState: Function;
  @Input() materialLocked = false;
  @Input() nonMaterialLocked = false;
  @Input() isEditMode = false;
  @Input() isScheduledOrder = false;
  @Input() pickupDate = '';

  @select(BuilderSelectors.getStops) stops$: Observable<Array<StopDto>>;

  public day: string;
  public stops: StopDto[];
  public StopType;
  public TimeRangeOptions;

  private customDisplayFormat = 'hh:mm a';
  private dateFormatString = 'YYYY-MM-DD';
  private isPickupStopInEditMode = false;

  constructor(private builderActions: BuilderActions) {
    this.day = moment().format(this.dateFormatString);
    this.StopType = StopType;
    this.TimeRangeOptions = TimeRangeOptions;
    this.stops$.subscribe(result => this.stops = result);
  }

  ngOnInit() {
    this.isPickupStopInEditMode = this.isEditMode && this.stopType === StopType.Pickup;

    // In edit mode, we have no way to know whether the "Easy" or "Custom" radio
    // was selected and are therefore are setting to "Custom" by default
    if (this.isPickupStopInEditMode) {
      this.builderActions.onTimeSelectionChange(TimeRangeOptions.CustomWindow, this.stopIndex);

      // JAY: to prevent UI failures during edit mode duplicates
      if (moment(this.stop.pickupNoEarlierThan).isBefore(moment().add(10, 'minutes'))) {
        this.stop.pickupNoEarlierThan = moment().add(10, 'minutes').toISOString();
        this.stop.pickupNoLaterThan = moment().add(4, 'hours').add(10, 'minutes').toISOString();
        if (moment(this.stop.pickupNoLaterThan).isAfter(moment('23:59:59', 'HH:mm:ss')))
          this.stop.pickupNoLaterThan = moment('23:59:59', 'HH:mm:ss').toISOString();
      }

      this.setFormControls(this.stop.pickupNoEarlierThan, this.stop.pickupNoLaterThan);
      this.pickupNoEarlierThan.markAsTouched();
      this.pickupNoLaterThan.markAsTouched();
    }

    if (this.materialLocked) {
      this.timeForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // These checks are put in ngOnChanges to trigger when navigating between stops

    // if stop is pickup stop and not the current step, set form control to state values
    if (this.stopType === StopType.Pickup && this.currentStep === deliveryStopIndex) {
      this.setFormControls(this.stop.pickupNoEarlierThan, this.stop.pickupNoLaterThan);
    }

    // delivery stop init
    if (this.stopType === StopType.Delivery && this.currentStep === deliveryStopIndex) {
      // JAY: to prevent UI failures during edit mode duplicates
      if (moment(this.stop.pickupNoEarlierThan).isBefore(moment().add(10, 'minutes'))) {
        this.stop.pickupNoEarlierThan = moment().add(10, 'minutes').toISOString();
        this.stop.pickupNoLaterThan = moment().add(4, 'hours').add(10, 'minutes').toISOString();
        if (moment(this.stop.pickupNoLaterThan).isAfter(moment('23:59:59', 'HH:mm:ss')))
          this.stop.pickupNoLaterThan = moment('23:59:59', 'HH:mm:ss').toISOString();
      }

      let deliveryStartTime = this.stop.pickupNoEarlierThan;
      let deliveryEndTime = this.stop.pickupNoLaterThan;

      // update state with pickup stop's times
      // need to update when implementing multi stops
      if (deliveryStartTime === null) {
        const pickupStop = this.stops[pickupStopIndex];
        deliveryStartTime = pickupStop.pickupNoEarlierThan;
        deliveryEndTime = pickupStop.pickupNoLaterThan;
        this.setTimesInState(this.stopIndex, deliveryStartTime, deliveryEndTime);
      }

      this.setFormControls(deliveryStartTime, deliveryEndTime);
    }

    if (changes && changes.stop) {
      this.day = moment(this.stop.pickupNoEarlierThan).format(this.dateFormatString);
    }
  }

  setFormControls(startTime: string, endTime: string) {
    this.timeForm.patchValue({ 'date': startTime });

    // convert to 'hh:mm am/pm' format for form controls
    const formStartTime = formatDisplayedTime(startTime, this.customDisplayFormat);
    const formEndTime = formatDisplayedTime(endTime, this.customDisplayFormat);

    this.timeForm.patchValue({
      pickupNoEarlierThan: formStartTime,
      pickupNoLaterThan: formEndTime
    });
  }

  onTimeChange(event) {
    this.saveInputTimeToState(event.time, event.key, this.stopIndex);

    if (this.stopType === StopType.Pickup) {
      const needToUpdateDeliveryStartTime = (event.key === 'pickupNoEarlierThan' && this.isDeliveryStartBeforePickupStart);
      const needToUpdateDeliveryEndTime = (event.key === 'pickupNoLaterThan' && this.isDeliveryEndBeforePickupEnd);

      if (needToUpdateDeliveryStartTime || needToUpdateDeliveryEndTime) {
        this.saveInputTimeToState(event.time, event.key, deliveryStopIndex);
      }
    }
  }

  saveInputTimeToState(time: string, key: string, stopIndex: number) {
    // time argument is in 'hh:mm a' format (12:00 pm)
    const parsedTime = parseInputTime(time);    // convert to 'HH:mm:ss' format (12:00:00)
    if (parsedTime === null) return null;       // return if time is invalid
    
    this.day = moment(this.stop.pickupNoEarlierThan).format(this.dateFormatString);
    if (isNaN(new Date(this.day).getTime())) this.day = moment().format(this.dateFormatString);

    const dateTime = formatToIsoTime(this.day, parsedTime);   // convert to iso format 2019-03-01T18:00:00.000Z
    this.builderActions.onEditTime(stopIndex, key, dateTime);
  }

  onTimeRangeRadioChange($event) {
    const selectedTimeOption = Number.parseInt($event, 0);
    if (selectedTimeOption === TimeRangeOptions.EasyWindow) {
      this.setTimesInState(this.stopIndex, this.easyStartTime, this.easyEndTime);
      if (this.isPickupStopInEditMode) {
        // update form controls in pickup stop
        this.setFormControls(this.easyStartTime, this.easyEndTime);
        // update times in delivery stop
        this.setTimesInState(deliveryStopIndex, this.easyStartTime, this.easyEndTime);
      }
    }
    if (selectedTimeOption === TimeRangeOptions.CustomWindow) {
      this.saveInputTimeToState(this.pickupNoEarlierThan.value, 'pickupNoEarlierThan', this.stopIndex);
      this.saveInputTimeToState(this.pickupNoLaterThan.value, 'pickupNoLaterThan', this.stopIndex);
    }
    this.builderActions.onTimeSelectionChange(selectedTimeOption, this.stopIndex);
  }

  onDateChange(selectedDate: string) {
    this.day = selectedDate;
    // getting the day this way because event.target.value = "1. Object" which is useless
    const parsedStartTime = parseInputTime(this.pickupNoEarlierThan.value);
    if (parsedStartTime === null) return null; // return if time is invalid

    const parsedEndTime = parseInputTime(this.pickupNoLaterThan.value);    
    if (parsedEndTime === null) return null;   // return if time is invalid

    const startTime = formatToIsoTime(this.day, parsedStartTime);
    const endTime = formatToIsoTime(this.day, parsedEndTime);
    this.setTimesInState(this.stopIndex, startTime, endTime);

    if ((this.stops[1] && this.stops[1].pickupNoEarlierThan < this.stops[0].pickupNoEarlierThan) || this.isPickupStopInEditMode) {
      this.setTimesInState(deliveryStopIndex, startTime, endTime);
    } else if (this.stops[1]) {
      const d = new Date(new Date(this.stops[1].pickupNoEarlierThan).getTime() + (5 * 24 * 60 * 60 * 1000)).toISOString();
      if (d > this.stops[0].pickupNoEarlierThan) {
        this.setTimesInState(deliveryStopIndex, startTime, endTime);
      }
    }
  }

  get isDeliveryStartBeforePickupStart() {
    if (this.stops[deliveryStopIndex]) {
      const pickupStartTime = this.stops[pickupStopIndex].pickupNoEarlierThan;
      const deliveryStartTime = this.stops[deliveryStopIndex].pickupNoEarlierThan;

      return moment(pickupStartTime).isAfter(deliveryStartTime);
    }

    return false;
  }

  get isDeliveryEndBeforePickupEnd() {
    if (this.stops[deliveryStopIndex]) {
      const pickupEndTime = this.stops[pickupStopIndex].pickupNoLaterThan;
      const deliveryEndTime = this.stops[deliveryStopIndex].pickupNoLaterThan;

      return moment(pickupEndTime).isAfter(deliveryEndTime);
    }

    return false;
  }

  get pickupNoEarlierThan() {
    return this.timeForm.get('pickupNoEarlierThan');
  }

  get pickupNoLaterThan() {
    return this.timeForm.get('pickupNoLaterThan');
  }
}
