import { Component, OnInit } from '@angular/core';
import { AuthActions } from '../../actions/auth.actions';

@Component({
    template: ''
})

export class LogoutComponent implements OnInit {
    constructor(private authActions: AuthActions) {
    }

    ngOnInit() {
        this.authActions.logout();
    }
}
