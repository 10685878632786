<tuya-stop-details-edit
        [details]="stopDeitalsEdit$ | async"
        [relatedStop]="relatedStop$ | async"
        [address]="(currentStop$ | async)?.address"
        [stopType]="(currentStop$ | async)?.typeId"
        [itemsSizes]="itemsSizes$ | async"
        [isDirect]="isDirect$ | async"
        [isInitialPickupStop]="isInitialPickupStop$ | async"
        [orderEditMode]="orderEditMode$ | async"
        [isStopDetailsEditMode]="isStopDetailsEditMode$ | async"
        [defaultSize]="defaultItemSize$ | async"
        [shipmentType]="orderDeliveryType$ | async"
        [items]="(currentStop$ | async)?.id && (orderItemList$ | async | filterItemsByStopId: (currentStop$ | async).id)"
        (goBack)="onGoBack()"
        (detailsTouched)="onDetailsTouched()"
        (onStopDetailsChunkSubmit)="saveStopDetails($event)"
        (onDefaultSizeUpdate)="onDefaultSizeUpdate($event)"
></tuya-stop-details-edit>