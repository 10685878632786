import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CombinedPoolsDTO } from 'app/core/models/dto';

export enum PoolConfirmType {
  create = 0,
  add,
  remove,
  addAndRemove
}

export interface PoolConfirmPopup {
  type: PoolConfirmType;
  pools: CombinedPoolsDTO[];
  driver: string;
  removedPools?: CombinedPoolsDTO[];
}

@Component({
  selector: 'tuya-driver-pool-confirmation',
  templateUrl: './driver-pool-confirmation.component.html',
  styleUrls: ['./driver-pool-confirmation.component.scss']
})
export class DriverPoolConfirmationComponent implements OnInit {

  poolConfirmType = PoolConfirmType;

  constructor(public dialogRef: MatDialogRef<DriverPoolConfirmationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: PoolConfirmPopup) { }

  ngOnInit() {
  }

  onAccept() {
    this.dialogRef.close();
  }

}
