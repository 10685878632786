import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { StopDto } from '../../../../core/models/dto';
import { NgForm } from '@angular/forms';
import { StopDetailsFormComponent } from '../stop-details-form/stop-details-form.component';

@Component({
    selector: 'tuya-stop-name-field',
    templateUrl: 'stop-name-field.component.html',
    styleUrls: ['stop-name-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopNameFieldComponent extends StopDetailsFormComponent {
    @ViewChild('form', {static: true}) public form: NgForm;
    @Input() stop: StopDto = new StopDto();
    @Input() disabled;
    @Output()
    validate = new EventEmitter<any>();
    isOnlySpaces: boolean;

  public namePattern = /^[\w ,.?@&!#'~*\-;+]*$/;

    constructor() {
        super();
    }

    onValidate() {
        this.isOnlySpaces = this.stop.nameOrDescription.length && !this.stop.nameOrDescription.trim().length;
        const isNameValid = this.namePattern.test(this.stop.nameOrDescription) &&
            this.stop.nameOrDescription.length && !this.isOnlySpaces;
        this.validate.emit({nameOrDescription: isNameValid});
    }
}
