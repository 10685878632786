import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ShipmentType } from '../../../core/models/shipment.model';
import { OrderEditMode } from '../../../core/models/order.dto';
import { ShipmentActions } from '../../actions/shipment.actions';
import { Router } from '@angular/router';

const COLLECTION_NAME = 'tuya-shipment.shipment-builder.collection';
const DISTRIBUTION_NAME = 'tuya-shipment.shipment-builder.distribution';
const SHIPMENT_BUILDER_NAME = 'tuya-shipment.shipment-builder.shipment_builder';
const BUILDER_ROUTE = '/shipment/builder';

@Component({
    selector: 'tuya-shipment-header',
    templateUrl: 'shipment-header.component.html',
    styleUrls: ['shipment-header.component.scss']
})
export class ShipmentHeaderComponent implements OnInit {
    @Input() headerFirstLevel = SHIPMENT_BUILDER_NAME;
    @Input() shipmentType;
    @Input() headerSecondLevel;
    @Input() editMode: OrderEditMode;
    @Input() isDirectOrder = false;
    @Input() orderRefID: any;
    @Input() orderEditRefID: any;

    inputVal: any;
    canEdit: boolean;

    @Output() directOrderUpdate = new EventEmitter<boolean>();

    OrderEditMode: typeof OrderEditMode = OrderEditMode;

    constructor(private shipmentActions: ShipmentActions, private router: Router) {
    }

    ngOnInit() {
        this.inputVal = (this.editMode === OrderEditMode.Other
                || this.editMode === OrderEditMode.NoEdit)
                ? this.orderRefID
                : this.orderEditRefID;

        this.canEdit = this.router.url === BUILDER_ROUTE;
    }

    public getHeaderText() {
        return this.headerSecondLevel || this.shipmentType === ShipmentType.Collection ? COLLECTION_NAME : DISTRIBUTION_NAME;
    }

    public ifDisabledEditDirect() {
        return this.editMode >= OrderEditMode.Accepted;
    }

    public onChange(e) {
        const val = e.target.value;
        this.shipmentActions.shipmentOrderAddReferenceID(val);
    }
}
