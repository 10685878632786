import { Reference } from './../../../../core/models/order.dto';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BoxSizeDto, StopDto, StopStatusId } from '../../../../core/models/dto';
import { OrderItemVo } from '../../../models/order.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, skip, Subscription } from 'rxjs';

type BoxSizeDtoMap =  { [key: number]: BoxSizeDto };
const MAX_ITEM_VALUE = 99;
const MAX_ITEM_WEIGHT = 9999;

@Component({
    selector: 'tuya-delivery-items-selection',
    templateUrl: 'delivery-items-selection.component.html',
    styleUrls: ['delivery-items-selection.component.scss']
})
export class DeliveryItemsSelectionComponent implements OnInit, OnDestroy {

    @Output() onSizeSelected = new EventEmitter<number>();
    @Output()
    validate = new EventEmitter<any>();
    @Output()
    change = new EventEmitter();
    @Output()
    saveItems = new EventEmitter<{
                      items: Array<OrderItemVo>;
                      isValid: boolean;
                      itemsDescription: string;
                    }>();
    @Input() defaultSize = 1;
    @Input() disabled;
    @Input() isFormPristine = true;
    @Input() public set disabledDescription(disabledDescription) {
        this._disabledDescription = disabledDescription;
        disabledDescription ? this.itemDescriptionCtrl.disable() : this.itemDescriptionCtrl.enable();
    }
    @Input() stop: StopDto = new StopDto();


    public itemDescriptionCtrl = new UntypedFormControl();

    public isChecked = false;
    public StopStatusId: typeof StopStatusId = StopStatusId;
    public hazardousMaterialRef: BsModalRef;
    public get disabledDescription() {
        return this._disabledDescription;
    }
    @ViewChild('hazardous', {static: true}) hazardous;

    public _filterItems: OrderItemVo[];
    public isCollapsed = true;
    private _itemsSizesMap: BoxSizeDtoMap;
    private _itemsSizes: BoxSizeDto[];
    private _disabledDescription = false;

    @Input() set itemsSizes(szs: BoxSizeDto[]) {
        this._itemsSizes = szs.filter(box => box.isVisible);
        this._itemsSizesMap = szs.reduce((l: BoxSizeDtoMap, r: BoxSizeDto) => ({
          ...l,
          [r.id]: r
        }), {} );
    }
    get itemsSizes() {
      return this._itemsSizes;
    }

    @Input() set items(value: OrderItemVo[]) {
        if (value === undefined || value.length < 1) {
          // this._items = [this.createDefaultItem()];
          this._filterItems = [this.createDefaultItem()];
          this.onItemsChanged();
          return;
        }
        this._filterItems = value;
        this.onItemsChanged();
    }

    private _subscription: Subscription;


    constructor(private modalService: BsModalService) {

    }

    ngOnInit() {
      this._subscription = this.itemDescriptionCtrl.valueChanges.pipe(
          skip(1),
          debounceTime(1000)
      ).subscribe((value) => {
          this.onItemsChanged();
          this.updateFormState();
      });
  }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    getSizeById(id) {
      // assert needed
      return this._itemsSizesMap[id];
    }

    createDefaultItem() {
        const sz = this.getSizeById(this.defaultSize);
        const item = {
                piecesCount: 1,
                sizeTypeId: 0,
                sizeId: sz.id,
                weight: sz.weight,
                reference: new Reference,
                description: sz.description,
                stopIds: []
            };
        return item;
    }

    isItemValid(item) {
      const c = parseInt(item.piecesCount, 10);
      const w = parseInt(item.weight, 10);
      return 0 < c && c <= MAX_ITEM_VALUE
          && 0 < w && w <= MAX_ITEM_WEIGHT; // unknow boxtype weight = 0
    }

    isItemListValid() {
      return this._filterItems.length > 0 && this._filterItems.reduce( (l, r) => {
        return l && this.isItemValid(r);
      }, true);
    }

    onAddItem() {
        this._filterItems.push(this.createDefaultItem());
        this.onItemsChanged();
        this.updateFormState();
    }

    onItemSizeUpdate(idx, sz) {
      // update weight if it's default; should check dirty ?
      if (this._filterItems[idx].weight === this._itemsSizesMap[this._filterItems[idx].sizeId].weight) {
        this._filterItems[idx].weight = sz.weight;
        this._filterItems[idx].description = sz.description;
      }

      this._filterItems[idx].sizeId = sz.id;
      this.onSizeSelected.emit(sz.id);

      this.onItemsChanged();
      this.updateFormState();
    }

    deleteItem(idx) {
      if (this._filterItems.length < 1) {
        return;
      }
      this._filterItems = this._filterItems.filter( (v, i) => i !== idx );
      this.onItemsChanged();
      this.updateFormState();
    }

    updateFormState() {
        if (this.isFormPristine) {
            this.isFormPristine = false;
            this.change.emit();
        }
    }

    onItemsChanged() {
        this.saveItems.emit({
          items: this._filterItems,
          isValid: this.isItemListValid(),
          itemsDescription: this.stop.itemsDescription
        });
    }

    onUpdateHazardCheckbox(isChecked) {
     if (isChecked) {
         this.openHazardousMaterialModal(this.hazardous);
     }
     this.validate.emit({hazardousMaterials: !isChecked});
    }

    openHazardousMaterialModal(template: TemplateRef<any>) {
      if (this.isChecked) {
        this.hazardousMaterialRef =
          this.modalService.show(template, {ignoreBackdropClick: true });
      }
    }
}
