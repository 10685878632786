<fieldset class="builder-item-selector builder-fieldset">
  <div [formGroup]="itemSelectorForm">

    <legend class="builder-field-label builder-item-selector-label">
        {{ 'itemSelector.addItemsLabel' | translate }} *
    </legend>
    <div class="builder-form-warning">
      <div class="builder-form-icon"></div>
      <span class="builder-form-message">
          {{ 'itemSelector.hazardousMaterialsWarning' | translate }}
      </span>
    </div>

    <table class="builder-item-selector-table">
      <thead>
        <tr>
          <th class="builder-item-selector-table-header">
            {{ 'itemSelector.sizeHeader' | translate }}
          </th>
          <th class="builder-item-selector-table-header">
            {{ 'itemSelector.quantityHeader' | translate }}
          </th>
          <th class="builder-item-selector-table-header">
            {{ 'itemSelector.weightHeader' | translate }} ({{ 'itemSelector.weightUOM' | translate }})
          </th>
          <th class="builder-item-selector-table-header">
            {{ 'itemSelector.referenceHeader' | translate }}
          </th>
          <th class="builder-item-selector-table-header">
            <span class="sr-only sr-only-focusable">{{ 'itemSelector.removeItem' | translate }}</span>
          </th>
        </tr>
      </thead>

      <tbody formArrayName="itemList" class="builder-item-selector-table-body">
        <tr class="builder-item-selector-table-rows"
          *ngFor="let item of itemList.controls; let i=index"
          [formGroupName]="i">

          <td class="builder-item-selector-table-cell">
            <label 
              class="sr-only sr-only-focusable" 
              for="item-size">
                {{ 'itemSizeOptions.chooseItemSizeLabel' | translate }}
            </label>
            <select 
              class="builder-item-select"
              [ngClass]="{'builder-item-select--disabled': materialLocked}"
              id="item-size" 
              name="item-size"
              formControlName="sizeTypeId"
              (change)="onSelectItemSize(i, $event)"
              data-key="sizeTypeId">
              <option
                *ngFor="let itemSize of itemsSizes"
                [value]="itemSize.sizeTypeId"
                class="builder-item-select-option">
                  {{ itemSize.name }}&nbsp;-&nbsp;{{ itemSize.description }}
              </option>
            </select>
          </td>
    
          <td class="builder-item-selector-table-cell builder-item-selector-item-quantity">
            <label 
              class="sr-only sr-only-focusable"
              for="item-quantity">
                {{ 'itemSelector.enterQuantity' | translate }}
            </label>
            <input 
              class="builder-item-selector-item-quantity-input"
              [ngClass]="{'builder-item-selector-item-quantity-input--disabled': materialLocked}"
              type="number"
              id="item-quantity" 
              name="item-quantity"
              placeholder="{{ 'itemSelector.quantityAbbr' | translate }}"
              formControlName="piecesCount"
              min="1"
              max="999"
              (change)="onEditItem(i, $event)"
              data-key="piecesCount">
            <span *ngIf="piecesCount(i).invalid && piecesCount(i).touched"
              class="input-field-error-message builder-item-selector-item-quantity-error">
              <span *ngIf="piecesCount(i).errors.required">
                {{ 'validation.piecesCount' | translate }}
              </span>
            </span>
          </td>
      
          <td class="builder-item-selector-table-cell builder-item-selector-item-weight">
            <label 
              class="sr-only sr-only-focusable" 
              for="item-weight">
                {{ 'itemSelector.enterWeight' | translate }}
            </label>
            <input
              class="builder-item-selector-item-weight-input"
              [ngClass]="{'builder-item-selector-item-weight-input--disabled': materialLocked}"
              type="number"
              name="item-weight"
              id="item-weight"
              placeholder="{{ 'itemSelector.weightUOM' | translate }}"
              formControlName="weight"
              min="1"
              max="999"
              (change)="onEditItem(i, $event)"
              data-key="weight">
            <span *ngIf="weight(i).invalid && weight(i).touched"
              class="input-field-error-message builder-item-selector-item-weight-error">
              <span *ngIf="weight(i).errors.required">
                {{ 'validation.weight' | translate }}
              </span>
            </span>
          </td>
    
          <td class="builder-item-selector-table-cell">
            <label 
              class="sr-only sr-only-focusable"
              for="item-reference-id">
                {{ 'itemSelector.referenceId' | translate }} ({{ 'itemSelector.optional' | translate }})
            </label>
            <input
              class="builder-item-selector-reference-id-input"
              [ngClass]="{'multiple': numItems > 1 && !materialLocked,
                'builder-item-selector-item-weight-input--disabled': materialLocked}"
              type="text"
              id="item-reference-id"
              name="item-reference-id"
              placeholder="Reference ID"
              formControlName="referenceId"
              maxlength="75"
              (focusout)="onEditItem(i, $event)"
              data-key="referenceId">
          </td>

          <td class="builder-item-selector-table-cell" *ngIf="numItems > 1 && !materialLocked">
            <button 
              type="button"
              class="builder-remove-item-button"
              id="remove-item-button-{{ i }}"
              (click)="onRemoveItem(i)">
              <span class="builder-remove-item-symbol">-</span>
            </button>
            <label class="sr-only sr-only-focusable" for="remove-item-button-{{ i }}">
              {{ 'itemSelector.removeItem' | translate }}
            </label>
          </td>

        </tr>
      </tbody>
    </table>

    <div class="builder-item-selector-add-remove-item builder-add-item" *ngIf="!materialLocked">
      <button 
        class="builder-add-item-button"
        id="add-item-button"
        type="button"
        (click)="onAddItem()">
          +
      </button>
      <label class="builder-item-selector-add-remove-item-text" for="add-item-button">
          {{ 'itemSelector.addItemLabel' | translate }}
      </label>
    </div>

    <textarea
      class="builder-item-selector-item-notes"
      name="builder-item-notes"
      id="builder-item-notes"
      placeholder="{{ 'itemSelector.enterNotes' | translate }}(s)"
      (focusout)="onEditItemNotes($event)"
      attr.data-key="{{ 'itemsDescription' }}"
      formControlName="itemsDescription"
      maxlength="250"
    >{{ stop.itemsDescription }}</textarea>

  </div>
</fieldset>
