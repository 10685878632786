import { StopDto, StopEventLog, TrackingOrderItemDto } from './../../core/models/dto';
import { OrderItemErrorId, OrderStatusId, StopEventType, TrackingInfoDto } from '../../core/models/dto';
import { StopType } from '../../shipment/models/order.model';

export class ITrackingState {
    trackingInfo: TrackingInfoDto;
}

export class TrackingOrderItem extends TrackingOrderItemDto {
    itemName: string;
    itemWeight: number;
    itemDescription: string;
}

export class TrackingInfoVo {
    driverUserAccountId: any;
    businessEntityName: string;
    createDate: string;
    driverName: string;
    driverPhoto: string | null;
    itemsDescription: string;
    orderCode: string;
    orderItems: TrackingOrderItem[];
    orderStatusId: number;
    shipperEmail: string;
    shipperName: string;
    shipperPhone: string;
    lastLocationUpdateTime: string;
    isDirectOrder: boolean;
    stopInfo: StopDto;
    stopEventLog: StopEventLog[];
    driverArrivedAtFirstStop: boolean;
}

export const Steps = {
    [StopType.Pickup]: [
        {
            statusId: OrderStatusId.Offered,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.shipment-created',
            skip: false
        },
        {
            statusId: OrderStatusId.Assigned,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.driver-assigned',
            skip: true
        },
        {
            statusId: OrderStatusId.Assigned,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.await-arrival',
            skip: false
        },
        {
            statusId: OrderStatusId.Completed || OrderStatusId.CompletedWithExceptions,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.picked-up',
            skip: false
        }
    ],
    [StopType.Delivery]: [
        {
            statusId: OrderStatusId.Offered,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.shipment-created',
            skip: false
        },
        {
            statusId: OrderStatusId.Assigned,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.driver-assigned',
            skip: false
        },
        {
            statusId: OrderStatusId.InProgress,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.picked-up',
            skip: true
        },
        {
            statusId: OrderStatusId.InProgress,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.in-transit',
            skip: false
        },
        {
            statusId: OrderStatusId.Completed || OrderStatusId.CompletedWithExceptions,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.delivered',
            skip: false
        }
    ],
    [StopType.Return]: [
        {
            statusId: OrderStatusId.Offered,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.shipment-created',
            skip: false
        },
        {
            statusId: OrderStatusId.Assigned,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.driver-assigned',
            skip: false
        },
        {
            statusId: OrderStatusId.InProgress,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.picked-up',
            skip: true
        },
        {
            statusId: OrderStatusId.InProgress,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.in-transit',
            skip: false
        },
        {
            statusId: OrderStatusId.Completed || OrderStatusId.CompletedWithExceptions,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.delivered',
            skip: false
        }
    ],
    [StopType.DeliveryWithReturn]: [
        {
            statusId: OrderStatusId.Offered,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.shipment-created',
            skip: false
        },
        {
            statusId: OrderStatusId.Assigned,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.driver-assigned',
            skip: false
        },
        {
            statusId: OrderStatusId.InProgress,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.picked-up',
            skip: true
        },
        {
            statusId: OrderStatusId.InProgress,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.in-transit',
            skip: false
        },
        {
            statusId: OrderStatusId.Completed || OrderStatusId.CompletedWithExceptions,
            stepName: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stepper.delivered',
            skip: false
        }
    ]
};

export const EventsNames = {
    [StopType.Pickup]: {
        [StopEventType.Arrive]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.arrived',
        [StopEventType.CancelArrival]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.attempted-arrival',
        [StopEventType.Complete]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.pickup-completed',
        [StopEventType.Departure]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.attempted-pickup',
        [StopEventType.ItemException]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.item-exception'
    },
    [StopType.Delivery]: {
        [StopEventType.Arrive]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.arrived',
        [StopEventType.CancelArrival]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.attempted-arrival',
        [StopEventType.Complete]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.delivery-completed',
        [StopEventType.Departure]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.attempted-delivery',
        [StopEventType.ItemException]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.item-exception'
    },
    [StopType.Return]: {
        [StopEventType.Arrive]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.arrived',
        [StopEventType.CancelArrival]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.attempted-arrival',
        [StopEventType.Complete]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.return-completed',
        [StopEventType.Departure]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.attempted-return',
        [StopEventType.ItemException]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.item-exception'
    },
    [StopType.DeliveryWithReturn]: {
        [StopEventType.Arrive]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.arrived',
        [StopEventType.CancelArrival]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.attempted-arrival',
        [StopEventType.Complete]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.delivery-completed',
        [StopEventType.Departure]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.attempted-delivery',
        [StopEventType.ItemException]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.stop-names.item-exception'
    }
};

export const OrderTypes = {
    [StopType.Delivery]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.delivery',
    [StopType.Pickup]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.pickup',
    [StopType.Return]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.return',
    [StopType.DeliveryWithReturn]: 'tuya-tracking.tuya-tracking-info.tuya-stop-event-item.delivery'
};

export const ItemsErrorTypes = {
    [OrderItemErrorId.Ok]: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.errors.ok',
    [OrderItemErrorId.NoReceiver]: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.errors.no-receiver',
    [OrderItemErrorId.DamagedItems]: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.errors.damaged-items',
    [OrderItemErrorId.MissingItems]: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.errors.missing-items',
    [OrderItemErrorId.RefusedByReceiver]: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.errors.refused',
    [OrderItemErrorId.ExtraItems]: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.errors.extra-items',
    [OrderItemErrorId.Unknown]: 'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.errors.unknown'
};