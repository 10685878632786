import { Component, OnInit, Input } from '@angular/core';
import { BuilderActions } from 'app/builder/actions/builder.actions';

@Component({
  selector: 'tuya-builder-reference-id',
  templateUrl: './builder-reference-id.component.html',
  styleUrls: ['./builder-reference-id.component.scss']
})
export class BuilderReferenceIdComponent implements OnInit {

  @Input() referenceId = null;

  constructor(private builderActions: BuilderActions) { }

  ngOnInit() {
  }

  onReferenceIdUpdate(event: any) {
    const orderReferenceId = event.target.value.trim();
    if (orderReferenceId) {
      this.builderActions.onUpdateOrder({key: 'referenceId', value: event.target.value});
    }
  }

}
