<div class="ordeTableWrapper">

  <table mat-table [dataSource]="items"
         matSort
         (matSortChange)="sortTable($event)"
         class="orderTable"
         multiTemplateDataRows
         matSortDisableClear="true">

    <!-- Recurring Icon Column -->
    <ng-container matColumnDef="{{ headers[0] }}">
      <th mat-header-cell *matHeaderCellDef class="recurring-col"></th>
      <td mat-cell *matCellDef="let item" class="recurring-col">
        <div *ngIf="item.recurrenceOrderDetail"
            class="recurring-icon"
            tooltip="{{ item.recurrenceOrderDetail.recurrenceDetail }}"
            placement="bottom"
            container="body"
            containerClass="tuya-scheduled-tooltip"></div>
      </td>
    </ng-container>

    <!-- Scheduled Icon Column -->
    <ng-container matColumnDef="{{ headers[1] }}">
      <th mat-header-cell *matHeaderCellDef class="scheduled-col"></th>
      <td mat-cell *matCellDef="let item" class="scheduled-col">
        <div class="scheduled-icon"
          *ngIf="item.statusId == orderStatusId.Scheduled ||
                  (item.isScheduledOrder && item.statusId === orderStatusId.Offered) ||
                  (item.isScheduledOrder && item.statusId === orderStatusId.FutureAssigned)"
          tooltip="{{item.statusId == orderStatusId.Scheduled ?
                    'This Scheduled Order has not been submitted to the platform yet by TUYA.' : 
                    'This scheduled Order has been submitted to the platform'}}"
          placement="bottom"
          container="body"
          containerClass="tuya-scheduled-tooltip"></div>
      </td>
    </ng-container>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="{{ headers[2] }}">
      <th mat-header-cell *matHeaderCellDef class="check-box">
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()"></mat-checkbox>
      </th>

      <td mat-cell *matCellDef="let item" class="check-box">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(item) : null"
                      [checked]="selection.isSelected(item)"
                      [aria-label]="checkboxLabel(item)"></mat-checkbox>
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="{{ headers[3] }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="insider"> {{ 'tuya-my-orders.table-headers.' + headers[3] | translate }} </th>
      <td mat-cell *matCellDef="let item" class="insider">
        <div>{{item?.date | date:'M/dd/yyyy'}}</div>
        <div class="small-font time-value">{{item?.date | date:'shortTime' | lowercase}}</div>
      </td>
    </ng-container>
    
    <!-- dateCompleted Column -->
    <ng-container matColumnDef="{{ headers[4] }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="insider"> {{ 'tuya-my-orders.table-headers.' + headers[4] | translate }} </th>
      <td mat-cell *matCellDef="let item" class="insider" [ngClass]="{'date-completed-no-data': !item?.dateCompleted}">
          <div>{{item?.dateCompleted | date:'M/dd/yyyy'}}</div>
          <div class="small-font time-value">{{item?.dateCompleted | date:'shortTime' | lowercase}}</div>
          <div *ngIf="!item?.dateCompleted">&mdash;</div>
      </td>
    </ng-container>
    
    <!-- Code Column -->
    <ng-container matColumnDef="{{ headers[5] }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="insider">{{ 'tuya-my-orders.table-headers.' + headers[5] | translate }}</th>
      <td mat-cell *matCellDef="let item" class="insider">
        <div>{{ item?.code }}</div>
      </td>
    </ng-container>
  
    <!-- Stop from Column -->
    <ng-container matColumnDef="{{ headers[6] }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="insider">{{ 'tuya-my-orders.table-headers.' + headers[6] | translate }}</th>
      <td mat-cell *matCellDef="let item" class="insider"> 
        <div class="text-container">{{item?.stopTo.short}}</div>
        <div class="small-font text-container">{{item?.stopTo.long}}</div>
      </td>
    </ng-container>
    
    <!-- Stop to Column -->
    <ng-container matColumnDef="{{ headers[7] }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="insider"> {{  'tuya-my-orders.table-headers.' + headers[7] | translate }} </th>
      <td mat-cell *matCellDef="let item" class="insider">
        <div class="text-container">{{item?.stopFrom.short}}</div>
        <div class="small-font text-container">{{item?.stopFrom.long}}</div>
      </td>
    </ng-container>

      <!-- Assigned Driver Column -->
    <ng-container matColumnDef="{{ headers[8] }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="insider"> {{ 'tuya-my-orders.table-headers.' + headers[8] | translate }} </th>
      <td mat-cell *matCellDef="let item" class="insider">
        <div>{{item?.deliveryPro !== 'Empty' ? item?.deliveryPro :
                                    ('tuya-my-orders.table-headers.noDriver' | translate)}}
        </div>
      </td>
    </ng-container>

    <!-- Order Total Column -->
    <ng-container matColumnDef="{{ headers[9] }}" class="insider">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{  'tuya-my-orders.table-headers.' + headers[9] | translate }} </th>
      <td mat-cell *matCellDef="let item" class="insider">
        {{ item?.total | currency:'USD':'symbol' }}
      </td>
    </ng-container>

    <!-- Order Status Column -->
    <ng-container matColumnDef="{{ headers[10] }}">
        <th mat-header-cell *matHeaderCellDef class="insider" mat-sort-header> {{  'tuya-my-orders.table-headers.' + headers[10] | translate }} </th>
        <td mat-cell *matCellDef="let item" class="order-status insider" [ngClass]="orderStatuses[item?.statusId]?.color">
            {{ orderStatuses[item?.statusId]?.name }}
            <span class="exception" *ngIf="item?.statusId === orderStatusId.CompletedWithExceptions"></span>
        </td>
    </ng-container>

    <!-- Order Detail Icon -->
    <ng-container matColumnDef="{{ headers[11] }}" stickyEnd>
      <th mat-header-cell *matHeaderCellDef class="sticky-end"></th>
      <td mat-cell *matCellDef="let item" 
                    class="sticky-end"
                    routerLink= "/order-details" [queryParams]="{orderId: item.orderId}">
        <div class="arrow-icon"></div>
      </td>
    </ng-container>

    <!-- Expanded Order Details -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let row" [ngStyle]="{'padding.px': 0}" [attr.colspan]="headers.length">
        <tuya-expanded-order-view *ngIf="!!expandedRows[row.orderId]"
                          [isShipperSuspended]="isShipperSuspended"
                          [orderItem]="expandedRows[row.orderId]"
                          [recurrenceOrderDetail]="row.recurrenceOrderDetail"
                          (onEditRecurringOrder)="onRecurringOrderEdit($event)">
        </tuya-expanded-order-view>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="headers"></tr>
    <tr mat-row *matRowDef="let row; columns: headers;"
                matRipple class="element-row"
                (click)="toggleExpandableSymbol(row)"
                class="table-row"
                [ngClass]="{'expanded-row': row.orderId === expandedRows[row.orderId]?.orderId }"></tr>

    <tr mat-row class="expandable-row" *matRowDef="let row; columns: ['expandedDetail'];"></tr>
  </table>

  <mat-paginator [pageSize]="pageSize"
                 [pageSizeOptions]="[10, 20, 50]"
                 [length]="totalItemCount"
                 [pageIndex]="currentPageNumber-1"
                 (page)="onPaginateChange($event)"
                 showFirstLastButtons></mat-paginator>
</div>