import { Component, Input, OnInit } from '@angular/core';
import { SummaryDto } from '../../models/order.dto';

@Component({
    selector: 'tuya-order-summary-bar',
    templateUrl: './order-summary-bar.component.html',
    styleUrls: ['./order-summary-bar.component.scss']
})
export class OrderSummaryBarComponent implements OnInit {

    @Input() orderSummary: SummaryDto = {
        items: 0, stops: 0, lbs: 0, miles: 0, returns: 0
    };
    @Input() isLoading = false;

    constructor() {}

    ngOnInit() {}
}
