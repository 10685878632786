import { OrderItemDto, OrderItemBase} from './item.model';
import { getInitialStopState, StopType, StopDto } from './stop.model';
import { AssignmentsDto } from './assignments.model';
import { VehicleTypeDto, SummaryDto } from '../../core/models/order.dto';
import { SessionStorageUtil } from '../../store/sessionStorage';

export enum OfferType {
    Any = 1,
    Exclusive = 2,
    DriversPool = 3
}

/**
 * This is extended to create definitions for app state and PUT/POST.
 */
export interface IOrderBase {
    id?: number;
    shipperContactId: any;
    referenceId: string;
    deliveryType: number;
    offerType: number;
    isDirect: boolean;
    isExtendable: boolean;
    isPriceChangeAllowed?: boolean;
    isScheduledOrder: boolean;
    total: number;
    assignments?: AssignmentsDto;
    vehicleType?: VehicleTypeDto;
    orderSummary?: SummaryDto;
    statusId?: number;
    code?: string;
    scheduledOrderOfferTime?: string;
    submitOrder?: boolean;
}

/**
 * Keep items and stops at the same level inside order instead of nesting stops in items so it's easier to work with.
 */
export interface IOrderAppState extends IOrderBase {
    stops: Array<StopDto>;
    orderItems: Array<OrderItemBase>;
}

/**
 * Nest stops inside items for data transfer.
 */
export interface OrderDto extends IOrderBase {
    orderItems: Array<OrderItemDto>;
}

/**
 * Get initial order state for application state.
 */
export function getInitialOrderState(useDefault = false): IOrderAppState  {
    const instance = SessionStorageUtil.getInstance();
    return {
        shipperContactId: instance.get('builder.order.shipperContactId', null, useDefault),
        referenceId: instance.get('builder.order.referenceId', null, useDefault),
        deliveryType: instance.get('builder.order.deliveryType', 1, useDefault),
        offerType: instance.get('builder.order.offerType', 1, useDefault),
        isDirect: instance.get('builder.order.isDirect', false, useDefault),
        isExtendable: instance.get('builder.order.isExtendable', false, useDefault),
        isScheduledOrder: instance.get('builder.order.isScheduledOrder', useDefault),
        total: instance.get('builder.order.total', 0, useDefault),
        stops: instance.get('builder.order.stops', [getInitialStopState({ type: StopType.Pickup, sequenceNumber: 0})], useDefault),
        orderItems: instance.get('builder.order.orderItems', [], useDefault),
        id: instance.get('builder.order.id', null, useDefault),
        assignments: instance.get('builder.order.assignments', null, useDefault),
        vehicleType: instance.get('builder.order.vehicleType', null, useDefault),
        orderSummary: instance.get('builder.order.orderSummary', null, useDefault),
        scheduledOrderOfferTime: instance.get('builder.order.scheduledOrderOfferTime', null, useDefault),
        submitOrder: instance.get('builder.order.submitOrder', true, true)
    };
}
