export class CreditCardDto {
    name: string;
    cardNumberHash: string;
    expiresMonth: number;
    expiresYear: number;
    cardHolder: string;
    storedAccountId: any;
    cardTypeId: number;
    id?: any;
    isDefault?: boolean;
}

export enum CreditCardTypes {
    Undefined = 0,
    Visa = 1,
    MasterCard = 2,
    Discover = 3,
    Amex = 4,
}

export interface T3PROAuth {
    sessionId: string;
    customerId: string;
    contactId: string;
    accountName?: string;
}

export enum msgTypes {
    ValidateCC,
    AuthRequest,
    Authorization,
    SaveCC,
    Error,
    Default
}
