<div class="summary-bar" [ngClass]="{'symmaryLoading': isLoading}">
    <div class="item loader">
        <div class="number"><span *ngIf="!isLoading">{{ orderSummary.items || 0}}</span></div>
        <div class="text" *ngIf="!isLoading" [translate]="'tuya-shipment.footer.labels.items'"></div>
    </div>
    <div class="item loader">
        <div class="number"><span *ngIf="!isLoading">{{ orderSummary.stops || 0}}</span></div>
        <div class="text" *ngIf="!isLoading" [translate]="'tuya-shipment.footer.labels.stops'"></div>
    </div>
    <div class="item loader">
        <div class="number"><span *ngIf="!isLoading">{{ (orderSummary.lbs || 0) | number : '.0-2' }}</span></div>
        <div class="text" *ngIf="!isLoading" [translate]="'tuya-shipment.footer.labels.lb'"></div>
    </div>
    <div class="item loader">
        <div class="number miles"><span *ngIf="!isLoading">{{ orderSummary.miles || 0 | number: '1.0-1' }}</span></div>
        <div class="text" *ngIf="!isLoading" [translate]="'tuya-shipment.footer.labels.miles'"></div>
    </div>
    <div class="item loader">
        <div class="number"><span *ngIf="!isLoading">{{ orderSummary.returns || 0}}</span></div>
        <div class="text" *ngIf="!isLoading" [translate]="'tuya-shipment.footer.labels.returns'"></div>
    </div>
</div>