import { AddressDto } from './dto';

export enum ShipmentType {
    Distribution = 1, // 'Distribution',
    Collection = 2 // 'Collection'
}

export enum PickupDateType {
    Today = 1,
    Tomorrow = 2
}

export class Shipment {
    type: ShipmentType | null;
    location: AddressDto | null;
    bar: string;
}
