import { OrderHelper } from './../../utils/order-helper.utils';
import { ItemUtils } from './../../../core/utils/item-utils';
import { OrderItemVo } from './../../models/order.model';
import { AfterContentInit, Component, Input } from '@angular/core';
import { Stop, StopType } from '../../models/order.model';
import { ShipmentType } from '../../../core/models/shipment.model';


@Component({
  selector: 'tuya-summary-screen',
  templateUrl: './summary-screen.component.html',
  styleUrls: ['./summary-screen.component.scss']
})

export class SummaryScreenComponent implements AfterContentInit {
  public StopType: typeof StopType = StopType;
  public OrderHelper: typeof OrderHelper = OrderHelper;
  @Input() orderType;
  @Input() set stopList(value: Array<Stop>) {
    if (this.orderType === ShipmentType.Collection) {
      const pickupStops = value.filter(stop => stop.typeId === StopType.Pickup),
            deliveryStop = value.find(stop => stop.typeId === StopType.Delivery);
      if (deliveryStop) {
        pickupStops.push(deliveryStop);
      }
      this._stopList = pickupStops;
    } else {
      value.sort(function(a, b) {
        return +(a.sequenceNumber > b.sequenceNumber) || +(a.sequenceNumber === b.sequenceNumber) - 1;
      });
      this._stopList = value;
    }
  }
  public totalReturnItemsCount = 0;
    @Input() set stopItems(value: OrderItemVo[]) {
        this._stopItems = value;
        const totalItemsDetails = ItemUtils.getItemsDetails(value);
        this.totalReturnItemsCount = totalItemsDetails.returnCount;
    }
    get stopItems () {
        return this._stopItems;
    }

  get stopList(): Array<Stop> {
    return this._stopList;
  }
  private _stopItems: OrderItemVo[];
  private _stopList: Array<Stop>;

  constructor() { }

  ngAfterContentInit() {
  }

  public getStopItemsDetails(stop: Stop) {
    switch (stop.typeId) {
      case StopType.Return: {
        return {
          weight: 0,
          count: this.totalReturnItemsCount
        };
      }
      case StopType.Pickup:
      case StopType.Delivery: {
          const itemDetails = this.getCurrentStopItems(stop);
          return {
              weight: itemDetails.weight,
              count: itemDetails.count,
          };
      }
      case StopType.DeliveryWithReturn: {
        const itemDetails = this.getCurrentStopItems(stop);
          return {
          weight: itemDetails.weight,
          count: itemDetails.count,
          returnCount: itemDetails.returnCount
        };
      }

      default: {
        return true;
      }
    }
  }

  private getCurrentStopItems(stop) {
      const currentStopItems = [];
      this.stopItems.filter(items => items.stopIds
          .findIndex(ind => ind === stop.id) !== -1 ? currentStopItems.push(items) : null);
      return  ItemUtils.getItemsDetails(currentStopItems);
  }

}
