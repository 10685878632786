import { Action } from 'redux';

import { DeliveryStopAction, OPEN_NEW_DELIVERY_STOP, SAVE_DELIVERY_STOP_ITEMS } from '../actions/delivery-stop.actions';


export const initialState: any = {
};


export function deliveryReducer(state = initialState, a: Action): any {
    const action = a as DeliveryStopAction;
    switch (action.type) {
        case OPEN_NEW_DELIVERY_STOP: {
            return {
                ...state
            };
        }

        case SAVE_DELIVERY_STOP_ITEMS: {
            return {
                ...state,
                itemList: action.payload
            };
        }

        default: {
            return state;
        }
    }
}
