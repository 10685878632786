import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SearchOrderFilter } from '../../../home/model/search-order.model';

@Component({
  selector: 'tuya-filter-view',
  templateUrl: './filter-view.component.html',
  styleUrls: ['./filter-view.component.scss']
})
export class FilterViewComponent implements OnInit {

  @Input() filterItems: SearchOrderFilter[];
  @Output() onRemoveFilterItem = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {}

  removeFilter(indx) {
      this.onRemoveFilterItem.emit(indx);
  }

  trackByFn(index, item) {
    return item.id;
  }

}
