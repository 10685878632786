<div tuyaBackgroundColor class="wrapper">
    <tuya-admin-header
        *ngIf="(adminProfile$ | async) && (adminProfile$ | async).isAdmin"
        [adminProfile]="adminProfile$ | async">
    </tuya-admin-header>
    <tuya-header
        *ngIf="!isHideHeader()"
        [loggedIn]="loggedIn$ | async"
        [adminProfile]="adminProfile$ | async"
        [currentRouter]="currentRouter$ | async"
        [shipperStatus]="shipperStatus$ | async"
        [showSuspendAlert]='showSuspendAlert$ | async'
        [shipperEntityLocation]='mapDefaultCenter$ | async'
        (logout)="logout()">
    </tuya-header>
    <div class="container-fluid">
        <div class="col-sm-12 col-xs-12">
            <router-outlet></router-outlet>
            <tuya-alert [alert]="alert$"></tuya-alert>
            
        </div>
    </div>
    <tuya-notification-list [itemList]="itemList$ | async"
                            (deleteItem)="onDeleteNotification($event)"
                            (openOrderDetails)="onOpenOrderDetails($event)"
    ></tuya-notification-list>
</div>

