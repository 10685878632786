import { LOAD_STATES_SUCCESS, StatesAction } from './../actions/states.actions';
import { Action } from 'redux';


export const initialState = [];


export function statesReducer(state = initialState, a: Action): any[] {
    const action = a as StatesAction;
    switch (action.type) {
        case LOAD_STATES_SUCCESS: {
            return action.payload;
        }

        default: {
            return state;
        }
    }
}

