import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateHelper } from '../../utils/dateHelper';

@Component({
    selector: 'tuya-popup-timepicker',
    styleUrls: ['./popup-timepicker.component.scss'],
    templateUrl: './popup-timepicker.component.html'
})

export class PopupTimepickerComponent implements OnChanges, AfterViewInit {

    public pickerCtrl = new UntypedFormControl('', (control: UntypedFormControl) => {
        const value = control.value;
        if (value && !this.isEditMode) {
            this.setControlsForTimePicker(value);
        }
        return this.validateTimeFrame(value);
    });

    public localValue: Date = null;

    changeTime = DateHelper.changeTime;

    @ViewChild('timepicker', { static: true }) timepicker;

    @Output() public valueChange = new EventEmitter<Date | null>();
    @Output() public invalid = new EventEmitter();
    @Output() public showPopupChange = new EventEmitter();

    @Input() minDateError: string;

    @Input()
    set value(val: Date) { this._value = val; }
    get value() { return this._value; }

    @Input()
    set showPopup(val: boolean) { this._showPopup = val; }
    get showPopup() { return this._showPopup; }

    @Input() public minDate: Date = null;
    @Input() public maxDate: Date = null;
    @Input() public dateDisabled: any[] = [];
    @Input() isEditMode = false;

    private _value: Date;
    private _showPopup = false;
    private lastValidTime: Date;
    private minuteStep = 5;
    private hourStep = 1;
    private hoursPerDayHalf = 12;

    constructor(private cdRef: ChangeDetectorRef) { }

    public ngOnChanges(changes: any) {
        if (typeof changes.value === 'undefined') {
            return;
        }
        // // user maybe typing a value into an input box, so would come in as string
        if (typeof this.value === 'string') {
            // check if the string is a valid date
            if (!isNaN(new Date(this.value).getTime())) {
                this.localValue = new Date(this.value);
                this.lastValidTime = new Date(this.value);
            }
        } else if (this.value) {
            this.localValue = this.value;
            this.lastValidTime = this.value;
            this.cdRef.detectChanges();
        }
    }

    /* We should handle bootstrap custom store for adjust controls */
    public ngAfterViewInit() {
        this.timepicker._store.select(state => state.controls)
            .subscribe(() => {
                if (!this.isEditMode) {
                    this.setControlsForTimePicker(this.localValue);
                }
            });
    }

    public offClick() {
        if (this.showPopup) {
            // Close invalid popup and set value to valid;
            if (!this.isEditMode && (this.pickerCtrl.errors || this.localValue === null)) {
                this.invalid.emit();
                this.resetTimepicker();
            }
            this.valueChange.emit(this.localValue);
            this.hideTimepicker();
        }
    }

    public tryToSubmit() {
        setTimeout(() => this.offClick(), 100);
    }


    private hideTimepicker() {
        this.showPopup = false;
        this.showPopupChange.emit(false);
    }

    // Validate time from time picker;
    private validateTimeFrame(value: Date): null | { wrongNumbers?: boolean; minDateBound?: boolean; outOfRange?: boolean } {
        if (!value) return { wrongNumbers: true }; // Indicate wrong numbers if value is empty

        if (this.isEditMode) {
            this.lastValidTime = value;
            return null; // No errors in edit mode
        }

        // Check if the value exceeds the minimum or maximum date bounds
        if (this.minDate && value < this.minDate) return { minDateBound: true }; // Indicate if value is below the minimum date
        if (this.maxDate && value > this.maxDate) return { outOfRange: true };   // Indicate if value is above the maximum date

        // If no errors found, update last valid time and return null
        this.lastValidTime = value;
        return null;
    }


    /* We have to reset validation and set last valid value */
    private resetTimepicker() {
        // Reset validation of the time picker
        this.timepicker.resetValidation();

        // Set the time picker value to the last valid time
        this.localValue = this.lastValidTime;

        // Render the time picker with the last valid time
        this.timepicker._renderTime(this.localValue);
    }

    /* Handle min max value, disabled controls out of range */
    private setControlsForTimePicker(value) {
        if (!value) { return; }

        this.setControlsForMinDate(value);
        this.setControlsForMaxDate(value);
    }

    private setControlsForMaxDate(value) {
        const _newHour = this.changeTime(value, { hour: this.hourStep });
        const _newMinutes = this.changeTime(value, { minute: this.minuteStep });

        this.timepicker.canIncrementHours = this.maxDate > _newHour;
        this.timepicker.canIncrementMinutes = !this.timepicker.canIncrementHours || this.maxDate >= _newMinutes;
        this.timepicker.canToggleMeridian = value.getHours() < this.hoursPerDayHalf && this.changeTime(value, { hour: this.hoursPerDayHalf }) < this.maxDate;
    }


    private setControlsForMinDate(value) {
        const _newHour = this.changeTime(value, { hour: -this.hourStep });
        const _newMinutes = this.changeTime(value, { minute: -this.minuteStep });

        this.timepicker.canDecrementHours = this.minDate < _newHour;
        this.timepicker.canDecrementMinutes = !this.timepicker.canDecrementHours || this.minDate <= _newMinutes;
        this.timepicker.canToggleMeridian = value.getHours() >= this.hoursPerDayHalf && this.changeTime(value, { hour: -this.hoursPerDayHalf }) > this.minDate;
    }
}
