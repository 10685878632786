import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../models/redux.action';
import { BoxSizeDto } from '../models/dto';

export type ItemsSizesAction = Action<any, any>;

export const LOAD_ITEMS_SIZES = '[ITEMS SIZES] Loading';
export const LOAD_ITEMS_SIZES_SUCCESS = '[ITEMS SIZES] Loaded';
export const LOAD_ITEMS_SIZES_FAIL = '[ITEMS SIZES] Failed';

@Injectable()
export class ItemsSizesActions {
    @dispatch()
    loadItemsSizes = (): ItemsSizesAction => ({
        type: LOAD_ITEMS_SIZES,
        payload: null
    })

    loadItemsSizesSuccess = (itemsSizes: BoxSizeDto[]): ItemsSizesAction => ({
        type: LOAD_ITEMS_SIZES_SUCCESS,
        payload: itemsSizes
    })

    loadItemsSizesFail = (error: any): ItemsSizesAction => ({
        type: LOAD_ITEMS_SIZES_FAIL,
        payload: null,
        error
    })
}
