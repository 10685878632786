<div class="search-input-container">
    <form id="search-form"
            [formGroup]="searchForm"
            autocomplete="off">
        
            <input type="text"
            placeholder="{{placeholder}}"
            name="searchQuery"
            class="search-input"
            formControlName="searchQuery"
            (focus)="onFocus()"
            required
            #searchQuery
            autocomplete="off">
        
            <input type="reset"
            value=""
            class="clearBtn"
            alt="clear"
            id="clearButton" />
        
            <button type="submit"
            class="search-btn"
            (click)="onSearch(searchQuery.value)">
                <div class="magnifying-glass"></div></button>
    </form>
</div>
