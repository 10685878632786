import { Component, HostListener, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { NgRedux, select, select$ } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UIActions } from '../../actions/ui.actions';
import { ShipmentActions } from '../../actions/shipment.actions';
import { ShipmentSelectors } from '../../selectors/shipment.selectors';
import { WizardStep } from '../../models/ui.model';
import { LatLng, Marker } from '../../../core/models/map.model';
import { OrderSelectors } from '../../selectors/order.selectors';
import { OrderTransforms } from '../../transforms/order.transforms';
import { EditConfirmPageComponent } from '../../../shipment-edit/containers/edit-confirm/edit-confirm.page';
import { AssignedDriverDto, OrderEditMode } from '../../../core/models/order.dto';
import { ShipmentEditSelectors } from '../../../shipment-edit/selectors/shipment-edit.selectors';
import { IAppState } from '../../../store/model';
import { OrderStatusId, PriceOptimizationDto } from '../../../core/models/dto';
import { ShipmentEditActions } from '../../../shipment-edit/actions/shipment-edit.actions';
import { ShipmentType } from './../../../core/models/shipment.model';
import { AlertSelectors } from '../../../core/selectors/alert.selectors';
import { Alert } from '../../../core/models/alert.model';

@Component({
    templateUrl: './shipment.page.html',
    styleUrls: ['./shipment.page.scss']
})

export class ShipmentPageComponent implements OnInit, OnDestroy {

    // general
    WizardStep: typeof WizardStep = WizardStep;

    ACTION_BOTTOM_BAR_RULES = {
        [WizardStep.Initial]:
        {
            actionBarStep: 0, message: 'tuya-shipment.action-bottom-bar.enter-pickup',
            buttonLabel: 'tuya-shipment.btn.proceed-to-details'
        },
        [WizardStep.InitialStopEdit]:
        {
            actionBarStep: 0, message: 'tuya-shipment.action-bottom-bar.enter-pickup',
            buttonLabel: 'tuya-shipment.btn.continue'
        },
        [WizardStep.Stops]:
        {
            actionBarStep: 1, message: 'tuya-shipment.action-bottom-bar.enter-stop',
            buttonLabel: 'tuya-shipment.btn.proceed-to-drivers-selection'
        },
        [WizardStep.EditStopDetails]:
        {
            actionBarStep: 1, message: 'tuya-shipment.action-bottom-bar.enter-delivery',
            buttonLabel: 'tuya-shipment.btn.proceed-to-drivers-selection'
        },
        [WizardStep.SummaryAndDriverAssignment]:
        {
            actionBarStep: 2, message: 'tuya-shipment.action-bottom-bar.enter-driver',
            buttonLabel: 'tuya-shipment.btn.proceed-to-payments'
        },
        [WizardStep.SummaryAndPaymentOrder]:
        {
            actionBarStep: 2, message: 'tuya-shipment.action-bottom-bar.enter-payment',
            buttonLabel: 'tuya-shipment.btn.submit'
        },
        [WizardStep.Confirmation]:
        {
            actionBarStep: 2, message: 'tuya-shipment.action-bottom-bar.enter-payment',
            buttonLabel: 'tuya-shipment.btn.submit'
        }
    };
    ACTION_BAR_STEP_LIST = [
      'INITIAL',
      'SHIPMENT_BUILDER',
      'SUMMARY'
    ];

    @select(ShipmentSelectors.wizardStepSelector)
    wizardStep$: Observable<number>;
    @select (ShipmentSelectors.continueAllowedMapSelector)
    continueAllowedMap$;
    @select(OrderSelectors.orderTotalSelector)
    total$: Observable<number>;
    @select(ShipmentSelectors.priceSelector)
    priceOpt$: Observable<PriceOptimizationDto>;
    @select$(OrderSelectors.orderSelector, OrderTransforms.transformOrderToMarkerList)
    markerList$: Observable<Marker[]>;
    @select(ShipmentSelectors.mapCenterSelector)
    mapCenter$: Observable<LatLng>;
    @select(OrderSelectors.selectedStopIdSelector)
    selectedStopId$: Observable<any>;
    @select(ShipmentSelectors.disabledRefreshBtnSelector)
    disabled$: Observable<boolean>;
    @select(OrderSelectors.assignedDriversSelector)
    drivers$: Observable<AssignedDriverDto>;
    @select(OrderSelectors.isHasValidCreditCard)
    isHasValidCreditCard$: Observable<boolean>;
    @select (OrderSelectors.orderDeliveryTypeSelector)
    deliveryTypeSelector$: Observable<number>;
    @select(AlertSelectors.alertSelector)
    alertSelector$: Observable<Alert>;
    error: any = null;
    wizardStep = new BehaviorSubject<number>(0);
    dialogRef: BsModalRef;
    @ViewChild('errorDialog', {static: true}) errorDialog: TemplateRef<any>;
    constructor(private ngRedux: NgRedux<IAppState>,
                private shipmentActions: ShipmentActions,
                private shipmentEditActions: ShipmentEditActions,
                private uiActions: UIActions,
                private modalService: BsModalService,
                private location: Location,
                private ref: ChangeDetectorRef) {

        this.wizardStep$.subscribe(this.wizardStep);
        this.isHasValidCreditCard$.subscribe(res => this.setTitleForDriverStep(res));
        this.deliveryTypeSelector$.subscribe(type => this.setActionBottomBarMessage(type));
        this.alertSelector$.subscribe(alert => this.onErrorModal(alert));
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        const wizardStep = this.wizardStep.getValue();
        if (wizardStep !== WizardStep.Initial) { // don't show modal window, because logout logic handles earlier, than redirect
            return $event.returnValue = 'Do you want to reload this site? Changes you made may not be saved';
        }
    }

    ngOnInit() {}

    ngOnDestroy() {
        // remove listener from onErrorModal method
        this.ref.detach();
    }
    onStepContinue() {
        const step = this.wizardStep.getValue();
        if (this.orderChangedStatement(step)) {
            const isOrderChanged = ShipmentEditSelectors.selectIsOrderChanged(this.ngRedux.getState());
            if (isOrderChanged) {
                this.modalService.show(EditConfirmPageComponent, {ignoreBackdropClick: true});
                return;
            }
        }
        this.uiActions.onWizardStepContinue(step);
    }

    onAskOrderCancel(template: TemplateRef<any>) {
        this.dialogRef =
            this.modalService.show(template, { backdrop: false, ignoreBackdropClick: true });

    }

    onErrorModal(alert) {
        if (alert && alert.message) {
            this.error = alert;
            if (!this.ref['destroyed']) {
                // if not destroyed, go ahead and detect changes
                this.ref.markForCheck();
            }
        } else {
            this.error = null;
        }
    }

    showEditLimitationBar() {
        const wizardValue = this.wizardStep.getValue();
        const allowedSteps = [
            WizardStep.InitialStopEdit,
            WizardStep.Stops,
            WizardStep.EditStopDetails,
            WizardStep.SummaryAndDriverAssignment];
        const stepCondition = allowedSteps.includes(wizardValue);
        const editModeCondition = ShipmentEditSelectors.selectEditMode(this.ngRedux.getState());
        const orderStatus = OrderSelectors.orderSelector(this.ngRedux.getState()).statusId;
        const orderAssignedCondition = (orderStatus > OrderStatusId.Offered) &&
            (orderStatus !== OrderStatusId.Released) &&
            (orderStatus !== OrderStatusId.Declined);
        return stepCondition && editModeCondition && orderAssignedCondition;
    }

    onOrderReset() {
        this.shipmentActions.manualOrderReset();
    }

    onOrderDiscard() {
        const orderId = OrderSelectors.orderIdSelector(this.ngRedux.getState());
        this.shipmentEditActions.editDiscardOrder(orderId);
    }

    private setTitleForDriverStep(res) {
        if (res) {
            this.ACTION_BOTTOM_BAR_RULES[WizardStep.SummaryAndDriverAssignment].buttonLabel = 'tuya-shipment.btn.submit';
        } else {
            this.ACTION_BOTTOM_BAR_RULES[WizardStep.SummaryAndDriverAssignment].buttonLabel = 'tuya-shipment.btn.proceed-to-payments';
        }
    }
    private setActionBottomBarMessage(type) {
        if (type === ShipmentType.Collection) {
            this.ACTION_BOTTOM_BAR_RULES[WizardStep.Stops].message = 'tuya-shipment.action-bottom-bar.enter-final-delivery';
        }
    }

    private orderChangedStatement(step: WizardStep): boolean {
        const editMode = ShipmentEditSelectors.selectEditMode(this.ngRedux.getState());
        // const hasValidCC = OrderSelectors.isHasValidCreditCard(this.ngRedux.getState());
        // Set hasValidCC to true for now until we're handling cases where there is no credit card number.
        const hasValidCC = true;
        return hasValidCC ? step === WizardStep.SummaryAndDriverAssignment && editMode !== OrderEditMode.NoEdit :
            step ===  WizardStep.SummaryAndPaymentOrder && editMode !== OrderEditMode.NoEdit;
    }

}
