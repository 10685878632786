<div *ngIf="WizardStep.SummaryAndDriverAssignment === (wizardStep$ | async)">
    <tuya-general-offer
            [pools]="pools$ | async"
            [orderEditMode]="orderEditMode$ | async"
            [selectedCandidates]="selectedCandidates$ | async"
            [driverAssignmentType]="driverAssignmentType$ | async"
            [priceOptimizationData]="priceOptimizationData$ | async"
            [total]="total$ | async"
            [orderStopList]="orderStopList$ | async"
            [drivers]="drivers$ | async"
            [isDriversLoading]="isDriversLoading$ | async"
            [vehicleName]="(vehicleType$ | async)?.name"
            [isExtendable]="isExtendable$ | async"
            (driverSearch)="onDriverSearch($event, vehicleType?.id)"
            (driverTypeOffer)="onDriverOffer($event)"
            (hidePriceOptimizationModal)="onHidePriceOptimizationModal()"
            (setExtendable)="onSetExtendableProperty($event)"
            (editOrder)="onEditOrder()"
    ></tuya-general-offer>
    <ng-container *ngTemplateOutlet="summaryBar"></ng-container>
</div>

<div *ngIf="WizardStep.SummaryAndPaymentOrder === (wizardStep$ | async)">
    <tuya-payment-order
            (paymentOrderSubmit)="onPaymentOrder($event)"
            (paymentOrderSkip)="onPaymentOrderSkip()"
            (paymentOrderSubmitErrors)="onPaymentOrderErrors()"
            [isValidate]="isValidate$ | async"
    ></tuya-payment-order>
    <ng-container *ngTemplateOutlet="summaryBar"></ng-container>
</div>

<div *ngIf="WizardStep.Confirmation === (wizardStep$ | async)">
  <tuya-order-confirm
            [orderId]="orderId$ | async"
            [orderCode]="orderCode$ | async"
            (newOrderCreate)="onNewOrderCreate($event)"
  ></tuya-order-confirm>
</div>

<ng-template #summaryBar>
  <div class="sidebar right-alignment">
        <div class="flex">
            <tuya-shipment-header [headerFirstLevel]="'Order Summary'"
                                  [editMode]="orderEditMode$ | async"
                                  [shipmentType]="orderDeliveryType$ | async"
                                  [isDirectOrder]="isDirectOrder$ | async"
                                  [orderRefID]="orderRefID$ | async"
                                  [orderEditRefID]="orderEditRefID$ | async"
                                  (directOrderUpdate)="onSetIsDirectOrder($event)"></tuya-shipment-header>
            <div class="sidebar-content">

                <tuya-summary-screen
                    [stopList]="orderStopList$ | async"
                    [stopItems]="orderItemList$ | async">
                </tuya-summary-screen>

                <button class="btn-link edit" (click)="uiSetWizardStep(WizardStep.Stops)"
                        [translate]="'tuya-shipment.order-summary.edit-stops'"></button>
            </div>
            <tuya-shipment-footer
                [miles]="miles$ | async"
                [orderSummary]="orderSummary$ | async"
                [isLoading]="isLoadingOrderDetails$ | async"
            ></tuya-shipment-footer>
        </div>
  </div>
</ng-template>
<tuya-spinner *ngIf="isLoading$ | async" size="fullscreen"></tuya-spinner>