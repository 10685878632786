import { DeactivateCompanyGuard } from './guards/deactivate-company.guard';
import { DeactivateProfileGuard } from './guards/deactivate-profile.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './guards/auth.guard';
import { AuthenticationService } from './services/authentication.service';
import { LoginComponent } from './containers/login/login.component';
import { ForgotPasswordComponent } from './containers/forgot-password/forgot-password.component';
import { CoreModule } from '../core/module';
import { AuthActions } from './actions/auth.actions';
import { AuthEpics } from './epics/auth.epics';
import { ShipperActions } from '../shipper/actions/shipper.status.actions';
import { ChooseNewPasswordComponent } from './components/choose-new-password/choose-new-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

import { TranslateService } from '@ngx-translate/core';
import { TranslationLoaderService } from '../core/services/translation-loader.service';
import { locale as english } from './i18n/auth.en';
import { SharedModule } from '../shared/module';
import { OrdersListGuard } from './guards/orders-list.guard';
import { LogoutComponent } from './containers/logout/logout.component';
import { ForgotPasswordEpics } from './epics/forgot-password.epics';
import { ForgotPasswordGuard } from './guards/forgot-password.guard';
import { LoginGuard } from './guards/login.guard';

export const COMPONENTS = [LoginComponent, LogoutComponent, ForgotPasswordComponent,
    ChooseNewPasswordComponent, ResetPasswordComponent];

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        ReactiveFormsModule,
        CoreModule,
        FormsModule,
        SharedModule],
    declarations: COMPONENTS,
    exports: COMPONENTS,
    providers: [AuthActions, AuthEpics, ForgotPasswordEpics, AuthenticationService, ShipperActions, AuthGuard,
        OrdersListGuard, DeactivateProfileGuard, DeactivateCompanyGuard, ForgotPasswordGuard, LoginGuard]
})
export class AuthModule {
    constructor(private translate: TranslateService,
                private translationLoader: TranslationLoaderService) {
        /* Config translation in root module */
        this.translate.addLangs(['en']);
        this.translate.setDefaultLang('en');
        this.translate.use('en');
        /* Load translations in each module/component as needed */
        this.translationLoader.loadTranslations(english);
    }
}
