import { Action } from 'redux';
import {
    AccountValidationResultVO,
    CompanyValidationResultVO,
    IFormRegisterState,
    RegisterFormStep
} from '../model/model';
import {
    STEPBACK_REGISTER_FORM,
    CLEAR_FORM,
    SUBMIT_REGISTER_FORM,
    SUBMIT_REGISTER_FORM_ERROR,
    SUBMIT_REGISTER_FORM_SUCCESS,
    VALIDATE_ACCOUNT_FORM,
    VALIDATE_ACCOUNT_FORM_ERROR,
    VALIDATE_ACCOUNT_FORM_SUCCESS,
    VALIDATE_COMPANY_FORM,
    VALIDATE_COMPANY_FORM_ERROR,
    VALIDATE_COMPANY_FORM_SUCCESS,
    ValidateAccountRegisterAction,
    ValidateCompanyRegisterAction
} from '../actions/register.actions';
import { TURN_OFF_ALL_SPINNERS } from '../../core/actions/alert.actions';


export const initialState: IFormRegisterState = {
    formStep: RegisterFormStep.Init,
    myAccountForm: {},
    myAccountFormValidationResult: null,
    companyInfoForm: {},
    companyInfoFormValidationResult: null,
    isLoading: false
};


export function formsReducer(state = initialState, a: Action): IFormRegisterState {
    switch (a.type) {

        case CLEAR_FORM: {
            return initialState;
        }

        case STEPBACK_REGISTER_FORM: {
            return {
                ...state,
                formStep: RegisterFormStep.Init
            };
        }

        case VALIDATE_ACCOUNT_FORM: {
            return {
                ...state,
                isLoading: true
            };
        }

        case VALIDATE_ACCOUNT_FORM_SUCCESS: {
            const action = a as ValidateAccountRegisterAction;
            const result: AccountValidationResultVO = <AccountValidationResultVO>action.payload;
            return {
                ...state,
                myAccountFormValidationResult: result,
                formStep: result.isValid ? RegisterFormStep.AccountValidated : state.formStep,
                isLoading: false
            };
        }

        case VALIDATE_ACCOUNT_FORM_ERROR: {
            return {
                ...state,
                isLoading: false
            };
        }

        case VALIDATE_COMPANY_FORM: {
            return {
                ...state,
                isLoading: true
            };
        }

        case VALIDATE_COMPANY_FORM_SUCCESS: {
            const action = a as ValidateCompanyRegisterAction;
            const result: CompanyValidationResultVO = <CompanyValidationResultVO>action.payload;
            return {
                ...state,
                companyInfoFormValidationResult: result,
                myAccountFormValidationResult: null,
                formStep: result.isValid ? RegisterFormStep.CompanyValidated : state.formStep,
                isLoading: false
            };
        }

        case VALIDATE_COMPANY_FORM_ERROR: {
            return {
                ...state,
                isLoading: false
            };
        }

        case SUBMIT_REGISTER_FORM_SUCCESS: {
            return initialState;
        }

        case SUBMIT_REGISTER_FORM: {
            return {
                ...state,
                isLoading: true
            };
        }

        case SUBMIT_REGISTER_FORM_ERROR: {
            return {
                ...state,
                isLoading: false
            };
        }

        case TURN_OFF_ALL_SPINNERS: {
            return {
                ...state,
                isLoading: false
            };
        }

        default: {
            return state;
        }
    }
}

