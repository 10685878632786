export const locale = {
    'lang': 'en',
    'data': {
        'tuya-shipment': {
            'unable-change': 'Order in Progress - Unable to Change',
            'edit-limit': 'has already started working on your order. As a result, some changes are not possible',
            'initial-step': {
                'not-in-msa-message': 'We are rapidly expanding our service areas but have not reached this location yet. ' +
                'Please check back soon.',
                'ok-btn': 'Ok'
            },
            'header': {
                'add-ref-id': 'Add Order Reference ID',
                'order-ref-id': 'Order Ref ID:'
            },
            'network-error-modal': {
                'text1': 'We\'re sorry!',
                'text2': 'The server encountered an internal error or misconfiguration and was unable to complete your request.',
                'text3': 'Please contact the server administrator for more information.',
                'server-error': 'Internal Server Error'
            },
            'leaving-unfinished-dialog': {
                'message': 'Are you sure you want to leave? Your changes will not be saved.',
                'ok': 'Ok',
                'cancel': 'Cancel'
            },
            'price-optimization-dialog': {
                'optimizing-route': 'Optimizing your Routes...',
                'title': 'We optimized your order and saved you',
                'message-text': 'We optimized your order according to the time constraints and locations you provided and saved you money!',
                'message-text-well-done': 'Well done - your order is already optimized ' +
                'for efficiency given the stops and time constraints you provided!',
                'price-changed': 'The price of this order has changed!',
                'thanks-button': 'Thanks',
                'ok-button': 'Ok'
            },
            'price-optimization-error-modal': {
                'header-text': 'Arrival time conflict',
                'message-text': 'The order cannot be completed in its entirety within the times you have requested.  ' +
                    'Specifically, following stop(s) cannot be reached:',
                'message-text-2': 'Consider adjusting the arrival times of the above stops or removing them from the ' +
                'order to construct an order that can be completed on time.',
                'edit-btn': 'Edit Order'
            },
            'reset-confirm-dialog': {
                'message': 'Clear all information and start over with a new order?',
                'ok': 'Agree',
                'cancel': 'Cancel'
            },
            'discard-changes-dialog': {
                'message': 'Do you want to discard the order changes?',
                'ok': 'Discard changes',
                'cancel': 'Cancel'
            },
            'popup-timepicker': {
                'error': {
                    'min-arrive-no-earlier': 'All times must be in future',
                    'min-arrive-no-later': 'Arrive no later than time must be after Arrive no earlier time',
                    'time-out-of-range': 'Time out of range'
                }
            },
            'action-bottom-bar': {
                'enter-pickup': 'Enter Pickup Details to Continue',
                'enter-stop': 'Enter Stop Details to Continue',
                'enter-delivery': 'Enter Delivery Details to Continue',
                'enter-final-delivery': 'Enter final delivery to Continue',

                'enter-driver': 'Enter Driver Details to Submit',
                'enter-payment': 'Enter Payment Details to Continue',
                'hazard-mat': 'Cannot Ship Hazardous Materials',
                'order-total': 'ORDER TOTAL'
            },
            'shipment-builder': {
                'collection': 'collection',
                'distribution': 'distribution',
                'shipment_builder': 'shipment builder',
                'choose-shipment-type': 'What kind of shipment do you want create today?',
                'direct-order': 'Direct order',
                'add-reference-ids': '+ Add reference IDs',
                'confirm': 'confirm',
                'cancel': 'cancel',
                'not-in-texas': 'TUYA is currently only available in Texas. We are rapidly expanding, however - please check back soon.',
                'switch-to-direct-order-header': 'Switch to a Direct Shipment?',
                'switch-to-direct-order-message': 'This is the fastest available service we offer. ' +
                                    'Selecting a Direct Shipment will remove pickup/delivery arrival window times - ' +
                                    'the driver will simply arrive at each stop as fast as possible.',
                'switch-to-non-direct-order-header': 'Switch to a regular (Non-Direct) Shipment?',
                'switch-to-non-direct-order-message': 'This will assign the default arrival times for each stop.'
            },
            'order-summary': {
                'edit-stops': 'Edit Stops'
            },
            'general-offer': {
                'who-deliver': 'Who would you like to deliver your order?',
                'order-require-driver': 'Your order will require a driver with a ',
                'radio': {
                    'any-driver': 'Assign to any driver who is qualified to deliver my order.',
                    'specific-driver': 'Specific driver(s)',
                    'limited': 'Limited Offer',
                    'exclusive': 'Exclusive Offer',
                },
                'select-driver': 'Select Driver Pool(s)',
                'enter-driver': 'Enter Driver Name or Code',
                'no-matches': 'No Matches',
                're-offer': 'In this case all drivers decline offer, extend to all drivers',
            },
            'payment-order': {
                'pay-shipment': 'Please add a method of payment to your profile',
                'credit-card': 'Credit card',
                'tuya-credit': 'tuya credit terms',
                'add-new-card': 'Add a new credit card',
                'card-name': 'Name on Card *',
                'card-number': 'Card Number *',
                'exp-date': 'Expiration Date *',
                'save-card': 'Save card for future orders',
                'cvv': 'CVV *',
                'nickname': 'Nickname',
                'error-card': 'Please try a different card.',
                'error': {
                    'name-card': 'Name on card is required and should be valid.',
                    'number-card': 'card number is required and should be valid.',
                    'exp-date': 'expiration date should be in "m/yy" format.',
                    'exp-date-required': 'Expiration date is required.',
                    'exp-date-not-valid': 'Please enter a valid date',
                    'cvv': 'cvv required (3 or 4 digit)'
                }
            },
            'address-input-placeholder': 'Enter Stop Name or Address',
            'address-required': 'Address is required',
            'choose-address': 'Choose address from list',
            'confirm-order': {
                'success-ok': 'Success!',
                'order-submitted': 'Your order has been successfully submitted.',
                'order-number': 'ORDER NUMBER',
                'btn': {
                    'new-order': 'create new order',
                    'order-details': 'view order details'
                }
            },
            'btn': {
                'continue': 'Continue',
                'submit': 'Submit',
                'proceed-to-details': 'Proceed to Details',
                'proceed-to-drivers-selection': 'Proceed to Drivers Selection',
                'proceed-to-payments': 'Submit Order'
                // will need to change this copy back to 'proceed to payment' once payment page is put back into the flow

            },
            'tuya-stop-details': {
                'headers': {
                    'date': 'Date',
                    'contact': 'Contact',
                    'items': 'Items',
                    'stop': 'Stop',
                    'notes': 'Notes'
                },
                'modals': {
                    'remove-return-stop': {
                        'delete-stop': 'Do you want us to delete a return stop and clear Return section in the Delivery stop?',
                        'ok': 'Ok',
                        'cancel': 'Cancel'
                    }
                },
                'save-details': 'Save Stop Details',
                'add-contact-details': 'Add contact phone and email',
                'add-notes': 'Add notes about this stop',
                'tell-us-about-items': 'Tell us more about these items',
                'distributionShipmentType': 'Distribution',
                'collectionShipmentType': 'Collection',
                'required': 'required',
                'optional': 'optional',
                'lbs': 'lb',
                'items': 'items',
                'delivery': 'delivery',
                'pickup': 'pickup',
                'return': 'return',
                'delivery-with-return': 'delivery-with-return',
                'dotted': 'dotted',
                'return-delivery': 'return (delivery) to pickup',
                'return-items': 'return (pickup)',
                'add-delivery': 'add-delivery',
                'defaultNameOrDescription': 'Stop Name',
                'nameOrDescription': 'ENTER STOP NAME',
                'contact-text': 'Who should we contact at this stop?',
                'whenPickup': 'When will your items be ready for pickup?',
                'whenDelivered': 'When would you like your items delivered?',
                'willBeAsapHeader': 'Items will be delivered ASAP',
                'willBeAsapText': 'To set spicified timeframes for delivery, return to the ShipmentBuilder'
                                + ' and unselect the "Direct Order" option.',
                'isDirect': 'Would you like this to be a direct shipment?',
                'isDirectHint': 'This will result in the fastest delivery but at an additional cost.',
                'isDirectYes': 'Yes, this is a rush order.',
                'isDirectNo': 'No, thanks',
                'pickupDateReminder': 'Pickup Date *',
                'arrivalDateReminder': 'Delivery Date *',
                'stopReminder': 'Stop Name *',
                'notes-text': 'Do you have notes about this delivery?',
                'notes-label': 'Notes',
                'arriveNoEarlier': 'Arrive no earlier than',
                'arriveNoLater': 'Arrive no later than',
                'name': 'Name*',
                'phone': 'Phone',
                'email': 'Email',
                'suiteNumber': 'Suite Number',
                'suiteNumberLabel': 'Suite No (optional)',
                'email-tracking': 'Email tracking link',
                'text-tracking': 'Text tracking link',
                'signatureRequired': 'Signature Required',
                'invalid-timeframe': 'Invalid time window',
                'add-timeframe-pickup': 'specify timeframe for pickup',
                'add-timeframe-delivery': 'specify timeframe for delivery',
                'continue': 'Continue',
                'error': {
                    'date': 'Pickup Date is required field',
                    'nameOrDescription': 'Stop Name/Description is required',
                    'contactName': 'Contact name is required',
                    'contact-name-pattern-error': 'Please enter a valid Contact name.',
                    'phone': 'Please enter a valid phone',
                    'email': 'Please enter a valid email',
                    'name-pattern-error': 'Stop Name/Description is invalid'
                }
            }
        },
        'tuya-delivery-stop': {
            'tuya-item-selection': {
                'text': 'What should we deliver to this stop?',
                'continue': 'Continue',
                'add-item': 'Add item',
                'add-reference': 'Add reference IDS to Stop',
                'weight': 'LB',
                'header_item': 'ITEM',
                'header_items': 'ITEMS',
                'items': 'items',
                'reference-id': 'Reference',
                'description': 'Description',
                'hazardCheckbox': 'Contains Hazardous Materials',
                'add-return': ' Add a return for this stop',
                'is-there-return': ' Is there a return at this stop?',
                'number-of-pieces': ' Number of Pieces',
                'hazardous-modal': {
                    'uh-oh': 'Uh Oh!',
                    'sorry': 'We are sorry, but we cannot accept shipments that contain hazardous materials at this time.',
                    'not-permitted': 'Shipments with Hazardous Materials are not permitted on the TUYA platform at this time.',
                    'ok-btn': 'ok'
                }
            }
        }
    }
};
