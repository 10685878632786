import { ManageCompanyTab } from './../model/model';
import { ShipperProfileDto, BusinessEntityDto } from './../../core/models/dto';
import { ProfileToEditVO } from '../../register/model/model';
import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../../core/models/redux.action';


export type ProfilesAction = Action<any, any>;

export const SAVE_SHIPPER_PROFILE = '[SHIPPER] Shipper profile save request';
export const SAVE_SHIPPER_PROFILE_SUCCESS = '[SHIPPER] Shipper profile was saved';
export const SAVE_SHIPPER_PROFILE_FAIL = '[SHIPPER]  Shipper profile save failed';

export const EDIT_SHIPPER_OR_COMPANY_PROFILE = '[SHIPPER] Edit Shipper or Company profile';
export const CHANGE_MANAGE_COMPANY_TAB = '[SHIPPER] Change manage company tab';

export const SAVE_COMPANY_PROFILE = '[SHIPPER] Company profile save request';
export const SAVE_COMPANY_PROFILE_SUCCESS = '[SHIPPER] Company profile was saved';
export const SAVE_COMPANY_PROFILE_FAIL = '[SHIPPER]  Company profile save failed';
export const HIDE_COMPANY_ERRORS = '[SHIPPER] Company errors was hided';
export const HIDE_PROFILE_ERRORS = '[SHIPPER] Shipper profile errors was hided';

export const GO_TO_COMPANY_PROFILE = '[SHIPPER] Go to profile';

@Injectable()
export class ProfilesActions {
    @dispatch()
    saveShipperProfile = (shipperProfile: ShipperProfileDto): ProfilesAction => ({
        type: SAVE_SHIPPER_PROFILE,
        payload: shipperProfile
    })

    saveShipperProfileSucceeded = (shipperProfile: ShipperProfileDto): ProfilesAction => ({
        type: SAVE_SHIPPER_PROFILE_SUCCESS,
        payload: shipperProfile
    })

    saveShipperProfileFailed = (error: any): ProfilesAction => ({
        type: SAVE_SHIPPER_PROFILE_FAIL,
        payload: null,
        error
    })

    @dispatch()
    editShipperOrCompanyProfile = (shipperProfile: ShipperProfileDto): ProfilesAction => ({
        type: EDIT_SHIPPER_OR_COMPANY_PROFILE,
        payload: shipperProfile
    })

    @dispatch()
    changeManageCompanyTab = (manageCompanyTab: ManageCompanyTab): ProfilesAction => ({
        type: CHANGE_MANAGE_COMPANY_TAB,
        payload: manageCompanyTab
    })

    @dispatch()
    saveCompanyProfile = (profile: ShipperProfileDto): ProfilesAction => ({
        type: SAVE_COMPANY_PROFILE,
        payload: profile
    })

    saveCompanyProfileSucceeded = (shipperProfile: ShipperProfileDto): ProfilesAction => ({
        type: SAVE_COMPANY_PROFILE_SUCCESS,
        payload: shipperProfile
    })

    saveCompanyProfileFailed = (error: any): ProfilesAction => ({
        type: SAVE_COMPANY_PROFILE_FAIL,
        payload: error
    })

    @dispatch()
    hideCompanyErrors = (): ProfilesAction => ({
        type: HIDE_COMPANY_ERRORS,
        payload: null
    })
    @dispatch()
    hideProfileErrors = (): ProfilesAction => ({
        type: HIDE_PROFILE_ERRORS,
        payload: null
    })

    @dispatch()
    goToCompanyProfile = (): ProfilesAction => ({
        type: GO_TO_COMPANY_PROFILE,
        payload: null
    })

}
