export interface AddressDto {
    id?: number;
    name?: string;
    addressLine: string;
    city: string;
    stateAbbr: string;
    stateName: string;
    stateId?: number;
    postalCode: string;
    country?: string;
    countryShortName?: string;
    suiteNumber?: string;
    latitude?: number;
    longitude?: number;
}

export function getInitialAddressState(): AddressDto {
    return {
        id: null,
        name: null,
        addressLine: null,
        city: null,
        stateId: null,
        stateAbbr: null,
        stateName: null,
        postalCode: null,
        country: null,
        countryShortName: null,
        suiteNumber: null,
        latitude: null,
        longitude: null,
    };
}
