<div>
    <div class="line-desc">
        <div *ngIf="isNewStop; then newStopTpl else currentStopTemplate"></div>
    </div>
</div>

<ng-template #newStopTpl>
    <div class="without-date">
        <span class="uppercase" [translate]="stopTypeName"></span>
        <span [translate]="isOptionalStopLine ? 'tuya-shipment.tuya-stop-details.optional' : 'tuya-shipment.tuya-stop-details.required'"></span>
    </div>
</ng-template>

<ng-template #currentStopTemplate>
    <span class="uppercase" [translate]="stopTypeName"></span>
    {{stop.typeId === StopType.Return ? totalItemsDetails.returnCount : totalItemsDetails.count}}
    <span class="items" [translate]="'tuya-shipment.tuya-stop-details.items'"></span>
    <span *ngIf="stop.typeId !== StopType.Return">| {{totalItemsDetails.weight}}
        <span [translate]="'tuya-shipment.tuya-stop-details.lbs'"></span>
    </span>
    <div class="return-items" *ngIf="stop.typeId === StopType.DeliveryWithReturn">
        <span class="uppercase" [translate]="'tuya-shipment.tuya-stop-details.return-items'"></span>
        <span class="items"> {{ totalItemsDetails.returnCount}}
                <span [translate]="'tuya-shipment.tuya-stop-details.items'"></span>
            </span>
    </div>
</ng-template>

