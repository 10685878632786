import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../../core/models/redux.action';
import { Stop } from '../models/order.model';
import { WizardStep } from '../models/ui.model';

export type UIAction = Action<any, any>;

export const SET_WIZARD_STEP = '[SHIPMENT UI] Set wizard step';
export const SET_WIZARD_STEP_NO_HOOKS = '[SHIPMENT UI] Set wizard step no hooks';
export const SET_STOP_DETAILS_EDIT_MODE = '[SHIPMENT UI] back button available';
export const LOADING = '[SHIPMENT UI] stop data is loading';
export const DISABLED_REFRESH = '[SHIPMENT UI] set disabled for refresh order btn';
export const TOGGLE_EDIT_NAME_MODE = '[SHIPMENT UI] toggle edit name mode';
export const INIT_STOP_EDIT = '[SHIPMENT UI] Init stop edit with stop details';
export const SET_WIZARD_STEP_CONTINUE_ALLOWED = '[SHIPMENT UI] Set wizard step continue allowed';
export const ON_WIZARD_STEP_CONTINUE = '[SHIPMENT UI] On wizard step continue';
export const SET_DEFAULT_ITEM_SIZE = '[SHIPMENT UI] Set default item size';
export const GO_TO_ORDER_DETAILS = '[SHIPMENT UI] Go to order details';
export const CALL_PAYMENT_VALIDATION = '[SHIPMENT UI] Call payment validation';

@Injectable()
export class UIActions {
    @dispatch()
    loading = (isLoading: boolean): UIAction => ({
        type: LOADING,
        payload: isLoading
    })

    @dispatch()
    callValidation = (isValidate: boolean): UIAction => ({
        type: CALL_PAYMENT_VALIDATION,
        payload: isValidate
    })

    @dispatch()
    setDisableStateForRefreshOrder = (disabled: boolean): UIAction => ({
        type: DISABLED_REFRESH,
        payload: disabled
    })

    @dispatch()
    setDefaultItemSize = (size: number): UIAction => ({
        type: SET_DEFAULT_ITEM_SIZE,
        payload: size
    })

    @dispatch()
    setWizardStep = (step: WizardStep): UIAction => ({
        type: SET_WIZARD_STEP,
        payload: step
    })
    setWizardStepNoHooks = (): UIAction => ({
        type: SET_WIZARD_STEP_NO_HOOKS,
        payload: null
    })
    @dispatch()
    setStopDetailsEditMode = (isAvailable: boolean): UIAction => ({
        type: SET_STOP_DETAILS_EDIT_MODE,
        payload: isAvailable
    })
    @dispatch()
    toggleEditNameMode = (mode: boolean): UIAction => ({
        type: TOGGLE_EDIT_NAME_MODE,
        payload: mode
    })

    @dispatch()
    initStopEdit = (details: Stop): UIAction => ({
        type: INIT_STOP_EDIT,
        payload: details
    })
    @dispatch()
    setWizardStepContinueAllowed = (step: number, allowed: boolean): UIAction => ({
        type: SET_WIZARD_STEP_CONTINUE_ALLOWED,
        payload: {step: step, allowed: allowed}
    })

    @dispatch()
    goToOrderDetails = (data: {orderId: number, recurringId?: number}): UIAction => ({
        type: GO_TO_ORDER_DETAILS,
        payload: data
    })

    @dispatch()
    onWizardStepContinue = (step: WizardStep): UIAction => ({
        type: ON_WIZARD_STEP_CONTINUE,
        payload: step
    })
}
