import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { LOAD_NOTIFICATION_BUCKET, NotificationActions } from '../actions/notification.actions';
import { NotificationService } from '../services/notification.service';
import { catchError, exhaustMap, map } from 'rxjs/operators';

@Injectable()
export class NotificationEpics {
    constructor(private notificationActions: NotificationActions,
                private notificationService: NotificationService) {
    }

    public createEpic() {
        return combineEpics(
            createNotificationBucketEpic(this.notificationActions, this.notificationService),
        );
    }
}


export function createNotificationBucketEpic(actions: NotificationActions,
                                             service: NotificationService) {
    return action$ => action$.pipe(
        ofType(LOAD_NOTIFICATION_BUCKET),
        exhaustMap((action: any) => {
            return service.getNotificationBucket(action.payload).pipe(
                map(data => actions.saveNotificationBucket(data))
                ,catchError(error => {
                    return of(actions.showErrorMessage('error1'));
                }));
        })
    );
}

