import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { IAppState } from '../../store/model';
import { AuthenticationService } from '../services/authentication.service';
import {
    AuthActions,
    CREATE_ACCESS_TOKEN,
    CREATE_ACCESS_TOKEN_FAILURE,
    GET_PERMISSION_FCM,
    GO_TO_HOME,
    HOME_PAGE_REDIRECT,
    LOGIN,
    LOGIN_FCM,
    LOGIN_REDIRECT,
    LOGIN_SUCCESS,
    LOGOUT,
    LOGOUT_FAILURE,
    LOGOUT_SUCCESS,
    REQUEST_CHANGE_PASSWORD,
    REQUEST_CHANGE_PASSWORD_SUCCESS,
    REQUEST_VERIFICATION_EMAIL,
    VALIDATE_NEW_PASSWORD,
    GO_TO_ORDERS
} from '../actions/auth.actions';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';
import { UPDATE_LOCATION } from '@angular-redux/router';
import { Router } from '@angular/router';
import { ComplexToken, PasswordValidationVO, User } from '../model/model';
import { ACCESS_TOKEN_KEY, FCM_TOKEN_KEY, REFRESH_TOKEN_KEY, TOKEN_TYPE, BEARER_AUTH } from '../../core/constants/storage.constants';
import {
    transformValidateAccountResult,
    transformValidateLoginResult
} from '../../register/transformations/validate-result.transformation';
import { ShipperActions } from '../../shipper/actions/shipper.status.actions';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, mergeMap, filter, finalize, tap, debounceTime } from 'rxjs/operators';
import { NOTIFICATION_SUBSCRIBE, NOTIFICATION_SUBSCRIBE_SUCCESS } from '../../core/actions/notification.actions';
import { isServiceWorkerSupported } from '../../core/utils/feature-detections.utils';
import { PushNotificationService } from '../../core/services/push-notification.service';
import { ShipperProfileDto } from '../../core/models/dto';
import { TranslateService } from '@ngx-translate/core';
import { NgRedux } from '@angular-redux/store';
import { environment } from 'environments/environment.prod';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const app = initializeApp(environment.firebase);
const messaging = getMessaging(app);

@Injectable()
export class AuthEpics {
    constructor(private authenticationService: AuthenticationService,
        private authActions: AuthActions,
        private shipperActions: ShipperActions,
        private alertActions: AlertActions,
        private router: Router,
        private translationService: TranslateService,
        private msg: PushNotificationService) {
    }

    public createEpic() {
        return combineEpics(
            createAuthenticateByOpenIdEpic(this.authenticationService, this.authActions, this.shipperActions),
            createAuthenticateFCMEpic(this.authActions, this.alertActions, this.msg, this.translationService),
            createGetPermissionFCMEpic(this.authActions, this.alertActions, this.msg, this.translationService),
            createsubscribeNotificationsEpic(this.msg),
            createAccessTokenFailureEpic(this.authActions),
            createLogoutFailedEpic(this.authActions),
            createLoginEpic(this.router, this.authActions),
            createLogoutEpic(this.authenticationService, this.authActions),
            createAccessTokenEpic(this.authenticationService, this.authActions),
            createRedirectHomePageEpic(this.router),
            createRedirectLoginEpic(this.router),
            createRedirectLogoutEpic(this.router),
            createRequestVerificationEmailEpic(this.authActions, this.authenticationService),
            createRequestChangePasswordEpic(this.authenticationService, this.authActions),
            createHideModalEpic(this.authActions),
            createGoToHomeEpic(this.router),
            createGoToOrdersEpic(this.router)
        );
    }
}

let isLoginProcessOngoing = false;
export function createAuthenticateByOpenIdEpic(authenticationService: AuthenticationService, actions: AuthActions, shipperActions: ShipperActions) {
    return (action$) => action$.pipe(
        ofType(LOGIN),
        debounceTime(5),
        filter(() => !isLoginProcessOngoing),
        tap(() => { isLoginProcessOngoing = true; }),
        exhaustMap((action: any) =>
            authenticationService.openIdLogin(action.payload).pipe(
                map((data: ComplexToken) => {
                    localStorage.setItem(ACCESS_TOKEN_KEY, data.access_token);
                    localStorage.setItem(REFRESH_TOKEN_KEY, data.refresh_token);
                    localStorage.setItem(TOKEN_TYPE, BEARER_AUTH);

                    const localData: User = JSON.parse(localStorage.getItem('user')) || {};

                    localData.name = action.payload['rememberMe'] ? action.payload['username'] : '';
                    localData.rememberMe = !!action.payload['rememberMe'];
                    localStorage.setItem('user', JSON.stringify(localData));

                    if (!environment.production) console.debug(LOGIN);

                    // Dispatch a plain object action
                    return shipperActions.loadProfile();
                }),
                catchError((resp) => {
                    const errorMessage = transformValidateLoginResult(resp.error);

                    if (!environment.production) console.debug('Login Error: ', errorMessage);
                    return of(actions.loginFailed(errorMessage));
                }),
                finalize(() => { isLoginProcessOngoing = false; })
            )
        )
    );
}

let isLoginFCMProcessOngoing = false;
export function createAuthenticateFCMEpic(actions: AuthActions, alertActions: AlertActions, msg: PushNotificationService, translationService: TranslateService) {
    return (action$) => action$.pipe(
        ofType(LOGIN_FCM),
        debounceTime(5),
        filter(() => !isLoginFCMProcessOngoing),
        tap(() => { isLoginFCMProcessOngoing = true; }),
        exhaustMap((action: any) => {
            // Skip FCM for local development
            if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
                if (!environment.production) console.debug('Running locally. Skipping FCM.');
                isLoginFCMProcessOngoing = false;
                return of(actions.login(action.payload)); // normal login without FCM
            }

            // Skip FCM if Service Worker is not supported
            if (!isServiceWorkerSupported()) {
                if (!environment.production) console.debug('Service Worker not supported. Skipping FCM.');
                isLoginFCMProcessOngoing = false;
                return of(actions.login(action.payload)); // normal login without FCM
            }

            // Get FCM token
            return from(getToken(messaging)).pipe(
                map((data: string | null) => {
                    if (data) {
                        action.payload.fbToken = data;
                        localStorage.setItem(FCM_TOKEN_KEY, data);
                        msg.receiveMessage();
                    } else {
                        console.error('FCM Token is null or undefined');
                    }

                    // Dispatch a plain object action
                    return actions.login(action.payload);
                }),
                catchError((error) => {
                    console.error('FCM Token - ERROR', error);
                    return of(
                        actions.login(action.payload),
                        alertActions.show({
                            message: translationService.instant('tuya-login.error.server-error'),
                            type: AlertType.Firebase,
                        })
                    );
                }),
                finalize(() => { isLoginFCMProcessOngoing = false; })
            );
        })
    );
}


export function createGetPermissionFCMEpic(actions: AuthActions, alertActions: AlertActions, msg: PushNotificationService, translationService: TranslateService) {
    return (action$) => action$.pipe(
        ofType(GET_PERMISSION_FCM),
        switchMap((action: any) => {
            // Skip FCM for local development
            if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
                if (!environment.production) console.debug('Running locally. Skipping GET FCM Permissions.');
                isLoginFCMProcessOngoing = false;
                return of(actions.login(action.payload)); // normal login without FCM
            }

            // Skip FCM if Service Worker is not supported
            if (!isServiceWorkerSupported()) {
                if (!environment.production) console.debug('Service Worker not supported.');
                return of(actions.login(action.payload)); // normal login without FCM
            }

            // Request notification permission
            return from(Notification.requestPermission()).pipe(
                mergeMap((permission) => {
                    switch (permission) {
                        case 'granted':
                        case 'default':
                            // Permission granted, proceed with FCM-specific login
                            if (!environment.production) console.debug('Permission granted for notifications.');
                            return of(actions.loginFCM(action.payload));
                        default:
                            throw new Error('Notification permission denied.');
                    }
                }),
                catchError((error) => {
                    if (!environment.production) console.error('Error requesting notification permission:', error);
                    return of(actions.login(action.payload));
                })
            );
        })
    );
}

export function createsubscribeNotificationsEpic(msg: PushNotificationService) {
    return (action$) => action$.pipe(
        ofType(NOTIFICATION_SUBSCRIBE),
        map(() => {
            msg.receiveMessage();
            return {
                type: NOTIFICATION_SUBSCRIBE_SUCCESS,
                payload: null,
            };
        })
    );
}

export function createAccessTokenFailureEpic(actions: AuthActions) {
    return (action$) => action$.pipe(
        ofType(CREATE_ACCESS_TOKEN_FAILURE),
        switchMap(() => {
            clearLocalStorage();
            return of(actions.initRefreshReset(), actions.loginRedirect());
        })
    );
}

export function createLoginEpic(router: Router, actions: AuthActions) {
    return (action$, store) => action$.pipe(
        ofType(LOGIN_SUCCESS),
        map(() => {
            actions.initRefreshDone();
            const route = switchRoute(router, store);
            router.navigate(route.commands, route.extras);
            const routeString = route.commands[0].indexOf('order-details') > -1 ? router.url : route.commands[0];
            return {
                type: UPDATE_LOCATION,
                payload: routeString,
            };
        }),
        catchError(error => {
            if (!environment.production) console.error('Error in Shipper/Admin Login:', error);
            console.debug(LOGOUT);

            // Handle error, maybe dispatch a fallback action or log out the user
            return of({ type: LOGOUT, payload: null });
        })
    );
}

export function getUsersHomepage(shipperProfile: ShipperProfileDto, adminProfile: ShipperProfileDto): string {
    if (adminProfile && adminProfile.isAdmin) {
        return '/admin-search';
    }
    return (shipperProfile && shipperProfile.hasOrders) ? '/orders' : '/home';
}

export function switchRoute(route: any, store: NgRedux<IAppState>): any {
    const currentUser = store['value'].auth.currentUser;
    let newRoute: any = { commands: ['/'] };
    if (route.url.indexOf('shipment') > -1) {
        newRoute = { commands: ['/shipment/initial'] };
    } else if (route.url === '/login' || route.url === '/home' || route.url === '/orders') {
        newRoute = { commands: [getUsersHomepage(currentUser.shipperProfile, currentUser.adminProfile)] };
    } else if (route.url.indexOf('order-details') > -1) {
        newRoute = {
            commands: ['/order-details'],
            extras: { queryParams: { orderId: route.currentUrlTree.queryParams['orderId'] } }
        };
    } else if (route.url === '/register') {
        newRoute = { commands: ['/home'] };
    } else {
        newRoute = { commands: [route.url] };
    }
    return newRoute;
}

export function createLogoutEpic(authenticationService: AuthenticationService, actions: AuthActions) {
    return (action$) => action$.pipe(
        ofType(LOGOUT),
        exhaustMap(() => {
            if (!environment.production) console.debug(LOGOUT);
            return authenticationService.openIdLogout().pipe(
                map(() => {
                    return actions.logoutSucceeded();
                })
                , catchError(error => of(actions.logoutFailed({ error }))));
        })
    );
}

export function createLogoutFailedEpic(actions: AuthActions) {
    return (action$) => action$.pipe(
        ofType(LOGOUT_FAILURE),
        map(() => {
            return actions.logoutSucceeded();
        })
    );
}

export function createAccessTokenEpic(authenticationService: AuthenticationService, actions: AuthActions) {
    return (action$) => action$.pipe(
        ofType(CREATE_ACCESS_TOKEN),
        exhaustMap(() => {
            // ToDo: better handling for this case
            if (!localStorage.getItem(REFRESH_TOKEN_KEY))
                return of({ type: CREATE_ACCESS_TOKEN_FAILURE, payload: 0, error: 'Refresh token is empty' });

            return authenticationService.openIdCreateAccessToken().pipe(
                map((data: any) => {
                    localStorage.setItem(ACCESS_TOKEN_KEY, data.access_token);
                    localStorage.setItem(REFRESH_TOKEN_KEY, data.refresh_token);
                    localStorage.setItem(TOKEN_TYPE, BEARER_AUTH);

                    actions.initRefreshDone();
                    return actions.createAccessTokenSucceeded(data);
                })
                , catchError(error => of(actions.createAccessTokenFailed({ error }))));
        })
    );
}

export function createRedirectHomePageEpic(router: Router) {
    return (action$) => action$.pipe(
        ofType(HOME_PAGE_REDIRECT),
        map((action: any) => {
            const redirectPath = getUsersHomepage(action.payload.shipperProfile, action.payload.adminProfile);
            router.navigate([redirectPath]);
            return {
                type: UPDATE_LOCATION,
                payload: redirectPath,
            };
        })
    );
}

export function createRedirectLoginEpic(router: Router) {
    return (action$) => action$.pipe(
        ofType(LOGIN_REDIRECT),
        map(() => {
            router.navigate(['/login']);
            return {
                type: UPDATE_LOCATION,
                payload: '/login',
            };
        })
    );
}

export function createRedirectLogoutEpic(router: Router) {
    return (action$) => action$.pipe(
        ofType(LOGOUT_SUCCESS),
        map(() => {
            clearLocalStorage();
            deleteFirebaseToken();
            router.navigate(['/login']);
            return {
                type: UPDATE_LOCATION,
                payload: '/login',
            };
        })
    );
}

export function createValidateNewPasswordEpic(authService: AuthenticationService,
    authActions: AuthActions) {
    return (action$) => action$.pipe(
        ofType(VALIDATE_NEW_PASSWORD),
        switchMap((action: any) => {
            return authService.validateNewPassword(action.payload).pipe(
                map((resp: PasswordValidationVO) => authActions.validateNewPasswordSucceeded(resp))
                , catchError(resp => {
                    if (resp.status !== 422) {
                        return of(authActions.validateNewPasswordFailed({ status: '' + resp.status }));
                    }
                    const result = transformValidateAccountResult(resp.error);
                    return of(authActions.validateNewPasswordSucceeded({
                        isValid: result.isValid,
                        msgs: result.passwordErrorMsg,
                        msgsOld: null
                    }));
                }));

        })
    );
}

export function createRequestVerificationEmailEpic(actions: AuthActions, service: AuthenticationService) {
    return (action$) => action$.pipe(
        ofType(REQUEST_VERIFICATION_EMAIL),
        exhaustMap(() => {
            return service.requestVerificationEmail().pipe(
                map(() => actions.requestVerificationEmailSucceded())
                , catchError(error => of(actions.requestVerificationEmailFailed({ error }))));
        })
    );
}

export function createRequestChangePasswordEpic(authService: AuthenticationService,
    authActions: AuthActions) {
    return (action$) => action$.pipe(
        ofType(REQUEST_CHANGE_PASSWORD),
        switchMap((action: any) => {
            return authService.requestChangePasword(action.payload).pipe(
                map((data: PasswordValidationVO) => authActions.requestChangePasswordSucceeded(data))
                , catchError(data => {
                    const result = transformValidateAccountResult(data.error);
                    return of(authActions.requestChangePasswordFailed({
                        isValid: result.isValid,
                        msgs: result.passwordErrorMsg,
                        msgsOld: result.oldPasswordErrorMsg
                    }));
                }));
        })
    );
}

export function createHideModalEpic(authActions: AuthActions) {
    return (action$) => action$.pipe(
        ofType(REQUEST_CHANGE_PASSWORD_SUCCESS),
        map(() => {
            return authActions.hideModal();
        })
    );
}

export function createGoToHomeEpic(router: Router) {
    return (action$) => action$.pipe(
        ofType(GO_TO_HOME),
        map(() => {
            router.navigate(['/home']);
            return { type: UPDATE_LOCATION, payload: '/home' };
        })
    );
}

export function createGoToOrdersEpic(router: Router) {
    return (action$) => action$.pipe(
        ofType(GO_TO_ORDERS),
        map(() => {
            router.navigate(['/orders']);
            return { type: UPDATE_LOCATION, payload: '/orders' };
        })
    );
}

export function clearLocalStorage() {
    const localData = localStorage.getItem('user');
    localStorage.clear();
    localStorage.setItem('user', localData);
}

export function clearIndexedDB(dbName, storeName) {
    const indexedDB: IDBFactory = window.indexedDB ||
        (<any>window).mozIndexedDB || (<any>window).webkitIndexedDB ||
        (<any>window).msIndexedDB || (<any>window).shimIndexedDB;
    const DBOpenRequest = indexedDB.open(dbName);
    DBOpenRequest.onsuccess = (event) => {
        const db = DBOpenRequest.result;
        const transaction = db.transaction([storeName], 'readwrite');
        const objectStore = transaction.objectStore(storeName);
        objectStore.clear();
    };
    DBOpenRequest.onerror = (event: Event) => {
        console.log(event);
    };
}

function deleteFirebaseToken() {
    const app = initializeApp(environment.firebase);
    const messaging = getMessaging(app);
    const firebaseToken = localStorage.getItem(FCM_TOKEN_KEY);

    if (firebaseToken == null) return;
    return deleteTokenLocally();

    /*
    // Send the token to your server for deletion
    sendTokenToDeleteEndpoint(firebaseToken)
        .then(() => {
            // After successful server-side deletion, delete the token locally
            return deleteTokenLocally();
        })
        .then(() => {
            console.debug('Deleted Firebase Token');
        })
        .catch((error) => {
            console.error('ERROR | Delete Firebase Token', error);
        });
    */
}

/* 
function sendTokenToDeleteEndpoint(token) {
    // Implement your server-side endpoint to delete the token
    // You can use fetch or another HTTP library for this
    const deleteEndpoint = 'your_server_delete_endpoint';
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        // Include any necessary authentication or authorization headers
        body: JSON.stringify({ token }),
    };

    return fetch(deleteEndpoint, requestOptions);
}*/

function deleteTokenLocally() {
    // Implement your logic to delete the token from local storage
    localStorage.removeItem(FCM_TOKEN_KEY);
    return Promise.resolve();
}