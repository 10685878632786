import {IAuthState, InitFlagType, PasswordValidationVO} from '../model/model';
import {
    AuthAction, LOGIN_SUCCESS, LOGOUT, LOGIN_FAILURE, LOGIN,
    REQUEST_FORGOT_PASSWORD, REQUEST_FORGOT_PASSWORD_SUCCESS, REQUEST_FORGOT_PASSWORD_FAILURE,
    PASSWORD_RESET_REDIRECT, REQUEST_RESET_PASSWORD,
    REQUEST_RESET_PASSWORD_SUCCESS, REQUEST_RESET_PASSWORD_FAILURE, UPDATE_SHIPPER_PROFILE_TO_STATE,
    REQUEST_CHANGE_PASSWORD_SUCCESS, REQUEST_CHANGE_PASSWORD_FAILURE, SHOW_MODAL, HIDE_MODAL, INIT_REFRESH_DONE, INIT_REFRESH_RESET,
    CLEAR_CHANGE_PASSWORD_OLD_PASSWORD_MSG,
    CLEAR_CHANGE_PASSWORD_NEW_PASSWORD_MSG,
    CLEAR_CHANGE_PASSWORD_VALIDATION_RESULT,
    UPDATE_ADMIN_PROFILE_TO_STATE,
    UPDATE_CURRENT_USER,
    UPDATE_SHOW_SUSPEND_ALERT,
    UPDATE_AUTH_SHIPPER_STATUSES,
    UPDATE_HAS_ORDERS
} from '../actions/auth.actions';
import { Action } from 'redux';
import { SessionStorageUtil } from '../../store/sessionStorage';
import { LOAD_SHIPPER_PROFILE_FAIL, UPDATE_CAN_CREATE_SHIPEMENT, UPDATE_HAS_VALID_CC } from '../../shipper/actions/shipper.status.actions';
import { TURN_OFF_ALL_SPINNERS } from '../../core/actions/alert.actions';
import { environment } from '../../../environments/environment';

const instance = SessionStorageUtil.getInstance();
const userAccessToken = localStorage.getItem('access_token');

export const initialState: IAuthState = {
    loggedIn: !!localStorage.getItem('access_token') || false,
    currentUser: instance.get('auth.currentUser'),
    error: null,
    isLoading: false,
    forgotPasswordRequestedEmail: null,
    resetPasswordValidationResult: null,
    changePasswordValidationResult: null,
    isChangePasswordModalShow: false,
    initRefreshFlag: instance.get('auth.initRefreshFlag') || InitFlagType.Init,
    showSuspendAlert: userAccessToken ? instance.get('auth.showSuspendAlert') : true,
};

export function authReducer(state = initialState, a: Action): IAuthState {
    const action = a as AuthAction;
    switch (action.type) {
        case LOGIN: {            
            return {
                ...state,
                isLoading: true
            };
        }

        case INIT_REFRESH_DONE: {
            if (!environment.production) console.debug(INIT_REFRESH_DONE);
            return {
                ...state,
                initRefreshFlag: InitFlagType.Done
            };
        }

        case INIT_REFRESH_RESET: {
            if (!environment.production) console.debug(INIT_REFRESH_RESET);
            return {
                ...state,
                initRefreshFlag: InitFlagType.Reset
            };
        }

        case LOGIN_SUCCESS: {
            if (!environment.production) console.debug(LOGIN_SUCCESS);
            return {
                ...state,
                loggedIn: true,
                error: null,
                currentUser: action.payload,
                isLoading: false
            };
        }

        case LOGIN_FAILURE: {
            if (!environment.production) console.debug(LOGIN_FAILURE);
            return {
                ...state,
                loggedIn: false,
                currentUser: null,
                error: action.payload,
                isLoading: false
            };
        }

        case UPDATE_CURRENT_USER: {
            if (!environment.production) console.debug(UPDATE_CURRENT_USER);
            return {
                ...state,
                loggedIn: true,
                error: null,
                currentUser: action.payload,
                isLoading: false
            };
        }

        case LOGOUT: {
            return {
                ...state,
                loggedIn: false,
                error: null,
                currentUser: null,
                showSuspendAlert: true,
                initRefreshFlag: InitFlagType.Init
            };
        }

        case REQUEST_FORGOT_PASSWORD: {
            if (!environment.production) console.debug(REQUEST_FORGOT_PASSWORD);
            return {
                ...state,
                forgotPasswordRequestedEmail: action.payload,
                isLoading: true
            };
        }

        case REQUEST_FORGOT_PASSWORD_SUCCESS: {
            if (!environment.production) console.debug(REQUEST_FORGOT_PASSWORD_SUCCESS);
            return {
                ...state,
                isLoading: false
            };
        }

        case REQUEST_FORGOT_PASSWORD_FAILURE: {
            if (!environment.production) console.debug(REQUEST_FORGOT_PASSWORD_FAILURE);
            return {
                ...state,
                isLoading: false
            };
        }

        case PASSWORD_RESET_REDIRECT: {
            if (!environment.production) console.debug(PASSWORD_RESET_REDIRECT);
            return {
                ...state,
                passwordResetToken: action.payload
            };
        }

        case REQUEST_RESET_PASSWORD: {
            if (!environment.production) console.debug(REQUEST_RESET_PASSWORD);
            return {
                ...state,
                isLoading: true
            };
        }

        case REQUEST_RESET_PASSWORD_SUCCESS: {
            if (!environment.production) console.debug(REQUEST_RESET_PASSWORD_SUCCESS);
            return {
                ...state,
                isLoading: false,
                passwordResetToken: undefined
            };
        }

        case REQUEST_RESET_PASSWORD_FAILURE: {
            if (!environment.production) console.debug(REQUEST_RESET_PASSWORD_FAILURE);
            return {
                ...state,
                isLoading: false,
                resetPasswordValidationResult: <PasswordValidationVO>action.payload
            };
        }

        case UPDATE_SHIPPER_PROFILE_TO_STATE: {
            if (!environment.production) console.debug(UPDATE_SHIPPER_PROFILE_TO_STATE);
            return {
                ...state,
                currentUser: {
                  ...state.currentUser,
                  shipperProfile: action.payload
                }
            };
        }

        case UPDATE_ADMIN_PROFILE_TO_STATE: {
            if (!environment.production) console.debug(UPDATE_ADMIN_PROFILE_TO_STATE);
            return {
                ...state,
                currentUser: {
                  ...state.currentUser,
                  adminProfile: action.payload
                }
            };
        }

        case UPDATE_HAS_ORDERS: {
            if (!environment.production) console.debug(UPDATE_HAS_ORDERS);
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    shipperProfile: {
                        ...state.currentUser.shipperProfile,
                        hasOrders: action.payload
                    }
                }
            };
        }

        case UPDATE_AUTH_SHIPPER_STATUSES: {
            if (!environment.production) console.debug(UPDATE_AUTH_SHIPPER_STATUSES);
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    shipperProfile: {
                        ...state.currentUser.shipperProfile,
                        hasOrders: action.payload.hasOrders,
                        canCreateShipment: action.payload.canCreateShipment,
                        suspended: action.payload.suspended,
                        hasValidCreditCard: action.payload.hasValidCreditCard || false
                    }
                }
            };
        }

        case UPDATE_CAN_CREATE_SHIPEMENT: {
            if (!environment.production) console.debug(UPDATE_CAN_CREATE_SHIPEMENT);
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    shipperProfile: {
                        ...state.currentUser.shipperProfile,
                        canCreateShipment: action.payload
                    }
                }
            };
        }

        case UPDATE_HAS_VALID_CC: {
            if (!environment.production) console.debug(UPDATE_HAS_VALID_CC);
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    shipperProfile: {
                        ...state.currentUser.shipperProfile,
                        hasValidCreditCard: action.payload || false
                    }
                }
            };
        }

        case REQUEST_CHANGE_PASSWORD_SUCCESS: {
            if (!environment.production) console.debug(REQUEST_CHANGE_PASSWORD_SUCCESS);
            return {
                ...state,
                changePasswordValidationResult: {
                    ...state.changePasswordValidationResult,
                    isValid: true
                }
            };
        }

        case REQUEST_CHANGE_PASSWORD_FAILURE: {
            if (!environment.production) console.debug(REQUEST_CHANGE_PASSWORD_FAILURE);
            return {
                ...state,
                changePasswordValidationResult: <PasswordValidationVO> action.payload
            };
        }

        case CLEAR_CHANGE_PASSWORD_VALIDATION_RESULT: {
            if (!environment.production) console.debug(CLEAR_CHANGE_PASSWORD_VALIDATION_RESULT);
            return {
                ...state,
                changePasswordValidationResult: null
            };
        }

        case CLEAR_CHANGE_PASSWORD_OLD_PASSWORD_MSG: {
            if (!environment.production) console.debug(CLEAR_CHANGE_PASSWORD_OLD_PASSWORD_MSG);
            return {
                ...state,
                changePasswordValidationResult: {
                    ...state.changePasswordValidationResult,
                    msgsOld: null
                }
            };
        }

        case CLEAR_CHANGE_PASSWORD_NEW_PASSWORD_MSG: {
            if (!environment.production) console.debug(CLEAR_CHANGE_PASSWORD_NEW_PASSWORD_MSG);
            return {
                ...state,
                changePasswordValidationResult: {
                    ...state.changePasswordValidationResult,
                    msgs: null
                }
            };
        }

        case SHOW_MODAL: {
            if (!environment.production) console.debug(SHOW_MODAL);
            return {
                ...state,
                isChangePasswordModalShow: true
            };
        }

        case HIDE_MODAL: {
            if (!environment.production) console.debug(HIDE_MODAL);
            return {
                ...state,
                isChangePasswordModalShow: false
            };
        }

        case LOAD_SHIPPER_PROFILE_FAIL: {
            if (!environment.production) console.debug(LOAD_SHIPPER_PROFILE_FAIL);
            return {
                ...state,
                isLoading: false
            };
        }

        case TURN_OFF_ALL_SPINNERS: {
            if (!environment.production) console.debug(TURN_OFF_ALL_SPINNERS);
            return {
                ...state,
                isLoading: false
            };
        }

        case UPDATE_SHOW_SUSPEND_ALERT: {
            if (!environment.production) console.debug(UPDATE_SHOW_SUSPEND_ALERT);
            return {
                ...state,
                showSuspendAlert: action.payload
            };
        }

        default: {
            return state;
        }
    }
}
