import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ShipmentType } from '../../../core/models/shipment.model';
import { StopType } from '../../models/order.model';

const PICKUP_OBJECT = {
    className: 'pickup',
    title: 'Pickup'
};

const DELIVERY_OBJECT = {
    className: 'delivery',
    title: 'Delivery'
};

const DISTRIBUTION_STOPS = [PICKUP_OBJECT, DELIVERY_OBJECT, DELIVERY_OBJECT, DELIVERY_OBJECT];
const COLLECTION_STOPS = [PICKUP_OBJECT, PICKUP_OBJECT, PICKUP_OBJECT, DELIVERY_OBJECT];

@Component({
    selector: 'tuya-shipment-kind-picker-btn',
    templateUrl: './shipment-kind-picker-btn.component.html',
    styleUrls: ['./shipment-kind-picker-btn.component.scss']
})
export class ShipmentKindPickerBtnComponent {
    ShipmentType = ShipmentType;
    StopType = StopType;

    @Input() readonly type: ShipmentType;
    @Output() onSelect = new EventEmitter<ShipmentType>();
    selected = false;

    public stopTypes;

    @Input('selectedType')
    set selectedType(shipmentType: ShipmentType | null) {
        this.selected = (this.type === shipmentType);
        this.stopTypes = this.type === ShipmentType.Collection ? COLLECTION_STOPS : DISTRIBUTION_STOPS;
    }

    onClick() {
        this.onSelect.emit(this.type);
    }

}
