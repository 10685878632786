<tuya-start-step
        *ngIf="WizardStep.Initial === (wizardStep$ | async)"
        [firstStop]="firsStop$ | async"
        [shipmentType]="(orderDeliveryType$ | async) || null"
        [startAddressRawString]="fullAddressString"
        (updateShipmentType)="onShipmentTypeUpdate($event)"
        (updateSuiteNumber)="onPickupStopSuiteNumberSave($event)"
></tuya-start-step>

<tuya-spinner *ngIf="isLoading$ | async" size="fullscreen"></tuya-spinner>
