<div class="shipment-type"
     (click)="onClick()"
     [ngClass]="{'selected': selected}">
    <div class="shipment-type-title">{{ShipmentType[type]}}</div>

    <div *ngFor="let stop of stopTypes" class="{{stop.className}}">
        <span class="stop-icon"></span>
        <span class="stop-line"></span>
        <span class="stop-type">{{stop.title}}</span>
    </div>
</div>
