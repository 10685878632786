import { IShipmentState } from '../models/model';
import { Action } from 'redux';
import { CREDIT_CARD_ADD_SUCCESS, HAS_VALID_CREDIT_CARD_SUCCESS, PaymentAction } from '../actions/payment.actions';
import { initialShipmentState } from './index';


export function paymentReducer(state = initialShipmentState, a: Action): IShipmentState {
    const action = a as PaymentAction;
    switch (a.type) {
        case CREDIT_CARD_ADD_SUCCESS: {
            return {
                ...state,
                order: {
                    ...state.order,
                    creditCard: action.payload
                }
            };
        }
        case HAS_VALID_CREDIT_CARD_SUCCESS: {
            return {
                ...state,
                order: {
                    ...state.order,
                    hasValidCreditCard: action.payload
                }
            };
        }
        default: {
            return state;
        }
    }
}
