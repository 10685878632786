import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/module';
import { ShipmentEditEpics } from './epics/shipment-edit.epics';
import { ShipmentEditActions } from './actions/shipment-edit.actions';
import { TranslationLoaderService } from '../core/services/translation-loader.service';
import { locale as english } from './i18n/shipment-edit.en';
import { EditConfirmPageComponent } from './containers/edit-confirm/edit-confirm.page';


export const COMPONENTS = [EditConfirmPageComponent];

@NgModule({
    imports: [
        CommonModule,
        CoreModule
    ],
    declarations: COMPONENTS,
    exports: [...COMPONENTS],
    providers: [ShipmentEditEpics, ShipmentEditActions]
})
export class ShipmentEditModule {
    constructor(private translationLoader: TranslationLoaderService) {
        this.translationLoader.loadTranslations(english);
    }
}
