import { Action } from 'redux';
import { ITermsOfServiceState } from '../models/model';
import { LOAD_PRIVACY_SUCCESS, LOAD_TERMS_SUCCESS, TermsOfServiceAction } from '../actions/termsOfService.actions';

export const initialState: ITermsOfServiceState = {
    terms: null,
    privacy: null
};

export function termsOfServiceReducer(state = initialState, a: Action): ITermsOfServiceState {
    switch (a.type) {
        case LOAD_TERMS_SUCCESS: {
            const action = a as TermsOfServiceAction;
            return {
                ...state,
                terms: action.payload
            };
        }
        // @TODO clear modal message on route change!!
        case LOAD_PRIVACY_SUCCESS: {
            const action = a as TermsOfServiceAction;
            return {
                ...state,
                privacy: action.payload
            };
        }
        default: {
            return state;
        }
    }
}
