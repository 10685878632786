import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StopDto } from '../../../../core/models/dto';
import { DateHelper } from '../../../utils/dateHelper';
import { StopType } from '../../../models/order.model';
import { PickupDateType } from '../../../../core/models/shipment.model';
import { DEFAULT_TIME_WINDOW, MIN_TIME_WINDOW, StopPickupTimeUtils } from '../../../utils/stop-pickup-time.utils';


@Component({
    selector: 'tuya-stop-date-common-component',
    template: '',
    styleUrls: ['stop-date-fields.component.scss']
})
export class StopDateCommonComponent {
    // get current date.
    currentDate = new Date();

    // Utils
    addMsec2Date = DateHelper.addMsec2Date;

    // options for change date type select
    dateList = [
        { dateTypeId: PickupDateType.Today, label: 'Today', getISO: (date: Date) => date.toISOString() },
        { dateTypeId: PickupDateType.Tomorrow, label: 'Tomorrow', getISO: (date: Date) => DateHelper.getTomorrow(date).toISOString() }
    ];

    @Input() disabled: boolean;
    @Input() isEditMode: boolean;
    // identify of is draft order stop created or edited - important calculate depended stop time first time
    @Input() isDraftStopDetailsEditMode = false;
    @Input() isFormPristine = true;
    set dateType(dateTypeId: PickupDateType) {
        // if date was changes fire form update event
        if (this._dateTypeId !== dateTypeId) {
            this.updateFormState();
            this._dateTypeId = dateTypeId;
            this.updateMinNoEarlierDT(this._dateTypeId);

            const defaultDateByType = this._dateTypeId !== PickupDateType.Tomorrow ? new Date(this.minNoEarlierDT)
                : StopPickupTimeUtils.getDefaultDateForTomorrow();

            this.setDatePairByNoEarlier(defaultDateByType);
            this.updateStop();
        } else {
            this.updateMinNoEarlierDT(this._dateTypeId);
        }
    }
    get dateType() { return this._dateTypeId; }

    @Input() set stop(value: StopDto) {
        this._stop = value;
        // save stop type
        this.isPickupStop = this.stop.typeId === StopType.Pickup;
        // save date type
        this.dateType = value.pickupDateTypeId;

        // If value's dates contains empty string -> actual DT will be (currentDate..currentDate+default_window)
        if (value.pickupNoEarlierThan && value.pickupNoLaterThan) {
            this.setDatePairWithConstrains(new Date(Date.parse(value.pickupNoEarlierThan)),
                new Date(Date.parse(value.pickupNoLaterThan)), true);
        }
        this.updateStop();
    }
    get stop() {
        return this._stop;
    }

    //@Input() relatedStop;

    @Output()
    updateDate = new EventEmitter<any>();
    @Output()
    validate = new EventEmitter<any>();
    @Output()
    change = new EventEmitter();

    // ------------ values for PickupNoEarlierThan Control --------------------------------
    // by default no earlier DT should be in future
    public noEarlierDT: Date = new Date(this.currentDate);
    // by default no earlier DT should be in future
    public minNoEarlierDT: Date = new Date(this.currentDate);
    // by default no earlier DT should be in the same day according to dateType: Today | Tomorrow : 23:55
    public get maxNoEarlierDT(): Date {
        return this.addMsec2Date(-MIN_TIME_WINDOW, DateHelper.getTomorrow(this.minNoEarlierDT));
    }
    // ------------ values for PickupNoEarlierThan Control ---------------------------------

    // ------------ values for PickupNoLaterThan Control ---------------------------------
    // by default no later DT should be noEarlierDT + 3h
    public noLaterDT: Date = this.addMsec2Date(DEFAULT_TIME_WINDOW, this.noEarlierDT);
    // by default no later DT should be in future
    public get minNoLaterDT(): Date {
        return this.addMsec2Date(MIN_TIME_WINDOW, this.noEarlierDT);
    }
    // by default no later DT should be  in the same day according to dateType: Today | Tomorrow : 23.59
    public get maxNoLaterDT() {
        return StopPickupTimeUtils.getMaxDateTimeToday(this.noEarlierDT);
    }
    // ------------ values for PickupNoLaterThan Control ---------------------------------

    public isCollapsed = true;
    public _dateTypeId = PickupDateType.Today;
    public _stop = new StopDto();
    public isPickupStop = false;
    // date type change is unavailable when related stop is setted up to tomorrow
    public isDateTypeChangeAvailable = true;

    constructor() { }

    onTimeIsInvalid() {
        this.validate.emit({pickupDate: false});
    }

    updateFormState() {
        if (this.isFormPristine) {
            this.isFormPristine = false;
            this.change.emit();
        }
    }

    setDatePairWithConstrains(noEarlierDT: Date, noLaterDT: Date, isStopSetting?) {
        // @Info: Fix for edit order, if the user did not change date time, then we leave date time as in the stop;
        if (this.disabled || (isStopSetting && this.isEditMode) || (isStopSetting && this.isDraftStopDetailsEditMode)) {
            this.noEarlierDT = noEarlierDT;
            this.noLaterDT = noLaterDT;
            return;
        }

        if (this.minNoEarlierDT > noEarlierDT) {
            noEarlierDT = this.minNoEarlierDT;
        }

        // Tryin' to leave room for a delivery and the time window for the noLaterDT.
        if (noEarlierDT > this.maxNoEarlierDT) {
            noEarlierDT = new Date(this.maxNoEarlierDT);
            noLaterDT = this.addMsec2Date(DEFAULT_TIME_WINDOW, noEarlierDT);
        }

        this.noEarlierDT = noEarlierDT;

        if (noLaterDT < this.minNoLaterDT) {
            noLaterDT = this.minNoLaterDT;
        }
        // check same day.
        if (noLaterDT > this.maxNoLaterDT) {
            noLaterDT = this.maxNoLaterDT;
        }

        this.noLaterDT = noLaterDT;
    }

    updateMinNoEarlierDT(newDateTypeId: number) {}

    // ToDo: needs refactoring
    setDatePairByNoEarlier(noEarlierDT: Date) {
        this.setDatePairWithConstrains(noEarlierDT, this.addMsec2Date(DEFAULT_TIME_WINDOW, noEarlierDT));
    }

    // process changes of timepicker for noEarlier DT
    noEarlierThanUpdate(date: Date) {
        this.setDatePairWithConstrains(date, this.noLaterDT);
        this.updateFormState();
        this.updateStop();
    }

    // process changes of timepicker for noLater DT
    noLaterThanUpdate(date: Date) {
        this.setDatePairWithConstrains(this.noEarlierDT, date);
        this.updateFormState();
        this.updateStop();
    }

    // emit date components changes to parent Stop Details Component
    updateStop() {
        const updates = {
            pickupDateTypeId: this._dateTypeId,
            pickupNoEarlierThan: this.noEarlierDT.toISOString(),
            pickupNoLaterThan: this.noLaterDT.toISOString()
        };

        this._stop = {
            ...this._stop,
            ...updates
        };

        this.validate.emit({pickupDate: true});
        this.updateDate.emit({
            details: updates,
            isValid: true
        });
    }
}
