<div *ngIf="!_requestedEmail; else requested">
    <div class="col-md-offset-1">
        <h2 [translate]="'tuya-forgot-password.forgottenYourPassword'"></h2>
        <h4 [translate]="'tuya-forgot-password.enterToResetPassword'"></h4>
    </div>
    <!-- Reset your Password -->
    <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
        <div class="form-group input-control enlarge-bottom-margin"
            [ngClass]="{ 'has-error': (f.submitted && !email.valid) || isUserNotValid }">

            <label for="email" [translate]="'tuya-login.placeholder.email'"></label>
            <input placeholder="{{'tuya-login.placeholder.email' | translate }}" type="text" class="form-control"
                name="email" [(ngModel)]="model.email" #email="ngModel" required email />
            <div *ngIf="f.submitted && !email.valid" class="help-block" [translate]="'tuya-login.error.email'"></div>

            <button title="Reset Password" type="submit" [disabled]="false"
                class="btn btn-primary btn-lg btn-block forgot-password-btn no-margin"
                [translate]="'tuya-forgot-password.submit'"></button>
        </div>
    </form>
</div>

<ng-template #requested>
    <div class="col-md-offset-1 no-link-header">
        <div class="delivery-emblem email">
            <div class="content email"></div>
        </div>
        <h2 [translate]="'tuya-forgot-password.checkYourEmail'"></h2>
        <h4>{{ 'tuya-forgot-password.checkYourEmailDescPre' | translate }}
            <span class="email-text">{{forgotPasswordRequestedEmail}}</span>{{
            'tuya-forgot-password.checkYourEmailDescPost' | translate }}
        </h4>
    </div>
    <a class="back-to-login-lnk" routerLink="/login">
        <i class="fa fa-long-arrow-left"></i>
        {{'tuya-forgot-password.backToLogin' | translate}}
    </a>
</ng-template>