<div [formGroup]="timeForm" class="time-dropdown">

  <label class="time-dropdown__label" for="{{ idKey }}-time-dropdown">
    {{ label }}
  </label>
  <br>
  <input type="text" mask="h9:m0 AA||Hh:m0 AA" placeholder="h:mm am/pm" clearIfNotMatch="true" apm="true"
    specialCharacters=":" allow="APM apm:" class="time-dropdown__input"
    [ngClass]="{'time-dropdown__input--disabled': disabled}"
    [class.invalid-input]="areValidInputs() && (hasInvalidStartTime() || endsAfterStartTime() || invalidMinimumTimeWindow())"
    id="{{ idKey }}-time-dropdown" (focusout)="onTimeSelect($event)" formControlName="{{ formControlNameField }}"
    [attr.data-key]="key">
</div>