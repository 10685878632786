import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShipmentType } from '../../../core/models/shipment.model';

@Component({
  selector: 'tuya-shipment-kind-picker',
  templateUrl: './shipment-kind-picker.component.html',
  styleUrls: ['./shipment-kind-picker.component.scss']
})
export class ShipmentKindPickerComponent implements OnInit {

  ShipmentType: typeof ShipmentType = ShipmentType;
  // shipmentType: ShipmentType | null = null;

  @Input() shipmentType: ShipmentType | null = null;
  @Output() shipmentTypeSelected = new EventEmitter<ShipmentType>();

  constructor() { }

  ngOnInit() {
  }

  setShipmentType(t: ShipmentType) {
   this.shipmentType = t;
   this.shipmentTypeSelected.emit(t);
  }
}
