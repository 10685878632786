import { initialOrderVo } from './order.reducer';
import { initialAddressAutocompleteState } from './address-autocomplete.reducer';
import { initialUiState } from './ui.reducer';
import { initialGeneralOfferState } from './general-offer.reducer';

export const initialShipmentState = {
    order: initialOrderVo,
    ui: initialUiState,
    generalOffer: initialGeneralOfferState,
    addressAutocomplete: initialAddressAutocompleteState
};
