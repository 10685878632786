import { Reference } from './../../../core/models/order.dto';
import { OrderHelper } from './../../utils/order-helper.utils';
import { ShipmentType } from './../../../core/models/shipment.model';
import {
    Component, Input, Output, EventEmitter, ViewChild,
    ChangeDetectionStrategy, OnInit
} from '@angular/core';
import { StopDto, AddressDto, StopStatusId } from '../../../core/models/dto';
import { StopType, OrderItemVo, Stop, BoxSizeTypes } from '../../models/order.model';
import { OrderEditMode } from '../../../core/models/order.dto';

@Component({
    selector: 'tuya-stop-details-edit',
    templateUrl: './stop-details-edit.component.html',
    styleUrls: ['./stop-details-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopDetailsEditComponent implements OnInit {
    @ViewChild('stopRequiredFields', {static: true}) stopRequiredFields;

    StopStatusId: typeof StopStatusId = StopStatusId;
    StopType: typeof StopType = StopType;
    OrderEditMode: typeof OrderEditMode = OrderEditMode;
    ShipmentType: typeof ShipmentType = ShipmentType;
    OrderHelper: typeof OrderHelper = OrderHelper;
    @Input() isDirect = false;
    @Input() isStopDetailsEditMode: boolean;
    @Input() isInitialPickupStop: boolean;
    @Input() orderEditMode: OrderEditMode;
    @Input() relatedStop: Stop;
    @Input() stopType: StopType;
    @Input() address: AddressDto;
    @Input() shipmentType: ShipmentType;
    @Input() itemsSizes = [];
    @Input() defaultSize: number;
    @Input() set items(value: OrderItemVo[]) {
        if (value) {
            // split items array to selectedItems and returnItem; initialize returnItem if it doesn't exist
            this.returnItem = value.find(val => val.sizeId === BoxSizeTypes.UnknownReturn);
            if (!this.returnItem) {
                this.returnItem = {
                    piecesCount: 0,
                    directId: null,
                    sizeId: BoxSizeTypes.UnknownReturn,
                    weight: 0,
                    reference: new Reference,
                    description: '',
                    stopIds: []
                };
            }
            this.selectedItems = value.filter(val => val.sizeId !== BoxSizeTypes.UnknownReturn);
        }
    }
    @Output() onStopDetailsChunkSubmit = new EventEmitter< {
        details: StopDto;
        items: Array<OrderItemVo>;
        isEditMode: boolean,
        isDirect: boolean,
        isBackupSubmit: boolean;
        isValid: boolean}>();
    @Output() onDefaultSizeUpdate = new EventEmitter<number>();
    @Output() goBack = new EventEmitter();
    @Output() detailsTouched = new EventEmitter();

    public _details: StopDto | null;
    public returnItemsCount: number;
    public isFormPristine = true;
    public validationMap;
    public selectedItems: Array<OrderItemVo> | null;
    public returnItem: OrderItemVo | null;
    public dateComponentData;

    @Input() set details(d: StopDto) {
        this.validationMap = {
            nameOrDescription: d ? !!d.nameOrDescription : false,
            contactName: d ? !!d.contactName : false
        };
        this._details = d;
        this.dateComponentData = {
            ...this._details
        };
    }


    constructor() {
    }

    ngOnInit() {
        // if it's an edit order - then it means that data is provided outside
        // and ready to validate
        if (this.orderEditMode === OrderEditMode.NoEdit) {
            this.isFormPristine = true;
        }
    }

    isStopValidated() {
        for (const key of Object.keys(this.validationMap)) {
            if (!this.validationMap[key]) {
                return false;
            }
        }
        return true;
    }

    isItemsShowed() {
        return this.shipmentType === ShipmentType.Distribution &&
            (this.stopType === StopType.Delivery || this.stopType === StopType.DeliveryWithReturn) ||
            this.shipmentType === ShipmentType.Collection && this.stopType === StopType.Pickup;
    }

    isSaveBtnDisabled() {
        return !this.isStopValidated() || (this.isStopDetailsEditMode && this.isFormPristine);
    }

    isBackBtnAvailable() {
        return this.isStopValidated() && (this.isStopDetailsEditMode && this.isFormPristine);
    }

    onUpdateStopDate(stopUpdates) {
        if (stopUpdates.details) {
            this._details = {
                ...this._details,
                ...stopUpdates.details
            };
        }
    }

    onUpdateIsDirect(isDirect) {
        this.isFormPristine = false;
        this.isDirect = isDirect;
    }

    onSaveStopDetails(isBackup = false) {
        this.isFormPristine = true;
        this.onStopDetailsChunkSubmit.emit({
            details: this._details,
            isDirect: this.isDirect,
            isBackupSubmit: isBackup,
            isEditMode: this.isStopDetailsEditMode,
            items: this.returnItem.piecesCount !== 0 ? this.selectedItems.concat(this.returnItem) : this.selectedItems,
            isValid: true
        });
    }

    onSaveReturnItemCount(count) {
        this.returnItemsCount = count;
        this.onDetailsChanged(count);
    }

    onUpdateValidationMap(fieldValidation: {[key: string]: { prop: boolean }}) {
        this.validationMap = {...this.validationMap, ...fieldValidation};
    }

    onDetailsChanged(e: any) {
        this.isFormPristine = false;
        this.detailsTouched.emit();
    }

    onItemsDetailsChunkEnter(itemsUpdate) {
        this.selectedItems = itemsUpdate.items;
        // this._details.itemsDescription = itemsUpdate.items
        this.onUpdateValidationMap({items: itemsUpdate.isValid});
    }

    ifDisableAssignedOrder() {
        return this.orderEditMode === OrderEditMode.Accepted;
    }

    ifDisabledInprogressOrder() {
        return this.orderEditMode === OrderEditMode.PartiallyCompleted;
    }

    isDisabledCompletedStop() {
        return this.ifDisabledInprogressOrder()
            && this._details.statusId >= StopStatusId.Completed;
    }

    isEditMode() {
        return this.orderEditMode !== OrderEditMode.NoEdit;
    }

    isReturnSectionAvailable() {
        return this.shipmentType === ShipmentType.Distribution &&
            (this.stopType === StopType.Delivery || this.stopType === StopType.DeliveryWithReturn);
    }
}
