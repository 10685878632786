import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'tuya-stop-direct-order-date',
    templateUrl: 'stop-direct-order-date.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopDirectOrderDateComponent {
    @Input() disabled: boolean;
    @Input() isInitialPickupStop = false;
    constructor() { }
}
