export const locale = {
  'lang': 'en',
  'data': {
    'tuya-admin': {
      'search-page': {
        'search-for': 'Search For',
        'search-placeholder': 'Shipper name, phone, email, code or account number',
        'search-results-title': 'Search results for <strong>{{ term }}</strong>',
        'headers': {
          'name': 'Company Name',
          'contact': 'Contact',
          'email': 'Email',
          'phone': 'Phone',
          'account': 'account'
        },
        'no-results': 'Sorry, no search results for {{ term }}. Please try again or refine your query.',
        'new-search': 'Please type in the search field above to look up shippers.',
      },
      'admin-panel': {
        'admin-panel-label': 'Admin Panel',
        'shipper-code-label': 'Shipper Factor Code',
        'placeholder': 'Enter Code',
        'apply': 'Apply',
        'remove': 'remove',
        'enable-terms': 'Enable terms for this shipper',
        'suspend': 'Suspend this account',
        'code-applied': '{{ friendlyFactorCode }} code applied.',
        'shipper-factor-code': 'Shipper Factor Code',
        'modal-enable-terms-message': 'Do you want to bill this shipper on terms?',
        'modal-disable-terms-message': 'Do you want to remove billing on terms?',
        'modal-suspend-message': 'Are you sure you want to suspend this account?',
        'modal-unsuspend-message': 'Are you sure you want to unsuspend this account?'
      }
    }
  }
};
