import { map, Observable } from 'rxjs';
import { OrderListItem, MyOrdersState, OrderExpandedView } from '../model/my-orders.model';
import { IAppState } from '../../store/model';
import { OrderRespDtoV2} from 'app/core/models/dto';

export function getCurrentItemsList(myOrdersState$: Observable<MyOrdersState>): Observable<Array<OrderListItem>> {
    return myOrdersState$.pipe(
        map(({ pagination, ordersTable }) => {
            if (pagination.currentPageNumber < 1) {
                return [];
            }

            const currentOrders: Array<OrderListItem> = [];

            ordersTable.currentOrdersId.forEach((id) => {
                const order = ordersTable.ordersCommonInfo[id] as any;
                currentOrders.push(transformOrderCommonInfo(order));
            });

            return currentOrders;
        })
    );
}

export function getExpandedRows(myOrdersState$: Observable<MyOrdersState>): Observable<{ [key: string]: OrderExpandedView }> {
    return myOrdersState$.pipe(map(({ordersDetails, rateReasons, ordersTable}) => {
        const expandedRowsObject = ordersDetails.ordersExpandedInfo;
        const ordersCommonInfo = ordersTable.ordersCommonInfo;
        return Object.keys(expandedRowsObject).reduce((result, rowId) => {
            if (ordersCommonInfo[rowId]) {
                const statusId = ordersCommonInfo[rowId].statusId;
                result[rowId] = {...expandedRowsObject[rowId], rateReasons, statusId};
            }
            return result;
        }, {});
    }));
}

export function getActiveOrderDetails(myOrdersState$: Observable<MyOrdersState>): Observable<OrderExpandedView> {
    return myOrdersState$.pipe(map(({ordersDetails, rateReasons}) => {
        if (!ordersDetails.activeOrderDetails) {
            return null;
        }
        const statusId = ordersDetails.activeOrderDetails.statusId;
        const {activeOrderDetails} = ordersDetails;
        return  {...activeOrderDetails, rateReasons, statusId};
    }));
}

// street = full address
function transformOrderCommonInfo(order: OrderRespDtoV2) {
    let from = {short: 'Empty', long: 'Empty'};
    let to = {short: 'Empty', long: 'Empty'};
    if (order.pickupAddress !== null) {
        to = {short: order.pickupAddress.stopName, long: order.pickupAddress.name};
    }
    if (order.deliveryAddress !== null) {
        from = {short: order.deliveryAddress.stopName, long: order.deliveryAddress.name};
    }

    return {
        ...(new OrderListItem),
        date: order.creationDateUtc, // mod
        dateCompleted: order.completionDateUtc,
        stopFrom: from,
        orderId: order.id,
        stopTo: to,
        code: order.code,
        stopsCount: order.stopsCount,
        deliveryPro: order.deliveryPro || 'Empty',
        total: order.total,
        statusId: order.statusId,
        isScheduledOrder: order.isScheduledOrder,
        recurrenceOrderDetail: order.recurrenceOrderDetail
    };
}

export class MyOrdersSelectors {
    public static CancelSuccessSelector = (state: IAppState): any => state.home.myOrders.ordersTable.ordersCommonInfo;
}
