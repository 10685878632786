import { SaveModalComponent } from './components/save-modal/save-modal.component';
import { BillingContactsComponent } from './components/billing-contacts/billing-contacts.component';
import { SharedModule } from './../shared/module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CancelModalComponent } from './components/cancel-modal/cancel-modal.component';
import { ProfilesEpics } from './epics/profiles.epics';
import { ProfilesService } from './services/profiles.service';
import { ProfilesActions } from './actions/profiles.actions';
import { CoreModule } from './../core/module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslationLoaderService } from './../core/services/translation-loader.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ProfilePageComponent } from './../profiles/containers/profile-page/profile-page.component';
import { CompanyPageComponent } from './../profiles/containers/company-page/company-page.component';
import { locale as english } from './i18n/profiles.en';
import { GeneralInformationComponent } from './components/general-information/general-information.component';
import { ManageCompanyTabsComponent } from './components/manage-company-tabs/manage-company-tabs.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { BillingService } from './services/billing.service';
import { BillingActions } from './actions/billing.actions';
import { BillingEpics } from './epics/billing.epics';
import { CompanyHeaderComponent } from './components/company-header/company-header.component';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar-portable';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};
export const COMPONENTS = [ProfilePageComponent, CompanyPageComponent, GeneralInformationComponent,
    BillingContactsComponent, ManageCompanyTabsComponent, CancelModalComponent, CompanyHeaderComponent,
    ChangePasswordComponent, SaveModalComponent, PaymentMethodsComponent];

@NgModule({
    declarations: COMPONENTS,
    imports: [CommonModule, 
              TranslateModule, 
              RouterModule, 
              FormsModule, 
              ReactiveFormsModule, 
              CoreModule,
              NgxMaskDirective,
              NgxMaskPipe,
              PerfectScrollbarModule, 
              ModalModule.forRoot(), 
              SharedModule],
    exports: [...COMPONENTS, TranslateModule, RouterModule],
    providers: [
        TranslationLoaderService,
        BillingActions,
        ProfilesActions,
        ProfilesService,
        ProfilesEpics,
        BillingService,
        BillingEpics,
        [provideNgxMask()],
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }        
    ]
})

export class ProfilesModule {
  constructor(private translationLoader: TranslationLoaderService) {
        this.translationLoader.loadTranslations(english);
  }
}
