import { ServerErrorResultDto, ShipperRegistrationDto } from '../../core/models/dto';
import { AccountValidationResultVO, CompanyValidationResultVO } from '../model/model';
import { IdentityValidationErrorCode, ValidationErrorCode } from '../../core/models/error-code.model';

const REGISTER_MAX_LENGTH_ERROR_KEY = 'tuya-register.validation.maxLength';
const REGISTER_MIN_LENGTH_ERROR_KEY = 'tuya-register.validation.minLength';
const REGISTER_BLACK_LIST_ERROR_KEY = 'tuya-register.validation.blackList';
const REGISTER_PASSWORD_FORBIDDEN_SYMBOLS = 'tuya-register.validation.forbidden-symbols';
const REGISTER_COMBINATION_ERROR_KEY = 'tuya-register.validation.combination';
export const REGISTER_NOT_UNIQUE_EMAIL = 'tuya-register.validation.uniqueEmail';
const REGISTER_NOT_UNIQUE_COMPANY_NAME = 'tuya-register.validation.company-name';
const PHONE_NUMBER_LENGTH = 'tuya-register.validation.phone-length';
const REGISTER_NOT_VALID_PASSWORD = 'tuya-register.validation.valid-password';
const FIRST_NAME_ERROR = 'tuya-register.validation.first-name-format';
const LAST_NAME_ERROR = 'tuya-register.validation.last-name-format';
const ACCOUNT_TEMPORARY_LOCKED = 'tuya-login.error.account-locked';
const INVALID_USERNAME_OR_PASSWORD = 'tuya-login.error.userNotValid';
const NEW_PASSWORD_SAME_AS_CURRENT = 'tuya-register.validation.same-as-current';
const EXPIRED_TOKEN = 'tuya-register.validation.expired-token';

export function cleanValidateAccountAndProfile(body: ShipperRegistrationDto) {
    // server requirements:
    // set businessEntityRegistrationDto to null until next step (BusinessEntityAndReferralCodeValidation)
    // set nickname to null if empty
    const transformed = { ...body };
    transformed.businessEntityRegistrationDto = null;
    transformed.nickname = transformed.nickname || null;
    return transformed;
}

export function transformValidateAccountResult(result: ServerErrorResultDto): AccountValidationResultVO {
    const oldPasswordErrorMsg = [];
    const passwordErrorMsg = [];
    const emailErrorMsg = [];
    const isValid = !result || !result.errors || result.errors.length === 0;

    if (!isValid) {
        const passwordErrors = result.errors.filter(error => error && error.field && error.field.toLowerCase() === 'password');

        if (passwordErrors.find(error => Number(error.code) === ValidationErrorCode.FieldLessMinLength)) {
            passwordErrorMsg.push(REGISTER_MIN_LENGTH_ERROR_KEY);
        }

        if (passwordErrors.find(error => Number(error.code) === ValidationErrorCode.FieldMoreMaxLength)) {
            passwordErrorMsg.push(REGISTER_MAX_LENGTH_ERROR_KEY);
        }

        if (passwordErrors.find(error => Number(error.code) === IdentityValidationErrorCode.PasswordContainForbiddenCharacters)) {
            passwordErrorMsg.push(REGISTER_PASSWORD_FORBIDDEN_SYMBOLS);
        }

        if (passwordErrors.find(error => Number(error.code) === IdentityValidationErrorCode.PasswordNoCapitalLetter) ||
            passwordErrors.find(error => Number(error.code) === IdentityValidationErrorCode.PasswordNoDigit) ||
            passwordErrors.find(error => Number(error.code) === IdentityValidationErrorCode.PasswordNoSpecialCharacters)) {
            passwordErrorMsg.push(REGISTER_COMBINATION_ERROR_KEY);
        }

        if (passwordErrors.find(error => Number(error.code) === IdentityValidationErrorCode.PasswordIsBlackList)) {
            passwordErrorMsg.push(REGISTER_BLACK_LIST_ERROR_KEY);
        }

        if (passwordErrors.find(error => Number(error.code) === IdentityValidationErrorCode.PasswordSameAsCurrent)) {
            passwordErrorMsg.push(NEW_PASSWORD_SAME_AS_CURRENT);
        }

        if (passwordErrors.find(error => Number(error.code) === IdentityValidationErrorCode.ExpiredToken)) {
            passwordErrorMsg.push(EXPIRED_TOKEN);
        }

        const oldPasswordErrors = result.errors.filter(error => error && error.field && error.field.toLowerCase() === 'oldpassword');

        if (oldPasswordErrors.find(error => Number(error.code) === IdentityValidationErrorCode.PasswordNotValid)) {
            oldPasswordErrorMsg.push(REGISTER_NOT_VALID_PASSWORD);
        }

        const emailErrors = result.errors.filter(error => error && error.field && error.field.toLowerCase() === 'email');

        if (emailErrors.find(error => Number(error.code) === ValidationErrorCode.FieldIsNotUnique)) {
            emailErrorMsg.push(REGISTER_NOT_UNIQUE_EMAIL);
        }

        const phoneErrors = result.errors.filter(error => error && error.field && error.field.toLowerCase() === 'phonenumber');

        if (phoneErrors.find(error => Number(error.code) === ValidationErrorCode.FieldNotEqualsLength)) {
            emailErrorMsg.push(PHONE_NUMBER_LENGTH);
        }

        const firstNameErrors = result.errors.filter(error => error && error.field && error.field.toLowerCase() === 'firstname');

        if (firstNameErrors.find(error => Number(error.code) === ValidationErrorCode.FieldIsInIncorrectFormat)) {
            emailErrorMsg.push(FIRST_NAME_ERROR);
        }

        const lastNameErrors = result.errors.filter(error => error && error.field && error.field.toLowerCase() === 'lastname');

        if (lastNameErrors.find(error => Number(error.code) === ValidationErrorCode.FieldIsInIncorrectFormat)) {
            emailErrorMsg.push(LAST_NAME_ERROR);
        }

    }

    return {
        oldPasswordErrorMsg,
        passwordErrorMsg,
        emailErrorMsg,
        isValid
    };
}

export function transformValidateCompanyResult(result: ServerErrorResultDto): CompanyValidationResultVO {
    const isValid = !result || !result.errors || result.errors.length === 0;
    const nameErrorMsg = [];


    const nameErrors = result.errors.filter(error => error && error.field && error.field.toLowerCase() === 'name');

    if (nameErrors.find(error => Number(error.code) === ValidationErrorCode.FieldIsNotUnique)) {
        nameErrorMsg.push(REGISTER_NOT_UNIQUE_COMPANY_NAME);
    }

    return <CompanyValidationResultVO>{
        isValid,
        nameErrorMsg
    };
}

export function transformValidateLoginResult(result: ServerErrorResultDto): Array<string> {
    const nameErrorMsg = [];

    if (result && result.errors && result.errors.find(
        error => Number(error.code) === IdentityValidationErrorCode.InvalidUsernameOrPassword)) {
        nameErrorMsg.push(INVALID_USERNAME_OR_PASSWORD);
    }

    if (result && result.errors && result.errors.find(
        error => Number(error.code) === IdentityValidationErrorCode.AccountTemporaryLocked)) {
        nameErrorMsg.push(ACCOUNT_TEMPORARY_LOCKED);
    }

    return nameErrorMsg;
}

export function transformShipperRegistrationDto(shipperDto: ShipperRegistrationDto): ShipperRegistrationDto {
    shipperDto = {
        ...shipperDto,
        businessEntityRegistrationDto: {
            ...shipperDto.businessEntityRegistrationDto,
            billingEmail: shipperDto.email,
            billingPhone: shipperDto.phoneNumber,
            billingFirstName: shipperDto.firstName,
            billingLastName: shipperDto.lastName
        }
    };
    return shipperDto;
}
