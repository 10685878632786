import { AlertType } from './../../../core/models/alert.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';

@Component({
  selector: 'tuya-address-alert-modal',
  templateUrl: './address-alert-modal.component.html',
  styleUrls: ['./address-alert-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressAlertModalComponent implements AfterViewInit {

  AlertType: typeof AlertType = AlertType;
  @Input() public alert;
  @Output() clearSearchInput = new EventEmitter<any>();

  @ViewChild('staticModal', {static: true}) staticModal: ModalDirective;

  constructor() { }

  ngAfterViewInit() {
      this.showModal();
  }

  showModal() {
      this.staticModal.show();
  }
  onCloseModal() {
    this.staticModal.hide();
    this.clearSearchInput.emit();
}

}
