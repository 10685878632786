import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { OrderExpandedView } from '../../model/my-orders.model';
import { Marker } from '../../../core/models/map.model';
import { DatePipe } from '@angular/common';
import { MarkerUrls } from '../../../core/constants/general.constants';

@Component({
    selector: 'tuya-order-info-container',
    templateUrl: './order-info.container.component.html',
    styleUrls: ['./order-info.container.component.scss'],
    providers: [DatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderInfoContainerComponent implements OnInit {

    @Input() public set orderInfo(orderInfo: OrderExpandedView) {
        if (!orderInfo) { return; }
        this._orderInfo = orderInfo;
        if (orderInfo && orderInfo.stopList) { this.markers = this._getMarkersArray(orderInfo, orderInfo.assignments.drivers); }
    }
    @Input() isLoading = false;

    public get orderInfo() {
        return this._orderInfo;
    }

    public markers: Marker[] = [];

    private _orderInfo: OrderExpandedView = {} as OrderExpandedView;

    constructor(private _datePipe: DatePipe) { }

    ngOnInit() {
    }

    private _getMarkersArray(orderInfo, drivers = []): Marker[] {
        // show tracking marker only when the Driver has items in vehicle
        // or status is Declined/Completed/CompletedWithExceptions/Canceled/PendingCancellation/Released (dto.ts)

        // JAY: the view should not hold this logic (lat and long should be null when not allowed to track)
        /*if (!orderInfo.driverArrivedAtFirstStop || orderInfo.statusId > 35) {
            return [...this._getStopMarkers(orderInfo.stopList)];
        }*/
        // else if in progress, show driver too

        // Driver Tracking
        return [...this._getStopMarkers(orderInfo.stopList), ...this._getDriverMarkers(drivers)];
    }

    private _getStopMarkers(stopList) {
        return stopList.reduce((p, c) => {
            const address = c.address;
            if (!address) { return p; }
            return p.concat({
                ...MarkerUrls[c.typeId],
                latLng: { lat: +address.latitude, lng: +address.longitude },
                id: c.id || c.addressId,
                infoWindow: {
                    infoWindowHeader: c.name,
                    infoWindowBody: address.addressLine
                }
            });
        }, <Marker[]>[]);
    }

    private _getDriverMarkers(drivers) {
        if (!drivers.length) { return []; }
        return drivers.reduce((p, c) => {
            if (!c.latitude || !c.longitude) { return p; }
            return p.concat({
                latLng: { lat: +c.latitude, lng: +c.longitude },
                id: c.userAccountId,
                activeIconUrl: 'assets/img/stops-list/marker_driver.png',
                iconUrl: 'assets/img/stops-list/marker_driver.png',
                infoWindow: {
                    infoWindowHeader: c.name,
                    infoWindowBody: this._formattedDate(c.lastUpdated)
                }
            });
        }, <Marker[]>[]);
    }

    private _formattedDate(date) {
        return this._datePipe.transform(date || new Date(), 'M/d/yy, h:mm a');
    }
}