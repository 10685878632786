import { TrackingInfoVo } from './../../models/tracking.model';
import { Component, Input, OnInit } from '@angular/core';
import {ConfigService} from '../../../app.config.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tuya-tracking-driver-info',
  templateUrl: './tracking-driver-info.component.html',
  styleUrls: ['./tracking-driver-info.component.scss']
})
export class TrackingDriverInfoComponent implements OnInit {

  @Input() public trackingInfo: TrackingInfoVo = <TrackingInfoVo>{};

  constructor(private configService: ConfigService,
              public sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  public get driverImageUrl() {
      return this.trackingInfo.driverPhoto || '../../../../assets/img/my-orders/img_driver_avatar.svg';
    }
}
