<div class="home-wrapper">
    <div class="home-container">
        <div class="home-content">
            <div class="content-image"></div>
            <h1>{{'tuya-driver.home.header' | translate }}</h1>
            <div class="title"><div class="logo"></div><div class="text">{{'tuya-driver.home.text' | translate }}</div></div>
            <p><a class="proceed" [href]="getAppLink()">{{'tuya-driver.home.proceed' | translate }}</a></p>
            <p class="buttons">
                <!-- Android -->
                <a class="btn btn-link btn-android" [href]="appConfig.driverAndroidApp"></a>
                <!-- iOS -->
                <a class="btn btn-link btn-ios" [href]="appConfig.driverIosApp"></a>
            </p>
        </div>
        <div class="circle-background"></div>
    </div>
</div>