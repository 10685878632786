import { Component, Input, OnInit } from '@angular/core';
import { OrderDetailsSummary } from '../../model/my-orders.model';
import { OrderStatusId } from '../../../core/models/dto';
import { OrderUtils } from '../../utils/order.utils';

@Component({
    selector: 'tuya-order-status-bar',
    templateUrl: './order-status-bar.component.html',
    styleUrls: ['./order-status-bar.component.scss']
})
export class OrderStatusBarComponent implements OnInit {

    _orderSummary: OrderDetailsSummary;
    color: any;
    content: string;
    @Input() isLoading = false;
    @Input() set orderSummary(data: OrderDetailsSummary) {
        this._orderSummary = data;
        switch (data.orderStatusType) {
            case OrderStatusId.Draft: {
                this.color = { 'status-yellow': true };
                this.content = 'tuya-my-orders.status-bar.draft';
                break;
            }
            case OrderStatusId.Offered: {
                this.color = { 'status-yellow': true };
                this.content = 'tuya-my-orders.status-bar.awaiting-driver';
                break;
            }
            case OrderStatusId.Assigned: {
                this.color = { 'status-orange': true };
                this.content = 'tuya-my-orders.status-bar.accepted';
                break;
            }
            case OrderStatusId.InProgress: {
                this.color = { 'status-orange': true };
                this.content = 'tuya-my-orders.status-bar.in-progress';
                break;
            }
            case OrderStatusId.Declined: {
                this.color = { 'status-red': true };
                this.content = 'tuya-my-orders.status-bar.declined';
                break;
            }
            case OrderStatusId.Completed: {
                this.color = { 'status-green': true };
                this.content = 'tuya-my-orders.status-bar.completed';
                break;
            }

            case OrderStatusId.CompletedWithExceptions: {
                this.color = { 'status-green': true };
                this.content = 'tuya-my-orders.status-bar.completed';
                break;
            }

            case OrderStatusId.Canceled: {
                this.color = { 'status-red': true };
                this.content = 'tuya-my-orders.status-bar.cancelled';
                break;
            }

            case OrderStatusId.PendingCancellation: {
                this.color = { 'status-red': true };
                this.content = 'tuya-my-orders.status-bar.pending-cancellation';
                break;
            }

            case OrderStatusId.Released: {
                this.color = { 'status-red': true };
                this.content = 'tuya-my-orders.status-bar.released';
                break;
            }
        }
    }

    get orderSummary() {
        return this._orderSummary;
    }

    constructor() { }

    ngOnInit() {
    }

    showDate() {
        return OrderUtils.showDateForOrderBar(this.orderSummary.orderStatusType);
    }
}
