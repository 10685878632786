<div bsModal #staticModal="bs-modal" class="modal fade" role="dialog" [config]="{ignoreBackdropClick: true}">
  <div class="modal-content" *ngIf="alert === AlertType.NotInMsa; else notInTexas">
        <div class="modal-header">
            <span class="message-msa" [translate]="'tuya-shipment.initial-step.not-in-msa-message'"></span>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="onCloseModal()"
            [translate]="'tuya-shipment.initial-step.ok-btn'"></button>
        </div>
  </div>
  <ng-template #notInTexas>
    <div class="modal-content">
      <div class="modal-header ">
          <span class="message-texas" [translate]="'tuya-shipment.shipment-builder.not-in-texas'"></span>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-lg btn-texas" (click)="onCloseModal()"
          [translate]="'tuya-shipment.initial-step.ok-btn'"></button>
      </div>
    </div>
  </ng-template>

</div>
