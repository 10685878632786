<div class="wrapper" *ngIf="currentManageCompanyTab === manageCompanyTab.PaymentMethods">
    <table class="cards-table">
        <tr class="t-head">
            <td>{{'tuya-company.payment.card' | translate}}</td>
            <td>{{'tuya-company.payment.name' | translate}}</td>
            <td>{{'tuya-company.payment.expiration' | translate}}</td>
            <td></td>
        </tr>
    </table>
    <div class="sub-wrapper">
        <div class="scroll-wrap" *ngIf="!(isGettingPaymentMethods$ | async)"
            [ngStyle]="{'height.px': getScrollHeight()}">
            <perfect-scrollbar>
                <table class="cards-table">
                    <tr class="card-item" *ngFor="let card of paymentMethods">
                        <td class="card" [ngClass]="{'default': card.isDefault}">
                            <p *ngIf="card.name">{{card.name}}</p>
                            <p>
                                <span
                                    *ngIf="card.cardTypeId !== cardTypes.Undefined">{{cardTypes[card.cardTypeId].toUpperCase()}}&nbsp;</span>
                                <span>{{'tuya-company.payment.ending-in' | translate}}</span>
                                {{card.cardNumberHash.slice(-4)}}
                            </p>
                        </td>
                        <td class="name">
                            {{card.cardHolder}}
                        </td>
                        <td class="expiration">
                            {{getExpirationDate(card.expiresMonth, card.expiresYear)}}
                        </td>
                        <td class="controls">
                            <!--for future delete and edit card-->
                            <!--<span class="edit">-->
                            <!--edit-->
                            <!--<i class="fa fa-times"></i>-->
                            <!--</span>-->
                        </td>
                    </tr>
                </table>
            </perfect-scrollbar>
        </div>
        <div class="spinner-wrapper" *ngIf="isGettingPaymentMethods$ | async">
            <tuya-spinner size="fullcontained" clear="true"></tuya-spinner>
        </div>
    </div>
</div>

<!-- + Add New Card -->
<div class="btn-wrap" *ngIf="currentManageCompanyTab === manageCompanyTab.PaymentMethods">
    <input type="button" class="button" title="Add new Credit Card" [disabled]="suspended"
        [value]="displayButtonText() | translate" [ngClass]="suspended ? 'suspendedClass' : ''"
        (click)="dispatchOpenModal()" />
</div>

<!-- Add/replace CC modal -->
<ng-template #addCard>
    <div class="modal-content" [style.display]="showForm ? 'block' : 'none'">
        <div class="modal-header operation-name">{{displayButtonText() | translate}}</div>
        <div class="frame-wrapper modal-boady">
            <!-- <tuya-payment-frame 
                [height]="460"
                [width]="590"
                (frameMsgEvt)="onPaymentFrameEvent($event)"></tuya-payment-frame> -->
            <stripe-payment-frame [height]="460" [width]="590">
            </stripe-payment-frame>
        </div>
        <div class="modal-footer controls">
            <!-- <button class="btn btn-default col-sm-3 btn-right cancel-button"
                        type="button"
                        (click)="cancelCreation()"
                        [translate]="'tuya-company.general.cancel'"></button> -->
            <button class="btn btn-primary col-sm-3 save-button" title="Close after credit card verification"
                [translate]="'tuya-profiles.btn.close'" (click)="validateCard()" type="submit"></button>
        </div>
    </div>

    <!-- <div class="close-prevent" [style.display]="showForm ? 'none' : 'block'" >
        <div class="cancel-modal-body" >
            <div class="modal-header operation-name">{{ displayButtonText() | translate}}</div>
            <h3> {{'tuya-company.payment.loose-changes' | translate}}</h3>
        </div>
        <div class="modal-footer controls">
            <button class="btn btn-default col-sm-3 btn-right cancel-button"
                    type="button"
                    (click)="dispatchCloseModal()"
                    [translate]="'tuya-profiles.btn.ok'"></button>
            <button class="btn btn-primary col-sm-3 btn-right save-button"
                    [translate]="'tuya-company.general.cancel'"
                    (click)="continueCardCreation()"
                    type="submit"></button>
        </div>
    </div> -->
</ng-template>