import { AddressDto, OrderCollapsedViewDto } from '../../core/models/dto';
import { CanceledOrderInfo, ShipperAssessment } from './driver-info.model';
import { OrderEventsState, ItemSizeType } from './order-events.model';

export class OrderListItem {
    date: string;
    stopFrom: { short: string, long: string };
    stopTo: { short: string, long: string };
    nStops: number;
    deliveryPro: string;
    total: number;
    statusId: any;
    orderId: any;
    additionalData: { [key: string]: any };
    recurrenceOrderDetail: any;
}

export class OrdersTableState {
    currentOrdersId: Array<any>;
    ordersCommonInfo: { [key: string]: OrderCollapsedViewDto };
    recurrenceOrderDetail: any;
    isLoading: boolean;
}

export class OrdersDetailsState {
    ordersExpandedInfo: { [key: string]: OrderExpandedView };
    canceledOrderInfo: CanceledOrderInfo | null;
    activeOrderDetails: OrderExpandedView;
    isLoading: boolean;
    cancelOrderLoading: boolean;
    recurringId: number;
}

export class MyOrdersState {
    pagination: PaginationState;
    ordersTable: OrdersTableState;
    ordersDetails: OrdersDetailsState;
    rateReasons: Array<RateReason>;
    orderEvents: OrderEventsState;
}

export class PaginationState {
    pageSize: number;
    currentPageNumber: number;
    sortDirection: number;
    filter?: any;
    statuses: any;
    sortField: string;
    totalItemCount?: number;
    hasExceptions: boolean;
}

export class OrderDetails {
    pools: Array<Pool>;
    driversPools: Array<DriverPools>;
}

export class Pool {
    id?: any;
    name: string;
    shipperUserAccountId?: any;
}

export class DriverPools {
    driverId: any;
    pools: Array<Pool>;
}

export class OrderSummary {
    items: number;
    ibs: number;
    miles: number;
    returns: number;
    stops: number;
    exceptionsCount?: number;
}

export class OrderStop {
    address: AddressDto;
    items: Array<ItemSizeType>;
    levelRefId: any;
    name: string;
    pickupNoEarlerThan: string;
    pickupNoLaterThan: string;
    pickupItemsCompleted: boolean;
    typeId: number;
    statusId: number;
    completedTimeStamp: number;
    referenceId: any;
    stopId: any;
}

export class OrderDriver {
    name: string;
    rate: number;
    userAccountId: any;
    image: string;
}

export class OrderAssignments {
    assessment: ShipperAssessment;
    drivers: Array<OrderDriver>;
    hasAssignedDrivers: boolean;
    totalDeclinesCount: number;
    totalDriversCount: number;
    isDriverAddedToPool?: boolean; // UI needs isDriverAddedToPool and addedPools values to show particular elements
    addedPools?: Array<any>;
}

export class StatisticInfo {
    declinedDriverName: string;
    declinedDriversCount: number;
    offeredDriversCount: number;
}

export class OrderExpandedView {
    assignments?: OrderAssignments;
    code: any;
    statusId?: number;
    directOrder: boolean;
    isCreatedOnMobile: boolean;
    orderId: any;
    orderSummary: OrderSummary;
    stopList: Array<OrderStop>;
    rateReasons?: Array<RateReason>;
    creationDate?: string;
    driverResponceStatistics: StatisticInfo;
    endDate?: number;
    total: number;
    referenceId: any;
    exceptionsCount: number;
    driverArrivedAtFirstStop: boolean;
}

export class OrderDetailsSummary {
    orderStatusType: number;
    exceptionsCount: number;
    endDate: number;
}

export class RateReason {
    id: number;
    name: String;
    sequenceNumber: number;
}

export enum StopStatus {
    New = 10,
    DriverArrived = 20,
    ArrivalAttempt = 25,
    Completed = 30,
    CompletedWithException = 40,
    DeliveryAttempt = 50
}

export enum OrderFilters {
    Draft = 1,
    Open = 2,
    Closed = 3,
    Blocked = 4
}

export enum SortDirection {
    Asc = 1,
    Desk = 2
}

