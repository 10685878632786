import { Component, Input, OnInit } from '@angular/core';

export enum STEP_STATE {
    NEW,
    ACTIVE,
    FINISHED,
}

export function makeMapStepState(activeStep) {
    return function mapStepState(value, index) {
        if (index < activeStep) {
          return STEP_STATE.FINISHED;
        }
        if (index === activeStep) {
          return STEP_STATE.ACTIVE;
        }
        return STEP_STATE.NEW;
    };
}

@Component({
  selector: 'tuya-step-progress-bar',
  templateUrl: './step-progress-bar.component.html',
  styleUrls: ['./step-progress-bar.component.scss']
})
export class StepProgressBarComponent implements OnInit {

  STEP_STATE: typeof STEP_STATE = STEP_STATE;

  private _activeStep = 0;
  private _stepsState: STEP_STATE[] = new Array<STEP_STATE>();

  @Input() set activeStep(stepN: number) {
    this._activeStep = stepN || 0;
    this._stepsState = this._stepsState.map(makeMapStepState(this._activeStep));
  }
  get activeStep() { return this._activeStep; }

  @Input() set stepList(newStepList: Array<string>) {
    if (this._stepsState.length === newStepList.length) { return; }
    this._stepsState = newStepList.map(makeMapStepState(this._activeStep));
  }
  get stepsState() { return this._stepsState; }

  constructor() { }

  ngOnInit() {
  }



}
