import { OrderEditMode, OrderDto } from '../../core/models/order.dto';
import { OrderStatusId } from '../../core/models/dto';


export function getOrderEditMode(statusId: number): OrderEditMode {

    if (statusId === OrderStatusId.Draft || statusId === OrderStatusId.Offered) {
        return OrderEditMode.NotAccepted;
    } else if (statusId === OrderStatusId.Assigned) {
        return OrderEditMode.Accepted;
    } else if (statusId === OrderStatusId.InProgress) {
        // @TODO THIS IS TEMP Should be also stops in count!!!!
        return OrderEditMode.PartiallyCompleted;
    } else {
        return OrderEditMode.Other;
    }
}

export function isEditOrderDisabled(statusId: number) {
    return (statusId > OrderStatusId.Declined) && (statusId < OrderStatusId.Released);
}

export function getCurrentStep(order: any, isDuplicateMode: boolean): number {
    return (order.stops[0].completionDateTime === null || isDuplicateMode) ? 0 : 1;
}
