<tuya-simple-autocomplete
            [isRequired]="true"
            [disable]="isControlDisabled"
            [className]="className"
            [placeholder]="placeholder"
            [searchResults]="addressAutocompletFoundItems$ | async"
            [isLoading]="addressAutoCompleteIsLoading"
            (loadSearchResults)="onRequestSearchResults($event)"
            [focusAndClear]="focusAndClear"
            [isInvalid]="isInvalid"
            (clearSearchResults)="onClearSearchResults()"
            (selectValue)="onSelectStopItem($event)">
</tuya-simple-autocomplete>
<tuya-address-alert-modal
        *ngIf="(alert$ | async)?.type === AlertType.NotInMsa || (alert$ | async)?.type === AlertType.NotInTexas"
        [alert]="(alert$ | async)?.type"
        (clearSearchInput)="onClearSearchInput($event)">
</tuya-address-alert-modal>
