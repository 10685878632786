import { Injectable } from '@angular/core';
import { BaseService } from '../../core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../app.config.service';

const GET_CURRENT_BUILD = '/CurrentBuild';

@Injectable()
export class BackVersionService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    getBackVersion() {
        return this.api().GET(GET_CURRENT_BUILD, { responseType: 'text'});
    }
}
