import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';

import { Action } from '../../core/models/redux.action';
import {
    AddressAutocompleteActions,
    CHECK_ADDRESS_IN_MSA,
    SELECT_AUTOCOMPLETE_ITEM,
    SELECT_AUTOCOMPLETE_ITEM_SUCCESS
} from '../actions/address-autocomplete.actions';
import { SET_WIZARD_STEP_CONTINUE_ALLOWED, UIActions, LOADING } from '../actions/ui.actions';
import { ShipmentService } from '../services/shipment.service';
import { StopType } from '../models/order.model';
import { MapActions } from '../../core/actions/map.actions';
import { ItemSelectOptions } from '../models/address-autocomplete.model';
import {
    SHIPMENT_ORDER_ADD_STOP,
    SHIPMENT_ORDER_UPDATE,
    SHIPMENT_ORDER_UPDATE_STOP_ADDRESS, ShipmentActions
} from '../actions/shipment.actions';
import { WizardStep } from '../models/ui.model';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';
import { StopDtoType } from '../../core/models/dto';
import { switchMap, exhaustMap, catchError } from 'rxjs/operators';

@Injectable()
export class AddressAutocompleteEpics {
    constructor(private actions: AddressAutocompleteActions,
        private uiActions: UIActions,
        private shipmentActions: ShipmentActions,
        private mapActions: MapActions,
        private alertActions: AlertActions,
        private shipmentService: ShipmentService) {
    }

    public createEpic() {
        return combineEpics(
            createSelectAutocompleteItem(this.actions),
            createCheckIsAddressInMsaEpic(this.actions, this.shipmentActions, this.shipmentService, this.alertActions),
            createSelectAutocompleteItemSuccess(this.actions, this.mapActions)
        );
    }
}


export function createSelectAutocompleteItem(actions: AddressAutocompleteActions) {
    return (action$) => action$.pipe(
        ofType(SELECT_AUTOCOMPLETE_ITEM),
        switchMap((action: any) => {
            const result = action.payload.item;
            if (!action.payload.item) {
                return of(actions.selectAutocompleteFailed('There is no assotiated item in results'));
            }
            // always check MSA, which will return SELECT_AUTOCOMPLETE_ITEM_SUCCESS to complete the epic
            return of(actions.checkIsAddressInMsa(result, action.payload.options));
        })
    );
}

export function createCheckIsAddressInMsaEpic(
    actions: AddressAutocompleteActions,
    shipmentActions: ShipmentActions,
    service: ShipmentService,
    alertActions: AlertActions
) {
    return (action$) => action$.pipe(
        ofType(CHECK_ADDRESS_IN_MSA),
        exhaustMap((action: any) => {
            return service.checkAddressIsInMSARange(action.payload.stop.address).pipe(
                switchMap(result => {
                    if (!(result as { value: boolean }).value) {
                        if (action.payload.options.stopId) {
                            return of(
                                alertActions.show({ message: 'Address is not in MSA range', type: AlertType.NotInMsa }),
                                shipmentActions.removeStopAddress(action.payload.options.stopId)
                            );
                        }
                        return of(alertActions.show({ message: 'Address is not in MSA range', type: AlertType.NotInMsa }));
                    } else {
                        return of(actions.selectAutocompleteSucceeded(action.payload.stop, action.payload.options));
                    }
                }),
                catchError(error => of(alertActions.show({ message: error.message || 'An error occurred', type: AlertType.Error })))
            );
        })
    );
}

export function createSelectAutocompleteItemSuccess(actions: AddressAutocompleteActions, mapActions: MapActions) {
    return (action$, store) => action$.pipe(
        ofType(SELECT_AUTOCOMPLETE_ITEM_SUCCESS),
        switchMap((action: any) => {
            const selectedStopItem = action.payload.item;
            const options: ItemSelectOptions = action.payload.options;
            let onSelectActions: Array<Action<any, any>>; // <=4
            const step = store.value.shipment.ui.wizardStep;

            // for initial Step Order Draft isn't created yet - so just update stop
            if (step === WizardStep.InitialStopEdit || step === WizardStep.Initial) {
                onSelectActions = [{
                    type: SHIPMENT_ORDER_ADD_STOP,
                    payload: { typeId: StopType.Pickup, stopTemplate: selectedStopItem, isFirstStop: true }
                }];
            } else if (options.stopId) {
                onSelectActions = rewriteOrChangeAddress(selectedStopItem, options);
            } else {
                // TODO: move it to the another action
                // Backend request to save order and set stop to edit mode
                // TODO: split shipment page -- collection worker
                const targetStep = (options.stopType === StopType.Pickup)
                    ? WizardStep.InitialStopEdit
                    : WizardStep.EditStopDetails;
                onSelectActions = [
                    {
                        type: SHIPMENT_ORDER_ADD_STOP,
                        payload: { typeId: options.stopType, stopTemplate: selectedStopItem, isFirstStop: false }
                    },
                    {
                        type: SHIPMENT_ORDER_UPDATE,
                        payload: {
                            isUpdateSelectedStop: true,
                            isSavedStopChosen: selectedStopItem.stopDtoType === StopDtoType.STOP
                        }
                    },
                    {
                        type: LOADING,
                        payload: true
                    },
                    {
                        type: SET_WIZARD_STEP_CONTINUE_ALLOWED,
                        payload: { step: targetStep, allowed: false }
                    }
                ];
            }

            return of(
                mapActions.setCenter({ lat: selectedStopItem.address.latitude, lng: selectedStopItem.address.longitude }),
                ...onSelectActions,
                actions.onAutocompleteItemSelected()
            );
        })
    );
}


// if the user chooses stop in the autocomplete we rewrite existing stop, in case address or object we change only address field
function rewriteOrChangeAddress(item, opt: ItemSelectOptions) {
    if (item.stopDtoType === StopDtoType.STOP) {
        return [
            {
                type: SHIPMENT_ORDER_ADD_STOP,
                payload: { typeId: opt.stopType, stopTemplate: item, isFirstStop: opt.firstStop, stopId: opt.stopId }
            },
            {
                type: SHIPMENT_ORDER_UPDATE,
                payload: { isUpdateSelectedStop: false }
            },
            {
                type: LOADING,
                payload: true
            }
        ];
    }
    return [
        {
            type: SHIPMENT_ORDER_UPDATE_STOP_ADDRESS,
            payload: { addr: item.address, stopId: opt.stopId }
        },
        {
            type: SHIPMENT_ORDER_UPDATE,
            payload: { isUpdateSelectedStop: false }
        },
    ];
}
