import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../app.config.service';
import { Meta, DomSanitizer } from '@angular/platform-browser';
import { Configuration } from '../core/models/base.config';

@Component({
    selector: 'tuya-driver-home',
    templateUrl: './driver-home.component.html',
    styleUrls: ['./driver-home.component.scss']
})

export class DriverHomeComponent implements OnInit {
    appConfig: Configuration;
    isAndroid = false;
    sanitize: Function;

    constructor(public configService: ConfigService, private meta: Meta, private sanitizer: DomSanitizer) {
        this.sanitize = sanitizer.bypassSecurityTrustUrl;
    }

    ngOnInit() {
        this.isAndroid = navigator.userAgent.toLowerCase().indexOf('android') !== -1;
        this.appConfig = this.configService.getConfiguration();
        this.meta.addTag({ name: this.appConfig.driverIosAppName, content: `app-id=${this.appConfig.driverIosAppId}` });
    }

    getAppLink() {
        return this.isAndroid ? this.sanitize(this.appConfig.mobileAppAndroidDriver + 'verifiedEmail') :
            this.appConfig.mobileAppDomain + 'driver/home';
    }
}
