import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    AfterViewInit,
    OnChanges,
    OnDestroy,
    SimpleChanges
} from '@angular/core';
import { Stop } from '../../../shipment/models/order.model';
import { OfferType } from '../../../builder/models/order.model';
import { OrderEditMode, AssignmentsDto } from '../../../core/models/order.dto';
import { DriverDto, PoolDto, PoolSelectDto } from '../../../core/models/dto';
import { SelectComponent } from '../../../core/components/pool-select';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { OrderSelectors } from '../../../shipment/selectors/order.selectors';
import { ShipmentEditSelectors } from '../../../shipment-edit/selectors/shipment-edit.selectors';
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'tuya-assign-driver',
    templateUrl: './assign-driver.component.html',
    styleUrls: ['./assign-driver.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignDriverComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @ViewChild('exclusiveDriversSelect', { static: true }) exclusiveDriversSelect: SelectComponent;
    @ViewChild('driverPoolsSelect', { static: true }) driverPoolsSelect: SelectComponent;

    orderEditMode;

    dontDebounce = false;

    @select(ShipmentEditSelectors.selectEditMode)
    orderEditMode$: Observable<OrderEditMode>;
    @select(OrderSelectors.orderDeliveryTypeSelector)
    orderDeliveryType$: Observable<number>;
    @select(OrderSelectors.orderStopListSelector)
    orderStopList$: Observable<Stop[]>;
    @select(['admin', 'shipperAdmin', 'isReofferLoading'])
    isLoading$: Observable<boolean>;

    @Output() driverSearch = new EventEmitter<object>();
    @Output() setExtendable = new EventEmitter<boolean>();
    @Output() driverTypeOffer = new EventEmitter<any>();
    @Input() isExtendable = false;
    @Input() vehicleType;
    @Input() isDriversLoading;

    public disabledLimited: boolean;
    public driver = null;
    public disabledExclusive: boolean;
    public OfferType: typeof OfferType = OfferType;
    public OrderEditMode: typeof OrderEditMode = OrderEditMode;
    public $inputSubject = new Subject<string>();
    private destroy$ = new Subject<void>();  // For automatic cleanup
    public MIN_LENGTH_STRING = 6;
    public disabledIsExtendable = false;

    private _pools: PoolDto[] = [];    
    private driverIdsFromDriverList: Array<any> = [];
    private poolsIdsFromPoolsList: Array<any> = [];
    private _assignments: AssignmentsDto = <AssignmentsDto>{};
    private _drivers: DriverDto[] = [];
    private driverTypeClickSubject = new Subject<OfferType>();

    get pools() {
        return this._pools;
    }

    get drivers() {
        return this._drivers;
    }

    private _offerType: OfferType = OfferType.Any;

    @Input() set offerType(offerType) {
        this._offerType = offerType;
        this.disableSearchFields(offerType);
    }

    get offerType() {
        return this._offerType;
    }

    @Input() set assignments(assignments) {        
        if (!assignments) assignments = { drivers: [], pools: [] };        
        this._assignments = assignments;
        this.addDataToSelects();
    }

    @Input() set drivers(drivers: DriverDto[]) {
        this._drivers = [...drivers] || [];  // Always create a new array reference
    }
    
    @Input() set pools(pools: PoolDto[]) {
        this._pools = [...pools.filter(item => item.drivers.length)];
    }

    constructor(private cdr: ChangeDetectorRef) {
        this.driverTypeClickSubject.pipe(
            debounceTime(300)  // Adjust debounce time as needed
        ).subscribe(event => {
            this.handleDriverTypeClick(event);
        });

        this.setupInputSubscription();
    }
    
    private setupInputSubscription(): void {
        this.$inputSubject.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            takeUntil(this.destroy$)
        ).subscribe(term => {
            this.driverSearch.next({ term, vehicle: 0 });
        });
    }

    ngOnInit() {
        this.exclusiveDriversSelect.items = [];
        this.offerType = OfferType.Any;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.drivers && this.dontDebounce) {
            this._onSendInputData(changes.drivers.currentValue);
        }
        this.dontDebounce = true;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onInputChange(value: string): void {
        this.$inputSubject.next(value);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.addDataToSelects();  // Deferred to avoid ExpressionChangedAfterItHasBeenCheckedError
            this.cdr.detectChanges();  // Force Angular to detect changes
        });
    }

    disableSearchFields(offerType: OfferType) {
        if (offerType === this.OfferType.Any) {
            this._setSelectsState(true);
        } else if (offerType === this.OfferType.DriversPool) {
            this._setDisabledExclusive(true);
            this._setDisabledLimited(false);
        } else if (offerType === this.OfferType.Exclusive) {
            this._setDisabledLimited(true);
            this._setDisabledExclusive(false);
        }

        this.setExtendableBehaviour(offerType === this.OfferType.Any);
    }

    public onTypedEventHandler(input: string) {
        if (input.length >= this.MIN_LENGTH_STRING) {
            this.$inputSubject.next(input);
        }
    }

    public isDisabledDriverSection() {
        return this.orderEditMode === OrderEditMode.PartiallyCompleted ||
            this.orderEditMode === OrderEditMode.Accepted;
    }

    public getDisabledAnyStatement(): boolean {
        return !(this.isDisabledDriverSection() && this.offerType !== OfferType.Any);
    }

    public getDisabledExclusiveStatement(): boolean {
        return !(this.isDisabledDriverSection() && this.offerType === OfferType.Any);
    }

    public clearSelectedDriversTags() {
        this.exclusiveDriversSelect.clearActiveTags();
        this.driverPoolsSelect.clearActiveTags();
    }

    public setTagsIntoSelect(component: SelectComponent, options: PoolSelectDto[] | DriverDto[]) {
        component.setSelectOptions(options);
        component.addTagsToSelect();
    }

    public submitSelectedPools(e) {
        this.poolsIdsFromPoolsList = this.getPoolsIdsArrayFromPools(e);
        if (this.offerType !== this.OfferType.Any) {
            this._updateOrder(this._getAssignedData());
        }
    }

    public changeExtendable() {
        this.setExtendable.emit(this.isExtendable);
    }

    public driverTypeClick(event: OfferType) {
        this.driverTypeClickSubject.next(event);  // Debounce this change
    }

    private handleDriverTypeClick(event: OfferType) {
        this.driverTypeOffer.emit({ type: event, data: {} });
        this._offerType = event;
    
        if (event === this.OfferType.Any) {
            this.clearSelectedDriversTags();
        } else if (event === this.OfferType.DriversPool) {
            this.exclusiveDriversSelect.clearActiveTags();
        } else if (event === this.OfferType.Exclusive) {
            this.driverPoolsSelect.clearActiveTags();
        }
    
        this.disableSearchFields(event);
    }

    public submitSelectedDrivers(e) {
        this.driverIdsFromDriverList = this.getUniqDriversidsArrayFromDrivers(e);
        if (this.offerType === this.OfferType.Any) return;

        this._updateOrder(this._getAssignedData());
    }

    private _getAssignedData() {
        return {
            type: this.offerType,
            data: {
                driverUserAccountIds: this.driverIdsFromDriverList,
                driverPoolIds: this.poolsIdsFromPoolsList
            }
        };
    }

    private getPoolsIdsArrayFromPools(selectedItems) {
        return selectedItems.map(item => ({
            id: item.id,
            name: item.name
        }));
    }

    private _updateOrder(assignData: Object | null) {
        this.driverTypeOffer.emit(assignData);
    }

    private addDataToSelects() {
        if (this._assignments.drivers && this._assignments.drivers.length) {
            this.setTagsIntoSelect(this.exclusiveDriversSelect, this._assignments.drivers);
        }
        if (this._assignments.pools && this._assignments.pools.length) {
            this.setTagsIntoSelect(this.driverPoolsSelect, this._assignments.pools);
        }
    }

    private _setSelectsState(state: boolean) {
        this._setDisabledExclusive(state);
        this._setDisabledLimited(state);
    }

    private _setDisabledExclusive(state: boolean) {
        this.disabledExclusive = state;
    }

    private _setDisabledLimited(state: boolean) {
        this.disabledLimited = state;
    }

    private _onSendInputData(drivers) {
        if (drivers.length) {
            this.exclusiveDriversSelect.items = drivers;
            this.exclusiveDriversSelect.openSelect();
        } else {
            this.exclusiveDriversSelect.items = [];
        }
    }

    private setExtendableBehaviour(assignmentType: boolean) {
        this.disabledIsExtendable = assignmentType;
        this.isExtendable = !assignmentType;
        this.changeExtendable();
    }

    private getUniqDriversidsArrayFromDrivers(selectedItems) {
        return [...new Set(selectedItems.map(item => item.id))];
    }
}
