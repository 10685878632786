import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../../core/models/redux.action';
import { AddressDto } from '../models/address.model';

export type BuilderAction = Action<any, any>;

export const BUILDER_INIT = '[BUILDER] Initialize';
export const BUILDER_ADD_ITEM_TO_ORDER = '[BUILDER] Add Item To Order';
export const BUILDER_REMOVE_ITEM_FROM_ORDER = '[BUILDER] Remove Item From Order';
export const BUILDER_EDIT_ITEM = '[BUILDER] Edit item';
export const BUILDER_FORM_UPDATE = '[BUILDER] Form Updated';
export const BUILDER_ADD_STOP_TO_ORDER = '[BUILDER] Add Stop';
export const BUILDER_EDIT_STOP = '[BUILDER] Edit Stop';
export const BUILDER_SUMMARY_UPDATE = '[BUILDER] Update Summary';
export const SET_ORDER_SUMMARY = '[BUILDER] Set Summary';
export const BUILDER_SET_VEHICLE_TYPE = '[BUILDER] Set Vehicle type';
export const BUILDER_SELECT_DRIVERS = '[BUILDER] Select Drivers';
export const BUILDER_SET_USER_ID = '[BUILDER] Set user id';
export const BUILDER_SELECT_POOLS = '[BUILDER] Select pools';
export const BUILDER_UPDATE_ORDER = '[BUILDER] Update order';
export const BUILDER_SET_DRIVER_SELECT_SEARCH_TERM = '[Builder] Set driver select search term';
export const BUILDER_SET_DRIVER_SELECT_PENDING = '[Builder] Set pending on driver search select';
export const BUILDER_SET_DRIVER_SELECT_SEARCH_RESULTS = '[Builder] Set driver select search results';
export const BUILDER_SET_CURRENT_STEP = '[Builder] Set accordion current step';
export const BUILDER_ADDRESS_SELECT = '[Builder] Address search result selected';
export const BUILDER_DISPLAY_SPINNER = '[Builder] Display loading spinner';
export const BUILDER_HIDE_SPINNER = '[Builder] Hide loading spinner';
export const BUILDER_EDIT_NAME_OR_DESCRIPTION = '[Builder] Edit address nameOrDescription';
export const BUILDER_EDIT_PHONE = '[Builder] Edit phone number';
export const BUILDER_POST_SUCCESS = '[Builder] Post order success :)';
export const BUILDER_POST_FAILURE = '[Builder] Post order failure :(';
export const BUILDER_PUT_SUCCESS = '[Builder] Put order success :)';
export const BUILDER_CONFIRM_SUCCESS = '[Builder] Put order confirm success :)';
export const BUILDER_CONFIRM_FAILURE = '[Builder] Put order confirm failure :(';
export const BUILDER_TIME_SELECTION_CHANGE = '[Builder] Time type selection';
export const BUILDER_SUBMIT_ERROR = '[Builder] Submit error';
export const BUILDER_CLEAR_SUBMIT_ERROR = '[Builder] Clear submit errors';
export const BUILDER_EDIT_TIME = '[Builder] Edit time';
export const BUILDER_CHECK_ADDRESS_IN_MSA = '[Builder] Check if address is in MSA.';
export const BUILDER_SHOW_ERROR_MODAL = '[Builder] Show error modal';
export const BUILDER_EDIT_LOAD_ORDER = '[Builder] Fetch order for builder edit';
export const BUILDER_EDIT_LOAD_ORDER_SUCCESS = '[Builder] Fetch order for builder edit success';
export const BUILDER_EDIT_LOAD_ORDER_FAIL = '[Builder] Fetch order for builder edit fail';
export const BUILDER_EDIT_ORDER = '[Builder] Edit order';
export const BUILDER_UPDATE_MODE = '[Builder] Update order mode';
export const BUILDER_FORM_NAV_PROMPT_REQUIRED = '[Builder] Set nav prompt required';
export const BUILDER_FORM_NAV_PROMPT_NOT_REQUIRED = '[Builder] Set nav prompt not required';
export const BUILDER_SET_ROUTIFIC_ERROR = '[Builder] Set Routific error';
export const BUILDER_REMOVE_DELIVERY_STOP = '[Builder] Remove delivery stop';
export const BUILDER_CLEAR_ROUTIFIC_ERROR = '[Builder] Clear Routific error';

export const BUILDER_WAIT_TIME_PASSED = '[Builder] Summary Step five minutes passed';
export const UPDATE_INIT_PRICE = '[Builder] Update init price';

// ACTION ONLY FOR THE ADMIN
export const ALLOW_ORDER_PRICE_CHANGE = '[Builder] Allow ADMIN change order price';

export const SET_SCHEDULED_ORDER_SUBMIT = '[Builder] Scheduled order submit now value';

@Injectable()
export class BuilderActions {
    public payload;

    @dispatch()
    onBuilderInit = (payload): BuilderAction => ({
        type: BUILDER_INIT,
        payload: payload
    })

    @dispatch()
    onBuilderLoadEditOrder = (payload): BuilderAction => ({
        type: BUILDER_EDIT_LOAD_ORDER,
        payload: payload
    })

    @dispatch()
    onBuilderLoadEditOrderSuccess = (payload): BuilderAction => ({
        type: BUILDER_EDIT_LOAD_ORDER_SUCCESS,
        payload: payload
    })
    onBuilderLoadEditOrderFail = (): BuilderAction => ({
        type: BUILDER_EDIT_LOAD_ORDER_FAIL,
        payload: null
    })

    @dispatch()
    onAddItemToOrder = (item): BuilderAction => ({
        type:  BUILDER_ADD_ITEM_TO_ORDER,
        payload: item
    })

    @dispatch()
    onRemoveItemFromOrder = (index): BuilderAction => ({
        type: BUILDER_REMOVE_ITEM_FROM_ORDER,
        payload: index
    })

    @dispatch()
    onEditItem = (item, index): BuilderAction => ({
        type: BUILDER_EDIT_ITEM,
        payload: {
            'item': item,
            'index': index
        }
    })

    @dispatch()
    onFormUpdate = (payload): BuilderAction => ({
        type: BUILDER_FORM_UPDATE,
        payload: payload
    })

    onAddStop = (payload): BuilderAction => ({
        type: BUILDER_ADD_STOP_TO_ORDER,
        payload: payload
    })

    @dispatch()
    onEditStop = (stop, index): BuilderAction => ({
        type: BUILDER_EDIT_STOP,
        payload: {
            'stop': stop,
            'index': index
        }
    })

    @dispatch()
    onEditNameOrDescription = (nameOrDescription, stopIndex): BuilderAction => ({
        type: BUILDER_EDIT_NAME_OR_DESCRIPTION,
        payload: {
            'nameOrDescription': nameOrDescription,
            'stopIndex': stopIndex
        }
    })

    @dispatch()
    onEditPhoneNumber = (phoneNumber, stopIndex): BuilderAction => ({
        type: BUILDER_EDIT_PHONE,
        payload: {
            'phoneNumber': phoneNumber,
            'stopIndex': stopIndex
        }
    })

    @dispatch()
    onAddressSelect = (stop, index): BuilderAction => ({
        type: BUILDER_ADDRESS_SELECT,
        payload: {
            'stop': stop,
            'index': index
        }
    })

    onUpdateSummaryField = (key, value): BuilderAction => ({
        type: BUILDER_SUMMARY_UPDATE,
        payload: {
            summaryKey: key,
            summaryValue: value
        }
    })

    @dispatch()
    onSetSummaryField = (orderSummary): BuilderAction => ({
        type: SET_ORDER_SUMMARY,
        payload: orderSummary
    })

    @dispatch()
    onSetVehicleType = (vehicleObj): BuilderAction => ({
        type: BUILDER_SET_VEHICLE_TYPE,
        payload: vehicleObj
    })

    @dispatch()
    onSelectDrivers = (drivers): BuilderAction => ({
        type: BUILDER_SELECT_DRIVERS,
        payload: {
            drivers: drivers
        }
    })

    @dispatch()
    onSelectPools = (pools): BuilderAction => ({
        type: BUILDER_SELECT_POOLS,
        payload: {
            pools: pools
        }
    })

    setUserId = (userId): BuilderAction => ({
        type: BUILDER_SET_USER_ID,
        payload: {
            id: userId
        }
    })

    @dispatch()
    onUpdateOrder = (update): BuilderAction => ({
        type:   BUILDER_UPDATE_ORDER,
        payload: {
            key: update.key,
            value: update.value
        }
    })

    @dispatch()
    onSetSearchTerm = (term, identifier): BuilderAction => ({
        type: BUILDER_SET_DRIVER_SELECT_SEARCH_TERM,
        payload: {
            driverOrPool: identifier,
            searchTerm: term
        }
    })

    @dispatch()
    onSetPending = (pending, identifier): BuilderAction => ({
        type: BUILDER_SET_DRIVER_SELECT_PENDING,
        payload: {
            driverOrPool: identifier,
            pending: pending
        }
    })

    @dispatch()
    setDriverSelectSearchResult = (results, identifier): BuilderAction => ({
        type: BUILDER_SET_DRIVER_SELECT_SEARCH_RESULTS,
        payload: {
            driverOrPool: identifier,
            searchResults: results
        }
    })

    @dispatch()
    setCurrentStep = (from, to): BuilderAction => ({
        type: BUILDER_SET_CURRENT_STEP,
        payload: {
            from: from,
            to: to
        }
    })

    @dispatch()
    displaySpinner = (): BuilderAction => ({
        type: BUILDER_DISPLAY_SPINNER,
        payload: null
    })

    @dispatch()
    hideSpinner = (): BuilderAction => ({
        type: BUILDER_HIDE_SPINNER,
        payload: null
    })

    @dispatch()
    onPostOrderSuccess = (result): BuilderAction => ({
        type: BUILDER_POST_SUCCESS,
        payload: {
            result: result,
        }
    })

    @dispatch()
    onPostOrderFailure = (result): BuilderAction => ({
        type: BUILDER_POST_FAILURE,
        payload: {
            result: result
        }
    })

    @dispatch()
    onPutOrderSuccess = (result, isOptPrice = false, isthisAdmin = false): BuilderAction => ({
        type: BUILDER_PUT_SUCCESS,
        payload: {
            result: result,
            isOptimizePrice: isOptPrice,
            isAdmin: isthisAdmin
        }
    })

    @dispatch()
    onPutOrderConfirmSuccess = (orderCode): BuilderAction => ({
        type: BUILDER_CONFIRM_SUCCESS,
        payload: {
            orderCode: orderCode
        }
    })

    @dispatch()
    onTimeSelectionChange = (timeSelection, index): BuilderAction => ({
        type: BUILDER_TIME_SELECTION_CHANGE,
        payload: {
            selection: timeSelection,
            index: index
        }
    })

    @dispatch()
    onSubmitError = (index, key): BuilderAction => ({
        type: BUILDER_SUBMIT_ERROR,
        payload: {
            index,
            messageKey: key
        }
    })

    @dispatch()
    onEditTime = (stopIndex, fieldName, time): BuilderAction => ({
        type: BUILDER_EDIT_TIME,
        payload: {
            stopIndex,
            fieldName,
            time
        }
    })

    clearSubmitErrors = (index): BuilderAction => ({
        type: BUILDER_CLEAR_SUBMIT_ERROR,
        payload: {
            index
        }
    })

    @dispatch()
    checkAddressInMsa = (address: AddressDto, stopIndex: number) => ({
        type: BUILDER_CHECK_ADDRESS_IN_MSA,
        payload: {
            address,
            stopIndex
        }
    })

    // Edit Order
    @dispatch()
    onEditOrder = (orderId: number, isDuplicateMode: boolean = false): BuilderAction => ({
        type: BUILDER_EDIT_ORDER,
        payload: {
            orderId,
            isDuplicateMode
        }
    })

    @dispatch()
    onUpdateOrderMode = (mode: boolean): BuilderAction => ({
        type: BUILDER_UPDATE_MODE,
        payload: mode
    })

    @dispatch()
    setNavPromptRequired = () => ({
        type: BUILDER_FORM_NAV_PROMPT_REQUIRED,
        payload: null
    })

    @dispatch()
    setNavPromptNotRequired = () => ({
        type: BUILDER_FORM_NAV_PROMPT_NOT_REQUIRED,
        payload: null
    })

    @dispatch()
    setRoutificError = () => ({
        type: BUILDER_SET_ROUTIFIC_ERROR,
        payload: null
    })

    @dispatch()
    removeDeliveryStop = () => ({
        type: BUILDER_REMOVE_DELIVERY_STOP,
        payload: null
    })

    @dispatch()
    clearRoutificError = () => ({
        type: BUILDER_CLEAR_ROUTIFIC_ERROR,
        payload: null
    })

    @dispatch()
    builderWaitTimePassed = (val: boolean) => ({
        type: BUILDER_WAIT_TIME_PASSED,
        payload: val
    })

    @dispatch()
    updateInitPrice = (val: boolean) => ({
        type: UPDATE_INIT_PRICE,
        payload: val
    })

    @dispatch()
    scheduleOrderSubmitNow = (submitNow: boolean) => ({
        type: SET_SCHEDULED_ORDER_SUBMIT,
        payload: submitNow
    })

    @dispatch()
    allowOrderPriceChange = (isPriceChangeAllowed: boolean) => ({
        type: ALLOW_ORDER_PRICE_CHANGE,
        payload: isPriceChangeAllowed
    })

}
