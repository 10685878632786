import { StopStatusId } from './../../../core/models/dto';
import { TrackingInfoVo } from './../../models/tracking.model';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderStatusId } from '../../../core/models/dto';
import { Steps } from '../../models/tracking.model';


@Component({
    selector: 'tuya-tracking-stepper',
    templateUrl: './tracking-stepper.component.html',
    styleUrls: ['./tracking-stepper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackingStepperComponent {
    public steps = [];
    public orderStatusId = OrderStatusId;
    private _trackingInfo = <TrackingInfoVo>{};
    constructor() {}
    @Input() public set trackingInfo(trackingInfo: TrackingInfoVo) {
        this._trackingInfo = trackingInfo;
        this.steps = Steps[((trackingInfo.stopInfo || {})['typeId'])];
    }

    public addActiveClass (step): boolean {
        return (step.statusId <= (this.trackingInfo.orderStatusId || this.orderStatusId.Offered) ||
            this.trackingInfo.stopInfo.statusId === StopStatusId.CompletedWithException ||
            this.trackingInfo.stopInfo.statusId === StopStatusId.Completed);
    }

    public addCurrentClass(step): boolean {
        return step.statusId === (this.trackingInfo.orderStatusId || this.orderStatusId.Offered);
    }

    public get trackingInfo() {
        return this._trackingInfo;
    }
}