import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CoreModule } from '../core/module';
import { HomePageComponent } from './containers/home-page/home.page';
import { HomeComponent } from './components/home.component';
import { ShipperWelcomeComponent } from './components/shipper/welcome.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { HomeActions } from './actions/home.actions';
import { MyOrdersEpics } from './epics/my-orders.epics';
import { MyOrdersPageComponent } from './containers/my-orders.page/my-orders.page.component';
import { OrderInfoContainerComponent } from './containers/order-info.container/order-info.container.component';
import { MyOrdersService } from './services/my-orders.service';

import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ExpandedOrderViewPageComponent } from './components/expanded-order-view.page/expanded-order-view.page.component';

import { TranslationLoaderService } from '../core/services/translation-loader.service';
import { locale as english } from './i18n/my-orders.en';
import { PaymentPageComponent } from './containers/payment.page/payment.page.component';
import { DriversPageComponent } from './containers/drivers.page/drivers.page.component';
import { ExpandedOrderInfoComponent } from './components/expanded-order-info/expanded-order-info.component';
import { ExpandedDriverInfoComponent } from './components/expanded-driver-info/expanded-driver-info.component';
import { SelectModule } from '../core/components/pool-select/select/select.module';
import { PoolsActions } from '../core/actions/pools.actions';
import { MyOrdersActions } from './actions/my-orders.actions';
import { RateDriverComponent } from './components/rate-driver/rate-driver.component';
import { OrdersTableComponent } from './components/orders-table/orders-table.component';
import { NotificationsService } from './services/notifications.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { OrderDetailsPageComponent } from './containers/order-details.page/order-details.page.component';
import { DriverInfoContainerComponent } from './containers/driver-info.container/driver-info.container.component';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {OrderStatusBarComponent} from './components/order-status-bar/order-status-bar.component';
import { EventMainComponent } from './components/event-main/event-main.component';
import { EventsLogComponent } from './components/events-log/events-log.component';
import { OrderEventsActions } from './actions/order-events.action';
import { OrderEventsEpics } from './epics/order-events.epics';
import { OrderEventsService } from './services/order-events.service';
import { OrderInfoHeaderComponent } from './components/order-info-header/order-info-header.component';
import { SplitWithComaPipe } from './pipes/split-with-coma.pipe';
import { ArrayToQueryStringPipe } from './pipes/array-to-query-string.pipe';
import { DriverHomeComponent } from '../driver/driver-home.component';
import { DriverHomeGuard } from '../auth/guards/driver-home.guard';
import { DriverResetPasswordComponent } from '../driver/change-password/change-password.component';
import { ShipperMobileHomeComponent } from '../mobile/mobile-shipper-home.component';
import { OrderSummaryLogComponent } from './components/order-summary-log/order-summary-log.component';
import { OrderSummaryItemComponent } from './components/order-summary-item/order-summary-item.component';
import { ItemListComponent } from './components/item-list/item-list.component';
import { SharedModule } from '../shared/module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DirectivesModule } from '../directives/directives.module';
import { HomeGuard } from '../auth/guards/home.guard';
import { SearchOrderActions } from './actions/search-order.actions';
import { SearchFilterViewComponent } from './components/search-filter-view/search-filter-view.component';
import { FilterViewComponent } from './components/filter-view/filter-view.component';
import { NgmatHomeModule } from './ngmat-home.module';
import { OrderActionBtnsComponent } from './components/order-action-btns/order-action-btns.component';
import { HttpClientModule } from '@angular/common/http';
import { RecurringModalComponent } from './containers/recurring-modal/recurring-modal.component';
import { RecurringDatePickComponent } from './containers/recurring-modal/recurring-date-pick/recurring-date-pick.component';
import { PriceChangeModalComponent } from 'app/builder/components/price-change-modal/price-change-modal.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

export const COMPONENTS = [HomePageComponent, HomeComponent, DriverHomeComponent, ShipperWelcomeComponent, ExpandedOrderInfoComponent,
    ExpandedDriverInfoComponent, MyOrdersPageComponent, ExpandedOrderViewPageComponent, PaymentPageComponent,
    DriversPageComponent, RateDriverComponent, OrdersTableComponent, OrderDetailsPageComponent, SplitWithComaPipe, ArrayToQueryStringPipe,
    DriverInfoContainerComponent, OrderInfoContainerComponent, OrderStatusBarComponent, EventMainComponent,
    DriverResetPasswordComponent, ShipperMobileHomeComponent, EventsLogComponent, OrderInfoHeaderComponent,
    OrderSummaryLogComponent, OrderSummaryItemComponent, ItemListComponent, SearchFilterViewComponent, FilterViewComponent,
    OrderActionBtnsComponent, RecurringModalComponent, RecurringDatePickComponent];


@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        TranslateModule,
        SelectModule,
        TooltipModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        BsDatepickerModule.forRoot(),
        SharedModule,
        ReactiveFormsModule,
        DirectivesModule,
        FormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        NgmatHomeModule,
        HttpClientModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS,
    providers: [TranslationLoaderService, HomeActions, SearchOrderActions, MyOrdersActions, DriverHomeGuard,
        MyOrdersService, MyOrdersEpics, PoolsActions, NotificationsService, OrderEventsActions,
        OrderEventsEpics, OrderEventsService, HomeGuard, DatePipe, [provideNgxMask()]]
})
export class HomeModule {
    constructor(private translationLoader: TranslationLoaderService) {
        this.translationLoader.loadTranslations(english);
    }
}
