<div class="details-section" [ngClass]="{collapsed: isCollapsed}">
  <a (click)="isCollapsed = !isCollapsed" class="btn-link add-info-expanded" [ngClass]="{expanded: !isCollapsed}">
    <span [translate]="'tuya-shipment.tuya-stop-details.add-notes'"></span>
  </a>
  <div [collapse]="isCollapsed">
    <p>{{'tuya-shipment.tuya-stop-details.notes-text' | translate}}</p>
    <form name="form"
          #form="ngForm"
          novalidate>
      <div class="form-group"
           tooltip="{{'tuya-shipment.unable-change' | translate}}"
           placement="top"
           containerClass="tuya-tooltip"
           container="body"
           [isDisabled]="!disabled || (disabled && StopStatusId.Completed > stop.statusId)">
      <textarea name="notes"
                class="form-control"
                title="Notes"
                [disabled]="disabled && stop.statusId >= StopStatusId.Completed"
                [ngClass]="{ 'filled': stop.notes }"
                rows="5" [(ngModel)]="stop.notes" maxlength="500"></textarea>
        <span class="reminder">{{'tuya-shipment.tuya-stop-details.notes-label' | translate }}</span>
      </div>
    </form>
  </div>
</div>
