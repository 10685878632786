import { IShipmentEditState } from '../models/model';
import { EDIT_ORDER_ACTION, EDIT_ORDER_ACTION_SUCCEED } from '../actions/shipment-edit.actions';
import { Action } from '../../core/models/redux.action';
import { OrderVo } from '../../shipment/models/order.model';
import { getOrderEditMode } from '../utils/order.utils';
import { OrderEditMode } from '../../core/models/order.dto';
import { SHIPMENT_RESET } from '../../shipment/actions/shipment.actions';


export const INITIAL_EDIT_STATE: IShipmentEditState = {
    editMode: OrderEditMode.NoEdit,
    initialOrder: null,
    isEditOrderLoading: false
};

export function shipmentEditReducer(state = INITIAL_EDIT_STATE, action: Action<any, any>): IShipmentEditState {
    switch (action.type) {
        case EDIT_ORDER_ACTION: {
            return {
                ...state,
                isEditOrderLoading: true
            };
        }
        case EDIT_ORDER_ACTION_SUCCEED: {
            const orderVo = <OrderVo>action.payload;
            return {
                ...state,
                initialOrder: orderVo,
                editMode: getOrderEditMode(orderVo.statusId)
            };
        }
        case SHIPMENT_RESET: {
            return INITIAL_EDIT_STATE;
        }
        default: {
            return state;
        }
    }
}
