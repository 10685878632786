import { CompanyValidationResultVO } from './../../../register/model/model';
import { ProfilesSelectors } from './../../selectors/profiles.selectors';
import { BillingContactsComponent } from './../../components/billing-contacts/billing-contacts.component';
import { GeneralInformationComponent } from './../../components/general-information/general-information.component';
import { IndustrySegment, ShipperProfileDto, BusinessEntityDto, StateDto } from './../../../core/models/dto';
import { CreditCardDto } from './../../../core/models/payment.dto';
import { ProfileToEditVO } from '../../../register/model/model';
import { ManageCompanyTab } from './../../model/model';
import { ProfilesActions } from './../../actions/profiles.actions';
import { IndustrySegmentActions } from './../../../register/actions/industry.segments.actions';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { select } from '@angular-redux/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { StatesActions } from '../../../core/actions/states.actions';
import { BillingActions } from '../../actions/billing.actions';
import { BillingSelectors } from '../../selectors/billing.selectors';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PaymentMethodsComponent } from '../../components/payment-methods/payment-methods.component';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
    selector: 'tuya-company-page',
    templateUrl: './company-page.component.html',
    styleUrls: ['./company-page.component.scss'],
    providers: [BsModalRef]
})
export class CompanyPageComponent implements OnInit {

    @select(ProfilesSelectors.profileToEditSelector)
    shipperProfilesInfo$: Observable<ProfileToEditVO>;
    @select(ProfilesSelectors.companyInfoToEditSelector)
    companyProfilesInfo$: Observable<BusinessEntityDto>;
    @select(ProfilesSelectors.industrySegmentsSelector)
    industrySegments$: Observable<IndustrySegment[]>;
    @select(ProfilesSelectors.statesSelector)
    states$: Observable<StateDto[]>;
    @select(ProfilesSelectors.currentManageCompanyTabSelector)
    currentManageCompanyTab$: Observable<ManageCompanyTab>;
    @select(ProfilesSelectors.companyValidateSelector)
    validateCompanyErrors$: Observable<CompanyValidationResultVO>;
    @select(ProfilesSelectors.isLoading)
    isLoading$: Observable<boolean>;
    @select(BillingSelectors.paymentMethodsSelector)
    paymentMethods$: Observable<CreditCardDto[]>;
    @ViewChild(GeneralInformationComponent, {static: true}) generalInfoComponent;
    @ViewChild(BillingContactsComponent, {static: true}) billingInfoComponent;
    @ViewChild(PaymentMethodsComponent, {static: true}) paymentMethodsComponent;
    @ViewChild('cancel', {static: true}) cancelModal;
    @select(['shipper', 'shipperStatus', 'suspended'])
    readonly suspended$: Observable<boolean>;

    public ManageCompanyTab: typeof ManageCompanyTab = ManageCompanyTab;
    private nextTab: ManageCompanyTab;
    private shipperProfilesInfo = new BehaviorSubject<ProfileToEditVO>(new ShipperProfileDto());
    private companyProfilesInfo = new BehaviorSubject<BusinessEntityDto>(new BusinessEntityDto());

    constructor(industrySegmentActions: IndustrySegmentActions,
                private billingActions: BillingActions,
                private statesActions: StatesActions,
                private profilesActions: ProfilesActions,
                private modalService: BsModalService) {
        industrySegmentActions.load();
        this.shipperProfilesInfo$.subscribe(this.shipperProfilesInfo);
        this.companyProfilesInfo$.subscribe(this.companyProfilesInfo);
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (this.isFormsModelsChanged()) {
            return $event.returnValue = 'Do you want to reload this site? Changes you made may not be saved';
        }
    }

    ngOnInit() {}

    isFormsModelsChanged(): boolean {
        const isGeneralChanged = this.generalInfoComponent.isModelChanged();
        const isBillingChanged = this.billingInfoComponent.isModelChanged();
        const isPaymentChanged = this.paymentMethodsComponent.isModelChanged();
        return isGeneralChanged || isBillingChanged || isPaymentChanged;
    }

    changeTab() {
        this.profilesActions.changeManageCompanyTab(this.nextTab);
    }

    resetForms() {
        this.generalInfoComponent.discardChanges();
        this.billingInfoComponent.discardChanges();
    }

    onChangeManageCompanyTab(manageCompanyTab) {
        this.nextTab = manageCompanyTab;
        this.isFormsModelsChanged()
            ? this.modalService.show(this.cancelModal)
            : this.changeTab();
    }

    onHideModal(evt) {
        if (evt) {
            this.resetForms();
            this.changeTab();
        }
        this.modalService.hide(1);
    }

    onSaveGeneralInfo(generalInfo: BusinessEntityDto) {
        // address model using stateId (number)
        // remove stateAbbr because it overrides stateId on save to DB
        // (remove stateName while we're here)
        delete generalInfo.physicalAddress.stateAbbr;
        delete generalInfo.physicalAddress.stateName;

        const companyInfo = {
            ...generalInfo,
            physicalAddress: {
                ...generalInfo.physicalAddress
            },
            billingAddress: {
                ...generalInfo.billingAddress
            }
        };

        const isPleaseUseCompanyAddressChecked = this.arePhysicalAndBillingAddressTheSame(this.companyProfilesInfo.value);
        if (isPleaseUseCompanyAddressChecked) {
            // set the billing address to the physical address
            const billingAddressId = companyInfo.billingAddress.id;
            companyInfo.billingAddress = {
                ...companyInfo.physicalAddress,
                id: billingAddressId
            };
        }

        // construct ShipperProfileDto
        const profile = {
            ...this.shipperProfilesInfo.value,
            businessEntity: companyInfo
        };
        delete profile.businessEntity.physicalAddress.latitude;
        delete profile.businessEntity.physicalAddress.longitude;

        this.profilesActions.saveCompanyProfile(profile);
    }

    onSaveBillingInfo(billingInfo: BusinessEntityDto) {
        // construct ShipperProfileDto
        delete billingInfo.billingAddress.stateAbbr;
        delete billingInfo.billingAddress.stateName;

        const profile = {
            ...this.shipperProfilesInfo.value,
            businessEntity: billingInfo
        };

        delete profile.businessEntity.physicalAddress.latitude;
        delete profile.businessEntity.physicalAddress.longitude;

        this.profilesActions.saveCompanyProfile(profile);
    }

    arePhysicalAndBillingAddressTheSame(companyInfo: BusinessEntityDto): boolean {
        const physicalAddress = JSON.stringify(Object.assign({}, {
            ...companyInfo.physicalAddress,
            id: companyInfo.billingAddress.id
        }));

        const billingAddress = JSON.stringify(Object.assign({}, {
            ...companyInfo.billingAddress,
            id: companyInfo.billingAddress.id
        }));

        return physicalAddress === billingAddress;
    }

    onClearErrors() {
        this.profilesActions.hideCompanyErrors();
    }

    onPaymentOrder(creditCard) {
        this.billingActions.addCreditCard(creditCard);
    }

}
