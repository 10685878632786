<div class="header" [ngClass]="{ 'headerBgLoading': isLoading }">
    <div class="upper" [ngClass]="{ 'headerLoading': isLoading }">
        <div class="order-info-left loader">
            <div class="order-code">
                <span *ngIf="!isLoading">{{orderInfo.code}}</span>
            </div>
        </div>
        <div class="order-info-right">
            <div class="order-type loader" *ngIf="!isLoading">
                <span *ngIf="orderInfo.directOrder; else indirect">{{'tuya-my-orders.order-info.direct-order' | translate}}</span>
                <ng-template #indirect>{{'tuya-my-orders.order-info.indirectOrder' | translate}} </ng-template>
            </div>
            <div class="order-type loader" *ngIf="isLoading"></div>
            <div class="order-price loader"><span *ngIf="!isLoading">{{orderInfo.total | currency : 'USD' : 'symbol'}}</span></div>
        </div>
    </div>
    <div class="lower" [ngClass]="{ 'headerLoading': isLoading }">
        <div class="ref-ids-wrapper">
            <div class="ref-ids loader"><span *ngIf="!isLoading">{{'tuya-my-orders.order-info.order-ref-id' | translate}}: </span></div>
            <div *ngIf="orderInfo.referenceId" class="ids loader"><span *ngIf="!isLoading">{{orderInfo.referenceId}}</span></div>
            <div *ngIf="!orderInfo.referenceId" class="no-content ids loader"><span *ngIf="!isLoading">{{'tuya-my-orders.order-info.no-content' | translate}}</span></div>
        </div>
    </div>
</div>
