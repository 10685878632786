<div class="login-wrapper">
    <div class="login-card">
        <!-- Create a new account -->
        <img class="logo" src="../../../../assets/img/login-page/main_logo.png" alt="TUYA" title="TUYA" />
        <label class="title">{{'tuya-login.btn.shipping' | translate}}</label>
        <div class="create-an-account">
            {{'tuya-login.newToTuya' | translate}}&nbsp;<a routerLink="/register">{{'tuya-login.createAnAcount' |
                translate}}</a>
        </div>

        <tuya-spinner *ngIf="isLoading$ | async" class="login-spinner"></tuya-spinner>

        <div class="password-error" *ngIf="isUserNotValid.getValue() && password.valid && email.valid &&  f.submitted">
            <div class="error-iconn">
                <img src="../../../../assets/img/login-page/ic_error.svg" alt="Error" />
            </div>
            <div>
                {{'tuya-login.passwordError' | translate}} <a style="text-decoration: underline;"
                    routerLink="/forgotPassword">{{'tuya-login.resetPassword' | translate}}</a>
            </div>
        </div>

        <!-- Login -->
        <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" class="login-form" novalidate>
            <div class="form-group input-control"
                [ngClass]="{ 'form-has-error': ((email.dirty || f.submitted) && !email.valid) || (f.submitted && isUserNotValid.getValue()) }">
                <label for="email" [translate]="'tuya-login.placeholder.email'"></label>
                <div><input type="text" class="form-control" title="Email" [pattern]="emailPattern" name="email"
                        id="email" autocomplete="username" [(ngModel)]="model.email" #email="ngModel" required email />
                </div>
                <div *ngIf="(email.dirty || f.submitted) && !email.valid" class="help-block">
                    <div *ngIf="email?.errors.required" [translate]="'tuya-login.error.email'"></div>
                    <div *ngIf="email?.errors?.pattern" [translate]="'tuya-login.error.email-pattern'"></div>
                </div>
            </div>

            <!-- Password -->
            <div class="form-group input-control"
                [ngClass]="{ 'form-has-error': (f.submitted && (!password.valid || isUserNotValid.getValue())) }">
                <label for="password" [translate]="'tuya-login.placeholder.password'"></label>
                <input type="{{passwordInputType}}" title="password" class="form-control" name="password" id="password"
                    autocomplete="current-password" [(ngModel)]="model.password" #password="ngModel" #passwordInput
                    required />
                <div class="error-hide-pass">
                    <div *ngIf="f.submitted && !password.valid" class="help-block"
                        [translate]="'tuya-login.error.password'"></div>
                    <div class="show-password">
                        <a (click)='togglePassword()'>
                            <span *ngIf="!showPassword">{{'tuya-login.showPassword' | translate}}</span>
                            <span *ngIf="showPassword">{{'tuya-login.hidePassword' | translate}}</span>
                        </a>
                    </div>
                </div>
                <label class="container">{{'tuya-login.rememberMe' | translate}}
                    <input type="checkbox" [checked]="user.rememberMe" (change)="onRememberMe()">
                    <span class="checkmark"></span>
                </label>
            </div>

            <!-- Terms -->
            <div class="terms"><tuya-terms-of-service-container /></div>

            <div class="form-group no-margin">
                <button type="submit" title="Login" class="login-button btn-lg btn-block"
                    [translate]="'tuya-login.btn.shipping'"></button>
                <div class="forgot-password">
                    <a routerLink="/forgotPassword" [translate]="'tuya-login.forgotPassword'"></a>
                </div>
            </div>
        </form>
    </div>
</div>