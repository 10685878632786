<div class="status-bar" *ngIf="!isLoading">
    <div class="exceptions" *ngIf="orderSummary.exceptionsCount > 0">
        <span class="exception-icon"></span>
        {{orderSummary.exceptionsCount}}
        {{'tuya-my-orders.status-bar.exceptions' | translate}}
    </div>
    <div class="date" *ngIf="showDate()">
        {{orderSummary.endDate | date:'M/dd/yyyy, h:mm a'}}</div>
    <div class="status">{{content | translate}}</div>
</div>

<div class="status-br" *ngIf="isLoading">
</div>