import { Injectable } from '@angular/core';
import { NotificationBucketDto } from '../models/notification.model';
import { map, Observable } from 'rxjs';
import { ConfigService } from '../../app.config.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { API_PREFIX } from '../constants/api.constants';
import { LoadNotificationsParamsDto } from '../models/dto';

@Injectable()
export class NotificationService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    getNotificationBucket(searchPaginationParams: LoadNotificationsParamsDto): Observable<NotificationBucketDto> {
        return this.api().GET(API_PREFIX + '/Notifications', searchPaginationParams).pipe(map(data => (data as any).notificationBucket));
    }
}
