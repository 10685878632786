<ul class="events-log" *ngIf="!isLoading">
    <li class="event-item" *ngFor="let eventItem of orderEventsList" >
        <tuya-event-main 
            [eventItem]="eventItem" 
            [isShipperSuspended]="isShipperSuspended"
            [statusId]="statusId"
        ></tuya-event-main>
    </li>
    <li *ngIf="errorMessageKey" class="error-container">
        <div class="error-icon"></div>
        <div class="error-text" [translate]="'tuya-my-orders.errors.' + errorMessageKey"></div>
    </li>
</ul>

<div class="events-log" [ngClass]="'loading'" *ngIf="isLoading">
    <div class="image loader"></div>
    <div class="status">
        <div class="title">
            <div class="txt loader"></div>
            <div class="time loader"></div>
        </div>
        <div class="desc loader"></div>
    </div>
</div>

<div class="events-log" [ngClass]="'loading'" *ngIf="isLoading">
    <div class="image loader"></div>
    <div class="status">
        <div class="title">
            <div class="txt loader"></div>
            <div class="time loader"></div>
        </div>
        <div class="desc loader"></div>
        <div class="desc loader"></div>
    </div>
</div>
