<div bsModal #modalChangePassword="bs-modal" class="modal fade"  role="dialog" [config]="{ ignoreBackdropClick: true }" >
<div class="modal-dialog modal-lg">
<div class="modal-content">
  <div class="modal-body">
    <h1 [translate]="'tuya-profiles.h1.change-password'"></h1>
    <form name="changePasswordForm" autocomplete="off"
        (ngSubmit)="password.valid && newPassword.valid && confirmPassword.valid && saveNewPassword()"
        #changePasswordForm="ngForm" novalidate>
    <div>
        <div class="form-group" [ngClass]="{ 'has-error': isPasswordValid(password, true) || hasOldPasswordErrors(),
                                'valid': isPasswordValid(password) && !hasOldPasswordErrors() }">
          <label [translate]="'tuya-profiles.label.verifyCurrentPassword'"></label>
          <input placeholder="{{'tuya-profiles.placeholder.currentPassword' | translate }}"
              type="password"
              class="form-control"
              name="password"
              [(ngModel)]="model.password"
              #password="ngModel"
              maxlength="32"
              [pattern]="passwordPattern"
              (focusin)="onFocusInOldPassword()"
              required/>
          <div *ngIf="isPasswordValid(password, true) || (validationResult)?.msgs" class="help-block">
             <div *ngIf="isPasswordEmpty(password.value) && !!password.errors.required" [translate]="'tuya-profiles.error.password'"></div>
             <div *ngIf="!!password.errors?.pattern" [translate]="'tuya-profiles.error.valid-password'"></div>
             <div *ngIf="(validationResult)?.isValid === false">
              <div *ngFor="let error of (validationResult)?.msgsOld" [translate]="error"></div>
            </div>
          </div>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': isNewPasswordNotValid(newPassword) || hasNewPasswordErrors() }">
          <label [translate]="'tuya-profiles.placeholder.newPassword'"></label>
          <input placeholder="{{'tuya-profiles.placeholder.newPassword' | translate }}"
              type="password"
              class="form-control"
              name="newPassword"
              [(ngModel)]="model.newPassword"
              (ngModelChange)="onNewPasswordChange()"
              #newPassword="ngModel"
              maxlength="32"
              minlength="6"
              [pattern]="passwordPattern"
              tuyaValidatePasswordEqual="confirmPassword"
              reverse="true"
              (focusin)="onFocusInNewPassword()"
              required/>
          <div *ngIf="isNewPasswordNotValid(newPassword)" class="help-block">
              <div *ngIf="isPasswordEmpty(newPassword.value) && !!newPassword.errors.required" [translate]="'tuya-profiles.error.password'"></div>
              <div *ngIf="!!newPassword.errors?.pattern" [translate]="'tuya-profiles.error.valid-password'"></div>
              <div *ngIf="!!newPassword.errors?.minlength" [translate]="'tuya-profiles.error.short-password'"></div>
              <div *ngIf="isNewPasswordEasy(newPassword.value) && !newPassword.errors?.minlength" [translate]="'tuya-profiles.error.easy-password'"></div>
          </div>
          <div *ngIf="hasNewPasswordErrors()" class="help-block">
            <div *ngFor="let err of (validationResult)?.msgs" [translate]="err"></div>
          </div>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': isPasswordValid(confirmPassword, true), 'valid': isPasswordValid(confirmPassword) }  ">
          <label [translate]="'tuya-profiles.placeholder.confirmPassword'"></label>
          <input placeholder="{{'tuya-profiles.placeholder.confirmPassword' | translate }}"
              type="password"
              class="form-control"
              name="confirmPassword"
              [(ngModel)]="model.confirmPassword"
              #confirmPassword="ngModel"
              maxlength="32"
              [pattern]="passwordPattern"
              required
              tuyaValidatePasswordEqual="newPassword"
              reverse="false"/>
          <div *ngIf="isPasswordValid(confirmPassword, true)" class="help-block">
            <div *ngIf="confirmPassword.dirty && confirmPassword.invalid && !confirmPassword.errors.required" class="help-block" [translate]="'tuya-profiles.error.confirm-password'"></div>
            <div *ngIf="isPasswordEmpty(confirmPassword.value) && confirmPassword.invalid" [translate]="'tuya-profiles.error.confirm-password-required'"></div>
          </div>
        </div>
        <div class="form-buttons">
          <button type="button" class="btn btn-default btn-lg cancel-button" (click)="dispatcHHideModal()" aria-label="Close" [translate]="'tuya-profiles.btn.cancel'"></button>
          <button type="submit" class="btn btn-primary btn-lg save-button" [translate]="'tuya-profiles.btn.save'" title="Save Changes"
            [disabled]="!(password.valid && newPassword.valid && confirmPassword.valid)"></button>
        </div>
    </div>
    </form>
  </div>
</div>
</div>
</div>
