<footer>
    <div class="footer-col">
        <div class="footer-col-item logo">
            <a href=""></a>
        </div>
        <div class="footer-col-item text" [translate]="'tuya-tracking.tuya-tracking-footer.title'"></div>
    </div>
    <div class="footer-col">
        <a class="navigate-link" href="https://www.tuyatech.com/">{{'tuya-tracking.tuya-tracking-footer.learn-more-link' | translate}}</a>
    </div>
</footer>
