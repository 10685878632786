import { Component, EventEmitter, Output, OnDestroy, Input, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigService } from '../../../app.config.service';
import { BillingService } from '../../../profiles/services/billing.service';
import { BillingActions } from '../../../profiles/actions/billing.actions';
import { AlertActions } from '../../../core/actions/alert.actions';
import { T3PROAuth, CreditCardDto, msgTypes } from '../../models/payment.dto';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { BillingSelectors } from '../../../profiles/selectors/billing.selectors';
import { AlertType } from '../../models/alert.model';

@Component({
    selector: 'tuya-payment-frame',
    templateUrl: './payment-frame.component.html',
})
export class PaymentFrameComponent implements OnDestroy {
    @Input() height = 460;
    @Input() width = 590;
    @Output() public frameMsgEvt = new EventEmitter();

    @select(BillingSelectors.getIsLoading) isLoading$: Observable<boolean>;

    public isLoading: boolean;
    public paymentUrl: SafeResourceUrl;
    private topOrigin: string;
    private tcpAccountName: string;
    private authSubscription: Subscription;
    private frame;
    private isLocal = this.configService.getConfiguration().configuration === 'local';
    constructor(private sanitizer: DomSanitizer,
                private configService: ConfigService,
                private alertActions: AlertActions,
                private billingService: BillingService,
                private billingActions: BillingActions) {
        this.isLoading$.subscribe(isLoading => {
            this.isLoading = isLoading;
            if (isLoading) {
                this.runCCValidation();
            }
        });

        this.topOrigin = this.configService.getConfiguration().payment2CPUrl;
        this.tcpAccountName = this.configService.getConfiguration().creditCardAccountName;
        const configPaymentUrl = `${this.topOrigin}/${this.configService.getConfiguration().paymentUri}?ts=${Date.now()}`;
        this.paymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(configPaymentUrl);
        // this.paymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl('http://127.0.0.1:8101'); // use with local 2cp server
    }

    ngOnDestroy() {
        this.authUnsubscribe();
    }

    public notifyFrame(payload: any, type: msgTypes = msgTypes.Default) {
        const origins = this.isLocal ? '*' : this.topOrigin;
        this.frame.postMessage({ payload, type }, origins);
    }

    public authUnsubscribe () {
        if (this.authSubscription) {
            this.authSubscription.unsubscribe();
        }
    }

    public onPaymentFrameLoad(event) {
        // We have to support Safari browser;
        const path = this.getElementPath(event);
        // make the request to service directly and pass to iframe
        // - just because of security reasons
        this.frame = this.frame || path[0].contentWindow;
    }

    public authenticate2cp() {
        this.authSubscription = this.billingService.getAuth().subscribe(
            (payload: T3PROAuth) => {
                payload.accountName = this.tcpAccountName;
                this.notifyFrame(payload, msgTypes.Authorization);
                this.authUnsubscribe();
            },
            (err: any) => {
                this.billingActions.turnOffValidateCC();
                this.alertActions.show({
                    type: AlertType.BadRequest,
                    error: err
                });
                console.error('error', err);
            }
        );
    }

    private async runCCValidation() {
        await this.notifyFrame(null, msgTypes.ValidateCC);
    }

    @HostListener('window:message', ['$event'])
    private onPaymentFrameEvent(e) {
        if ((!this.isLocal && e.origin !== this.topOrigin) || !e.data) {
            return;
        }

        switch (e.data.type) {
            case msgTypes.AuthRequest:
                this.authenticate2cp();
                break;

            case msgTypes.SaveCC:
                const creditCard = {
                    name: e.data.payload.nickName,
                    cardHolder: e.data.payload.cardHolder,
                    cardNumberHash: e.data.payload.hash,
                    cardTypeId: e.data.payload.typeId,
                    expiresMonth: Number(e.data.payload.expiresMonth),
                    expiresYear: Number(e.data.payload.expiresYear),
                    storedAccountId: e.data.payload.storedAccountId
                } as CreditCardDto;
                this.frameMsgEvt.emit(creditCard);
                break;

            case msgTypes.Error:
                this.billingActions.turnOffValidateCC();
                break;

            default:
                // do not dispatch any actions from the default case
                // dispatching from here will trigger this @HostListener and cause infinite loop
        }
    }

    private getElementPath(event) {
        return event.path
            || (event.composedPath && event.composedPath())
            || [event.srcElement];
    }
}
