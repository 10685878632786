import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { of, timer } from 'rxjs';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';
import { HomeActions, HOME_LOAD_PAGE, HOME_LOAD_ROW_INFO } from '../actions/home.actions';
import { MyOrdersService } from '../services/my-orders.service';
import { CanceledOrderInfo, DriverScore } from '../model/driver-info.model';
import {
    HOME_LOAD_RATE_REASONS, CANCEL_ORDER, HOME_RATE_DRIVER,
    MyOrdersActions, UPDATE_ORDERS, CREATE_RECURRING_ORDER, GET_RECURRING_DETAIL, DELETE_RECURRING_DETAIL
} from '../actions/my-orders.actions';
import { OrderStatusId, SearchPaginationParamsDto } from '../../core/models/dto';
import { OrderEventsActions } from '../actions/order-events.action';
import { SEARCH_FOR_AN_ORDER, SearchOrderActions } from '../actions/search-order.actions';
import { OrdersService } from 'app/core/services/orders.service';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { RecurringDetail } from '../containers/recurring-modal/recurring-modal.component';

@Injectable()
export class MyOrdersEpics {
    constructor(private homeActions: HomeActions,
        private myOrdersActions: MyOrdersActions,
        private myOrdersService: MyOrdersService,
        private ordersService: OrdersService,
        private alertActions: AlertActions,
        private searchOrderActions: SearchOrderActions,
        private orderEventsActions: OrderEventsActions) {
    }

    public createEpic() {
        return combineEpics(
            createPageLoadEpic(this.homeActions, this.myOrdersService, this.alertActions, this.ordersService),
            createUpdateOrdersEpic(this.homeActions, this.myOrdersService, this.alertActions),
            createSearchOrdersEpic(this.homeActions, this.myOrdersService, this.alertActions, this.searchOrderActions, this.ordersService),
            createRowInfoLoadEpic(this.homeActions, this.myOrdersService, this.alertActions),
            createRateDriverEpic(this.myOrdersActions, this.myOrdersService, this.alertActions),
            createRateReasonsLoadEpic(this.myOrdersActions, this.myOrdersService, this.alertActions),
            createCancelOrderEpic(this.myOrdersActions, this.myOrdersService, this.alertActions, this.homeActions, this.orderEventsActions),
            createAddRecurringOrderEpic(this.myOrdersActions, this.myOrdersService, this.alertActions),
            createGetRecurringDetailEpic(this.myOrdersActions, this.myOrdersService, this.alertActions),
            createDeleteRecurringDetailEpic(this.myOrdersActions, this.myOrdersService, this.alertActions)
        );
    }
}

// Load Orders
export function createPageLoadEpic(actions: HomeActions, service: MyOrdersService,
    alertActions: AlertActions, ordersService: OrdersService) {
    return (action$, store) => action$.pipe(
        ofType(HOME_LOAD_PAGE),
        exhaustMap((action: any) => {
            const myStore = store.value;
            const pagination = myStore.home.myOrders.pagination;
            const statuses = ordersService.arrayToQueryString(pagination.filter, pagination.statuses, 'orderStatuses');
            const params = <SearchPaginationParamsDto>{
                pageSize: pagination.pageSize,
                currentPageNumber: pagination.currentPageNumber,
                sortField: pagination.sortField,
                sortDirection: pagination.sortDirection,
                orderStatuses: statuses,
                searchQuery: myStore.home['searchOrders'].searchQuery,
                shipperUserId: myStore.auth.currentUser.shipperProfile.userAccountId
            };
        
            // param for admin
            if (action.payload.shipperUserId) params.shipperUserId = action.payload.shipperUserId;

            // OrdersForShipperViewQuery (My Orders)
            return service.getOrders(params).pipe(
                map(data => actions.loadPageSucceeded(data)),
                catchError(error => {
                    console.log('error getting orders');
                    return of(
                        actions.loadPageFailed({ error }),
                        alertActions.show({ error: error, type: AlertType.BadRequest })
                    );
                })
            );
        })
    );
}

// Load Orders
export function createUpdateOrdersEpic(actions: HomeActions, service: MyOrdersService, alertActions: AlertActions) {
    return (action$, store) => action$.pipe(
        ofType(UPDATE_ORDERS),
        switchMap((action) => {
            const myStore = store.value;
            const statusFilter = myStore.home.myOrders.pagination.filter;
            const statusFilterString = [];
            for (const key in statusFilter) {
                if (statusFilter[key]) {
                    statusFilterString.push(key);
                }
            }

            // OrdersForShipperViewQuery (My Orders)
            return service.getOrders({
                pageSize: myStore.home.myOrders.pagination.pageSize,
                currentPageNumber: myStore.home.myOrders.pagination.currentPageNumber,
                sortField: myStore.home.myOrders.pagination.sortField,
                sortDirection: myStore.home.myOrders.pagination.sortDirection,
                orderFilter: statusFilterString.toString(),
                hasExceptions: myStore.home.myOrders.pagination.hasExceptions,
                shipperUserId: myStore.auth.currentUser.shipperProfile.userAccountId
            }).pipe(
                map(data => actions.loadPageSucceeded(data)),
                catchError(error => {
                    console.log('error loading orders');
                    return of(
                        actions.loadPageFailed({ error }),
                        alertActions.show({ error: error, type: AlertType.BadRequest })
                    );
                })
            );
        })
    );
}

export function createSearchOrdersEpic(actions: HomeActions, service: MyOrdersService,
    alertActions: AlertActions, searchOrderActions: SearchOrderActions, ordersService: OrdersService) {
    return (action$, store) => action$.pipe(
        ofType(SEARCH_FOR_AN_ORDER),
        switchMap((action: any) => {
            searchOrderActions.updateProgressBar(20);
            setTimeout(() => { searchOrderActions.updateProgressBar(45); }, 150);
            setTimeout(() => { searchOrderActions.updateProgressBar(75); }, 250);
            searchOrderActions.updateIsSearching(true);

            const params = ordersService.generateSearchQuery(store);

            // OrdersForShipperViewQuery (My Orders)
            return service.searchForAnOrder(action.payload, params).pipe(
                mergeMap(data => {
                    return of(
                        searchOrderActions.updateProgressBar(100) &&
                        searchOrderActions.updateIsSearching(false) &&
                        actions.loadPageSucceeded(data)
                    );
                }),
                catchError(error => {
                    console.log('error searching orders');
                    searchOrderActions.updateProgressBar(100);
                    return of(
                        actions.loadPageFailed({ error }),
                        alertActions.show({ error: error, type: AlertType.BadRequest })
                    );
                })
            );
        })
    );
}


// Infrastructure Request
let lastFetched = 0;
let cachedData = null;
const HOUR = 3600000; // 1 hour in milliseconds
export function createRateReasonsLoadEpic(actions: MyOrdersActions, service: MyOrdersService, alertActions: AlertActions) {
    return action$ => action$.pipe(
        ofType(HOME_LOAD_RATE_REASONS),
        filter(() => {
            const now = Date.now();
            return !cachedData || (now - lastFetched > HOUR);
        }),
        switchMap(() => {
            return service.getLowRateReasons().pipe(
                map(data => {
                    cachedData = data;
                    lastFetched = Date.now();
                    return actions.loadLowRateReasonsSucceeded(data);
                }),
                catchError(error => {
                    return of(actions.loadLowRateReasonsFailed({
                        error,
                    }), alertActions.show({ error: error, type: AlertType.BadRequest }));
                }),
                takeUntil(timer(HOUR)) // Reset the timer if new data is fetched within the hour
            );
        })
    );
}

/*export function createRateReasonsLoadEpic(actions: MyOrdersActions, service: MyOrdersService, alertActions: AlertActions) {
    return action$ => action$.pipe (
        ofType(HOME_LOAD_RATE_REASONS),
        switchMap((action) => {
            return service.getLowRateReasons().pipe(
                map(data => actions.loadLowRateReasonsSucceeded(data))
                ,catchError(error => {
                    return of(actions.loadLowRateReasonsFailed({
                        error,
                    }), alertActions.show({error: error, type: AlertType.BadRequest}));
                }));
        })
    );
}*/

export function createRateDriverEpic(actions: MyOrdersActions, service: MyOrdersService, alertActions: AlertActions) {
    return action$ => action$.pipe(
        ofType(HOME_RATE_DRIVER),
        switchMap(({ payload }: { payload: any }) => {
            return service.updateDriverRate(payload).pipe(
                map((data: DriverScore) => actions.rateDriverSucceeded({ driverRate: data.value, ...payload })),
                catchError(error => {
                    return of(
                        actions.rateDriverFailed({ error }),
                        alertActions.show({ error: error, type: AlertType.BadRequest })
                    );
                })
            );
        })
    );
}

export function createRowInfoLoadEpic(actions: HomeActions, service: MyOrdersService, alertActions: AlertActions) {
    return action$ => action$.pipe(
        ofType(HOME_LOAD_ROW_INFO),
        switchMap((action: any) => {
            if (action.payload.collapsed) {
                return service.getOrderById(action.payload.id).pipe(
                    map((data: any) => {
                        return actions.loadRowInfoSucceeded(data);
                    }), 
                    catchError(error => {
                        console.log('error loading row information');
                        return of(
                            actions.loadRowInfoFailed({ error }),
                            alertActions.show({ error: error, type: AlertType.BadRequest })
                        );
                    })
                );
            }

            return [];
        })
    );
}

export function createCancelOrderEpic(
    actions: MyOrdersActions,
    service: MyOrdersService,
    alertActions: AlertActions,
    homeActions: HomeActions,
    orderEventsActions: OrderEventsActions
) {
    return (action$) => action$.pipe(
        ofType(CANCEL_ORDER),
        switchMap((action: any) => {
            const orderId = action.payload?.orderId;
            return service.cancelOrder(action.payload).pipe(
                map((data: CanceledOrderInfo) => {
                    if (
                        data.orderStatusId === OrderStatusId.PendingCancellation ||
                        data.orderStatusId === OrderStatusId.Canceled
                    ) {
                        action.payload.success();
                        return actions.cancelOrderSucceeded(data);
                    }
                    return actions.cancelOrderCanceled(data);
                }),
                catchError(error => {
                    console.error('Error canceling order:', error);
                    action.payload.fail();
                    return of(
                        actions.cancelOrderFailed({ error }),
                        alertActions.show({ message: error.message || 'An error occurred', type: AlertType.BadRequest }),
                        homeActions.loadRowInfo({ id: orderId, collapsed: true }),
                        orderEventsActions.getAllOrderEvents(orderId)
                    );
                })
            );
        })
    );
}

export function createAddRecurringOrderEpic(actions: MyOrdersActions, service: MyOrdersService, alertActions: AlertActions) {
    return action$ => action$.pipe(
        ofType(CREATE_RECURRING_ORDER),
        switchMap((action: any) => {
            if (action.payload.isEditMode) {
                action.payload.data['recurrenceOrderId'] = action.payload.data.id;
                delete action.payload.data.id;
            }
            
            return service.createRecurringOrder(action.payload.data, action.payload.isEditMode).pipe(
                map((data: any) => actions.createRecurringDetailSucceeded(data, action.payload.data.orderId)),
                catchError(error => {
                    console.log('error adding recurring order');
                    return of(
                        actions.createRecurringOrderFailed(error),
                        alertActions.show({ error: error, type: AlertType.BadRequest })
                    );
                })
            );
        })
    );
}

export function createGetRecurringDetailEpic(actions: MyOrdersActions, service: MyOrdersService, alertActions: AlertActions) {
    return action$ => action$.pipe(
        ofType(GET_RECURRING_DETAIL),
        switchMap((action: any) => {
            return service.getRecurringDetail(action.payload).pipe(
                map((data: RecurringDetail) => actions.getRecurringDetailSucceeded(data)),
                catchError(error => {
                    console.log('error retrieving recurring order details');
                    return of(
                        actions.getRecurringDetailFailed(error),
                        alertActions.show({ error: error, type: AlertType.BadRequest })
                    );
                })
            );
        })
    );
}

export function createDeleteRecurringDetailEpic(actions: MyOrdersActions, service: MyOrdersService, alertActions: AlertActions) {
    return action$ => action$.pipe(
        ofType(DELETE_RECURRING_DETAIL),
        switchMap((action: any) => {
            return service.deleteRecurringOrder(action.payload.id).pipe(
                map((data: any) => actions.deleteRecurringDetailSucceeded({ data: null, orderId: action.payload.orderId })),
                catchError(error => {
                    console.log('error deleting recurring order');
                    return of(
                        actions.deleteRecurringDetailFailed(error),
                        alertActions.show({ error: error, type: AlertType.BadRequest })
                    );
                })
            );
        })
    );
}