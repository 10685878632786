import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/module';
import { RegisterPageComponent } from './containers/register.page.component';
import { RegisterEpics } from './epics/register.epics';
import { RegisterService } from './services/register.service';
import { TermsOfServiceService } from '../core/services/termsOfService.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RegisterFormActions } from './actions/register.actions';
import { IndustrySegmentActions } from './actions/industry.segments.actions';
import { TranslateService } from '@ngx-translate/core';
import { TranslationLoaderService } from '../core/services/translation-loader.service';
import { locale as english } from './i18n/register.en';
import { SharedModule } from '../shared/module';
import { CompanyAddressInputComponent } from './components/company-address-input/company-address-input.component';
import { FormCtrlErrrPrinterComponent } from './components/form-ctrl-errr-printer/form-ctrl-errr-printer.component';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar-portable';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};
export const COMPONENTS = [RegisterPageComponent, CompanyAddressInputComponent, FormCtrlErrrPrinterComponent];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        CoreModule,
        TypeaheadModule,
        ModalModule,
        FormsModule,
        SharedModule,
        NgxMaskDirective, NgxMaskPipe,
        PerfectScrollbarModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS,
    providers: [
        RegisterFormActions,
        IndustrySegmentActions,
        RegisterEpics,
        RegisterService,
        TermsOfServiceService,
        [provideNgxMask()],
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
    ]
})
export class RegisterModule {
    constructor(private translate: TranslateService,
                private translationLoader: TranslationLoaderService) {
        /* Config translation in root module */
        this.translate.addLangs(['en']);
        this.translate.setDefaultLang('en');
        this.translate.use('en');
        /* Load translations in each module/component as needed */
        this.translationLoader.loadTranslations(english);
    }
}

