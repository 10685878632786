import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import {
    ADD_DRIVER_TO_POOLS,
    CREATE_POOL,
    DRIVER_POOLS_LOAD,
    LOAD_DRIVERS,
    LOAD_POOLS,
    REMOVE_DRIVER_FROM_POOLS,
    PoolsActions
} from '../actions/pools.actions';
import { AlertActions } from '../actions/alert.actions';
import { AlertType } from '../models/alert.model';
import { PoolsService } from '../services/pools.service';
import { exhaustMap, concatMap, switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
//import { AuthenticationService } from 'app/auth/services/authentication.service';

@Injectable()
export class PoolsEpics {
    constructor(private poolsActions: PoolsActions,
                private poolsService: PoolsService,
                private alertActions: AlertActions) {
    }

    public createEpic() {
        return combineEpics(
            createPoolsEpic(this.poolsActions, this.poolsService, this.alertActions),
            createShipmentDriverEpic(this.poolsActions, this.poolsService, this.alertActions),
            driverPoolsLoadEpic(this.poolsActions, this.poolsService, this.alertActions),
            createPoolEpic(this.poolsActions, this.poolsService, this.alertActions),
            addDriverToPoolsEpic(this.poolsActions, this.poolsService, this.alertActions),
            removeDriverFromPools(this.poolsActions, this.poolsService, this.alertActions),
        );
    }
}

/*export function createPoolsEpic(actions: PoolsActions, service: PoolsService, alertActions: AlertActions, authService: AuthenticationService) {
    return (action$, store) => action$.pipe(
        ofType(LOAD_POOLS),
        exhaustMap(() => {
            // Check if the token is valid
            if (!authService.isTokenValid()) {
                // If the token is not valid, refresh it
                return authService.refreshToken().pipe(
                    switchMap(() => {
                        // Once the token is refreshed, proceed with loading pools
                        return loadPoolsWithValidToken(service, store, actions, alertActions);
                    }),
                    catchError(error => {
                        // Handle token refresh error
                        console.error('Token refresh error:', error);
                        return of(alertActions.show({ error: error, type: AlertType.BadRequest }));
                    })
                );
            } else {
                // If the token is valid, proceed with loading pools
                return loadPoolsWithValidToken(service, store, actions, alertActions);
            }
        })
    );
}

function loadPoolsWithValidToken(service, store, actions, alertActions) {
    const state = store.value;
    const isAdmin = state.auth.currentUser.adminProfile ? state.auth.currentUser.adminProfile.isAdmin : false;
    const selectedShipperId = state.admin.shipperAdmin.selectedShipper && state.admin.shipperAdmin.selectedShipper.userAccountId;

    return service.getPools(isAdmin && selectedShipperId)
        .map(data => actions.loadPoolListSucceeded(data))
        .catch(error => {
            console.debug('Load pools error:', error);
            return of(actions.loadPoolListFailed({ error }), alertActions.show({ error: error, type: AlertType.BadRequest }));
        });
}*/



export function createPoolsEpic(actions: PoolsActions, service: PoolsService, alertActions: AlertActions) {
    return (action$, store) => action$.pipe(
        ofType(LOAD_POOLS),
        exhaustMap(() => {
            // if admin and selectedShipper, send shipperId into service
            const state = store.value;
            const isAdmin = state.auth.currentUser.adminProfile ? state.auth.currentUser.adminProfile.isAdmin : false;
            const selectedShipperId = state.admin.shipperAdmin.selectedShipper && state.admin.shipperAdmin.selectedShipper.userAccountId;
            return service.getPools(isAdmin && selectedShipperId).pipe(
                map(data => actions.loadPoolListSucceeded(data))
                ,catchError(error => {
                    console.log('error');
                    return of(actions.loadPoolListFailed({
                        error,
                    }), alertActions.show({error: error, type: AlertType.BadRequest}));
                }));
        })
    );
}

export function createShipmentDriverEpic(actions: PoolsActions, service: PoolsService, alertActions: AlertActions) {
    return action$ => action$.pipe(
        ofType(LOAD_DRIVERS),
        exhaustMap((action: any) => {
            return service.getDriverByQuery(action.payload).pipe(
                map(data => actions.loadDriverListSucceeded(data))
                ,catchError(error => {
                    console.log('error');
                    return of(actions.loadDriverListFailed({
                        error,
                    }), alertActions.show({error: error, type: AlertType.BadRequest}));
                }));
        })
    );
}

export function driverPoolsLoadEpic(actions: PoolsActions, service: PoolsService, alertActions: AlertActions) {
    return (action$, store) => action$.pipe(
        ofType(DRIVER_POOLS_LOAD),
        concatMap((action: any) => {
            const currentUser = store.value.auth.currentUser;
            const urlQuery = (currentUser['adminProfile'] && currentUser['adminProfile'].isAdmin) ?
                              '?shipperId=' + currentUser['shipperProfile'].userAccountId : '';
            return service.getDriverPools(action.payload + urlQuery).pipe(
                map(data => actions.driverPoolsLoadSucceeded(data, action.payload))
                ,catchError(error => {
                    console.log('error');
                    return of(actions.driverPoolsLoadFailed({
                        error,
                    }), alertActions.show({error: error, type: AlertType.BadRequest}));
                }));
        })
    );
}

export function createPoolEpic(actions: PoolsActions, service: PoolsService, alertActions: AlertActions) {
    return (action$, store) => action$.pipe(
        ofType(CREATE_POOL),
        switchMap((action: any) => {
            return service.createPool(
                action.payload.driverId,
                action.payload.name,
                store.value.auth.currentUser.shipperProfile.userAccountId).pipe(
                map(data => actions.createPoolSucceeded(data, action.payload.driverId))
                ,catchError(error => {
                    console.log('error');
                    return of(actions.createPoolFailed({
                        error,
                    }), alertActions.show({error: error, type: AlertType.BadRequest}));
                }));
        })
    );
}

export function addDriverToPoolsEpic(actions: PoolsActions, service: PoolsService, alertActions: AlertActions) {
    return (action$, store) => action$.pipe(
        ofType(ADD_DRIVER_TO_POOLS),
        switchMap((action: any) => {
            return service.addDriverToPools(action.payload.driverId, action.payload.pools).pipe(
                map(data => actions.addDriverToPoolsSucceeded(data, action.payload.driverId, action.payload.pools, action.payload.orderId))
                ,catchError(error => {
                    console.log('error');
                    return of(actions.addDriverToPoolsFailed({
                        error,
                    }), alertActions.show({error: error, type: AlertType.BadRequest}));
                }));
        })
    );
}

export function removeDriverFromPools(actions: PoolsActions, service: PoolsService, alertActions: AlertActions) {
    return (action$, store) => action$.pipe(
        ofType(REMOVE_DRIVER_FROM_POOLS),
        switchMap((action: any) => {
            return service.removeDriverFromPools(action.payload.driverId, action.payload.pools).pipe(
                map(data => actions.removeDriverFromPoolsSucceeded(action.payload.driverId, action.payload.pools))
                ,catchError(error => {
                    console.log('error');
                    return of(actions.removeDriverFromPoolsFailed({
                        error,
                    }), alertActions.show({error: error, type: AlertType.BadRequest}));
                }));
        })
    );
}

