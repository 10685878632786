import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../../core/models/redux.action';

import { Stop, StopType } from '../models/order.model';
import { ItemSelectOptions } from '../models/address-autocomplete.model';

export type AddressAutocompleteAction = Action<any, any>;

export const SELECT_AUTOCOMPLETE_ITEM = '[ADDRESS AUTOCOMPLETE] Indicates that user selected an item from an suggested autocomplete list';
export const SELECT_AUTOCOMPLETE_ITEM_SUCCESS = '[ADDRESS AUTOCOMPLETE] Store item and apply all related actions';
export const SELECT_AUTOCOMPLETE_ITEM_FAILURE = '[ADDRESS AUTOCOMPLETE] Item selection failed';
export const CHECK_ADDRESS_IN_MSA = '[SHIPMENT] check is address in MSA range';
export const ON_AUTOCOMPLETE_ITEM_SELECTION_HANDLER_FINISHED = '[ADDRESS AUTOCOMPLETE] On item selection handler finished';

@Injectable()
export class AddressAutocompleteActions {
    @dispatch()
    selectAutocompleteItem = (item: Stop,
                              options: ItemSelectOptions = {
                                    stopId: undefined,
                                    firstStop: false,
                                    stopType: StopType.Pickup
                                }): AddressAutocompleteAction => ({
        type: SELECT_AUTOCOMPLETE_ITEM,
        payload: { item, options }
    })
    @dispatch()
    checkIsAddressInMsa = (stop: Stop, options: ItemSelectOptions = {
                                stopId: undefined,
                                firstStop: false,
                                stopType: StopType.Pickup
                            }): AddressAutocompleteAction => ({
        type: CHECK_ADDRESS_IN_MSA,
        payload: { stop, options }
    })

    selectAutocompleteSucceeded = (item: Stop, options: ItemSelectOptions): AddressAutocompleteAction => ({
        type: SELECT_AUTOCOMPLETE_ITEM_SUCCESS,
        payload: { item, options }
    })
    selectAutocompleteFailed = (error: any): AddressAutocompleteAction => ({
        type: SELECT_AUTOCOMPLETE_ITEM_FAILURE,
        payload: null,
        error
    })

    onAutocompleteItemSelected = (): AddressAutocompleteAction => ({
        type: ON_AUTOCOMPLETE_ITEM_SELECTION_HANDLER_FINISHED,
        payload: null
    })
}
