<div class="order-driver-details">
    <tabset [ngClass]="{'tabsLoading': isLoading}">
        <tab heading="{{ isLoading ? '' : ('tuya-my-orders.driver-info.order-history' | translate) }}" id="tab1">
            <tuya-expanded-order-view [orderDetailsMode]="true"
                                      [isShipperSuspended]="isShipperSuspended"
                                      [orderItem]="order">
            </tuya-expanded-order-view>
            <tuya-order-status-bar [orderSummary]="orderSummary" [isLoading]="isLoading"></tuya-order-status-bar>
            <tuya-events-log [orderEventsList]="orderEventsList" 
                             [errorMessageKey]="errorMessageKey"
                             [isLoading]="isLoading"
                             [isShipperSuspended]="isShipperSuspended"
                             [statusId]="order.statusId">
            </tuya-events-log>
        </tab>
        <tab heading="{{isLoading ? '' : ('tuya-my-orders.driver-info.order-summary' | translate)}}">
            <tuya-order-summary-log *ngIf="order.stopList"
                [stopList]="order.stopList"
                [order]="order">
            </tuya-order-summary-log>
        </tab>
    </tabset>
</div>
