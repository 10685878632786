import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class SvgConvertorService {
    constructor(private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer) {
    }

    svgConverToMatIcon(svWithPath: string, iconName: string) {
        return this.matIconRegistry.addSvgIcon(
            iconName,
            this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../../assets/img/${svWithPath}`)
        );
    }

}
