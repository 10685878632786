import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ComplexToken, Credentials } from '../model/model';
import { BaseService } from '../../core/services/base.service';
import { ConfigService } from '../../app.config.service';
import {
    API_CLIENT_ID,
    API_OPEN_ID_GET_TOKEN,
    API_OPEN_ID_REVOCATION,
    API_PASSWORD_GRANT_TYPE,
    API_SCOPE,
    API_REFRESH_TOKEN_GRANT_TYPE,
    API_REQUEST_VERIFICATION_EMAIL,
    API_SHIPPER_CHANGE_PASSWORD,
    API_SHIPPER_FORGOT_PASSWORD,
    API_SHIPPER_RESET_PASSWORD,
    API_SHIPPER_VALIDATE_PASSWORD
} from '../../core/constants/api.constants';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../../core/constants/storage.constants';
import { Guid } from '../../core/utils/guid';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthenticationService extends BaseService {

    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    refreshToken(): Observable<ComplexToken> {
        const refreshData: any = {
            refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY),
            grant_type: API_REFRESH_TOKEN_GRANT_TYPE,
            client_id: API_CLIENT_ID
        };

        return this.api(true).POST_OPEN_ID(API_OPEN_ID_GET_TOKEN, refreshData).pipe(
            mergeMap((newToken: ComplexToken) => {
                localStorage.setItem(ACCESS_TOKEN_KEY, newToken.access_token);

                if (newToken.refresh_token) {
                    if (!environment.production) console.debug("refreshToken: new refresh token received", newToken);
                    localStorage.setItem(REFRESH_TOKEN_KEY, newToken.refresh_token);
                }

                return of(newToken);
            })
        );
    }

    requestForgotPassword(email: string) {
        return this.api().POST(API_SHIPPER_FORGOT_PASSWORD, { email });
    }

    requestResetPassword(resetToken, newPassword) {
        return this.api().PUT(API_SHIPPER_RESET_PASSWORD, {
            token: resetToken,
            password: newPassword
        });
    }

    validateNewPassword(newPassword) {
        return this.api().POST(API_SHIPPER_VALIDATE_PASSWORD, { password: newPassword });
    }

    requestVerificationEmail() {
        return this.api().POST(API_REQUEST_VERIFICATION_EMAIL);
    }

    requestChangePasword(passwords) {
        return this.api().PUT(API_SHIPPER_CHANGE_PASSWORD, {
            oldPassword: passwords.password,
            password: passwords.newPassword
        });
    }

    // Open Id requests;
    openIdLogin(cred: Credentials): Observable<ComplexToken> {
        const loginData: any = {
            username: cred.username,
            password: cred.password,
            grant_type: API_PASSWORD_GRANT_TYPE,
            client_id: API_CLIENT_ID,
            scope: API_SCOPE,
            fire_token: cred.fbToken || Guid.generate() // use firebase token OR generate a unique ID to avoid collisions in the backend which cause token revocation            
        };
        // "[" + API_SCOPE.map(scope => '"' + scope + '"').join(",") + "]" // Construct the JSON array string

        if (!environment.production) {
            console.debug("openIdLogin", loginData);
        }

        return this.api(true).POST_OPEN_ID(API_OPEN_ID_GET_TOKEN, loginData).pipe(
            map((data: any) => data as ComplexToken)
        );
    }

    openIdCreateAccessToken() {
        const refreshData: any = {
            refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY),
            grant_type: API_REFRESH_TOKEN_GRANT_TYPE,
            client_id: API_CLIENT_ID
        };

        if (!environment.production) console.debug("openIdCreateAccessToken", refreshData);
        return this.api(true).POST_OPEN_ID(API_OPEN_ID_GET_TOKEN, refreshData);
    }

    openIdLogout() {
        const logoutData: any = {
            token: localStorage.getItem(REFRESH_TOKEN_KEY),
            token_type_hint: API_REFRESH_TOKEN_GRANT_TYPE,
            client_id: API_CLIENT_ID
        };

        return this.api(true).POST_OPEN_ID(API_OPEN_ID_REVOCATION, logoutData);
    }
}