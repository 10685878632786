import {OrderDetailsSummary, OrderExpandedView} from '../../model/my-orders.model';
import { Component, Input, OnInit } from '@angular/core';
import { EventItem } from '../../model/order-events.model';

@Component({
    selector: 'tuya-driver-info-container',
    templateUrl: './driver-info.container.component.html',
    styleUrls: ['./driver-info.container.component.scss']
})
export class DriverInfoContainerComponent implements OnInit {
    @Input() isShipperSuspended: boolean;
    @Input() isLoading: boolean;
    @Input() set order(data) {
        this._order = data;
        this.orderSummary = {
            orderStatusType: data.statusId,
            exceptionsCount: data.exceptionsCount,
            endDate: data.endDate
        };
    }
    @Input() errorMessageKey: string;
    get order() {
        return this._order;
    }

    @Input() orderEventsList: Array<EventItem>;
    _order: OrderExpandedView;
    orderSummary: OrderDetailsSummary;

    constructor() {
    }

    ngOnInit() {}

}
