import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UIActions } from '../../actions/ui.actions';


@Component({
  selector: 'tuya-order-confirm',
  templateUrl: './order-confirm.component.html',
  styleUrls: ['./order-confirm.component.scss']
})
export class OrderConfirmComponent implements OnInit {

  @Input() orderCode: string;
  @Input() orderId: any;

  @Output() newOrderCreate = new EventEmitter<any>();
  @Output() viewOrderDetails = new EventEmitter<any>();

  constructor(private uIActions: UIActions) {}

  ngOnInit() {}

  onCreateNewOrder() {
     this.newOrderCreate.emit();
  }

  goToOrderDetails() {
      this.uIActions.goToOrderDetails({orderId: this.orderId});
  }
}
