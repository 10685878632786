import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { first, Observable } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store/model';
import { AuthGuard } from './auth.guard';
import { AuthActions } from '../actions/auth.actions';

@Injectable()
export class HomeGuard implements CanActivate {
  constructor(private ngRedux: NgRedux<IAppState>,
              private _authGuard: AuthGuard,
              private authActions: AuthActions) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this._authGuard.canActivate(route).pipe(first()).subscribe((data: boolean) => {
                if (!data) {
                    observer.next(false);
                } else {
                    const auth = this.ngRedux.getState().auth;
                    if (auth.currentUser && auth.currentUser.shipperProfile && auth.currentUser.shipperProfile.hasOrders) {
                        auth.loggedIn ? this.authActions.goToPageOrders() : this.authActions.logout();
                        observer.next(false);
                    } else {
                        observer.next(true);
                    }
                }
            });
        });
    }
}
