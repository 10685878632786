import { IBuilderState } from '../models/builder.model';
import { getInitialOrderState } from '../models/order.model';
import { getInitialUIstate } from '../models/ui.model';
import { merge } from 'lodash-es';
import { getOrderEditMode, getCurrentStep } from '../utils/order.utils';
import { transformOrderDtoToOrderAppState, transformStopsToMinimalRequirement } from '../transforms/builder.transforms';
import {
    BuilderAction,
    BUILDER_INIT,
    BUILDER_ADD_ITEM_TO_ORDER,
    BUILDER_FORM_UPDATE,
    BUILDER_REMOVE_ITEM_FROM_ORDER,
    BUILDER_EDIT_ITEM,
    BUILDER_ADD_STOP_TO_ORDER,
    BUILDER_EDIT_STOP,
    BUILDER_SUMMARY_UPDATE,
    SET_ORDER_SUMMARY,
    BUILDER_SET_VEHICLE_TYPE,
    BUILDER_SELECT_DRIVERS,
    BUILDER_SET_USER_ID,
    BUILDER_SELECT_POOLS,
    BUILDER_UPDATE_ORDER,
    BUILDER_SET_DRIVER_SELECT_SEARCH_TERM,
    BUILDER_SET_DRIVER_SELECT_PENDING,
    BUILDER_SET_DRIVER_SELECT_SEARCH_RESULTS,
    BUILDER_SET_CURRENT_STEP,
    BUILDER_ADDRESS_SELECT,
    BUILDER_DISPLAY_SPINNER,
    BUILDER_HIDE_SPINNER,
    BUILDER_EDIT_NAME_OR_DESCRIPTION,
    BUILDER_EDIT_PHONE,
    BUILDER_POST_SUCCESS,
    BUILDER_PUT_SUCCESS,
    BUILDER_CONFIRM_SUCCESS,
    BUILDER_TIME_SELECTION_CHANGE,
    BUILDER_SUBMIT_ERROR,
    BUILDER_CLEAR_SUBMIT_ERROR,
    BUILDER_EDIT_TIME,
    BUILDER_EDIT_LOAD_ORDER,
    BUILDER_EDIT_LOAD_ORDER_SUCCESS,
    BUILDER_EDIT_LOAD_ORDER_FAIL,
    BUILDER_EDIT_ORDER,
    BUILDER_FORM_NAV_PROMPT_REQUIRED,
    BUILDER_FORM_NAV_PROMPT_NOT_REQUIRED,
    BUILDER_SET_ROUTIFIC_ERROR,
    BUILDER_REMOVE_DELIVERY_STOP,
    BUILDER_CLEAR_ROUTIFIC_ERROR,
    BUILDER_UPDATE_MODE,
    BUILDER_WAIT_TIME_PASSED,
    UPDATE_INIT_PRICE,
    SET_SCHEDULED_ORDER_SUBMIT,
    ALLOW_ORDER_PRICE_CHANGE} from '../actions/builder.actions';
import { TURN_OFF_ALL_SPINNERS } from '../../core/actions/alert.actions';

export function initialBuilderState(useDefault = false): IBuilderState {
    return {
            order: getInitialOrderState(useDefault),
            ui: getInitialUIstate(useDefault),
        };
}

export function builderReducer(state = initialBuilderState(), a: BuilderAction): IBuilderState {
    const action = a as BuilderAction;

    switch (action.type) {

        case BUILDER_INIT: {
            return {
                ...initialBuilderState(action.payload.useDefault),
            };
        }
        case BUILDER_EDIT_LOAD_ORDER: {
            const shipperContact = state.order.shipperContactId;
            return {
                ...initialBuilderState,
                order: {
                    ...state.order,
                    shipperContactId: shipperContact
                },
                ui: {
                    ...initialBuilderState().ui,
                    isLoading: true,
                    isEditMode: true,
                    isEditOrderLoaded: false
                }
            };
        }
        case BUILDER_UPDATE_MODE: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isEditMode: action.payload
                }
            };
        }
        case BUILDER_EDIT_LOAD_ORDER_SUCCESS: {
            // Delete values not used by shipment builder.
            delete action.payload.metropolitanStatisticalArea;
            delete action.payload.paymentInfo;
            return {
                order: {
                    ...state.order,
                    ...action.payload,
                    stops: [...transformStopsToMinimalRequirement(action.payload.stops)]
                },
                ui: {
                    ...initialBuilderState().ui,
                    isLoading: false,
                    isEditMode: true,
                    orderEditMode: getOrderEditMode(action.payload.statusId),
                    accordion: {
                        currentStep: getCurrentStep(action.payload, state.ui.isDuplicateMode)
                    },
                    editOrder: {
                        price: action.payload.total,
                        isInitPrice: true
                    },
                    isEditOrderLoaded: true
                }
            };
        }
        case BUILDER_EDIT_LOAD_ORDER_FAIL: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isLoading: false
                }
            };
        }
        case BUILDER_ADD_ITEM_TO_ORDER: {
            return {
                ...state,
                order: {
                    ...state.order,
                    orderItems: [...state.order.orderItems, action.payload]
                }
            };
        }
        case BUILDER_REMOVE_ITEM_FROM_ORDER: {
            return {
                ...state,
                order: {
                    ...state.order,
                    orderItems: [
                        ...state.order.orderItems.filter((item, index) => index !== action.payload),
                    ]
                }
            };
        }
        case BUILDER_ADD_STOP_TO_ORDER: {
            return {
                ...state,
                order: {
                    ...state.order,
                    stops: [...transformStopsToMinimalRequirement([
                        ...state.order.stops, action.payload
                    ])]
                }
            };
        }

        case BUILDER_EDIT_ITEM: {
            return {
                ...state,
                order: {
                    ...state.order,
                    orderItems: state.order.orderItems.map((item, index) => {
                        if (index === action.payload.index) {
                            return action.payload.item;
                        } else {
                            return item;
                        }
                    })
                }
            };
        }

        case BUILDER_EDIT_STOP: {
            return {
                ...state,
                order: {
                    ...state.order,
                    stops: state.order.stops.map(
                        (stop, i) => {
                            if (i === action.payload.index) {
                                return merge(stop, action.payload.stop);
                            } else {
                                return stop;
                            }
                        }
                    )
                }
            };
        }

        case BUILDER_FORM_UPDATE: {
            return {
                ...state
            };
        }

        case BUILDER_SUMMARY_UPDATE: {
            return {
                ...state,
                order: {
                    ...state.order,
                    orderSummary: {
                        ...state.order.orderSummary,
                        ...state.order.orderSummary[action.payload.summaryKey] = action.payload.summaryValue
                    }
                }
            };
        }

        case SET_ORDER_SUMMARY: {
            return {
                ...state,
                order: {
                    ...state.order,
                    orderSummary: action.payload
                }
            };
        }

       case BUILDER_SET_VEHICLE_TYPE: {
            return {
                ...state,
                order: {
                    ...state.order,
                    vehicleType: action.payload
                }
            };
       }

        case BUILDER_SELECT_DRIVERS: {
            return {
                ...state,
                order: {
                    ...state.order,
                    assignments: {
                        ...state.order.assignments,
                        pools: [],
                        drivers: action.payload.drivers
                    }
                }
            };
        }

        case BUILDER_SELECT_POOLS: {
            return {
                ...state,
                order: {
                    ...state.order,
                    assignments: {
                        ...state.order.assignments,
                        pools: action.payload.pools,
                        drivers: []
                    }
                }
            };
        }

        case BUILDER_SET_USER_ID: {
            return {
                ...state,
                order: {
                    ...state.order,
                    shipperContactId: action.payload.id
                }
            };
        }

        case BUILDER_UPDATE_ORDER: {
            return {
                ...state,
                order: {
                    ...state.order,
                    [action.payload.key]: action.payload.value
                }
            };
        }

        case BUILDER_SET_DRIVER_SELECT_SEARCH_TERM: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    driverSelection: {
                        ...state.ui.driverSelection,
                        [action.payload.driverOrPool]: {
                            ...state.ui.driverSelection[action.payload.driverOrPool],
                            searchTerm: action.payload.searchTerm
                        }
                    }
                }
            };
        }

        case BUILDER_SET_DRIVER_SELECT_PENDING: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    driverSelection: {
                        ...state.ui.driverSelection,
                        [action.payload.driverOrPool]: {
                            ...state.ui.driverSelection[action.payload.driverOrPool],
                            pending: action.payload.pending
                        }
                    }
                }
            };
        }

        case BUILDER_SET_DRIVER_SELECT_SEARCH_RESULTS: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    driverSelection: {
                        ...state.ui.driverSelection,
                        [action.payload.driverOrPool]: {
                            ...state.ui.driverSelection[action.payload.driverOrPool],
                            searchResults: action.payload.searchResults
                        }
                    }
                }
            };
        }

        case BUILDER_SET_CURRENT_STEP: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    accordion: {
                        ...state.ui.accordion,
                        currentStep: action.payload.to
                    }
                }
            };
        }

        case BUILDER_ADDRESS_SELECT: {
            const fields = [
                'contactName',
                'contactPhoneNumber',
                'contactEmail',
                'nameOrDescription',
                'address',
                'isEmailTrackingLink',
                'isSignatureRequired'
            ];
            if (action.payload.stop.address.id && action.payload.stop.address.id < 1) {
                delete action.payload.stop.address.id;
            }
            return {
                ...state,
                order: {
                    ...state.order,
                    stops: state.order.stops.map(
                        (stop, index) => {
                            if (index === action.payload.index) {
                                fields.forEach( fieldName => {
                                    if (action.payload.stop.hasOwnProperty(fieldName)) {
                                        stop[fieldName] = action.payload.stop[fieldName];
                                    }
                                });
                            }
                            return stop;
                        }
                    )
                }
            };
        }

        case BUILDER_DISPLAY_SPINNER: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isLoading: true
                }
            };
        }

        case BUILDER_HIDE_SPINNER: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isLoading: false
                }
            };
        }

        case BUILDER_EDIT_NAME_OR_DESCRIPTION: {
            return {
                ...state,
                order: {
                    ...state.order,
                    stops: state.order.stops.map(
                        (stop, index) => {
                            if (index === action.payload.stopIndex) {
                                stop.nameOrDescription = action.payload.nameOrDescription;
                                stop.address.name = action.payload.nameOrDescription;
                            }
                            return stop;
                        }
                    )
                }
            };
        }

        case BUILDER_EDIT_PHONE: {
            return {
                ...state,
                order: {
                    ...state.order,
                    stops: state.order.stops.map(
                        (stop, index) => {
                            if (index === action.payload.stopIndex) {
                                stop.contactPhoneNumber = action.payload.phoneNumber;
                            }
                            return stop;
                        }
                    )
                }
            };
        }

        case BUILDER_POST_SUCCESS: {
            const orderStateObj = transformOrderDtoToOrderAppState(action.payload.result);
            return {
                ...state,
                order: merge({...state.order}, orderStateObj),
                ui: {
                    ...state.ui,
                    editOrder: {
                        ...state.ui.editOrder,
                        price: action.payload.result.total
                    }
                }
            };
        }

        case BUILDER_PUT_SUCCESS: {
            const orderStateObj = transformOrderDtoToOrderAppState(action.payload.result);
            const currentStep = state.ui.accordion.currentStep;
            return {
                ...state,
                order: merge(state.order, orderStateObj),
                ui: {
                    ...state.ui,
                    isLoading: action.payload.isOptimizePrice ? true : false,
                    accordion: {
                        currentStep: action.payload.isOptimizePrice ? currentStep : currentStep + 1,
                    },
                    editOrder: {
                        ...state.ui.editOrder,
                        price: action.payload.isAdmin ? state.ui.editOrder.price : action.payload.result.total
                    }
                }
            };
        }

        case BUILDER_CONFIRM_SUCCESS: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    orderCode: action.payload.orderCode
                }
            };
        }

        case BUILDER_TIME_SELECTION_CHANGE: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    timeSelections: [
                        action.payload.index = action.payload.selection
                    ]
                }
            };
        }

        case BUILDER_SUBMIT_ERROR: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    submitErrors: {
                        ...state.ui.submitErrors,
                        [action.payload.index]: action.payload.messageKey
                    }
                }
            };
        }

        case BUILDER_CLEAR_SUBMIT_ERROR: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    submitErrors: [
                        state.ui.submitErrors[action.payload.index] = null
                    ]
                }
            };
        }

        case BUILDER_EDIT_TIME: {
            return {
                ...state,
                order: {
                    ...state.order,
                    stops: state.order.stops.map(
                        (stop, index) => {
                            if (index === action.payload.stopIndex) {
                                stop[action.payload.fieldName] = action.payload.time;
                            }
                            return stop;
                        }
                    )
                }
            };
        }

        case BUILDER_EDIT_ORDER: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isEditMode: true,
                    isDuplicateMode: action.payload.isDuplicateMode ? true : false
                }
            };
        }

        case BUILDER_FORM_NAV_PROMPT_REQUIRED: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    navPromptRequired: true
                }
            };
        }

        case BUILDER_FORM_NAV_PROMPT_NOT_REQUIRED: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    navPromptRequired: false
                }
            };
        }

        case BUILDER_SET_ROUTIFIC_ERROR: {
            const newTimeSelections = [2];
            return {
                ...state,
                ui: {
                    ...state.ui,
                    timeSelections: newTimeSelections,
                    routificError: true
                }
            };
        }

        case BUILDER_REMOVE_DELIVERY_STOP: {
            return {
                ...state,
                order: {
                    ...state.order,
                    stops: state.order.stops.slice(0, 1)
                }
            };
        }

        case SET_SCHEDULED_ORDER_SUBMIT: {
            return {
                ...state,
                order: {
                    ...state.order,
                    submitOrder: action.payload
                }
            };
        }

        case BUILDER_CLEAR_ROUTIFIC_ERROR: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    routificError: false
                }
            };
        }

        case TURN_OFF_ALL_SPINNERS: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isLoading: false
                }
            };
        }

        case BUILDER_WAIT_TIME_PASSED: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    waitTimePassed: action.payload
                }
            };
        }

        case UPDATE_INIT_PRICE: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    editOrder: {
                        ...state.ui.editOrder,
                        isInitPrice: action.payload
                    }
                }
            };
        }

        case ALLOW_ORDER_PRICE_CHANGE: {
            return {
                ...state,
                order: {
                    ...state.order,
                    isPriceChangeAllowed: action.payload
                },
            };
        }

        default: {
            return state;
        }
    }
}
