<label
  for="{{ id }}"
  name="set-name"
  [ngClass]="{'tuya-switch-label': true, 'tuya-switch-label--disabled': disabled}">
    {{ label }}
</label>
<input 
  type="checkbox" 
  id="{{ id }}" 
  name="{{ name }}" 
  class="tuya-switch-input sr-only"
  [ngClass]="{'tuya-switch-input': true, 'tuya-switch-input--disabled': disabled, 'sr-only': true}"
  [disabled]="disabled"
  (change)="onToggle($event)"
  [checked]="model"
  attr.data-key="{{ key }}"
>

<label 
  [ngClass]="{'tuya-switch-wrap': true, 'tuya-switch-wrap--disabled': disabled}"
  for="{{ id }}">
    <span class="tuya-switch-nubbin"></span>
    <span class="toggle--on">{{ 'builderInstructions.emailSignatureToggleYes' | translate }}</span>
    <span class="toggle--off">{{ 'builderInstructions.emailSignatureToggleNo' | translate }}</span>
</label>
