<!-- @TODO: translate template text -->
<div class="tuya-builder-input">
  <form class="builder-form">
    <tuya-builder-reference-id [referenceId]="orderReferenceId">
    </tuya-builder-reference-id>

    <tuya-builder-stop *ngFor="let stop of stops$ | async; let i = index" [stopType]="stop.typeId" [stopIndex]="i"
      [stop]="stop" [items]="(items$ | async)" [currentStep]="(currentStep$ | async)"
      [timeSelections]="(timeSelections$ | async)" [setTimesInState]="setTimesInState" [easyStartTime]="easyStartTime"
      [easyEndTime]="easyEndTime" [easyStartTimeDisplay]="easyStartTimeDisplay"
      [easyEndTimeDisplay]="easyEndTimeDisplay" [submitErrors]="(submitErrors$ | async)"
      [materialLocked]="materialLocked" [nonMaterialLocked]="nonMaterialLocked"
      [isScheduledOrder]="(isScheduledOrder$ | async)" [isEditMode]="(isEditMode$ | async)" [pickupDate]="pickupDate"
      [vehicleType]="(order$ | async).vehicleType">
    </tuya-builder-stop>

    <!-- Fake collapsed stop to resolve delivery stop disappearing during POST. -->
    <div class="builder-stop builder-stop--inactive" *ngIf="(stops$ | async).length < 2">
      <h3 class="builder-stop-header builder-stop-header--delivery">
        <div class="builder-stop-header-delivery-icon"></div>
        Delivery Stop
      </h3>
    </div>

    <tuya-builder-driver-selection [currentStep]="(currentStep$ | async)" [stopCount]="(stops$ | async).length"
      [materialLocked]="materialLocked || driversLocked"></tuya-builder-driver-selection>

    <tuya-builder-summary [stops]="stops$" [currentStep]="(currentStep$ | async)" [stopCount]="(stops$ | async).length"
      [isEditMode]="isEditMode$ | async" [restartTimer]="(restartTimer$ | async)"></tuya-builder-summary>

    <div class="builder-form-submit">
      <input type="submit" name="submit" class="builder-form-submit-button"
        value="{{ (isEditMode && !(isDuplicateMode$ | async) ? 'builderInput.update' : 'builderInput.submit') | translate }}"
        *ngIf="!isSubmitButtonDisabled()" (click)="submitForm()">
    </div>

    <tuya-builder-submit-error *ngIf="submitErrors.submit" [message]="'validation.' + submitErrors.submit | translate">
    </tuya-builder-submit-error>

  </form>

  <tuya-builder-map></tuya-builder-map>

</div>

<tuya-spinner size="fullwindow" *ngIf="isLoading$ | async">
</tuya-spinner>