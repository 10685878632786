
  <div class="item-warning" *ngIf="!stripeIsLive" style="background-color: #fff250;">
    Stripe Test Environment
  </div>

  <div #paymentElement id="paymentElement" style="margin: 25px;">
    <!-- Stripe injects payment UI here -->
  </div>


  <div class="row">
    <div class="col-md-4">
      <button type="button" class="btn btn-secondary"
        [disabled]="disableVerifyButton"
        (click)="createToken()">Verify Card</button>
      </div>

    <p id="stripeError" class="col-md-7" *ngIf="stripeShowError">
      <span>{{ stripeErrorMessage }}</span>
    </p>

    <p id="stripeConfirmed"  class="col-md-7" *ngIf="stripeShowConfirmed">
      Card Successfully Confirmed
    </p>
  </div>
