
<div class="wrapper">
    <div class="manage-company-wrapper">
        <tuya-company-header
            [companyProfilesInfo]="companyProfilesInfo$ | async">
        </tuya-company-header>

        <tuya-manage-company-tabs
            [currentActiveTab]="currentManageCompanyTab$ | async"
            [shipperProfilesInfo]="shipperProfilesInfo$ | async"
            [companyProfilesInfo]="companyProfilesInfo$ | async"
            (changeManageCompanyTab)="onChangeManageCompanyTab($event)">
        </tuya-manage-company-tabs>

        <tuya-general-information
            [currentManageCompanyTab]="currentManageCompanyTab$ | async"
            [companyProfilesInfo]="companyProfilesInfo$ | async"
            [industrySegments]="industrySegments$ | async"
            [states]="states$ | async"
            [validateCompanyErrors]="validateCompanyErrors$ | async"
            [suspended]="suspended$ | async"
            (saveGeneralInfo)="onSaveGeneralInfo($event)">
        </tuya-general-information>
        
        <tuya-billing-contacts
            [currentManageCompanyTab]="currentManageCompanyTab$ | async"
            [companyProfilesInfo]="companyProfilesInfo$ | async"
            [states]="states$ | async"
            [arePhysicalAndBillingAddressTheSame]="arePhysicalAndBillingAddressTheSame"
            [suspended]="suspended$ | async"
            (saveBillingInfo)="onSaveBillingInfo($event)">
        </tuya-billing-contacts>

        <tuya-payment-methods
            (paymentOrderSubmit)="onPaymentOrder($event)"
            [currentManageCompanyTab]="currentManageCompanyTab$ | async"
            [paymentMethods]="paymentMethods$ | async"
            [suspended]="suspended$ | async">
        </tuya-payment-methods>

        <tuya-save-modal
            *ngIf="(validateCompanyErrors$ | async)?.isValid === true"
            (clearErrors)="onClearErrors($event)">
        </tuya-save-modal>

    </div>
    <div class="spinner-wrapper">
        <tuya-spinner *ngIf="isLoading$ | async" size="fullscreen"></tuya-spinner>
    </div>
    <ng-template #cancel>
        <tuya-cancel-modal (hideModal)="onHideModal($event)"></tuya-cancel-modal>
    </ng-template>
</div>
