import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { BaseService } from '../../core/services/base.service';
import {
    IndustrySegment,
    ServerErrorResultDto,
    ShipperRegisterationResultDto,
    ShipperRegistrationDto,
    StateDto
} from '../../core/models/dto';
import { ConfigService } from '../../app.config.service';

const BUSINESS_ENTTITY_INDUSTRY_SEGMENTS = '/api/v1/BusinessEntity/IndustrySegments';
const BUSINESS_ENTTITY_STATES = '/api/v1/BusinessEntity/States';
const SHIPPER_PROFILE_REGISTER = '/api/v1/ShipperProfile';
const SHIPPER_PROFILE_VALIDATE_ACCOUNT = '/api/v1/ShipperProfile/UserAccountAndShipperProfileValidation';
const SHIPPER_PROFILE_VALIDATE_BIS_ENTITY = '/api/v1/ShipperProfile/BusinessEntityAndReferralCodeValidation';

@Injectable()
export class RegisterService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    loadStates(): Observable<StateDto []> {
        return this.api().GET(BUSINESS_ENTTITY_STATES).pipe(
            map((data: StateDto[]) => data));
    }

    loadInsustrySegments(): Observable<IndustrySegment []> {
        return this.api().GET(BUSINESS_ENTTITY_INDUSTRY_SEGMENTS).pipe(
            map((data: IndustrySegment[]) => data));
    }

    registerShipper(shipperRegisterationDto: ShipperRegistrationDto): Observable<ShipperRegisterationResultDto> {
        return this.api().POST(SHIPPER_PROFILE_REGISTER, shipperRegisterationDto).pipe(
            map((data: ShipperRegisterationResultDto) => data));
    }

    validateAccountAndProfile(shipperRegisterationDto: ShipperRegistrationDto): Observable<ServerErrorResultDto> {
        return this.api().POST(SHIPPER_PROFILE_VALIDATE_ACCOUNT, shipperRegisterationDto).pipe(
            map((data: ServerErrorResultDto) => data));
    }

    validateEntityAndRefCodeAsync(shipperRegisterationDto: ShipperRegistrationDto): Observable<ServerErrorResultDto> {
        return this.api().POST(SHIPPER_PROFILE_VALIDATE_BIS_ENTITY, shipperRegisterationDto).pipe(
            map((data: ServerErrorResultDto) => data));
    }

    validateForm(form: any) {
        return of('valid');
    }
}
