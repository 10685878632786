export const locale = {
    'lang': 'en',
    'data': {
        'tuya-order-edit': {
            'confirm-dialog': {
                'message': 'Commit these changes to the order?'
            },
            'message': {
                'order-limitation': 'has already started working on your order. As a result, some of the changes are not possible.'
            }
        }
    }
};
