import { ShipmentType } from './../../core/models/shipment.model';
import { Stop, StopType } from './../models/order.model';

const PICKUP_TYPE_NAME = 'tuya-shipment.tuya-stop-details.pickup';
const DELIVERY_TYPE_NAME = 'tuya-shipment.tuya-stop-details.delivery';
const RETURN_TYPE_NAME = 'tuya-shipment.tuya-stop-details.return';
const DELIVERY_WITH_RETURN_TYPE_NAME = 'tuya-shipment.tuya-stop-details.delivery-with-return';
const LAST_RETURN_STOP_NAME = 'tuya-shipment.tuya-stop-details.return-delivery';


// const PICKUP_TYPE_NAME = 'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.pickup';
// const DELIVERY_TYPE_NAME = 'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.delivery';
// const RETURN_TYPE_NAME = 'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.return';
// const DELIVERY_WITH_RETURN_TYPE_NAME = 'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.delivery-with-return';

export class OrderHelper {
    public static getStopType(typeId: number, isNewCollectionPickupStop = false) {
        if (isNewCollectionPickupStop) {
            return PICKUP_TYPE_NAME;
        }
        switch (typeId) {
            case StopType.Pickup: {
                return PICKUP_TYPE_NAME;
            }
            case StopType.Return: {
                return RETURN_TYPE_NAME;
            }
            case StopType.DeliveryWithReturn: {
                return DELIVERY_WITH_RETURN_TYPE_NAME;
            }
            default:
                return DELIVERY_TYPE_NAME;
        }
    }

    public static getStopTypeName(typeId: number) {
        const typeStop = this.getStopType(typeId);
        if (typeStop === DELIVERY_WITH_RETURN_TYPE_NAME) {
          return DELIVERY_TYPE_NAME;
        }
        return (typeId === StopType.Return) ? LAST_RETURN_STOP_NAME : typeStop;
    }

    public static getTrackingStopTypeName(typeId: number) {
        const stopType = this.getStopType(typeId);
        return (stopType === DELIVERY_WITH_RETURN_TYPE_NAME) ? DELIVERY_TYPE_NAME : stopType;
    }

    public static isDeliveryStopExist(stopList: Stop[]) {
        return !!stopList.find((stop: Stop) => stop.typeId === StopType.Delivery
            || stop.typeId === StopType.DeliveryWithReturn);
    }

    public static isLastStopByType(stop: Stop, isStopsRequiredFieldsProvided: boolean, shipmentType: number, filterStopList: Stop []) {
        const typeId = shipmentType === ShipmentType.Collection ? StopType.Pickup : StopType.Delivery;
        if ((stop.typeId === typeId || stop.typeId === StopType.DeliveryWithReturn)
            && isStopsRequiredFieldsProvided) {
                const stops = filterStopList.filter(stopItem => stopItem.typeId === typeId
                    || stopItem.typeId === StopType.DeliveryWithReturn),
                lastStop = stops[stops.length - 1];
                if (lastStop.id === stop.id) {
                    return true;
                }
            }
        return false;
    }

    public static getLastStop(shipmentType: number, stopList: Stop []) {
        switch (shipmentType) {
          case ShipmentType.Collection: {
            const deliveryItem = stopList.find(stop => stop.typeId === StopType.Delivery);
            return deliveryItem || new Stop();
          }
          case ShipmentType.Distribution: {
            const returnStop = stopList.find(stop => stop.typeId === StopType.Return);
            return returnStop || new Stop();
          }
          default: {
            return new Stop();
          }
        }
    }

    public static getStopHeader(stopType: number) {
        return StopType[stopType] === StopType[StopType.DeliveryWithReturn] ?
          StopType[StopType.Delivery] : StopType[stopType];
    }
}
