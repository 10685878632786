import { Action } from 'redux';
import { OrderEventsState } from '../../model/order-events.model';
import { GET_ALL_ORDER_EVENTS_SUCCESS, GET_ALL_ORDER_EVENTS_FAILURE, OrderEventsAction } from '../../actions/order-events.action';

export const initialOrderEventsState: OrderEventsState = {
    eventsList: [],
    errorMessageKey: ''
};

export function orderEventsReducer(state = initialOrderEventsState, a: Action): OrderEventsState {
    const {type, payload} = a as OrderEventsAction;
    switch (type) {
        case GET_ALL_ORDER_EVENTS_SUCCESS: {
            return {
                ...state,
                eventsList: payload,
                errorMessageKey: ''
            };
        }
        case GET_ALL_ORDER_EVENTS_FAILURE: {
            return {
                ...state,
                eventsList: [],
                errorMessageKey: payload.errorMessageKey
            };
        }
        default:
            return state;
    }
}


