<div class="home-wrapper">
    <div class="home-container">
        <div class="home-content">
            <!-- Email is not confirmed -->
            <ng-template [ngIf]="checkEmailConfirmation()">
                <div class="content-image check-email"></div>
                <h1 class="welcomeText">{{'tuya-home.welcome' | translate }}, {{username}}!</h1>
                <p>{{ 'tuya-home.shipper-email-not-verified' | translate }}
                    {{ 'tuya-home.shipper-email-not-verified-resend-pre' | translate }}
                    <a class="link" (click)="resendVerficationEmailClick()"
                        [translate]="'tuya-home.shipper-email-not-verified-resend-link'"></a>
                    {{ 'tuya-home.shipper-email-not-verified-resend-post' | translate }}
                </p>
            </ng-template>

            <!-- Has no CC, under terms false -->
            <ng-template [ngIf]="checkHasValidCC()">
                <div class="content-image shipper-no-credit-card"></div>
                <h1 class="welcomeText">{{'tuya-home.lets-get-shipping' | translate }}, {{username}}!</h1>
                <p [translate]="'tuya-home.shipper-no-credit-card'"></p>
                <a class="btn btn-secondary btn-lg resend-link" [routerLink]="['/company']"
                    [translate]="'tuya-header.btn.add-credit-card'"
                    [ngClass]="suspended ? 'suspended-button' : ''"
                    (click)="profilesActions.changeManageCompanyTab(3)"></a>
            </ng-template>
            
            <!-- Has no orders -->
            <ng-template [ngIf]="checkHasOrders()">
                <div class="content-image shipper-no-orders"></div>
                <h1 class="welcomeText">{{'tuya-home.welcome' | translate }}, {{username}}!</h1>
                <p [translate]="'tuya-home.shipper-no-orders'"></p>
                <a class="btn btn-primary btn-lg resend-link"
                   [routerLink]="['/builder']"
                   [ngClass]="suspended ? 'suspended-button' : ''"
                   [translate]="'tuya-header.btn.shipment'"></a>
            </ng-template>

            <ng-template [ngIf]="!isEmailConfirmed && bSentStub.getValue()">
                <div class="content-image check-email"></div>
                <h1 class="welcomeText">{{'tuya-home.welcome' | translate }}, {{username}}!</h1>
                <p [translate]="'tuya-home.shipper-email-not-verified-sent-stub'"></p>
            </ng-template>

            <ng-template [ngIf]="isEmailConfirmed && hasOrders">
                <div class="content-image"></div>
                <h1 class="welcomeText">{{'tuya-home.welcome' | translate }}, {{username}}</h1>
            </ng-template>
        </div>
        <div class="circle-background"></div>
    </div>

    <div class="row contact-info text-center" *ngIf="checkEmailConfirmation()">
        <p class="call-text">For assistance, please call:</p>
        <p class="call-number">(833) 488-9266.</p>
    </div>
</div>