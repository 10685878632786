import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';

import { BaseService } from '../../core/services/base.service';
import { ConfigService } from '../../app.config.service';
import { DriverResponse, DriverTablePagination, DriverDTO, VehicleDTO } from '../models/driverportal.model';
import { GET_DRIVERS, ACTIVATE_DEACT_DRIVER, RESET_DRIVER_PASSWORD, ADD_A_DRIVER, UPDATE_VEHICLE, ADD_VEHICLE } from '../../core/constants/api.constants';

@Injectable()
export class DriverPortalService extends BaseService {

  constructor(http: HttpClient, configService: ConfigService) {
    super(http, configService);
  }

  getDriverName(drs, vehicleId: number)  {
    let driverName = '';
    if (!vehicleId) {
      return driverName;
    }

    const drivers = drs.filter(driver => {
      if (driver.vehicle && vehicleId === driver.vehicle.id) {
        return driver;
      }
    });
    if (drivers.length > 0) {
      driverName = drivers[0].name;
    }
    return driverName;
  }

  getDriverNameByDriverId(store, driverId: string)  {
    let driverName = '';
    if (!driverId) {
      return driverName;
    }

    const drivers = store.value.admin.driverPortal.drivers.filter(driver => driverId === driver.id);
    if (drivers.length > 0) {
      driverName = drivers[0].name;
    }
    return driverName;
  }

  // https://github.com/angular/angular/issues/20460

  getDrivers(params: DriverTablePagination): Observable<DriverResponse> {
    return this.api().GET(GET_DRIVERS, params).pipe(map((data: any) => data));
  }

  activateDeactDriverBy(body: {id: string, active: boolean}): Observable<any> {
    return this.api().PUT(ACTIVATE_DEACT_DRIVER, body).pipe(map((data: any) => data));
  }

  resetDriverPasswordBy(body: {driverId: string, driverEmail: string}): Observable<any> {
    return this.api().PUT(RESET_DRIVER_PASSWORD, body).pipe(map((data: any) => data));
  }

  addNewDriver(body: DriverDTO): Observable<{driverId: number}> {
    return this.api().POST(ADD_A_DRIVER, body).pipe(map((data: any) => data));
  }

  addDriverVehicle(body: VehicleDTO): Observable<{driverId: number}> {
    return this.api().POST(ADD_VEHICLE, body).pipe(map((data: any) => data));
  }

  updateVehicle(body: VehicleDTO): Observable<{response: boolean}> {
    return this.api().PUT(UPDATE_VEHICLE, body).pipe(map((data: any) => data));
  }

}
