import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderExpandedView, OrderStop, StopStatus, StatisticInfo } from '../../model/my-orders.model';
import { OrderStatuses, OrderStatusName } from '../../../core/models/order.dto';
import { ConfigService } from '../../../app.config.service';
import { isEditOrderDisabled } from '../../../shipment-edit/utils/order.utils';
import { DomSanitizer } from '@angular/platform-browser';
//import { get } from 'lodash/fp';
import { get } from 'lodash-es';
import { CombinedPoolsDTO } from 'app/core/models/dto';

@Component({
    selector: 'tuya-expanded-driver-info',
    templateUrl: './expanded-driver-info.component.html',
    styleUrls: ['./expanded-driver-info.component.scss']
})
export class ExpandedDriverInfoComponent {
    @Input() pools: CombinedPoolsDTO[];
    @Input() isShipperSuspended = false;
    @Input() isEditLoading: boolean;
    @Input() isLoading = false;
    @Input()
    set order(order) {
        this._order = order;
        this.driverInfo = this._order.assignments;
        this.statisticInfo = this._order.driverResponceStatistics;
        this.setRateWidgetParams();
        this.showDeclinedBlock = !this.driverInfo.hasAssignedDrivers &&
            (this.statisticInfo.offeredDriversCount !== this.statisticInfo.declinedDriversCount ||
                this.statisticInfo.declinedDriversCount === 0);
        this.showAllDeclinedBlock = !this.driverInfo.hasAssignedDrivers &&
            (this.statisticInfo.offeredDriversCount === this.statisticInfo.declinedDriversCount) &&
            this.statisticInfo.declinedDriversCount !== 0;
    }

    get order(): OrderExpandedView {
        return this._order;
    }

    @Input()
    set combinePools(combinePools) {
        this._combinePools = combinePools;
    }

    get combinePools() {
        return this._combinePools;
    }

    @Output() createPool = new EventEmitter();
    @Output() sendPools = new EventEmitter();
    @Output() removePools = new EventEmitter();

    @Output() updateRate = new EventEmitter();
    @Output() editOrder = new EventEmitter();

    public isTimeToRateExceed: boolean;
    public driverInfo: any | undefined;
    public statisticInfo: StatisticInfo;
    public shipperRate: number | undefined;
    public shipperReasonId: number | undefined;
    public isRateUpdated: boolean | undefined;
    public showDeclinedBlock: boolean;
    public showAllDeclinedBlock: boolean;

    private _order: OrderExpandedView | undefined;
    private _combinePools: Array<any>;

    constructor(private configService: ConfigService,
        public sanitizer: DomSanitizer) {
    }

    public get isCancelled(): boolean {
        return (
            OrderStatuses[this._order.statusId].name === OrderStatusName.Canceled ||
            OrderStatuses[this._order.statusId].name === OrderStatusName.PendingCancellation
        );
    }

    public get isAllowedToRateDriver(): boolean {
        const completedStops = this._order.stopList.filter(stop => stop.statusId === StopStatus.Completed ||
            stop.statusId === StopStatus.CompletedWithException);
        return completedStops.length > 0;
    }

    public get addedPools(): Array<any> {
        if (this._order.assignments.addedPools) {
            return this._order.assignments.addedPools.map(pool => pool.name);
        }
        return [];
    }

    public onEditOrder() {
        this.editOrder.emit();
    }

    public onCreatePool(evt: any): void {
        this.createPool.emit(evt);
    }

    public isEditOrderDisabled() {
        return isEditOrderDisabled(this._order.statusId);
    }

    public onRateDriver(data: any): void {
        this.updateRate.emit(data);
    }

    public get driverImageUrl() {
        return get(this._order, 'assignments.drivers[0].image') || '../../../../assets/img/my-orders/img_driver_avatar.svg';
    }

    addRemovePools(ev) {
        if (ev.add.length !== 0) {
            this.sendPools.emit(ev.add);
        }
        if (ev.remove.length !== 0) {
            this.removePools.emit(ev.remove);
        }
    }

    private setRateWidgetParams() {
        if (OrderStatuses[this._order.statusId].name === OrderStatusName.Completed ||
            OrderStatuses[this._order.statusId].name === OrderStatusName.CompletedWithExceptions) {
            const lastCompletedStop = this.getLastCompletedStop(this._order.stopList);
            this.isTimeToRateExceed = this.isTimeToRateDriverExceed(new Date(lastCompletedStop.completedTimeStamp));
        }
        if (this.driverInfo.assessment) {
            this.shipperRate = this.driverInfo.assessment.rate;
            this.shipperReasonId = this.driverInfo.assessment.reasonId;
            this.isRateUpdated = this.driverInfo.assessment.isRateUpdated;

            const NOTIFICATION_TIMEOUT = 3000;
            setTimeout(() => this.hideRateDriverNotification(), NOTIFICATION_TIMEOUT);
        }
    }

    private getLastCompletedStop(stops): OrderStop {
        return stops.reduce((lastStop, currStop) => lastStop.completedTimeStamp > currStop.completedTimeStamp ? lastStop : currStop);
    }

    private hideRateDriverNotification() {
        this.isRateUpdated = false;
    }

    private isTimeToRateDriverExceed(date: Date): boolean {
        const nowMS = new Date().getTime();
        const completionDateMS = date.getTime();
        const differenceInMS = nowMS - completionDateMS;
        const differenceInDays = differenceInMS / (1000 * 60 * 60 * 24);
        const MAX_ALLOWED_DAYS = 7;
        return Math.round(differenceInDays) > MAX_ALLOWED_DAYS;
    }
}
