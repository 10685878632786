import { AfterContentInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'tuya-stop-details-form',
    template: `<form name="form" #form="ngForm"></form>  `
})
export class StopDetailsFormComponent implements AfterContentInit {
    @Input() isFormPristine = true;
    @Output()
    change = new EventEmitter();
    @ViewChild('form', {static: true}) public form: NgForm;
    constructor () {}

    ngAfterContentInit () {
        this.form.valueChanges.subscribe(changes => {
            if (this.form.dirty && this.isFormPristine) {
                this.isFormPristine = false;
                this.change.emit();
            }
        });
    }
}
