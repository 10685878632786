import { Component, Input, OnInit } from '@angular/core';

export type SpinnerSize = 'small' | 'fullscreen';

@Component({
  selector: 'tuya-ng-select-spinner',
  templateUrl: './select-spinner.component.html',
  styleUrls: ['./select-spinner.component.scss']
})
export class SelectSpinnerComponent implements OnInit {

  @Input() size: SpinnerSize;
  constructor() { }

  ngOnInit() {
  }

}
