import { Reference } from './../../core/models/order.dto';
import { BoxSizeTypes, ConfirmationDriversInfoDto, OrderItemVo, OrderVo, Stop, StopType } from '../models/order.model';
import { Action } from 'redux';
import { Guid } from '../../core/utils/guid';
import { AddressDto, OrderStatusId, StopTypeId } from '../../core/models/dto';
import {
    NEW_CONFIRM_ORDER_CREATE_SUCCESS,
    OPEN_SHIPMENT_SUCCESS,
    SET_SHIPMENT_TYPE,
    SHIPMENT_ADD_MISSED_DEFAULT_ITEM_TO_STOP,
    SHIPMENT_DRIVER_TYPE_CREATE,
    SHIPMENT_ORDER_ADD_STOP,
    SHIPMENT_ORDER_CURRENT_STOP_SELECT,
    SHIPMENT_ORDER_ITEMS_UPDATE,
    SHIPMENT_ORDER_PICKUP_STOP_SUITE_NUMBER_ADD,
    SHIPMENT_ORDER_REMOVE_FIRST_STOP,
    SHIPMENT_ORDER_REMOVE_STOP,
    SHIPMENT_ORDER_SAVE_TO_STATE,
    SHIPMENT_ORDER_STOP_NAME_UPDATE,
    SHIPMENT_ORDER_STOP_UPDATE,
    SHIPMENT_ORDER_UPDATE_STOP_ADDRESS,
    ShipmentAction, SHIPMENT_ORDER_REMOVE_STOP_ADDRESS
} from '../actions/shipment.actions';
import {
    SHIPMENT_IS_DIRECT_ORDER_UPDATE,
    SHIPMENT_SAVE_DEFAULT_DATE_TIME_FOR_STOP_LIST,
    SHIPMENT_SET_EXTENDABLE
} from '../actions/order-stops.actions';
import { StopHelper } from '../utils/stop-helper.utis';
import { ShipmentType } from '../../core/models/shipment.model';
import { AssignmentsDto, SelectedCandidatesDto } from '../../core/models/order.dto';

export const initialOrderVo: OrderVo = {
    deliveryType: 0,
    total: 0,
    isExtendable: false,
    // orderCode: '',
    // ... other required order-specific fields
    stopList: [],  // List of stops specified by user contains ids from stopList.
    itemList: Array<OrderItemVo>(),
    selectedStopId: null,
    isDirect: false,
    confirmationDriversInfo: new ConfirmationDriversInfoDto(),
    orderSummary: {items: 0, stops: 0, lbs: 0, miles: 0, returns: 0},
    vehicleType: null,
    selectedCandidates: new SelectedCandidatesDto(),
    statusId: OrderStatusId.Draft,
    assignments: new AssignmentsDto(),
    hasValidCreditCard: false,
    referenceId: null
};

export function orderReducer(state = initialOrderVo, a: Action): OrderVo {
    const action = a as ShipmentAction;
    switch (action.type) {
        case OPEN_SHIPMENT_SUCCESS: {
            return {
                ...state,
                selectedStopId: null
            };
        }
        case SET_SHIPMENT_TYPE: {
            return {
                ...state,
                deliveryType: action.payload
            };
        }
        case SHIPMENT_IS_DIRECT_ORDER_UPDATE: {
            const copyStopList = state.stopList.slice();
            // clear time values for Rush Order first Pickup Stop
            if (action.payload.isDirect) {
                copyStopList.map(stop => {
                    if (stop.typeId === StopType.Pickup && stop.sequenceNumber === 1) {
                        stop.pickupNoLaterThan = '';
                        stop.pickupNoEarlierThan = '';
                    }
                    return stop;
                });
            }
            return {
                ...state,
                isDirect: action.payload.isDirect,
                stopList: copyStopList
            };
        }

        case SHIPMENT_ORDER_ADD_STOP: {
            let copyStopList = state.stopList.slice(),
                copyItemList = state.itemList.slice(),
                sequenceNumber;
            const existedStop: Stop = action.payload.stopId ? copyStopList.find(e => e.id === action.payload.stopId) : undefined,
                newStopIndex = action.payload.isFirstStop ? 0
                    : existedStop ? existedStop.sequenceNumber - 1 :  copyStopList.length,
                returnStop = state.deliveryType === ShipmentType.Distribution &&
                    state.stopList.filter(sl => sl.typeId === StopType.Return);

            if (existedStop) {
                sequenceNumber = existedStop.sequenceNumber;
            } else {
                sequenceNumber = returnStop.length > 0 || state.deliveryType === ShipmentType.Collection && newStopIndex > 1
                ? newStopIndex : newStopIndex + 1;

                // if return stop is exist in Distribution order - update position of return stop to the last - when new stop is added
                if (returnStop.length > 0) {
                    copyStopList = StopHelper.setStopOnLastPositionByType(copyStopList, StopType.Return);
                }

                // if additional stop is added to Collection order - update position of delivery stop to the last - when new stop is added
                if (state.deliveryType === ShipmentType.Collection && copyStopList.length > 1) {
                    copyStopList = StopHelper.setStopOnLastPositionByType(copyStopList, StopType.Delivery);
                }
            }
            const type = action.payload.typeId === StopType.DeliveryWithReturn ? StopType.Delivery : action.payload.typeId;
            const stop = StopHelper.createNewStop(<Stop>action.payload.stopTemplate, type, sequenceNumber);
            if (existedStop) {
                copyItemList = StopHelper.updateStopItemsCount(copyItemList,
                    action.payload.stopId,
                    StopHelper.getIsCollectionDelivery(state, copyStopList, newStopIndex));
            }

            // replace stop with new address if it's already exist
            copyStopList.splice(newStopIndex, 1, stop);
            return {
                ...state,
                stopList: copyStopList,
                itemList: copyItemList
            };
        }

        case SHIPMENT_SAVE_DEFAULT_DATE_TIME_FOR_STOP_LIST: {
            return {
                ...state,
                stopList: action.payload
            };
        }

        case SHIPMENT_ADD_MISSED_DEFAULT_ITEM_TO_STOP: {
            const copyItemList = state.itemList.slice(),
                defaultSize = action.payload.defaultItem,
                defaultItem: OrderItemVo = {
                    piecesCount: 1,
                    sizeId: defaultSize.id,
                    weight: defaultSize.weight,
                    reference: new Reference,
                    description: '',
                    stopIds: action.payload.stopIds
                };

            copyItemList.push(defaultItem);

            return {
                ...state,
                itemList: copyItemList
            };
        }

        case SHIPMENT_ORDER_UPDATE_STOP_ADDRESS: {
            const guid = null,
                address = <AddressDto>action.payload.addr,
                copyStopList = state.stopList.slice(),
                stopIndex = copyStopList.findIndex(stopItem => stopItem.id === action.payload.stopId);
            let stop = copyStopList[stopIndex];

            // Add new address for stop, assign default stop name, save previous assigned properties
            address.id = guid;
            stop = {
                ...stop,
                addressId: guid,
                address: {...address}
            };

            copyStopList.splice(stopIndex, 1, stop);

            return {
                ...state,
                stopList: copyStopList
            };
        }

        case SHIPMENT_ORDER_REMOVE_STOP_ADDRESS: {
            const copyStopList = state.stopList.slice(),
                stopIndex = copyStopList.findIndex(stopItem => stopItem.id === action.payload);
            let stop = copyStopList[stopIndex];

            stop = {
                ...stop,
                addressId: null
            };

            copyStopList.splice(stopIndex, 1, stop);

            return {
                ...state,
                stopList: copyStopList
            };
        }

        case SHIPMENT_ORDER_REMOVE_STOP: {
            let copyItemsList;
            // remove stop from stop list and remove related to this stop item from items list
            const copyStopList = state.stopList.slice(),
                stopIndex = copyStopList.findIndex(stopItem => stopItem.id === action.payload),
                removedStop = copyStopList[stopIndex],
                isCollectionDelivery = state.deliveryType === ShipmentType.Collection && removedStop.typeId === StopType.Delivery;

            copyStopList.splice(stopIndex, 1);

            // for Collection order Delivery stop - just remove id from Item because item related to Pickup stop,
            // in other case - remove whole item - because for Distribution order item related to Delivery stop
            if (isCollectionDelivery) {
                copyItemsList = state.itemList.map((item, i) => {
                    const index = item.stopIds.indexOf(action.payload);
                    if (index !== -1) {
                        item.stopIds.splice(index, 1);
                        return item;
                    } else {
                        return;
                    }
                }).filter(item => item !== undefined);
            } else {
                copyItemsList = state.itemList.filter((item, i) => {
                    const index = item.stopIds.indexOf(action.payload);
                    return index === -1;
                });
            }
            return {
                ...state,
                stopList: copyStopList.map((item, i) => ({...item, sequenceNumber: i + 1})),
                itemList: copyItemsList
            };
        }

        case SHIPMENT_ORDER_REMOVE_FIRST_STOP: {
            return {
                ...state,
                stopList: []
            };
        }

        case SHIPMENT_ORDER_CURRENT_STOP_SELECT: {
            return {
                ...state,
                selectedStopId: action.payload
            };
        }

        case SHIPMENT_ORDER_PICKUP_STOP_SUITE_NUMBER_ADD: {
            const stopListCopy = state.stopList.slice();
            stopListCopy[0].address.suiteNumber = action.payload;
            return {
                ...state,
                stopList: stopListCopy
            };
        }

        case SHIPMENT_ORDER_STOP_UPDATE: {
            return {
                ...state,
                stopList: state.stopList.map(stop => {
                    if (stop.id === action.payload.stopId) {
                        return {...stop, ...action.payload};
                    } else {
                        return stop;
                    }
                })
            };
        }

        case SHIPMENT_ORDER_ITEMS_UPDATE: {
            const itemsListCopy = state.itemList.slice(),
                updatedItems = action.payload.items,
                targetStopId = action.payload.targetStopId,
                currentStopId = action.payload.currentStopId;

            // update items list to contain it's delivery and pickup stop Ids,
            // for return item - assign only current stop id if its not exist
            const modifiedItems = itemsListCopy.filter((item) => !item.stopIds.includes(currentStopId)) // get rid of old items
                .concat(updatedItems.map(
                    (item) => ({
                        ...item,
                        // assign stop id's only if it wasn't assigned before
                        stopIds: item.sizeId === BoxSizeTypes.UnknownReturn ?
                            item.stopIds.length > 1 ? item.stopIds : [currentStopId] :
                            item.stopIds.length < 2 ? [targetStopId, currentStopId] : item.stopIds
                    })
                ));

            return {
                ...state,
                itemList: modifiedItems
            };
        }

        case SHIPMENT_ORDER_STOP_NAME_UPDATE: {
            const stopListCopy = state.stopList.slice();
            stopListCopy.map(stop => {
                if (stop.sequenceNumber === action.payload.stopNumber) {
                    return stop.nameOrDescription = action.payload.nameOrDescription;
                } else {
                    return stop;
                }
            });
            return {
                ...state,
                stopList: stopListCopy
            };
        }

        case SHIPMENT_ORDER_SAVE_TO_STATE: {
            return {
                ...state,
                id: action.payload.id,
                deliveryType: action.payload.deliveryType,
                driverAssignmentType: action.payload.driverAssignmentType,
                orderCode: action.payload.orderCode,
                isDirect: action.payload.isDirect,
                isExtendable: action.payload.isExtendable,
                stopList: action.payload.stopList,
                itemList: action.payload.itemList,
                orderSummary: action.payload.orderSummary,
                total: action.payload.total,
                vehicleType: action.payload.vehicleType,
                statusId: action.payload.statusId,
                selectedCandidates: action.payload.selectedCandidates,
                assignments: action.payload.assignments,
                referenceId: action.payload.referenceId
            };
        }

        case NEW_CONFIRM_ORDER_CREATE_SUCCESS: {
            return {
                ...state,
                orderCode: action.payload.orderCode
            };
        }

        case SHIPMENT_DRIVER_TYPE_CREATE: {
            return {
                ...state,
                driverAssignmentType: action.payload.type,
                confirmationDriversInfo: action.payload.data
            };
        }

        case SHIPMENT_SET_EXTENDABLE: {
            return {
                ...state,
                isExtendable: action.payload
            };
        }

        default: {
            return state;
        }
    }
}
