import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tuya-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

  @Input() id;
  @Input() name;
  @Input() label;
  @Input() disabled;
  @Input() model;
  @Input() key;

  @Output() onInputToggle = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onToggle(event) {
    const key = event.target.dataset.key;
    const value = event.target.checked;
    this.onInputToggle.emit( {key, value} );
  }

}
