import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/module';
import { environment } from './environments/environment';

// enable production mode and thus disable debugging information
if (environment.production) { enableProdMode(); }

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
