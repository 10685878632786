import { AddressAlertModalComponent } from './components/address-alert-modal/address-alert-modal.component';
import { StopReturnItemsComponent } from './components/stop-details-edit/stop-return-items/stop-return-items.component';
import { PaymentOrderComponent } from './components/payment-order/payment-order.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar-portable';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShipmentPageComponent } from './containers/shipment-page/shipment.page';
import { OrderConfirmComponent } from './components/order-confirm-page/order-confirm.component';

import { ShipmentContentComponent } from './components/shipment-content/shipment-content.component';
import { ShipmentFooterComponent } from './components/shipment-footer/shipment-footer.component';
import { ShipmentHeaderComponent } from './components/shipment-header/shipment-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationLoaderService } from '../core/services/translation-loader.service';
import { UIActions } from './actions/ui.actions';

import { GeneralOfferActions } from './actions/general-offer.actions';
import { ShipmentActions } from './actions/shipment.actions';
import { ShipmentEpics } from './epics/shipment.epics';
import { RouterModule } from '@angular/router';

import { ShipmentService } from './services/shipment.service';

import { StartStepComponent } from './components/start-step/start-step.component';
import { ShipmentKindPickerComponent } from './components/shipment-kind-picker/shipment-kind-picker.component';
import { ShipmentKindPickerBtnComponent } from './components/shipment-kind-picker-btn/shipment-kind-picker-btn.component';
import { ShipmentMapPickerComponent } from './components/shipment-map-picker/shipment-map-picker.component';

import { StopDetailsEditComponent } from './components/stop-details-edit/stop-details-edit.component';
import { ShipmentBuilderComponent } from './components/shipment-builder/shipment-builder.component';
import { DeliveryStopActions } from './actions/delivery-stop.actions';
import { DeliveryStopEpics } from './epics/delivery-stop.epics';
import { GeneralOfferEpics } from './epics/general-offer.epics';
import { locale as english } from './i18n/shipment.en';
import { DeliveryItemsSelectionComponent } from './components/stop-details-edit/delivery-items-selection/delivery-items-selection.component';
import { SummaryScreenComponent } from './components/summary-screen/summary-screen.component';
import { GeneralOfferComponent } from './components/general-offer/general-offer.component';
import { PriceOptimizationModalComponent } from './components/price-optimization-modal/price-optimization-modal.component';

// import { ArchwizardModule } from 'ng2-archwizard';
import { StopContactFieldsComponent } from './components/stop-details-edit/stop-contact-fields/stop-contact-fields.component';
import { StopNameFieldComponent } from './components/stop-details-edit/stop-name-field/stop-name-field.component';
import { StopNotesFieldComponent } from './components/stop-details-edit/stop-notes-field/stop-notes-field.component';
import { StopLineComponent } from './components/shipment-builder/stop-line/stop-line.component';

import { ActionBottomBarComponent } from './components/action-bottom-bar/action-bottom-bar.component';
import { StepProgressBarComponent } from './components/step-progress-bar/step-progress-bar.component';

import { FilterItemsByStopIdPipe } from './pipes/filterItemsByStopId.pipe';
import { FilterBySizeIdPipe } from './pipes/filterBySizeId.pipe';

import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { ArriveTimepickerComponent } from './components/arrive-timepicker/arrive-timepicker.component';
import { PopupTimepickerComponent } from './components/popup-timepicker/popup-timepicker.component';

import { SelectModule } from '../core/components/pool-select/select/select.module';
import { AddressAutocompleteContainerComponent } from './components/address-autocomplete/address-autocomplete.container';

import { ShipmentBuilderPageComponent } from './containers/shipment-builder-page/shipment-builder-page.component';
import { ShipmentInitialPageComponent } from './containers/shipment-initial-page/shipment-initial-page.component';
import { ShipmentSummaryPageComponent } from './containers/shipment-summary-page/shipment-summary-page.component';
import { StopDetailsHeaderComponent } from './components/stop-details-edit/stop-details-header/stop-details-header.component';
import { DeactivateShipmentGuard } from './guards/deactivate-shipment.guard';
import { LeaveUnfinishedModalComponent } from './components/leave-unfinished-modal/leave-unfinished-modal.component';
import { StopDetailsFormComponent } from './components/stop-details-edit/stop-details-form/stop-details-form.component';
import { AddressAutocompleteEpics } from './epics/address-autocomplete.epics';
import { AddressAutocompleteActions } from './actions/address-autocomplete.actions';
import { ShipmentStopDetailsPageComponent } from './containers/shipment-stop-details-page/shipment-stop-details-page.component';
import { DirectOrderComponent } from './components/direct-order/direct-order.component';
import { OrderStopsEpics } from './epics/order-stops.epic';
import { StopDirectOrderDateComponent } from './components/stop-details-edit/stop-date-fields/stop-direct-order-date.component';
import { OrderStopsActions } from './actions/order-stops.actions';
import { StopIsDirectControlComponent } from './components/stop-details-edit/stop-date-fields/stop-is-direct-control.component';
import { StopLineHeaderComponent } from './components/shipment-builder/stop-line-header/stop-line-header.component';
import { EditConfirmPageComponent } from '../shipment-edit/containers/edit-confirm/edit-confirm.page';
import { StopDependedStopDateComponent } from './components/stop-details-edit/stop-date-fields/depended-stop-date/depended-stop-date.component';
import { StopPrimaryStopDateComponent } from './components/stop-details-edit/stop-date-fields/primary-stop-date/primary-stop-date.component';
import { StopDateCommonComponent } from './components/stop-details-edit/stop-date-fields/stop-date-common.component';
import { EditLimitationBarComponent } from './components/edit-limitation-bar/edit-limitation-bar.component';
import { DirectivesModule } from '../directives/directives.module';
import { PaymentsEpics } from './epics/payments.epics';
import { PaymentActions } from './actions/payment.actions';
import { PriceOptimizationErrorModalComponent } from './components/general-offer/price-optmization-error-modal/price-optmization-error-modal.component';
import { WizardStepperEpics } from './epics/wizard-stepper.epics';
import { ShipmentOrderEpics } from './epics/shipment-order.epics';
import { SharedModule } from '../shared/module';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

export const COMPONENTS = [ShipmentPageComponent, OrderConfirmComponent, StartStepComponent, ShipmentContentComponent,
    ShipmentFooterComponent, ShipmentHeaderComponent, StopDetailsEditComponent, StopDetailsFormComponent, StopDetailsHeaderComponent,
    StopContactFieldsComponent, DirectOrderComponent, StopIsDirectControlComponent,
    AddressAutocompleteContainerComponent, ShipmentStopDetailsPageComponent, AddressAlertModalComponent,
    ShipmentKindPickerComponent, ShipmentKindPickerBtnComponent, ShipmentMapPickerComponent, EditLimitationBarComponent,
    ShipmentKindPickerComponent, ShipmentKindPickerBtnComponent, ShipmentMapPickerComponent, StopDependedStopDateComponent,
    StopPrimaryStopDateComponent, StopDateCommonComponent,
    StopNameFieldComponent, ShipmentBuilderComponent, StopLineComponent, DeliveryItemsSelectionComponent, SummaryScreenComponent,
    GeneralOfferComponent, PriceOptimizationErrorModalComponent, StopNotesFieldComponent, StopReturnItemsComponent, PaymentOrderComponent,
    ActionBottomBarComponent, StepProgressBarComponent, FilterItemsByStopIdPipe, FilterBySizeIdPipe,
    ArriveTimepickerComponent, PopupTimepickerComponent, StopDirectOrderDateComponent,
    ShipmentBuilderPageComponent, ShipmentInitialPageComponent, ShipmentSummaryPageComponent,
    LeaveUnfinishedModalComponent, PriceOptimizationModalComponent, StopDetailsFormComponent, StopLineHeaderComponent];

export const providers = [TranslationLoaderService, ShipmentService, OrderStopsEpics,
    ShipmentActions, ShipmentEpics, ShipmentOrderEpics, PaymentsEpics,
    WizardStepperEpics, PaymentActions, UIActions, GeneralOfferActions, OrderStopsActions,
    DeliveryStopActions, DeliveryStopEpics, GeneralOfferEpics,
    AddressAutocompleteActions, AddressAutocompleteEpics,
    BsModalService, DeactivateShipmentGuard,
    [provideNgxMask()],
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG}];

@NgModule({
    imports: [RouterModule, CommonModule, CoreModule, FormsModule, ReactiveFormsModule, SharedModule,
        CollapseModule.forRoot(), BsDatepickerModule.forRoot(), AccordionModule.forRoot(),
        TooltipModule.forRoot(), NgxMaskDirective, NgxMaskPipe, TimepickerModule.forRoot(), ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        TranslateModule, DirectivesModule,
        PerfectScrollbarModule,
        // ArchwizardModule,
        SelectModule],
    declarations: COMPONENTS,
    exports: [...COMPONENTS, RouterModule],
    providers: providers
})
export class ShipmentModule {
    constructor(private translationLoader: TranslationLoaderService) {
        this.translationLoader.loadTranslations(english);
    }
}
