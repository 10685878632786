
export class EventItem {
    date: string;
    isCreatedOnMobile: boolean;
    eventTypeId: number;
    eventActionId: number;
    title: string;
    subtitle: string;
    actor: string;
    latitude: number;
    longitude: number;
    departureTypeId: number;
    receiverName: string;
    files: Array<string>;
    signatureURI: string;
    signature: string;
    stopComments: string;
    scheduledTime: string;
    items: Array<ItemSizeType>;
    stopTypeId: number;
    pickupNoEarlierThan: string;
    pickupNoLaterThan: string;
    actorRole: string;
    adminComment: string;
}

export class ItemSizeType {
    description: string;
    quantity: number;
    packageTypeId: number;
    packageType: string;
    dimensions: object;
    weight: number;
    referenceId: any;
}

export class OrderEventsState {
    eventsList: Array<EventItem>;
    errorMessageKey: string;
}

export enum EventType {
    OrderEvent = 1,
    StopEvent = 2
}

export enum OrderEventActionId {
    OfferCreated = 2,
    OfferExtended = 3, // deprecated. using more specific ReOffered and OfferConvertedGeneral
    OfferAccepted = 4,
    OfferReleased = 5,
    OfferCancelled = 6,
    OrderChangesRejected = 8,
    ReOffered = 9,
    CallToAction = 10,
    OfferConvertedGeneral = 11,
    OrderScheduled = 16,
    ScheduledOrderAccepted = 17,
    ItemException = 18
}

export const OrderEventTitle = {
    // 1: General - not used
    2: { value: 'tuya-my-orders.order-event.order-creation'},
    3: { value: 'tuya-my-orders.order-event.order-extended'},
    4: { value: 'tuya-my-orders.order-event.order-accepted'},
    5: { value: 'tuya-my-orders.order-event.order-released'},
    6: { value: 'tuya-my-orders.order-event.order-cancelled'},
    // 7: Order Changed - not used
    8: { value: 'tuya-my-orders.order-event.order-changes'},    // Order Changes Rejected
    9: { value: 'tuya-my-orders.order-event.reoffered-by-admin'},
    10: { value: 'tuya-my-orders.order-event.call-to-action'},
    11: { value: 'tuya-my-orders.order-event.offer-converted-general'},
    16: { value: 'tuya-my-orders.order-event.order-scheduled'},
    17: { value: 'tuya-my-orders.order-event.scheduled-order-accepted'}
};

// /eventLog2 eventItem.actorRole string const for admin
export const OrderEventAdmin = 'Tuya Admin';

export const OrderEventActorRole = {
    [OrderEventAdmin]: { value: 'tuya-my-orders.order-event-role.admin'},
    'Driver': { value: 'tuya-my-orders.order-event-role.driver'},
    'Shipper': { value: 'tuya-my-orders.order-event-role.shipper'}
};

// StopEventActionId is equivalent to StopEventType, so avoiding duplication

export const StopEventTitle = {
    12: { value: 'tuya-my-orders.stop-event.stop-arrived'},
    13: { value: 'tuya-my-orders.stop-event.stop-arrived-cancelled'},
    // 14 (Completed) needs to be more specific to display Pickup or Delivery (look at stopTypeId)
    15: { value: 'tuya-my-orders.stop-event.stop-arrival-attempted'},
    18: { value: 'tuya-my-orders.stop-event.stop-item-exception'}
};

export enum StopTypeId {
    Pickup = 1,
    Delivery = 2
}

export enum DepartureTypeId {
    NoOneHere = 1,
    CantAccess = 2,
    Other = 3,
    OrderCancelled = 4
}
