import { ShipperProfileDto, ShipperProfileResultDto } from '../../core/models/dto';
import { ProfileToEditVO } from '../../register/model/model';

export function transformShipperProfile(data: ShipperProfileResultDto): ShipperProfileDto {
    const shipperProfile = data.shipperProfile || data.adminProfile;
    if (data.adminProfile) shipperProfile.isAdmin = true;
    
    return shipperProfile;
}

export function transformToProfileToEdit(data: ShipperProfileDto): ProfileToEditVO {
    const profileToEdit = Object.assign({}, data);
    delete profileToEdit.businessEntity;
    
    return profileToEdit;
}
