import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { PoolsActions } from '../../core/actions/pools.actions';
import { OrderEditMode } from '../../core/models/order.dto';
import { Action } from '../../core/models/redux.action';
import { ShipmentEditSelectors } from '../../shipment-edit/selectors/shipment-edit.selectors';
import { GeneralOfferActions } from '../actions/general-offer.actions';
import { OrderStopsActions } from '../actions/order-stops.actions';
import { PaymentActions } from '../actions/payment.actions';
import { ShipmentActions } from '../actions/shipment.actions';
import { ON_WIZARD_STEP_CONTINUE, SET_WIZARD_STEP, UIActions } from '../actions/ui.actions';
import { Stop } from '../models/order.model';
import { WizardStep } from '../models/ui.model';
import { StopPickupTimeUtils } from '../utils/stop-pickup-time.utils';
import { exhaustMap } from 'rxjs/operators';

@Injectable()
export class WizardStepperEpics {
    constructor(
        private shipmentActions: ShipmentActions,
        private uiActions: UIActions,
        private orderStopsActions: OrderStopsActions,
        private poolsActions: PoolsActions,
        private generalOfferActions: GeneralOfferActions,
        private paymentActions: PaymentActions
    ) { }

    public createEpic() {
        return combineEpics(
            createSetWizardStepHooksEpic(this.shipmentActions, this.generalOfferActions, this.poolsActions, this.uiActions),
            createWizardStepOnContinueEpic(this.uiActions, this.orderStopsActions, this.shipmentActions, this.paymentActions)
        );
    }
}

export function createSetWizardStepHooksEpic(
    shipmentActions: ShipmentActions,
    generalOfferActions: GeneralOfferActions,
    poolsActions: PoolsActions,
    uiActions: UIActions) {
    return (action$, store) => action$.pipe(
        ofType(SET_WIZARD_STEP),
        exhaustMap((action: any) => {
            // on continue hooks
            const state = store.value;
            const stopList = state.shipment.order.stopList;
            const stopId = state.shipment.order.selectedStopId;
            const findStopById = function (stops, id) {
                return stops.filter(stop => stop.id === id)[0];
            };
            const hookActionList: Action<any, any>[] = (function getHookActions() {
                let orderId;
                switch (action.payload) {
                    case WizardStep.InitialStopEdit: // Initial -> Pickup
                        const editMode = ShipmentEditSelectors.selectEditMode(store.value);
                        // DO NOT CREATE DRAFT FOR EDIT!
                        if (editMode === OrderEditMode.NoEdit) {
                            return [shipmentActions.newShipmentDraftCreate()];
                        } else {
                            return [];
                        }
                    case WizardStep.EditStopDetails: // Stop Details
                        const currentStop = findStopById(stopList, stopId);
                        return [
                            uiActions.initStopEdit({ ...(new Stop()), ...currentStop })
                        ];
                    case WizardStep.SummaryAndDriverAssignment:
                        const stopLength = state.shipment && state.shipment.order && state.shipment.order.stopList &&
                            state.shipment.order.stopList.length;
                        const returnArr = [
                            poolsActions.loadPoolList()
                        ];
                        if (stopLength > 2) {
                            returnArr.push(generalOfferActions.requestStartPriceOptimization(orderId));
                        }
                        return returnArr;
                    case WizardStep.SummaryAndPaymentOrder:
                        // No valid credit card information. Not currently handling this. Proceed to next step.
                        uiActions.setWizardStep(WizardStep.Confirmation);
                        break;
                    case WizardStep.Confirmation:
                        return [uiActions.setWizardStepNoHooks()];
                    default:
                        return [uiActions.setWizardStepNoHooks()];
                }
            })();

            return of(...hookActionList,
                shipmentActions.shipmentReroute(action.payload));
        })
    );
}

export function createWizardStepOnContinueEpic(uiActions: UIActions, orderStopsActions: OrderStopsActions,
    shipmentActions: ShipmentActions, paymentActions: PaymentActions) {
    return (action$, store) => action$.pipe(
        ofType(ON_WIZARD_STEP_CONTINUE),
        exhaustMap((action: any) => {
            const resActions = [];
            switch (action.payload) {
                case WizardStep.Stops:
                    uiActions.loading(true);
                    shipmentActions.shipmentOrderUpdate();
                    return of(
                        paymentActions.hasValidCreditCard(),
                        uiActions.setWizardStep(WizardStep.SummaryAndDriverAssignment)
                    );
                case WizardStep.EditStopDetails:
                    const stopList = store.value.shipment.order.stopList,
                        orderType = store.value.shipment.order.deliveryType,
                        editMode = store.value.shipmentEdit.editMode,
                        isDirect = store.value.shipment.order.isDirect,
                        isValidateTime = editMode === OrderEditMode.NoEdit ? true :
                            ShipmentEditSelectors.selectIsValidateOrder(store.value);
                    // validate timeframes from Shipment Builder - if valid proceed to drivers selection
                    const invalidStopIds = StopPickupTimeUtils.validateTimeframesForShipmentBuilder(new Date(), stopList, orderType);
                    if (isDirect ||
                        (editMode !== OrderEditMode.NotAccepted && !isValidateTime) ||
                        !invalidStopIds.length) {
                        resActions.push(uiActions.setWizardStep(WizardStep.SummaryAndDriverAssignment));
                    } else if (action.payload === WizardStep.EditStopDetails) {
                        resActions.push(uiActions.setWizardStep(WizardStep.Stops),
                            orderStopsActions.saveInvalidTimeframesStops(invalidStopIds));
                    } else {
                        resActions.push(orderStopsActions.saveInvalidTimeframesStops(invalidStopIds));
                    }
                    break;
                case WizardStep.SummaryAndDriverAssignment:
                    /**
                     * Here we should check for a valid credit card at store.value.shipment.order.hasValidCreditCard.
                     * However we don't seem to have UI for this case. So for now proceed to step 6 whether we have a valid
                     * credit card number or not.
                     */
                    resActions.push(shipmentActions.newShipmentReadyToConfirm());
                    break;
                case WizardStep.SummaryAndPaymentOrder:
                    resActions.push(uiActions.callValidation(true));
                    break;
                default:
                    resActions.push(uiActions.setWizardStep(action.payload + 1));
            }
            return of(...resActions);
        })
    );
}
