import { CompanyValidationResultVO, ProfileToEditVO } from '../../register/model/model';
import { IProfilesState, ManageCompanyTab } from './../model/model';
import { BusinessEntityDto, ShipperProfileDto, AddressDto } from './../../core/models/dto';
import {
    CHANGE_MANAGE_COMPANY_TAB,
    EDIT_SHIPPER_OR_COMPANY_PROFILE,
    HIDE_COMPANY_ERRORS,
    HIDE_PROFILE_ERRORS,
    ProfilesAction,
    SAVE_COMPANY_PROFILE,
    SAVE_COMPANY_PROFILE_FAIL,
    SAVE_COMPANY_PROFILE_SUCCESS,
    SAVE_SHIPPER_PROFILE,
    SAVE_SHIPPER_PROFILE_FAIL,
    SAVE_SHIPPER_PROFILE_SUCCESS
} from '../actions/profiles.actions';
import {
    LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN,
    LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN_SUCCESS,
    LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN_FAIL,
    SAVE_SHIPPER_PROFILE_AS_ADMIN_SUCCESS,
    SAVE_COMPANY_PROFILE_AS_ADMIN_SUCCESS
} from '../../admin/actions/shipper.admin.actions';
import { LOGOUT_SUCCESS } from '../../auth/actions/auth.actions';
import { Action } from 'redux';
import { transformToProfileToEdit } from '../../shipper/utils/shipper-profile.utils';
import { TURN_OFF_ALL_SPINNERS } from '../../core/actions/alert.actions';

export const initialState: IProfilesState = {
    profileToEdit: null,
    currentManageCompanyTab: ManageCompanyTab.GeneralInformation,
    companyInfoToEdit: null,
    companyInfoFormValidationResult: null,
    profileInfoFormValidationResult: null,
    error: null,
    isLoading: false
};

export function profilesReducer(state = initialState, a: Action): IProfilesState {
    const action = a as ProfilesAction;
    switch (action.type) {
        case SAVE_SHIPPER_PROFILE: {
            return {
                ...state,
                isLoading: true
            };
        }
        case SAVE_SHIPPER_PROFILE_SUCCESS: {
            const profileToEdit = transformToProfileToEdit(action.payload);
            return {
                ...state,
                profileToEdit: profileToEdit,
                profileInfoFormValidationResult: {
                    ...state.companyInfoFormValidationResult,
                    isValid: true
                },
                isLoading: false
            };
        }
        case SAVE_SHIPPER_PROFILE_AS_ADMIN_SUCCESS: { // triggered by a different epic
            return {
                ...state,
                profileToEdit: action.payload,
                profileInfoFormValidationResult: {
                    ...state.companyInfoFormValidationResult,
                    isValid: true
                },
                isLoading: false
            };
        }
        case SAVE_SHIPPER_PROFILE_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        }

        case EDIT_SHIPPER_OR_COMPANY_PROFILE: {
            const shipperProfile = action.payload;
            const profileToEdit = transformToProfileToEdit(shipperProfile);
            const companyProfile = shipperProfile.businessEntity;
            return {
                ...state,
                isLoading: false,
                profileToEdit: profileToEdit,
                companyInfoToEdit: companyProfile
            };
        }

        case LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN: {
            const emptyProfileToEdit = {
                isEmailConfirmed: false
            } as ProfileToEditVO;
            const blankCompanyProfile = new BusinessEntityDto;
            blankCompanyProfile.physicalAddress = new AddressDto;
            blankCompanyProfile.billingAddress = new AddressDto;
            return {
                ...state,
                isLoading: true,
                currentManageCompanyTab: 1,
                profileToEdit: emptyProfileToEdit,
                companyInfoToEdit: blankCompanyProfile
            };
        }
        case LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN_SUCCESS: {
            const shipperProfile = action.payload;
            const companyProfile = shipperProfile.businessEntity;
            const blankCompanyProfile = new BusinessEntityDto;
            return {
                ...state,
                isLoading: false,
                profileToEdit: {
                    ...shipperProfile,
                    businessEntity: blankCompanyProfile
                },
                companyInfoToEdit: companyProfile
            };
        }
        case LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        }

        case CHANGE_MANAGE_COMPANY_TAB: {
            return {
                ...state,
                currentManageCompanyTab: action.payload
            };
        }

        case SAVE_COMPANY_PROFILE: {
            return {
                ...state,
                isLoading: true
            };
        }
        case SAVE_COMPANY_PROFILE_SUCCESS: {
            return {
                ...state,
                companyInfoToEdit: action.payload.businessEntity,
                companyInfoFormValidationResult: {
                    ...state.companyInfoFormValidationResult,
                    isValid: true
                },
                isLoading: false
            };
        }
        case SAVE_COMPANY_PROFILE_AS_ADMIN_SUCCESS: { // triggered by a different epic
            return {
                ...state,
                companyInfoToEdit: action.payload,
                companyInfoFormValidationResult: {
                    ...state.companyInfoFormValidationResult,
                    isValid: true
                },
                isLoading: false
            };
        }
        case SAVE_COMPANY_PROFILE_FAIL: {
            return {
                ...state,
                companyInfoFormValidationResult: <CompanyValidationResultVO> action.payload,
                isLoading: false
            };
        }

        case HIDE_COMPANY_ERRORS: {
            return {
                ...state,
                companyInfoFormValidationResult: action.payload
            };
        }
        case HIDE_PROFILE_ERRORS: {
            return {
                ...state,
                profileInfoFormValidationResult: action.payload
            };
        }

        case LOGOUT_SUCCESS: {
            return {
                ...initialState
            };
        }

        case TURN_OFF_ALL_SPINNERS: {
            return {
                ...state,
                isLoading: false
            };
        }

        default: {
            return state;
        }
    }
}
