import { IAppState } from '../../store/model';
import { OrderVo } from '../../shipment/models/order.model';
import { OrderEditMode } from '../../core/models/order.dto';
import { OrderPriceChange } from '../models/model';
import { isOrderChanged, isOrderPriceChanged, isValidationFieldChanged } from '../utils/order-change.utils';


export class ShipmentEditSelectors {

    public static IsLoadingSelector = (state: IAppState): boolean => state.shipmentEdit.isEditOrderLoading;
    public static selectInitialEditOrder = (state: IAppState): OrderVo => state.shipmentEdit.initialOrder;
    public static selectEditMode = (state: IAppState): OrderEditMode => state.shipmentEdit.editMode;
    public static selectIsOrderEdit = (state: IAppState): boolean => state.shipmentEdit.editMode !== OrderEditMode.NoEdit;
    public static selectIsOrderChanged = (state: IAppState): boolean => {
        return isOrderPriceChanged(state.shipmentEdit.initialOrder, state.shipment.order)
            || isOrderChanged(state.shipmentEdit.initialOrder, state.shipment.order);
    }

    public static selectIsValidateOrder = (state: IAppState): boolean => {
        return isValidationFieldChanged(state.shipmentEdit.initialOrder, state.shipment.order);
    }

    public static selectOrderPriceChange = (state: IAppState): OrderPriceChange => {
        return {
            newPrice: state.shipment.order.total,
            oldPrice: state.shipmentEdit.initialOrder ? state.shipmentEdit.initialOrder.total : 0
        };
    }
}
