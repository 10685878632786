import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { BaseService } from '../../core/services/base.service';
import { ConfigService } from '../../app.config.service';
import { OrdersRespDto, SearchPaginationParamsDto } from '../../core/models/dto';
import { OrderDto } from '../models/order.dto';

export const API_ORDER = '/api/v1/Order/';
export const API_ORDER_V2 = '/api/v2/Order';
export const API_ORDER_CANCEL = '/Cancel';
export const API_ORDER_EDIT = '/Edit';
export const API_DISCARD_CHNAGES = '/DiscardChanges';
export const API_CANCELLATION_REASON = '?cancelationReason=';

// @TODO JAY: Add ADMIN God View Endpoints here (open stops + driver locations)

@Injectable()
export class OrdersService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    // OrdersForShipperViewQuery
    getOrders(searchPaginationParams: SearchPaginationParamsDto): Observable<OrdersRespDto> {
        return this.api().GET(API_ORDER_V2, searchPaginationParams).pipe(map((data: any) => data));
    }

    getOrderById(orderId: string): Observable<OrderDto> {
        return this.api().GET(API_ORDER + orderId).pipe(map((data: any) => data));
    }

    // @TODO change to real implemenntation later (JAY Look here)
    editOrder(orderId: string, isDuplicateMode: boolean = false): Observable<OrderDto> {
        const params = Object.create({});
        params.isDuplicateMode = isDuplicateMode;
        return this.api().GET(API_ORDER + orderId + API_ORDER_EDIT, params).pipe(map((data: any) => data));
    }

    cancelOrder(payload: any) {
        const orderId = payload.orderId;
        const comments = payload.comments;
        let params = API_ORDER + orderId + API_ORDER_CANCEL;
        if (comments.length > 0) {
            params += API_CANCELLATION_REASON + encodeURI(comments);
        }
        return this.api().PUT(params).pipe(map((data: any) => data));
    }

    discardChanges(orderId: string) {
        return this.api().PUT(API_ORDER + orderId + API_DISCARD_CHNAGES).pipe(map((data: any) => data));
    }

    searchForAnOrder(searchQuery: string, searchParams: SearchPaginationParamsDto): Observable<OrdersRespDto> {
        const params = searchParams;
        params['searchQuery'] = searchQuery;
        return this.api().GET(API_ORDER_V2, params).pipe(map((data: any) => data));
    }

    generateSearchQuery(store) {
        const myStore = store.value;
        const pagination = myStore.home.myOrders.pagination;
        const searchOrders = myStore.home.searchOrders;
        const statusFilter = pagination.filter;
        const statusFilterString = [];

        for (const key in statusFilter) {
            if (statusFilter[key]) {
                statusFilterString.push(key);
            }
        }
        const sFilter = searchOrders.searchFilter;
        const filters = searchOrders.searchQuery !== '' ? sFilter : pagination.filter;
        const statuses = this.arrayToQueryString(filters, pagination.statuses, 'orderStatuses');

        return {
            pageSize: pagination.pageSize,
            currentPageNumber: pagination.currentPageNumber,
            sortField: pagination.sortField,
            sortDirection: pagination.sortDirection,
            shipperUserId: myStore.auth.currentUser.shipperProfile.userAccountId,
            orderStatuses: statuses,
            submittedDateFrom: sFilter.startDate !== '' ? sFilter.startDate : '',
            submittedDateTo: sFilter.endDate !== '' ? sFilter.endDate : ''
        };
    }

    arrayToQueryString(filter: any, statuses: any, paramName: string) {
        let i = 0;
        const arr = [];
        for (const key of Object.keys(filter)) {
          if (filter[key] && typeof(filter[key]) === 'boolean') {
            for (let s = 0; s < statuses[i].length; s++) {
                arr.push(statuses[i][s]);
            }
          }
          i++;
        }
        return arr;
    }
}
