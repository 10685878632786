<div class="mat-select-search-inner">
  
  <mat-icon matSuffix>search</mat-icon>
  
  <input matInput
          #searchSelectInput
          class="mat-select-search-input"
          placeholder="{{inputPlaceholder | translate}}"
          [formControl]="searchFormControl"
          (keydown)="$event.stopPropagation()"
          autocomplete="off"
          tuyaAutofocusDirective
      />

</div>