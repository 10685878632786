<div class="builder-time">
  <div [formGroup]="timeForm">

    <div class="builder-time__default"
      *ngIf="stopIndex === 0 && !materialLocked || timeSelections[0] === TimeRangeOptions.EasyWindow">
      <input *ngIf="stopIndex === 0 && !materialLocked" type="radio" id="{{ idKey }}-builder-time-default"
        name="{{ groupName }}" [checked]="timeSelections[stopIndex] === TimeRangeOptions.EasyWindow"
        class="builder-radio-button" (change)="onTimeRangeRadioChange(TimeRangeOptions.EasyWindow)">
      <label class="builder-form-label" for="{{ idKey }}-builder-time-default">
        {{ 'builderTime.defaultWindowLabel' | translate }}
      </label>
      <p class="builder-time__description">
        {{ 'builderTime.defaultWindowDescription' | translate: { start: easyStartTimeDisplay, end: easyEndTimeDisplay }
        }}
      </p>
    </div>

    <div class="builder-time__custom"
      *ngIf="stopIndex === 0 && !materialLocked || timeSelections[0] === TimeRangeOptions.CustomWindow">
      <input *ngIf="stopIndex === 0 && !materialLocked" type="radio" id="{{ idKey }}-builder-time-custom"
        name="{{ groupName }}" class="builder-radio-button"
        [checked]="timeSelections[stopIndex] === TimeRangeOptions.CustomWindow"
        (change)="onTimeRangeRadioChange(TimeRangeOptions.CustomWindow)">
      <label for="{{ idKey }}-builder-time-custom">
        {{ 'builderTime.customWindow' | translate: { stopType: stopTypeText } }}
      </label>

      <fieldset class="builder-time__customFieldset" *ngIf="timeSelections[0] === TimeRangeOptions.CustomWindow">
        <div class="builder-time__fields">
          <div class="builder-time__date">
            <tuya-date-picker [stopType]="stopType" [timeForm]="timeForm" [currentStep]="currentStep"
              [disabled]="materialLocked" [isScheduledOrder]="isScheduledOrder" (onDateChange)="onDateChange($event)"
              [pickupDate]="pickupDate">
            </tuya-date-picker>
          </div>
          <div class="builder-time--from-to-container">
            <div class="builder-time__from">
              <tuya-time-dropdown [label]="'From'" [idKey]="idKey + '-window-start'" [key]="'pickupNoEarlierThan'"
                [time]="stop.pickupNoEarlierThan" [timeForm]="timeForm" [formControlNameField]="'pickupNoEarlierThan'"
                (onTimeSelected)="onTimeChange($event)" [disabled]="materialLocked">
              </tuya-time-dropdown>
            </div>
            <div class="builder-time__to">
              <tuya-time-dropdown [label]="'To'" [idKey]="idKey + '-window-end'" [key]="'pickupNoLaterThan'"
                [time]="stop.pickupNoLaterThan" [timeForm]="timeForm" [formControlNameField]="'pickupNoLaterThan'"
                (onTimeSelected)="onTimeChange($event)" [disabled]="materialLocked">
              </tuya-time-dropdown>
            </div>
            <div *ngIf="!timeForm?.valid" class="builder-time__errors">
              <div *ngIf="pickupNoEarlierThan.touched && 
                          !!pickupNoEarlierThan.errors?.invalidTime" class="builder-time-error-message">
                {{ 'validation.fromTime' | translate: { stopType: stopTypeText } }}
              </div>
              <div *ngIf="pickupNoLaterThan.touched && 
                          !!pickupNoLaterThan.errors?.invalidTime" class="builder-time-error-message">
                {{ 'validation.toTime' | translate: { stopType: stopTypeText } }}
              </div>
              <div *ngIf="pickupNoEarlierThan.touched && 
                          pickupNoEarlierThan.valid && 
                          !!timeForm.errors?.startTimeBeforeCurrentTime" class="builder-time-error-message">
                {{ 'validation.startTime' | translate: { stopType: stopTypeTextCapitalized } }}
              </div>
              <div *ngIf="pickupNoEarlierThan.valid && 
                          pickupNoLaterThan.valid && 
                          !!timeForm.errors?.minTimeWindow" class="builder-time-error-message">
                {{ 'validation.minTimeWindow' | translate }}
              </div>
              <div *ngIf="pickupNoEarlierThan.valid && 
                        pickupNoLaterThan.valid && 
                        !!timeForm.errors?.endBeforeStart" class="builder-time-error-message">
                {{ 'validation.endBeforeStart' | translate }}
              </div>
            </div>
          </div>
        </div>
      </fieldset>

    </div>
  </div>
</div>