import { Component, Input, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'tuya-snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.scss'],
  })
export class SnackBarComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public message: string) {}

}
