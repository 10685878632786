import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, exhaustMap, map, Observable } from 'rxjs';
import { ConfigService } from '../../app.config.service';
import { DriverScore, ShipperAssessment } from '../model/driver-info.model';
import { OrdersService } from '../../core/services/orders.service';
import { GET_INFRASTRUCTURE, API_RATE_DRIVER, RECURRING_ORDER, GET_RECURRING_DETAIL } from '../../core/constants/api.constants';
import { RecurringDetail } from '../containers/recurring-modal/recurring-modal.component';

@Injectable()
export class MyOrdersService extends OrdersService {
    private getLowRateReasonsSubject = new BehaviorSubject<void>(null);
    private getLowRateReasons$: Observable<Object>;
    
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
        this.getLowRateReasons$ = this.initializeGetLowRateReasons();
    }

    updateDriverRate(params: ShipperAssessment): Observable<DriverScore> {
        return this.api().PUT(API_RATE_DRIVER, params).pipe(map((data: any) => data));
    }

    private initializeGetLowRateReasons(): Observable<Object> {
        return this.getLowRateReasonsSubject.pipe(
            exhaustMap(() => this.api().GET(GET_INFRASTRUCTURE).pipe(
                map((data: any) => data.orderRateReasons)
            ))
        );
    }

    getLowRateReasons(): Observable<Object> {
        this.getLowRateReasonsSubject.next();
        return this.getLowRateReasons$;
    }

    /*getLowRateReasons(): Observable<Object> {
        return this.api().GET(GET_INFRASTRUCTURE).pipe(map((data: any) => {
            return data.orderRateReasons;
        }));
    }*/

    createRecurringOrder(data: RecurringDetail, isEditMode: boolean): Observable<Object> {
        if (isEditMode) {
            return this.api().PUT(RECURRING_ORDER, data).pipe(map((response: any) => response));
        }
        return this.api().POST(RECURRING_ORDER, data).pipe(map((response: any) => response));
    }

    getRecurringDetail(recurringDetailId: number): Observable<RecurringDetail> {
        return this.api().GET(GET_RECURRING_DETAIL + `/${recurringDetailId}`).pipe(map((response: any) => response));
    }

    deleteRecurringOrder(recurringDetailId): Observable<Object> {
        return this.api().DELETE(RECURRING_ORDER + `/${recurringDetailId}`).pipe(map((response: any) => response));
    }

    getDefaultData(orderId = 0) {
        return {
            orderId: orderId,
            code: '',
            directOrder: false,
            isCreatedOnMobile: false,
            isScheduledOrder: false,
            statusId:  10,
            creationDate: '',
            endDate: 0,
            referenceId: 0,
            total: 0,
            acceptanceDateTime: 0,
            isSimplified: false,
            driverArrivedAtFirstStop: false,
            exceptionsCount: 0,
            driverResponceStatistics: {},
            assignments: {},
            orderSummary: {},
            items: [],
            recurrenceOrderDetail: null,
            stopList: Array(2).fill(
                {
                    address: {
                        id: 0,
                        name: '',
                        addressLine: '',
                        city: '',
                        stateAbbr: '',
                        stateId: 0,
                        stateName: '',
                        country: '',
                        countryShortName: '',
                        latitude: 0,
                        longitude: 0,
                        postalCode: '',
                        suiteNumber: null
                    },
                    stopId: 0,
                    contactEmail: null,
                    contactName: '',
                    contactPhoneNumber: null,
                    hasTrackingLink: false,
                    inMsa: false,
                    isSignitureRequired: false,
                    isTextTrackingLink: false,
                    itemsDescription: null,
                    name: '',
                    pickupNoEarlerThan: '',
                    pickupNoLaterThan: '',
                    sequenceNumber: 1,
                    statusId: 10,
                    status: 10,
                    typeId: 1,
                    type: 1,
                    completedTimeStamp: null,
                    referenceId: null,
                    pickupItemsCompleted: false
                }
            )
        };
    }

}
