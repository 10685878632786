export const locale = {
    'lang': 'en',
    'data': {
        'tuya-register': {
            'validation': {
                'maxLength': 'Password is too long. Try one with maximum 32 characters',
                'minLength': 'Short passwords are easy to guess. Try one with at least 6 characters',
                'blackList': 'This password is common and easy to guess. Try again',
                'combination': 'Password is too weak. Your password must include at least one upper case letter,' +
                    ' one lower case letter, one special character, and one number',
                'uniqueEmail': 'E-mail already exists in our system',
                'phone-length': 'Phone Number must contain 10 digits.',
                'company-name': 'An account with that company name already exists. Please contact your administrator ' +
                'to register with this company.',
                'valid-password': 'Valid password is required',
                'same-as-current': 'New password can\'t be the same as your current password',
                'expired-token': 'It has been too long since you requested the password change. Please refresh and try again'
            },
        }
    }
};

