import { AddressDto, IndustrySegment, StateDto } from '../../core/models/dto';

export interface IRegisterState {
    physicalAddresses: IPhysicalAddressesState;
    industrySegments: IndustrySegment[];
    states: StateDto[];
    forms: IFormRegisterState;
}

export interface IFormRegisterState {
    formStep: RegisterFormStep;
    myAccountForm: any;
    myAccountFormValidationResult: AccountValidationResultVO;
    companyInfoForm: any;
    companyInfoFormValidationResult: CompanyValidationResultVO;
    isLoading: boolean;
}

export interface IPhysicalAddressesState {
    physicalAddresses: AddressDto[];
    isAddressLoading: boolean;
}

export interface AccountValidationResultVO {
    isValid: boolean;
    oldPasswordErrorMsg: string[];
    passwordErrorMsg: string[];
    emailErrorMsg: string[];
}

export interface ProfileToEditVO {
    userAccountId: string;
    firstName: string;
    lastName: string;
    nickname: string;
    phoneNumber: string;
    isPhoneNumberConfirmed: boolean;
    email: string;
    isEmailConfirmed: boolean;
    hasOrders: boolean;
    hasValidCreditCard: boolean;
    referralCode: string;
    rating: number;
    sendUpdates: boolean;
    suspended: boolean;
    isUnderTerms: boolean;
}

export interface CompanyValidationResultVO {
    isValid: boolean;
    nameErrorMsg: string[];
}
export interface ProfileValidationResultVO {
    isValid: boolean;
    nameErrorMsg: string[];
}

// TODO: discuss w/ back about DTO
export class FullAddressVO {
    address: string;
    city: string;
    suiteNumber: string;
    state: string;
    zipcode: string;
}

export enum RegisterFormStep {
    Init,
    AccountValidated,
    CompanyValidated,
    Registered,
}
