<div class="confirm-wrapper">
    <div class="title">
        <span *ngIf="data.type === poolConfirmType.create">Confirmation - New Driver Group</span>
        <span *ngIf="data.type === poolConfirmType.add ||
                     data.type === poolConfirmType.addAndRemove">Confirmation</span>
        <span *ngIf="data.type === poolConfirmType.remove">Removal Confirmation</span>
    </div>

    <div class="content">
        <label class="header">
            <span *ngIf="data.type === poolConfirmType.create">
                You’ve created the new <span class="boldName">Driver Group: </span> <span class="name">{{ data?.pools[0] }}.</span>
            </span>
            <span *ngIf="data.type === poolConfirmType.add ||
                         data.type === poolConfirmType.addAndRemove">
                You added driver <span class="boldName">{{ data?.driver }}</span> to the following <span class="boldName">Driver Groups: </span>
            </span>
            <span *ngIf="data.type === poolConfirmType.remove">
                You have removed driver <span class="boldName">{{ data?.driver }}</span> from the following <span class="boldName">Driver Groups: </span> 
                <span class="name" *ngFor="let p of data?.pools; let indx=index">{{ p.poolName }} <span *ngIf="indx !== data?.pools.length-1">, </span></span>
            </span>
        </label>
        <ul *ngIf="data.type === poolConfirmType.create">
            <li>
                Please note that the driver <span class="boldName">{{ data?.driver }}</span> was automatically
                added to the <span class="name">{{ data?.pools[0] }} </span> Driver Group.
            </li>
            <li>
                To remove the driver from this <span class="boldName">Driver Group</span>, please go to the 
                <span class="boldName">"Add driver to a Favorite Driver Group"</span> dropdown and uncheck 
                the <span class="name">{{ data?.pools[0] }} </span> Driver Group. 
            </li>
        </ul>

        <section *ngIf="data.type === poolConfirmType.add ||
                        data.type === poolConfirmType.addAndRemove" class="section-container">
            <p *ngFor="let p of data?.pools; let indx=index" class="addedPoolsList">
                <mat-checkbox [(ngModel)]="p.newStatus" [disabled]="true">
                    {{ p.poolName }}
                </mat-checkbox>
            </p>
        </section>
    </div>

    <div class="seperator" *ngIf="data.type === poolConfirmType.addAndRemove">
        <div class="sp"></div>
    </div>

    <div *ngIf="data.type === poolConfirmType.addAndRemove">
        <div class="title">
            <span>Removal Confirmation</span>
        </div>
        <div class="content">
            <label class="header">
                You have removed driver <span class="boldName">{{ data?.driver }}</span> from the following <span class="boldName">Driver Groups: </span> 
                <span class="name" *ngFor="let p of data?.removedPools; let indx=index">{{ p.poolName }} <span *ngIf="indx !== data?.removedPools.length-1">, </span></span>
            </label>
        </div>
    </div>

    <div class="buttons">
        <button mat-button class="accept" (click)="onAccept()">OK</button>
    </div>
</div>
