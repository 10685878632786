import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../models/redux.action';
import { Alert } from '../models/alert.model';

export type AlertAction = Action<Alert, any>;

export const SHOW_ALERT = '[ALERT] Show';
export const REMOVE_ALERT = '[ALERT] Remove';
export const REJECT_INCOMING_ALERTS = '[ALERT] Reject incoming alerts';
export const ALLOW_INCOMING_ALERTS = '[ALERT] Allow incoming alerts';
export const TURN_OFF_ALL_SPINNERS = '[ALERT] Turn off all spinners';

@Injectable()
export class AlertActions {
    @dispatch()
    show = (alert: Alert): AlertAction => ({
        type: SHOW_ALERT,
        payload: alert,
    })

    @dispatch()
    hide = (): AlertAction => ({
        type: REMOVE_ALERT,
        payload: null,
    })

    @dispatch()
    rejectIncomingAlerts = (): AlertAction => ({
        type: REJECT_INCOMING_ALERTS,
        payload: null,
    })

    @dispatch()
    allowIncomingAlerts = (): AlertAction => ({
        type: ALLOW_INCOMING_ALERTS,
        payload: null,
    })

    @dispatch()
    turnOffAllSpinners = (): AlertAction => ({
        type: TURN_OFF_ALL_SPINNERS,
        payload: null,
    })
}
