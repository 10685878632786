<tuya-terms-of-service
    (tosTermsClick)="openModal(termsOfService)"
    (tosPrivacyClick)="openModal(privacyPolicy)"></tuya-terms-of-service>
<ng-template #termsOfService let-termsOfService="termsOfService">
    <div class="modal-header">
        <button type="button" class="close-btn" (click)="closeModal()"><i class="fa fa-close fa-lg"></i></button>
        <span class="title" [translate]="'tuya-tos-policy.tos'"></span>
    </div>
    <div class="modal-body" [innerHTML]="termsOfServiceText">
    </div>
    <div class="modal-footer">
        <span class="date">
            {{'tuya-tos-policy.effective-date' | translate}}
            {{termsOfServiceEffectiveDate | date: 'M/dd/yyyy'}}
        </span>
    </div>
</ng-template>

<ng-template #privacyPolicy>
    <div class="modal-header">
        <button type="button" class="close-btn" (click)="closeModal()"><i class="fa fa-close fa-lg"></i></button>
        <span class="title" [translate]="'tuya-tos-policy.policy'"></span>
    </div>
    <div class="modal-body" [innerHTML]="privacyPolicyText">
    </div>
    <div class="modal-footer">
        <span class="date">
            {{'tuya-tos-policy.effective-date' | translate}}
            {{privacyPolicyEffectiveDate | date: 'M/dd/yyyy'}}
        </span>
    </div>
</ng-template>
