import { Observable } from 'rxjs';
import { UserProfile } from '../../auth/model/model';
import { AddressDto, BoxSizeDto, PriceOptimizationDto, StateDto, StopDto } from '../../core/models/dto';
import { DriverAssignmentTypeId, OrderVo, Stop, StopRequiredFields, StopType } from '../models/order.model';
import { AutocompleteItem } from '../../core/models/autocomplete-item.model';
import { transformStop2AutocompleteItem } from '../transforms/shipment.transforms';
import { ShipmentType } from '../../core/models/shipment.model';
import { IAppState } from '../../store/model';
import { LatLng } from '../../core/models/map.model';
import { CreditCardDto } from '../../core/models/payment.dto';
import { map } from 'rxjs/operators';

function getCurrentStopFromOrder(order: OrderVo): Stop {
    return order.stopList.filter(stop => {
        return stop.id === order.selectedStopId;
    })[0];
}

function getMainStopFromOrder(order: OrderVo): Stop {
    if (order.stopList.length < 1) { return  undefined; }

    const firstPickup = order.stopList.find(stop => stop.typeId === StopType.Pickup);

    // ToDo: update during Collection Orders dates validation implementations
    return (order.deliveryType === ShipmentType.Distribution)
        ?  firstPickup // Should be always a pickup stop.
        : (order.stopList.length === 2) ? firstPickup // Should be first pickup for delivery entering
            : order.stopList.find(stop => stop.typeId === StopType.Delivery);
}

function getLaterDeliveryStop(order: OrderVo): Stop {
    const deliveryStops = order.stopList.filter((stop: Stop) => stop.typeId === StopType.Delivery),
            maxDeliveryStopTime = deliveryStops.reduce((maxTimeStop, stop) =>
                stop.pickupNoLaterThan > maxTimeStop.pickupNoLaterThan ? stop : maxTimeStop, deliveryStops[0]);
    return maxDeliveryStopTime;
}

export function getContinueNotAllowed(order$: Observable<OrderVo>): Observable<boolean> {
    return order$.pipe(map(order => (order.deliveryType < 1
                            || order.stopList.length < 1
                            || order.stopList[0].address === null )));
}

export function isStopsRequiredFieldsProvided(order$: Observable<OrderVo>): Observable<boolean> {
    return order$.pipe(map(order => order.stopList.every(stop => {
        let isAllRequiredFieldsFilled = true;
        // validate date field
        if (!stop.pickupNoEarlierThan && !order.isDirect) {
           return false;
        }
        // validete required fields
        for (const requiredField in StopRequiredFields) {
            if (isNaN(Number(requiredField))) {
                if (!stop[requiredField]) {
                    isAllRequiredFieldsFilled = false;
                }
            }
        }
        return isAllRequiredFieldsFilled;
    })));
}

export function hasInvalidAddresses(stopList: Observable<Array<StopDto>>): Observable<boolean> {
    return stopList.pipe(map(stops => !!stops.find(stop => { // update function when fully switched to vnext
        if (typeof stop.addressId === 'string') {
            return !stop.addressId.length;
        }
        return stop.addressId > 0;
    })));
}

// ToDO: remove after addressLine with zipcode will be fixed
export function getAddress(cu$: Observable<UserProfile>): Observable<AddressDto> {
    return cu$.pipe(
      map(cu => {
        if (!cu) {
          return {} as AddressDto;
        }
        return cu.shipperProfile?.businessEntity?.physicalAddress ?? {} as AddressDto;
      })
    );
  }

export function getCurrentStop(order$: Observable<OrderVo>): Observable<Stop> {
    return order$.pipe(map(getCurrentStopFromOrder));
}

export function getFirstStopFromList(order$: Observable<OrderVo>): Observable<Stop> {
    return order$.pipe(map(order => {
        if (order.stopList.length < 1) { return  undefined; }
        return order.stopList.reduce( (l, r) => (l.sequenceNumber < r.sequenceNumber) ? l : r);
    }));
}

export function getMainStop(order$: Observable<OrderVo>): Observable<Stop> {
    return order$.pipe(map(getMainStopFromOrder));
}

export function getRelatedStop(state: IAppState): Stop|undefined {
    const order = state.shipment.order,
        currentStop = getCurrentStopFromOrder(order),
        relatedStop = currentStop ?
            (currentStop.typeId === StopType.Return ? getLaterDeliveryStop(order) : getMainStopFromOrder(order))
            : undefined;

    return (currentStop && relatedStop && currentStop.id !== relatedStop.id)
        ? relatedStop
        : undefined;
}


export function getDriverAssignmentType(order$: Observable<OrderVo>): Observable<number> {
    return order$.pipe(map(item => item.driverAssignmentType));
}

export function getAddressAutocompleteItems(stops: Stop[], states: StateDto[]): AutocompleteItem[] {
    return stops.map((stop, index) => {
        return transformStop2AutocompleteItem(stop, index, states);
    }).sort((a, b) => (a.type - b.type)); // sort by type
}

export class ShipmentSelectors {
    public static mapCenterSelector = (state: IAppState): LatLng => state.core.map.mapCenter;
    public static mapCenterDefault = (state: IAppState): LatLng => {
        let physicalAddress;
        if (state.auth.currentUser && state.auth.currentUser.shipperProfile) {
            physicalAddress = state.auth.currentUser.shipperProfile.businessEntity.physicalAddress;
        } else {
            physicalAddress = { latitude: 29.76328, longitude: -95.36327};
        }
        return {'lat': parseFloat(physicalAddress.latitude), 'lng': parseFloat(physicalAddress.longitude)};
    }
    public static itemsSizesSelector = (state: IAppState): BoxSizeDto[] => state.core.itemsSizes;
    public static isStopDetailsEditModeSelector = (state: IAppState): boolean => state.shipment.ui.isStopDetailsEditMode;
    public static wizardStepSelector = (state: IAppState): number => state.shipment.ui.wizardStep;
    public static defaultItemSizeSelector = (state: IAppState): number => state.shipment.ui.defaultItemSize;
    public static driverAssignmentTypeSelector = (state: IAppState): DriverAssignmentTypeId => state.shipment.order.driverAssignmentType;
    public static creditCardSelector = (state: IAppState): CreditCardDto => state.shipment.order.creditCard;
    public static disabledRefreshBtnSelector = (state: IAppState): boolean => state.shipment.ui.disabled;
    public static continueAllowedMapSelector = (state: IAppState): {[key: number]: boolean} => state.shipment.ui.continueAllowedMap;
    public static priceSelector = (state: IAppState): PriceOptimizationDto => state.shipment.generalOffer.priceOptimizationData;
    public static milesSelector = (state: IAppState): number => state.shipment.generalOffer.priceOptimizationData.optimizedDistance;
}


