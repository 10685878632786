import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ConfigService } from '../../app.config.service';
import { CreditCardDto } from '../models/payment.dto';
import { API_PREFIX } from '../constants/api.constants';
import { StripeCreatePaymentMethodResponse, StripeVerifyPaymentMethodResponse } from '../models/stripe.model';


export interface T3PROAuth {
    sessionId: string;
    customerId: string;
    contactId: string;
}

const API_TPRO3_AUTH = `${API_PREFIX}/AcctGateway/authorize`;
const API_PAYMENT_ADD_CREDIT_CARD = `${API_PREFIX}/Payment/CreditCards`;
const API_HAS_VALID_CREDIT_CARD = `${API_PREFIX}/Payment/HasValidCreditCard`;
const API_CREATE_STRIPE_PAYMENT_METHOD = `${API_PREFIX}/Payment/CreateStripePaymentMethod`;
const API_VERIFY_STRIPE_PAYMENT_METHOD = `${API_PREFIX}/Payment/VerifyStripePaymentMethod`;

@Injectable()
export class PaymentService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    getAuth(): Observable<T3PROAuth> {
        return this.api().POST(API_TPRO3_AUTH);
    }

    addCreditCard(creditCard: CreditCardDto): Observable<any> {
        return this.api().POST(API_PAYMENT_ADD_CREDIT_CARD, creditCard);
    }

    // We have to check if the user has at least one valid credit card;
    hasValidCreditCard(): Observable<boolean> {
        return this.api().GET(API_HAS_VALID_CREDIT_CARD).pipe(map((data: any) => data.success));
    }

    createStripePaymentMethod(): Observable<StripeCreatePaymentMethodResponse> {
        return this.api().POST(API_CREATE_STRIPE_PAYMENT_METHOD);
    }

    verifyStripePaymentMethod(customerId:string, setupIntentId: string): Observable<StripeVerifyPaymentMethodResponse> {

        const payload = {
            customerId: customerId,
            setupIntentId: setupIntentId
        };

        return this.api().POST(API_VERIFY_STRIPE_PAYMENT_METHOD, payload).pipe(map((data: any) => data.success));
    }
}
