import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { ShipmentType } from '../../../core/models/shipment.model';
import { Stop, StopType } from '../../models/order.model';
import { AddressDto } from '../../../core/models/dto';

@Component({
  selector: 'tuya-start-step',
  templateUrl: './start-step.component.html',
  styleUrls: ['./start-step.component.scss']
})
export class StartStepComponent implements AfterViewInit {

  StopType: typeof StopType = StopType;
  @Input() shipmentType: ShipmentType | null = null;
  @Input() startAddressRawString: string;
  @Output() updateShipmentType = new EventEmitter<ShipmentType>();
  @Output() updateSuiteNumber = new EventEmitter<Stop>();

  @Input() set firstStop(stop: Stop) {
      if (stop && stop.address) {
          this.stop.address.suiteNumber = stop.address.suiteNumber;
      }
  }

  stop = new Stop();

  constructor() {
      this.stop.address = new AddressDto();
      this.stop.address.suiteNumber = '';
  }
  ngAfterViewInit() {}

  // passing through
  onShipmentTypeSelected(t: ShipmentType) {
      this.shipmentType = t;
      this.updateShipmentType.emit(this.shipmentType);
  }

  onUpdateSuiteNumber() {
    this.updateSuiteNumber.emit(this.stop);
  }
}
