import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../models/redux.action';

import { DriverDto, PoolDto } from '../models/dto';
import { DriverPools } from '../../home/model/my-orders.model';

export const LOAD_POOLS = '[POOLS] Load pools create request';
export const LOAD_POOLS_SUCCESS = '[POOLS] Load pools was succeeded';
export const LOAD_POOLS_FAILURE = '[POOLS] Load pools was failed';

export const LOAD_DRIVERS = '[POOLS] Load drivers create request';
export const LOAD_DRIVERS_SUCCESS = '[POOLS] Load drivers was succeeded';
export const LOAD_DRIVERS_FAILURE = '[POOLS] Load drivers was failed';
export const DRIVER_POOLS_LOAD = '[POOLS] Load driver pools request';
export const DRIVER_POOLS_LOAD_SUCCESS = '[POOLS] Driver pools loaded successfully';
export const DRIVER_POOLS_LOAD_FAILURE = '[POOLS] Driver pools load failed';
export const CREATE_POOL = '[POOLS] Create pool request';
export const CREATE_POOL_SUCCESS = '[POOLS] Create pool successfully';
export const CREATE_POOL_FAILURE = '[POOLS] Create pool failed';

export const ADD_DRIVER_TO_POOLS = '[POOLS] Add driver to pools request';
export const ADD_DRIVER_TO_POOLS_SUCCESS = '[POOLS] Add driver to pools successfully';
export const ADD_DRIVER_TO_POOLS_FAILURE = '[POOLS] Add driver to pools failed';
export const HIDE_DRIVER_POOLS_NOTIFICATION = '[POOLS] Hide notification about adding driver to pools';

export const REMOVE_DRIVER_FROM_POOLS = '[POOLS] Remove driver from pools';
export const REMOVE_DRIVER_FROM_POOLS_SUCCESS = '[POOLS] Remove driver from pools successfully';
export const REMOVE_DRIVER_FROM_POOLS_FAILURE = '[POOLS] Remove driver from pools failed';

export const RESET_DRIVERS = '[POOLS] Reset drivers';

export type PoolsAction = Action<any, any>;

@Injectable()
export class PoolsActions {

    @dispatch()
    loadDriverList = (query: string, minVehicleType: number, shipperId?: any): PoolsAction => ({
        type: LOAD_DRIVERS,
        payload: {query, minVehicleType, shipperId}
    })

    loadDriverListSucceeded = (drivers: Array<DriverDto>): PoolsAction => ({
        type: LOAD_DRIVERS_SUCCESS,
        payload: drivers
    })

    loadDriverListFailed = (error: any): PoolsAction => ({
        type: LOAD_DRIVERS_FAILURE,
        payload: null,
        error: error
    })

    @dispatch()
    resetDriverList = (): PoolsAction => ({
        type: RESET_DRIVERS,
        payload: null
    })

    @dispatch()
    loadPoolList = (): PoolsAction => ({
        type: LOAD_POOLS,
        payload: null
    })

    loadPoolListSucceeded = (pools: Array<PoolDto>): PoolsAction => ({
        type: LOAD_POOLS_SUCCESS,
        payload: pools
    })

    loadPoolListFailed = (error: any): PoolsAction => ({
        type: LOAD_POOLS_FAILURE,
        payload: null,
        error: error
    })

    @dispatch()
    driverPoolsLoad = (req: string): PoolsAction => ({
        type: DRIVER_POOLS_LOAD,
        payload: req
    })

    driverPoolsLoadSucceeded = (data: DriverPools, driverId?: string): PoolsAction => ({
        type: DRIVER_POOLS_LOAD_SUCCESS,
        payload: {data: data, driverId: driverId}
    })
    driverPoolsLoadFailed = (error: any): PoolsAction => ({
        type: DRIVER_POOLS_LOAD_FAILURE,
        payload: null,
        error
    })

    @dispatch()
    createPool = (req: any): PoolsAction => ({
        type: CREATE_POOL,
        payload: req
    })

    createPoolSucceeded = (data: any, driverId: any): PoolsAction => ({
        type: CREATE_POOL_SUCCESS,
        payload: {data: data, driverId: driverId}
    })
    createPoolFailed = (error: any): PoolsAction => ({
        type: CREATE_POOL_FAILURE,
        payload: null,
        error
    })

    @dispatch()
    addDriverToPools = (req: any): PoolsAction => ({
        type: ADD_DRIVER_TO_POOLS,
        payload: req
    })

    addDriverToPoolsSucceeded = (data: any, driverId: any, driverPools: Array<any>, orderId: any): PoolsAction => ({
        type: ADD_DRIVER_TO_POOLS_SUCCESS,
        payload: {data, driverId, driverPools, orderId}
    })

    addDriverToPoolsFailed = (error: any): PoolsAction => ({
        type: ADD_DRIVER_TO_POOLS_FAILURE,
        payload: null,
        error
    })

    @dispatch()
    removeDriverFromPools = (payload: any): PoolsAction => ({
        type: REMOVE_DRIVER_FROM_POOLS,
        payload: payload
    })

    removeDriverFromPoolsSucceeded = (driverId: number, removedPoolIds: number[]): PoolsAction => ({
        type: REMOVE_DRIVER_FROM_POOLS_SUCCESS,
        payload: {driverId, removedPoolIds}
    })

    removeDriverFromPoolsFailed = (error: any): PoolsAction => ({
        type: REMOVE_DRIVER_FROM_POOLS_FAILURE,
        payload: null,
        error
    })

    @dispatch()
    hideNotification = (req): PoolsAction => ({
        type: HIDE_DRIVER_POOLS_NOTIFICATION,
        payload: req
    })
}
