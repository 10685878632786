import { OrderStatusId, StopStatusId } from '../../core/models/dto';

export class OrderUtils {
    public static showDateForOrderBar(orderStatusType) {
        if (orderStatusType === OrderStatusId.Canceled ||
            orderStatusType === OrderStatusId.PendingCancellation ||
            orderStatusType === OrderStatusId.Completed  ||
            orderStatusType === OrderStatusId.CompletedWithExceptions) {
            return true;
        }
        return false;
    }

    public static isAnyStopCompleted(stopList): boolean {
        let flag = false;
        for (let i = 0, n = stopList.length; i < n; i++) {
            if (stopList[i].statusId === StopStatusId.CompletedWithException ||
                stopList[i].statusId === StopStatusId.Completed) {
                flag = true;
                break;
            }
        }
        return flag;
    }
}
