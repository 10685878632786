import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SummaryDto } from '../../../core/models/order.dto';

@Component({
    selector: 'tuya-shipment-footer',
    templateUrl: 'shipment-footer.component.html',
    styleUrls: ['shipment-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class ShipmentFooterComponent implements OnInit {

    @Input() set miles(miles) {
        if (miles) {
            this.orderSummary.miles = miles;
        }
    }

    @Input() orderSummary: SummaryDto = { items: 0, stops: 0, lbs: 0, miles: 0, returns: 0 };
    @Input() isLoading = false;

    constructor() { }
    ngOnInit() {}

}
