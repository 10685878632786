import { Marker } from '../../core/models/map.model';
import { MarkerUrls } from '../../core/constants/general.constants';

export class OrderTransforms {

    public static transformOrderToMarkerList(order$) {
        return order$.map(order => {
            return order.stopList.reduce((p, c) => {
                const address = c.address;
                if (address) {
                    return p.concat({
                        latLng: {lat: +address.latitude, lng: +address.longitude},
                        id: c.id || c.addressId,
                        activeIconUrl: MarkerUrls[c.typeId].activeIconUrl,
                        iconUrl: MarkerUrls[c.typeId].iconUrl,
                        infoWindow: {
                            infoWindowHeader: `${c.nameOrDescription || ''} ${address.addressLine}`,
                            infoWindowBody: 'Select Pin to Refine this Stop\'s location'
                        }
                    });
                } else {
                    return p;
                }
            }, <Marker[]>[]);
        });
    }
}

