export enum ConfirmPopupType {
    confirm = 'Confirm an action: has thow buttons: Cancel and Accept'
}

export interface ConfirmPopup {
    type: ConfirmPopupType;
    text: string;
    buttons?: {
        accept?: string;
        cancel?: string
    };
}
