import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { SEARCH_DRIVERS, GET_POOLS } from '../constants/api.constants';
import { ConfigService } from '../../app.config.service';
import { DriverDto, PoolDto } from '../models/dto';

const API_CREATE_POOL = '/api/v1/DriverPool';
const API_ADD_DRIVER_TO_POOLS = '/api/v1/DriverPool/AddDriverToPools';
const API_REMOVE_DRIVER_FROM_POOLS = '/api/v1/DriverPool/RemoveDriverFromPools';
const API_GET_DRIVER_POOLS = '/api/v1/ShipperProfile/Pools/Driver/';

@Injectable()
export class PoolsService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    getPools(shipperId: any): Observable<PoolDto[]> {
        const id = shipperId ? { shipperUserId: shipperId } : {};
        return this.api().GET(GET_POOLS, id).pipe(map((data: any) => data.pools));
    }

    getDriverByQuery(payload: any): Observable<DriverDto[]> {
        const params: any = {searchTerm: payload.query, minVehicleType: payload.minVehicleType};
        if (payload.shipperId) {
            params.shipperId = payload.shipperId;
        }
        return this.api().GET(SEARCH_DRIVERS, params).pipe(map((data: any) => data.drivers));
    }

    getDriverPools(driverId: string): Observable<any> {
        return this.api().GET(API_GET_DRIVER_POOLS + driverId).pipe(map((data: any) => data));
    }

    createPool(userAccountId: any, name: string, shipperUserAccountId: any): Observable<any> {
        return this.api().POST(API_CREATE_POOL, {
            shipperUserAccountId: shipperUserAccountId,
            name: name,
            'drivers': [{
                userAccountId: userAccountId
            }]
        }).pipe(map((data: any) => data));
    }

    addDriverToPools(driverUserAccountId: any, driverPoolIds: Array<any>): Observable<any> {
        return this.api().POST(API_ADD_DRIVER_TO_POOLS, {
            driverUserAccountId: driverUserAccountId,
            driverPoolIds: driverPoolIds
        }).pipe(map((data: any) => data));
    }

    removeDriverFromPools(driverUserAccountId: number, driverPoolIds: string[]):  Observable<any> {
        return this.api().POST(API_REMOVE_DRIVER_FROM_POOLS, { driverUserAccountId, driverPoolIds }).pipe(map((data: any) => data));
    }
}
