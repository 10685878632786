import { AddressDto, StateDto, StopDto, StopStatusId } from '../../core/models/dto';
import { OrderItemVo, OrderVo, Stop, StopType } from '../../shipment/models/order.model';
import { AutocompleteItem } from '../../core/models/autocomplete-item.model';
import { OrderDto, OrderItemDto } from '../../core/models/order.dto';
import { ShipmentType } from '../../core/models/shipment.model';
import { getFullAddressLine } from '../utils/address.utils';

export function transformOrderVoIntoOrderDto(orderVo: OrderVo, userAccountId: any)
    : OrderDto {
    const orderDto = new OrderDto(), orderStopListLength = orderVo.stopList.length;
    if (orderVo.id) orderDto.id = orderVo.id;

    orderDto.userAccountId = userAccountId;
    orderDto.deliveryTypeId = orderVo.deliveryType;
    orderDto.driverAssignmentTypeId = orderVo.driverAssignmentType;
    orderDto.isDirect = orderVo.isDirect;
    orderDto.isExtendable = orderVo.isExtendable;
    orderDto.total = orderVo.total;
    orderDto.orderCode = orderVo.orderCode;
    orderDto.addresses = [];
    orderDto.assignments = orderVo.assignments;
    orderDto.referenceId = orderVo.referenceId;

    const existingAddressIds = new Set(orderDto.addresses.map(address => address.id));
    orderVo.stopList.forEach(stop => {
        if (!existingAddressIds.has(stop.address.id)) {
            orderDto.addresses.push(stop.address);
            existingAddressIds.add(stop.address.id); // Update set with new address id
        }
    });

    /*orderVo.stopList.forEach((stop) => {
        // ToDo: More common way should exist for distinct
        if (orderDto.addresses.findIndex(x => x.id === stop.address.id) === -1) {
            orderDto.addresses.push(stop.address);
        }
    });*/

    orderDto.stops = [];

    for (let i = 0; i < orderStopListLength; i++) {
        const stop = orderVo.stopList[i];
        const sequenceNumber = stop.sequenceNumber || orderStopListLength; // problem?
        const stopDto = transformStopIntoStopDto(stop, sequenceNumber);
        orderDto.stops.push(stopDto);
    }

    const fFixInvalidItems = (function (order: OrderVo) {
        function fReduceFirstPickup(acc, val) {
            return (acc !== null || val.typeId !== StopType.Pickup)
                ? acc
                : val.id;
        }

        function fReduceLastDelivery(acc, val) {
            return (val.typeId === StopType.Delivery && val.id)
                ? val.id
                : acc;
        }

        const fFilterNonexistentStopId = (function () {
            const stopIdMap = order.stopList.reduce((l, r) => ({
                ...l, [r.id]: true
            }), {});
            return function filterNonexsistentStopId(id: any) {
                return !!stopIdMap[id];
            };
        })();

        const mainStopId = (function calcMainStopId(o: OrderVo) {
            const fReduceMainStop = (order.deliveryType === 1)
                ? fReduceFirstPickup
                : fReduceLastDelivery;
            return order.stopList.reduce(fReduceMainStop, null);
        })(order);

        if (mainStopId === null) {
            console.log('Fixme: there is no main stop');
        }

        return function (stopIds: any[]) {
            stopIds = stopIds.filter(fFilterNonexistentStopId);
            if (stopIds.length === 1 && stopIds.slice(0, 1)[0] === mainStopId) {
                // there is only main stop left
                return [];
            }
            if (mainStopId === null || stopIds.length === 2) {
                return stopIds;
            }
            return stopIds.concat(mainStopId);
        };
    })(orderVo);

    orderDto.orderItems = orderVo.itemList
        .map((vo: OrderItemVo): OrderItemDto => ({
            id: vo.id,
            orderId: orderVo.id,
            directId: vo.directId,
            piecesCount: vo.piecesCount,
            sizeType: vo.sizeId,
            weight: vo.weight,
            reference: vo.reference,
            description: vo.description,
            stopIds: vo.sizeId === 6 ? vo.stopIds : fFixInvalidItems(vo.stopIds)
        }))
        .filter((dto: OrderItemDto) => dto.stopIds.length > 0);

    return orderDto;
}

export function transformOrderDtoIntoOrderVo(orderDto: OrderDto): OrderVo {

    const orderVo = new OrderVo();

    orderVo.id = orderDto.id;
    orderVo.isExtendable = orderDto.isExtendable;
    orderVo.deliveryType = orderDto.deliveryTypeId;
    orderVo.driverAssignmentType = orderDto.driverAssignmentTypeId;
    orderVo.isDirect = orderDto.isDirect;
    orderVo.orderCode = orderDto.orderCode;
    orderVo.statusId = orderDto.statusId;
    orderVo.total = orderDto.total;
    orderVo.selectedCandidates = orderDto.selectedCandidates;
    orderVo.assignments = orderDto.assignments;
    orderVo.referenceId = orderDto.referenceId;

    orderVo.stopList = orderDto.stops
        .map((stopDto) => transformStopDtoIntoStop(
            stopDto,
            orderDto.addresses.filter(x => x.id === stopDto.addressId)[0]))
        .sort((a, b) => a.sequenceNumber - b.sequenceNumber);

    orderVo.itemList = orderDto.orderItems
        .map(dto => ({ ...dto, sizeId: dto.sizeType }));

    orderVo.orderSummary = orderDto.orderSummary;
    orderVo.vehicleType = orderDto.vehicleType;
    return orderVo;
}

export function transformStopIntoStopDto(stop: Stop, sequenceNumber: number): StopDto {
    const stopDto: StopDto = {
        id: stop.id,
        orderId: stop.orderId,
        addressId: stop.addressId,
        directId: stop.directId,
        typeId: stop.typeId,
        sequenceNumber: sequenceNumber,
        nameOrDescription: stop.nameOrDescription,
        pickupDateTypeId: stop.pickupDateTypeId,
        pickupNoEarlierThan: stop.pickupNoEarlierThan,
        pickupNoLaterThan: stop.pickupNoLaterThan,
        contactName: stop.contactName,
        contactPhoneNumber: stop.contactPhoneNumber,
        contactEmail: stop.contactEmail,
        isEmailTrackingLink: stop.isEmailTrackingLink,
        isTextTrackingLink: stop.isTextTrackingLink,
        isSignatureRequired: stop.isSignatureRequired,
        itemsDescription: stop.itemsDescription,
        notes: stop.notes,
        statusId: stop.statusId
    };

    return stopDto;
}

export function transformAddressIntoStopDto(address: AddressDto): StopDto {
    const stopDto: StopDto = {
        id: 0,
        orderId: 0,
        addressId: 0,
        directId: null,
        typeId: 0,
        sequenceNumber: 0,
        nameOrDescription: address.name,
        pickupDateTypeId: null,
        pickupNoEarlierThan: null,
        pickupNoLaterThan: null,
        contactName: null,
        contactPhoneNumber: null,
        contactEmail: null,
        isEmailTrackingLink: false,
        isTextTrackingLink: false,
        isSignatureRequired: false,
        itemsDescription: null,
        notes: null,
        statusId: StopStatusId.Nothing,
        address: address
    };

    return stopDto;
}

export function transformStopDtoIntoStop(stopDto: StopDto, address: AddressDto): Stop {
    const stop: Stop = {
        id: stopDto.id,
        typeId: stopDto.typeId,
        orderId: stopDto.orderId,
        directId: stopDto.directId,
        address: address,
        addressId: stopDto.addressId,
        sequenceNumber: stopDto.sequenceNumber,
        nameOrDescription: stopDto.nameOrDescription,
        pickupDateTypeId: (null === stopDto.pickupDateTypeId) ? 1 : stopDto.pickupDateTypeId,
        pickupNoEarlierThan: (null === stopDto.pickupNoEarlierThan) ? '' : stopDto.pickupNoEarlierThan,
        pickupNoLaterThan: (null === stopDto.pickupNoLaterThan) ? '' : stopDto.pickupNoLaterThan,
        contactName: stopDto.contactName,
        contactPhoneNumber: stopDto.contactPhoneNumber,
        contactEmail: stopDto.contactEmail,
        isEmailTrackingLink: stopDto.isEmailTrackingLink,
        isTextTrackingLink: stopDto.isTextTrackingLink,
        isSignatureRequired: stopDto.isSignatureRequired,
        itemsDescription: stopDto.itemsDescription,
        notes: stopDto.notes,
        stopDtoType: stopDto.stopDtoType,
        statusId: stopDto.statusId
    };

    return stop;
}

export function transformStop2AutocompleteItem(item: Stop, index, states: StateDto[]): AutocompleteItem {
    return {
        name: getFullAddressLine(item, states),
        value: index,
        type: item.stopDtoType
    };
}

// return stops ids for default item
export function transformToDefaultItemStopIds(order: OrderVo) {
    const mainStopType = order.deliveryType === ShipmentType.Distribution ? StopType.Pickup : StopType.Delivery,
        relatedStopType = order.deliveryType === ShipmentType.Collection ? StopType.Pickup : StopType.Delivery,
        stopsThatShouldHaveItems = order.stopList.filter(stop => stop.typeId === mainStopType && stop.nameOrDescription.length),
        relatedStop = order.stopList.filter(stop => stop.typeId === relatedStopType).slice(-1)[0];
    // @TODO needs refactoring
    // stopToAddDefaultItem is needed for case if itemList is empty
    const stopToAddDefaultItem = stopsThatShouldHaveItems.find(stop =>
        !order.itemList.find(item => item.stopIds.indexOf(stop.id) !== -1));
    // 'firstStop' contain main stop (pickup if shipmentType distribution, delivery if shipmentType collection)
    const firstStop = stopsThatShouldHaveItems.find(stop =>
        !!order.itemList.find(item => item.stopIds.indexOf(stop.id) !== -1));
    const mainStop = !order.itemList.length ? stopToAddDefaultItem : firstStop;
    /*
      @Important()
    * @Description: If collection we don't set default item, else should set item;
    * If distribution order type and item list's empty we don't set default item
    * */
    const isNeededItemDist = !stopToAddDefaultItem && !order.itemList.length && order.deliveryType === ShipmentType.Distribution;
    const isNeededItemCollection = order.stopList.length === 2 && order.deliveryType === ShipmentType.Collection;
    return (isNeededItemDist || isNeededItemCollection) ?
        [] : [mainStop.id, relatedStop.id];
}
