<accordion class="main {{OrderHelper.getStopType(trackingInfo.stopInfo.typeId) | translate}}">
    <accordion-group panelClass="panel-body">
        <div accordion-heading class="panel-heading" (click)="isOpen = !isOpen">
            <div class="icons">
                <div class="bubble"></div>
            </div>
            <span class="chevron-heading">
                <i class="pull-right fa" aria-hidden="true"
                    [ngClass]="{'fa-angle-down': isOpen, 'fa-angle-up': !isOpen}"></i>
            </span>
            <div class="stop-name">
                <span>{{trackingInfo.stopInfo?.nameOrDescription}}</span>
            </div>
            <div class="stop-address">
                <span>{{trackingInfo.stopInfo?.address?.addressLine}}</span>
            </div>
        </div>

        <!-- TRACKING EMAIL: Items for Pickup/Delivery -->
        <perfect-scrollbar class="sidebar-content">
            <div class="accordion-content">
                <div class="items-description-type">
                    <span class="uppercase"
                        [translate]="'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.items-for'"></span>
                    <span class="uppercase stop-type">{{
                        OrderHelper.getTrackingStopTypeName(trackingInfo.stopInfo.typeId) | translate }}</span>
                </div>
                <div class="description">
                    <span>{{ trackingInfo.stopInfo?.itemsDescription | translate }}</span>                    
                </div>              
                <tuya-stop-item-info *ngFor="let stopItem of trackingInfo.orderItems" [stopItem]="stopItem">
                </tuya-stop-item-info>
            </div>
        </perfect-scrollbar>
    </accordion-group>
</accordion>