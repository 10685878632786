import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { PickupDateType } from '../../../../../core/models/shipment.model';
import { StopDateCommonComponent } from '../stop-date-common.component';
import { DateHelper } from '../../../../utils/dateHelper';


@Component({
    selector: 'tuya-primary-stop-date',
    templateUrl: '../stop-date-common.component.html',
    styleUrls: ['../stop-date-fields.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopPrimaryStopDateComponent extends StopDateCommonComponent {
    constructor() {
        super();
    }

    updateMinNoEarlierDT(newDateTypeId: number) {
        // update constrains for the timepicker.
        const minLocalDT = (PickupDateType.Tomorrow === newDateTypeId)
            ? DateHelper.getTomorrow(this.currentDate) // tomorrow
            : this.currentDate, // today
            minRelatedDT = this.currentDate;
        this.minNoEarlierDT = (minLocalDT >= minRelatedDT) ? minLocalDT : minRelatedDT; // pick more strict one.
    }
}
