import { Pipe, PipeTransform } from '@angular/core';
import { OrderItemVo } from '../models/order.model';

@Pipe({
  name: 'filterItemsByStopId'
})
export class FilterItemsByStopIdPipe implements PipeTransform {

  transform(items: OrderItemVo[], args: any): OrderItemVo[] {
    return items.filter(item => item.stopIds.includes(args));
  }

}
