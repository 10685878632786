import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../app.config.service';
import { Meta } from '@angular/platform-browser';
import { Configuration } from '../core/models/base.config';


@Component({
    selector: 'tuya-shipper-mobile-home',
    templateUrl: './mobile-shipper-home.component.html',
    styleUrls: ['./mobile-shipper-home.component.scss']
})

export class ShipperMobileHomeComponent implements OnInit {
    appConfig: Configuration;
    isAndroid = false;

    constructor(public configService: ConfigService, private meta: Meta) {
    }

    ngOnInit() {
        this.isAndroid = navigator.userAgent.toLowerCase().indexOf('android') !== -1;
        this.appConfig = this.configService.getConfiguration();
    }

    getAppLink() {
        return this.isAndroid ? this.appConfig.mobileAppAndroidShipper + 'verifiedEmail' :
                this.appConfig.mobileAppDomain + 'mobile/home';
    }
}

