<div class="container">
    
    <div class="closeButton">
        <div class="timer">{{ 'tuya-alert.timerTxt' | translate }}:&nbsp;<span id="time">{{ min }}:{{ sec }}</span></div>
    </div>
    <mat-dialog-content class="modal-body">
        <div class="title">
            {{ 'priceChangeModal.title' | translate }}
        </div>

        <div class="price-message">
           <div class="circle"></div> 
           <div class="txt">
                {{ 'priceChangeModal.message' | translate }}
                <span class="boldText">{{ orderData?.price.prev | currency: 'USD' : 'symbol-narrow'}}</span>
                {{ 'priceChangeModal.to' | translate }}
                <span class="boldText">{{ orderData?.price.current | currency: 'USD' : 'symbol-narrow'}}</span>.
           </div>
        </div>

        <div class="shipper-info-title boldText">
            {{ 'priceChangeModal.shipperInfo' | translate }}:
        </div>

        <div class="shipper-info boldText">
            <div class='name'>{{ orderData?.shipper.name }}</div>
            <div class='phone'>{{ orderData?.shipper.phone | phoneNumber}}</div>
        </div>
        
        <div class="seperator"></div>

        <div class="price-section">
            <label class="header">{{ 'priceChangeModal.priceSelectionTitle' | translate }}:</label>
            <mat-radio-group color="accent" class="price-options"
                            [(ngModel)]="adminSelection" *ngFor="let option of options; let i=index">

                <mat-radio-button class="radio-button" value="{{ (i+1).toString() }}">
                    <span [ngClass]="{'activeColor': adminSelection === (i+1).toString() }"> {{ option.name | translate }}</span>
                </mat-radio-button>
                <p *ngIf="i===0" class="subTitle" >{{  option.desc | translate }}  {{ orderData?.price.prev | currency: 'USD' : 'symbol-narrow' }}.</p>
                <p *ngIf="i===1" class="subTitle" >{{  option.desc | translate }}  {{ orderData?.price.current | currency: 'USD' : 'symbol-narrow'}}.</p>
            </mat-radio-group>
        </div>

        <mat-dialog-actions class="submit-btn-container">
            <button mat-raised-button color="primary" class="cencelBtn" (click)="onCloseModal()">Cancel</button>
            <button mat-raised-button color="primary" class="submitBtn" (click)="onSubmit()">Submit</button>
        </mat-dialog-actions>

    </mat-dialog-content>
</div>