import { Action } from 'redux';
import { HOME_LOAD_RATE_REASONS_SUCCESS } from '../../actions/my-orders.actions';
import { HomeAction } from '../../actions/home.actions';

export function rateReasonsReducer(state = [], a: Action) {
    const {type, payload} = a as HomeAction;
    switch (type) {
        case HOME_LOAD_RATE_REASONS_SUCCESS: {
            return payload;
        }

        default:
            return state;
    }
}
