import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { GeneralOfferActions, START_PRICE_OPTIMIZATION_CREATE } from '../actions/general-offer.actions';
import { ShipmentService } from '../services/shipment.service';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
@Injectable()
export class GeneralOfferEpics {
    constructor(
        private generalOfferActions: GeneralOfferActions,
        private shipmentService: ShipmentService,
        private alertActions: AlertActions) {
    }

    public createEpic() {
        return combineEpics (
            createPriceOptimizationEpic(this.generalOfferActions, this.shipmentService, this.alertActions),
        );
    }
}

export function createPriceOptimizationEpic(actions: GeneralOfferActions,
                                            service: ShipmentService,
                                            alertActions: AlertActions) {
    return (action$, store) => action$.pipe(
        ofType(START_PRICE_OPTIMIZATION_CREATE),
        switchMap((action: any) => {
            return service.startPriceOptimization(action.payload).pipe(
                map((data: any) => {
                    // if stops has invalid timeframes - show error
                    if (data.hasErrors) {
                        const stopList = store.value.shipment.order.stopList;
                        return actions.requestStartPriceOptimizationTimeframesFailed(data.unservedStopIds, stopList);
                    } else { // else show price optimisation result
                        return actions.requestStartPriceOptimizationSucceeded({...data,
                            hasAlreadyOptimized: action.payload.hasAlreadyOptimized ? true : data.isOptimized});
                    }
                })
                ,catchError(error => {
                    return of(actions.requestStartPriceOptimizationFailed({
                        error
                    }), alertActions.show({ message: error, type: AlertType.Error }));
                }));
        })
    );
}