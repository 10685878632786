<div class="flex" *ngIf="_details !== null">
    <tuya-stop-details-header
            [address]="address"
            [stopType]="stopType"
            [isBackButtonAvaliable]="isBackBtnAvailable()"
            [isDisableSuitNo]="isDisabledCompletedStop()"
            (goBack)="goBack.emit()"></tuya-stop-details-header>
  <perfect-scrollbar class="sidebar-content">
      <tuya-stop-name-field
              [stop]="_details"
              [disabled]="isDisabledCompletedStop()"
              [isFormPristine]="isFormPristine"
              (change)="onDetailsChanged($event)"
              (validate)="onUpdateValidationMap($event)"
      ></tuya-stop-name-field>
      <!-- Block of date section components -->
      <div class="details-section">
          <div *ngIf="isDirect; else regularDate">
              <tuya-stop-direct-order-date
                      [disabled]="ifDisableAssignedOrder() || ifDisabledInprogressOrder()"
                      [isInitialPickupStop]="isInitialPickupStop"></tuya-stop-direct-order-date>
          </div>
          <ng-template #regularDate>
              <div *ngIf="relatedStop; then dependedStopDate else primaryStopDate"></div>
              <ng-template #primaryStopDate>
                  <tuya-primary-stop-date
                          [relatedStop]="relatedStop"
                          [disabled]="ifDisabledInprogressOrder()"
                          [stop]="dateComponentData"
                          [isEditMode]="isEditMode()"
                          [isDraftStopDetailsEditMode]="isStopDetailsEditMode"
                          [isFormPristine]="isFormPristine"
                          (change)="onDetailsChanged($event)"
                          (updateDate)="onUpdateStopDate($event)"
                          (validate)="onUpdateValidationMap($event)"
                  ></tuya-primary-stop-date>
              </ng-template>
              <ng-template #dependedStopDate>
                  <tuya-depended-stop-date
                          [relatedStop]="relatedStop"
                          [disabled]="ifDisabledInprogressOrder()"
                          [stop]="dateComponentData"
                          [isEditMode]="isEditMode()"
                          [isDraftStopDetailsEditMode]="isStopDetailsEditMode"
                          [isFormPristine]="isFormPristine"
                          (change)="onDetailsChanged($event)"
                          (updateDate)="onUpdateStopDate($event)"
                          (validate)="onUpdateValidationMap($event)"
                  ></tuya-depended-stop-date>
              </ng-template>
          </ng-template>
      </div>
      <!-- Block of date section components -->
      <tuya-shipment-stop-contact-fields
              [stop]="_details"
              [disabled]="ifDisabledInprogressOrder()"
              [isFormPristine]="isFormPristine"
              (validate)="onUpdateValidationMap($event)"
              (change)="onDetailsChanged($event)"
      ></tuya-shipment-stop-contact-fields>
      <tuya-delivery-items-selection
              *ngIf="isItemsShowed()"
              [disabled]="ifDisabledInprogressOrder()"
              [disabledDescription]="isDisabledCompletedStop()"
              [itemsSizes]="itemsSizes"
              [defaultSize]="defaultSize"
              [stop]="_details"
              [items]="selectedItems"
              [isFormPristine]="isFormPristine"
              (change)="onDetailsChanged($event)"
              (onSizeSelected)="onDefaultSizeUpdate.emit($event)"
              (saveItems)="onItemsDetailsChunkEnter($event)"
              (validate)="onUpdateValidationMap($event)"></tuya-delivery-items-selection>
        <tuya-stop-return-items
              *ngIf="isReturnSectionAvailable()"
              class="details-section"
              [disabled]="ifDisabledInprogressOrder()"
              [returnItem]="returnItem"
              [isFormPristine]="isFormPristine"
              (saveReturnItemCount)="onSaveReturnItemCount($event)"></tuya-stop-return-items>
      <tuya-stop-notes-field
          [disabled]="ifDisabledInprogressOrder()"
          [stop]="_details"
          (change)="onDetailsChanged($event)"
          [isFormPristine]="isFormPristine"></tuya-stop-notes-field>
      <div class="btn-container">
          <button class="btn btn-secondary save-items {{OrderHelper.getStopHeader(stopType).toLowerCase()}}" [disabled]="isSaveBtnDisabled()" (click)="onSaveStopDetails()">
              {{'tuya-shipment.tuya-stop-details.save-details' | translate }}
          </button>
      </div>
  </perfect-scrollbar>
</div>
