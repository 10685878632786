import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StopEventLog, StopEventType } from '../../../core/models/dto';
import { StopType } from '../../../shipment/models/order.model';
import { EventsNames, OrderTypes } from '../../models/tracking.model';

@Component({
    selector: 'tuya-stop-event-item',
    templateUrl: './stop-event-item.component.html',
    styleUrls: ['./stop-event-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class StopEventItemComponent {
    @Input() public stopItem: StopEventLog = <StopEventLog>{};
    @Input() public set typeId(typeId) {
        this._typeId = typeId;
        this.stopName = this.getStopNames();
        this.statusText = this.getStatusText();
        this.orderType = this.getOrderType();
        this.eventItems = this.getStopItemsCount();
    }

    public get typeId () {
        return this._typeId;
    }
    public stopName: string;
    public statusText: string;
    public orderType: string;
    public eventItems: any;
    public stopEventType: typeof StopEventType = StopEventType;
    public stopType: typeof StopType = StopType;
    private _typeId: StopType;


    constructor() {
    }

    private getOrderType(): string {
        return OrderTypes[this.typeId];
    }

    private getStopNames(): string {
        return EventsNames[this.typeId][this.stopItem.typeId];
    }

    private getStatusText(): string {
        switch (this.stopItem.typeId) {
            case StopEventType.Arrive:
                return '';
            case StopEventType.CancelArrival:
                return '';
            case StopEventType.Complete:
                return this.stopItem.name;
            case StopEventType.Departure:
                return this.stopItem.departureReason;
            case StopEventType.ItemException:
                return '';
        }
    }
    private getStopItemsCount() {
        switch (this.typeId) {
            case this.stopType.Return:
                return {
                    actualCount: this.stopItem.returnItemsActualCount,
                    totalCount: this.stopItem.returnItemsTotalCount
                };
            case this.stopType.DeliveryWithReturn:
                return {
                    actualCount: this.stopItem.itemsActualCount,
                    totalCount: this.stopItem.itemsTotalCount,
                    returnActualCount: this.stopItem.returnItemsActualCount,
                    returnTotalCount: this.stopItem.returnItemsTotalCount
                };
            default:
                return {
                    actualCount: this.stopItem.itemsActualCount,
                    totalCount: this.stopItem.itemsTotalCount
                };
        }
    }
}
