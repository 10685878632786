import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuilderActions } from 'app/builder/actions/builder.actions';

@Component({
  selector: 'tuya-schedule-submit-selection',
  templateUrl: './schedule-submit-selection.component.html',
  styleUrls: ['./schedule-submit-selection.component.scss']
})
export class ScheduleSubmitSelectionComponent implements OnInit {

  schSelectionForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private builderActions: BuilderActions,
              private dialogRef: MatDialogRef<ScheduleSubmitSelectionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.initForm(this.data.submitNow);
  }

  initForm(isSubmitNow: boolean) {
    this.schSelectionForm = this.fb.group({
      submitNow: [isSubmitNow]
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    //this.builderActions.scheduleOrderSubmitNow(this.schSelectionForm.controls.submitNow.value);
    this.builderActions.scheduleOrderSubmitNow(true);
    this.dialogRef.close(this.schSelectionForm.controls.submitNow.value);
  }

}
