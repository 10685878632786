<div class="modal-content">
    <div class="modal-body">
        <h3 [translate]="'tuya-shipment.leaving-unfinished-dialog.message'"></h3>

        <!-- Cancel -->
        <button type="button" class="btn btn-default btn-lg" (click)="action(false)"
            [translate]="'tuya-shipment.leaving-unfinished-dialog.cancel'"></button>

        <!-- OK -->
        <button type="button" class="btn btn-primary btn-lg" (click)="action(true)"
            [translate]="'tuya-shipment.leaving-unfinished-dialog.ok'"></button>
    </div>
</div>