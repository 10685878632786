import { Reference } from './order.dto';

export type IndustrySegment = {
    id: number,
    name: string
};

export enum OrderStatusId {
    Draft = 10,
    Offered = 20,
    Assigned = 30,
    InProgress = 35,
    Declined = 40,
    Completed = 50,
    CompletedWithExceptions = 55,
    Canceled = 60,
    PendingCancellation = 61,
    Released = 70,
    Scheduled = 120,
    FutureAssigned = 130
}

export enum OrderItemErrorId {
    Ok = 0,
    NoReceiver = 1,
    DamagedItems = 2,
    MissingItems = 3,
    RefusedByReceiver = 4,
    ExtraItems = 5,
    Unknown = 100
}

export enum StopStatusId {
    Nothing = 0,
    New = 10,
    DriverArrived = 20,
    ArrivalAttempt = 25,
    Completed = 30,
    CompletedWithException = 40,
    Departed = 50,
    Pending
}

export enum StopTypeId {
    Nothing = 0,
    PickedUp = 1,
    Delivered = 2,
    DeliveredWithReturn = 3,
    DeliveredWithPickUp = 4,
    Driver = 5
}

export enum StopEventType {
    Arrive = 12,
    CancelArrival = 13,
    Complete = 14,
    Departure = 15,
    ItemException = 18
}

export class TermsOfServiceDto {
    id: number;
    text: string;
    version: string;
    effectiveDate: Date;
}

export class AddressDto {
    addressLine: string; // old addressLine2
    city: string;
    stateId: number;
    stateAbbr?: string;
    stateName?: string;
    postalCode: string;
    country: string;
    countryShortName?: string;
    id: any;
    latitude: string;
    longitude: string;
    name: string;
    suiteNumber: string;
}

export class DriversLocationsDto {
    id: any;
    latitude: string;
    longitude: string;
    name: string;
    timestamp: string;
}

export class ComplexTokenDto {
    access_token: string;
    refresh_token: string;
    expires_in?: number; // for now it's optional field
    token_type?: string; // for now it's optional field
}

export class BusinessEntityDto {
    name: string;
    code: string;
    id: string;
    industrySegmentId: number;
    physicalAddress: AddressDto;
    billingAddress: AddressDto;
    billingEmail: string;
    billingPhone: string;
    billingFirstName: string;
    billingLastName: string;
    generalPhone: string;
    accountingCode: string;
    allowSalesToContact: false;
    referralCode: string;
    type: number;
}

export class StateDto {
    id: number;
    name: string;
    shortName: string;
}

export class ShipperProfileDto {
    userAccountId: any; // Guid
    firstName: string;
    lastName: string;
    nickname: string;
    phoneNumber: string;
    isPhoneNumberConfirmed: boolean;
    email: string;
    isEmailConfirmed: boolean;
    hasOrders: boolean;
    hasValidCreditCard: boolean;
    referralCode: string;
    businessEntity: BusinessEntityDto;
    rating: number;
    sendUpdates: boolean;
    suspended: boolean;
    isAdmin?: boolean;
    shipperFactorCode?: string;
    canCreateShipment?: boolean;
    shipperFactorCodeDisplay?: string;
    isUnderTerms: boolean;
}

export class ShipperProfileResultDto {
    shipperProfile?: ShipperProfileDto;
    adminProfile?: ShipperProfileDto;
    complexToken: ComplexTokenDto;
}


export class ShipperRegistrationDto {
    firstName: string;
    lastName: string;
    nickname: string;
    phoneNumber: string;
    email: string;
    password: string;
    confirmPassword?: string;
    deviceCode: string;
    referralCode: string;
    businessEntityRegistrationDto: BusinessEntityDto;
    firebaseToken?: string;
}


export class ShipperRegisterationResultDto {
    shipperProfile: ShipperProfileDto;
    complexToken: ComplexTokenDto;
}

export class ServerErrorDto {
    code: string;
    message: string;
    field: string;
    attemptedValue: string;
    entityId: string;
}

export class ServerErrorResultDto {
    errors: ServerErrorDto[];
}

export enum StopDtoType {
    STOP = 0,
    BUSINESS_NAME = 1,
    GOOGLE_ADDRESS = 2
}

export class StopDto {
    id: any; // Guid
    orderId: any;
    addressId: any; // Guid
    typeId: number;
    readonly directId: any;
    sequenceNumber: number;
    nameOrDescription: string;
    pickupDateTypeId: number;
    pickupNoEarlierThan: string;
    pickupNoEarlerThan?: string;  // temp fix for bad data from server
    pickupNoLaterThan: string;
    contactName: string;
    contactPhoneNumber: string;
    contactEmail: string;
    isEmailTrackingLink: boolean;
    isTextTrackingLink: boolean;
    isSignatureRequired: boolean;
    itemsDescription: string;
    notes: string;
    address?: AddressDto;
    stopDtoType?: StopDtoType; // s2248:  0 == Stop, 1 == Business Name, 2 == Google Address
    statusId?: StopStatusId;
}

export class PoolDto {
    id: any;
    name: string;
    shipperUserAccountId: any;
    createByid: any;
    drivers: DriverDto[];
}

export class DriverDto {
    fullName: string;
    userAccountId: any;
}

export class PoolSelectDto {
    id: any;
    name: string;
}

export class DriverPoolDTO {
    driverId: any;
    pools: Array<{ id: any; name: string; }>;
}

export interface CombinedPoolsDTO {
    poolId: number;
    poolName: string;
    driverInPool: boolean;
    newStatus: boolean;
}

export class BoxSizeDto {
    name: string;
    id: number;
    sizeTypeId: number;
    description: string;
    weight: number;
    isVisible: boolean;
}

export class OrdersSearchRequestDto {
    pageSize: number;
    currentPageNumber: number;
    sortField: string;
    sortDirection: number;
    orderFilters: any;
    hasExceptions: boolean;
    shipperUserId?: any;
}

export class OrdersRespDto {
    pagedList: {
        items: OrderRespDtoV2[];
        totalItemsCount: number;
    };
}

export class OrderRespDtoV2 {
    id: number;
    completionDateUtc: string;
    creationDateUtc: string;
    stopsCount: number;
    offeredDriversCount: number;
    total: number;
    statusId: number;
    code: string;
    isDirectOrder: true;
    isCreatedOnMobile: true;
    deliveryPro: string;
    lastEventOnTheStopDate: string;
    externalReferences: string;
    isScheduledOrder: boolean;
    recurrenceOrderDetail: any;
    pickupAddress: {
        stopId: number;
        stopName: string;
        id: number;
        name: string;
        street: string;
        city: string;
        state: string;
        postalCode: string;
        stateId: 0
    };
    deliveryAddress: {
        stopId: number;
        stopName: string;
        id: number;
        name: string;
        street: string;
        city: string;
        state: string;
        postalCode: string;
        stateId: 0
    };
}

export class StopCollapsedViewDto {
    stopId: any; // "StopId": "guid", // Stop identifier
    orderId: any; // "OrderId": "guid", // Order identifier
    name: string; // "Name": string, // Name of stop
    address: any;
}

export class OrderCollapsedViewDto {
    completionDateUtc: string;
    creationDateUtc: string;
    deliveryPro: string | null;
    orderId: number;
    statusId: number;
    stopFrom: StopCollapsedViewDto | null;
    stopTo: StopCollapsedViewDto | null;
    stopsCount: number;
    total: number;
    additionalData: { [key: string]: any };
    isScheduledOrder: boolean;
    recurrenceOrderDetail: any;
}

export class SearchPaginationParamsDto {
    pageSize: number;
    currentPageNumber: number;
    sortField: string;
    sortDirection: number;
    orderFilter?: string;
    hasExceptions?: boolean;
    shipperUserId?: any;
    orderStatuses?: any;
    submitedDateFrom?: Date;
    submitedDateTo?: Date;
}

export class LoadNotificationsParamsDto {
    totalItemCountRequired: boolean;
    pageSize: number;
    currentPageNumber: number;
    searchText: string;
    sortOrder: string;
}

export class LatLngDto {
    latitude: number;
    longitude: number;
}

export class PriceOptimizationDto {
    startOptimization: boolean;
    price: number;
    finishOptimization: boolean;
    isOptimized: boolean;
    hasAlreadyOptimized?: boolean;
    hasErrors: boolean;
    optimizedDistance: number;
    unservedStopNames: Array<string>;
}

export class StopEventLog {
    eventTime: string;
    typeId: StopEventType;
    name: string;
    itemsTotalCount?: number;
    itemsActualCount?: number;
    returnItemsActualCount: number;
    returnItemsTotalCount: number;
    exceptions?: string;
    departureReason?: string;
    note?: string;
}

export class TrackingOrderItemDto {
    piecesCount: number;
    sizeTypeId: number;
    weight: number;
    reference?: string;
    referenceId?: string;
    description?: string;
    actualItemsCount?: number;
    errorNote?: string;
    errorReasonName?: string;
    errorReasonId?: number;
    hasItemError?: boolean;
}

export class TrackingInfoDto {
    driverUserAccountId: any;
    businessEntityName: string;
    createDate: string;
    driverName: string;
    driverPhoto: string | null;
    itemsDescription: string;
    orderCode: string;
    orderItems: TrackingOrderItemDto[];
    orderStatusId: number;
    shipperEmail: string;
    shipperName: string;
    shipperPhone: string;
    lastLocationUpdateTime: string;
    isDirectOrder: boolean;
    stopInfo: StopDto;
    stopEventLog: StopEventLog[];
    driverArrivedAtFirstStop: boolean;
}