import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { of } from 'rxjs';

@Injectable()
export class TrackingGuard implements  CanActivate {
    canActivate(route: ActivatedRouteSnapshot) {
        return of(!!(route.queryParams.code && route.queryParams.encodedStopId));
    }
}
