import { CompanyPageComponent } from './../../profiles/containers/company-page/company-page.component';
import { CancelModalComponent } from './../../profiles/components/cancel-modal/cancel-modal.component';
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CanDeactivate } from '@angular/router';
import { first, map, Subject } from 'rxjs';


@Injectable()
export class DeactivateCompanyGuard implements CanDeactivate<CompanyPageComponent> {

    constructor(private modalService: BsModalService) {}

      canDeactivate(component) {
        const isGeneralChanged = component.generalInfoComponent.isModelChanged();
        const isBillingChanged = component.billingInfoComponent.isModelChanged();

        if (isGeneralChanged || isBillingChanged) {

          const subject = new Subject<boolean>();

          const modal = this.modalService.show(CancelModalComponent);
          modal.content.subject = subject;

          return modal.content.subject.pipe(map(res => {
            if (res) {
              return isGeneralChanged ? component.generalInfoComponent.discardChanges() :
                component.billingInfoComponent.discardChanges();
            }
            return res;
          }),
          first());
        }
        
        return true;
      }
}
