import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tuya-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public model: any = {};

  @Input() isUserNotValid: boolean;
  @Input() set forgotPasswordRequestedEmail(email: string | null) {
    this._requestedEmail = email;
    if (email !== null) {
      this.model.email = email;
    }
  }
  get forgotPasswordRequestedEmail(): string | null {
    return this._requestedEmail;
  }
  @Output() onSubmit = new EventEmitter<string>();

  public _requestedEmail: string | null;

  constructor() { }

  ngOnInit() {
  }

  submit() {
    this.onSubmit.emit(this.model.email);
  }
}