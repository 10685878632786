import { Component, OnInit, Input } from '@angular/core';
import { ShipperProfileDto } from '../../../core/models/dto';
import { Router } from '@angular/router';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { IAppState } from '../../../store/model';

@Component({
  selector: 'tuya-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {
  @select(['admin', 'shipperAdmin', 'selectedShipper'])
  readonly selectedShipper$: Observable <ShipperProfileDto>;

  @Input() adminProfile = new ShipperProfileDto();

  constructor(
    private router: Router,
    private ngRedux: NgRedux<IAppState>,
  ) {
  }

  ngOnInit() { }

  goToDriverPortal() {
    this.router.navigate(['/driver-portal']);
  }

  goBack() {
    this.router.navigate(['/admin-search']);
  }

  logout() {
    this.router.navigate(['/logout']);
  }

  showShipper() {
    const route = this.ngRedux.getState().router;
    return route.indexOf('admin-search') === -1 && route.indexOf('driver-portal') === -1; // show on all pages except /admin
  }

  showGoBakcButton() {
    const route = this.ngRedux.getState().router;
    return route === '/driver-portal';
  }

  showDriverPortalButton() {
    const route = this.ngRedux.getState().router;
    return route === '/admin-search';
  }

}
