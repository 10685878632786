<div *ngIf="(isLoading$ | async)">
  <tuya-spinner size="fullwindow" [clear]="true"></tuya-spinner>
</div>
<form class="admin-panel-container">
  <h2 class="admin-panel-label">{{ 'tuya-admin.admin-panel.admin-panel-label' | translate }}</h2>

  <div class="admin-panel-code-container">
    <label class="admin-panel-shipper-code-label" for="factor-code">
      {{ 'tuya-admin.admin-panel.shipper-code-label' | translate }}
    </label>
    <div 
      *ngIf="!(shipperFactorCode$ | async)"
      class="admin-panel-input-and-apply-button" 
    >
      <input 
        id="factor-code"
        name="factor-code"
        class="admin-panel-enter-code-input" 
        type="text" 
        placeholder="{{ 'tuya-admin.admin-panel.placeholder' | translate }}" 
        [(ngModel)]="codeModel"
      />
      <button 
        (click)="codeApplied()"
        class="admin-panel-button-apply"
        [disabled]="!codeModel || (isLoading$ | async)"
      >{{ 'tuya-admin.admin-panel.apply' | translate }}</button>
    </div>
    <div 
      *ngIf="shipperFactorCode$ | async"
      class="admin-panel-code-and-remove-button" 
    >
      <span class="admin-panel-shipper-factor-code">{{ shipperFactorCode$ | async }}</span>
      <button 
        class="admin-panel-button-remove"
        (click)="codeApplied(true)"
        [disabled]="isLoading$ | async"
      >{{ 'tuya-admin.admin-panel.remove' | translate }}</button>
    </div>
    <div 
      *ngIf="shipperFactorCodeDisplay$ | async"
      class="admin-panel-friendly-code-banner"
    >{{ 'tuya-admin.admin-panel.code-applied' | translate: { friendlyFactorCode: (shipperFactorCodeDisplay$ | async) } }}</div>
  </div>

  <div class="admin-form-wrapper">
    <input 
      class="admin-panel-checkbox-box" 
      id="terms" 
      type="checkbox" 
      (change)="onTermsChange($event, 'terms')"
      [checked]="(shipperProfileInfo$ | async).isUnderTerms"
    />
    <label class="admin-panel-checkbox-enable-terms-text" for="terms">
      {{ 'tuya-admin.admin-panel.enable-terms' | translate }}
    </label>
  </div>

  <div class="admin-form-wrapper">
    <input class="admin-panel-checkbox-box"
      id="suspend"
      type="checkbox"
      name="suspend"
      (change)="onTermsChange($event, 'suspend')"
      [checked]="(shipperProfileInfo$ | async).suspended"
     />
    <label class="admin-panel-checkbox-suspend-account-text" for="suspend">
      {{ 'tuya-admin.admin-panel.suspend' | translate }}
    </label>
  </div>
</form>
