import { Action } from 'redux';

import {
    IndustrySegmentAction,
    LOAD_INDUSTRY_SEGMENTS_ERROR,
    LOAD_INDUSTRY_SEGMENTS_SUCCESS
} from '../actions/industry.segments.actions';

export const initialState = [];


export function industrySegmentsReducer(state = initialState, a: Action): any[] {
    const action = a as IndustrySegmentAction;
    switch (action.type) {
        case LOAD_INDUSTRY_SEGMENTS_SUCCESS: {
            return action.payload;
        }

        case LOAD_INDUSTRY_SEGMENTS_ERROR: {
            return [];
        }

        default: {
            return state;
        }
    }
}

