import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { BoxSizeDto, StateDto, TermsOfServiceDto } from '../models/dto';
import {LOAD_PRIVACY, LOAD_TERMS, TermsOfServiceActions } from '../actions/termsOfService.actions';
import { TermsOfServiceService } from '../services/termsOfService.service';
import { ItemsSizesActions, LOAD_ITEMS_SIZES } from '../actions/items-sizes.actions';
import { ItemsSizesService } from '../services/items-sizes.service';
import { BusinessEntityService } from '../services/business.entity.service';
import { LOAD_STATES, StatesActions } from '../actions/states.actions';
import { ShipperActions } from 'app/shipper/actions/shipper.status.actions';
import { ACCESS_TOKEN_KEY, TOKEN_TYPE } from '../constants/storage.constants';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class CoreEpics {
    constructor(private toss: TermsOfServiceService, private tosActions: TermsOfServiceActions,
                private itemSizeService: ItemsSizesService, private itemSizeActions: ItemsSizesActions,
                private businesEntityService: BusinessEntityService, private statesActions: StatesActions,
                private shipperActions: ShipperActions) {
    }

    public createEpic() {
        return combineEpics(
            createTermsEpic(this.toss, this.tosActions),
            createPrivacyEpic(this.toss, this.tosActions),
            createLoadItemsSizesEpic(this.itemSizeService, this.itemSizeActions),
            createLoadStatesEpic(this.businesEntityService, this.statesActions, this.shipperActions)
        );
    }
}

export function createTermsEpic(toss: TermsOfServiceService,
                                actions: TermsOfServiceActions) {
    return action$ => action$.pipe(
        ofType(LOAD_TERMS),
        exhaustMap((action: any) => {
            return toss.getTermsOfService(action.payload).pipe(
                map((data: TermsOfServiceDto) => actions.loadTermsSucceeded(data))
                ,catchError(response => of(actions.loadTermsFailed(<any>{
                    status: '' + response.status,
                }))));
        })
    );
}

export function createPrivacyEpic(toss: TermsOfServiceService,
                                  actions: TermsOfServiceActions) {
    return action$ => action$.pipe(
        ofType(LOAD_PRIVACY),
        exhaustMap((action: any) => {
            return toss.getPrivacyPolicy(action.payload).pipe(
                map((data: TermsOfServiceDto) => actions.loadPrivacySucceeded(data))
                ,catchError(response => of(actions.loadPrivacyFailed({
                    status: '' + response.status,
                }))));
        })
    );
}

export function createLoadItemsSizesEpic(itemSizeService: ItemsSizesService,
                                         actions: ItemsSizesActions) {
    return action$ => action$.pipe(
        ofType(LOAD_ITEMS_SIZES),
        exhaustMap((action) => {
            return itemSizeService.getItemsSizes().pipe(
                map((data: BoxSizeDto[]) => actions.loadItemsSizesSuccess(data))
                ,catchError(response => of(actions.loadItemsSizesFail({
                    status: '' + response.status,
                }))));
        })
    );
}

export function createLoadStatesEpic(businesEntityService: BusinessEntityService,
                                     actions: StatesActions, shipperActions: ShipperActions) {
    return action$ => action$.pipe(
        ofType(LOAD_STATES),
        exhaustMap((action) => {
            return businesEntityService.getStates().pipe(
                map((data: StateDto[]) => {
                    if (localStorage.getItem(ACCESS_TOKEN_KEY) && localStorage.getItem(TOKEN_TYPE)) {
                        shipperActions.loadProfile();
                    }
                    return actions.loadSucceeded(data);
                })
                ,catchError(response => of(actions.loadFailed({
                    status: '' + response.status,
                }))));
        })
    );
}
