<div class="icons">
    <div class="exclamation" *ngIf="stopItem.errorReasonId">
        <img src="./../../../../../assets/img/my-orders/ic_triangle_exclamation.svg" alt="Item Exception">
    </div>
    <div class="item-icon {{stopItem.itemName}}"></div>
</div>
<div class="item-info">
    <div class="items">
        <div class="items-count">
            <span class="count" *ngIf="!stopItem.errorReasonId">{{stopItem.piecesCount}}</span>
            <span class="error-count" *ngIf="stopItem.errorReasonId">
                {{stopItem.actualItemsCount}}
                {{'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.of' | translate}}
                {{stopItem.piecesCount}}
            </span>
            {{stopItem.itemName}}
            {{'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.items' | translate}}
        </div>
    </div>
    <div class="info" *ngIf="stopItem.sizeTypeId !== boxSizeTypes.UnknownReturn">
        <span class="dimensions">
            {{stopItem.itemDescription}} ·
            {{stopItem.itemWeight}}
            {{'tuya-tracking.tuya-tracking-info.tuya-tracking-stop-info.lbs' | translate}}
        </span>
        <span class="uppercase err" *ngIf="stopItem.errorReasonId">{{getItemsErrorTypes() | translate}}</span>
    </div>
</div>
<div class="reference-id" *ngIf="stopItem.reference">
    <span class="uppercase">{{stopItem.reference}}</span>
</div>