export interface NewDriverDTO  {
    firstname: string;
    lastname: string;
    physicalAddress: string;
    city: string;
    state: string;
    zipCode: string;
    phoneNumber: string;
    emailAddress: string;
    serviceArea: number;
    cxtNumber: string;
    vehicle: VehicleDTO;
}

export interface VehicleDTO {
    make: string;
    model: string;
    vehicleType: number;
    id?: string;
    driverId?: number;
}

export interface DriverDTO  {
    id: number;
    name: string;
    driverCode: string;
    phoneNumber: string;
    email: string;
    serviceArea: number;
    vehicle: VehicleDTO;
    active: boolean;
}

export interface DriverTablePagination {
    currentPageNumber: number;
    pageSize: number;
    searchQuery?: string;
    totalItemCount?: number;
    isSearching?: boolean;
}

export interface DriverResponse {
    items: DriverDTO[];
    currentPageNumber: number;
    pageSize: number;
    totalItemCount: number;
}

export interface Table  {
    drivers: DriverDTO[];
}

export interface ModalModel {
    mode: DriverModalModeEnum;
    isActive: boolean;
    isDriverSubmitLoading: boolean;
}

export interface IDriverPortal {
    drivers: DriverDTO[];
    pagination: DriverTablePagination;
    driverModal: ModalModel;
    isLoading: boolean;
    showSnackBar: {show: boolean, message: string, subTitle?: string};
}

export enum DriverModalModeEnum {
    AddDriver = 1,
    AddVehicle = 2,
    UpdateVehicle = 3
}

export enum ServiceArea {
    Houston = 1,
    Dallas,
    Austin,
    SanAntonio,
}
export enum ServiceAreaName {
    Houston = 'Houston',
    Dallas = 'Dallas',
    Austin = 'Austin',
    SanAntonio = 'San Antonio'
}

export enum VehicleEnum {
    Sedan = 1,
    Suv,
    Pickup,
    Van
}
