import { Action } from 'redux';
import { PaginationState, SortDirection } from '../../model/my-orders.model';
import { HOME_LOAD_PAGE_SUCCESS, HOME_SET_PAGE_NUMBER, HomeAction } from '../../actions/home.actions';
import {UPDATE_PAGINATION, RESET_PAGINATION} from '../../actions/my-orders.actions';
import { SessionStorageUtil } from '../../../store/sessionStorage';
import { LOGOUT_SUCCESS } from '../../../auth/actions/auth.actions';

const sessionStorageUtil = SessionStorageUtil.getInstance();
const statuses = [
    [10],
    [20, 30, 35, 130],
    [50, 60, 61],
    [40, 70, 120]
];

export const initialPaginationState: PaginationState = {
    ...(new PaginationState()),
    pageSize: sessionStorageUtil.get('home.myOrders.pagination.pageSize') || 10,
    currentPageNumber: sessionStorageUtil.get('home.myOrders.pagination.currentPageNumber') || 1,
    sortDirection: sessionStorageUtil.get('home.myOrders.pagination.sortDirection') || SortDirection.Desk,
    filter: sessionStorageUtil.get('home.myOrders.pagination.filter') || {
        1: true, // draft
        2: true, // open/active
        3: true, // closed
        4: true, // blocked
    },
    statuses: statuses,
    sortField: sessionStorageUtil.get('home.myOrders.pagination.sortField') || 'CreationDateUtc',
    totalItemCount: 0,
    hasExceptions: false
};

export function paginationReducer(state = initialPaginationState, a: Action): PaginationState {
    const action = a as HomeAction;
    switch (action.type) {
        case HOME_LOAD_PAGE_SUCCESS: {
            return {
                ...state,
                totalItemCount: action.payload.pagedList.totalItemsCount
            };
        }
        case HOME_SET_PAGE_NUMBER: {
            return {
                ...state,
                currentPageNumber: action.payload
            };
        }

        case UPDATE_PAGINATION: {
            return {
                ...state,
                pageSize: action.payload.pageSize,
                currentPageNumber: action.payload.currentPageNumber,
                sortDirection: action.payload.sortDirection,
                filter: action.payload.filter ? action.payload.filter : {...state.filter},
                sortField: action.payload.sortField,
                hasExceptions: action.payload.hasExceptions
            };
        }

        case RESET_PAGINATION:
        case LOGOUT_SUCCESS: {
            return {
                ...state,
                pageSize: 10,
                currentPageNumber: 1,
                sortDirection: SortDirection.Desk,
                filter: { 1: true, 2: true, 3: true, 4: true},
                statuses: statuses,
                sortField: 'CreationDateUtc',
                totalItemCount: 0,
                hasExceptions: false
            };
        }

        default:
            return state;
    }
}
