import { Component, Input, OnInit, TemplateRef, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { OrderStop, StopStatus, OrderExpandedView } from '../../model/my-orders.model';
import { select } from '@angular-redux/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import { StopTypeId } from '../../model/order-events.model';
import { OrderStatusId, ShipperProfileDto } from '../../../core/models/dto';
import { ShipperAdminActions } from '../../../admin/actions/shipper.admin.actions';
import { HomeActions } from '../../../home/actions/home.actions';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { OrderEventsActions } from 'app/home/actions/order-events.action';
import { Observable, Subject } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
  selector: 'tuya-order-summary-item',
  templateUrl: './order-summary-item.component.html',
  styleUrls: ['./order-summary-item.component.scss'],
  providers: [DatePipe]
})

export class OrderSummaryItemComponent implements OnInit, OnChanges, OnDestroy {
  @select(['auth', 'currentUser', 'adminProfile'])
  readonly adminProfile$: Observable<ShipperProfileDto>;
  @select(['admin', 'shipperAdmin', 'isLoading'])
  readonly isLoading$: Observable<boolean>;

  @Input() stopItem: OrderStop;
  @Input() last: boolean;
  @Input() order: OrderExpandedView;

  dialogRef: BsModalRef;
  undoRef: BsModalRef;
  translator: TranslateService;

  expanded = false;
  stopStatus;
  adminUser;
  stopDisabledList = {};
  obs$ = new Subject();
  firstStopUndoText = 'tuya-my-orders.common-summary-text.undo-btn-tooltip-inprogress';
  lastStopUndoText = 'tuya-my-orders.common-summary-text.undo-btn-tooltip-assigned';

  // Admin Modal Init
  dateFormat = 'M/D/YYYY';
  timeFormat = 'h:mma';
  timeFormat24 = 'H:mm';
  get maxDate () {
    return new Date();
  }
  get minDate () {
    return new Date(this.order.creationDate);
  }
  adminShowTimeList = false;
  adminShowDatePicker = false;
  timeOptions = (() => {
    const timeArr = [];
    timeArr.push('12:00am', '12:30am');
    for (let x = 1; x < 12; x++) {
      timeArr.push(x + ':00am', x + ':30am');
    }
    timeArr.push('12:00pm', '12:30pm');
    for (let x = 1; x < 12; x++) {
      timeArr.push(x + ':00pm', x + ':30pm');
    }
    return timeArr;
  })();

  // Admin Form Init
  adminInit: any = {
    date: new Date(),
    time: moment().format(this.timeFormat),
    comments: ''
  };
  adminForm = new UntypedFormGroup({
    date: new UntypedFormControl(this.adminInit.date, [
      Validators.required
    ]),
    time: new UntypedFormControl(this.adminInit.time, [
      Validators.required,
      Validators.pattern('(((1[0-2]|[1-9])):([0-5][0-9])([ap][m]))|((2[0-3]|1[3-9]):([0-5][0-9]))')
    ]),
    comments: new UntypedFormControl(this.adminInit.comments, [])
  }, this.validateDateLogic.bind(this));

  get adminFormDate() {
    return this.adminForm.controls['date'].value;
  }
  get adminFormTime() {
    return this.adminForm.controls['time'].value;
  }

  constructor(
    private _datePipe: DatePipe,
    private modalService: BsModalService,
    private shipperAdminActions: ShipperAdminActions,
    private homeActions: HomeActions,
    private translate: TranslateService,
    private orderEventsActions: OrderEventsActions,
    private bsDatePickerConfig: BsDatepickerConfig
  ) {
    this.stopStatus = StopStatus;
    this.adminProfile$.subscribe(val => {
      this.adminUser = val;
    });
    this.translator = translate;
    this.bsDatePickerConfig.dateInputFormat = this.dateFormat;
  }

  ngOnInit() {
    this.obs$.subscribe( res => {
      this.stopDisabledList = res;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.order) {
        this.order.stopList.forEach((stop, indx) => {
          if (indx > 0) {
            this.stopDisabledList[stop.stopId - 1] = stop.statusId === 30 ? true : false;
            this.stopDisabledList[stop.stopId] = false;
          } else {
            this.stopDisabledList[stop.stopId] = false;
          }
        });

      this.obs$.next(this.stopDisabledList);
    }
  }

  ngOnDestroy() {
    this.obs$.unsubscribe();
  }

  toggleExpanded(): void {
    this.expanded = ! this.expanded;
  }

  getWeightCopy(): string {
    let copy: string;
    let totalWeight = 0;
    this.stopItem.items.forEach( item => {
      totalWeight += item.weight * item.quantity;
    });
    this.translator.get(
        ['tuya-my-orders.stop-completed-arrival-text.weight-singular', 'tuya-my-orders.stop-completed-arrival-text.weight-plural'],
        {num: totalWeight})
        .subscribe((res: object) => {
            if (totalWeight === 1) {
                copy = res['tuya-my-orders.stop-completed-arrival-text.weight-singular'];
            } else {
                copy = res['tuya-my-orders.stop-completed-arrival-text.weight-plural'];
            }
        });
    return copy;
  }

  getTotalQuantity(): number {
    let total = 0;
    this.stopItem.items.forEach(item => {
      total += item.quantity;
    });
    return total;
  }

  getDateRange(): string {
    let dateRange: string;
    const startRangeDate = new Date(this.stopItem.pickupNoEarlerThan);
    const endRangeDate = new Date(this.stopItem.pickupNoLaterThan);

    // shorten the date range to DATE: TIME1 - TIME2 if same day
    if (startRangeDate.getDate() === endRangeDate.getDate()) {
        dateRange = this._datePipe.transform(this.stopItem.pickupNoEarlerThan , 'M/dd/yyyy')
            + ', ' + this._datePipe.transform(this.stopItem.pickupNoEarlerThan , 'shortTime')
            + ' - ' + this._datePipe.transform(this.stopItem.pickupNoLaterThan , 'shortTime') ;
    } else {
        dateRange = this._datePipe.transform(this.stopItem.pickupNoEarlerThan , 'M/dd/yyyy, h:mm a')
            + ' - ' + this._datePipe.transform(this.stopItem.pickupNoLaterThan , 'M/dd/yyyy, h:mm a');
    }
    return dateRange;
  }

  getClasses(): any {
    return {...this.getClassForSeparator(), ...this.getStopTypeIcon()};
  }

  getClassForSeparator(): any {
    return {'hideSeparator': this.last || this.expanded};
  }

  getStopTypeIcon(): any {
    switch (this.stopItem.typeId) {
        case StopTypeId.Pickup: {
            return {'pickup-icon': true};
        }
        case StopTypeId.Delivery: {
            return {'delivery-icon': true};
        }  
        default:
            return {};
    }
  }

  // Admin Undo Complete Stop
  onUndoStopCompletion(event: any, undoAdminModal: TemplateRef<any>) {
    event.stopPropagation();
    this.undoRef = this.modalService.show(undoAdminModal, { backdrop: false, ignoreBackdropClick: false });
  }

  failureUndoCallback() {
    this.undoRef.hide();
  }

  successUndoCallback(stopId: number) {
    this.undoRef.hide();
    const payload = {
      stopId: stopId,
      success: this.onUndoSucceed.bind(this)
    };
    this.shipperAdminActions.undoStopComlete(payload);
  }

  onUndoSucceed() {
    this.homeActions.loadRowInfo({id: this.order.orderId, collapsed: true});
    this.orderEventsActions.getAllOrderEvents(this.order.orderId);
  }

  // Admin Undo Complete Stop Disabled
  adminUndoStopDisabled(stopId: number) {
    if (!environment.production) console.debug(this.stopDisabledList[stopId]);
    return true;
  }

  // Admin Complete Stop Stuff
  adminCompleteStopDisabled(): boolean {
    return (
      this.order.statusId === OrderStatusId.Canceled ||
      this.order.assignments.hasAssignedDrivers === false ||
      this.stopItem.pickupItemsCompleted === false
    );
  }

  onAdminCompleteStop(event, template: TemplateRef<any>): void {
    event.stopPropagation();
    this.dialogRef =
      this.modalService.show(template, { backdrop: false, ignoreBackdropClick: true });
  }

  failureCallback() {
    this.dialogRef.hide();
  }

  successCallback() {
    this.dialogRef.hide();
    this.homeActions.loadRowInfo({id: this.order.orderId, collapsed: true});
    this.orderEventsActions.getAllOrderEvents(this.order.orderId);
  }

  onAdminTimeFocus(): void {
    this.adminShowTimeList = true;
  }

  onAdminTimeBlur(): void {
    this.adminShowTimeList = false;
  }

  onAdminDateToggle(): void {
    this.adminShowDatePicker = !this.adminShowDatePicker;
  }

  onAdminDateBlur(): void {
    this.adminShowDatePicker = false;  
  }

  onAdminSelectTime(e): void {
    this.adminForm.patchValue({time: e});
  }

  onAdminSubmit() {
    const dateTime = this.parseDateAndTimeTogether();
    const name = this.translator.instant('tuya-my-orders.order-event-role.admin');
    const payload = {
      stopId: this.stopItem.stopId,
      receiverName: name,
      stopNotes: this.adminForm.value.comments,
      timestamp: dateTime.toISOString(),
      success: this.successCallback.bind(this),
      failure: this.failureCallback.bind(this)
    };
    this.shipperAdminActions.completeStop(payload);
  }

  validateDateLogic() {
    // check for BEFORE creationDate / AFTER now
    // validator is constructed prior to data existing. need to check for this.order
    if (this.order && this.order.creationDate) {
      const creationDate = moment(this.order.creationDate);
      const now = moment();
      const enteredDateTime = this.parseDateAndTimeTogether();

      // check if entered time is before order creation or in future
      const tooEarly = enteredDateTime < creationDate;
      const tooLate = enteredDateTime > now;

      // check if entered time is before first stop completion
      let beforePickup;
      if (this.order.stopList[0] && this.order.stopList[0].completedTimeStamp) {
        const pickupDateTime = moment(this.order.stopList[0].completedTimeStamp);
        beforePickup = enteredDateTime <= pickupDateTime; // ensure it is AFTER pickup, in case event log doesn't handle ties well
      }

      // if any errors, return the error object
      if (tooEarly || tooLate || beforePickup) {
        return {
          dateLogic: {
            early: tooEarly,
            late: tooLate,
            beforePickup: beforePickup
          }
        };
      }
    }
    
    return null;
  }

  parseDateAndTimeTogether() {
    return moment(moment(this.adminForm.value.date).format(this.dateFormat) + ',' + this.adminForm.value.time,
      [this.dateFormat + ',' + this.timeFormat, this.dateFormat + ',' + this.timeFormat24]);
  }
}
