import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthActions } from '../actions/auth.actions';
import { API_GET_PARAM_RESET_PASSWORD_TOKEN } from '../../core/constants/api.constants';

@Injectable()
export class ForgotPasswordGuard implements CanActivate {

    constructor(private authActions: AuthActions) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const token = route.queryParams[API_GET_PARAM_RESET_PASSWORD_TOKEN];
        if (!token) {
            return true;
        } else {
            this.authActions.passwordResetRedirect(token);
            return false;
        }
    }
}
