import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({name: 'formatNotificationTemplate'})
export class FormatNotificationTemplatePipe implements PipeTransform {
    transform(value: string, args?: any[]): string {
        for (const key of Object.keys(args)) {
            if (key === 'firstStopPickUpDateTime' || key === 'finalStopPickUpDateTime') {
                args[key] = new DatePipe('en-US').transform(new Date(args[key]), 'M/dd/yyyy, h:mm a');
            }
            value = value.replace('{{' + key + '}}', '<strong>' + args[key] + '</strong>');
        }
        return value;
    }
}
