import { StopDetailsFormComponent } from './../stop-details-form/stop-details-form.component';
import { OrderItemVo } from './../../../models/order.model';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'tuya-stop-return-items',
  templateUrl: './stop-return-items.component.html',
  styleUrls: ['./stop-return-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopReturnItemsComponent extends StopDetailsFormComponent {
  @Input() isFormPristine = true;
  @Input() disabled;
  @Input() returnItem: OrderItemVo;
  @Output() saveReturnItemCount = new EventEmitter<number>();

  public isCollapsed = true;

  constructor () {
    super();
  }

  onIncrementReturnItems() {
    this.returnItem.piecesCount = this.returnItem.piecesCount + 1;
    this.saveReturnItemCount.emit(this.returnItem.piecesCount);
  }
  onDecrementReturnItems() {
    if (this.returnItem.piecesCount > 0) {
      this.returnItem.piecesCount = this.returnItem.piecesCount - 1;
      this.saveReturnItemCount.emit(this.returnItem.piecesCount);
    }
  }

}
