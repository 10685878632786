import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
    selector: '[tuyaOffClick]'
})

export class OffClickDirective implements OnInit, OnDestroy {
    @Output()
    public tuyaOffClick = new EventEmitter();

    public eventHandler: any;

    constructor(
        private elementRef: ElementRef) {}

    public ngOnInit() {
        this.eventHandler = ($event: any) => {
            $event.stopPropagation();
            if (!this.elementRef.nativeElement.contains($event.target)) {
                this.tuyaOffClick.emit(null);
            }
        };

        document.addEventListener('click', this.eventHandler);
    }

    public ngOnDestroy() {
        document.removeEventListener('click', this.eventHandler);
    }
}
