import { Action } from 'redux';
import { IBillingState } from '../model/model';
import {
    BillingAction,
    GET_PAYMENT_METHODS,
    GET_PAYMENT_METHODS_SUCCESS,
    GET_PAYMENT_METHODS_FAIL,
    ADD_CREDIT_CARD_SUCCESS,
    ADD_CREDIT_CARD_FAILURE,
    OPEN_PAYMENT_MODAL,
    CLOSE_PAYMENT_MODAL,
    TURN_ON_VALIDATE_CC,
    TURN_OFF_VALIDATE_CC
} from '../actions/billing.actions';
import { LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN } from '../../admin/actions/shipper.admin.actions';
import { TURN_OFF_ALL_SPINNERS } from '../../core/actions/alert.actions';
import { LOGOUT_SUCCESS } from 'app/auth/actions/auth.actions';


export function initialState(): IBillingState {
    return {
        paymentMethods: [],
        isGettingPaymentMethods: false,
        isPaymentModalOpen: false,
        isLoading: false
    };
}


export function billingReducer(state = initialState(), a: Action): IBillingState {
    const action = a as BillingAction;
    switch (action.type) {
        case GET_PAYMENT_METHODS: {
            return {
                ...state,
                isGettingPaymentMethods: true
            };
        }

        case GET_PAYMENT_METHODS_SUCCESS: {
            return {
                ...state,
                paymentMethods: action.payload,
                isGettingPaymentMethods: false
            };
        }

        case GET_PAYMENT_METHODS_FAIL: {
            return {
                ...state,
                isGettingPaymentMethods: false
            };
        }

        case LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN: {
            return {
                ...initialState()
            };
        }

        case ADD_CREDIT_CARD_SUCCESS: {
            return {
                ...state,
                isLoading: false
            };
        }

        case ADD_CREDIT_CARD_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        case OPEN_PAYMENT_MODAL: {
            return {
                ...state,
                isPaymentModalOpen: true
            };
        }

        case CLOSE_PAYMENT_MODAL: {
            return {
                ...state,
                isPaymentModalOpen: false
            };
        }

        case TURN_ON_VALIDATE_CC: {
            return {
                ...state,
                isLoading: true
            };
        }

        case TURN_OFF_VALIDATE_CC: {
            return {
                ...state,
                isLoading: false
            };
        }

        case TURN_OFF_ALL_SPINNERS: {
            return {
                ...state,
                isGettingPaymentMethods: false,
                isLoading: false
            };
        }

        case LOGOUT_SUCCESS: {
            return {
                ...initialState()
            };
        }

        default: {
            return state;
        }
    }
}
