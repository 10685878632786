import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'tuya-create-pool-popup',
  templateUrl: './create-pool-popup.component.html',
  styleUrls: ['./create-pool-popup.component.scss']
})
export class CreatePoolPopupComponent implements OnInit {

  @ViewChild('poolInput', {static: true}) poolInput: ElementRef;
  poolName = '';

  constructor(public dialogRef: MatDialogRef<CreatePoolPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.focus();
  }

  onClosePopup() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.poolName);
  }

  focus() {
    if (!this.poolInput) {
      return;
    }
    setTimeout(() => {
      this.poolInput.nativeElement.focus();
    }, 0);
  }

}
