import { combineReducers } from 'redux';
import { paginationReducer } from './pagination.reducer';
import { ordersTableReducer } from './orders-table.reducer';
import { ordersDetailsReducer } from './orders-details.reducer';
import { rateReasonsReducer } from './rate-reasons.reducer';
import { orderEventsReducer } from './order-events.reducer';

export let myOrdersReducer = combineReducers({
    pagination: paginationReducer,
    ordersTable: ordersTableReducer,
    ordersDetails: ordersDetailsReducer,
    rateReasons: rateReasonsReducer,
    orderEvents: orderEventsReducer
});

