<!-- Container Holding the Event -->
<div class="event-wrapper">
    <div class="event-overview-container" [ngClass]="{'expandable': expandable(), 'expanded': expanded}"
        (click)="expandable() && toggleExpanded()">
        <div class="event-arrow-container">
            <div class="event-arrow" *ngIf="expandable()"></div>
        </div>
        <ng-container
            *ngIf="eventItem.eventTypeId === EventTypes.OrderEvent; then orderEvent; else stopEvent"></ng-container>
    </div>
    <div class="event-details-container" *ngIf="expanded">
        <ng-container
            *ngIf="eventItem.eventTypeId === EventTypes.StopEvent && eventItem.eventActionId === StopEventActions.Complete; then stopCompletedDetails"></ng-container>
        <ng-container
            *ngIf="eventItem.eventTypeId === EventTypes.StopEvent && eventItem.eventActionId === StopEventActions.Departure; then stopAttemptedDetails"></ng-container>
        <ng-container
            *ngIf="eventItem.eventTypeId === EventTypes.StopEvent && eventItem.eventActionId === StopEventActions.ItemException; then itemExceptionDetails"></ng-container>            
    </div>
</div>

<!-- Order Event -->
<ng-template #orderEvent>
    <div class="event-info event-icon" [ngClass]="getIcon()">
        <div *ngIf="eventItem.eventActionId != OrderEventActions.OfferCancelled; else cancelled">
            <div class="event-header">
                <!-- left side -->
                <div class="event-title">{{OrderEventTitles[eventItem.eventActionId]?.value | translate}}</div>

                <!-- right side -->
                <div class="event-time">{{eventItem.date | date: 'M/dd/yyyy, h:mm a'}}</div>
            </div>

            <div class="event-body">
                <!-- left side -->
                <div>
                    <!-- attempted switch statement start -->
                    <div [ngSwitch]="eventItem.eventActionId">
                        <div *ngSwitchCase="OrderEventActions.OfferConvertedGeneral" class="event-subtitle">
                            <span>{{ eventItem.subtitle }}</span>
                        </div>

                        <div *ngSwitchCase="OrderEventActions.CallToAction" class="event-subtitle">
                            <span>{{ 'tuya-my-orders.order-info.call-to-action' | translate }}</span>
                            <ng-container *ngIf="orderId && !isEditOrderDisabled()">
                                &nbsp;
                                <a *ngIf="orderId" routerLink="/builder" [queryParams]="{'orderId': orderId}"
                                    [translate]="'tuya-my-orders.driver-info.edit-order-link'">
                                </a>.
                            </ng-container>
                        </div>

                        <!-- Offer Accepted -->
                        <div *ngSwitchCase="OrderEventActions.OfferAccepted" class="event-subtitle">
                            <span>{{ eventItem.actor }}</span>
                        </div>               

                        <div *ngSwitchCase="OrderEventActions.ReOffered" class="event-subtitle">
                            <span>{{ eventItem.subtitle }}</span>
                        </div>

                        <div *ngSwitchDefault>
                            {{eventItem.actor}}
                        </div>
                    </div>

                    <!-- attempted switch statement end -->
                    <div class="event-created" *ngIf="eventItem.eventActionId === OrderEventActions.OfferCreated">
                        {{'tuya-my-orders.order-info.created-on' | translate}}{{getCreatedContent() | translate}}</div>
                </div>
                <!-- right side -->
            </div>
        </div>
    </div>
</ng-template>

<!-- Shipper Cancels Order -->
<ng-template #cancelled>
    <div class="event-header">
        <!-- left side -->
        <div class="event-title">{{OrderEventTitles[eventItem.eventActionId]?.value | translate: {actor:
            OrderEventActorRoles[eventItem.actorRole]?.value | translate } }}</div>

        <!-- right side -->
        <div class="event-time">{{eventItem.date | date: 'M/dd/yyyy, h:mm a'}}</div>
    </div>
    <div class="event-body">
        <!-- left side -->
        <div *ngIf="eventItem.actorRole != AdminRole; else cancelledAdmin">
            <div class="event-subtitle"><span>{{eventItem.actor}}</span></div>
            <div class="event-created" *ngIf="eventItem.eventActionId === OrderEventActions.OfferCreated">
                {{'tuya-my-orders.order-info.created-on' | translate}}{{getCreatedContent() | translate}}</div>
        </div>
        <!-- right side -->
    </div>
</ng-template>

<!-- Order Subtitles -->
<ng-template #cancelledAdmin>
    <div>
        <div class="event-subtitle-admin"><span>{{ 'tuya-my-orders.common-event-text.admin-comment' | translate
                }}</span></div>
        <div class="event-subtitle"><span>{{eventItem?.adminComment}}</span></div>
    </div>
</ng-template>

<!-- Stop Events -->
<ng-template #stopEvent>
    <div class="event-info event-icon" [ngClass]="getStopTypeIcon()">
        <div class="event-header">
            <!-- left event details -->
            <ng-container
                *ngIf="eventItem.eventActionId !== StopEventActions.Complete; then renderStopEventTitle; else renderStopEventTitleCompleted"></ng-container>

            <!-- right event status -->
            <div class="event-time">{{eventItem.date | date: 'M/dd/yyyy, h:mm a'}}</div>
        </div>

        <div class="event-body">
            <!-- left side -->
            <ng-container [ngSwitch]="eventItem.eventActionId">
                <!-- Driver Arrived -->
                <div *ngSwitchCase="StopEventActions.Arrive" class="event-subtitle">
                    <span *ngIf="eventItem.stopTypeId === StopTypes.Pickup">{{
                        'tuya-my-orders.stop-event-text.arrival-pickup' | translate: {driver: eventItem.actor, location:
                        eventItem.title} }}</span>
                    <span *ngIf="eventItem.stopTypeId === StopTypes.Delivery">{{
                        'tuya-my-orders.stop-event-text.arrival-delivery' | translate: {driver: eventItem.actor,
                        location: eventItem.title} }}</span>
                </div>

                <!-- cancel arrival -->
                <div *ngSwitchCase="StopEventActions.CancelArrival" class="event-subtitle">
                    <span>{{ 'tuya-my-orders.stop-event-text.arrival-undo' | translate: {driver: eventItem.actor, location: eventItem.title}
                        }}</span>
                </div>

                <!-- complete arrival -->
                <div *ngSwitchCase="StopEventActions.Complete" class="event-subtitle">
                    <span>{{ itemText }}</span>
                </div>

                <!-- Item Exception -->
                <div *ngSwitchCase="StopEventActions.ItemException" class="event-subtitle">
                    <span>{{ 'tuya-my-orders.stop-event-text.item-exception' | translate: {driver: eventItem.actor,
                        location: eventItem.title} }}</span>
                </div>

                <!-- attempted arrival -->
                <div *ngSwitchCase="StopEventActions.Departure">
                    <div class="event-subtitle">
                        <span *ngIf="eventItem.stopTypeId === StopTypes.Pickup">{{
                            'tuya-my-orders.stop-event-text.arrival-attempted-pickup' | translate: {driver:
                            eventItem.actor, location: eventItem.title} }}</span>
                        <span *ngIf="eventItem.stopTypeId === StopTypes.Delivery">{{
                            'tuya-my-orders.stop-event-text.arrival-attempted-delivery' | translate: {driver:
                            eventItem.actor, location: eventItem.title} }}</span>
                    </div>
                    <div *ngIf="eventItem.departureTypeId" class="event-reason" [ngSwitch]="eventItem.departureTypeId">
                        <span
                            *ngSwitchCase="DepartureTypes.NoOneHere">{{'tuya-my-orders.stop-event-departure-text.no-one-here'
                            | translate }}</span>
                        <span
                            *ngSwitchCase="DepartureTypes.CantAccess">{{'tuya-my-orders.stop-event-departure-text.cant-access'
                            | translate }}</span>
                        <span *ngSwitchCase="DepartureTypes.Other">{{'tuya-my-orders.stop-event-departure-text.other' |
                            translate }}</span>
                        <span
                            *ngSwitchCase="DepartureTypes.OrderCancelled">{{'tuya-my-orders.stop-event-departure-text.order-cancelled'
                            | translate }}</span>
                    </div>
                </div>
            </ng-container>

            <!-- right side: if delivery and arrival complete, show "completed" -->
            <div class="completed-via-admin">
                <div *ngIf="eventItem.eventActionId === StopEventActions.Complete" class="event-status">{{
                    'tuya-my-orders.stop-event-text.completed' | translate }}</div>

                <div *ngIf="eventItem.eventActionId === StopEventActions.ItemException" class="exception-status">{{
                    'tuya-my-orders.stop-event-text.exception' | translate }}</div>

                <span class="via-admin-text" *ngIf="eventItem.actorRole === AdminRole">{{
                    'tuya-my-orders.stop-event-text.via-admin' | translate }}</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- Stop Event Titles -->
<ng-template #renderStopEventTitle>
    <div class="event-title">{{StopEventTitles[eventItem.eventActionId]?.value | translate}}</div>
</ng-template>

<ng-template #renderStopEventTitleCompleted>
    <div class="event-title">
        <span *ngIf="eventItem.stopTypeId === StopTypes.Pickup">{{'tuya-my-orders.stop-event.stop-pickup-completed' |
            translate}}</span>
        <span *ngIf="eventItem.stopTypeId === StopTypes.Delivery">{{'tuya-my-orders.stop-event.stop-delivery-completed'
            | translate}}</span>
    </div>
</ng-template>

<!-- Expanded Details Stop Attempted -->
<ng-template #itemExceptionDetails>
    <div class="event-details">
        <div class="driver-comments-container">
            <div class="details-label">{{'tuya-my-orders.common-event-text.driver-comments-label' | translate }}</div>
            <div class="details-content">
                <span *ngIf="eventItem.stopComments; else emptyContent">{{ eventItem.stopComments }}</span>
            </div>
        </div>
        <div class="driver-photos-container">
            <div class="details-label">{{'tuya-my-orders.common-event-text.driver-photos-label' | translate }}</div>
            <div class="details-content driver-photos-list">
                <div *ngIf="eventItem.files && eventItem.files.length; else emptyContent">
                    <a *ngFor="let photo of eventItem.files|slice:0:3" [href]="photo" target="_blank">
                        <div class="driver-photo" [ngStyle]="{'background-image':'url(' + photo +')'}"></div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Expanded Details Stop Attempted -->
<ng-template #stopAttemptedDetails>
    <div class="event-details">
        <div class="driver-comments-container">
            <div class="details-label">{{'tuya-my-orders.common-event-text.driver-comments-label' | translate }}</div>
            <div class="details-content">
                <span *ngIf="eventItem.stopComments; else emptyContent">{{ eventItem.stopComments }}</span>
            </div>
        </div>
        <div class="driver-photos-container">
            <div class="details-label">{{'tuya-my-orders.common-event-text.driver-photos-label' | translate }}</div>
            <div class="details-content driver-photos-list">
                <div *ngIf="eventItem.files && eventItem.files.length; else emptyContent">
                    <a *ngFor="let photo of eventItem.files|slice:0:3" [href]="photo" target="_blank">
                        <div class="driver-photo" [ngStyle]="{'background-image':'url(' + photo +')'}"></div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Expanded Details Stop Completed -->
<ng-template #stopCompletedDetails>
    <div class="event-details">
        <div class="admin-completed-stop" *ngIf="eventItem.actorRole === AdminRole">
            {{'tuya-my-orders.stop-completed-arrival-text.admin-completed-stop' | translate }}</div>
        <div class="details-label" *ngIf="eventItem.actorRole != AdminRole; else adminCommentLabel">
            {{'tuya-my-orders.common-event-text.driver-comments-label' | translate }}</div>
        <div class="details-content">
            <span *ngIf="eventItem.stopComments; else adminComment">{{ eventItem.stopComments }}</span>
        </div>
        <div>
            <div class="details-label">{{'tuya-my-orders.stop-completed-arrival-text.stop-contact-name-label' |
                translate }}</div>
            <div class="details-content">
                <span *ngIf="eventItem.receiverName; else emptyContent">{{ eventItem.receiverName }}</span>
            </div>
        </div>
        <div>
            <div class="details-label">{{'tuya-my-orders.stop-completed-arrival-text.scheduled-arrival-time-label' |
                translate }}</div>
            <div class="details-content">
                <span *ngIf="eventItem.pickupNoEarlierThan && eventItem.pickupNoLaterThan; else emptyContent">{{
                    getDateRange() }}</span>
            </div>
        </div>
        <div>
            <div class="details-label">{{'tuya-my-orders.stop-completed-arrival-text.actual-arrival-time-label' |
                translate }}</div>
            <div class="details-content">
                <span *ngIf="eventItem.date; else emptyContent">{{ eventItem.date | date: 'M/dd/yyyy, h:mm a' }}</span>
            </div>
        </div>
        <div>
            <div class="details-label">{{'tuya-my-orders.stop-completed-arrival-text.item-description-label' | translate
                }}</div>
            <div class="details-content">
                <span *ngIf="eventItem?.itemsDescription; else emptyContent">{{ eventItem.itemsDescription }}</span>
            </div>
        </div>
        <div>
            <div class="details-label">
                <span
                    *ngIf="eventItem.stopTypeId === StopTypes.Pickup">{{'tuya-my-orders.stop-completed-arrival-text.items-label-pickup'
                    | translate }}</span>
                <span
                    *ngIf="eventItem.stopTypeId === StopTypes.Delivery">{{'tuya-my-orders.stop-completed-arrival-text.items-label-delivery'
                    | translate }}</span>
            </div>
            <div class="details-content">
                <div *ngIf="eventItem.items && eventItem.items.length; else emptyContent">
                    <tuya-item-list [itemSizes]="eventItem.items"></tuya-item-list>
                </div>
            </div>
        </div>
        <div>
            <div class="details-label">{{'tuya-my-orders.stop-completed-arrival-text.receiver-signature-label' |
                translate }}</div>
            <div class="details-content">
                <!-- signature URL -->
                <div *ngIf="eventItem.signatureURI; else emptyContent">
                    <div class="signature" [ngStyle]="{'background-image': 'url(' + eventItem.signatureURI + ')'}">
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="details-label">{{'tuya-my-orders.common-event-text.driver-photos-label' | translate }}</div>
            <div class="details-content driver-photos-list">
                <div *ngIf="eventItem.files && eventItem.files.length; else emptyContent">
                    <a *ngFor="let photo of eventItem.files|slice:0:3" [href]="photo" target="_blank">
                        <div class="driver-photo" [ngStyle]="{'background-image': 'url(' + photo +')'}"></div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<!-- Copy for No Content -->
<ng-template #emptyContent>
    <span class="empty-text">{{ 'tuya-my-orders.common-event-text.no-content' | translate }}</span>
</ng-template>

<!-- Copy for Admin Comment Label -->
<ng-template #adminCommentLabel>
    <div class="details-label">{{'tuya-my-orders.common-event-text.admin-comments-label' | translate }}</div>
</ng-template>

<!-- Copy for Admin Comment -->
<ng-template #adminComment>
    <span *ngIf="eventItem.adminComment; else emptyContent">{{ eventItem.adminComment }}</span>
</ng-template>