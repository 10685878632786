import { SessionStorageUtil } from '../../store/sessionStorage';

export interface IUiState {
    driverSelection: {
        driver: ISelectState,
        pool: ISelectState
    };
    accordion: {
        currentStep: number
    };
    isLoading: boolean;
    orderCode?: string;
    timeSelections: Array<number>;
    routificError: boolean;
    submitErrors: object;
    isEditMode: boolean;
    isDuplicateMode: boolean;
    waitTimePassed: boolean;
    orderEditMode: number;
    editOrder: {
        price: number;
        isInitPrice: boolean;
    };
    isEditOrderLoaded: boolean;
    navPromptRequired: boolean;
}

export interface ISelectState {
    searchTerm: string;
    searchResults: Array<any>;
    pending: boolean;
    selectedSearchResults: Array<any>;
}

export function getInitialUIstate(useDefault = false) {
    const instance = SessionStorageUtil.getInstance();
    return {
        driverSelection: {
            driver: {
                searchTerm: instance.get('builder.ui.driverSelection.driver.searchTerm', '', useDefault),
                searchResults: instance.get('builder.ui.driverSelection.driver.searchResults', [], useDefault),
                pending: false,
                selectedSearchResults: instance.get('builder.ui.driverSelection.driver.selectedSearchResults', [], useDefault)
            },
            pool: {
                searchTerm: instance.get('builder.ui.driverSelection.pool.selectedSearchResults', null, useDefault),
                searchResults: instance.get('builder.ui.driverSelection.pool.searchResults', [], useDefault),
                pending: false,
                selectedSearchResults: instance.get('builder.ui.driverSelection.pool.selectedSearchResults', [], useDefault)
            }
        },
        accordion: {
            currentStep: instance.get('builder.ui.accordion.currentStep', 0, useDefault)
        },
        isLoading: false,
        orderCode: instance.get('builder.ui.orderCode', null, useDefault),
        timeSelections: instance.get('builder.ui.timeSelections', [1], useDefault),
        routificError: instance.get('builder.ui.routificError', false, useDefault),
        submitErrors: instance.get('builder.ui.submitErrors', {}, useDefault),
        isEditMode: instance.get('builder.ui.isEditMode', false, useDefault),
        isDuplicateMode: instance.get('builder.ui.isDuplicateMode', false, useDefault),
        waitTimePassed: instance.get('builder.ui.waitTimePassed', false, useDefault),
        orderEditMode: instance.get('builder.ui.orderEditMode', 0, useDefault),
        editOrder: {
            price: instance.get('builder.ui.editOrder.price', 0, useDefault),
            isInitPrice: instance.get('builder.ui.editOrder.isInitPrice', true, useDefault)
        },
        isEditOrderLoaded: instance.get('builder.ui.isEditOrderLoaded', false, useDefault),
        navPromptRequired: instance.get('builder.ui.navPromptRequired', false, useDefault)
    };
}
