<div class="driver-wrapper">

  <div class="title">Driver Portal</div>

  <div class="action-container">
    <div class="left">
      <button mat-raised-button class="addDriver"
              (click)="onAddNewDriver(true, null)"
              *ngIf="(!(isLoading$ | async) || (drivers$ | async)?.length !== 0) && (searchQuery$ | async) === ''">
              Add New Driver
      </button>

      <h1 *ngIf="!(isSearching$ | async) && (searchQuery$ | async) !== ''">
          {{ 'tuya-my-orders.table-headers.searcHHeader' | translate }}
          <p>
              <!-- result equals 0 or less than page size -->
              <b *ngIf="(totalItemCount$ | async) === 0 || (totalItemCount$ | async) <= (pageSize$ | async)">{{totalItemCount$ | async}}</b>
              <!-- result greater than page size -->
              <b *ngIf="(totalItemCount$ | async) > (pageSize$ | async)">
                  {{(pageSize$ | async)*(currentPageNumber$ | async) - (pageSize$ | async) + 1}}-
                  <span *ngIf="(pageSize$ | async)*(currentPageNumber$ | async) > (totalItemCount$ | async)">{{(totalItemCount$ | async)}}</span>
                  <span *ngIf="(pageSize$ | async)*(currentPageNumber$ | async) <= (totalItemCount$ | async)">{{(pageSize$ | async)*(currentPageNumber$ | async)}}</span>
              </b>
              <!-- result greater than page size -->
              <span *ngIf="(totalItemCount$ | async) > (pageSize$ | async)"> of over <b>{{(totalItemCount$ | async)}}</b> </span>
              <!-- result equal to 0 or 1-->
              <span *ngIf="(totalItemCount$ | async) === 0 || (totalItemCount$ | async) === 1"> result </span>
              <span *ngIf="(totalItemCount$ | async) !== 0 && (totalItemCount$ | async) !== 1"> results </span>
              for <b>"{{ (searchQuery$ | async) }}"</b>
          </p>
      </h1>
    </div>

    <!-- driver search input -->
    <tuya-order-search-input class="drive-search-input"
             [form]="isChanged"
             [searchVal]="(searchQuery$ | async)"
             [placeholder]="'Search for Driver Name'"
             (onClearEvent)="onClear()"
             class="right"
             (onSearchEvent)="onSearch($event)"></tuya-order-search-input>
  </div>

  <div *ngIf="(isLoading$ | async)">
    <tuya-spinner size="fullscreen" [clear]="true"></tuya-spinner>
  </div>

  <tuya-progress-bar *ngIf="(isSearching$ | async) && (searchQuery$ | async) !== ''"
                    [progressBarVal]="(progressBarVal$ | async)"></tuya-progress-bar>


  <table mat-table [dataSource]="(drivers$ | async)"
         class="driver-table"
         *ngIf="(drivers$ | async)?.length !== 0 && !(isSearching$ | async)">
    
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Driver Name </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'disabled': !element.active}"> {{ element.name }} </td>
      </ng-container>
    
      <!-- Driver Code Column -->
      <ng-container matColumnDef="driverCode">
        <th mat-header-cell *matHeaderCellDef> Driver Code </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'disabled': !element.active}"> {{element.driverCode}} </td>
      </ng-container>
    
      <!-- Phone Number Column -->
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef> Phone Number </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'disabled': !element.active}"> {{element.phoneNumber}} </td>
      </ng-container>
    
      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email Address</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'disabled': !element.active}"> {{element.email}} </td>
      </ng-container>

        <!-- Service Area Column -->
        <ng-container matColumnDef="serviceArea">
        <th mat-header-cell *matHeaderCellDef> Service Area </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'disabled': !element.active}"> {{ serviceAreaNameEnum[serviceAreaEnum[element.serviceArea]] }} </td>
      </ng-container>

      <!-- Vehicle Type Column -->
      <ng-container matColumnDef="vehicleType">
        <th mat-header-cell *matHeaderCellDef> Vehicle Type </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'disabled': !element.active}" style> 
            {{ vehicleEnum[element.vehicle?.vehicleType] }}
          <span *ngIf="!element.vehicle">
            <mat-icon>maximize</mat-icon>
          </span>
        </td>
      </ng-container>

      <!-- Status Column --> 
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"> 
            <button mat-button color="primary"
                    class="action-buttons"
                    (click)="onUpdateVehicle(element)"> 
                    <span *ngIf="element.vehicle">
                      Edit Vehicle
                    </span>
                    <span *ngIf="!element.vehicle">
                      Add Vehicle
                    </span>
                  </button>
            <span class="seperator"></span>
            <button mat-button color="primary"
                    class="action-buttons"
                    (click)="onResetPassword(element)"> Reset Password </button>
            <span class="seperator"></span>
            <button mat-button color="warn"
                    class="action-buttons"
                    (click)="onStatusChange(element.id, element.active)"
                    [ngStyle]="{'color': element.active ? '#cd202c' : '#41853e'}"> {{element.active ? 'Deactivate' : 'Activate'}} </button>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          (click)="selection.toggle(row)">
      </tr>
  </table>

  <mat-paginator [length]="(pagination$ | async).totalItemCount"
                  [pageSizeOptions]="[10, 15, 20]"
                  [pageSize]="(pagination$ | async).pageSize"
                  [pageIndex]="(pagination$ | async).currentPageNumber - 1"
                  (page)="onPaginateChange($event)"
                  *ngIf="(drivers$ | async)?.length !== 0 && !(isSearching$ | async)"
                  showFirstLastButtons></mat-paginator>

    <div class="no-order-found" *ngIf="!(isLoading$ | async) && (searchQuery$ | async) !== '' && (drivers$ | async)?.length === 0">
        <div class="title">We're Sorry!</div>
        <div class="description">We can’t seem to find any results that match your search for <b>"{{ (searchQuery$ | async) }}"</b></div>
        <div class="description">Try changing the parameters of the search and please try again.</div>
    </div>
</div>
