<div class="shipment-header">
    <div>
        <h5>{{getHeaderText() | translate}}</h5>
        <h2>{{headerFirstLevel | translate}}</h2>
    </div>

    <div class="shipment-header-additional-controls row">
        <div class="ref-id-label">{{ 'tuya-shipment.header.order-ref-id' | translate }}</div>
        <div class="value-container">
            <input *ngIf="canEdit" class="ref-id-input" 
                placeholder="Add Order Reference ID"
                maxlength="75"
                (keyup)="onChange($event)"
                [(ngModel)]="inputVal"
                #refID="ngModel"
                name="refID">
            <span *ngIf="!canEdit">{{ orderRefID }}</span>
        </div>
    </div>

</div>
