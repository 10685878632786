import { ModalDirective } from 'ngx-bootstrap/modal';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'tuya-save-modal',
    templateUrl: './save-modal.component.html',
    styleUrls: ['./save-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveModalComponent implements AfterViewInit {

    @Output() clearErrors = new EventEmitter<any>();

    @ViewChild('staticModal', {static: true}) staticModal: ModalDirective;

    constructor() {
    }

    ngAfterViewInit() {
        this.showModal();
        this.staticModal.onHide.subscribe(test => {
            this.clearErrors.emit();
        });
    }

    showModal() {
        this.staticModal.show();
    }

    onCloseModal() {
        this.staticModal.hide();
    }
}