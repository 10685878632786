<div class="builder-stop">
  <form [formGroup]="stopForm">

    <h3 
      class="builder-stop-header builder-stop-header--pickup"
      id="{{ idkey }}"
      *ngIf="stopType === StopType.Pickup">
        <div class="builder-stop-header-pickup-icon"></div>
          {{ 'builderInput.pickupHeader' | translate }}
    </h3>

    <h3
      class="builder-stop-header builder-stop-header--delivery"
      id="{{ idkey }}"
      *ngIf="stopType === StopType.Delivery">
        <div class="builder-stop-header-delivery-icon"></div>
          {{ 'builderInput.deliveryHeader' | translate }}
    </h3>

    <tuya-builder-accordion
      [expanded]="currentStep === stopIndex"
      [currentStep]="currentStep"
      [stepIndex]="stopIndex"
      [disabled]="isStopFormInvalid"
      [stopForm]="stopForm"
      [stop]="stop"
      [materialLocked]="materialLocked"
      [submitErrors]="submitErrors">

      <tuya-address-contact
        [idKey]="idkey"
        [stopType]="stopType"
        [stopIndex]="stopIndex"
        [stop]="stop"
        [materialLocked]="materialLocked"
        [addressContactForm]="stopForm.controls.addressContactForm">
      </tuya-address-contact>

      <tuya-time
        [idKey]="idkey"
        [stopType]="stopType"
        [stopTypeText]="stopTypeText"
        [stopTypeTextCapitalized]="stopTypeTextCapitalized"
        [groupName]="idkey + '-window'"
        [stop]="stop"
        [stopIndex]="stopIndex"
        [currentStep]="currentStep"
        [timeSelections]="timeSelections"
        [setTimesInState]="setTimesInState"
        [easyStartTime]="easyStartTime"
        [easyEndTime]="easyEndTime"
        [easyStartTimeDisplay]="easyStartTimeDisplay"
        [easyEndTimeDisplay]="easyEndTimeDisplay"
        [dateDropdownOptions]="dateDropdownOptions"
        [isEditMode]="isEditMode"
        [materialLocked]="materialLocked"
        [nonMaterialLocked]="nonMaterialLocked"
        [timeForm]="stopForm.controls.timeForm"
        [isScheduledOrder]="isScheduledOrder"
        [pickupDate]="pickupDate">
      </tuya-time>

      <tuya-item-selector
        *ngIf="stopType === StopType.Pickup"
        [stop]="stop"
        [stopIndex]="stopIndex"
        [materialLocked]="materialLocked"
        [nonMaterialLocked]="nonMaterialLocked"
        [items]="items"
        [itemSelectorForm]="stopForm.controls.itemSelectorForm">
      </tuya-item-selector>

      <tuya-instructions
        [idKey]="idkey"
        [stopType]="stopType"
        [stopIndex]="stopIndex"
        [stop]="stop"
        [instructionsForm]="stopForm.controls.instructionsForm"
        [addressContactForm]="stopForm.controls.addressContactForm">
      </tuya-instructions>

      <tuya-vehicle-selection *ngIf="stop.typeId === StopType.Pickup"
                              [items]="stopItemsForVehicleSelection$ | async"
                              [selectedVehicle]="vehicleType">
      </tuya-vehicle-selection>

    </tuya-builder-accordion>

  </form>

  <div *ngIf="currentStep > stopIndex" class="builder-stop-summary-info">
    <div *ngIf="stopType === StopType.Pickup" class="builder-stop-summary-info-left-side">
      <div class="builder-stop-summary-info-stop-name">{{ stop.nameOrDescription }}</div>
      <div class="builder-stop-summary-info-address">
        {{ stop.address.addressLine }}
        <!-- <span *ngIf="stop.address.suiteNumber">#{{ stop.address.suiteNumber }}</span> -->
          <!--<span>{{ stop.address.city }},</span>-->
          <!--<span>{{ stop.address.stateName }}</span>-->
          <!--<span>{{ stop.address.postalCode }}</span>-->
      </div>
      <div class="builder-stop-summary-info-date-time">{{ stop.pickupNoEarlierThan | date: 'M/dd/yyyy' }} | 
        <span *ngIf="stop.pickupDateTypeId === 1">{{ 'builderTime.readyNow' | translate }}</span>
        <span *ngIf="stop.pickupDateTypeId != 1">{{ stop.pickupNoEarlierThan | date: 'shortTime' }} - {{ stop.pickupNoLaterThan | date: 'shortTime' }}</span>
      </div>
    </div>
    <div *ngIf="stopType === StopType.Delivery" class="builder-stop-summary-info-left-side">
      <div class="builder-stop-summary-info-stop-name">{{ stop.nameOrDescription }}</div>
      <div class="builder-stop-summary-info-address">
          {{ stop.address.addressLine }}
          <!-- <span *ngIf="stop.address.suiteNumber">#{{ stop.address.suiteNumber }}</span> -->
          <!--<span>{{ stop.address.city }},</span>-->
          <!--<span>{{ stop.address.stateName }}</span>-->
          <!--<span>{{ stop.address.postalCode }}</span>-->
        </div>
      <div class="builder-stop-summary-info-date-time">{{ stop.pickupNoEarlierThan | date: 'M/dd/yyyy' }} | {{ stop.pickupNoEarlierThan | date: 'shortTime' }} - {{ stop.pickupNoLaterThan | date: 'shortTime' }}</div>
    </div>
    <div *ngIf="stopType === StopType.Pickup" class="builder-stop-summary-info-right-side">

      <h4 class="builder-stop-summary-info-quantity">{{ getItemQuantity() }}
        <span *ngIf="getItemQuantity() != 1">{{ 'builderSummaryItems.itemsPlural' | translate }}</span>
        <span *ngIf="getItemQuantity() === 1">{{ 'builderSummaryItems.itemSingular' | translate }}</span>
      </h4>

      <div class="builder-stop-summary-info-description">
        <span class="builder-stop-summary-size" *ngIf="getSizeTotal(1) != 0">
          {{ getSizeTotal(1) }}&nbsp;
          <span *ngIf="getSizeTotal(1) > 1">{{ 'builderSummaryItems.envelopesPlural' | translate }}</span>
          <span *ngIf="getSizeTotal(1) === 1">{{ 'builderSummaryItems.envelopeSingular' | translate }}</span>
          <span *ngIf="getSizeTotalGreaterThan(1)" class="builder-stop-summary-info-description-comma">,</span>
        </span>
        <span class="builder-stop-summary-size" *ngIf="getSizeTotal(2) != 0">{{ getSizeTotal(2) }}&nbsp;{{ 'builderSummaryItems.smallPackage' | translate }}
          <span *ngIf="getSizeTotalGreaterThan(2) > 0" class="builder-stop-summary-info-description-comma">,</span>
        </span>
        <span class="builder-stop-summary-size" *ngIf="getSizeTotal(3) != 0">{{ getSizeTotal(3) }}&nbsp;{{ 'builderSummaryItems.mediumPackage' | translate }}
          <span *ngIf="getSizeTotalGreaterThan(3)" class="builder-stop-summary-info-description-comma">,</span>
        </span>
        <span class="builder-stop-summary-size" *ngIf="getSizeTotal(4) != 0">{{ getSizeTotal(4) }}&nbsp;{{ 'builderSummaryItems.largePackage' | translate }}
          <span *ngIf="getSizeTotalGreaterThan(4)" class="builder-stop-summary-info-description-comma">,</span>
        </span>
        <span class="builder-stop-summary-size" *ngIf="getSizeTotal(5) != 0">{{ getSizeTotal(5) }}&nbsp;<span *ngIf="getSizeTotal(5) > 1">{{ 'builderSummaryItems.palletsPlural' | translate }}</span><span *ngIf="getSizeTotal(5) === 1">{{ 'builderSummaryItems.palletSingular' | translate }}</span>
        </span>
      </div>

    <div class="builder-stop-summary-info-weight">{{ getTotalWeight() }}
      <span>{{ 'builderSummaryItems.weightUOMSingular' | translate }}</span>
    </div>

    </div>
  </div>  
</div>  
