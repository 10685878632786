export class NotificationDto {
    id: any;
    type: number;
    messageBody: string;
    title: string;
    orderId: any;
}

export class NotificationBucketItemDto {
    id: any;
    title: string;
    template: string;
    payload: any;
    action: number;
    isRead: false;
    creationDate: string;
    highlightingTypeId: number;
}

export class NotificationBucketDto {
    items: NotificationBucketItemDto[];
    currentPageNumber: number;
    pageSize: number;
    totalItemCount: number;
}

export enum HighlightType {
    Default = 0,
    Success,
    Warning,
    Danger,
    Primary,
    Recurring
}
