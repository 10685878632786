<form class="simple-autocomplete {{ className }}" [ngClass]="{ inline: isInline }" (tuyaOffClick)="offClick()">
    <input type="text" 
        class="form-control address-input {{ className }}"
        [formControl]="searchControlTerm"
        [ngClass]="{ invalid: isInvalid, disabled: disable }"
        name="'builderAddressSearchField'"
        autocomplete="off"
        (keydown)="keydownEvent($event)"
        (focusin)="onFocusIn()"
        (focusout)="onFocusOut()"
        #searchText
        placeholder="{{ placeholder | translate }} *">
</form>