<div class="order-edit-confirm-dialog modal-body">
    <div class="message">
        <h3>{{ 'tuya-order-edit.confirm-dialog.message' | translate }}</h3>
    </div>
    <div class="price" *ngIf="ifShowPriceMessage()">
        {{oldPrice | currency:'USD':'symbol'}} <span class="ico"></span> <span class="bold">{{newPrice | currency:'USD':'symbol'}}</span>
    </div>

    <div class="btn-group">
        <button class="btn btn-primary btn-lg confirm-edit-order" (click)="onEditConfirm()">{{ 'tuya-common.btn.yes' | translate }}
        </button>
        <button class="btn  btn-default btn-lg discard-edit-order" (click)="onEditCancel();">{{ 'tuya-common.btn.no' | translate }}
        </button>
    </div>
</div>
