import { IAppState } from '../../store/model';
import { OrderItemVo, OrderVo, Stop } from '../models/order.model';
import { StopDto } from '../../core/models/dto';
import { AssignedDriverDto, SelectedCandidatesDto, SummaryDto, VehicleTypeDto } from '../../core/models/order.dto';
import { StopHelper } from '../utils/stop-helper.utis';

export class OrderSelectors {
    public static orderSelector = (state: IAppState): OrderVo => state.shipment.order;
    public static assignedDriversSelector = (state: IAppState): Array<AssignedDriverDto> => state.shipment.order.assignments.drivers;
    public static orderVehicleTypeSelector = (state: IAppState): VehicleTypeDto => state.shipment.order.vehicleType;
    public static orderStopListSelector = (state: IAppState): Stop[] => state.shipment.order.stopList;
    public static orderDeliveryTypeSelector = (state: IAppState): number => state.shipment.order.deliveryType;
    public static stopDetailsEditSelector = (state: IAppState): StopDto | null => state.shipment.ui.stopDetailsEdit;
    public static orderItemListSelector = (state: IAppState): OrderItemVo[] => state.shipment.order.itemList;
    public static orderCodeSelector = (state: IAppState): string => state.shipment.order.orderCode;
    public static orderIdSelector = (state: IAppState): any => state.shipment.order.id;
    public static orderTotalSelector = (state: IAppState): number => state.shipment.order.total;
    public static selectedStopIdSelector = (state: IAppState): any => state.shipment.order.selectedStopId;
    public static orderSummarySelector = (state: IAppState): SummaryDto => state.shipment.order.orderSummary;
    public static orderIsExtendableSelector = (state: IAppState): boolean => state.shipment.order.isExtendable;
    public static isDirectOrderSelector = (state: IAppState): boolean => state.shipment.order.isDirect;
    public static isHasValidCreditCard = (state: IAppState): boolean => state.shipment.order.hasValidCreditCard;
    public static selectedCandidatesSelector = (state: IAppState): SelectedCandidatesDto => state.shipment.order.selectedCandidates;
    public static firstStopSelector = (state: IAppState): StopDto => StopHelper.getFirstStop(state);
    public static isInitialPickupStop = (state: IAppState): boolean => {
        const firstPickupStop = StopHelper.getFirstStop(state);
        return state.shipment.order.selectedStopId === (firstPickupStop || <Stop>{}).id;
    }
}

