import { ValidatorFn, AbstractControl, Validators } from '@angular/forms';


export function phoneNumberLengthValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any} | null => {
        let phoneNumber = control.value;
        if (phoneNumber) {
            // on init, phoneNumber === null
            // run replace function only when phoneNumber !== null
            phoneNumber = phoneNumber.replace(/\(|\)|\ |\-|\_/g, '');
        }
        if (phoneNumber === null || phoneNumber.length === 0 || phoneNumber.length === 10) {
            return null;
        }
        return { 'phoneNumberLength': { value: phoneNumber.length } };
    };
}

export function optionalValidator(validators?: (ValidatorFn | null | undefined)[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        return control.value ? Validators.compose(validators)(control) : null;
    };
}

export function emailTldValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any} | null => {
        const tld = getTld(control.value);
        if (tld && isValidTld(tld)) {
            return null;
        }
        return { 'emailTld': { value: true } };
    };
}

function getTld(email: string) {
    const domain = email.split('@')[1];
    const domainArr = domain ? domain.split('.') : null;
    if (domainArr && domainArr.length > 1) {
        return domainArr[domainArr.length - 1];
    }
    return null;
}

function isValidTld(tld: string): Boolean {
    return tld.length >= 2 && /^[a-zA-Z]+$/.test(tld);
}
