<div class="back">
    <div class="wrapper">
        <div class="no-data">
            <div class="circle"></div>
            <h1 [translate]="'tuya-shipment.confirm-order.success-ok'"></h1>
            <p [translate]="'tuya-shipment.confirm-order.order-submitted'"></p>
            <p class="bold">{{'tuya-shipment.confirm-order.order-number' | translate }}: {{ orderCode }} </p>
        </div>
        <button class="btn btn-default btn-lg" (click)="onCreateNewOrder()"
                [translate]="'tuya-shipment.confirm-order.btn.new-order'"></button>
        <button class="btn btn-primary btn-lg" [translate]="'tuya-shipment.confirm-order.btn.order-details'"
                (click)="goToOrderDetails()">
        </button>
    </div>
</div>
