import { Attribute, Directive, forwardRef, Input, ElementRef, AfterContentInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[tuyaAutofocusDirective]',
})
export class AutofocusDirective implements AfterContentInit {

    @Input() public appAutoFocus: boolean;

    public constructor(private el: ElementRef) {
    }

    public ngAfterContentInit() {

        setTimeout(() => {
            this.el.nativeElement.focus();
        }, 500);

    }

}
