<div class="back">
    <div class="container">
        <div class="col-md-offset-1">
            <h1 [translate]="'tuya-shipment.payment-order.pay-shipment'"></h1>
        </div>
        <div class="col-md-9">
            <ul class="navigation">
                <li class="active">
                    <a [translate]="'tuya-shipment.payment-order.credit-card'"></a>
                </li>
            </ul>
            <form name="paymentOrderForm"
                  #paymentOrderForm="ngForm"
                  novalidate>
                <div>
                    <div class="radio-button">
                        <label class="control control-radio">
                            <span [translate]="'tuya-shipment.payment-order.add-new-card'"></span>
                            <input type="radio" name="paymentCard"
                                   [(ngModel)]="paymentCard"
                                   [value]="paymentCardType.NewCreditCard"/>
                            <div class="control_indicator_radio"></div>  <!-- temporary paymentCard model (only ui)-->
                        </label>
                    </div>
                    <div>
                        <!--<iframe width="650" height="600" [src]="paymentUrl" frameborder="0" name="payment" (load)="onPaymentFrameLoad($event)"></iframe>-->
                        <tuya-payment-frame (frameMsgEvt)="onPaymentFrameEvent($event)"></tuya-payment-frame>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
