<!-- Progress Bar Loader -->
<tuya-progress-bar *ngIf="(searchOrders$ | async).isSearching && (searchOrders$ | async).searchQuery !== ''"
    [progressBarVal]="(searchOrders$ | async).progressBarVal"></tuya-progress-bar>

<div class="sticky-header" [ngClass]="{'stickyHeader': applySticky}">
    <div class="table-header" *ngIf="(itemsList$ | async).length > 0">
        <!-- My orders result - table header -->
        <div class="header-container"
            *ngIf="!(searchOrders$ | async).isSearching && (searchOrders$ | async).searchQuery === ''">
            <h1 [translate]="'tuya-my-orders.table-headers.myOrders'"></h1>
            <div class="filter-container">
                <!-- Hide for now per ticket #21289 -->
<!--                 <div>
                    <label class="filter-radio">
                        <input type="checkbox" [(ngModel)]="hasExceptions" (change)="toggleHasException()">
                        <span [translate]="'tuya-my-orders.table-filters.has-exception'"></span>
                    </label>
                </div> -->

                <!-- My Order - Filter Buttons (DRAFT, OPEN, CLOSED, BLOCKED)-->
                <ul class="filter-list">
                    <li (click)="updateFilterList(statusFilterEnum.Draft)"
                        tooltip="{{'tuya-my-orders.table-filters.draft-tooltip' | translate}}"
                        [ngClass]="{'active': statusFilterList[statusFilterEnum.Draft]}"
                        [translate]="'tuya-my-orders.table-filters.draft'" data-placement="bottom"></li>
                    <li (click)="updateFilterList(statusFilterEnum.Open)"
                        tooltip="{{'tuya-my-orders.table-filters.open-tooltip' | translate}}"
                        [ngClass]="{'active': statusFilterList[statusFilterEnum.Open]}"
                        [translate]="'tuya-my-orders.table-filters.open'" data-placement="bottom"></li>
                    <li (click)="updateFilterList(statusFilterEnum.Closed)"
                        tooltip="{{'tuya-my-orders.table-filters.closed-tooltip' | translate}}"
                        [ngClass]="{'active': statusFilterList[statusFilterEnum.Closed]}"
                        [translate]="'tuya-my-orders.table-filters.closed'" data-placement="bottom"></li>
                    <li (click)="updateFilterList(statusFilterEnum.Blocked)"
                        tooltip="{{'tuya-my-orders.table-filters.blocked-tooltip' | translate}}"
                        [ngClass]="{'active': statusFilterList[statusFilterEnum.Blocked]}"
                        [translate]="'tuya-my-orders.table-filters.blocked'" data-placement="bottom"></li>
                </ul>
            </div>
        </div>

        <!-- Search results - table header -->
        <div class="header-container"
            *ngIf="!(searchOrders$ | async).isSearching && (searchOrders$ | async).searchQuery !== ''">
            <h1>
                {{ 'tuya-my-orders.table-headers.searcHHeader' | translate }}
                <p>
                    <!-- result equals 0 or less than page size -->
                    <b *ngIf="(totalItemCount$ | async) === 0 || (totalItemCount$ | async) <= (pageSize$ | async)">{{totalItemCount$
                        | async}}</b>
                    <!-- result greater than page size -->
                    <b *ngIf="(totalItemCount$ | async) > (pageSize$ | async)">
                        {{(pageSize$ | async)*(currentPageNumber$ | async) - (pageSize$ | async) + 1}}-
                        <span
                            *ngIf="(pageSize$ | async)*(currentPageNumber$ | async) > (totalItemCount$ | async)">{{(totalItemCount$
                            | async)}}</span>
                        <span
                            *ngIf="(pageSize$ | async)*(currentPageNumber$ | async) <= (totalItemCount$ | async)">{{(pageSize$
                            | async)*(currentPageNumber$ | async)}}</span>
                    </b>
                    <!-- result greater than page size -->
                    <span *ngIf="(totalItemCount$ | async) > (pageSize$ | async)"> of over <b>{{(totalItemCount$ |
                            async)}}</b> </span>
                    <!-- result equal to 0 or 1-->
                    <span *ngIf="(totalItemCount$ | async) === 0 || (totalItemCount$ | async) === 1"> result </span>
                    <span *ngIf="(totalItemCount$ | async) !== 0 && (totalItemCount$ | async) !== 1"> results </span>
                    for <b>"{{ (searchOrders$ | async).searchQuery }}"</b>
                </p>
            </h1>

            <!-- Search result orders - filter button -->
            <div class="filter-wrapper">
                <div class="filter-container">
                    <ul class="filter-list">
                        <li (click)="onSearchFilter()" class="searchFilter">
                            <div class="search-filter-icon"></div>
                            {{ 'tuya-my-orders.table-filters.searchFilter' | translate}}
                        </li>
                    </ul>
                </div>
                <tuya-search-filter-view *ngIf="(isFilterViewActive$ | async)"
                    class="filterView"></tuya-search-filter-view>
            </div>
        </div>
    </div>

    <tuya-filter-view [filterItems]="filterViews"
        *ngIf="filterViews.length && (searchQuery$ | async) !== '' && !(searchOrders$ | async).isSearching"
        (onRemoveFilterItem)="onRemoveFilter($event)"></tuya-filter-view>

    <tuya-order-action-btns (onActionClick)="onActionButtonEmit($event)" [actions]="(homeActionBtns$ | async)" *ngIf="!(isLoading$ | async) &&
                                    !(searchOrders$ | async).isSearching &&
                                    selectedOrderQty != 0"></tuya-order-action-btns>
</div>

<tuya-spinner size="recurrenceOrderId ? fullwindow : null" *ngIf="(isLoading$ | async) &&
                            !(searchOrders$ | async).isSearching &&
                            dialog.openDialogs.length === 0"></tuya-spinner>

<tuya-spinner size="fullwindow" *ngIf="(orderCancelLoading$ | async)"></tuya-spinner>

<div class="hide-wrapper" [hidden]="(((isLoading$ | async) && !recurrenceOrderId) || (searchOrders$ | async).isSearching) &&
                                        dialog.openDialogs.length === 0">
    <tuya-orders-table *ngIf="(itemsList$ | async).length > 0" [items]="itemsList$ | async" [headers]="tableHeaders"
        [expandedRows]="expandedRows$ | async" [totalItemCount]="totalItemCount$ | async"
        [currentPageNumber]="currentPageNumber$ | async" [isShipperSuspended]="isShipperSuspended$ | async"
        [sortDirection]="sortDirection$ | async" [pageSize]="pageSize$ | async" (loadPages)="loadPages()"
        (toggleRow)="onRowToggle($event)" (onOrderSelectEmit)="onOrderSelect($event)"
        (onEditRecurringOrder)="onRecurringOrderEdit($event)">
    </tuya-orders-table>

    <div class="no-data" *ngIf="(itemsList$ | async)?.length === 0 && (searchOrders$ | async).searchQuery === ''">
        <div class="circle"></div>
        <div class="no-data-top" [translate]="'tuya-my-orders.table-filters.noOrders'"></div>
        <div class="no-data-sub" [translate]="'tuya-my-orders.table-filters.noOrdersDescrpt'"></div>
    </div>

    <div class="no-order-found"
        *ngIf="!(searchOrders$ | async).isSearching && (searchOrders$ | async).searchQuery !== '' && (itemsList$ | async)?.length === 0">
        <div class="title">We're Sorry!</div>
        <div class="description">We can’t seem to find any results that match your search for <b>"{{ (searchOrders$ |
                async).searchQuery }}"</b></div>
        <div class="description">Try changing the parameters of the search and please try again.</div>
    </div>
</div>

<ng-template #cancelOrderModal>
    <div class="modal-body text-center">
        <h2>{{'tuya-my-orders.modals.cancelNotOne' | translate}}
            {{(canceledOrderInfo$ | async)?.driverName}}
            {{'tuya-my-orders.modals.cancelNotTwo' | translate}}
            {{(canceledOrderInfo$ | async)?.firstCopmletedStopName}}.</h2>
        <button type="button" class="btn btn-primary" (click)="hideModal()">{{'tuya-my-orders.modals.ok' |
            translate}}</button>
    </div>
</ng-template>