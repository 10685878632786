import { Action, combineReducers } from 'redux';

import { orderReducer } from './order.reducer';
import { uiReducer } from './ui.reducer';
import { IShipmentState } from '../models/model';
import { SHIPMENT_RESET, SHIPMENT_ORDER_ADD_REFERENCE_ID, ShipmentAction } from '../actions/shipment.actions';
import { composeReducers } from '@angular-redux/form';
import { generalOfferReducer } from './general-offer.reducer';
import { addressAutocompleteReducer } from './address-autocomplete.reducer';
import { paymentReducer } from './payment.reducer';
import { initialShipmentState } from './index';


export function orderShipmentReducer(state = initialShipmentState, a: Action): IShipmentState {
    switch (a.type) {
         // should reset all order-related states besides of ui.defaultItemSize
        case SHIPMENT_RESET: {
            return {
                ...initialShipmentState,
                ui: {
                    ...initialShipmentState.ui,
                    defaultItemSize: state.ui.defaultItemSize
                }
            };
        }
        case SHIPMENT_ORDER_ADD_REFERENCE_ID: {
            const action = a as ShipmentAction;
            return {
                ...state,
                order: {
                    ...state.order,
                    referenceId: action.payload
                }
            };
        }
        default: {
            return state;
        }
    }
}

export let shipmentReducer = composeReducers(
    orderShipmentReducer, // handles order-related states (order and ui) reset.
    paymentReducer,
    combineReducers({
        order: orderReducer,
        ui: uiReducer,
        generalOffer: generalOfferReducer,
        addressAutocomplete: addressAutocompleteReducer
    }));
