import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component } from '@angular/core';

@Component({
    selector: 'tuya-leave-unfinished-modal',
    templateUrl: 'leave-unfinished-modal.component.html',
    styleUrls: ['leave-unfinished-modal.component.scss']
})
export class LeaveUnfinishedModalComponent {
    subject: Subject<boolean>;

    constructor(public bsModalRef: BsModalRef) { }

    action(value: boolean) {
        this.bsModalRef.hide();
        this.subject.next(value);
        this.subject.complete();
    }
}
