<div *ngFor="let itemSize of itemSizes" class="item-size-row">
    <div class="item-row-left">
        <div>
            <div class="icon {{ getIcon(itemSize) }}">
            </div><div class="quantity">{{ getQuantityCopy(itemSize) }}</div>
        </div>
        <div class="dimensions">{{ itemSize.description }}</div>
    </div>
    <div class="divide-line"></div>
    <div class="item-row-right">
        <div class="weight">
            <span>{{'tuya-my-orders.stop-completed-arrival-text.total' | translate }}:</span>
            <span class="bold">{{ getWeightCopy(itemSize) }}</span>
        </div>
        <div class="ref-id-info">
            <span class="ref-id-label">{{'tuya-my-orders.stop-completed-arrival-text.ref-id' | translate }}:&nbsp;</span>
            <div class="ref-id" *ngIf="itemSize.referenceId; else noContent">{{ itemSize.referenceId }}</div>
        </div>
    </div>
</div>

<ng-template #noContent>
    <span class="no-content">{{ 'tuya-my-orders.common-event-text.no-content' | translate }}</span>
</ng-template>


