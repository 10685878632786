import { IAppState } from '../../store/model';
import { DriverDto, PoolDto, PoolSelectDto, CombinedPoolsDTO } from '../models/dto';

export const combinedPoolsSelector = ({pools, driversPools}, driverId) => {
    let combinedPools = [];

    if (pools && driversPools) {
        const assignedDriver = driversPools.find((driver) => driver.driverId === driverId);

        if (assignedDriver) {
            const assignedDriverPools = assignedDriver.pools;

            combinedPools = pools.map(({id, name}) => {
                const inPool = !!assignedDriverPools.find((driverPool) => driverPool.id === id);
                return {id, name, inPool};
            });

        } else {
            return null;
        }
    }

    return combinedPools;
};

export const isDriverInPool = (driverPools: PoolSelectDto[], shipperPoolId: number): boolean => {
    const id = driverPools.find(pool => pool.id === shipperPoolId);
    return id ? true : false;
};

export const combineShipperAndDrviverPools = (shipperPools: PoolDto[], driverPools: PoolSelectDto[], driverId: number):
    CombinedPoolsDTO[] => {
    const combinedPools = shipperPools.map(pool =>  {
        const driverInPool = isDriverInPool(driverPools, pool.id);
        return {poolId: pool.id, poolName: pool.name, driverInPool: driverInPool, newStatus: driverInPool};
    });
    return combinedPools;
};


export class DriversSelectors {
    public static driversSelector = (state: IAppState): Array<DriverDto> => state.core.pools.drivers;
    public static isDriversLoadingSelector = (state: IAppState): boolean => state.core.pools.isDriversLoading;
    public static getPools = (state: IAppState): Array<PoolDto> => state.core.pools.pools;
    public static isPoolsLoading = (state: IAppState): boolean => state.core.pools.isPoolsLoading;
}
