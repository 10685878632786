<div class="sidebar sidebar-with-footer" *ngIf="(wizardStep$ | async) === WizardStep.Stops">
    <div class="flex">
        <tuya-shipment-header
            [shipmentType]="orderDeliveryType$ | async"
            [isDirectOrder]="isDirectOrder$ | async"
            [editMode]="editMode$ | async"
            [orderRefID]="orderRefID$ | async"
            [orderEditRefID]="orderEditRefID$ | async"
            (directOrderUpdate)="onSetIsDirectOrder($event)"
        ></tuya-shipment-header>
        <div class="sidebar-content">
            <tuya-shipment-builder
                    [shipmentType]="orderDeliveryType$ | async"
                    [stopList]="orderStopList$ | async"
                    [editMode]="editMode$ | async"
                    [hasInvalidAddress]="hasInvalidAddress$ | async"
                    [orderItems]="orderItemList$ | async"
                    [invalidTimeframesStopsList]="invalidTimeframesStopsList$ | async"
                    [selectedStop]="currentStop$ | async"
                    [isDirectOrder]="isDirectOrder$ | async"
                    [isStopsRequiredFieldsProvided]="isStopsRequiredFieldsProvided$ | async"
                    (stopSelect)="onSelectStop($event)"
                    (removeStop)="onRemoveStop($event)"
                    (editStop)="onEditStop($event)"
                    (updateSuiteNumber)="onUpdateSuiteNumber($event)"
            ></tuya-shipment-builder>
        </div>
    </div>
    <tuya-shipment-footer
        [orderSummary]="orderSummary$ | async"
        [isLoading]="isLoadingOrderDetail$ | async"
    ></tuya-shipment-footer>
</div>

<div class="sidebar" *ngIf="(wizardStep$ | async) === WizardStep.EditStopDetails || (wizardStep$ | async) === WizardStep.InitialStopEdit">
    <tuya-shipment-stop-details-page></tuya-shipment-stop-details-page>
</div>
<tuya-spinner *ngIf="isLoading$ | async" size="fullscreen"></tuya-spinner>
