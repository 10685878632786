<div class="order-details-page">
    <div class="info-column info-container">
        <tuya-order-info-container [orderInfo]="activeOrderInfo$ | async" [isLoading]="isLoading$ | async"></tuya-order-info-container>
    </div>
    <div class="info-column driver-container">
        <tuya-driver-info-container *ngIf="(activeOrderInfo$ | async) && (activeOrderInfo$ | async).assignments"
                                    [order]="activeOrderInfo$ | async"
                                    [isShipperSuspended]="isShipperSuspended$ | async"
                                    [errorMessageKey]="errorMessageKey$ | async"
                                    [orderEventsList]="orderEventsList$ | async"
                                    [isLoading] = "isLoading$ | async">
        </tuya-driver-info-container>
    </div>
</div>
