import { BoxSizeTypes } from './../../../../shipment/models/order.model';
import { TrackingOrderItem } from './../../../models/tracking.model';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ItemsErrorTypes } from './../../../models/tracking.model';
import { environment } from 'environments/environment';

@Component({
    selector: 'tuya-stop-item-info',
    templateUrl: './stop-item-info.component.html',
    styleUrls: ['./stop-item-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopItemInfoComponent {
    @Input() public stopItem: TrackingOrderItem = <TrackingOrderItem>{};
    public boxSizeTypes: typeof BoxSizeTypes = BoxSizeTypes;

    constructor() { }

    getItemsErrorTypes() {
        if (!environment.production) console.debug('[Item Exception] ' + this.stopItem.errorReasonId);
        return ItemsErrorTypes[this.stopItem.errorReasonId];
    }
}