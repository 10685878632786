import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PasswordValidationVO } from '../../../auth/model/model';

@Component({
  selector: 'tuya-choose-new-password',
  templateUrl: './choose-new-password.component.html',
  styleUrls: ['./choose-new-password.component.scss']
})
export class ChooseNewPasswordComponent implements OnInit {
  model: any = {};

  @Input() validationResult: PasswordValidationVO | null;
  @Output() onPasswordSubmit = new EventEmitter<string>();

  constructor() { }

  ngOnInit() { }

  onSubmit() {
    this.onPasswordSubmit.emit(this.model.password);
  }
}