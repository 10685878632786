import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'tuya-price-optimization-modal',
    templateUrl: 'price-optimization-modal.component.html',
    styleUrls: ['price-optimization-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceOptimizationModalComponent implements AfterViewInit {

    @Input() public price = 0;
    @Input() public priceOptProccesingCompleted = false;
    @Input() public priceOptimized = false;
    @Input() public oldPrice = 0;
    @Input() public currentPrice = 0;
    @Input() public alreadyOptimizedPrice = false;
    @ViewChild('staticModal', {static: true}) staticModal: ModalDirective;
    @Output() public hideModal = new EventEmitter();

    constructor() {}

    ngAfterViewInit() {
        this._showModal();
    }

    public hideModalAsCallback() {
        this.hideModal.emit();
        this.staticModal.hide();
    }

    private _showModal() {
        this.staticModal.show();
    }

}
