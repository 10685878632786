import { Component, Input, OnInit } from '@angular/core';

export type SpinnerSize = 'small' | 'fullscreen' | 'fullcontained' | 'fullwindow';

@Component({
    selector: 'tuya-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

    @Input() size: SpinnerSize;
    @Input() clear: boolean;

    constructor() {
    }

    ngOnInit() {
    }

}
