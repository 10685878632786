import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectComponent } from './select';
import { HighlightPipe } from './select-pipes';
import { OffClickDirective } from './off-click';
import { SelectSpinnerComponent } from './select-spinner.component';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [SelectComponent, HighlightPipe, OffClickDirective, SelectSpinnerComponent],
    exports: [SelectComponent, HighlightPipe, OffClickDirective]
})
export class SelectModule {
}
