import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tuya-arrive-timepicker',
  templateUrl: './arrive-timepicker.component.html',
  styleUrls: ['./arrive-timepicker.component.scss']
})
export class ArriveTimepickerComponent implements OnInit {

  showPicker = false;

  // INPUT
  @Input() set datetime(val: Date | any) {
      this.applyDT(val);
  }
  get datetime() { return this._datetime; }

  @Input() minDatetime = new Date();
  @Input() minRelatedDatetime = new Date();
  @Input() maxDatetime = new Date();
  @Input() hint: string;
  @Input() minDateError: string;
  @Input() disabled = true;
  @Input() isEditMode = false;

  // Output

  @Output() onDatetimeUpdate = new EventEmitter<Date>();
  @Output() invalid = new EventEmitter<any>();

  // Internal datetime
  private _datetime = new Date();

  constructor() { }

  ngOnInit() {
  }

  applyDT(val: Date) {
    this._datetime = val;
  }

  onValueChange(val: any) {
    this.applyDT(val);
    this.onDatetimeUpdate.emit(this.datetime);
  }

  onTogglePicker() {
    if (this.showPicker === false) {
        this.showPicker = true;
    }
  }

  onTimeInvalid(data) {
    this.invalid.emit(data);
  }

}

