<div *ngIf="currentManageCompanyTab === ManageCompanyTab.GeneralInformation">

    <form name="generalInfoForm" autocomplete="off"
            (ngSubmit)="generalInfoForm.valid && saveGeneralInfoForm()"
            #generalInfoForm="ngForm" novalidate>
        <div class="form-group col-sm-13" [ngClass]="{ 'has-error': isDirtyAndInvalid(name) || validateErrors === false }">
            <label for="name" [translate]="'tuya-company.general.companyName'"></label>
            <input placeholder="{{'tuya-company.general.companyName' | translate }}"
                    type="text"
                    class="form-control"
                    name="name"
                    [(ngModel)]="model.name"
                    #name="ngModel"
                    maxlength="250"
                    [pattern]="companyNamePattern"
                    [disabled]="suspended"
                    required/>
            <div *ngIf="isDirtyAndInvalid(name) && !name.errors?.pattern" class="help-block"
                    [translate]="'tuya-profiles.error.companyName'"></div>
            <div *ngIf="name.dirty && !!name.errors?.pattern" class="help-block"
                    [translate]="'tuya-profiles.error.company-name-pattern'"></div>
            <div *ngIf="validateCompanyErrors?.isValid === false" class="help-block">
                    <div *ngFor="let error of validateCompanyErrors?.nameErrorMsg" [translate]="error"></div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-6"
                    [ngClass]="{ 'has-error': isDirtyAndInvalid(addressLine) }">
                <label for="addressLine"
                        [translate]="'tuya-company.general.physicalAddressLine1'"></label>
                <input placeholder="{{'tuya-company.general.physicalAddressLine1' | translate }}"
                        type="text"
                        name="addressLine"
                        class="form-control"
                        [(ngModel)]="model.physicalAddress.addressLine"
                        #addressLine="ngModel"
                        maxlength="75"
                        [pattern]="addressAndCityPattern"
                        [disabled]="suspended"
                        required/>
                <div *ngIf="isDirtyAndInvalid(addressLine) && !addressLine.errors?.pattern"
                        class="help-block" [translate]="'tuya-profiles.error.addressLine'"></div>
                <div *ngIf="addressLine.dirty && !!addressLine.errors?.pattern" class="help-block"
                        [translate]="'tuya-profiles.error.addressLine-pattern'"></div>
            </div>
            <div class="form-group col-sm-6"
                [ngClass]="{ 'has-error': isDirtyAndInvalid(suiteNumber) }">
                <label for="suiteNumber" [translate]="'tuya-company.general.suiteNumber'"></label>
                <input placeholder="{{'tuya-company.general.suiteNumber' | translate }}"
                        type="text"
                        name="suiteNumber"
                        maxlength="10"
                        [pattern]="suitePattern"
                        class="form-control"
                        [(ngModel)]="model.physicalAddress.suiteNumber"
                        [disabled]="suspended"
                        #suiteNumber="ngModel"/>
                <div *ngIf="suiteNumber.dirty && !!suiteNumber.errors?.pattern" class="help-block"
                        [translate]="'tuya-profiles.error.suite-number-pattern'"></div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-6" [ngClass]="{ 'has-error': isDirtyAndInvalid(city)}">
                <label for="city" [translate]="'tuya-company.general.city'"></label>
                <input placeholder="{{'tuya-company.general.city' | translate }}"
                        type="text"
                        name="city"
                        class="form-control"
                        [pattern]="cityNamePattern"
                        [(ngModel)]="model.physicalAddress.city"
                        (ngModelChange)="onCityNameChange()"
                        #city="ngModel"
                        [disabled]="suspended"
                        required/>
                <div *ngIf="isDirtyAndInvalid(city) && !city.errors?.pattern" class="help-block"
                        [translate]="'tuya-profiles.error.city'"></div>
                <div *ngIf="city.dirty && !!city.errors?.pattern" class="help-block"
                        [translate]="'tuya-profiles.error.city-pattern'"></div>
            </div>
            <div class="form-group col-sm-3">
                <label for="state" [translate]="'tuya-company.general.state'"></label>
                <div class="select-wrapper">
                    <select name="stateId"
                            [(ngModel)]="model.physicalAddress.stateId"
                            #stateId="ngModel"
                            [disabled]="suspended"
                            class="form-control-select">
                        <option *ngFor="let state of states" [ngValue]="state.id">
                            {{state.shortName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group col-sm-3"
                    [ngClass]="{ 'has-error': isDirtyAndInvalid(postalCode) }">
                <label for="postalCode" [translate]="'tuya-company.general.zip'"></label>
                <input placeholder="{{'tuya-company.general.zip' | translate }}"
                        type="text"
                        name="postalCode"
                        class="form-control"
                        [(ngModel)]="model.physicalAddress.postalCode"
                        #postalCode="ngModel"
                        [textMask]="{mask: zipCodeMask, placeholderChar: '\u2000'}"
                        pattern="[0-9]{5}"
                        [disabled]="suspended"
                        required/>
                <div *ngIf="isDirtyAndInvalid(postalCode) && !postalCode.errors?.pattern"
                        class="help-block" [translate]="'tuya-profiles.error.zip'"></div>
                <div *ngIf="postalCode.dirty && postalCode.errors?.pattern" class="help-block"
                        [translate]="'tuya-profiles.error.zip-pattern'"></div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-6"
                    [ngClass]="{ 'has-error': phoneNumber.dirty && !isPhoneNumberValid && !isPhoneEmpty() }">
                <label [translate]="'tuya-company.general.phone-number'"></label>
                <input placeholder="{{'tuya-company.general.phone-number' | translate }}"
                        type="text" class="form-control"
                        name="phoneNumber"
                        [(ngModel)]="model.generalPhone"
                        (ngModelChange)="onPhoneChange()"
                        [disabled]="suspended"
                        #phoneNumber="ngModel"
                        [textMask]="{mask: phoneMask}"/>
                <div *ngIf="phoneNumber.dirty && !isPhoneNumberValid && !isPhoneEmpty()" class="help-block">
                    <div [hidden]="isPhoneNumberValid && phoneNumber.dirty"
                            [translate]="'tuya-profiles.error.phone-pattern'"></div>
                    <div [hidden]="phoneNumber.valid" [translate]="'tuya-profiles.error.phone'"></div>
                </div>
            </div>
            <div class="form-group col-sm-6">
                <label for="industrySegmentId" [translate]="'tuya-company.general.industryType'"></label>
                <div class="select-wrapper">
                    <select name="industrySegmentId"
                            class="form-control-select"
                            [(ngModel)]="model.industrySegmentId"
                            [disabled]="suspended"
                            #industrySegmentId="ngModel">
                        <option *ngFor="let industrySegment of industrySegments"
                                [ngValue]="industrySegment.id">
                            {{industrySegment.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <button class="btn btn-primary col-sm-3 btn-right save-button"
                [translate]="'tuya-company.general.save'"
                type="submit"
                [disabled]="!generalInfoForm.dirty || !generalInfoForm.valid ||
                (!isPhoneNumberValid && !isPhoneEmpty()) || doesCityContainsOnlySpaces || !isModelChanged() || suspended">
        </button>
        <button class="btn btn-default col-sm-3 btn-right cancel-button"
                type="button"
                (click)="generalInfoForm.dirty && isModelChanged() ? cancelGeneralInfoForm(cancel) : null"
                [disabled]="!isModelChanged() || suspended"
                [translate]="'tuya-company.general.cancel'"></button>
    </form>
</div>
<ng-template #cancel>
        <tuya-cancel-modal (hideModal)="onHideModal($event)"></tuya-cancel-modal>
</ng-template>
