import { ProfileToEditVO, CompanyValidationResultVO, ProfileValidationResultVO } from './../../register/model/model';
import { BusinessEntityDto } from './../../core/models/dto';
import { CreditCardDto } from '../../core/models/payment.dto';

export interface IProfilesState {
     profileToEdit: ProfileToEditVO;
     currentManageCompanyTab: ManageCompanyTab;
     companyInfoToEdit: BusinessEntityDto;
     companyInfoFormValidationResult: CompanyValidationResultVO;
     profileInfoFormValidationResult: ProfileValidationResultVO;
     error?: any;
     isLoading: boolean;
}

export enum ManageCompanyTab {
    GeneralInformation = 1,
    BillingContact = 2,
    PaymentMethods = 3,
}

export interface IBillingState {
    paymentMethods: CreditCardDto[];
    isGettingPaymentMethods: boolean;
    isPaymentModalOpen: boolean;
    isLoading: boolean;
}
