import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'tuya-price-change-modal',
  templateUrl: './price-change-modal.component.html',
  styleUrls: ['./price-change-modal.component.scss']
})
export class PriceChangeModalComponent implements OnInit, OnDestroy {

  adminSelection = '1';
  orderData: any;
  firstChange = true;

  min = '01';
  sec = '00';
  myinterval: any;

  options = [
    {name: 'priceChangeModal.keepOriginalAndNew', desc: 'priceChangeModal.keepOriginalAndNewDesc'},
    {name: 'priceChangeModal.newAndChanges', desc: 'priceChangeModal.newAndChangesDesc'}
  ];
  private unsubscribeSubscribers = new Subject<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
              private dialogRef: MatDialogRef<PriceChangeModalComponent>) {
  }

  ngOnInit() {
    this.orderData = this.data;

    this.startCountDown();
  }

  ngOnDestroy() {
    this.unsubscribeSubscribers.next(true);
    this.unsubscribeSubscribers.unsubscribe();
    clearInterval(this.myinterval);
  }

  onSubmit() {
    this.dialogRef.close(this.adminSelection);
  }

  onCloseModal(): void {
    this.dialogRef.close();
  }

  startCountDown() {
    this.min = '01';
    this.sec = '00';
    let seconds = 59;
    let timeStarted = false;
    this.myinterval = setInterval(() => {
        if (this.min !== '00') { this.min = '00'; }
        if (this.sec === '00' && timeStarted) {
          this.onCloseModal();
        } else {
          this.sec = seconds < 10 ? `0${seconds}` : `${seconds}`;
          seconds = seconds - 1;
        }
        timeStarted = true;
    }, 1000);
  }

}
