<fieldset
  [ngClass] = "{
    'builder-driver-selection': true,
    'builder-fieldset': true,
    'builder-driver-selection--inactive': currentStep < stopCount
  }"
>

  <h3 
    class="builder-driver-selection-label">
    <div class="builder-driver-selection-icon"></div>
    <div *ngIf="currentStep === stopCount" class="builder-driver-selection-icon-margin"></div>
    {{ 'builderDriverSelection.driverSelectionLegend' | translate }}
  </h3>

  <tuya-builder-accordion
              [expanded]="currentStep === stopCount"
              [currentStep]="currentStep"
              [disabled]="!validateDriverSelection()"
              [stepIndex]="stopCount">

    <p class="builder-driver-selection-description">
        {{ 'builderDriverSelection.driverVehicleType' | translate }} <strong>{{  VehicleTypes[(vehicleTypeId$ | async)]?.name| translate }}</strong>. {{ 'builderDriverSelection.whoDelivers' | translate }}
    </p>

    <div class="builder-driver-selection-wrapper">
      <div class="builder-driver-selection-general-offer">
        <input 
          type="radio" 
          [value]="offerTypeEnum.Any"
          name="driver-selection"
          id="general-offer"
          class="builder-driver-selection-general-offer-input"
          [(ngModel)]="offerType"
          [disabled]="materialLocked"
          (change)="onUpdateDriverSelectionType()">
        <label
          for="general-offer"
          class="builder-driver-selection-general-offer-label builder-field-label">
            <span class="builder-driver-selection-offer-type-text">{{ 'builderDriverSelection.generalOffer' | translate }}</span>
            &mdash; {{ 'builderDriverSelection.anyDriverLabel' | translate }}
        </label>  
      </div>

      <div class="builder-driver-selection-limited-offer-container">
        <div class="builder-driver-selection-limited-offer">
          <div class="builder-driver-selection-limited-offer-pool">
            <div class="builder-driver-selection-limited-offer-input-label">
              <input 
                type="radio"
                [value]="offerTypeEnum.DriversPool"
                name="driver-selection"
                id="limited-offer-pool"
                class="builder-driver-selection-limited-offer-input"
                [(ngModel)]="offerType"
                [disabled]="materialLocked"
                (change)="onUpdateDriverSelectionType()">
              <label
                for="limited-offer-pool"
                class="builder-driver-selection-limited-offer-label builder-field-label">
                  <span class="builder-driver-selection-offer-type-text">{{ 'builderDriverSelection.limitedOfferLabel' | translate }}</span>
                  &mdash;  
                  {{ 'builderDriverSelection.assignToPool' | translate }}
              </label>
            </div>  
            <tuya-search-select #driverPoolsSelect
              (typed)="onTypeEventHandler($event, 'pool')"
              (onSelectItems)="onSelectDrivers($event, 'pool')"
              (textFocus)="onTextFocus('pool')"
              [offerType]="offerType"
              [disabled]="offerType !== offerTypeEnum.DriversPool || materialLocked"
              [searchResults]="poolSearchResults"
              [autoFocusOut]="false"
              [selections]="poolSelections"
              [placeholder]="'builderDriverSelection.driverPools' | translate"
              [multiSelect]="true"
            ></tuya-search-select>
          </div>
        </div><!-- .builder-driver-selection-limited-offer -->
        <div class="builder-driver-selection-limited-offer-driver">
          <div class="builder-driver-selection-limited-offer-input-label">
            <input 
              type="radio"
              [value]="offerTypeEnum.Exclusive"
              name="driver-selection"
              id="limited-offer-driver"
              class="builder-driver-selection-limited-offer-input"
              [(ngModel)]="offerType"
              [disabled]="materialLocked"
              (change)="onUpdateDriverSelectionType()">
            <label
              for="limited-offer-driver"
              class="builder-driver-selection-limited-offer-driver-label builder-field-label">
                <span class="builder-driver-selection-limited-offer-label-highlight">{{ 'builderDriverSelection.exclusiveOfferLabel' | translate }}</span>
                &mdash; {{ 'builderDriverSelection.assignToDriver' | translate }}
            </label>
          </div>
          
          <tuya-search-select #exclusiveDriversSelect
            (typed)="onTypeEventHandler($event, 'driver')"
            (onSelectItems)="onSelectDrivers($event, 'driver')"
            (textFocus)="onTextFocus('driver')"
            (changeSearchResults)="onChangeSearchResults($event, 'driver')"
            [offerType]="offerType"
            [disabled]="offerType !== offerTypeEnum.Exclusive || materialLocked"
            [searchResults]="driverSearchResults"
            [autoFocusOut]="true"
            [selections]="driverSelections"
            [placeholder]="'builderDriverSelection.enterDriverCode' | translate"
            [multiSelect]="false"
            [pending]="driversPending$ | async"
          ></tuya-search-select>
        </div><!-- /.builder-driver-selection-limited-offer-driver -->
      </div>

      <div class="builder-driver-selection-extend-offer">
        <div class="builder-driver-selection-extend-offer-label">
            <tuya-switch
              class="builder-checkbox-extend-offer"
              [id]="'extend-offer'"
              [name]="'extend-offer'"
              [label]="'builderDriverSelection.extendOffer' | translate"
              [disabled]="offerType === offerTypeEnum.Any"
              [model]="isExtendable"
              (onInputToggle)="onCheckboxToggle($event)"
              [key]="'isExtendable'"
            >
            </tuya-switch>
        </div>
      </div>
    </div>
  </tuya-builder-accordion>

  <div class="builder-driver-selection-summary-info">
    <ng-container *ngIf="currentStep > stopCount && offerTypeEnum.Any === offerType">{{ 'builderCollapsedSummary.generalOffer' | translate }}</ng-container>
    <ng-container *ngIf="currentStep > stopCount && offerTypeEnum.Exclusive === offerType">{{ 'builderCollapsedSummary.exclusiveOffer' | translate }}</ng-container>
    <ng-container *ngIf="currentStep > stopCount && offerTypeEnum.DriversPool === offerType">{{ 'builderCollapsedSummary.limitedOffer' | translate }}</ng-container>
  </div>

</fieldset>