import { Component, Input, ViewChild } from '@angular/core';
import { StopDto, StopStatusId } from '../../../../core/models/dto';
import { NgForm } from '@angular/forms';
import { StopDetailsFormComponent } from '../stop-details-form/stop-details-form.component';

@Component({
    selector: 'tuya-stop-notes-field',
    templateUrl: 'stop-notes-field.component.html',
    styleUrls: ['stop-notes-field.component.scss']
})
export class StopNotesFieldComponent extends StopDetailsFormComponent {
    @ViewChild('form', {static: true}) public form: NgForm;
    @Input() stop: StopDto = new StopDto();
    @Input() disabled;
    public isCollapsed = true;
    public StopStatusId: typeof StopStatusId = StopStatusId;

    constructor () {
        super();
    }
}

