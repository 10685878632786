import { Action } from 'redux';
import { ItemsSizesAction, LOAD_ITEMS_SIZES_SUCCESS } from '../actions/items-sizes.actions';

export const initialState: any = [];

export function itemsSizesReducer(state = initialState, a: Action): any {
    switch (a.type) {
        case LOAD_ITEMS_SIZES_SUCCESS: {
            const action = a as ItemsSizesAction;
            return action.payload;
        }

        default: {
            return state;
        }
    }
}
