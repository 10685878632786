import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { GET_PRIVACY_POLICY, GET_TERMS_OF_SERVICE } from '../constants/api.constants';
import { ConfigService } from '../../app.config.service';

@Injectable()
export class TermsOfServiceService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    getPrivacyPolicy(userType: string) {
        return this.api().GET(GET_PRIVACY_POLICY + userType);
    }

    getTermsOfService(userType: string) {
        return this.api().GET(GET_TERMS_OF_SERVICE + userType);
    }
}
