<div class="recurring-alert" >
    <div>
        <div class="message" [innerHTML]="data.text"></div>

        <div class="btns">
            <button mat-button class="cancel" (click)="onCancel()">{{ data.buttons.cancel }}</button>
            <button mat-button class="accept" (click)="onAccept()">{{ data.buttons.accept }}</button>
        </div>
    </div>
</div>
