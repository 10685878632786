import { BoxSizeTypes, OrderItemVo } from '../../shipment/models/order.model';
import { ItemsDetails } from '../../shipment/models/ui.model';

export class ItemUtils {
    public static getItemsDetails(value: OrderItemVo[]): ItemsDetails {
        const itemDetails = value.reduce((l, r) => ({
            c: l.c + (r.sizeId !== BoxSizeTypes.UnknownReturn ? r.piecesCount : 0),
            w: l.w + r.weight * (r.sizeId !== BoxSizeTypes.UnknownReturn ? r.piecesCount : 1),
            rc: l.rc + (r.sizeId === BoxSizeTypes.UnknownReturn ? r.piecesCount : 0)
        }), {w: 0, c: 0, rc: 0});
        return {
            weight: itemDetails.w,
            count: itemDetails.c,
            returnCount: itemDetails.rc
        };
    }
}
