import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ProfilesActions } from 'app/profiles/actions/profiles.actions';
import { BehaviorSubject, debounceTime } from 'rxjs';

@Component({
    selector: 'tuya-shipper-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ShipperWelcomeComponent {
    @Input() username: string;
    @Input() suspended: boolean;
    @Input() isEmailConfirmed: boolean;
    @Input() isUnderTerms: boolean;
    @Input() hasOrders: boolean;
    @Input() canCreateShipment: boolean;
    @Input() hasValidCreditCard: boolean;

    @Input() onVerficationEmailRequest: () => void;

    bSentStub = new BehaviorSubject<boolean>(false);

    constructor(private cdRef: ChangeDetectorRef, public profilesActions: ProfilesActions) {
        this.bSentStub.pipe(debounceTime(5000))
        .subscribe(v => {
            if (v) {
                this.bSentStub.next(false);
                this.cdRef.detectChanges();
            }
        });
    }

    resendVerficationEmailClick() {
        this.onVerficationEmailRequest();
        this.bSentStub.next(true);
    }

    checkEmailConfirmation() {
        return !this.isEmailConfirmed && !this.bSentStub.getValue();
    }

    checkHasValidCC() {
        return this.isEmailConfirmed && !this.hasOrders && !this.isUnderTerms && !this.hasValidCreditCard;
    }

    checkHasOrders() {
        return this.isEmailConfirmed && !this.hasOrders && (this.isUnderTerms || this.hasValidCreditCard);
    }
}
