import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'tuya-driver-pool-search',
  templateUrl: './driver-pool-search.component.html',
  styleUrls: ['./driver-pool-search.component.scss']
})
export class DriverPoolSearchComponent implements OnInit, OnChanges {

  @Input() setFocus: boolean;
  @Input() searchFormControl = new UntypedFormControl();
  @Input() inputPlaceholder = 'Search';
  @Output() onChange = new EventEmitter<string>();

  @ViewChild('searchSelectInput', {static: true}) searchSelectInput: ElementRef;

  constructor() {
    this.focus();
  }

  ngOnInit() {
    this.focus();
  }

  ngOnChanges(change: SimpleChanges) {
    if (change && change.setFocus) {
      this.focus();
    }
  }

  focus() {
    setTimeout(() => {
      this.searchSelectInput.nativeElement.focus();
    }, 500);
  }

}
