import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { Action } from '../../core/models/redux.action';

export type SearchOrderAction = Action<any, any>;

export const SEARCH_FOR_AN_ORDER = '[SEARCH ORDER] Search for an order';
export const UPDATE_IS_SEARCHING = '[SEARCHING ORDER] Update isSearching value';
export const UPDATE_SEARCH_QUERY = '[SEARCH ORDER] Update user order search query';
export const SET_IS_SEARCH_RES = '[SEARCH ORDER] Sets true, if got result/not empty, otherwise false';
export const UPDATE_PROGRESS_BAR = '[SEARCH ORDER] Update progress bar';
export const RESET_ORDER_SEARCH = '[SEARCH ORDER] Reset order search';
export const OPEN_FILTER_DROPDOWN = '[SEARCH ORDER] Open filter dropdown';
export const CLOSE_FILTER_DROPDOWN = '[SEARCH ORDER] Close filter dropdown';
export const UPDATE_SEARCH_FILTER = '[SEARCH ORDER] Update search filter';
export const RESET_SEARCH_FILTER = '[SEARCH ORDER] Reset search filter';


@Injectable()
export class SearchOrderActions {
    @dispatch()
    searchForAnOrder = (searchQuery: string): SearchOrderAction => ({
        type: SEARCH_FOR_AN_ORDER,
        payload: searchQuery
    })

    @dispatch()
    updateSearchQuery = (searchQuery: string): SearchOrderAction => ({
        type: UPDATE_SEARCH_QUERY,
        payload: searchQuery
    })

    @dispatch()
    updateIsSearching = (isSearching: boolean): SearchOrderAction => ({
        type: UPDATE_IS_SEARCHING,
        payload: isSearching
    })

    @dispatch()
    gotSearchData = (isAnyData: boolean): SearchOrderAction => ({
        type: SET_IS_SEARCH_RES,
        payload: isAnyData
    })

    @dispatch()
    updateProgressBar = (val: number): SearchOrderAction => ({
        type: UPDATE_PROGRESS_BAR,
        payload: val
    })

    @dispatch()
    resetOrderSearch = (): SearchOrderAction => ({
        type: RESET_ORDER_SEARCH,
        payload: null
    })

    @dispatch()
    openFilter = (): SearchOrderAction => ({
        type: OPEN_FILTER_DROPDOWN,
        payload: null
    })

    @dispatch()
    closeFilter = (): SearchOrderAction => ({
        type: CLOSE_FILTER_DROPDOWN,
        payload: null
    })

    @dispatch()
    updateSearchFilter = (searchFilter): SearchOrderAction => ({
        type: UPDATE_SEARCH_FILTER,
        payload: searchFilter
    })

    @dispatch()
    resetSearchFilter = (resetSearchQuery: boolean): SearchOrderAction => ({
        type: RESET_SEARCH_FILTER,
        payload: resetSearchQuery
    })

}
