import { Component, OnInit, OnChanges, SimpleChanges, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'tuya-order-search-input',
  templateUrl: './order-search-input.component.html',
  styleUrls: ['./order-search-input.component.scss']
})
export class OrderSearchInputComponent implements OnInit, OnChanges, OnDestroy {
  searchForm: UntypedFormGroup;
  @Input() form: boolean;
  @Input() searchVal = '';
  @Input() placeholder = '';

  @Output() onClearEvent = new EventEmitter();
  @Output() onSearchEvent = new EventEmitter<string>();

  private unsubscriber = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    this.onChanges();
  }

  createForm() {
    this.searchForm = this.formBuilder.group({
      searchQuery: [this.searchVal],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.form || changes.form.isFirstChange()) {
      return;
    }
    // whenever input changes (and input is the form's state in the store), we update the value of the control
    this.searchForm.controls['searchQuery'].setValue('');
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.unsubscribe();
  }

  // show | hide search text clear button
  onChanges(): void {
    this.searchForm.controls['searchQuery'].valueChanges.subscribe(val => {
      const x = document.getElementById('clearButton');
      if (val === '' || val === null) {
        this.searchVal !== '' ? this.onClear() : this.searchVal = '';
        x.style.visibility = 'hidden';
        x.style.transition = 'visibility 0.08s, opacity 0.5s ease-out';
      } else {
        x.style.visibility = 'visible';
        x.style.transition = 'visibility 0.08s, opacity 0.5s ease-in';
      }
    });
  }

  onSearch(quuery: string) {
    const searchQuery = quuery.trim();
    if (searchQuery !== '') {
      this.onSearchEvent.emit(searchQuery);
    } else {
      this.searchForm.controls['searchQuery'].reset();
    }
  }

  onFocus() {
    if (this.searchForm.controls['searchQuery'].value  !== null && this.searchForm.controls['searchQuery'].value !== '') {
      const x = document.getElementById('clearButton');
      x.style.visibility = 'visible';
      x.style.transition = 'visibility 0.08s, opacity 0.5s ease-in';
    }
  }

  onClear() {
    this.onClearEvent.emit();
  }

  // onClear()
  // onSearch()

}
