import { LatLngDto } from '../../core/models/dto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { BaseService } from '../../core/services/base.service';
import { ConfigService } from '../../app.config.service';
import { StopDto } from '../models/stop.model';
import { get } from 'lodash-es';
import { OrderDto } from '../models/order.model';
import { AddressDto } from '../models/address.model';
import {
    API_ORDER,
    API_ORDER_CONFIRM,
    API_CHECK_ADDRESS_MSA,
    API_ADDRESS,
    API_ORDER_DISTANCE,
    API_PRICE_OPTIMIZATION,
    API_PREFIX,
    API_SEARCH_AUTOCOMPLETE
 } from '../../core/constants/api.constants';

 const ADDRESS_ONLY = 'AddressOnly';

@Injectable()
export class BuilderService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    orderHasMsaStop(stops: any[]): boolean {
        const existedStops = stops.find(stop => stop.inMsa === true);
        return (existedStops ? true : false);
    }

    createDraftOrder(order: OrderDto, editMode: boolean): Observable<OrderDto> {
        const orderData = {...order};
        if (!editMode) {
            delete orderData.id;
        }
        return this.api().POST(API_ORDER,
            {...orderData}).pipe(
            map((data: OrderDto) => data));
    }
    
    // this is currently not being used. keep for now
    // deleteOrder(orderId: string) {
    //     return this.api().DELETE(API_ORDER_DELETE.replace('{0}', orderId));
    // }

    getEditOrder(orderId: number, isDuplicateMode) {
        return this.api().GET(`${API_PREFIX}/order/${orderId}/edit?isDuplicateMode=${isDuplicateMode}`);
    }

    checkAddressIsInMSARange(address: AddressDto) {
        return this.api().POST(API_CHECK_ADDRESS_MSA,
            {...address}).pipe(
            map((result: any) => result));
    }

    updateOrder(order: OrderDto): Observable<OrderDto> {
        return this.api().PUT(API_ORDER,
            {...order}).pipe(
            map((data: OrderDto) => data));
    }

    updateOrderPrice(order: OrderDto): Observable<OrderDto> {
        return this.api().POST(API_PRICE_OPTIMIZATION.replace('{orderId}', get(order, 'id', '').toString()),
            {...order}).pipe(
            map((data: OrderDto) => data));
    }

    createOrder(order: OrderDto): Observable<OrderDto> {
        return this.api().POST(API_ORDER + '/Create',
            {...order}).pipe(
            map((data: OrderDto) => data));
    }

    getConfirmOrderId(assignmentData) {
        return this.api().PUT(API_ORDER_CONFIRM.replace('{0}', assignmentData.orderId),
            assignmentData).pipe(map((data: string) => data));
    }

    getNameAddressList(addressSearchParam: string, checkState = true): Observable<StopDto[]> {
        return this.api().GET(API_ADDRESS, {addressSearchParam, checkState}).pipe(
            map((data: StopDto[]) => data));
    }

    getSearchAutoComplete(shipperId: any, searchParam: string, latitude: any, longitude: any): Observable<AddressDto[]> {
        return this.api().GET(API_SEARCH_AUTOCOMPLETE, {shipperId, searchParam, latitude, longitude}).pipe(
            map((data: AddressDto[]) => data));
    }

    getAddressOnly(addressSearchParam: string, checkState = true): Observable<StopDto[]> {
        return this.api().GET(API_ADDRESS + ADDRESS_ONLY, {addressSearchParam, checkState}).pipe(
            map((data: StopDto[]) => data));
    }

    getOrderDistance(coords: LatLngDto[]) {
        return this.api().POST(API_ORDER_DISTANCE, {coordinates: coords}).pipe(
            map((resp: { miles: number; duration: number; }) => resp.miles));
    }
}
