import { paymentCardType } from '../../models/order.model';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CreditCardDto } from '../../../core/models/payment.dto';

@Component({
    selector: 'tuya-payment-order',
    templateUrl: './payment-order.component.html',
    styleUrls: ['./payment-order.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentOrderComponent implements OnInit {
    @Input() creditCard: CreditCardDto;
    @Input() isValidate: boolean;
    @Output() paymentOrderSubmit = new EventEmitter<CreditCardDto>();
    // @TODO until story 1526 will be implemented
    @Output() paymentOrderSkip = new EventEmitter();
    @Output() paymentOrderSubmitErrors = new EventEmitter<any>();

    paymentCard: paymentCardType = paymentCardType.NewCreditCard;
    paymentCardType: typeof paymentCardType = paymentCardType; // temporary
    // paymentUrl: SafeResourceUrl;
    @ViewChild('paymentOrderForm', {static: true}) paymentOrderForm;

    constructor() {}

    ngOnInit() {
        // @TODO until story 1526 will be implemented
        this.paymentOrderSkip.emit();
    }

    onPaymentOrderSubmit(creditCard) {
        this.paymentOrderSubmit.emit(creditCard);
    }

    onPaymentOrderSubmitErrors() {
        this.paymentOrderSubmitErrors.emit();
    }


    onPaymentFrameEvent(dataToSave) {
        // const dataToSave = event.dataToSave;
        this.onPaymentOrderSubmit({
            name: dataToSave.nickName,
            cardNumberHash: dataToSave.hash,
            expiresMonth: dataToSave.expiresMonth,
            expiresYear: dataToSave.expiresYear,
            cardHolder: dataToSave.cardHolder,
            storedAccountId: dataToSave.storedAccountId,
            id: dataToSave.id,
            cardTypeId: dataToSave.typeId // @TODO add support for correct types
        });
    }
}
