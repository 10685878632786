<div class="dropdown" [ngClass]="{ 'show': showPopup === true }" (tuyaOffClickMouseUp)="offClick()">
    <div class="dropdown-menu form-group" role="menu"
        [ngClass]="{ 'show': showPopup === true, 'has-error': pickerCtrl.errors }">
        <form>
            <timepicker [(ngModel)]="localValue" [formControl]="pickerCtrl" (keyup.enter)="tryToSubmit()" required
                #timepicker></timepicker>
        </form>
        <div *ngIf="pickerCtrl?.errors?.minDateBound" class="help-block" [translate]="minDateError"></div>
        <div *ngIf="pickerCtrl?.errors?.wrongNumbers || pickerCtrl?.errors?.outOfRange" class="help-block"
            [translate]="'tuya-shipment.popup-timepicker.error.time-out-of-range'"></div>
    </div>
</div>