import { StateDto, StopDto } from '../../core/models/dto';

export function getFullAddressLine(stop: StopDto, states: StateDto[]): string {
    const state = states.find((stateItem: StateDto) => stateItem.id === stop.address.stateId);
    return (stop.nameOrDescription ? (stop.nameOrDescription + ' - ') : '') +
        (stop.address.addressLine ? stop.address.addressLine : '') +
        ((stop.address.city && stop.address.postalCode && stop.address.stateId) ? ', ' : '') +
        (stop.address.city ? stop.address.city : '') +
        (state ? (' ' + state.shortName) : '') +
        (stop.address.postalCode ? (' ' + stop.address.postalCode) : '');
}

