import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ConfigService } from "app/app.config.service";
import { StripeCard, StripeScriptTag } from "stripe-angular"
import { PaymentService } from "../../services/payment.service"
import { StripeCreatePaymentMethodResponse } from "app/core/models/stripe.model";

@Component({
    selector: 'stripe-payment-frame',
    templateUrl: './stripe-payment-frame.component.html',
    styleUrls: ['./stripe-payment-frame.component.scss'],
})
export class StripePaymentFrame implements OnInit, OnDestroy {
    
    @ViewChild('stripeCard',{static: true}) stripeCard: StripeCard;
    @ViewChild('paymentElement',{static: true}) paymentElement: any;

    constructor(private stripeScriptTag: StripeScriptTag,
        private configService: ConfigService,
        private paymentService: PaymentService
        ) {
    }

    // Stripe variables
    private savedElements: any;
    private setupElement: any;

    stripeIsLive: boolean;

    stripeIsConfirming = false;
    stripeShowError = false;
    stripeShowConfirmed = false;
    
    stripeErrorMessage = '';

    customerId: string;
    setupIntentId: string;

    disableVerifyButton = true;

    stripeLoaded = false;

    ngOnInit(): void {

        // console.debug('stripe',this.stripeScriptTag.StripeInstance);
        this.stripeIsLive = this.configService.getConfiguration().stripeIsLive;

        this.paymentService.createStripePaymentMethod().subscribe(createResponse => {


            this.customerId = createResponse.customerId;
            this.setupIntentId = createResponse.setupIntentId;

            let elements = this.stripeScriptTag.StripeInstance.elements({
                clientSecret: createResponse.setupIntentClientSecretKey
            });
            this.setupElement = elements.create('payment');

            this.savedElements = elements;
            this.setupElement.mount('#paymentElement');

            // console.debug('elements',this.savedElements);
            // console.debug('setupIntent',this.setupElement);
            
            this.stripeLoaded = true;
            this.disableVerifyButton = false;
        });
    }

    ngOnDestroy(): void {
        this.setupElement.destroy();
    }
    
    createToken() {
        this.stripeIsConfirming = true;
        this.disableVerifyButton = true
        this.stripeScriptTag.StripeInstance.confirmSetup({
            elements: this.savedElements,
            redirect: 'if_required'
        }).then(res => {
            
            if(res.error) {
                this.stripeErrorMessage = res.error.message;
                this.stripeShowError = true;
                this.stripeShowConfirmed = false;
                this.disableVerifyButton = false;
                
            } else {
                this.setupElement.blur();
                this.stripeErrorMessage = '';
                this.stripeShowError = false;
                this.stripeShowConfirmed = true;
                this.disableVerifyButton = true;
                // Verify with API
                this.verifyCard();
            }
            this.stripeIsConfirming = false;
        });        
    }

    verifyCard() {
        this.paymentService.verifyStripePaymentMethod(this.customerId,this.setupIntentId)
            .subscribe();
    }
}
