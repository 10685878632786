import { OrderHelper } from './../../../utils/order-helper.utils';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { OrderItemVo, Stop, StopType } from '../../../models/order.model';
import { ShipmentType } from '../../../../core/models/shipment.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddressDto, StopStatusId } from '../../../../core/models/dto';
import { OrderEditMode } from '../../../../core/models/order.dto';

const DOTTED_CLASS = 'tuya-shipment.tuya-stop-details.dotted';
const ASAP_TIMEFRAME = 'ASAP';

@Component({
    selector: 'tuya-stop-line',
    templateUrl: './stop-line.component.html',
    styleUrls: ['./stop-line.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopLineComponent implements OnInit {

    public StopType: typeof StopType = StopType;
    public OrderHelper: typeof OrderHelper = OrderHelper;

    @Input() selectedStop: Stop = new Stop();
    @Input() isOptionalStopLine = false;
    @Input() isInvalidTimeframe = false;
    @Input() hasInvalidAddress = false;
    @Input() canDelete = false;
    @Input() isDirectOrder = false;
    @Input() shipmentType: ShipmentType;
    @Input() isStopsRequiredFieldsProvided: boolean;
    @Input() isLastOfMultipleStops = false;
    @Input() stopListLength = 0;
    @Input() disabled: boolean;
    @Input() public stopItems: OrderItemVo[];
    @Input() set stop(stop: Stop) {
        this._stop = stop;
        this.addressString = this._createAddressString(stop);
        this._checkIncomingStopSuit(stop);
    }
    OrderEditMode: typeof OrderEditMode = OrderEditMode;
    StopStatusId: typeof StopStatusId = StopStatusId;

    get stop() { return this._stop; }

    get desiredStopType() {
        return this.shipmentType === ShipmentType.Collection && this.isOptionalStopLine
            ? StopType.Pickup : (this.stop.typeId || StopType.Delivery);
    }

    @Output() selectStop = new EventEmitter<{id, sequenceNumber}>();
    @Output() removeStop = new EventEmitter<any>();
    @Output() editStop = new EventEmitter<any>();
    @Output() updateSuiteNumber = new EventEmitter<Stop>();

    public addressString = '';

    private _stop: Stop = new Stop();

    // Probably changed it in stop dto.
    constructor(public modalService: BsModalService) {
        this._stop.address = new AddressDto();
    }

    ngOnInit() {
    }

    public isNewStop() {
        return (!this.stop.address.name && !this.stop.address.addressLine ) || this.stop.address.addressLine === '';
    }
    public isNewCollectionPickupStop() {
        // 'Pickup' name for new optional stop of Collection order
        return this.shipmentType === ShipmentType.Collection && this.isNewStop() && this.isOptionalStopLine;
    }

    public getTypeName() {
        // switch (this.stop.typeId) {
        //     case StopType.Pickup || this.isNewCollectionPickupStop(): {
        //         return PICKUP_TYPE_NAME;
        //     }
        //     case StopType.Return: {
        //         return RETURN_TYPE_NAME;
        //     }
        //     case StopType.DeliveryWithReturn: {
        //         return DELIVERY_WITH_RETURN_TYPE_NAME;
        //     }
        //     default:
        //         return DELIVERY_TYPE_NAME;
        // }
        return OrderHelper.getStopType(this.stop.typeId, this.isNewCollectionPickupStop());
    }

    public onRemoveStop(stopId: any) {
        this.removeStop.emit(stopId);
        this.modalService.hide(1);
    }

    onRemoveReturn(template: TemplateRef<any>) {
        this.modalService.show(template, { ignoreBackdropClick: true });
    }

    public onEditStop(stopId: any) {
        this.editStop.emit(stopId);
    }

    public isSelected () {
        return this.selectedStop && this.selectedStop.address && this.selectedStop.id === this.stop.id;
    }

    public isDisabledTooltip() {
        return !this.disabled ||
            (this.disabled && (StopStatusId.Completed > this.stop.statusId));
    }

    public getLineClass() {
        if (this.stop.typeId === StopType.Pickup &&
            (this.stopListLength < 2 || this.stopListLength === 2 && !this.isStopsRequiredFieldsProvided)) {
            return DOTTED_CLASS;
        } else {
            return this.isLastOfMultipleStops ? DOTTED_CLASS : '';
        }
    }

    public onUpdateSuiteNumber() {
        this.updateSuiteNumber.emit(this.stop);
    }

    private _createAddressString(stop: Stop) {
        if (!stop) {
            return '';
        }
        return stop.nameOrDescription ? stop.nameOrDescription + ' - ' + stop.address.addressLine :
            stop.address.addressLine;
    }

    /**
     *
     * @description
     * Task 3941.4 Temporary solution for story requirements
     * @param stop
     */
    private _checkIncomingStopSuit(stop: Stop) {
        const statement = !!((stop.sequenceNumber > 1)
            && (stop.address.suiteNumber && !this.isStopsRequiredFieldsProvided)
            && this.isSelected());
        if (statement) { this.onEditStop(stop.id); }
    }
}
