import { Attribute, Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[tuyaValidatePasswordEqual][ngModel]',
  providers: [
      { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidatePasswordDirective), multi: true }
  ]
})
export class ValidatePasswordDirective implements Validator {
  constructor(  @Attribute('tuyaValidatePasswordEqual') public validateEqual: string,
                @Attribute('reverse') public reverse: string) { }
  private get isReverse() {
      if (!this.reverse) {
        return false;
      }
      return this.reverse === 'true' ? true : false;
  }

  validate(c: AbstractControl): { [key: string]: any } {
      const v = c.value;
      const e = c.root.get(this.validateEqual);
      if (e && v !== e.value && !this.isReverse) {
          return {
              validateEqual : false
          };
      }

      if (e && v === e.value && this.isReverse) {
          delete e.errors['validateEqual'];
          if (!Object.keys(e.errors).length) {
             e.setErrors(null);
          }
      }

      if (e && v !== e.value && this.isReverse) {
          e.setErrors({
              validateEqual: false
          });
      }
      return null;
  }
}
