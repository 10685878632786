<div class="reference-wrapper">
    <div class="reference">
        <div class="reference__icon"></div>
        <div class="reference__label">
            {{ 'builderSummary.enterRefIdLabel'| translate }}
        </div>

        <div class="reference__input">
            <input
                class="builder-order-summary-reference-id-input"
                id="order-refernce-id"
                type="text"
                placeholder="({{ 'builderSummary.enterRefPlaceholder' | translate }})"
                (focusout)="onReferenceIdUpdate($event)"
                [(ngModel)]="referenceId"
                (keydown.enter)="$event.preventDefault()">
        </div>
    </div>
</div>