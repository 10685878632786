export enum ValidationErrorCode {
    FieldIsRequired = 1000,
    FieldIsInIncorrectFormat = 1001,
    FieldIsNotUnique = 1002,
    FieldLessMinLength = 1003,
    FieldMoreMaxLength = 1004,
    FieldNotEqualsLength = 1005,
    FieldOutOfRange = 1006,
    FieldNotAllDigits = 1007,
    FieldNotAllLetters = 1008,
    NotUniqueSequence = 1009,
    EntityNotExist = 1010
}

export enum IdentityValidationErrorCode {
    PasswordNoCapitalLetter = 1100,
    PasswordNoDigit = 1101,
    PasswordIsBlackList = 1102,
    PasswordNoSpecialCharacters = 1103,
    PasswordContainForbiddenCharacters = 1105,
    AccountTemporaryLocked = 1108,
    InvalidUsernameOrPassword = 1107,
    PasswordNotValid = 1012,
    PasswordSameAsCurrent = 1011,
    ExpiredToken = 2005
}
