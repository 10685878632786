<nav class="navbar" [ngClass]="{'apply-sticky': currentRouter === '/orders'}">
    <div class="navbar-buttons-left-side" *ngIf="loggedIn" routerLinkActive="active">
        <a class="navbar-logo" routerLink="/" (click)="onClickIcon()"></a>
        
        <!-- Create Order Button -->
        <div class="navbar-create-order-button"
            *ngIf="showCreateButton()"
            routerLinkActive="active">
            <a
                routerLink="/builder"
                class="navbar-shipment-create-order-a"
                (click)="createNewShipment()"
                tooltip="{{ 'tuya-header.btn.suspend-tooltip' | translate}}"
                placement="bottom"
                container="body"
                [isDisabled]="!shipperStatus.suspended"
                containerClass="tuya-white-tooltip"
                [ngClass]="shipperStatus.suspended ? 'navbar-shipment-create-order-suspended' : ''">
                <div class="navbar-shipment-create-order-content">
                    <div class="navbar-shipment-create-order-plus">+</div>
                    <div class="navbar-shipment-create-order-text">{{ 'tuya-header.btn.create' | translate }}</div>
                </div>
            </a>
        </div>

        <!-- My Orders Button -->
        <div class="navbar-shipment-my-orders-button"
            *ngIf="showMyOrdersButton()"
            routerLinkActive="active">
            <a
                routerLink="/orders"
                class="navbar-shipment-my-orders-a"
                (click)="onClickIcon()">
                <div class="navbar-shipment-my-orders-text">{{ 'tuya-header.btn.my-orders' | translate }}</div>
            </a>
        </div>
    </div>

    <!-- Shipper Suspended Message -->
    <div class="navbar-middle-content" *ngIf="loggedIn && shipperStatus.suspended && !showSuspendAlert">
        <div class="status">
            {{ 'tuya-header.suspended-message.account-status' | translate }}: <b>{{ 'tuya-header.suspended-message.status-name' | translate }}</b>
        </div>
        <div class="message" [innerHTML]="'tuya-header.suspended-message.contact-info' | translate"></div>
    </div>

    <!-- right side buttons -->
    <div class="navbar-buttons-right-side">
        <!-- logged out -->
        <div class="navbar-buttons-right-side-logged-out" *ngIf="!loggedIn">
            <div class="navbar-button-register" routerLinkActive="active">
                <a class="navbar-button-register-text" routerLink="/register" [translate]="'tuya-header.btn.register'"></a>
            </div>
            <div class="navbar-button-login" routerLink="/login">
                <a class="navbar-button-login-text" [translate]="'tuya-header.btn.login'"></a>
            </div>
        </div>
        <!-- logged in -->

        <!-- order search input -->
        <tuya-order-search-input class="navbar-order-search-input"
                *ngIf="showSearchBox()"
                [form]="isChanged"
                [searchVal]="(searchQuery$ | async)"
                [placeholder]="'Search'"
                (onClearEvent)="onClear()"
                (onSearchEvent)="onSearch($event)"></tuya-order-search-input>

        <!-- notifications -->
        <tuya-notification-panel-container title="Notifications" class="navbar-notifications" *ngIf="loggedIn" (click)="onClickDropdown()"></tuya-notification-panel-container>
        
        <!-- Menu: Profile Dropdown -->
        <div class="btn-group" *ngIf="loggedIn" dropdown routerLinkActive="active">
            <button title="Profile" dropdownToggle type="button" class="dropdown-toggle" (click)="onClickDropdown()"> </button>
            <!-- profile dropdown list -->
            <ul *dropdownMenu class="dropdown-menu account-menu" role="menu">
                <li title="Admin" role="menuitem" *ngIf="adminProfile?.isAdmin"><a class="admin-panel" routerLink="/admin-panel"
                                        [translate]="'tuya-header.dropdown.admin-panel'"></a> </li>
                <li title="Profile" role="menuitem"><a class="profile" routerLink="/profile"
                                        [translate]="'tuya-header.dropdown.profile'"></a> </li>
                <li title="Company" role="menuitem"><a class="company" routerLink="/company"
                                        [translate]="'tuya-header.dropdown.company'"></a> </li>
                <li title="Exit" role="menuitem"><a class="logout" (click)="logout.emit()"
                                        [translate]="'tuya-header.dropdown.logout'"></a> </li>

                <!-- build info | Version Number -->
                <li *ngIf="showInHeader && version" role="menuitem" class="build">
                    <div>
                        {{configService.getConfiguration().configuration}} - {{version}}
                    </div>
                    <div *ngIf="backVersion">
                        Back#: {{backVersion}}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
