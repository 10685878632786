<div class="container">
    <div class="flex">
        <div class="register-part">
            <div class="register-logo">
                <div class="content"></div>
            </div>
            <div>
                <h2 [translate]="'tuya-register.h.sign'"></h2>
                <h5 [translate]="'tuya-register.h.join'"></h5>
            </div>
            <tuya-spinner *ngIf="isLoading$ | async"></tuya-spinner>
            <!--"f.form.valid && register()"  onSubmit(f) -->
            <form name="myAccountForm" autocomplete="off"
                [hidden]="(isLoading$ | async) || (formStep$ | async) !== RegisterFormStep.Init"
                [connect]="['register','forms','myAccountForm']" (ngSubmit)="onAccountValidate()"
                #myAccountForm="ngForm" novalidate>
                <div class="form-inputs">
                    <div class="form-group">
                        <div class="progress-container">
                            <ul class="progressbar">
                                <li class="active" [translate]="'tuya-register.progressbar.myAccount'"></li>
                                <li class="inactive" [translate]="'tuya-register.progressbar.companyInfo'"></li>
                            </ul>
                        </div>
                    </div>
                    <div class="form-group col-sm-6"
                        [ngClass]="{ 'has-error': (firstName.dirty || myAccountForm.submitted) && !firstName.valid }">
                        <input title="First Name" type="text" class="form-control" name="firstName" autocomplete="off"
                            [ngClass]="{ 'filled': model.firstName }" [(ngModel)]="model.firstName" #firstName="ngModel"
                            maxlength="50" [pattern]="namePattern" trim="blur" required />
                        <span class="reminder">{{ 'tuya-register.placeholder.firstName' | translate }}</span>
                        <div *ngIf="(firstName.dirty || myAccountForm.submitted)  && !firstName.valid && !firstName.errors?.pattern"
                            class="help-block" [translate]="'tuya-register.error.firstName'"></div>
                        <div *ngIf="firstName.dirty && !!firstName.errors?.pattern" class="help-block"
                            [translate]="'tuya-register.error.first-name-pattern'"></div>
                    </div>
                    <div class="form-group col-sm-6"
                        [ngClass]="{ 'has-error': (lastName.dirty || myAccountForm.submitted) && !lastName.valid }">
                        <input title="Last Name" autocomplete="off" [ngClass]="{ 'filled': model.lastName }" type="text"
                            class="form-control" name="lastName" [(ngModel)]="model.lastName" #lastName="ngModel"
                            maxlength="50" [pattern]="namePattern" trim="blur" required />
                        <span class="reminder">{{ 'tuya-register.placeholder.lastName' | translate }}</span>
                        <div *ngIf="(lastName.dirty || myAccountForm.submitted) && !lastName.valid && !lastName.errors?.pattern"
                            class="help-block" [translate]="'tuya-register.error.lastName'"></div>
                        <div *ngIf="lastName.dirty && !!lastName.errors?.pattern" class="help-block"
                            [translate]="'tuya-register.error.last-name-pattern'"></div>
                    </div>
                    <div class="form-group col-sm-12"
                        [ngClass]="{ 'has-error': (nickname.dirty || myAccountForm.submitted) && !nickname.valid}">
                        <!-- <label *ngIf="nickname.value && nickname.valid" [translate]="'tuya-register.placeholder.nickname'"></label> -->
                        <input title="Nickname" autocomplete="off" [ngClass]="{ 'filled': model.nickname }" type="text"
                            class="form-control" name="nickname" [(ngModel)]="model.nickname" #nickname="ngModel"
                            maxlength="50" [pattern]="namePattern" />
                        <span class="reminder">{{ 'tuya-register.placeholder.nickname' | translate }}</span>
                        <div *ngIf="(nickname.dirty || myAccountForm.submitted) && !!nickname.errors?.pattern"
                            class="help-block" [translate]="'tuya-register.error.nickname'"></div>
                    </div>
                    <div class="form-group col-sm-12"
                        [ngClass]="{ 'has-error': (phoneNumber.dirty || myAccountForm.submitted) && (!isPhoneNumberValid || !phoneNumber.valid) }">
                        <input title="Phone Number" autocomplete="off"
                            [ngClass]="{ 'filled': (model.phoneNumber || hasPhoneMask) }" type="text"
                            class="form-control" name="phoneNumber" [(ngModel)]="model.phoneNumber"
                            (ngModelChange)="isPhoneValid()" mask="(000) 000-0000" #phoneNumber="ngModel" required />
                        <span class="reminder">{{ 'tuya-register.placeholder.phone' | translate }}</span>
                        <div *ngIf="(phoneNumber.dirty || myAccountForm.submitted) && (!isPhoneNumberValid || !phoneNumber.valid)"
                            class="help-block">
                            <div [hidden]="phoneNumber.valid" [translate]="'tuya-register.error.phone'"></div>
                            <div [hidden]="isPhoneNumberValid || (isPhoneEmpty() && phoneNumber.dirty)"
                                [translate]="'tuya-register.error.phone-pattern'"></div>
                        </div>
                    </div>
                    <div class="form-group col-sm-12"
                        [ngClass]="{ 'has-error': (email.dirty || myAccountForm.submitted) && !email.valid }">
                        <input title="Email" autocomplete="off" [ngClass]="{ 'filled': model.email }" type="email"
                            class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel" required
                            [pattern]="emailPattern" />
                        <span class="reminder">{{ 'tuya-register.placeholder.email' | translate }}</span>
                        <div *ngIf="(email.dirty || myAccountForm.submitted) && !email.valid" class="help-block">
                            <div [hidden]="!email.errors.required" [translate]="'tuya-register.error.email'"></div>
                            <div [hidden]="!email.errors?.pattern" [translate]="'tuya-register.error.email-pattern'">
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-sm-12"
                        [ngClass]="{ 'has-error': (password.dirty || myAccountForm.submitted) && !password.valid || isLongPassword(password.value) }">
                        <input title="Password" autocomplete="off" [ngClass]="{ 'filled': model.password }"
                            type="password" class="form-control" name="password" [(ngModel)]="model.password"
                            #password="ngModel" required tuyaValidatePasswordEqual="confirmPassword" reverse="true"
                            minlength="6" maxlength="32" />
                        <span class="reminder">{{ 'tuya-register.placeholder.password' | translate }}</span>
                        <p class="password-requirements-text"
                            [ngClass]="{ 'invalid-password': password.dirty && !isPasswordValid, 'valid-password': password.dirty && isPasswordValid }">
                            {{ 'tuya-register.password-requirements' | translate }}</p>
                    </div>
                    <div class="form-group col-sm-12 confirm"
                        [ngClass]="{ 'has-error': (confirmPassword.dirty || myAccountForm.submitted) && !confirmPassword.valid }">
                        <input title="Confirm Password" autocomplete="off"
                            [ngClass]="{ 'filled': model.confirmPassword }" type="password" class="form-control"
                            name="confirmPassword" [(ngModel)]="model.confirmPassword" #confirmPassword="ngModel"
                            required tuyaValidatePasswordEqual="password" reverse="false" minlength="6"
                            maxlength="32" />
                        <span class="reminder">{{ 'tuya-register.placeholder.confirmPassword' | translate }}</span>
                        <div *ngIf="(confirmPassword.dirty || myAccountForm.submitted) && !confirmPassword.valid"
                            class="help-block">
                            {{(confirmPassword.value?.length ? 'tuya-register.error.confirm-password' :
                            'tuya-register.error.not-confirm') | translate}}
                        </div>
                    </div>
                </div>
                <div class="form-alert">
                    <div *ngIf="(validateAccountResult$ | async)?.isValid === false" class="alert alert-danger">
                        <div *ngFor="let error of (validateAccountResult$ | async)?.passwordErrorMsg"
                            [translate]="error"></div>
                        <div *ngFor="let error of (validateAccountResult$ | async)?.emailErrorMsg" [translate]="error">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <button title="Next" type="submit" class="btn btn-primary btn-lg btn-block register-btn"
                        [translate]="'tuya-register.btn.next'"></button>
                </div>
            </form>

            <form name="companyInfoForm" autocomplete="off"
                [hidden]="(isLoading$ | async) || (formStep$ | async) !== RegisterFormStep.AccountValidated"
                (ngSubmit)="onCompanyInfoSubmit()" [connect]="['register','forms','companyInfoForm']"
                #companyInfoForm="ngForm" novalidate>
                <div class="form-inputs">
                    <!-- FIRST FORMS BLOCK -->
                    <div class="form-group">
                        <div class="progress-container">
                            <ul class="progressbar">
                                <li class="inactive-after" [translate]="'tuya-register.progressbar.myAccount'"
                                    (click)="onStepBack()"></li>
                                <li class="active" [translate]="'tuya-register.progressbar.companyInfo'"></li>
                            </ul>
                        </div>
                    </div>
                    <div class="sidebar-content">
                        <div class="row">
                            <div class="form-group col-md-12 form-company"
                                [ngClass]="{ 'has-error': (name.dirty || companyInfoForm.submitted) &&
                                (!name.valid || name.dirty && name.errors?.pattern || (validateCompanyError$ | async)?.nameErrorMsg) }">
                                <input title="Company Information" type="text" class="form-control" name="name"
                                    [ngClass]="{ 'filled': companyInfoModel.name }" [(ngModel)]="companyInfoModel.name"
                                    #name="ngModel" maxlength="250" [pattern]="companyNamePattern" required />
                                <span class="reminder">{{ 'tuya-register.placeholder.company-name' | translate }}</span>
                                <div *ngIf="(name.dirty || companyInfoForm.submitted) && !name.valid && !name.errors?.pattern"
                                    class="help-block" [translate]="'tuya-register.error.companyName.required'"></div>
                                <div *ngIf="name.dirty && !!name.errors?.pattern" class="help-block"
                                    [translate]="'tuya-register.error.companyName.pattern'"></div>
                                <div *ngIf="(validateCompanyError$ | async)?.isValid === false" class="help-block">
                                    <div *ngFor="let error of (validateCompanyError$ | async)?.nameErrorMsg"
                                        [translate]="error"></div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group pos-rel"
                            [ngClass]="{ 'has-error': (industrySegmentId.dirty|| companyInfoForm.submitted) && !industrySegmentId.valid }">
                            <label *ngIf="industrySegmentId.value"
                                [translate]="'tuya-register.placeholder.industry'"></label>
                            <div class="select-wrapper">
                                <select title="Industry Segment" class="form-control-select" name="industrySegmentId"
                                    [(ngModel)]="companyInfoModel.industrySegmentId" #industrySegmentId="ngModel"
                                    required>
                                    <option [value]="industrySegmentUndefinedOptionValue" hidden
                                        [translate]="'tuya-register.placeholder.industry'"></option>
                                    <option *ngFor="let industrySegment of industrySegments$ | async"
                                        [ngValue]="industrySegment.id">
                                        {{industrySegment.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="spinner-wrapper" *ngIf="(industrySegments$ | async)?.length <= 0">
                                <tuya-spinner [size]="'small'"></tuya-spinner>
                            </div>
                            <div *ngIf="(industrySegmentId.dirty || companyInfoForm.submitted) && !industrySegmentId.valid"
                                class="help-block" [translate]="'tuya-register.error.industry'"></div>
                        </div>
                        <hr>

                        <!-- COMPANY ADDRESS -->
                        <h4 [translate]="'tuya-register.h.physicalAddress'" uppercase></h4>

                        <div class="form-groups-block">
                            <tuya-company-address-input [states]="states$ | async"
                                [showErrors]="companyInfoForm.submitted" [(ngModel)]="companyInfoModel.physicalAddress"
                                name="physicalAddress" #physicalAddress="ngModel"></tuya-company-address-input>
                        </div>
                        <hr>
                        <h4 [translate]="'tuya-register.h.billingAddress'" uppercase></h4>
                        <div class="form-checkbox">
                            <label for="sameAsPhysical" class="control control-checkbox">
                                <span [translate]="'tuya-register.checkbox.sameAsPhysical'"></span>
                                <input title="Same a Physical Address" type="checkbox"
                                    [(ngModel)]="billingSameAsPhysical" id="sameAsPhysical"
                                    [ngModelOptions]="{standalone: true}" />
                                <div class="control_indicator"></div>
                            </label>
                        </div>

                        <div [hidden]="billingSameAsPhysical" class="form-groups-block">
                            <tuya-company-address-input [states]="states$ | async"
                                [showErrors]="companyInfoForm.submitted && showErrorsInCompanyInfo"
                                [(ngModel)]="companyInfoModel.billingAddress" name="billingAddress"
                                #billingAddress="ngModel"></tuya-company-address-input>
                        </div>

                        <div class="form-group referral-code"
                            [ngClass]="{ 'has-error': (referralCode.dirty || myAccountForm.submitted) && !referralCode.valid }">
                            <input title="Referral Code" [ngClass]="{ 'filled': model.referralCode }" type="text"
                                class="form-control" name="referralCode" [(ngModel)]="model.referralCode"
                                #referralCode="ngModel" pattern="^[a-zA-Z0-9]{0,9}$" />
                            <span class="reminder">{{ 'tuya-register.placeholder.referralCode' | translate }}</span>
                            <div *ngIf="(referralCode.dirty || myAccountForm.submitted) && !referralCode.valid"
                                class="referral-block" [translate]="'tuya-register.error.referralCode'"></div>
                        </div>
                        <!-- Hiding for now per ticket #23532.
                        <div class="form-checkbox">
                            <label for="terms" class="control control-checkbox">
                                <span [translate]="'tuya-register.checkbox.sales'"></span>
                                <input type="checkbox" id="terms" [ngModelOptions]="{standalone: true}" [(ngModel)]="sales" />
                                <div class="control_indicator"></div>
                            </label>
                        </div>
                        -->
                        <div class="terms-service">
                            <tuya-terms-of-service-container></tuya-terms-of-service-container>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <button title="Submit" [disabled]="loading" class="btn btn-primary btn-lg btn-block" type="submit"
                        [translate]="'tuya-register.btn.shipping'"></button>
                </div>
            </form>
        </div>
    </div>
</div>