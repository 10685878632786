import { combineReducers } from 'redux';

import { alertReducer } from './alert.reducers';
import { termsOfServiceReducer } from './termsOfService.reducers';
import { itemsSizesReducer } from './items-sizes.reducers';
import { poolsReducer } from './pools.reducers';
import { statesReducer } from './states.reducers';
import { notificationReducer } from './notification.reducers';
import { mapReducer } from './map.reducer';

export let coreReducer = combineReducers({
    alert: alertReducer,
    tos: termsOfServiceReducer,
    itemsSizes: itemsSizesReducer,
    pools: poolsReducer,
    states: statesReducer,
    notification: notificationReducer,
    map: mapReducer
});
