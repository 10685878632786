import { Pipe, PipeTransform } from '@angular/core';
import { BoxSizeDto } from '../../core/models/dto';

@Pipe({
  name: 'filterBySizeId'
})
export class FilterBySizeIdPipe implements PipeTransform {

  transform(items: BoxSizeDto[], args: number): BoxSizeDto[] {
    return items.filter(item => item.id !== args);
  }

}
