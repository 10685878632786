import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Stop } from '../../../../models/order.model';
import { PickupDateType } from '../../../../../core/models/shipment.model';
import { DEFAULT_TIME_WINDOW, MIN_TIME_WINDOW } from '../../../../utils/stop-pickup-time.utils';
import { StopDateCommonComponent } from '../stop-date-common.component';
import { DateHelper } from '../../../../utils/dateHelper';


@Component({
    selector: 'tuya-depended-stop-date',
    encapsulation: ViewEncapsulation.None,
    templateUrl: '../stop-date-common.component.html',
    styleUrls: ['../stop-date-fields.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopDependedStopDateComponent extends StopDateCommonComponent {
    // ToDO: needs refactoring
    @Input() set relatedStop(val: Stop) {
        if (!val) { return; }
        this.relatedStopExists = true;
        this.relatedStopDateType = val.pickupDateTypeId;

        this.relatedStopDate = new Date(Date.parse(val.pickupNoEarlierThan));
        this.relatedStopNoLaterThanDate = (val.pickupNoLaterThan === '')
            ? this.addMsec2Date(DEFAULT_TIME_WINDOW, this.relatedStopDate)
            : new Date(Date.parse(val.pickupNoLaterThan));

        if (this.relatedStopDateType === PickupDateType.Tomorrow && !this.isDraftStopDetailsEditMode
            //  if date is empty ->  Should be same actions like on dateType change.
            || this.noEarlierDT === null || this.noLaterDT === null) {
            this.dateType = this.relatedStopDateType; // easy can't be before pickup.
            this.isDateTypeChangeAvailable = false;
            return;
        }
        // or simply update constrains.
        this.updateMinNoEarlierDT(this.dateType);
        this.setDatePairWithConstrains(this.noEarlierDT, this.noLaterDT, true);
        this.updateStop();
    }

    relatedStopDateType: number;
    relatedStopExists = false;
    relatedStopDate: Date;
    relatedStopNoLaterThanDate: Date;

    constructor() {
        super();
    }

    setDatePairWithConstrains(noEarlierDT: Date, noLaterDT: Date, isStopSetting?) {
        super.setDatePairWithConstrains(noEarlierDT, noLaterDT, isStopSetting);
        if (this.disabled || (this.isEditMode && isStopSetting)) { return; }

        // is draft stop is edited
        if (this.isDraftStopDetailsEditMode && isStopSetting) {
            this.noLaterDT = new Date(noLaterDT);
        }
    }

    updateMinNoEarlierDT(newDateTypeId: number) {
        // update constrains for the timepicker.
        const minLocalDT = (PickupDateType.Tomorrow === newDateTypeId)
            ? DateHelper.getTomorrow(this.currentDate) // tomorrow
            : this.currentDate; // today


        let minRelatedDT = this.currentDate;
        if (this.relatedStopExists && !this.isPickupStop && !this.isDraftStopDetailsEditMode) {
            minRelatedDT = this.addMsec2Date(MIN_TIME_WINDOW, this.relatedStopDate);
        }
        this.minNoEarlierDT = (minLocalDT >= minRelatedDT) ? minLocalDT : minRelatedDT; // pick more strict one.
    }
}
