<div bsModal #errorModal="bs-modal" class="modal fade" role="dialog" [config]="{ignoreBackdropClick: true}">
    <div class="modal-content">
        <div class="modal-header minus-margin">
            <div class="modal-logo"></div>
            <span class="header-text" [translate]="'tuya-shipment.price-optimization-error-modal.header-text'"></span>
        </div>
        <div class="modal-body">
            <p>{{'tuya-shipment.price-optimization-error-modal.message-text' | translate}}</p>
            <ul>
                <li class="stop-name" *ngFor="let name of unservedStopNames">{{name}}</li>
            </ul>
            <p class="bottom-message">{{'tuya-shipment.price-optimization-error-modal.message-text-2' | translate}}</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="_hideModal()"
                    [translate]="'tuya-shipment.price-optimization-error-modal.edit-btn'"></button>
        </div>
    </div>
</div>
