import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'tuya-time-dropdown',
  templateUrl: './time-dropdown.component.html',
  styleUrls: ['./time-dropdown.component.scss']
})
export class TimeDropdownComponent implements OnInit {
  @Output() onTimeSelected = new EventEmitter<object>();
  // Prefix for ids in case element is used multiple times on a page.
  @Input() idKey: string;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() selected: string;
  @Input() stopIndex: number;
  @Input() onChange: Function;
  @Input() key: string;
  @Input() time: string;
  @Input() timeForm: FormGroup;
  @Input() formControlNameField: string;

  constructor() { }

  ngOnInit() {
  }

  onTimeSelect(event) {
    const target = event.target as HTMLInputElement;
    const payload = {
      key: target.dataset.key,
      time: target.value.toLowerCase()
    };

    this.onTimeSelected.emit(payload);
  }

  areValidInputs() {
    return !this.fromTimeInputErrors && !this.toTimeInputErrors;
  }

  get fromTimeInputErrors(): any {
    return this.timeForm.controls['pickupNoEarlierThan'].errors;
  }

  get toTimeInputErrors(): any {
    return this.timeForm.controls['pickupNoLaterThan'].errors;
  }

  hasInvalidStartTime(): boolean {
    return this.formControlNameField === 'pickupNoEarlierThan' &&
      this.timeForm.errors && this.timeForm.errors.startTimeBeforeCurrentTime;
  }

  endsAfterStartTime(): boolean {
    return this.timeForm.errors && this.timeForm.errors.endBeforeStart;
  }

  invalidMinimumTimeWindow(): boolean {
    return this.timeForm.errors && this.timeForm.errors.minTimeWindow;
  }
}
