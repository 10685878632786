import { ITrackingState } from '../models/tracking.model';
import { StopDto } from '../../core/models/dto';
import { Action } from 'redux';
import { LOAD_TRACKING_INFO_SUCCEEDED, TrackingAction } from '../actions/tracking.actions';

export const initialTrackingInfoState: ITrackingState = {
    trackingInfo: {
        driverUserAccountId: null,
        businessEntityName: '',
        createDate: '',
        driverName: '',
        driverPhoto: null,
        itemsDescription: '',
        orderCode: '',
        orderItems: [],
        orderStatusId: 20,
        shipperEmail: '',
        shipperName: '',
        shipperPhone: '',
        lastLocationUpdateTime: '',
        isDirectOrder: false,
        stopInfo: new StopDto(),
        stopEventLog: [],
        driverArrivedAtFirstStop: false
    }
};

export function trackingInfoReducer(state = initialTrackingInfoState, a: Action): ITrackingState {
    const action = <TrackingAction>a;
    switch (action.type) {
        case LOAD_TRACKING_INFO_SUCCEEDED: {
            return {
                ...state,
                trackingInfo: action.payload
            };
        }
        default: {
            return state;
        }
    }
}

