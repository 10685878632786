<div class="profile-container">
    <div class="container-header">
      <h1 >
        <span [translate]="'tuya-profiles.h1.my-profile'"></span>
      </h1>
    </div>
    <form name="shipperProfileForm" autocomplete="off" (ngSubmit)="firstName.valid && lastName.valid && phoneNumber.valid && saveShipperProfileForm()"
      #shipperProfileForm="ngForm" novalidate>
      <div>
        <div class="row">
          <div class="form-group col-md-6" [ngClass]="{ 'has-error': firstName.dirty && !firstName.valid}">
            <label [translate]="'tuya-profiles.label.firstName'"></label>
            <input placeholder="{{'tuya-profiles.placeholder.firstName' | translate }}"
                type="text"
                class="form-control"
                name="firstName"
                [(ngModel)]="model.firstName"
                #firstName="ngModel"
                maxlength="50"
                [pattern]="namePattern"
                trim="blur"
                [disabled]="suspended$ | async"
                required/>
            <div *ngIf="firstName.dirty && !firstName.valid && !firstName.errors?.pattern" class="help-block" [translate]="'tuya-profiles.error.firstName'"></div>
            <div *ngIf="firstName.dirty && !!firstName.errors?.pattern" class="help-block" [translate]="'tuya-profiles.error.first-name-pattern'"></div>
          </div>
          <div class="form-group col-md-6" [ngClass]="{ 'has-error': lastName.dirty && !lastName.valid }">
            <label [translate]="'tuya-profiles.label.lastName'"></label>
            <input placeholder="{{'tuya-profiles.placeholder.lastName' | translate }}"
                type="text"
                class="form-control"
                name="lastName"
                [(ngModel)]="model.lastName"
                #lastName="ngModel"
                maxlength="50"
                [pattern]="namePattern"
                trim="blur"
                [disabled]="suspended$ | async"
                required/>
            <div *ngIf="lastName.dirty && !lastName.valid && !lastName.errors?.pattern" class="help-block" [translate]="'tuya-profiles.error.lastName'"></div>
            <div *ngIf="lastName.dirty && !!lastName.errors?.pattern" class="help-block" [translate]="'tuya-profiles.error.last-name-pattern'"></div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6" [ngClass]="{ 'has-error': nickname.dirty && !nickname.valid}">
            <label [translate]="'tuya-profiles.label.nickname'"></label>
            <input placeholder="{{'tuya-profiles.placeholder.nickname' | translate }}"
                type="text"
                class="form-control"
                name="nickname"
                maxlength="50"
                [(ngModel)]="model.nickname"
                [pattern]="namePattern"
                [disabled]="suspended$ | async"
                #nickname="ngModel" />
            <div *ngIf="nickname.dirty && !!nickname.errors?.pattern" class="help-block" [translate]="'tuya-profiles.error.nickname'"></div>
          </div>
          <div class="form-group col-md-6">
            <label [translate]="'tuya-profiles.label.email'"></label>
            <input [disabled]="true" placeholder="{{'tuya-profiles.placeholder.email' | translate }}"
                type="email"
                class="form-control"
                name="email"
                [(ngModel)]="model.email"
                #email="ngModel" />
          </div>
        </div>
        <div class="row half">
          <div class="form-group col-md-6 form-phone" [ngClass]="{ 'has-error': phoneNumber.dirty && (!isPhoneNumberValid || !phoneNumber.valid) }">
            <label [translate]="'tuya-profiles.label.phone'"></label>
            <input placeholder="{{'tuya-profiles.placeholder.phone' | translate }}"
                type="text" class="form-control"
                name="phoneNumber"
                [(ngModel)]="model.phoneNumber"
                (ngModelChange)="isPhoneValid()"
                #phoneNumber="ngModel"
                [disabled]="suspended$ | async"
                mask="(000) 000-0000"
              required />
            <div *ngIf="phoneNumber.dirty && (!isPhoneNumberValid || !phoneNumber.valid) " class="help-block">
              <div [hidden]="isPhoneNumberValid || (isPhoneEmpty() && phoneNumber.dirty)" [translate]="'tuya-profiles.error.phone-pattern'"></div>
              <div [hidden]="phoneNumber.valid" [translate]="'tuya-profiles.error.phone'"></div>
            </div>
          </div>
          <div class="form-group col-md-6 form-checkbox">
            <label class="control control-checkbox">
              <span [translate]="'tuya-profiles.checkbox.send-updates'"></span>
                <input type="checkbox" name="saveForFuture" [(ngModel)]="model.sendUpdates" #sendUpdates="ngModel" [disabled]="suspended$ | async"/>
              <div class="control_indicator"></div>
            </label>
          </div>
        </div>
        <div class="footer">
          <span>
            <a class="change-password" [translate]="'tuya-profiles.link.change-password'" (click)="clickShowChangePasswordModal() " [ngClass]="(suspended$ | async )? 'suspendedClass' : null"></a>
            <i class="fa fa-arrow-right"></i>
          </span>
            <div class="form-buttons">
              <button 
                class="btn btn-secondary btn-lg cancel-button" 
                type="button" 
                [disabled]="!isModelChanged()"
                (click)= "shipperProfileForm.dirty && isModelChanged() ? cancelShipperProfileForm(cancel) : null" 
                [translate]="'tuya-profiles.btn.cancel'">
              </button>
              <button 
                class="btn btn-primary btn-lg save-button"
                id="save-button"
                type="submit"
                [disabled]="!shipperProfileForm.dirty || !shipperProfileForm.valid || !isPhoneNumberValid || !isModelChanged()"
                [translate]="'tuya-profiles.btn.save'">
              </button>
            </div>
        </div>
      </div>
    </form>
    <div class="spinner-wrapper">
        <tuya-spinner *ngIf="isLoading$ | async"></tuya-spinner>
    </div>
</div>
<tuya-change-password
  (changePasswordSubmit)="onChangePasswordSubmit($event)"
  [validationResult]="changePasswordValidationResult$ | async"
  >
</tuya-change-password>

<tuya-save-modal
    *ngIf="(validateProfileErrors$ | async)?.isValid === true"
    (clearErrors)="onClearErrors($event)">
</tuya-save-modal>
<ng-template #cancel>
   <div class="modal-content">
    <div class="modal-body">
      <h3 [translate]="'tuya-profiles.modals.modal-cancel'"></h3>
      <button type="button" class="btn btn-default btn-lg" (click)="modalService.hide(1)" [translate]="'tuya-profiles.btn.stay'"></button>
      <button type="button" class="btn btn-primary btn-lg" (click)="discardProfilesChanges()" [translate]="'tuya-profiles.btn.leave'"></button>
    </div>
  </div>
</ng-template>
