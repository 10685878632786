import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { concat, Observable } from 'rxjs';
import { of } from 'rxjs';
import {
    EDIT_DISCARD_ORDER_ACTION, EDIT_DISCARD_ORDER_CHANGES_SUCCEED,
    EDIT_ORDER_ACTION,
    EDIT_ORDER_ACTION_SUCCEED,
    ShipmentEditActions
} from '../actions/shipment-edit.actions';
import { transformOrderDtoIntoOrderVo } from '../../shipment/transforms/shipment.transforms';
import { OrdersService } from '../../core/services/orders.service';
import { Stop } from '../../shipment/models/order.model';
import { SHIPMENT_RESET, ShipmentActions } from '../../shipment/actions/shipment.actions';
import { UIActions } from '../../shipment/actions/ui.actions';
import { WizardStep } from '../../shipment/models/ui.model';
import { Router } from '@angular/router';
import { OrderStatusId } from '../../core/models/dto';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';
import { MyOrdersActions } from '../../home/actions/my-orders.actions';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';

/**
 * This is a sample of Epics with Observables for handling Side Effects in redux
 * Please read the basics about Observables
 */
@Injectable()
export class ShipmentEditEpics {
    constructor(private shipmentEditActions: ShipmentEditActions,
                private ordersService: OrdersService,
                private shipmentActions: ShipmentActions,
                private uiActions: UIActions,
                private router: Router,
                private alert: AlertActions,
                private myOrdersActions: MyOrdersActions,
                private translationService: TranslateService) {
    }

    public createEpic() {
        return combineEpics(
            createShipmentOrderEditEpic(this.shipmentEditActions, this.ordersService,
                                        this.alert, this.myOrdersActions, this.translationService),
            createShipmentOrderEditSucceedEpic(this.shipmentActions, this.uiActions),
            createDiscardEditEpic(this.ordersService, this.shipmentEditActions),
            createDiscardSuccessEpic(this.router)
        );
    }
}

export function createShipmentOrderEditEpic(shipmentEditActions: ShipmentEditActions, ordersService: OrdersService,
                                            alert: AlertActions, myOrdersActions: MyOrdersActions,
                                            translationService: TranslateService) {
    return (action$) => action$.pipe(
        ofType(EDIT_ORDER_ACTION),
        switchMap((action: any) => {
            return ordersService.editOrder(action.payload).pipe(
                map(data => {
                    if (data.statusId !== OrderStatusId.Completed &&
                        data.statusId !== OrderStatusId.CompletedWithExceptions) {
                        const orderVo = transformOrderDtoIntoOrderVo(data);
                        return shipmentEditActions.editOrderSucceeded(orderVo);
                    } else {
                        alert.show({
                            message: translationService.instant('tuya-alert.edit-disabled'),
                            type: AlertType.Error
                        });
                        return myOrdersActions.updateOrderStatus({...data, orderId: action.payload, statusId: data.statusId});
                    }
                })
                ,catchError(error => {
                    console.warn('EDIT_ORDER_ACTION Failed', error);
                    return of(shipmentEditActions.editOrderFailed({
                        error,
                    }));
                }));
        })
    );
}

export function createShipmentOrderEditSucceedEpic(shipmentActions: ShipmentActions, uiActions: UIActions) {
    return (action$) => action$.pipe(
        ofType(EDIT_ORDER_ACTION_SUCCEED),
        mergeMap((action: any) => {
            const orderVo = action.payload;
            return concat(
                of(uiActions.setDisableStateForRefreshOrder(true)),
                of(uiActions.setWizardStep(WizardStep.Stops)),
                of(shipmentActions.shipmentOrderSaveToState(orderVo)),
                of(shipmentActions.selectCurrentStop(orderVo.stopList[0].id)),
                of(uiActions.initStopEdit({...(new Stop()), ...orderVo.stopList[0]})));
        })
    );
}


export function createDiscardEditEpic(ordersService: OrdersService, shipmentEditActions: ShipmentEditActions) {
    return (action$) => action$.pipe(
        ofType(EDIT_DISCARD_ORDER_ACTION),
        switchMap((action: any) => {
            return ordersService.discardChanges(action.payload).pipe(
                map(data => shipmentEditActions.editDiscardChangesSuccess())
                ,catchError(error => {
                    console.warn('EDIT_ORDER_ACTION Failed', error);
                    return of(shipmentEditActions.editDiscardChangesFail({
                        error,
                    }));
                }));
        })
    );
}

export function createDiscardSuccessEpic(router: Router) {
    return (action$) => action$.pipe(
        ofType(EDIT_DISCARD_ORDER_CHANGES_SUCCEED),
        map((action) => {
            router.navigate(['/orders']);
            return {
                type: SHIPMENT_RESET,
                payload: null
            };
        })
    );
}

