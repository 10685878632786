import { Component, OnInit } from '@angular/core';
import { select } from '@angular-redux/store';

import { AlertActions } from '../../../core/actions/alert.actions';
import { BehaviorSubject } from 'rxjs';
import { ProfilesSelectors } from '../../../profiles/selectors/profiles.selectors';
import { ProfilesActions } from '../../../profiles/actions/profiles.actions';
import { ShipperProfileDto, BusinessEntityDto } from '../../../core/models/dto';
import { locale } from '../../i18n/admin.en';
import { ShipperAdminActions } from '../../actions/shipper.admin.actions';

import { Alert } from '../../../core/models/alert.model';
import { AlertType } from '../../../core/models/alert.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'tuya-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {
  @select(['profiles', 'isLoading'])
  readonly isLoading$: Observable<boolean>;

  @select(['admin', 'shipperAdmin', 'selectedShipper', 'userAccountId'])
  readonly selectedShipperId$: Observable<number>;

  @select(['profiles', 'profileToEdit', 'userAccountId'])
  readonly profileToEditUserAccountId$: Observable<any>;

  @select(['profiles', 'profileToEdit', 'shipperFactorCode'])
  readonly shipperFactorCode$: Observable<string>;

  @select(['profiles', 'profileToEdit', 'shipperFactorCodeDisplay'])
  readonly shipperFactorCodeDisplay$: Observable<string>;

  @select(ProfilesSelectors.profileToEditSelector)
  readonly shipperProfileInfo$: Observable<ShipperProfileDto>;

  @select(ProfilesSelectors.companyInfoToEditSelector)
  readonly companyProfileInfo$: Observable<BusinessEntityDto>;

  codeModel = '';

  private selectedShipperId = new BehaviorSubject<number>(null);
  private profileToEditUserAccountId = new BehaviorSubject<any>(null);
  private shipperProfileInfo = new BehaviorSubject<ShipperProfileDto>(null);
  private companyProfileInfo = new BehaviorSubject<BusinessEntityDto>(new BusinessEntityDto());

  constructor(
    private profilesActions: ProfilesActions,
    private shipperAdminActions: ShipperAdminActions,
    private alertActions: AlertActions
  ) {
      this.selectedShipperId$.subscribe(this.selectedShipperId);
      this.profileToEditUserAccountId$.subscribe(this.profileToEditUserAccountId);
      this.shipperProfileInfo$.subscribe(this.shipperProfileInfo);
      this.companyProfileInfo$.subscribe(this.companyProfileInfo);
     }

  ngOnInit() {
    // kick off call to load the shipper profile into state if not loaded into profileToEdit
    if (this.profileToEditUserAccountId.value !== this.selectedShipperId.value) {
      this.shipperAdminActions.loadShipperAndCompanyProfilesAsAdmin(this.selectedShipperId.value);
    }
  }

  codeApplied(reset = false) {
    const companyProfile = this.companyProfileInfo.value;
    const newProfile = {
      ...this.shipperProfileInfo.value,
      shipperFactorCode: reset ? null : this.codeModel,  // always need to send complete shipperProfile (with businessEntity)
      businessEntity: companyProfile
    };
    this.profilesActions.saveShipperProfile(newProfile);
  }

  onTermsChange(event: any, mode: string) {
    let message = '';
    const checked = event.target.checked;
    const companyProfile = this.companyProfileInfo.value;
    let newProfile: ShipperProfileDto;

    if (mode === 'terms') {
      newProfile = {
        ...this.shipperProfileInfo.value,
        isUnderTerms: checked,
        businessEntity: companyProfile
      };

      message = checked ? locale.data['tuya-admin']['admin-panel']['modal-enable-terms-message'] :
                          locale.data['tuya-admin']['admin-panel']['modal-disable-terms-message'];
    } else {
      newProfile = {
        ...this.shipperProfileInfo.value,
        suspended: checked,
        businessEntity: companyProfile
      };

      message = checked ?  locale.data['tuya-admin']['admin-panel']['modal-suspend-message'] :
                           locale.data['tuya-admin']['admin-panel']['modal-unsuspend-message'];
    }

    const alert: Alert = {
      message: message,
      type: mode === 'terms' ? AlertType.Cancellable : AlertType.AdminPanel,
      checked: checked,
      callback: (accepted: boolean) => {
        return new Promise<boolean>((resolve, reject) => {
          if (accepted) {
            this.profilesActions.saveShipperProfile(newProfile);
          } else {
            // Set the checkbox to the opposite of it's current checked state on cancel confirmation dialog.
            (<HTMLInputElement>document.getElementById(mode)).checked = !(<HTMLInputElement>document.getElementById(mode)).checked;
          }
          resolve(accepted);
        });
      }
    };
    this.alertActions.show(alert);
  }

}
