import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ManageCompanyTab } from '../../model/model';
import { BusinessEntityDto, ShipperProfileDto } from '../../../core/models/dto';
import { BillingActions } from '../../../profiles/actions/billing.actions';

@Component({
  selector: 'tuya-manage-company-tabs',
  templateUrl: './manage-company-tabs.component.html',
  styleUrls: ['./manage-company-tabs.component.scss']
})
export class ManageCompanyTabsComponent {

    @Output() changeManageCompanyTab = new EventEmitter<ManageCompanyTab>();
    @Input() shipperProfilesInfo: ShipperProfileDto;
    @Input() set companyProfilesInfo (profile: BusinessEntityDto) {
        this._profile = profile;
        this.tabs.find(tab => tab.type === ManageCompanyTab.PaymentMethods).show = this.showPaymentProfile();
    }
    @Input() set currentActiveTab (tab: ManageCompanyTab) {
        this.tabs.forEach((item, i) => {
            item.active = item.type === tab;
        });
        this._currentActiveTab = tab;
    }

    get companyProfilesInfo () {
        return  this._profile;
    }


    public tabs = [
        {
            type: ManageCompanyTab.GeneralInformation,
            active: true,
            text: 'tuya-company.navigation.generalInformation',
            show: true
        },
        {
            type: ManageCompanyTab.BillingContact,
            active: false,
            text: 'tuya-company.navigation.billingList',
            show: true
        },
        {
            type: ManageCompanyTab.PaymentMethods,
            active: false,
            text: 'tuya-company.navigation.paymentMethods',
            show: false
        }
    ];
    private _currentActiveTab: ManageCompanyTab;
    private _profile: BusinessEntityDto = new BusinessEntityDto();
    constructor(private billingActions: BillingActions) { }

    public emitChangeManageCompanyTab(i) {
        const st = JSON.parse(sessionStorage.getItem('state'));
        if (this.tabs[i].type === 3 && (!st.billing || st.billing.paymentMethods.length === 0)) {
            this.billingActions.getPaymentMethods();
        }
        this.changeManageCompanyTab.emit(this.tabs[i].type);
    }

    private showPaymentProfile() {
        if (!this.companyProfilesInfo) { return; }
          return this.shipperProfilesInfo.isEmailConfirmed;
    }


}
