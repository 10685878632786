<div class="shipment-kind-picker">
    <h2 [translate]="'tuya-shipment.shipment-builder.choose-shipment-type'"></h2>
    <div class="shipment-type-buttons">
        <tuya-shipment-kind-picker-btn
            [type]="ShipmentType.Distribution" [selectedType]="shipmentType"
            (onSelect)="setShipmentType($event)"
        ></tuya-shipment-kind-picker-btn>
        <tuya-shipment-kind-picker-btn
            [type]="ShipmentType.Collection" [selectedType]="shipmentType"
            (onSelect)="setShipmentType($event)"
        ></tuya-shipment-kind-picker-btn>
    </div>
</div>
