import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HighlightType, NotificationDto } from '../../models/notification.model';


@Component({
    selector: 'tuya-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationListComponent implements OnInit {
    @Input() itemList: Array<NotificationDto> = [];
    @Output() deleteItem = new EventEmitter();
    @Output() openOrderDetails = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    closeMessage(evt, item: NotificationDto) {
        evt.stopPropagation();
        this.deleteItem.emit(item);
    }

    goToOrderDetails(item: NotificationDto) {
        if (item.orderId) {
            this.openOrderDetails.emit(item.orderId);
        }
        this.deleteItem.emit(item);
    }

    getNotificationClass(item) {
        return {
            'item-success': item.type === HighlightType.Success,
            'item-warning': item.type === HighlightType.Warning,
            'item-danger': item.type === HighlightType.Danger,
            'item-default': item.type === HighlightType.Default,
            'item-recurring-posted': item.type === HighlightType.Recurring
        };
    }

}
