<div class="shipment-header {{OrderHelper.getStopHeader(stopType).toLowerCase()}}">
    <div class="row">
        <div class="col-sm-1">
            <a *ngIf="isBackButtonAvaliable" (click)="onGoBack($event)" class="back-btn"></a>
        </div>
        <div class="col-sm-5">
            <span class="icon"></span>
            <h3>{{OrderHelper.getStopHeader(stopType)}} {{'tuya-shipment.tuya-stop-details.headers.stop' | translate}}</h3>
        </div>
        <div class="col-sm-6 ">
            <a class="add-reference"><span class="text" [translate]="'tuya-delivery-stop.tuya-item-selection.add-reference'"></span></a>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-8">
            <input type="text" class="form-control" [disabled]="true" [(ngModel)]="address.addressLine"/>
        </div>
        <div class="col-sm-4">
            <input type="text"
                   class="form-control"
                   placeholder="{{'tuya-shipment.tuya-stop-details.suiteNumberLabel' | translate}}"
                   [disabled]="isDisableSuitNo"
                   [(ngModel)]="address.suiteNumber"/>
        </div>
    </div>
</div>
