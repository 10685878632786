import { TrackingInfoVo } from './../../models/tracking.model';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'tuya-stop-events-log',
    templateUrl: './stop-events-log.component.html',
    styleUrls: ['./stop-events-log.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopEventsLogComponent {
    @Input() public trackingInfo: TrackingInfoVo = <TrackingInfoVo>{};

    constructor() {
    }
}
