import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PriceOptimizationDto } from '../../../core/models/dto';

@Component({
    selector: 'tuya-action-bottom-bar',
    templateUrl: './action-bottom-bar.component.html',
    styleUrls: ['./action-bottom-bar.component.scss']
})

export class ActionBottomBarComponent implements OnInit {
    @Input() message: string;
    @Input() step = 0;
    @Input() stepList: Array<string>;
    @Input() isContinueAllowed = false;
    @Input() buttonLabel = 'tuya-shipment.btn.continue';
    @Input() disabled = false;
    @Input() set price(price: PriceOptimizationDto) {
        if ((price || new PriceOptimizationDto()).price) {
            this._total = price.price;
        }
    }
    @Input() set total (total: number) {
        this._total = total;
    }

    @Output() onContinue = new EventEmitter();
    @Output() onReset = new EventEmitter();
    @Output() onDiscard = new EventEmitter();

    get total () {
       return this._total;
    }

    private _total: number;
    constructor() { }

    ngOnInit() { }

    public onContinueClicked() {
        if (this.isContinueAllowed) {
            this.onContinue.emit();
        }
    }
}
