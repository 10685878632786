<fieldset class="builder-instructions builder-fieldset">
  <div [formGroup]="instructionsForm">
    
    <div class="builder-field-label builder-items-label">
      <ng-container *ngIf="stopType === StopType.Pickup">
        {{ 'builderInstructions.pickupInstructions' | translate }}
      </ng-container>
      <ng-container *ngIf="stopType === StopType.Delivery">
        {{ 'builderInstructions.deliveryInstructions' | translate }}
      </ng-container>
    </div>

    <div class="builder-instructions-wrap">
      <div class="builder-checkbox-wrap">
        <tuya-switch
          class="builder-checkbox-signature-email"
          [id]="idKey + '-signature-required'"
          [name]="idKey + '-signature-required'"
          [label]="'builderInstructions.signatureRequired' | translate"
          [disabled]="false"
          (onInputToggle)="onCheckboxToggle($event)"
          [model]="stop.isSignatureRequired"
          [key]="'isSignatureRequired'">
        </tuya-switch>
        <br>
        <tuya-switch
          class="builder-checkbox-signature-email"
          [id]="idKey + '-email-tracking'"
          [name]="idKey + '-email-tracking'"
          [label]="'builderInstructions.emailTracking' | translate"
          [disabled]="!stop.contactEmail || contactEmail.errors !== null"
          (onInputToggle)="onCheckboxToggle($event)"
          [model]="stop.isEmailTrackingLink"
          [key]="'isEmailTrackingLink'"
          >
        </tuya-switch>
      </div><!-- /.builder-checkbox-wrap -->
      <div class="builder-textarea-wrap">
        <textarea
          *ngIf="stopType === StopType.Pickup"
          class="builder-form-textarea builder-notes"
          id="{{ idKey }}-notes"
          name="{{ idKey }}-notes"
          placeholder="{{'builderInstructions.pickupNotesPlaceholder' | translate}}"
          (focusout)="onEditStopNotes($event)"
          maxlength="500"
          formControlName="pickupNotes">{{ stop.notes }}</textarea>
    
        <textarea
          *ngIf="stopType === StopType.Delivery"
          class="builder-form-textarea builder-notes"
          id="{{ idKey }}-notes"
          name="{{ idKey }}-notes"
          placeholder="{{'builderInstructions.deliveryNotesPlaceholder' | translate}}"
          (focusout)="onEditStopNotes($event)"
          maxlength="500"
          formControlName="deliveryNotes">{{ stop.notes }}</textarea>
      </div>
    </div><!-- /.builder-instructions-wrap -->
  </div>
</fieldset>