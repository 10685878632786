import { OrderItemVo, OrderVo, Stop } from '../../shipment/models/order.model';
import { AddressDto } from '../../core/models/dto';

export function isOrderChanged(oldOrder: OrderVo, newOrder: OrderVo): boolean {
    return isOrderStopsChanged(oldOrder, newOrder) ||
        isOrderItemsChanged(oldOrder, newOrder) ||
        isOrderDriversChanged(oldOrder, newOrder) ||
        isOrderPaymentChanged(oldOrder, newOrder);
}

/*
* @Description we need to handle changes time frames, stops quantity, address.
* IF some of fields changed, time validation has to be triggered on proceed to driver selection button
* and save details button;
* @Info - according story 9978
* */
export function isValidationFieldChanged(oldOrder: OrderVo, newOrder: OrderVo): boolean {
    return !isEqualsStopQuantity(oldOrder, newOrder) || isAddressOrTimeChanged(oldOrder, newOrder);
}

export function isOrderPriceChanged(oldOrder, newOrder): boolean {
    return oldOrder.total !== newOrder.total;
}

function checkIfStopNotChanged(oldStop: Stop, newOrder: OrderVo): boolean {
    const newStop = newOrder.stopList.find((stop) => stop.id === oldStop.id);
    if (!newStop) {
        return false;
    } else {
        return isStopEquals(oldStop, newStop);
    }
}

export function isStopEquals(oldStop: Stop, newStop: Stop) {
    function clearProperties(stop: Stop) {
        delete stop.address;
        delete stop.orderId;
        return stop;
    }

    const oldCopy = JSON.stringify(clearProperties({...oldStop}));
    const newCopy = JSON.stringify(clearProperties({...newStop}));

    return oldCopy === newCopy;
}

export function isOrderStopsChanged(oldOrder: OrderVo, newOrder: OrderVo) {
    if (!isEqualsStopQuantity(oldOrder, newOrder)) {
        return true;
    }
    const stopsNotChanged = oldOrder.stopList.every(stop => checkIfStopNotChanged(stop, newOrder));
    return !stopsNotChanged;
}

export function isEqualsStopQuantity(oldOrder, newOrder) {
    return oldOrder.stopList.length === newOrder.stopList.length;
}

function checkIfItemNotChanged(oldItem: OrderItemVo, newOrder: OrderVo): boolean {
    const newItem = newOrder.itemList.find((item) => item.id === oldItem.id);
    if (!newItem) {
        return false;
    } else {
        return isItemEquals(oldItem, newItem);
    }
}

export function isItemEquals(oldItem: OrderItemVo, newItem: OrderItemVo) {
    // @TODO check why we store incorrect values in store
    function clearProperties(item: OrderItemVo) {
        delete item['images'];
        delete item['orderId'];
        delete item['stopIds'];
        delete item['actualItemsCount'];
        return item;
    }

    const oldCopy = JSON.stringify(clearProperties({...oldItem}));
    const newCopy = JSON.stringify(clearProperties({...newItem}));

    return oldCopy === newCopy;
}

export function isOrderItemsChanged(oldOrder: OrderVo, newOrder: OrderVo) {
    if (oldOrder.itemList.length !== newOrder.itemList.length) {
        return true;
    }
    const itemsNotChanged = oldOrder.itemList.every((item) => checkIfItemNotChanged(item, newOrder));
    return !itemsNotChanged;
}

export function isOrderDriversChanged(oldOrder: OrderVo, newOrder: OrderVo) {
    return oldOrder.driverAssignmentType !== newOrder.driverAssignmentType;
}

export function isOrderPaymentChanged(oldOrder: OrderVo, newOrder: OrderVo) {
    return false;
}

export function isAddressOrTimeChanged(oldOrder: OrderVo, newOrder: OrderVo): boolean {
    if (!isEqualsStopQuantity(oldOrder, newOrder)) {
        return true;
    }
    const addressTimeNotChanged = oldOrder.stopList.every(stop => checkIfDataChanged(stop, newOrder));
    return !addressTimeNotChanged;
}

export function checkIfDataChanged(oldStop: Stop, newOrder: OrderVo) {
    const newStop = newOrder.stopList.find((stop) => stop.id === oldStop.id);
    if (!newStop) {
        return false;
    } else {
        return isEqualData(oldStop, newStop);
    }
}

export function isEqualData(oldStop: Stop, newStop: Stop): boolean {
    return isStopsAddressEquals(oldStop.address, newStop.address) &&
        isStopTimeEqual(oldStop, newStop);
}

export function isStopsAddressEquals(oldAddress: AddressDto, newAddress: AddressDto) {
    return (oldAddress.longitude === newAddress.longitude) &&
        (oldAddress.latitude === newAddress.latitude);
}

export function isStopTimeEqual(oldStop: Stop, newStop: Stop): boolean {
    return (new Date(oldStop.pickupNoEarlierThan).getTime() === new Date(newStop.pickupNoEarlierThan).getTime()) &&
        (new Date(oldStop.pickupNoLaterThan).getTime() === new Date(newStop.pickupNoLaterThan).getTime());
}


