<!-- order details -->
<div class="wrapper">
    <!-- header -->
    <tuya-order-info-header [orderInfo]="orderInfo" [isLoading]="isLoading"></tuya-order-info-header>
    <!-- map -->
    <div class="body">
        <tuya-map [markers]="markers" [autoBoundary]="true" mode="container-size" [screenChangeMode]="true"></tuya-map>
    </div>
    <!-- footer -->
    <div class="order-details-footer">
        <tuya-order-summary-bar [orderSummary]="orderInfo.orderSummary || {}" [isLoading]="isLoading"></tuya-order-summary-bar>
    </div>
</div>
