<div class="date-wrapper">
    <label class="dateLabel">Date</label>
    <form class="input-container" [formGroup]="timeForm">
        <input matInput title="date" placeholder="MM/DD/YYYY" [min]="minDate" [max]="maxDate" [matDatepicker]="picker"
            formControlName="date" (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)"
            [attr.disabled]="true"
            [ngClass]="[disabled ? 'input-container--disabled' : '', datePickerActive ? 'openedDatePicker' : '']">
        <mat-datepicker #picker (closed)="onClose()"></mat-datepicker>
        <div (click)="picker.open(); onOpen();" class="date-picker-toggle"
            [ngClass]="{'openedDatePickerBg': datePickerActive}">
            <img src="../../../../assets/img/my-orders/{{imageName}}.svg" alt="select a date" />
        </div>
    </form>
</div>