import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'tuya-price-optimization-error-modal',
    templateUrl: 'price-optmization-error-modal.template.html',
    styleUrls: ['price-optmization-error-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceOptimizationErrorModalComponent implements AfterViewInit {
    @Input() public unservedStopNames = [];
    @ViewChild('errorModal', {static: true}) errorModal: ModalDirective;
    @Output() public hideModal = new EventEmitter();

    constructor() {}

    ngAfterViewInit() {
        this._showModal();
    }

    public _hideModal() {
        this.hideModal.emit();
        this.errorModal.hide();
    }

    private _showModal() {
        this.errorModal.show();
    }

}
