import { Time } from '../models/ui.model';

export const hoursPerDay = 24;
export const dex = 10;

export class DateHelper {

    static getTomorrow(date: Date) {
        let tomorrow = new Date(date);
        tomorrow = new Date(tomorrow.setDate(date.getDate() + 1));
        tomorrow = new Date(tomorrow.setHours(0, 0, 0, 0));
        return tomorrow;
    }

    static addMsec2Date(msec: number, date: Date): Date {
        return new Date(date.getTime() + msec);
    }

    static getMaxNoLaterThan(date: Date) {
        const dt = new Date(date);
        return new Date(dt.setHours(23, 59, 0, 0));
    }

    static createDate(
        value: Date,
        hours: number,
        minutes: number,
        seconds: number
    ): Date {
        return new Date(
            value.getFullYear(),
            value.getMonth(),
            value.getDate(),
            hours,
            minutes,
            seconds,
            value.getMilliseconds()
        );
    }

    static toNumber(value: string | number): number {
        if (typeof value === 'number') {
            return value;
        }

        return parseInt(value, dex);
    }

    static changeTime(value: Date, diff: Time): Date {
        if (!value) {
            return DateHelper.changeTime(DateHelper.createDate(new Date(), 0, 0, 0), diff);
        }

        let hour = value.getHours();
        let minutes = value.getMinutes();
        let seconds = value.getSeconds();

        if (diff.hour) {
            hour = (hour + DateHelper.toNumber(diff.hour)) % hoursPerDay;
            if (hour < 0) {
                hour += hoursPerDay;
            }
        }

        if (diff.minute) {
            minutes = minutes + DateHelper.toNumber(diff.minute);
        }

        if (diff.seconds) {
            seconds = seconds + DateHelper.toNumber(diff.seconds);
        }

        return DateHelper.createDate(value, hour, minutes, seconds);
    }
}
