<div class="wrapper">
    <div class="align-left">
        <button class="btn-reset" *ngIf="!disabled; else close" (click)="onReset.emit()"></button>
        <div class="order-total">
            <span class="order-tota-label" [translate]="'tuya-shipment.action-bottom-bar.order-total'"></span>
            <span class="order-total-output">{{ total | currency : 'USD' : 'symbol' }}</span>
        </div>
    </div>

    <div class="align-center">
        <tuya-step-progress-bar
            [activeStep]="step"
            [stepList]="stepList"
        ></tuya-step-progress-bar>
    </div>


    <div class="align-right">
        <div *ngIf="!isContinueAllowed" class="message" [translate]="message"></div>
        <button [hidden]="!isContinueAllowed" (click)="onContinueClicked()"
                [translate]="buttonLabel" class="btn-continue">
        </button>
    </div>
</div>

<ng-template #close>
    <button class="btn-close" (click)="onDiscard.emit()"></button>
</ng-template>

