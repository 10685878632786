<div>
    <div class="col-md-offset-1">
        <h2 [translate]="'tuya-forgot-password.chooseANew'"></h2>
        <h4 [translate]="'tuya-forgot-password.selectANew'"></h4>
    </div>
    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
        <div class="form-group input-control" [ngClass]="{ 'has-error': (f.submitted && !password.valid) }">
            <label for="password" [translate]="'tuya-forgot-password.newPassword'"></label>
            <input placeholder="{{'tuya-forgot-password.newPassword' | translate }}"
                  type="password" class="form-control" name="password"
                  maxlength="32"
                  [(ngModel)]="model.password" #password="ngModel"
                   required tuyaValidatePasswordEqual="confirmPassword" reverse="true"/>
            <div *ngIf="f.submitted && !password.valid" class="help-block"
                  [translate]="'tuya-forgot-password.error.password'"></div>
        </div>
        <div class="form-group input-control" [ngClass]="{ 'has-error': (f.submitted && !confirmPassword.valid) }">
            <label for="confirmPassword" [translate]="'tuya-forgot-password.confirmPassword'"></label>
            <input placeholder="{{'tuya-forgot-password.confirmPassword' | translate }}"
                type="password" class="form-control" 
                name="confirmPassword"
                [(ngModel)]="model.confirmPassword" 
                #confirmPassword="ngModel"
                required 
                tuyaValidatePasswordEqual="password" 
                reverse="false"
                maxlength="32"
            />
            <div *ngIf="f.submitted && !confirmPassword.valid " class="help-block"
                  [translate]="'tuya-forgot-password.error.confirm-password'"></div>
        </div>
        <div *ngIf="(validationResult)?.isValid === false" class="alert alert-danger">
            <div *ngFor="let error of (validationResult)?.msgs" [translate]="error"></div>
        </div>
        <div class="form-group btn-submit">
            <button class="btn btn-primary btn-lg btn-block forgot-password-btn no-margin" [translate]="'tuya-forgot-password.submit'"></button>
            
        </div>
    </form>
</div>
