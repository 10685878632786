import { TrackingInfoVo } from './../../models/tracking.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'tuya-tracking-order-info',
    templateUrl: './tracking-order-info.component.html',
    styleUrls: ['./tracking-order-info.component.scss']
})
export class TrackingOrderInfoComponent implements OnInit {

    @Input() public trackingInfo: TrackingInfoVo = <TrackingInfoVo>{};

    constructor() { }

    ngOnInit() {

    }
}
