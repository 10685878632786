<div class="tracking-page-container">
    <main>
        <div class="tracking-page-col map">
            <tuya-map [markers]="markers$ | async" [autoBoundary]="true" mode="container-size"></tuya-map>
        </div>
        <div class="tracking-page-col info">
            <tuya-tracking-info-container
                [trackingInfo]="trackingInfoVo$ | async">
            </tuya-tracking-info-container>
        </div>
    </main>
    <tuya-tracking-footer></tuya-tracking-footer>
</div>
