import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '../../app.config.service';
import { Configuration } from '../../core/models/base.config';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'tuya-driver-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})

export class DriverResetPasswordComponent implements OnInit, OnDestroy {
    appConfig: Configuration;
    token = '';
    isAndroid = false;
    private subscription: Subscription;

    constructor(public configService: ConfigService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.isAndroid = navigator.userAgent.toLowerCase().indexOf('android') !== -1;
        this.appConfig = this.configService.getConfiguration();
        this.subscription = this.route.queryParams.subscribe(params => {
            this.token = params['token'] ? params['token'] : '';
        });
    }

    getAppLink() {
        return this.appConfig.mobileAppAndroidDriver + 'resetPassword?token=' + this.token;
        // removed becuase we made ios support the deep links
        // return this.isAndroid ? this.appConfig.mobileAppAndroidDriver + 'resetPassword?token=' + this.token
        //     : this.appConfig.mobileAppDomain + 'driver/resetPassword/?token=' + this.token;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}