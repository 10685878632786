import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tuya-payment.page',
  templateUrl: './payment.page.component.html',
  styleUrls: ['./payment.page.component.scss']
})
export class PaymentPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
