import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ElementRef, HostListener, Inject } from '@angular/core';
import { select, select$ } from '@angular-redux/store';
import { Observable, takeUntil } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { OrderFilters, OrderListItem, SortDirection } from '../../model/my-orders.model';
import { HomeActions } from '../../actions/home.actions';
import { getCurrentItemsList, getExpandedRows } from '../../selectors/my-orders.selectors';
import { PoolsActions } from '../../../core/actions/pools.actions';
import { MyOrdersActions } from '../../actions/my-orders.actions';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserProfile } from '../../../auth/model/model';
import { Subject } from 'rxjs';
import { ISearchOrderState } from '../../../home/model/search-order.model';
import { SearchOrderActions } from '../../../home/actions/search-order.actions';
import { MyOrdersService } from '../../../home/services/my-orders.service';
import { HomeActionsButton } from 'app/home/model/model';
import { OrderStatusId, OrderCollapsedViewDto } from 'app/core/models/dto';
import { BuilderActions } from 'app/builder/actions/builder.actions';
import { MatDialog } from '@angular/material/dialog';
import { RecurringModalComponent, RecurringDetail } from '../recurring-modal/recurring-modal.component';
import { PopupComponent } from 'app/shared/components/popup/popup.component';
import { PopupTypeEnum } from 'app/core/models/alert.model';

/*TODO: think over: do we really need function here? Why just not an object or enum? Is it better to move it to model, for an instance?*/
export function getBackFieldName(src: string) {
    switch (src) {
        case 'stopFrom':
            return 'StopFrom';
        case 'stopTo':
            return 'StopTo';
        case 'nStops':
            return 'StopsCount';
        case 'driver':
            return 'DeliveryPro';
        case 'total':
            return 'Total';
        case 'status':
            return 'StatusId';
        case 'dateCompleted':
            return 'CompletionDateUtc';
        case 'date':
        default:
            return 'CreationDateUtc';
    }
}

@Component({
    selector: 'tuya-my-orders.page',
    templateUrl: './my-orders.page.component.html',
    styleUrls: ['./my-orders.page.component.scss']
})
export class MyOrdersPageComponent implements OnInit, OnDestroy {
    @select(['auth', 'currentUser'])
    readonly currentUser$: Observable<any>;
    @select(['home', 'myOrders', 'ordersTable', 'isLoading'])
    isLoading$: Observable<boolean>;
    @select(['home', 'myOrders', 'ordersDetails', 'cancelOrderLoading'])
    orderCancelLoading$: Observable<boolean>;
    @select(['home', 'myOrders', 'ordersTable', 'shipmentsSummary'])
    readonly shipmentsSummary$: Observable<any>;
    @select(['home', 'myOrders', 'pagination', 'pageSize'])
    readonly pageSize$: Observable<number>;
    @select(['home', 'myOrders', 'pagination', 'currentPageNumber'])
    readonly currentPageNumber$: Observable<number>;
    @select(['home', 'myOrders', 'pagination', 'totalItemCount'])
    readonly totalItemCount$: Observable<number>;
    @select(['home', 'myOrders', 'pagination', 'filter'])
    readonly filter$: Observable<any>;
    @select(['home', 'myOrders', 'pagination', 'sortDirection'])
    readonly sortDirection$: Observable<number>;
    @select(['home', 'myOrders', 'pagination', 'sortField'])
    readonly sortField$: Observable<string>;
    @select(['home', 'myOrders', 'pagination', 'hasExceptions'])
    readonly hasExceptionFromStore$: Observable<boolean>;
    @select$(['home', 'myOrders'], getExpandedRows)
    readonly expandedRows$: Observable<any>;
    @select$(['home', 'myOrders'], getCurrentItemsList)
    readonly itemsList$: Observable<OrderListItem[]>;
    @select(['home', 'myOrders', 'ordersDetails', 'canceledOrderInfo'])
    readonly canceledOrderInfo$: Observable<any>;
    @select(['home', 'myOrders', 'ordersTable', 'recurrenceOrderDetail'])
    readonly recurrenceOrderDetail$: Observable<RecurringDetail>;
    @select(['home', 'searchOrders'])
    readonly searchOrders$: Observable<ISearchOrderState>;
    @select(['home', 'searchOrders', 'searchQuery'])
    readonly searchQuery$: Observable<string>;
    @select(['home', 'searchOrders', 'searchFilter'])
    readonly searchFilter$: Observable<any>;
    @select(['home', 'searchOrders', 'filterViewActive'])
    readonly isFilterViewActive$: Observable<boolean>;
    @select(['home', 'myOrders', 'ordersDetails', 'recurringId'])
    readonly recurringId$: Observable<boolean>;

    @ViewChild('cancelOrderModal', { static: true }) cancelOrderModal: TemplateRef<any>;
    @ViewChild('ElementRefName', { static: true }) el: ElementRef;

    pageSize = 10;
    currentPageNumber = 1;
    defaultSortField = 'CreationDateUtc';
    defaultSortDirection = SortDirection.Desk;
    statusFilterList = {
        1: true,
        2: true,
        3: true,
        4: true
    };
    statusFilterEnum = OrderFilters;
    filter = new BehaviorSubject<any>({
        1: true,
        2: true,
        3: true,
        4: true
    });
    statusFilterString: string;
    hasExceptions = false;
    hasExceptionFromStore = new BehaviorSubject<boolean>(false);
    tableHeaders = [
        'recurring',
        'scheduled',
        'select',
        'date',
        'dateCompleted',
        'code',
        'stopFrom',
        'stopTo',
        'driverPro',
        'total',
        'statusId',
        'actions'
    ];
    // admin props
    userAccountId: number;
    isAdmin = false;
    searchQuery = '';
    filterViews = [];
    isFilterViewActive = false;
    searchFilter = {};
    currentOpenId = null;
    selectedOrderQty = 0;
    itemsList: OrderListItem[];
    recurrenceOrderId = null;
    recurrenceOrderDetail: RecurringDetail;
    timeoutRef: number;
    modalCloseDelay = 10000;
    applySticky = false;

    actionButtons: HomeActionsButton[] = [
        { id: 0, name: 'Setup Recurring Order', disabled: true, hidden: false, icon: 'recurring_icon', orderId: null },
        { id: 1, name: 'Duplicate Order', disabled: true, hidden: false, icon: 'recurring_icon', orderId: null },
        //{id: 2, name: 'Edit Order', disabled: true, hidden: false, icon: 'edit_icon', orderId: null}, // JAY
        { id: 2, name: 'Cancel Order', disabled: true, hidden: false, icon: 'cancel_icon', orderId: null }
    ];

    homeActionBtns$ = new BehaviorSubject(this.actionButtons);

    public isShipperSuspended$ = new BehaviorSubject<boolean>(false);
    isShipperSuspended = false;
    // @TODO make it generic if we will not face any modal issues
    private modalConfig = {
        class: 'modal-sm',
        animated: false
    };
    private canceledOrderInfoSubscribe: any;
    private modalRef: BsModalRef;

    private unsubscriber = new Subject<void>();
    private unsubscribeRecurringDetail = new Subject<void>();
    private unsubscribeCurrentUser = new Subject<void>();

    constructor(private homeActions: HomeActions,
        private poolsActions: PoolsActions,
        private ordersAction: MyOrdersActions,
        private searchOrderActions: SearchOrderActions,
        private myOrdersService: MyOrdersService,
        private builderActions: BuilderActions,
        public dialog: MatDialog,
        private myOrdersActions: MyOrdersActions,
        private modalService: BsModalService) {
    }

    ngOnInit() {
        this.canceledOrderInfoSubscribe = this.canceledOrderInfo$.subscribe(data => {
            if (data) {
                this.modalRef = this.modalService.show(this.cancelOrderModal, this.modalConfig);
            }
        });

        this.searchFilter$.pipe(takeUntil(this.unsubscriber)).subscribe(res => {
            this.searchFilter = res;
            this.filterViews = this.generateFilterViews(res);
        });

        this.filter$.pipe(takeUntil(this.unsubscriber)).subscribe(res => {
            this.filter = res;
            this.statusFilterList = res;
        });
        this.itemsList$.pipe(takeUntil(this.unsubscriber)).subscribe(res => this.itemsList = res);
        this.hasExceptionFromStore$.pipe(takeUntil(this.unsubscriber)).subscribe(this.hasExceptionFromStore);
        this.currentPageNumber$.pipe(takeUntil(this.unsubscriber)).subscribe(num => this.currentPageNumber = num);
        this.totalItemCount$.pipe(takeUntil(this.unsubscriber)).subscribe();
        this.pageSize$.pipe(takeUntil(this.unsubscriber)).subscribe(psize => this.pageSize = psize);
        this.sortDirection$.pipe(takeUntil(this.unsubscriber)).subscribe(sortDirec => this.defaultSortDirection = sortDirec);
        this.sortField$.pipe(takeUntil(this.unsubscriber)).subscribe(sortF => this.defaultSortField = sortF);
        this.currentUser$.pipe(takeUntil(this.unsubscribeCurrentUser)).subscribe((data: UserProfile) => {
            if (data) {
                this.isAdmin = data.adminProfile ? data.adminProfile.isAdmin : false;
                this.isShipperSuspended$.next(data.shipperProfile.suspended);
                this.isShipperSuspended = data.shipperProfile.suspended;
                this.hasExceptions = this.hasExceptionFromStore.getValue();
                this.userAccountId = data.shipperProfile.userAccountId;
                this.loadLowRateReasons();

                this.unsubscribeCurrentUser.next();
                this.unsubscribeCurrentUser.unsubscribe();
            }
        });

        this.searchQuery$.pipe(takeUntil(this.unsubscriber)).subscribe(query => {
            this.searchQuery = query;
            if (this.searchQuery === '') {
                this.loadPages();
            } else {
                this.searchOrderActions.searchForAnOrder(this.searchQuery);
            }
        });
        this.isFilterViewActive$.pipe(takeUntil(this.unsubscriber)).subscribe(res => this.isFilterViewActive = res);
        if (this.isAdmin) this.poolsActions.loadPoolList(); // if admin, will load for the selected shipper (see epic)
        
        // In case this class is left on the body tag from a modal that doesn't remove it when being closed.
        document.body.classList.remove('modal-open');

        this.recurringId$.subscribe((res: any) => {
            if (res && res.recurringId) {
                this.ordersAction.getRecurringDetail(res.recurringId);
                let hadResponse = false;

                this.recurrenceOrderDetail$.pipe(takeUntil(this.unsubscribeRecurringDetail)).subscribe((ress: any) => {
                    if (res && !hadResponse) {
                        res.orderId = res.orderId;
                        hadResponse = true;
                        this.openRecurringModal(res);
                    }
                });
            }
        });

    }

    ngOnDestroy() {
        if (this.canceledOrderInfoSubscribe) {
            this.canceledOrderInfoSubscribe.unsubscribe();
        }

        this.builderActions.onBuilderInit({ useDefault: true });
        this.ordersAction.cancelOrderCanceledClear();
        this.unsubscriber.next();
        this.unsubscriber.unsubscribe();

        if (this.timeoutRef) {
            clearTimeout(this.timeoutRef);
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        const pos = document.documentElement.scrollTop;
        this.applySticky = pos >= 110 ? true : false;
    }

    // For Future implementation (Recurring Order Snack Bar)

    // openSnackBar() {
    //     const snackBarRef = this._snackBar.openFromComponent(SharedSnackBarComponent, {
    //       duration: 0,
    //       data: {
    //             buttonText: 'Update',
    //             title: 'Recurring Order Expiring!',
    //             description: """Your recurring setup for Order R58378 will be expiring in a
    // week. Your recurring setup for Order R58378 will be expiring in a week.""",
    //         }
    //     });

    //     snackBarRef.afterDismissed().subscribe(res => {
    //         if (res.dismissedByAction) {
    //             this.getRecurringDetail(this.itemsList[0].orderId);
    //         }
    //     });
    // }

    // Recurring Order - Setup/Edit
    onOrderSelect(orders: OrderCollapsedViewDto[]) {
        this.selectedOrderQty = orders.length;
        const isSingleOrderSelected = this.selectedOrderQty === 1;
        const order = orders[0];

        this.actionButtons.forEach((button, index) => {
            const isRecurringOrderButton = index === 0;
            const isDuplicateOrderButton = index === 1;
            const isCancelOrderButton = index === 2;

            // all buttons are disabled
            button.disabled = true;

            if (isSingleOrderSelected && !this.isShipperSuspended) {
                const isOrderMultiStop = order.stopsCount > 2;
                button.orderId = order.orderId;

                // Enable Cancel button unless the order is already canceled, completed, declined, or pending cancellation
                if (isCancelOrderButton && order.statusId !== OrderStatusId.Canceled && order.statusId !== OrderStatusId.InProgress && order.statusId !== OrderStatusId.Completed && order.statusId !== OrderStatusId.Declined && order.statusId !== OrderStatusId.PendingCancellation) {
                    button.disabled = false;
                } else if (!isOrderMultiStop && (isRecurringOrderButton || isDuplicateOrderButton)) {
                    button.disabled = false;
                    if (isRecurringOrderButton) button.name = order.recurrenceOrderDetail ? 'Edit Recurring Order' : 'Setup Recurring Order';
                }
            }
        });

        this.homeActionBtns$.next(this.actionButtons);
    }



    // Recurring Order - Setup/Edit
    /*onOrderSelect(data: OrderCollapsedViewDto[]) {
        this.selectedOrderQty = data.length;
        this.actionButtons.map((action, index) => {            
            if (this.selectedOrderQty === 1) {
                // Multistop Order
                if (data[0].stopsCount > 2) {
                    action.disabled = true;

                    // Cancel Order
                    if (data[0].statusId !== OrderStatusId.Canceled && data[0].statusId !== OrderStatusId.Completed && data[0].statusId !== OrderStatusId.PendingCancellation && !this.isShipperSuspended)
                        this.actionButtons[2].disabled = false;
                }
                // if selected items length 1 and item status is not DRAFT || COMPLETED means we can edit/cancel an order, then enable Edit/Cancel buttons            
                else if (data[0].statusId !== OrderStatusId.Canceled &&
                    data[0].statusId !== OrderStatusId.Completed &&
                    data[0].statusId !== OrderStatusId.Declined &&
                    !this.isShipperSuspended) {
                    action.disabled = false;
                    action.orderId = data[this.selectedOrderQty - 1].orderId;
                    if (index === 0 && data[0].recurrenceOrderDetail) {
                        action.name = 'Edit Recurring Order';
                    } else if (index === 0 && !data[0].recurrenceOrderDetail) {
                        this.actionButtons[index].name = 'Setup Recurring Order';
                    }
                }
            } else {
                action.disabled = true;
                if (this.selectedOrderQty === 1 && (index === 0 || index === 1) &&
                    !this.isShipperSuspended) {
                    action.disabled = false;
                    action.orderId = data[this.selectedOrderQty - 1].orderId;
                    if (index === 0 && data[0].recurrenceOrderDetail) {
                        action.name = 'Edit Recurring Order';
                    } else if (index === 0 && !data[0].recurrenceOrderDetail) {
                        this.actionButtons[index].name = 'Setup Recurring Order';
                    }
                }
            }
        });

        this.homeActionBtns$.next(this.actionButtons);
    }*/

    // ACTION: Recurring Order, Duplicate Order, Edit, Cancel
    // JAY: Swap Edit & Cancel
    onActionButtonEmit(data: HomeActionsButton) {
        if (data.orderId) {
            switch (data.id) {
                // Recurring Order
                case this.actionButtons[0].id:
                    this.getRecurringDetail(data.orderId);
                    break;

                // Duplicate Order                    
                case this.actionButtons[1].id:
                    this.builderActions.onEditOrder(data.orderId, true);
                    break;

                // Cancel Order
                case this.actionButtons[2].id:
                    const payload = {
                        orderId: data.orderId,
                        comments: '',
                        success: this.successCallback.bind(this, data.orderId),
                        fail: this.failCallback.bind(this),
                    };
                    this.ordersAction.cancelOrder(payload);
                    break;

                /*case this.actionButtons[3].id:
                    // Edit Order
                    this.builderActions.onEditOrder(data.orderId);
                    break;*/

                default:
                    break;
            }
        }
    }

    successCallback(id: number) {
        this.ordersAction.updateOrderStatus({ orderId: id });
    }

    failCallback() {
    }

    onRecurringOrderEdit(data: { recurringId: number, orderId: number }) {
        this.recurrenceOrderId = data.recurringId;
        this.ordersAction.getRecurringDetail(this.recurrenceOrderId);
        let hadResponse = false;

        this.recurrenceOrderDetail$.pipe(takeUntil(this.unsubscriber)).subscribe(res => {
            if (res && !hadResponse) {
                res.orderId = data.orderId;
                hadResponse = true;
                this.openRecurringModal(res);
            }
        });
    }

    getRecurringDetail(orderId: number) {
        this.recurrenceOrderId = null;
        const recurringOrder = this.itemsList.filter((order, indx) => {
            if (order.orderId === orderId && order.recurrenceOrderDetail) {
                return this.itemsList[indx];
            }
        });
        if (recurringOrder && recurringOrder.length > 0 && this.recurrenceOrderId === null) {
            this.recurrenceOrderId = recurringOrder[0].recurrenceOrderDetail.recurrenceOrderId;
            this.ordersAction.getRecurringDetail(this.recurrenceOrderId);
            let hadResponse = false;
            this.recurrenceOrderDetail$.pipe(takeUntil(this.unsubscribeRecurringDetail)).subscribe(res => {
                this.recurrenceOrderDetail = res;
                if (res && !hadResponse) {
                    res.orderId = orderId;
                    hadResponse = true;
                    this.openRecurringModal(res);
                }
            });
        } else {
            const data = { orderId: orderId };
            this.openRecurringModal(data);
        }

    }

    openRecurringModal(recurringData: any) {
        if (this.dialog.openDialogs.length > 0) {
            return true;
        }
        const dialogRef = this.dialog.open(RecurringModalComponent, {
            width: '720px',
            data: recurringData, // For create mode null val
            disableClose: true,
            autoFocus: false,
            panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(result => {
            this.selectedOrderQty = 0;
            this.recurrenceOrderId = null;
            this.ordersAction.clearRecurringDetail();
            this.myOrdersActions.setRecurringId(null);
            // If result is TRUE - MODE is: Delete
            if (result === 'delete') {
                const popupText = {
                    type: PopupTypeEnum.CreateUpdateOrder,
                    title: 'tuya-my-orders.recurring.delete-title',
                    subTitle: 'tuya-my-orders.recurring.delete-subtile'
                };
                const di = this.dialog.open(PopupComponent, {
                    data: popupText,
                    autoFocus: false,
                    disableClose: true,
                    panelClass: 'custom-dialog-container'
                });
                di.afterClosed().subscribe(r => {
                    if (r) {
                        clearTimeout(this.timeoutRef);
                    }
                });
                this.closeSuccessModal();
            } else if (result) {
                this.showSuccessPopup(recurringData);
            }
        });
    }

    showSuccessPopup(recurringData: any) {
        const updatedOrder = this.itemsList.filter(item => item.orderId === recurringData.orderId);
        let message = '';
        let orderNumber = '';

        if (updatedOrder.length > 0) {
            message = !recurringData.id ? updatedOrder[0].recurrenceOrderDetail.recurrenceDetail : '';
            orderNumber = updatedOrder[0]['code'];
        }

        let popupText = {};

        if (!recurringData.id) {
            popupText = {
                type: PopupTypeEnum.CreateUpdateOrder,
                title: 'tuya-my-orders.recurring.create-title',
                subTitle: 'tuya-my-orders.recurring.create-subtile',
                subTitleProps: { code: orderNumber, message: message }
            };
        } else {
            popupText = {
                type: PopupTypeEnum.CreateUpdateOrder,
                title: 'tuya-my-orders.recurring.update-title',
                subTitle: 'tuya-my-orders.recurring.update-subtile'
            };
        }

        const dialogRef = this.dialog.open(PopupComponent, {
            data: popupText,
            autoFocus: false,
            disableClose: true,
            panelClass: 'custom-dialog-container'
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                clearTimeout(this.timeoutRef);
            }
        });

        this.closeSuccessModal();
    }

    closeSuccessModal() {
        this.timeoutRef = window.setTimeout(() => {
            this.dialog.closeAll();
        }, this.modalCloseDelay);
    }

    generateFilterViews(res) {
        const filterViewsSet = new Set([]);
        const statuses = { 1: 'Draft', 2: 'Active', 3: 'Completed', 4: 'Canceled', startDate: null, endDate: null };

        let i = 0;
        for (const key of Object.keys(res)) {
            const obj = {};
            obj['id'] = i;
            obj['val'] = statuses[key] === null ? res[key] : statuses[key];

            if (key !== 'startDate' && key !== 'endDate') {
                obj['name'] = 'Status';
            } else {
                obj['name'] = key === 'startDate' ? 'Start Date' : 'End Date';
            }

            const strObj = JSON.stringify(obj);
            if (res[key] && res[key] !== '') {
                filterViewsSet.add(strObj);
            } else if (filterViewsSet.has(strObj)) {
                filterViewsSet.delete(strObj);
            }
            i++;
        }
        const views = [];
        filterViewsSet.forEach(v => views.push(JSON.parse(v)));
        return views;
    }

    onRemoveFilter(indx: number) {
        for (const key of Object.keys(this.searchFilter)) {
            if (key === (this.filterViews[indx].id + 1).toString()) {
                if (typeof (this.searchFilter[key]) === 'boolean' && this.searchFilter[key]) {
                    this.searchFilter[key] = false;
                    break;
                }
            } else if (key === 'endDate' && this.searchFilter[key] !== '' && this.filterViews[indx].name === 'End Date') {
                this.searchFilter[key] = '';
                break;
            } else if (key === 'startDate' && this.searchFilter[key] !== '' && this.filterViews[indx].name === 'Start Date') {
                this.searchFilter[key] = '';
                break;
            }
        }

        this.searchOrderActions.updateSearchFilter(this.searchFilter);
        this.searchOrderActions.searchForAnOrder(this.searchQuery);
    }

    loadLowRateReasons() {
        this.ordersAction.loadLowRateReasons();
    }

    toggleHasException() {
        this.currentPageNumber = 1;
        this.loadPages();
    }

    updateFilterList(filter) {
        this.statusFilterList[filter] = !this.statusFilterList[filter];
        this.currentPageNumber = 1;
        this.loadPages();
    }

    parseFilterList() {
        const str = [];
        for (const key in this.statusFilterList) {
            if (this.statusFilterList[key]) {
                str.push(key);
            }
        }
        this.statusFilterString = str.toString();
    }

    loadPages() {
        this.parseFilterList();
        this.homeActions.clearAllRowInfo();

        this.ordersAction.updatePagination({
            pageSize: this.pageSize,
            currentPageNumber: this.currentPageNumber,
            sortDirection: this.defaultSortDirection,
            filter: this.statusFilterList,
            statuses: [],
            sortField: this.defaultSortField,
            hasExceptions: this.hasExceptions
        });

        if (this.searchQuery !== '') {
            this.searchOrderActions.searchForAnOrder(this.searchQuery);
            this.searchOrderActions.closeFilter();
            return;
        }

        this.homeActions.loadPage({
            pageSize: this.pageSize,
            currentPageNumber: this.currentPageNumber,
            sortField: this.defaultSortField,
            sortDirection: this.defaultSortDirection,
            orderFilters: this.statusFilterString,
            hasExceptions: this.hasExceptions,
            shipperUserId: this.userAccountId
        });
    }

    onRowToggle(evt) {
        // store curr order id, to close it, if user clicks new row before loading prev
        if (this.currentOpenId) {
            this.homeActions.deleteRowInfo(this.currentOpenId);
            this.currentOpenId = null;
        }
        if (evt.open) {
            this.homeActions.loadRowInfoSucceeded(this.myOrdersService.getDefaultData(evt.data.orderId));
            this.homeActions.loadRowInfo({ id: evt.data.orderId, collapsed: true });
            this.currentOpenId = evt.data.orderId;
        } else {
            this.homeActions.loadRowInfo({ id: evt.data.orderId, collapsed: false });
            this.homeActions.deleteRowInfo(evt.data.orderId);
        }
    }

    hideModal() {
        this.modalRef.hide();
        this.ordersAction.cancelOrderCanceledClear();
    }

    onSearchFilter() {
        this.isFilterViewActive ? this.searchOrderActions.closeFilter() : this.searchOrderActions.openFilter();
    }

}
