import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Directive({
   selector: '[tuyaBackgroundColor]'
})
export class BackgroundColorDirective {
    constructor(private elementRef: ElementRef,
            private renderer: Renderer2,
            router: Router) {

        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const pagesWithMaps = ['/shipment/initial', '/shipment/builder'];
                const url = event.url.split('/')[1];
                if (pagesWithMaps.includes(event.urlAfterRedirects)) { return; }
                switch (url) {
                    case 'profile':
                    case 'company':
                    case 'login':
                    case 'register':
                    case 'forgotPassword':
                        this.renderer.addClass(this.elementRef.nativeElement, 'secondary-background');
                        break;
                    case 'shipment':
                        this.renderer.addClass(this.elementRef.nativeElement, 'secondary-background');
                        break;
                    default:
                        this.renderer.removeClass(this.elementRef.nativeElement, 'secondary-background');
                }
            }
        });
    }
}
