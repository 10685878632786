import { Action } from 'redux';
import { ISearchOrderState } from '../../model/search-order.model';
import { SearchOrderAction,
         UPDATE_SEARCH_QUERY,
         UPDATE_IS_SEARCHING,
         UPDATE_PROGRESS_BAR,
         RESET_ORDER_SEARCH,
         OPEN_FILTER_DROPDOWN,
         CLOSE_FILTER_DROPDOWN,
         UPDATE_SEARCH_FILTER,
         RESET_SEARCH_FILTER
         } from '../../actions/search-order.actions';
import { LOGOUT_SUCCESS } from '../../../auth/actions/auth.actions';
import { RESET_PAGINATION } from 'app/home/actions/my-orders.actions';
import { SessionStorageUtil } from 'app/store/sessionStorage';

const sessionStorageUtil = SessionStorageUtil.getInstance();
export const initialSearchOrderState: ISearchOrderState = {
    searchQuery: sessionStorageUtil.get('home.searchOrders.searchQuery') || '',
    isSearching: false,
    progressBarVal: 0,
    filterViewActive: false,
    searchFilter: sessionStorageUtil.get('home.searchOrders.searchFilter') ||
                {1: false, 2: false, 3: false, 4: false, 'endDate': '',  'startDate': ''}
};

export function searchOrderReducer(state = initialSearchOrderState, a: Action): ISearchOrderState {
    const action = a as SearchOrderAction;
    switch (action.type) {
        case LOGOUT_SUCCESS:
        case RESET_ORDER_SEARCH: {
            return {
                ...initialSearchOrderState,
                searchQuery: '',
                searchFilter: {1: false, 2: false, 3: false, 4: false, 'endDate': '',  'startDate': ''}
            };
        }
        case UPDATE_PROGRESS_BAR: {
            return {
                ...state,
                progressBarVal: action.payload
            };
        }
        case UPDATE_SEARCH_QUERY: {
            return {
                ...state,
                searchQuery: action.payload
            };
        }
        case UPDATE_IS_SEARCHING: {
            return {
                ...state,
                isSearching: action.payload
            };
        }
        case OPEN_FILTER_DROPDOWN: {
            return {
                ...state,
                filterViewActive: true
            };
        }
        case RESET_PAGINATION:
        case CLOSE_FILTER_DROPDOWN: {
            return {
                ...state,
                filterViewActive: false
            };
        }
        case UPDATE_SEARCH_FILTER: {
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    1: action.payload['1'],
                    2: action.payload['2'],
                    3: action.payload['3'],
                    endDate: action.payload['endDate'] || '',
                    startDate: action.payload['startDate'] || ''
                }
            };
        }
        case RESET_SEARCH_FILTER: {
            return {
                ...state,
                searchQuery: action.payload ? '' : state.searchQuery,
                searchFilter: {1: false, 2: false, 3: false, 4: false, 'endDate': '',  'startDate': ''}
            };
        }
        default:
            return state;
    }
}
