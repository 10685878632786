import { Injectable } from '@angular/core';
import { BaseService } from '../../core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../app.config.service';

export const API_GET_TRACKING_INFO = '/tracking';
@Injectable()
export class TrackingService extends BaseService {
    constructor(http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }

    get(data) {
        return this.api().GET(API_GET_TRACKING_INFO, data);
    }
}
