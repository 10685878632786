import { IAppState } from '../../store/model';
import { IndustrySegment, StateDto } from '../../core/models/dto';
import { AccountValidationResultVO, CompanyValidationResultVO } from '../model/model';

export class RegisterSelectors {
    public static statesSelector = (state: IAppState): StateDto[] => state.core.states;
    public static  industrySegmentsSelector = (state: IAppState): IndustrySegment[] => state.register.industrySegments;
    public static formStepSelector = (state: IAppState): number => state.register.forms.formStep;
    public static isLoadingSelector = (state: IAppState): boolean => state.register.forms.isLoading;
    public static companyInfoFormSelector = (state: IAppState): any => state.register.forms.companyInfoForm;
    public static validateAccountResultSelector = (state: IAppState): AccountValidationResultVO => {
        return state.register.forms.myAccountFormValidationResult;
    }
    public static validateCompanyErrorSelector = (state: IAppState): CompanyValidationResultVO => {
        return state.register.forms.companyInfoFormValidationResult;
    }
}

