<div class="builder-summary-component"
  [ngClass] = "{
    'builder-summary-component': true,
    'builder-summary-component--inactive': currentStep < stopCount + 1
  }"
>
  <div class="builder-summary-item builder-summary-item__header">
    <div class="builder-order-summary-icon"></div>
    <h3 class="builder-order-summary-header">
        {{ 'builderSummary.summaryHeader' | translate }}
    </h3>
  </div>

  <tuya-builder-accordion
    [expanded]="currentStep === stopCount + 1"
    [stepIndex]="stopCount + 1">

    <div class="builder-summary-item__total">
      <div class="price">
        <div class="builder-summary-head-cell builder-summary-head-cell-total">
          <span *ngIf="(isInitPrice$ | async)">{{ 'builderSummary.totalHeader' | translate }}</span>
          <span *ngIf="!(isInitPrice$ | async)"> {{ 'builderSummary.updatedTotalHeader' | translate }} </span>
        </div>
        <div class="builder-summary-body-total">{{ price | currency: 'USD' : 'symbol-narrow' }}</div>
      </div>

      <div class="info" *ngIf="((isScheduledOrder$ | async) && (statusId$ | async) === orderStatusId.Offered) ||
                  !(isScheduledOrder$ | async)">
        <div class="price-change-message">
          <div class="icon">
            <img src="../../../../assets/img/my-orders/time_info.svg"/>
          </div>
          <div class="info-message">
              <span>
                {{ 'builderSummary.timeMessage' | translate }}
              </span> 
          </div>
        </div>
      </div>

        <div class="info" *ngIf="(((isScheduledOrder$ | async) && (statusId$ | async) !== orderStatusId.Offered) ||
                                              (isScheduledOrder$ | async) && (statusId$ | async) === orderStatusId.Scheduled) && 
                                              ((isScheduledOrder$ | async) && (statusId$ | async) !== orderStatusId.Draft)">
            <div class="price-change-message" *ngIf="(isScheduledOrder$ | async) && (statusId$ | async) === orderStatusId.Scheduled">
              <div class="icon">
                <img src="../../../../assets/img/my-orders/time_info.svg"/>
              </div>

              <div class="info-message">
                  {{ 'builderSummary.scheduledTimeMessage' | translate }}
                    <span class="info-message--date">
                      {{ (scheduledOrderOfferTime$ | async) | date: 'longDate' }}
                    </span> at 
                    <span class="info-message--date">
                      {{ (scheduledOrderOfferTime$ | async) | date: 'shortTime' }}.
                    </span>
              </div>
            </div>
        </div>
    </div>

    <div class="summary-containter">
      <table 
        class="builder-summary-table"
        *ngIf="summary">

        <thead class="builder-summary-head">
          <th class="builder-summary-head-cell">
            {{ 'builderSummary.stopsHeader' | translate }}
          </th>
          <th class="builder-summary-head-cell builder-summary-head-cell-border-left">
            {{ 'builderSummary.itemsHeader' | translate }}
          </th>
          <th class="builder-summary-head-cell builder-summary-head-cell-border-left">
            {{ 'builderSummary.weightHeader' | translate }}
          </th>
          <th class="builder-summary-head-cell builder-summary-head-cell-border-left">
            {{ 'builderSummary.distanceHeader' | translate }}
          </th>
        </thead>

        <tbody class="builder-summary-body">
          <td class="builder-summary-body-cell">
            {{ summary.stops | number: '1.0-0' }}
          </td>
          <td class="builder-summary-body-cell builder-summary-head-cell-border-left">
            {{ summary.items | number: '1.0-0' }}
          </td>
          <td class="builder-summary-body-cell builder-summary-head-cell-border-left">
            {{ summary.lbs | number: '1.0-0' }} {{ 'builderSummary.pound' | translate }}
          </td>
          <td class="builder-summary-body-cell builder-summary-head-cell-border-left">
            {{ summary.miles | number: '1.1-1' }} {{ 'builderSummary.miles' | translate }}
          </td>
        </tbody>
      </table>

      <div class="item html" *ngIf="(((isScheduledOrder$ | async) && (statusId$ | async) === orderStatusId.Offered) ||
                                    !(isScheduledOrder$ | async))">
        <h2 class="timer" [ngStyle]="{'color': (timeLoading$ | async) ? '#225eac' : '#dcdfe0'}"> {{ (counter$ | async)?.ms  }}:{{ (counter$ | async)?.sc }}</h2>
        <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
        <g>
          <circle d="circlea" r="27.5" cy="50" cx="50" stroke-width="3" stroke="#dcdfe0" fill="none"/>
          <circle id="circle" [ngStyle]="{'visibility': (timeLoading$ | async) ? 'visible' : 'hidden'}" class="circle_animation" r="27.5" cy="50" cx="50" stroke-width="3" stroke="#225eac" fill="none"/>
        </g>
        </svg>
      </div>
    </div>

  </tuya-builder-accordion>
</div>