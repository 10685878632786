<div class="stepper-container">
    <div class="step"
         [ngStyle]="{'z-index': (steps.length - i)}"
         [ngClass]="{
            'active': addActiveClass(step),
            'last': l,
            'current': addCurrentClass(step),
            'skip': step.skip}"
         *ngFor="let step of steps; index as i; last as l">
        <div class="inner"></div>{{step.stepName | translate}}
    </div>
</div>
