import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { OrderItemVo, Stop, StopType } from '../../../models/order.model';
import { ItemUtils } from '../../../../core/utils/item-utils';
import { ItemsDetails } from '../../../models/ui.model';
import { OrderEditMode } from '../../../../core/models/order.dto';

@Component({
    selector: 'tuya-stop-line-header',
    templateUrl: './stop-line-header.component.html',
    styleUrls: ['./stop-line-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopLineHeaderComponent implements OnInit {

    @Input() public stopTypeName = '';
    @Input() public stop = new Stop();
    @Input() editMode = OrderEditMode.NoEdit;
    @Input() public isOptionalStopLine = false;
    @Input() public isNewStop = false;
    @Input() public set stopItems(value: OrderItemVo[]) {
        this.totalItemsDetails = ItemUtils.getItemsDetails(value);
    }

    public OrderEditMode: typeof OrderEditMode = OrderEditMode;
    public StopType: typeof StopType = StopType;
    public totalItemsDetails: ItemsDetails;

    constructor() { }
    ngOnInit() {}

}
